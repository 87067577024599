var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-md-4 p-3"},[_c('FTtransactionDetailModal',{attrs:{"show":_vm.showTransactionModal,"toggle":_vm.toggleTransactionModal,"singleTransactionObj":_vm.singleTransactionObj,"refetch":_vm.refetch}}),_c('div',{staticClass:"SectionItem no-b-b mb-3"},[_vm._m(0),_c('div',[_c('button',{staticClass:"btn btn-lg btn-secondary-inverted",on:{"click":function($event){return _vm.downloadLinks()}}},[_vm._m(1),_vm._v(" Export CSV ")])])]),_c('div',{staticClass:"px-3"},[_c('FilterWrapper',[_c('template',{slot:"filterForm"},[_c('PaymentMerchantFilter',{attrs:{"loading":_vm.loading,"rLoading":_vm.rLoading,"submitFilter":_vm.refetch,"clearFilter":() => _vm.clearFilter('providersRef')}})],1)],2)],1),_c('TableWrapper',{attrs:{"loading":_vm.loading,"errorMsg":_vm.errorMsg,"refetch":_vm.fetchFTTransaction,"isEmpty":_vm.isEmpty,"emptyTitle":_vm.emptyTitle}},[_c('div',{staticClass:"w-100 py-3",attrs:{"slot":"content"},slot:"content"},[_c('v-server-table',{ref:"providersRef",staticClass:"v-table row-pointer",attrs:{"data":_vm.customers,"columns":_vm.columns,"options":_vm.options},on:{"loaded":_vm.onLoaded,"loading":function($event){return _vm.setLoading(true)},"row-click":_vm.handleClick},scopedSlots:_vm._u([{key:"firstName",fn:function({ row }){return [_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(row.firstName || "Null")+" ")])]}},{key:"amount",fn:function({ row }){return [_vm._v(" ₦"+_vm._s(_vm.formatNumber(row.amount) || "0")+" ")]}},{key:"status",fn:function({ row }){return _c('div',{},[_c('span',{staticClass:"badge badge-app",class:row.responseCode == 'T100'
      ? 'badge-success'
      : row.responseCode == 'T133'
        ? 'badge-primary'
        : 'badge-danger'},[_vm._v(" "+_vm._s(row.responseCode == "T100" ? "Success" : row.responseCode == "T133" ? "Pending" : "Failed")+" ")])])}},{key:"email",fn:function({ row }){return [_vm._v(" "+_vm._s(row.email || "Null")+" ")]}},{key:"updatedAt",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm._f("moment")(row.updatedAt," DD-MM-YYYY h:mm a"))+" ")]}}])})],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"title m-0"},[_vm._v("Transfer Transactions")]),_c('p',{staticClass:"text-left description"},[_vm._v(" All transactions by Customers ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":"/assets/img/icons/document-download.svg","alt":""}})])
}]

export { render, staticRenderFns }