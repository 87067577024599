<template>
    <div>
        <div class="row">
            <div class="col-12 mt-3">
                <Spinner v-if="loading" />
                <div class="p-md-4 p-3  card mx-4" v-else>
                    <div class="my-4">
                        <a v-if="this.$route.query.type" @click="$router.go(-1)"
                            class="mr-2 text-secondary pointer">Address
                            Reports</a>
                        <router-link v-else to="/logs/verification-logs"
                            class="mr-2 text-secondary">Reports</router-link>
                        &lt;
                        <router-link to="" class="ml-2 text-primary">
                            Report Details</router-link>
                    </div>
                    <div class="">
                        <div class="SectionItem no-b-b mb-3 d-block">
                            <span class="title">
                                <a v-if="this.$route.query.type" @click="$router.go(-1)"
                                    class="nav-sub-link pointer"><img src="/assets/img/icons/close.svg" alt="image"
                                        height="30" /></a>
                                <router-link v-else to="/logs/verification-logs" class="nav-sub-link"><img
                                        src="/assets/img/icons/close.svg" alt="image" height="30" /></router-link>
                                Close</span>
                        </div>
                    </div>

                    <div>
                        <h5 class="font-weight-bold">Search Information</h5>
                        <div class="row">
                            <div class="col-lg-3 col-sm-6 mb-3">
                                <span class="text-secondary text-uppercase">REQUEST ID</span><br />
                                <span>{{ details.requestId }}</span>
                            </div>
                            <div class="col-lg-3 col-sm-6 mb-3">
                                <span class="text-secondary text-uppercase">Date REQUESTED</span><br />
                                <span>{{ timeDateFormat(details.date_requested).date }}</span>
                            </div>
                        </div>

                    </div>

                    <div>
                        <div>
                            <div class="mt-5">
                                <button class="btn btn-sm btn--secondary--border px-4 mx-3"
                                    @click="fetchReportDetails()">
                                    <img src="/assets/img/icons/refresh-2.svg" class="mr-2" alt="" />
                                    Refresh Data
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="dashboard-briefs mt-4">
                        <div class="dashboard-brief rounded py-4">
                            <h2 class="call-count">
                                &#8358;{{ formatNumber(details.total_transaction_value) || "0" }}
                            </h2>
                            <p class="call-name">Total Tranaction Value</p>
                        </div>
                        <div class="dashboard-brief  rounded py-4">
                            <h2 class="call-count">
                                {{ formatNumber(details.total_verifications, false) || "0" }}
                            </h2>
                            <p class="call-name">Total Number of Verifications</p>
                        </div>
                        <div class="dashboard-brief rounded py-4">
                            <h2 class="call-count">
                                {{ formatNumber(details.number_of_customers, false) || "0" }}
                            </h2>
                            <p class="call-name">Number of Customers</p>
                        </div>
                    </div>

                    <div class="content__slider mt-4">
                        <TabsV2 :tabNames="tabNames" :tabId="'reportTab'" :tabLink="true" />
                    </div>



                </div>
            </div>
        </div>
        <div class="col-12 mx-4" :class="tbClass">
            <div class="flex__end px-5 mb-5">
                <button class="btn btn-lg btn-secondary-inverted" @click="() => { downloadLinks() }">
                    <span><img src="/assets/img/icons/document-download.svg" alt="" /></span>
                    Export CSV
                </button>
            </div>
            <v-server-table ref="reportsRef" class="v-table row-pointer" :columns="columns" :options="options"
                @loading="setLoading(true)" @loaded="setLoading(false)" @row-click="handlClick">
                <template slot="status" slot-scope="{ row }">
                    <span :class="statusColor(row.status)">
                        {{ titleCase(row.status) }}
                    </span>
                </template>
                <template slot="date" slot-scope="{ row }">
                    {{ timeDateFormat(row.date).date }}
                    {{ timeDateFormat(row.date).time }}
                </template>

            </v-server-table>
        </div>
    </div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";
import TabsV2 from "../../../../components/utils/Tabs/TabsV2";
import Spinner from "../../../../components/utils/Spinner/Spinner";
import { formatNumber } from "../../../../components/utils/helpers";


const tableHeadings = {
    service_name: "Service Name",
    service_type: "Service Type",
    id_number: "ID Number",
    status: "Status",
    date: "Date",
    initiated_by: "Initiated By",
};

const TabName = (id) => [
    {
        tabName: "Bulk Service Information",
        value: "Bulk_Service_Information",
        link: `/logs/bulk-verification-logs/${id}`,
    },
];

export default {
    mixins: [componentData],
    name: "BulkVerificationDetails",
    data() {
        return {
            tabNames: TabName(this.$route.params.id),
            details: null,
            loading: true,
            rLoading: false,
            errorMsg: "",
            size: 25,
            filters: {},
            ...this.tableOptions(tableHeadings, 20),
        };
    },
    components: { TabsV2, Spinner, },
    computed: {
        tbClass() {
            return this.loading || this.errorMsg ? "hide__table" : "";
        },
    },
    methods: {
        formatNumber,
        handlClick({ row }) {
            if (row.service_type === "NIN" || row.service_type === "NIN Phone" || row.service_type === "BVN") {
                this.$router.push(
                    `/logs/verification-logs/${row.id_number}/main_information?id=${this.$route.params.id}`
                );
            }
            else {
                this.$router.push(`/logs/verification-logs/${row.id_number}/ownership?id=${this.$route.params.id}`);
            }
        },
        async fetchReportDetails() {
            this.options.requestFunction = async (data) => {
                const payload = {
                    params: {
                        id: this.$route.params.id,
                    },
                };

                try {
                   const resp = (
                        await this.$store.dispatch("logs/getServiceBulkReportLogDetails", payload)
                    ).data;
                    this.details = resp;
                    return { ...resp.bulk_service_information, data: resp.bulk_service_information, count: resp.bulk_service_information.length };
                } catch (error) {
                    this.loading = false;
                    this.errorMsg = "Error fetching service reports";
                }
            };
        },
        async downloadLinks() {
            try {
                const params = {
                    id: this.details.requestId,
                    clientid: this.details.client_id
                }
                const resp = (
                    await this.$store.dispatch("logs/getServiceBulkReportLogDetailsExport", {params})
                );
                this.showSuccessNotification(resp.message);
            } catch (error) {
                this.loading = false;
                this.errorMsg = "Error downloading service reports";
                console.log({ error })
            }
        },
    },
    created() {
        this.fetchReportDetails();
    },
    mounted() {
        this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
    },
};
</script>


<style scoped>
.dashboard-briefs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    /* grid-template-rows: 170px; */
}
.dashboard-brief {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}

.call-count {
    font-size: 22px;
    font-weight: 600;
    line-height: 1;
    color: #071b2b;
    padding-top: 8px;
    margin-bottom: 20px;
}

.call-name {
    font-size: 15px;
    color: #081b2a;
    text-transform: uppercase;
}</style>