<template>
    <form @submit.prevent="handleSubmit">
        <div class="row mb-2">
            <div class="col-sm-6 px-sm-2 col-md-4  col-xl-3">
                <CustomSelect name="serviceName" :optionLabel="'name'" placeholder="Service Name"
                    :options="serviceTypes" v-model="filters.serviceName" />
            </div>
            <div class="col-sm-6 px-sm-2 col-md-4  col-xl-3">
                <DatePicker :name="'from'" v-model="filters.from" :placeholder="'Start Date'"
                    :disabledDates="{ from: new Date() }" />
            </div>
            <div class="col-sm-6 px-sm-2 col-md-4  col-xl-3">
                <DatePicker :name="'to'" v-model="filters.to" :disabledDates="{ from: new Date(), to: filters.from }"
                    :placeholder="'End Date'" />
            </div>

            <div className="col-sm-12 px-sm-3 col-md-10 px-ms-3 col-xl-5 mt-3">
                <button type="submit" :disabled="disabled || loading" class="btn btn-primary mx-3">
                    Filter
                </button>

                <button v-show="isFilter" type="button" class="btn btn-outline" @click="resetFilters">
                    Clear
                </button>

                <div class="d-inline-block" v-if="download">
                    <DownloadReport :downloadReport="download" :loading="rLoading" />
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { filters } from "../../../public/assets/mixins/filters";

const serviceTypes = [
    { name: "Smart Address Verification", value: "Smart Address Verification" },
    { name: "Bulk Verification", value: "Bulk Verification" },
];
const initialState = () => ({
    filters: {
        serviceName: "",
        from: null,
        to: null,
    },
    isFilter: false,
    qLoading: false,
});

export default {
    mixins: [filters],
    name: "AddressVerificationFilter",
    data() {
        return { ...initialState(), serviceTypes };
    },
    props: ["loading", "rLoading", "clearFilter", "submitFilter", "download"],
    computed: {
        disabled() {
            return !Object.values(this.filters).some((val) => val);
        },
    },
    methods: {
        resetFilters() {
            Object.assign(this.$data, { ...initialState() });
            this.clearFilter();
            this.isFilter = false;
        },
        handleSubmit() {
            this.isFilter = true;
            if (this.filters.from)
            this.filters.from = this.timeRangeFormat(this.filters.from, true);
            if (this.filters.to)
                this.filters.to = this.timeRangeFormat(this.filters.to);
            if (this.filters.serviceName) {
                this.filters.serviceName = this.filters.serviceName.value;
                if (this.filters.serviceName === 'Bulk Verification') {
                    this.filters.service_type = "Address Verification";
                } else {
                    this.filters.service_type = ''
                }
            }
            this.submitFilter(this.filters);
        },
    },
    watch: {
        "filters.from": function () {
            this.filters.to = null;
        },
    },
};
</script>
