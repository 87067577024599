<template>
  <Modal
    :id="id"
    :title="
      service
        ? `Update ${service.service_label}`
        : `Add New Service to ${category.api_service_category}`
    "
    :toggle="handleCloseModal"
    :loading="loading"
  >
    <SuccessState
      v-if="successMsg"
      :message="`Service ${service ? 'Updated' : 'Added'} Successfully`"
      :onSuccess="handleRefetch"
    />
    <form v-else @submit.prevent="handleSubmit" class="row">
      <div v-show="service" class="col-12">
        <CustomSelect
          :name="'api_service_category_id'"
          :getLabel="getLabel"
          :placeholder="'Select HTTP Method'"
          :options="getApiServices"
          :label="'Service Category'"
          v-model="request.api_service_category_id"
          :required="true"
          :error="formErrors.api_service_category_id"
          :handleError="handleError"
        />
      </div>
      <div class="col-12">
        <CustomInput
          :name="'service_label'"
          v-model="request.service_label"
          :label="'Service Label'"
          :placeholder="'Enter Service Label'"
          :required="true"
          :error="formErrors.service_label"
          :handleError="handleError"
        />
      </div>

      <div class="col-12">
        <CustomInput
          :name="'service_url'"
          v-model="request.service_url"
          :label="'Service URL'"
          :placeholder="'Enter Service URL'"
          :required="true"
          :error="formErrors.service_url"
          :handleError="handleError"
        />
      </div>

      <div class="col-sm-6 col-12">
        <CustomSelect
          :name="'charge_type'"
          :placeholder="'Select Charge Type'"
          :options="chargeTypes"
          option-label="label"
          :reduce="(chargeType) => chargeType.value"
          :label="'Charge Type'"
          v-model="request.charge_type"
          :required="true"
          :error="formErrors.charge_type"
          :handleError="handleError"
        />
      </div>

      <div class="col-sm-6 col-12">
        <CustomSelect
          :name="'country_name'"
          :placeholder="'Select Country'"
          :options="countryCodes"
          :getLabel="(val) => `${val.name} (${val.dial_code})`"
          :label="'Country'"
          v-model="request.country_name"
          :required="true"
          :error="formErrors.country_name"
          :handleError="handleError"
        />
      </div>

      <div class="col-sm-6 col-12">
        <CustomInput
          :name="'price_per_call'"
          v-model="request.price_per_call"
          :label="'Price Per Call'"
          :placeholder="'Enter Price Per Call'"
          :required="true"
          :error="formErrors.price_per_call"
          :handleError="handleError"
        />
      </div>

      <div class="col-sm-6 col-12">
        <CustomInput
          :name="'fail_over_price'"
          v-model="request.fail_over_price"
          :label="'Fail Over Price'"
          :placeholder="'Enter a Fail Over Price'"
          :error="formErrors.fail_over_price"
          :handleError="handleError"
        />
      </div>
      <div
        v-if="chargeTypeIsFlatCommissionInclusiveOrExclusive"
        class="col-sm-6 col-12"
      >
        <CustomInput
          :name="'commission'"
          type="number"
          v-model="request.commission"
          :label="'Commission'"
          :placeholder="'Enter a Commission'"
          :error="formErrors.commission"
          :handleError="handleError"
        />
      </div>

      <div
        v-if="category.api_service_category === 'Electricity'"
        class="col-12"
      >
        <label class="input-item-label">Meter Type</label>
        <div class="row">
          <div class="col-6">
            <div class="form-group mt-1">
              <label class="ckbox">
                <input type="checkbox" v-model="request.metadata.prepaid" />
                <span style="font-size: 15px">Prepaid</span>
              </label>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group mt-1">
              <label class="ckbox">
                <input type="checkbox" v-model="request.metadata.postpaid" />
                <span style="font-size: 15px">Postpaid</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <FormButtons :loading="loading" :handleCloseModal="handleCloseModal" />
      </div>
    </form>
  </Modal>
</template>
<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import Modal from "../../../../components/utils/Modal/Modal";
import FormButtons from "../../../../components/utils/FormButtons/FormButtons";
import CustomInput from "../../../../components/utils/CustomInput/CustomInput";
import CustomSelect from "../../../../components/utils/CustomSelect/CustomSelect";
import SuccessState from "../../../../components/utils/ResponseState/SuccessState";
import { formValidator } from "../../../../components/utils/FormValidator/FormValidator";
import Spinner from "../../../../components/utils/Spinner/Spinner";
import { countryCodes } from "@/assets/js/countryDailcodes";

const initialState = () => ({
  request: {
    charge_type: "",
    api_service_category_id: "",
    service_label: "",
    service_url: "",
    price_per_call: "",
    fail_over_price: "",
    country_name: "",
    commission: null,
  },
  loading: false,
  successMsg: false,
  formErrors: {},
});

export default {
  mixins: [notifications],
  name: "AddServiceModal",
  data() {
    return {
      ...initialState(),
      countryCodes,
      request: {
        ...initialState().request,
        metadata:
          this.category.api_service_category === "Electricity"
            ? {
                prepaid: true,
                postpaid: true,
                isElectricity: true,
              }
            : null,
      },
    };
  },
  props: ["id", "closeModal", "service", "category", "countryList"],
  components: {
    Modal,
    CustomInput,
    SuccessState,
    CustomSelect,
    Spinner,
    FormButtons,
  },
  methods: {
    handleCloseModal() {
      this.successMsg = false;
      this.closeModal();
      this.setServiceData();
    },
    async handleRefetch() {
      this.handleCloseModal();
      
      this.setServiceData();
    },
    handleSubmit(e) {
      e.preventDefault();
      const payload = {
        ...this.request,
      };
      
      if (this.chargeTypeIsFlatCommissionInclusiveOrExclusive) {
        payload.commission = this.request.commission;
      } else {
        delete payload.commission;
      }
      payload.charge_type = payload.charge_type.value
        ? payload.charge_type.value
        : payload.charge_type;

      if (!payload.fail_over_price) delete payload.fail_over_price;
      if (!this.service) delete payload.api_service_category_id;
      if (this.category.api_service_category !== "Electricity") delete payload.metadata;

      const { formErrors, isValid } = formValidator(payload);
      if (isValid)
        this.service
          ? this.updateService(payload)
          : this.createService(payload);
      else this.formErrors = formErrors;
    },
    handleError(name) {
      this.formErrors = { ...this.formErrors, [name]: "" };
    },
    showError(msg) {
      this.loading = false;
      this.showErrorLoginNotification(msg);
    },
    getLabel: (val) => {
      return val.api_service_category;
    },

    async createService(payload) {
      try {
        this.loading = true;
        const response = await this.$store.dispatch(
          "infrastructureProviders/createApiService",
          {
            ...payload,
            api_service_category_id: this.category.id,
            country_name: payload.country_name.name,
            country_code: payload.country_name.dial_code,
          }
        );
        if (response.status_code === 200) {
          await this.$store.dispatch("getMetaData");
          this.loading = false;
          this.successMsg = true;
        } else this.showError("Error Creating Service");
      } catch (error) {
        this.showError(error.message);
      }
    },

    async updateService(payload) {
      try {
        this.loading = true;
        const response = await this.$store.dispatch(
          "infrastructureProviders/updateApiService",
          {
            id: this.service.id,
            payload: {
              ...payload,
              api_service_category_id: payload.api_service_category_id.id,
              country_name: payload.country_name.name,
              country_code: payload.country_name.dial_code,
              metadata: JSON.stringify(payload?.metadata),
            },
          }
        );
        if (response.status_code === 200) {
          await this.$store.dispatch("getMetaData");
          this.loading = false;
          this.successMsg = true;
        } else this.showError("Error Updating Service");
      } catch (error) {
        this.showError(error.message);
      }
    },

    setServiceData() {
      if (this.service) {
        this.request.api_service_category_id = this.category;
        this.request.service_label = this.service.service_label;
        this.request.service_url = this.service.service_url;
        this.request.price_per_call = this.service.price_per_call;
        this.request.fail_over_price = this.service.fail_over_price;
        this.request.charge_type = this.service.charge_type;
        this.request.commission = this.service.commission;
        this.request.country_name = this.getCountry();
        this.request.metadata.postpaid = JSON.parse(
          this.service?.metadata
        )?.postpaid;
        this.request.metadata.prepaid = JSON.parse(
          this.service?.metadata
        )?.prepaid;
      }
    },
    getCountry() {
      const country = this.countryCodes.filter(
        (country) => country.name === this.service.country_name
      );
      return country;
    },
  },
  computed: {
    getApiServices() {
      return this.$store.state.metaData.apiServiceCategories;
    },
    chargeTypes() {
      return this.$store.state.services.chargeTypes.map((chargeType) => {
        return {
          label: chargeType.replace(/_/g, " "),
          value: chargeType,
        };
      });
    },
    chargeTypeIsFlatCommissionInclusiveOrExclusive() {
      return [
        "flat_commission_inclusive",
        "flat_commission_exclusive",
      ].includes(this.request.charge_type);
    },
  },
  mounted() {
    this.setServiceData();
  },
  watch: {
    countryList() {
      if (!this.service) return;
      this.request.country_name = this.getCountry();
    },
  },
};
</script>