import { apiClient } from "./api";

export default {
  BVNVerification(applicationDetails) {
    console.log("[application] ->", applicationDetails);
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `IdentityVerification/BVN`,
      applicationDetails.payload,
      { headers }
    );
  },
  PVCVerification(applicationDetails) {
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `IdentityVerification/PVC`,
      applicationDetails.payload,
      { headers }
    );
  },
  NINVerification(applicationDetails) {
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `IdentityVerification/NIN`,
      applicationDetails.payload,
      { headers }
    );
  },
  DLVerification(applicationDetails) {
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `IdentityVerification/DriverLicence`,
      applicationDetails.payload,
      { headers }
    );
  },
  NIPVerification(applicationDetails) {
    let headers = {
      appname: applicationDetails.appname,
      apikey: applicationDetails.apikey,
      clientid: applicationDetails.clientID,
    };
    return apiClient.post(
      `IdentityVerification/NIP`,
      applicationDetails.payload,
      { headers }
    );
  },
  GetServiceCategoriesWithServices(applicationDetails) {
    let headers = {
    };
    if(applicationDetails?.clientid) headers.clientid = applicationDetails.clientid
    return apiClient.get(`Authorize/FetchApiServiceCategoriesWithServices`, {headers});
  },
  UpdateServiceCategoriesWithServices(applicationDetails) {
    let headers = {
      clientid: applicationDetails.clientid,
    };
    return apiClient.put(
      `Authorize/Admin/EditCustomerService`,
      applicationDetails.payload,
      { headers }
    );
  },
  GetSubscriptionPlans(query) {
    return apiClient.get(`Authorize/Admin/Subscription/Plans`, query);
  },
  GetSubscriptionPlanDetails(query) {
    return apiClient.get(`Authorize/Admin/Subscription/Plan/${query.id}`);
  },
  CreateSubscriptionPlan(applicationDetails) {
    return apiClient.post(
      `Authorize/Admin/Subscription/CreatePlan`,
      applicationDetails.payload,
    );
  },
  UpdateSubscriptionPlan(applicationDetails) {
    return apiClient.put(
      `https://dev-api.blusalt.net/v2/Authorize/Admin/Subscription/UpdatePlan/${applicationDetails.id}`,
      applicationDetails.payload,
    );
  },
};
