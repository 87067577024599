<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn--primary px-4 ml-3"
			@click="openAddCreditModal"
		>
			Add Credit
		</button>
		<AddCreditModal
			:id="'addCredit'"
			:clientid="clientid"
			:closeModal="closeModal"
			:refetch="refetch"
		/>
	</div>
</template>

<script>
import AddCreditModal from "../../modals/AddCreditModal/AddCreditModal";

export default {
	name: "AddCreditButton",
	props: ["clientid", "refetch"],
	methods: {
		openAddCreditModal() {
			$("#addCredit").modal("show");
		},
		closeModal() {
			$("#addCredit").modal("hide");
		},
	},
	components: {
		AddCreditModal,
	},
};
</script>
