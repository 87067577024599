<template>
  <div class="contact-details--card">
    <div class="contact-details--card-row">
      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">Name</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">{{user.firstname}} {{user.lastname}}</div>
        </span>
      </div>

      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">First Name</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">{{user.firstname}}</div>
        </span>
      </div>

      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">Last Name</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">{{user.lastname}}</div>
        </span>
      </div>
    </div>

    <div class="contact-details--card-row">
      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">Email</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">{{user.email}}</div>
        </span>
      </div>

      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">Country</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">{{user.country.name}}</div>
        </span>
      </div>

      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">State</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">{{user.state.state_label}}</div>
        </span>
      </div>
    </div>

    <div class="contact-details--card-row">
      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">City</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">{{user.city}}</div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.state.account.profile
    }
  }
}
</script>

<style>
</style>