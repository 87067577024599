<template>
  <SideModal
    :show="show"
    :toggle="toggle"
    :title="`SMS Details`"
    :subTitle="'All informations about SMS'"
  >
    <div class="row mt-2 pt-4">
      <div class="col-12 mt-2">
        <p>
          <span class="text-muted text-uppercase">Reference id</span> <br />
          <span class="text-capitalize h5">
            {{ singleSMSObj.referenceId }}
          </span>
        </p>
      </div>
      <div class="col-6">
        <p>
          <span class="text-muted text-uppercase">date created</span> <br />
          <span class="text-capitalize h5">
            {{ singleSMSObj.createdAt | moment(" MM-DD-YYYY h:mm a") }}
          </span>
        </p>
      </div>
      <div class="col-6">
        <span
              class="badge badge-app text-capitalize"
              :class="
                singleSMSObj.status == 'sent' ? 'badge-success' : 'badge-danger'
              "
            >
              {{ singleSMSObj.status }}
            </span>
      </div>
      <br />
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">Sender Id</span> <br />
          <span class="text-capitalize h5">
            {{ singleSMSObj.senderId }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">Phone Number</span>
          <br />
          <span class="text-capitalize h5">{{
            titleCase(singleSMSObj.number)
          }}</span>
        </p>
      </div>
    </div>
    <br />
    <hr />
    <br />
  </SideModal>
</template>

<script>
import { notifications } from "../../../../public/assets/mixins/notifications";
import SideModal from "../../../components/utils/Modal/SideModal.vue";
import { formatNumber, titleCase } from "../../../components/utils/helpers";

export default {
  mixins: [notifications],
  name: "SMSLogModal",
  data() {
    return {};
  },
  props: ["toggle", "show", "singleSMSObj"],
  components: {
    SideModal,
  },
  methods: {
    formatNumber,
    titleCase,
    handleCloseModal() {
      this.toggle();
    },
  },
};
</script>

<style scoped>
.d-flex {
  display: flex;
  justify-content: flex-end;
}
</style>
