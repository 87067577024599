<template>
  <div>
    <PaymentTransactionDetailModal :show="showTransactionModal" :toggle="toggleTransactionModal"
      :singleTransactionObj="singleTransactionObj" :refetch="refetch" />
    <div class="px-3">
      <FilterWrapper>
        <template slot="filterForm">
          <MerchantWalletFilter :loading="loading" :rLoading="rLoading" :submitFilter="refetch"
            :clearFilter="() => clearFilter('walletsRef')" />
        </template>
      </FilterWrapper>
    </div>
    <TableWrapper :loading="loading" :errorMsg="errorMsg" :refetch="fetchFTProviderTransactions" :isEmpty="isEmpty"
      :emptyTitle="emptyTitle">
      <div class="w-100 py-3" slot="content">
        <v-server-table ref="walletsRef" class="v-table row-pointer" :data="customers" :columns="columns"
          :options="options" @loading="setLoading(true)" @loaded="onLoaded" @row-click="handleClick">
          <template slot="source" slot-scope="{ row }">
            <p class="mb-0 ">
              {{ titleCase(row.source) || "Null" }}
            </p>
          </template>
          <template slot="type" slot-scope="{ row }">
            <p class="mb-0 ">
              {{ titleCase(row.type) || "Null" }}
            </p>
          </template>
          <template slot="amount" slot-scope="{ row }">
            &#8358;{{ formatNumber(row.amount) || "0" }}
          </template>
          <template slot="charges" slot-scope="{ row }">
            &#8358;{{ formatNumber(row.charges) || "0" }}
          </template>
          <div slot="status" slot-scope="{ row }">
            <span class="badge badge-app" :class="row.status == 'successful' ? 'badge-success' : 'badge-danger'
            ">
              {{ titleCase(row.status) }}
            </span>
          </div>
          <div slot="accountName" slot-scope="{ row }">
            <span v-if="row.metadata.merchantName">
              {{ row.metadata.merchantName }}
            </span>
          </div>

          <template slot="updatedAt" slot-scope="{ row }">
            {{ row.updatedAt | moment(" MM-DD-YYYY h:mm a") }}
          </template>
        </v-server-table>
      </div>
    </TableWrapper>
  </div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../../components/utils/TableWrapper/TableWrapper.vue";
import MerchantWalletFilter from "../../../../components/filters/MerchantWalletFilter";
import { formatNumber } from "../../../../components/utils/helpers";
import PaymentTransactionDetailModal from "../../modals/PaymentTransactionDetailModal.vue";


const tableHeadings = {
  reference: "Transaction ID",
  amount: "Amount",
  charges: "Charges",
  source: "Method",
  type: "Payment Source",
  status: "Status",
  updatedAt: "Date Created",
  updatedAt: "",
};

export default {
  name: "Services",
  mixins: [componentData],
  data() {
    return {
      singleTransactionObj: null,
      showTransactionModal: false,
      emptyTitle: "No Data Found",
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(tableHeadings, 25),
    };
  },
  components: {
    TableWrapper,
    MerchantWalletFilter,
    PaymentTransactionDetailModal,
  },
  methods: {
    toggleTransactionModal() {
      this.showTransactionModal = !this.showTransactionModal;
    },
    handleClick({ row }) {
      this.singleTransactionObj = row;
      this.showTransactionModal = true;
    },
    refetch(filters) {
      let newFilters = { ...filters };
      if (newFilters.status) newFilters.status = newFilters.status.value;
      this.handleRefetch(
        newFilters,
        this.fetchFTProviderTransactions,
        "walletsRef"
      );
    },
    async fetchFTProviderTransactions() {
      this.options.requestFunction = async (data) => {
        const params = {
          ...data,
          ...this.filters,
          provider_slug: this.$route.params.id,
        };
        const response = await this.$store.dispatch(
          "payment/getProviderTransactions",
          {
            params,
          }
        );

        const newData = response.data.data;
        const count = response.data.total;
        return { ...response, count, total: count, data: newData };
      };
    },
    formatNumber,
  },

  props: ["clientid"],
  created() {
    this.fetchFTProviderTransactions();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>