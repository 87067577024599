<template>
  <div class="px-3">
    <div class="p-2 mx-2 mt-3 bg-light-blue">Personal Information</div>
    <div class="p-4">
      <div class="row table-like-header">
        <div class="col-4 p-2">First Name</div>
        <div class="col-4 p-2">Last Name</div>
        <div class="col-4 p-2">Middle Name</div>
      </div>
      <div class="row table-like-body">
        <div class="col-4 p-2">{{ details.first_name || "N/A" }}</div>
        <div class="col-4 p-2">{{ details.last_name || "N/A" }}</div>
        <div class="col-4 p-2">{{ details.middle_name || "N/A" }}</div>
      </div>
      <div class="row table-like-header">
        <div class="col-4 p-2">Phone Number</div>
        <div class="col-4 p-2"></div>
      </div>
      <div class="row table-like-body border-bottom">
        <div class="col-4 p-2">{{ details.phone_number || "N/A" }}</div>
        <div class="col-4 p-2"></div>
      </div>
    </div>
    <div class="p-2 mx-2 mt-3 bg-light-blue">Address Details</div>
    <div class="p-4 pt-0">
      <div class="row table-like-header">
        <div class="col-4 p-2">Flat Number</div>
        <div class="col-4 p-2">Building Name</div>
        <div class="col-4 p-2">Building Number</div>
      </div>
      <div class="row table-like-body">
        <div class="col-4 p-2">
          <span class="rounded text-primary p-1">{{
            details.flat_number || "N/A"
          }}</span>
        </div>
        <div class="col-4 p-2">{{ details.building_name || "N/A" }}</div>
        <div class="col-4 p-2">{{ details.building_number || "N/A" }}</div>
      </div>
      <div class="row table-like-header">
        <div class="col-4 p-2">Landmark</div>
        <div class="col-4 p-2">Address 1</div>
        <div class="col-4 p-2">Address 2</div>
      </div>
      <div class="row table-like-body border-bottom">
        <div class="col-4 p-2">{{ details.address_landmark || "N/A" }}</div>
        <div class="col-4 p-2">{{ details.address_line1 || "N/A" }}</div>
        <div class="col-4 p-2">
          {{ details.address_line2 || "N/A" }}
        </div>
      </div>
      <div class="row table-like-header">
        <div class="col-4 p-2">Country</div>
        <div class="col-4 p-2">City</div>
        <div class="col-4 p-2">State/Region</div>
      </div>
      <div class="row table-like-body border-bottom">
        <div class="col-4 p-2">
          {{ details.country || "N/A" }}
        </div>
        <div class="col-4 p-2">{{ details.address_city || "N/A" }}</div>
        <div class="col-4 p-2">{{ details.address_state || "N/A" }}</div>
      </div>
      <div class="row table-like-header">
        <div class="col-4 p-2">Description</div>
        <div class="col-4 p-2"></div>
        <div class="col-4 p-2"></div>
      </div>
      <div class="row table-like-body border-bottom">
        <div class="col-4 p-2">
          {{ details.subscribed_share_capital || "N/A" }}
        </div>
        <div class="col-4 p-2">{{ details.shares_issued || "N/A" }}</div>
        <div class="col-4 p-2">{{ details.share_capital || "N/A" }}</div>
      </div>
    </div>
    <div class="p-2 mx-2 mt-3 bg-light-blue">Address Location</div>
    <iframe
      class="iframe mx-3"
      height="470"
      frameborder="0"
      scrolling="no"
      marginheight="0"
      marginwidth="0"
      :src="
        `https://maps.google.com/maps?q=${details.latitude},${details.longitude}&hl=es&z=14&amp;output=embed`
      "
    >
    </iframe>
    <div class="p-4">
      <div class="row table-like-header">
        <div class="col-4 p-2">Latitude</div>
        <div class="col-4 p-2">Longitude</div>
        <div class="col-4 p-2"></div>
      </div>
      <div class="row table-like-body">
        <div class="col-4 p-2">{{ details.latitude || "N/A" }}</div>
        <div class="col-4 p-2">{{ details.longitude || "N/A" }}</div>
        <div class="col-4 p-2"></div>
      </div>
    </div>
    <div class="p-2 mx-2 mt-3 bg-light-blue">Images</div>
    <div class="row">
      <div class="col-md-4"><img class="img-fluid" :src="details.home_image" alt="img"  /></div>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import "../style.css";

export default {
  name: "ContactInformation",
  mixins: [componentData],
  data() {
    return {
      details: null,
      loading: false,
      size: 25,
      filters: {},
      ...this.tableOptions({ tableHeadings }, 20),
    };
  },
  components: {},
  computed: {
    details() {
      return this.$store.state.logs.reportDetails;
    },
  },
};
</script>

<style>
.row {
  font-size: 15px;
}
.table-like-header {
  background: #f9f9f9;
}
.table-like-body {
  font-weight: 100;
}
.iframe {
  width: -webkit-fill-available;
}
.img-fluid{
    height: 300px;

}
</style>
