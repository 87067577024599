<template>
  <Loader v-if="isLoading == true"></Loader>
  <div class="az-content-body" v-else>
    <div class="row">
      <div class="col-lg-12">
        <div class="SectionItem no-b-b mb-3">
          <div class="SectionHeader">
            <span class="title">Account</span>
            <div class="description">Customize advanced settings for conversations</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-12 col-xl-12">
        <nav class="nav az-nav-line nav-line-user-account mg-b-40">
          <a href="#profile" data-toggle="tab" class="nav-link active show">Profile</a>
          <a href="#billing" data-toggle="tab" class="nav-link" v-if="false">Billings</a>
          <a href="#activity" data-toggle="tab" class="nav-link" v-if="false">Activity Log</a>
        </nav>
      </div>
    </div>

    <div class="tab-content">
      <div id="profile" class="row tab-pane active show">
        <div class="col-lg-12 col-md-12">
          <div class="user-details--wrapper">
            <div class="user-details-left-header">
              <div class="user-details-info">
                <div class="user-details-avatar user-avatar online" v-if="false">
                  <img src="/assets/img/avatar1.png" alt />
                </div>
                <div class="user-details--top-info">
                  <h2 class="user-profile-name">{{user.firstname}} {{user.lastname}}</h2>
                  <h5 class="user-profile-email">{{user.email}}</h5>
                </div>
              </div>
              <div>
                <a
                  href="#"
                  @click="toShow = true"
                  v-if="toShow == false"
                  class="btn btn-lg btn-light"
                >Edit Profile</a>
                <a
                  href="#"
                  v-if="toShow == true"
                  @click="toShow = false"
                  class="btn btn-lg btn-danger"
                >Cancel</a>
              </div>
            </div>
            <transition mode="out-in" name="fade">
              <AccountProfileEdit @closeEdit="closeEdit" v-if="toShow == true" :apiUser="apiUser"></AccountProfileEdit>
              <AccountProfileInfo v-else></AccountProfileInfo>
            </transition>
          </div>

          <!-- CHange Password  -->
          <div class="user-settings-section">
            <div class="user-settings-section-header">
              <div class="user-settings-section-header-text">
                <h1>Change password</h1>
                <span class="text-span">Update your secret combination of letters and numbers</span>
              </div>
              <div class>
                <a
                  @click="showPasswordEdit = !showPasswordEdit"
                  class="btn btn-lg btn-light"
                >Reset Password</a>
              </div>
            </div>

            <transition mode="out-in" name="fade">
              <div class="user-setting-password-edit" :class="{'closed': !showPasswordEdit }">
                <form @submit.prevent="changePassword">
                  <div class="row mg-b-10">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Current password</label>
                        <input
                          type="text"
                          class="form-control input-bordered"
                          v-model="PM.currentPassword"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mg-b-10">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">New password</label>
                        <input
                          type="password"
                          class="form-control input-bordered"
                          v-model="PM.password"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Confirm new password</label>
                        <input
                          type="password"
                          class="form-control input-bordered"
                          v-model="PM.confirmPassword"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button type="submit" class="btn btn-primary">Change password</button>
                      <button
                        @click="clearPassword"
                        type="button"
                        class="btn btn-outline-danger ml-2"
                      >Cancel</button>
                    </div>
                  </div>
                </form>
              </div>
            </transition>
          </div>

          <!-- Deactivate Account -->
          <div class="user-settings-section" v-if="false">
            <div class="user-settings-section-header">
              <div class="user-settings-section-header-text">
                <h1>Deactivate account</h1>
                <span
                  class="text-span"
                >This will remove your account from all teams and disable your account.</span>
              </div>
              <div class>
                <a href="#" class="btn btn-lg btn-outline-danger">Deactivate Account</a>
              </div>
            </div>
          </div>

          <div class="gaps-4x"></div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Nprogress from "nprogress"
import AccountBilling from '@/components/account/AccountBilling';
import AccountProfileInfo from '@/components/account/AccountProfileInfo';
import AccountProfileEdit from '@/components/account/AccountProfileEdit';
import AccountActivity from '@/components/account/AccountActivity';


import { notifications } from '../../public/assets/mixins/notifications';
export default {
  mixins: [notifications],
  name: "Account",
  components: {

    AccountProfileInfo,
    AccountProfileEdit
  },

  data() {
    return {
      isLoading: true,
      apiUser: {},

      toShow: false,
      showPasswordEdit: false,

      PM: {
        password: '',
        currentPassword: '',
        confirmPassword: ''
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.account.profile
    }
  },
  methods: {
    closeEdit() {
      this.toShow = !this.toShow
    },
    clearPassword() {
      this.PM.currentPassword = ''
      this.PM.password = ''
      this.PM.confirmPassword = ''

      this.showPasswordEdit = !this.showPasswordEdit
    },
    async changePassword() {
      let credentials = {
        old_password: this.PM.currentPassword,
        new_password: this.PM.password,
        confirm_new_password: this.PM.confirmPassword
      }

      try {
        let response = await this.$store.dispatch('account/changePassword', credentials)
        this.showSuccessNotification(response.message)
        console.log('[response from password change] ->', response);
        this.clearPassword()
        Nprogress.done();
      } catch (error) {
        console.log('[errors from password change] ->', error);
        this.showErrorRegistrationNotification(error.message)
        Nprogress.done();
      }
    }
  },
  async mounted() {
    try {
      let apiResponse = await this.$store.dispatch('account/getProfile')
      this.apiUser = { ...apiResponse }
      this.isLoading = false
    } catch (error) {
      console.log(error)
    }
  }
}
</script>