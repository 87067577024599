<template>
  <div class="col-md-12 col-lg-12 col-xl-12">
    <div class="payment-details--wrapper no-flex">
      <div class="flex-wrapper-space-between">
        <div class="payment-details--info">
          <h2 class="payment-section-hdr">Remaining account credits</h2>
          <div class="text-span">Earn more credit through our referral program</div>
        </div>
        <div class="balance-container">
          <h1 class="payment-balance">$60.62</h1>
          <!-- <a href="#" class="btn btn-lg btn-primary">Add test credit</a> -->
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <hr />
        </div>
      </div>

      <div class="flex-wrapper-space-between">
        <div class="payment-details--info">
          <h2 class="payment-section-span">
            Test credit
            <span class="badge-inverted ml-2 text-uppercase">Admin</span>
          </h2>
          <div class="text-span">Earn more credit through our referral program</div>
        </div>
        <div>
          <a href="#" class="btn btn-lg btn-outline-primary mg-t-15">Add test credit</a>
        </div>
      </div>
    </div>

    <div class="payment-details--wrapper no-flex">
      <div class="payment-details--info">
        <h2 class="payment-section-hdr">Payment methods</h2>
        <div
          class="text-span"
        >Please enter your preferred payment method below. You can use a credit / debit card, bank account or prepay through PayPal.</div>
      </div>
      <nav class="nav az-nav-line nav-line-user-account mg-t-35" style="top: -1px;">
        <a href data-toggle="tab" class="nav-link active show">Credit / Debit Card</a>
        <a href data-toggle="tab" class="nav-link">Bank Account</a>
        <a href data-toggle="tab" class="nav-link">PayPal</a>
      </nav>
      <div class="payment-details-box">
        <form method>
          <p
            class="text-span"
          >Your card will not be charged until you start using paid services on blusalt.</p>
          <div class="gaps-2x"></div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="input-item-label">Card Number</label>
                <input
                  id
                  type="text"
                  placeholder
                  v-model="cardNumber"
                  class="form-control input-bordered card-number"
                  :class="{ 'mastercard' : cardNumber.startsWith('5'),  'visa' : cardNumber.startsWith('4')}"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label class="input-item-label">Date of Expiry</label>
                <input type="text" placeholder="MM/YY" class="form-control input-bordered" />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label class="input-item-label">
                  CVC / CVV
                  <span class="card-hint">
                    <svg width="14" height="14" viewBox="0 0 20 20" aria-hidden="true">
                      <title />
                      <g id="Layer_4">
                        <path
                          d="M11,12.3V13c0,0-1.8,0-2,0v-0.6c0-0.6,0.1-1.4,0.8-2.1c0.7-0.7,1.6-1.2,1.6-2.1c0-0.9-0.7-1.4-1.4-1.4 c-1.3,0-1.4,1.4-1.5,1.7H6.6C6.6,7.1,7.2,5,10,5c2.4,0,3.4,1.6,3.4,3C13.4,10.4,11,10.8,11,12.3z"
                        />
                        <circle cx="10" cy="15" r="1" />
                        <path
                          d="M10,2c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S5.6,2,10,2 M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0 L10,0z"
                        />
                      </g>
                    </svg>
                  </span>
                </label>
                <input type="text" placeholder class="form-control input-bordered" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="input-item-label">Cardholder Name</label>
              <input type="text" placeholder class="form-control input-bordered" />
            </div>
          </div>
          <div class="gaps-3x"></div>
          <div class="row">
            <div class="col-md-12">
              <button type="button" class="btn btn-primary btn-block" disabled>
                <i class="la la-lock"></i> Save Card
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      'cardNumber': '',
      customers: [
        {
          account_type: "customer",
          app_id: "V312908",
          create_at: "1",
          fullname: "Abiodun Emmanuel",
          email: "Ayemitiboabiodun02@gmail.com",
          phone_number: "08088445544",
        },
        {
          account_type: "customer",
          app_id: "T334490",
          create_at: "1",
          fullname: "Ayemitibo Emmanuel",
          email: "aye02@gmail.com",
          phone_number: "08088445544",
        },

        {
          account_type: "customer",
          app_id: "P234544",
          create_at: "1",
          fullname: "Tunmise Adenuga",
          email: "tunmise@gmail.com",
          phone_number: "09021743695",
        },
        {
          account_type: "customer",
          app_id: "P234544",
          create_at: "1",
          fullname: "Shakirah Hassan",
          email: "shakirah.hassan@gmail.com",
          phone_number: "09021743695",
        },
        {
          account_type: "customer",
          app_id: "P268544",
          create_at: "1",
          fullname: "Gbenga Agoro",
          email: "gbenga.a@gmail.com",
          phone_number: "09021743695",
        },
        {
          account_type: "merchant",
          app_id: "k456342",
          create_at: "1",
          fullname: "Al-Ameen Ipaye",
          email: "al-ameen@gmail.com",
          phone_number: "09021743695",

        }
      ],
      columns: ['fullname', 'email', 'phone_number', "app_id"],
      options: {
        sortable: [],
        perPage: 5,
        'pagination': {
          chunk: 3,
        },
        texts: {
          filter: '',
          filterPlaceholder: 'Search..',
          noResults: 'No matching records'
        },
        headings: {
          'fullname': 'Full Name',
          'email': 'Email',
          'phonenumber': 'Phone Number',
          create_at: 'Actions',
        }
      },
    }
  }
}
</script>

<style>
</style>