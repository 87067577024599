<template>
	<div class="service-content accordion-content">
		<div v-if="getParams.length > 0">
			<v-client-table
				class="v-table"
				:data="getParams"
				:columns="columns"
				:options="options"
			>
				<template slot="route" slot-scope="{ row }">
					{{ truncate(row.route, 55) }}
					<img
						v-if="row.route"
						src="/assets/img/copy.svg"
						class="pointer ml-3"
						alt=""
						@click="copy(row.route)"
					/>
				</template>
				<template slot="price" slot-scope="{ row }">
					{{ toCurrency(row.price) }}
				</template>
				<template slot="action" slot-scope="{ row }">
					<UpdateProviderParametersButton
						:parameter="row"
						:provider="provider"
						:refetch="refetch"
					/>
				</template>
			</v-client-table>
		</div>
		<div v-else class="text-danger text-center pt-2">
			<h5 class="description">No service found under this provider</h5>
		</div>
	</div>
</template>

<script>
	import { notifications } from "../../../../../public/assets/mixins/notifications";
	import UpdateProviderParametersButton from "../../buttons/UpdateProviderParametersButton/UpdateProviderParametersButton";

	import {
		tableOptions,
		toCurrency,
		copyToClipboard,
		truncate,
	} from "../../../../components/utils/helpers";

	const tableHeadings = {
		route: "Route",
		price: "Cost",
		http_method: "HTTP Method",
		priority: "Priority",
		action: "Action",
	};

	export default {
		mixins: [notifications],
		name: "ProviderParameters",
		data() {
			return {
				params: [],
				...tableOptions(tableHeadings, 25),
			};
		},
		props: ["provider", "providersParams", "refetch"],
		components: {
			UpdateProviderParametersButton,
		},
		methods: {
			toCurrency,
			truncate,
			async copy(route) {
				const copied = copyToClipboard(route);
				copied && this.showSuccessNotification("Route Copied!");
			},
		},
		computed: {
			getParams() {
				if (this.provider && this.providersParams) {
					return this.providersParams.filter(
						({ provider_id }) => provider_id == this.provider.id
					);
				}
				return [];
			},
		},
		mounted() {
			this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
		},
	};
</script>
