<template>
  <div class="filters-wrapper">
    <div class="d-flex justify-content-between">
      <div class="filter-icon" @click="toggle">
        <img src="/assets/img/icons/filter.svg" alt="Click to toggle filters" />
        <h5 class="mb-0 ml-3">Filter By</h5>
      </div>

      <slot name="pageSelect" />
    </div>

    <slot name="filterForm" />
  </div>
</template>

<script>
export default {
  name: "FilterWrapper",
  methods: {
    toggle() {
      JQuery("form").toggleClass("active");
    },
  },
};
</script>

<style>
.filters-wrapper form {
  max-height: 0;
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.filters-wrapper form.h-100 {
  max-height: unset;
  opacity: 1;
}

.filters-wrapper form.active {
  opacity: 1;
  max-height: 500px;
}
.filters-wrapper .filter-icon {
  display: flex;
  align-items: center;
  border: 1px solid #efefef;
  border-radius: 4px;
  width: 115px;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 15px;
}
</style>
