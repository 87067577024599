<template>
	<div id="editApplication" class="modal">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content modal-content-demo">
				<div class="modal-body">
					<button
						type="button"
						class="close pos-absolute t-20 r-30 tx-26"
						data-dismiss="modal"
						aria-label="Close"
						id="close-editApplication"
					>
						<span aria-hidden="true">&times;</span>
					</button>
					<h5 class="modal-title mg-b-5">Update Application</h5>
					<p>
						Let's get you all setup so you can begin using our app.
					</p>
					<div class="b-b"></div>
					<div class="gaps-2x"></div>
					<form @submit.prevent="editApplication">
						<!-- CLient ID -->
						<div class="form-group">
							<label class="input-item-label">Client ID</label>
							<input
								type="text"
								disabled
								class="form-control input-bordered"
								:placeholder="clientID"
							/>
						</div>
						<!-- Application Name -->
						<div class="form-group">
							<label class="input-item-label"
								>Application Name</label
							>
							<input
								type="text"
								class="form-control input-bordered"
								placeholder
								v-model="modalData.app_name"
							/>
						</div>

						<!-- Application Services -->
						<div class="form-group">
							<label class="input-item-label">Services</label>
							<v-select
								class="v-select h-auto"
								placeholder="Select a service"
								v-model="modalData.app_services"
								@keydown.enter.native.prevent
								:options="userServices"
								label="service_label"
								multiple
							></v-select>
						</div>

						<div class="form-group mt-4">
							<label class="ckbox">
								<input type="checkbox" v-model="unlimited" />
								<span style="font-size: 15px"
									>Do you want to cap the credit</span
								>
							</label>
							<span class="input-note"
								>Note: Setting a cap to your API credit limit
								would limit the the credit limit on the
								app.</span
							>
						</div>

						<!-- Application Credit limit
            <div class="form-group">
              <label class="input-item-label">API Credit Limit</label>
              <input
                type="text"
                class="form-control input-bordered"
                v-model="modalData.api_credit_limit"
                placeholder
              />
              <span
                class="input-note"
              >Note: Set a limit for your API calls which will be deducted from total system credit.</span>
            </div>

            <div class="gaps-2x"></div>
            <div class="form-group">
              <label class="input-item-label">Billing Type</label>
              <label class="rdiobox">
                <input
                  name="billing"
                  required
                  type="radio"
                  value="1"
                  v-model="modalData.billing_type_id"
                />
                <span class="fs-15">Core Billing ( You will be charged per call for any service )</span>
              </label>
              <div class="gaps-1x"></div>
              <label class="rdiobox">
                <input
                  name="billing"
                  required
                  type="radio"
                  value="2"
                  v-model="modalData.billing_type_id"
                />
                <span class="fs-15" v-if="modalData.billing_type_id == '2'">
                  Bundled Billing ( Services will be billed as a bundle, total bundle cost is
                  <strong>{{ getBundledPrice }}</strong>)
                </span>
                <span class="fs-15" v-else>Bundled Billing (Services will be billed as a bundle)</span>
              </label>
            </div>-->

						<div class="gaps-2x"></div>
						<button
							type="submit"
							class="btn btn-lg btn-primary"
							:disabled="isProcessing == true"
						>
							<span v-if="isProcessing">editing application</span>
							<span v-else>Update Application</span>
						</button>
						<button
							type="button"
							class="btn btn-lg btn-light ml-2"
							data-dismiss="modal"
						>
							Close
						</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Nprogress from "nprogress";
	import { required } from "vuelidate/lib/validators";
	import { notifications } from "../../../public/assets/mixins/notifications";

	export default {
		mixins: [notifications],
		props: {
			modalData: {
				type: Object,
			},
			servicePrices: {
				type: Array,
			},
			clientID: {
				type: String,
			},
		},
		data() {
			return {
				isProcessing: false,
				unlimited: null,
			};
		},
		computed: {
			getBundledPrice() {
				let total = 0;
				if (this.modalData.app_services) {
					for (let service of this.modalData.app_services) {
						total += service.price_per_call;
					}
				}
				return total;
			},
			userServices() {
				return this.servicePrices;
				// return this.$store.state.account.profile.api_customer.customerServicePrices
			},
			metaData() {
				return this.$store.state.metaData;
			},
		},
		methods: {
			formatCreateApplicationData() {
				let requestData = new FormData();

				if (this.unlimited == null || this.unlimited == 0) {
					requestData.append("is_credit_limited", 0);
				} else {
					requestData.append("is_credit_limited", 1);
				}
				requestData.append("app_name", this.modalData.app_name);

				if (this.modalData.app_services) {
					for (
						let index = 0;
						index < this.modalData.app_services.length;
						index++
					) {
						requestData.append(
							"service",
							this.modalData.app_services[index].id
						);
					}
				}

				return requestData;
			},
			closeModalSuccess() {
				let closeButton = document.getElementById(
					"close-editApplication"
				);
				closeButton.click();

				this.$emit("fetchCustomer");
			},
			async editApplication() {
				try {
					let requestPayload = {
						clientID: this.clientID,
						payload: this.formatCreateApplicationData(),
					};
					let application = await this.$store.dispatch(
						"application/adminEditApplication",
						{ requestPayload, id: this.modalData.id }
					);
					console.log("[] ->", application);
					this.showSuccessNotification(application.message);
					let self = this;
					setTimeout(() => {
						self.closeModalSuccess();
					}, 2500);
					Nprogress.done();
				} catch (error) {
					Nprogress.done();
					console.log("[error] ->", error);
					this.showErrorLoginNotification(error.message);
				}
			},
		},
	};
</script>
