<template>
  <div>
    <div v-if="loading" class="flex__center mt-5 pt-5">
      <Spinner :size="'30px'" />
    </div>
    <template v-else>
      <div v-if="pricing.services.length === 0" class="flex__center py-5">
        <div class="text-center pb-5">
          <i class="la la-xing la-4x" />
          <h4 class="font-weight-bold my-4">
            No pricing available for this merchant
          </h4>
        </div>
      </div>

      <div class="overflow-hidden rounded" v-else>
        <div class="row service-header align-items-center">
          <div class="col-md-3 py-2 px-4">Service</div>
          <div class="col-md-4 py-2 px-4">Charge Type</div>
          <div class="col-md-5 py-2 px-4">Charge Fee</div>
        </div>

        <template v-for="(service, index) in pricing.services">
          <div
            v-if="pricing.details[service].length"
            class="app-key-wrap accordion accordion-s2"
            :id="`provider${index}`"
            :key="index"
          >
            <div class="app-key-item-wrapper accordion-item">
              <div class="app-key-list-item accordion-heading">
                <div class="service-list">
                  <div
                    class="service-name"
                    data-toggle="collapse"
                    :data-target="`#serv${index}`"
                  >
                    <div class="service-icon">
                      <em class="app-icon la la-cube"></em>
                    </div>
                    <span>
                      {{ service }}
                    </span>
                  </div>
                </div>
              </div>
              <div :id="`serv${index}`" class="collapse" style>
                <PriceSetting :details="pricing.details[service] || {}" :getPaymentMethods="getPaymentMethods" />
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import PriceSetting from "./PriceSetting";
import "./PriceSettings.css";

export default {
  name: "PaymentPricing",
  mixins: [componentData],
  data() {
    return {
      loading: false,
      errorMsg: "",
      pricing: {},
    };
  },
  components: {
    PriceSetting,
  },
  methods: {
    transformResult(results) {
      const details = {
        "Local Cards": results["Local Cards"],
        "International Cards": results["International Cards"],
        "Bank Transfer": results["Bank Transfer"],
        "Bank Account": results["Bank Account"],
        USSD: results["USSD"],
        "POS Local Cards": results["PoS Local Cards"]
          ? results["PoS Local Cards"]
          : results["POS Local Cards"],
        "POS International Cards": results["PoS International Cards"]
          ? results["PoS International Cards"]
          : results["POS International Cards"],
        "Fund Transfer": results["Fund transfer"]
          ? results["Fund transfer"]
          : results["Fund Transfer"],
        Remittance: results["Remittance"],
      };
      const services = Object.keys(details);
      return { services, details };
    },
    async getPaymentMethods() {
      try {
        this.loading = true;
        const response = await this.$store.dispatch(
          "customer/getPaymentMerchantPaymentPricing",
          {
            params: { id: this.clientid },
          }
        );
        if (response.status === "Success") {
          this.loading = false;
          const result = this.transformResult(response.data.pricing);
          this.pricing = result;
        } else this.errorMsg = response.message;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.errorMsg = this.getError(error);
      }
    },
  },

  props: ["clientid"],
  mounted() {
    this.getPaymentMethods();
  },
};
</script>
