<template>
  <div slot="content" class="row">
    <div class="col-lg-12">
      <template>
        <div class="app-key-wrap accordion accordion-s3" id="applications3">
          <div class="app-key-item-wrapper accordion-item">
            <div class="app-key-list-item accordion-heading">
              <div class="service-list">
                <div
                  class="service-name"
                  data-toggle="collapse"
                  data-target="#app3"
                >
                  <div class="service-icon">
                    <em class="app-icon la la-cube"></em>
                  </div>
                  <span> Business Information </span>
                </div>
              </div>
            </div>
            <div
              id="app3"
              class="collapse pt-4"
              data-parent="#applications3"
              style
            >
              <div class="row px-4">
                <div class="col-md-8">
                  <div class="form-group d-flex">
                    <div class="col-md-6">
                      <label class="input-item-label">
                        Business Registration Number
                      </label>
                    </div>
                    <div class="col-md-6">
                      <span v-if="data.rc_number">
                        {{ data.rc_number }}
                      </span>
                      <span v-else> Not Available</span>
                    </div>
                  </div>
                  <div class="form-group d-flex">
                    <div class="col-md-6">
                      <label class="input-item-label">
                        Tax Identification Number
                      </label>
                    </div>
                    <div class="col-md-6">
                      <span v-if="data.tin">
                        {{ data.tin }}
                      </span>
                      <span v-else> Not Available</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template>
        <div class="app-key-wrap accordion accordion-s2" id="applications1">
          <div class="app-key-item-wrapper accordion-item">
            <div class="app-key-list-item accordion-heading">
              <div class="service-list">
                <div
                  class="service-name"
                  data-toggle="collapse"
                  data-target="#app1"
                >
                  <div class="service-icon">
                    <em class="app-icon la la-cube"></em>
                  </div>
                  <span> Business Documentation </span>
                </div>
              </div>
            </div>
            <div
              id="app1"
              class="collapse pt-4"
              data-parent="#applications1"
              style
            >
              <div class="row px-4">
                <div class="col-md-8">
                  <div class="form-group d-flex">
                    <div class="col-md-6">
                      <label class="input-item-label"
                        >Certificate of Incorporation</label
                      >
                    </div>
                    <div
                      class="col-md-6"
                      :class="{
                        'link pointer': data.certificate_of_incorporation,
                      }"
                    >
                      <a
                        v-if="data.certificate_of_incorporation"
                        :href="data.certificate_of_incorporation"
                        download="Proof of Address"
                        target="_blank"
                      >
                        Certificate of Incorporation Link
                      </a>
                      <span v-else> Not Available</span>
                    </div>
                  </div>
                  <div class="form-group d-flex">
                    <div class="col-md-6">
                      <label class="input-item-label">
                        Business Certificate
                      </label>
                    </div>
                    <div
                      class="col-md-6"
                      :class="{
                        'link pointer': data.form_cac,
                      }"
                    >
                      <a
                        v-if="data.form_cac"
                        :href="data.form_cac"
                        download="Proof of Address"
                        target="_blank"
                      >
                        Business Certificate Link
                      </a>
                      <span v-else> Not Available</span>
                    </div>
                  </div>
                  <div class="form-group d-flex">
                    <div class="col-md-6">
                      <label class="input-item-label"> Memom Mart </label>
                    </div>
                    <div
                      class="col-md-6"
                      :class="{
                        'link pointer': data.memorandum_articles_of_association,
                      }"
                    >
                      <a
                        v-if="data.memorandum_articles_of_association"
                        :href="data.memorandum_articles_of_association"
                        download="Proof of Address"
                        target="_blank"
                      >
                        Memom Mart Link
                      </a>
                      <span v-else> Not Available</span>
                    </div>
                  </div>
                  <div class="form-group d-flex">
                    <div class="col-md-6">
                      <label class="input-item-label">Proof of Address</label>
                    </div>
                    <div
                      class="col-md-6"
                      :class="{
                        'link pointer': data.proof_of_address,
                      }"
                    >
                      <a
                        v-if="data.proof_of_address"
                        :href="data.proof_of_address"
                        download="Proof of Address"
                        target="_blank"
                      >
                        Proof of Address Link
                      </a>
                      <span v-else> Not Available</span>
                    </div>
                  </div>
                  <div class="form-group d-flex">
                    <div class="col-md-6">
                      <label class="input-item-label">TIN Document</label>
                    </div>
                    <div
                      class="col-md-6"
                      :class="{
                        'link pointer': data.tin_document,
                      }"
                    >
                      <a
                        v-if="data.tin_document"
                        :href="data.tin_document"
                        download="TIN Document"
                        target="_blank"
                      >
                        TIN Document Link
                      </a>
                      <span v-else> Not Available</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template>
        <div class="app-key-wrap accordion accordion-s2" id="applications2">
          <div class="app-key-item-wrapper accordion-item">
            <div class="app-key-list-item accordion-heading">
              <div class="service-list">
                <div
                  class="service-name"
                  data-toggle="collapse"
                  data-target="#app2"
                >
                  <div class="service-icon">
                    <em class="app-icon la la-cube"></em>
                  </div>
                  <span> Director Information </span>
                </div>
              </div>
            </div>
            <div id="app2" class="collapse" data-parent="#applications2" style>
              <div class="mt-4 px-4" v-if="data.directors">
                <v-client-table
                  :data="data.directors"
                  :columns="columns"
                  :options="options"
                >
                  <template slot="id_type" slot-scope="props">
                    <div>
                      {{
                        props.row.id_type
                          ? titleCase(props.row.id_type)
                          : "Not Available"
                      }}
                    </div>
                  </template>
                  <template slot="action" slot-scope="props">
                    <div>
                      <i @click="handleRowClick(props.row)" class="pointer mr-2"
                        >View</i
                      >
                    </div>
                  </template>
                  <template slot="id_image" slot-scope="props">
                    <div>
                      <a
                        v-if="props.row.id_image"
                        :href="props.row.id_image"
                        :download="`${props.row.first_name} ID Document`"
                        target="_blank"
                        >ID Document</a
                      >
                      <template v-else>Not Available</template>
                    </div>
                  </template>
                  <template slot="address_proof" slot-scope="props">
                    <div>
                      <a
                        v-if="props.row.address_proof"
                        :href="props.row.address_proof"
                        :download="`${props.row.first_name} Proof of Address`"
                        target="_blank"
                        >Proof of Address</a
                      >
                      <template v-else>Not Available</template>
                    </div>
                  </template>
                </v-client-table>
              </div>
            </div>
          </div>
        </div>
      </template>
      <ComplianceDetailsModal
        :id="'details'"
        :closeModal="closeModal"
        :selected="selected"
      />
    </div>
  </div>
</template>
<script>
import {
  notifications,
  componentData,
} from "../../../../../public/assets/mixins";
import { tableOptions, titleCase } from "../../../../components/utils/helpers";
import ComplianceDetailsModal from "./ComplianceDetailsModal.vue";

const tableHeadings = {
  id: "Id",
  first_name: "Firstname",
  last_name: "Lastname",
  email: "Email",
  bvn: "BVN",
  address: "Address",
  id_image: "ID Document",
  address_proof: "Proof of Address",
  action: "",
};

export default {
  mixins: [notifications, componentData],
  name: "KYC",
  data() {
    return {
      selected: {},
      ...tableOptions(tableHeadings, 20),
      columns: [
        "first_name",
        "last_name",
        "email",
        "id_type",
        "address",
        "id_image",
        "address_proof",
        "action",
      ],
      response: [],
    };
  },
  methods: {
    titleCase,
    async fetchCompliance() {
      try {
        this.loading = true;
        let response = await this.$store.dispatch(
          "customer/getCustomerCompliance",
          this.$route.params.clientid
        );
        this.response = response.data || [];
        this.loading = false;
      } catch (error) {
        this.setError(error.response);
      }
    },
    openModal() {
      $("#details").modal("show");
    },
    closeModal() {
      $("#details").modal("hide");
    },
    handleRowClick(director) {
      this.openModal();
      this.selected = JSON.parse(JSON.stringify(director));
    },
  },
  components: {
    ComplianceDetailsModal,
  },
  computed: {
    emptyCompliance() {
      const empty = this.response.length === 0;
      return empty;
    },
    data() {
      const data =
        this.response && this.response.length > 0 ? this.response[0] : {};
      return data;
    },
  },
  async mounted() {
    await this.fetchCompliance();
  },
};
</script>
