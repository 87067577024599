<template>
  <SideModal
    :show="show"
    :toggle="toggle"
    :title="`Key Personnel`"
    :subTitle="'All Information about a ket personnel'"
  >
    <div class="row">
      <div class="col-6">
        <p>
          <span class="text-muted text-uppercase">Name</span> <br />
          <span class="text-capitalize h5">
            {{ data.name || "N/A" }}
          </span>
        </p>
      </div>
      <div class="col-6">
        <p>
          <span class="text-muted text-uppercase">Designation</span> <br />
          <span class="text-capitalize h5">
            {{ data.designation || "N/A" }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-4">
        <p>
          <span class="text-muted text-uppercase">Appointed On</span> <br />
          <span class="text-capitalize h5">
            {{ timeDateFormat(data.appointedOn).date || "N/A" }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-4">
        <p>
          <span class="text-muted text-uppercase">Resigned On</span> <br />
          <span class="text-capitalize h5"
            >{{ timeDateFormat(data.resignedOn).date || "N/A" }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-4">
        <p>
          <span class="text-muted text-uppercase">Phone</span> <br />
          <span class="text-capitalize h5"> {{ data.phone || "N/A" }} </span>
        </p>
      </div>
      <div class="col-6 mt-4">
        <p>
          <span class="text-muted text-uppercase">Address</span> <br />
          <span class="text-capitalize h5">
            {{ data.address || "N/A" }}
          </span>
        </p>
      </div>
    </div>
    <div class="border-bottom my-3" />
    <div class="row">
      <div class="col-6">
        <p>
          <span class="text-muted text-uppercase">Email</span> <br />
          <span class="text-capitalize h5">
            {{ timeDateFormat(data.resignedOn).date || "N/A" }}
          </span>
        </p>
      </div>
      <div class="col-6">
        <p>
          <span class="text-muted text-uppercase">IsForeign</span> <br />
          <span class="text-capitalize h5">
            {{ data.isForeign || "N/A" }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-4">
        <p>
          <span class="text-muted text-uppercase">Date Of Birth</span> <br />
          <span class="text-capitalize h5">
            {{ timeDateFormat(data.dateOfBirth).date || "N/A" }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-4">
        <p>
          <span class="text-muted text-uppercase">Country Of Residence</span>
          <br />
          <span class="text-capitalize h5">
            {{ data.countryOfResidence || "N/A" }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-4">
        <p>
          <span class="text-muted text-uppercase">Occupation</span> <br />
          <span class="text-capitalize h5">
            {{ data.occupation || "N/A" }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-4">
        <p>
          <span class="text-muted text-uppercase">Nationality</span> <br />
          <span class="text-capitalize h5">
            {{ data.nationality || "N/A" }}
          </span>
        </p>
      </div>
    </div>
    <div class="border-bottom my-3" />
    <div class="row">
      <div class="col-6">
        <p>
          <span class="text-muted text-uppercase">Document Type</span> <br />
          <span class="text-capitalize h5">
            {{ data.documentType || "N/A" }}
          </span>
        </p>
      </div>
      <div class="col-6">
        <p>
          <span class="text-muted text-uppercase">Document Number</span>
          <br />
          <span class="text-capitalize h5"
            >{{ data.documentNumber || "N/A" }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-4">
        <p>
          <span class="text-muted text-uppercase">Document Issued By</span>
          <br />
          <span class="text-capitalize h5">{{
            data.documentIssuedBy || "N/A"
          }}</span>
        </p>
      </div>
      <div class="col-6 mt-4">
        <p>
          <span class="text-muted text-uppercase">Document Issued on</span>
          <br />
          <span class="text-capitalize h5"
            >{{ timeDateFormat(data.documentIssuedOn).date || "N/A" }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-4">
        <p>
          <span class="text-muted text-uppercase">Shares Type</span> <br />
          <span class="text-capitalize h5">{{ data.sharesType || "N/A" }}</span>
        </p>
      </div>
      <div class="col-6 mt-4">
        <p>
          <span class="text-muted text-uppercase">Shares Value</span> <br />
          <span class="text-capitalize h5">
            {{ data.sharesValue || "N/A" }}
          </span>
        </p>
      </div>
    </div>
    <div class="border-bottom my-3" />
    <div class="row">
      <div class="col-6">
        <p>
          <span class="text-muted text-uppercase">Shares Count</span> <br />
          <span class="text-capitalize h5">
            {{ data.sharesCount || "N/A" }}
          </span>
        </p>
      </div>
    </div>
  </SideModal>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import SideModal from "../../../../../components/utils/Modal/SideModal.vue";

export default {
  name: "OwnershipModal",
  mixins: [notifications, componentData],
  props: ["toggle", "show", "data"],
  components: {
    SideModal,
  },

  methods: {
    handleClick(e) {
      if (!e.target.closest(".table-modal")) {
        return this.$emit("close-modal");
      }
    },
    copy() {
      const copied = this.copyToClipboard(this.data.reference);
      copied && this.showSuccessNotification("Transaction Reference Copied!");
    },
  },
};
</script>
