<template>
  <div>
    <div class="row">
      <div class="col-sm-10"></div>
      <div class="col-sm-2">
        <CustomSelect
          name="filterBy"
          placeholder="Filter By"
          :optionLabel="'name'"
          :options="providerStatFilter"
          v-model="filterBy"
        />
      </div>
    </div>
    <div>
      <apexchart
        height="400"
        width="1400"
        type="bar"
        :options="{
          plotOptions: {
            bar: {
              borderRadius: 0,
            },
          },
        }"
        :series="[
            {
              data: chartData.map((dt) => ({
                x: dt.log_date,
                y: dt.log_count,
              })),
            },
        ]"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import {
  toCurrency,
  formatNumber,
  graphDateFormatter,
  providerStatFilter,
} from "../utils/helpers";
import CustomSelect from "../utils/CustomSelect/CustomSelect";
import logs from "../../services/logs";

export default {
  name: "ProviderOverviewLogStat",
  data() {
    return {
      chartData: [],
      filterBy: "thisWeek",
      providerStatFilter,
    };
  },
  components: { CustomSelect },
  methods: {
    toCurrency,
    formatNumber,
    graphDateFormatter,
    async fetchCreditLogStat(filterBy) {
      const { data } = await logs.GetProviderOverviewLogStats(filterBy);
      this.chartData = data.data;
      this.loading = false;
    },
  },
  watch: {
    filterBy(newVal, oldVal) {
      this.fetchCreditLogStat({
        filterBy: newVal.value,
        id: this.$route.params.id
      });
    },
  },
  created() {
    this.fetchCreditLogStat({
      filterBy: this.filterBy,
      id: this.$route.params.id
    });
  },
};
</script>

<style scoped></style>