<template>
  <div>
    <form @submit.prevent="">
      <div class="form-group">
        <label class="input-item-label"> Generate Account Statement </label>
      </div>
      <div class="row">
        <div class="col-md-4">
          <CustomSelect
            :name="'type'"
            :label="'Type'"
            :placeholder="'Choose Statement Section'"
            :optionLabel="'label'"
            :options="[
              // {
              //   label: 'Transactions',
              //   value: 'transactions',
              //   extra: 'Payment Transactions',
              // },
              { label: 'Invoices', value: 'invoices' },
              { label: 'Wallet Transactions', value: 'wallet' },
            ]"
            @input="handleReset"
            :error="$v.request.type.$error && formVuelidator($v.request.type)"
            v-model="request.type"
            :required="false"
          />
        </div>
        <div class="col-md-4 position-relative">
          <CustomInput
            :name="'durations'"
            :value="range"
            @click.native="handleShowDate"
            :otherCls="'pointer'"
            :label="'Durations'"
            :placeholder="'Choose Date'"
            :readonly="true"
            :error="
              $v.request.durations.$error &&
                formVuelidator($v.request.durations)
            "
            :icon="'/assets/img/icons/calender.svg'"
          />
          <DateRangePicker
            v-if="showDate"
            :dates="request.durations"
            @reset="handleReset"
            @close="showDate = false"
          />
        </div>
        <div class="col-md-6">
          <GenerateStatementBtn
            :success="success"
            :loading="loading"
            @refresh="handleRefresh"
            @generate="handleGenerate"
            :transactions="transactions"
            :dates="request.durations"
            :params="params"
          />
        </div>
        <div v-if="show" class="mt-4 col-12">
          <AccountStatementTable
            :params="params"
            @loading="handleLoading"
            @error="handleError"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import required from "vuelidate/lib/validators/required";
import { timeDateFormat } from "../../../../../../components/utils/helpers";
import { componentData } from "../../../../../../../public/assets/mixins/componentData";
import { notifications } from "../../../../../../../public/assets/mixins/notifications";
import CustomInput from "../../../../../../components/utils/CustomInput/CustomInput.vue";
import CustomSelect from "../../../../../../components/utils/CustomSelect/CustomSelect.vue";
import { formVuelidator } from "../../../../../../components/utils/FormValidator/FormVuelidator";
import DateRangePicker from "../../../../../../components/utils/DateRangePicker/DateRangePicker";
import GenerateStatementBtn from "./GenerateStatementBtn.vue";
import AccountStatementTable from "./AccountStatementTable.vue";

export default {
  validations: {
    request: {
      type: {
        required,
      },
      durations: {
        required,
      },
    },
  },
  name: "Wallet",
  mixins: [componentData, notifications],
  data() {
    return {
      request: {
        type: "",
        durations: {
          from: new Date(),
          to: new Date(),
        },
      },
      loading: false,
      showDate: false,
      success: false,
      show: false,
      transactions: null,
    };
  },
  computed: {
    params() {
      const params = {
        id: this.$route.params.clientid,
        customer: this.$route.params.customerid,
        ...this.request.durations,
      };

      if (this.request.type && this.request.type.value) {
        params.type = this.request.type.value;
      }
      return params;
    },
    range() {
      const { durations } = this.request;

      const value = `${timeDateFormat(durations.from).date} to ${
        timeDateFormat(durations.to).date
      }`;

      return value;
    },
  },
  components: {
    CustomInput,
    CustomSelect,
    DateRangePicker,
    GenerateStatementBtn,
    AccountStatementTable,
  },
  methods: {
    formVuelidator,
    handleShowDate() {
      this.showDate = !this.showDate;
    },
    handleReset() {
      this.show = false;
      this.success = false;
      this.transactions = "";
    },
    handleError() {
      this.loading = false;
      this.transactions = "";
    },
    handleLoading({ status, value }) {
      this.loading = status;
      this.transactions = value;
      status == false ? (this.success = true) : (this.success = false);
    },

    handleRefresh() {
      this.transactions = "";
      this.type = "";
      this.success = false;
      this.show = false;
      this.request.durations = {
        from: new Date(),
        to: new Date(),
      };
    },
    handleGenerate() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.show = true;
    },
  },

  created() {},
};
</script>
