<template>
  <SideModal
    :show="show"
    :toggle="toggle"
    :title="`Transaction Information`"
    :subTitle="'All informations about open transactions'"
  >
    <div class="row mt-2 pt-4">
      <!-- <div class="col-12 mb-4">
        <button type="submit" class="btn btn-lg btn-primary mr-4">
          Refund
        </button>
        <button type="submit" class="btn btn-lg btn-outline-primary text-dark">
          Retriger Notification
        </button>
      </div> -->
      <div class="col-6">
        <p>
          <span class="text-muted">AMOUNT</span> <br />
          <span class="text-capitalize h3">
            &#8358;{{ formatNumber(singleTransactionObj.amount) || "0" }}
          </span>
        </p>
      </div>
      <div class="col-6">
        <span
              class="badge badge-app text-capitalize"
              :class="
                singleTransactionObj.status == 'successful' ? 'badge-success' : 'badge-danger'
              "
            >
              {{ singleTransactionObj.status }}
            </span>
      </div>
      <br />
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">transaction id</span> <br />
          <span class="text-capitalize h5">
            {{ singleTransactionObj.reference | moment(" MM-DD-YYYY h:mm a") }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">transaction date</span> <br />
          <span class="text-capitalize h5">
            {{ singleTransactionObj.updatedAt | moment(" MM-DD-YYYY h:mm a") }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">transaction channel</span>
          <br />
          <span class="text-capitalize h5">{{
            titleCase(singleTransactionObj.source)
          }}</span>
        </p>
      </div>
      <div class="col-6 mt-2" >
        <p>
          <span class="text-muted text-uppercase">tranx approved note</span>
          <br />
          <span class="text-capitalize h5">{{
            singleTransactionObj.narration || 'Not Available'
          }}</span>
        </p>
      </div>
    </div>
    <br />
    <hr />
    <br />
    <h3>Transaction Information</h3>
    <div class="row mt-2">
      <div class="col-6">
        <p>
          <span class="text-muted text-uppercase">Customer generated ref. </span
          ><br />
          <span class="text-capitalize h5">{{
            singleTransactionObj.customerReference  || 'Not Available'
          }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">trnasaction ref</span>
          <br />
          <span class="text-capitalize h5">{{
            singleTransactionObj.transactionReference  || 'Not Available'
          }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">Recipient Bank</span>
          <br />
          <span class="text-capitalize h5">{{
            singleTransactionObj.senderBankName  || 'Not Available'
          }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">Recipient account name</span>
          <br />
          <span class="text-capitalize h5">{{
            singleTransactionObj.accountName  || 'Not Available'
          }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">Transaction narration</span>
          <br />
          <span class="text-capitalize h5">{{
            singleTransactionObj.narration  || 'Not Available'
          }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">Device os </span><br />
          <span class="text-capitalize h5">Null</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">authourization </span><br />
          <span class="text-capitalize h5">Null</span>
        </p>
      </div>
    </div>
    <br />
    <hr />
    <h3>Other Information</h3>
    <div class="row mt-2">
      <div class="col-6">
        <p>
          <span class="text-muted">DEVICE TYPE </span><br />
          <span class="text-capitalize h5">Null</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted"> DEVICE OS</span> <br />
          <span class="text-capitalize h5">{{ "Null" }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted">ATTEMPT</span> <br />
          <span class="text-capitalize h5">{{ "Null" }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted">ERRORS</span> <br />
          <span class="text-capitalize h5">{{ "NulL" }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted ">IP ADDRESS </span><br />
          <span class="text-capitalize h5">{{ "NulL" }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted">TRANSACTION REFERENCE</span> <br />
          <span class="text-capitalize h5"> {{ "Null" }} </span>
        </p>
      </div>
    </div>
  </SideModal>
</template>

<script>
import { notifications } from "../../../../public/assets/mixins/notifications";
import SideModal from "../../../components/utils/Modal/SideModal.vue";
import { formatNumber, titleCase } from "../../../components/utils/helpers";

export default {
  mixins: [notifications],
  name: "PaymentTransactionDetailModal",
  data() {
    return {};
  },
  props: ["toggle", "show", "singleTransactionObj"],
  components: {
    SideModal,
  },
  methods: {
    formatNumber,
    titleCase,
    handleCloseModal() {
      this.toggle();
    },
  },
};
</script>

<style scoped>
.d-flex {
  display: flex;
  justify-content: flex-end;
}
</style>
