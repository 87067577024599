<template>
  <div class="p-md-4 p-3">
    <div class="SectionItem no-b-b mb-0">
      <div>
        <p class="title m-0">SMS Logs</p>
        <p class="text-left description">
          View all the SMS logs
        </p>
      </div>
      <div>
        <button
          class="btn btn-lg btn-secondary-inverted"
          @click="downloadLinks()"
        >
          <span
            ><img src="/assets/img/icons/document-download.svg" alt=""
          /></span>
          Export CSV
        </button>
      </div>
    </div>
    <SMSLogModal
      :show="showSMSModal"
      :toggle="toggleSMSModal"
      :singleSMSObj="singleSMSObj"
      :refetch="refetch"
    />
    <div class="px-3">
      <FilterWrapper>
        <template slot="filterForm">
          <SMSFilters
            :loading="loading"
            :rLoading="rLoading"
            :submitFilter="refetch"
            :clearFilter="() => clearFilter('smsRef')"
          />
        </template>
      </FilterWrapper>
    </div>
    <TableWrapper
      :loading="loading"
      :errorMsg="errorMsg"
      :refetch="fetchSMSLogs"
      :isEmpty="isEmpty"
      :emptyTitle="emptyTitle"
    >
      <div class="w-100 py-3" slot="content">
        <v-server-table
          ref="smsRef"
          class="v-table row-pointer"
          :data="customers"
          :columns="columns"
          :options="options"
          @loaded="onLoaded"
          @loading="setLoading(true)"
          @row-click="handleClick"
        >
          <template slot="status" slot-scope="{ row }">
            {{ titleCase(row.status) }}
          </template>
          <template slot="price" slot-scope="{ row }">
            {{ row.price || "N/A" }}
          </template>
          <template slot="createdAt" slot-scope="{ row }">
            {{ row.createdAt | moment(" MM-DD-YYYY h:mm a") }}
          </template>
        </v-server-table>
      </div>
    </TableWrapper>
  </div>
</template>

<script>
import { componentData } from "../../../public/assets/mixins/componentData";
import TableWrapper from "../../components/utils/TableWrapper/TableWrapper.vue";
import SMSLogModal from "./modals/SMSLogModal.vue";
import SMSFilters from './filters/SMSFilters.vue'

const tableHeadings = {
  referenceId: "Reference",
  number: "Number",
  senderId: "Sender Id",
  status: "Status",
  price: "Cost",
  createdAt: "Date Created",
};

export default {
  name: "SMSLogs",
  mixins: [componentData],
  data() {
    return {
      singleSMSObj: null,
      showSMSModal: false,
      emptyTitle: "No SMS Logs Found",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(tableHeadings, 25),
    };
  },
  components: {
    TableWrapper,
    SMSLogModal,
    SMSFilters
  },
  methods: {
    toggleSMSModal() {
      this.showSMSModal = !this.showSMSModal;
    },
    handleClick({ row }) {
      this.singleSMSObj = row;
      this.showSMSModal = true;
    },
    refetch(filters) {
      this.handleRefetch(filters, this.fetchSMSLogs, "smsRef");
    },
    async fetchSMSLogs() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters };
        const response = await this.$store.dispatch("logs/getSMSLogs", {
          params,
        });
        const newData = response.data.data;
        const metaData = response.data.meta;
        return {
          count: metaData.total_items || 0,
          total: metaData.total_items || 0,
          data: newData,
        };
      };
    },
    async downloadLinks() {
      const download = (filterData) => {
        return this.downloadReport(tableHeadings, filterData.data);
      };
      this.downloadTableReport("logs/getSMSLogs", download);
    },
  },
  created() {
    this.fetchSMSLogs();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
