<template>
  <div>
    <div class="p-md-4 p-3">
      <div class="SectionItem no-b-b mb-3">
        <div>
          <span class="title">
            <router-link to="/payment-payouts" class="nav-sub-link"><img src="/assets/img/icons/go-back.svg" alt="image"
                height="30" /></router-link>
            Payout Information</span>
          <p class="text-left description">
            Payout Details
          </p>
        </div>

      </div>

      <div v-if="payoutIsLoading" class="my-4">
        <Spinner />
      </div>
      <div v-else class="row">
        <div class="col-12 card mx-3">
          <h1 class="merchant-title">
            <!-- <span class="mr-4"
            ><img height="35" :src="merchantInformation.logo" alt=""/></span
          > -->
            <!-- {{ providerOverview.provider.name }} -->
          </h1>
          <div class="contact-details--card border-0">
            <div class="contact-details--card-row">
              <div class="contact-details--card-field">
                <span class="field-key">
                  <div class="contact-details--truncated text-uppercase">
                    Provider Id
                  </div>
                </span>
                <span class="field-value">
                  <div class="contact-details--truncated">
                    {{ payoutDetails.reference }}
                  </div>
                </span>
              </div>
              <div class="contact-details--card-field">
                <span class="field-key">
                  <div class="contact-details--truncated text-uppercase">
                    Amount
                  </div>
                </span>
                <span class="field-value">
                  <div class="contact-details--truncated pointer">
                    {{ payoutDetails.currency}}{{ formatNumber(payoutDetails.amount) || "0" }}
                  </div>
                </span>
              </div>
              <div class="contact-details--card-field">
                <span class="field-key">
                  <div class="contact-details--truncated text-uppercase">
                    Charges
                  </div>
                </span>
                <span class="field-value">
                  <div class="contact-details--truncated pointer">
                    {{ payoutDetails.currency}}{{ formatNumber(payoutDetails.charges) || "0" }}
                  </div>
                </span>
              </div>

              <div class="contact-details--card-field">
                <span class="field-key">
                  <div class="contact-details--truncated">Date Created</div>
                </span>
                <span class="field-value">
                  <div class="contact-details--truncated">
                    {{
                    payoutDetails.createdAt | moment(" MM-DD-YYYY h:mm a")
                    }}
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div class="contact-details--card border-0" v-if="payoutDetails.BankAccount">
            <div class="contact-details--card-row">
              <div class="contact-details--card-field">
                <span class="field-key">
                  <div class="contact-details--truncated text-uppercase">
                    Status
                  </div>
                </span>
                <span class="field-value">
                  <div class="contact-details--truncated">
                    <span class="badge badge-app text-capitalize" :class="payoutDetails.status == 'successful'
          ? 'badge-success'
          : 'badge-danger'
        ">
                      {{ payoutDetails.status }}
                    </span>
                  </div>
                </span>
              </div>
              <div class="contact-details--card-field">
                <span class="field-key">
                  <div class="contact-details--truncated text-uppercase">
                    Account Name
                  </div>
                </span>
                <span class="field-value">
                  <div class="contact-details--truncated pointer">
                    {{ payoutDetails.BankAccount.account_name || 'N/A'}}
                  </div>
                </span>
              </div>
              <div class="contact-details--card-field">
                <span class="field-key">
                  <div class="contact-details--truncated text-uppercase">
                    Account Number
                  </div>
                </span>
                <span class="field-value">
                  <div class="contact-details--truncated pointer">
                    {{ payoutDetails.BankAccount.account_number}}
                  </div>
                </span>
              </div>
              <div class="contact-details--card-field">
                <span class="field-key">
                  <div class="contact-details--truncated text-uppercase">
                    Account Name
                  </div>
                </span>
                <span class="field-value">
                  <div class="contact-details--truncated pointer">
                    {{ payoutDetails.BankAccount.bank}}
                  </div>
                </span>
              </div>


            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="!payoutIsLoading">
        <div class="col-sm-12">
          <div class="dashboard-briefs">
            <div class="dashboard-brief rounded py-4">
              <h2 class="call-count">
                {{ payoutDetails.statistics[0].total || "0" }}
              </h2>
              <p class="call-name mb-0">Number of Transactions</p>
            </div>
            <div class="dashboard-brief rounded py-4">
              <h2 class="call-count">
                {{ payoutDetails.currency }} {{ formatNumber(payoutDetails.statistics[0].totalamount || "0") }}
              </h2>
              <p class="call-name mb-0">Total Amount</p>
            </div>
            <div class="dashboard-brief  rounded py-4">
              <h2 class="call-count">
                {{ payoutDetails.currency }} {{ formatNumber(payoutDetails.statistics[0].totalcharges || "0") }}
              </h2>
              <p class="call-name mb-0">Total Charges</p>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="p-md-4 p-3">
      <div class="SectionItem no-b-b mb-3">
        <div>
          <p class="title m-0">Payout Transactions</p>
        </div>
      </div>

      <TableWrapper :loading="loading" :errorMsg="errorMsg" :refetch="fetchPaymentPayouts" :isEmpty="isEmpty"
        :emptyTitle="emptyTitle">
        <div class="w-100 py-3" slot="content">
          <v-server-table ref="providersRef" class="v-table row-pointer" :data="customers" :columns="columns"
            :options="options" @loaded="onLoaded" @loading="setLoading(true)" @row-click="handleClick">
            <template slot="first_name" slot-scope="{ row }">
              <p class="mb-0 ">{{ titleCase(row.first_name) }} {{ titleCase(row.last_name) }}</p>
            </template>
            <template slot="source" slot-scope="{ row }">
              <p class="mb-0 ">
                {{ titleCase(row.source) || "Null" }}
              </p>
            </template>
            <template slot="amount" slot-scope="{ row }">
              {{ row.currency}}{{ formatNumber(row.amount) || "0" }}
            </template>
            <template slot="charges" slot-scope="{ row }">
              {{ row.currency}}{{ formatNumber(row.charges) || "0" }}
            </template>
            <div slot="status" slot-scope="{ row }">
              <span class="badge badge-app" :class="
                  row.status == 'successful' ? 'badge-success' : 'badge-danger'
                ">
                {{ titleCase(row.status) }}
              </span>
            </div>
            <div slot="type" slot-scope="{ row }">
              <span>
                {{ row.type || "Null" }}
              </span>
            </div>
            <div slot="reason" slot-scope="{ row }">
              <span>
                {{ row.reason || "Null" }}
              </span>
            </div>

            <template slot="updatedAt" slot-scope="{ row }">
              {{ row.updatedAt | moment(" MM-DD-YYYY h:mm a") }}
            </template>
          </v-server-table>
        </div>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../public/assets/mixins/componentData";
import TableWrapper from "../../components/utils/TableWrapper/TableWrapper.vue";
import PaymentPayoutFilter from "../../components/filters/PaymentPayoutFilter.vue";
import { formatNumber, titleCase } from "../../components/utils/helpers";

const tableHeadings = {
  reference: "Reference",
  id: "Transaction ID",
  amount: "Amount",
  charges: "Charges",
  status: "Status",
  updatedAt: "Payout Date",
};

export default {
  name: "PayoutDetailPage",
  mixins: [componentData],
  data() {
    return {
      singleTransactionObj: null,
      showTransactionModal: false,
      payoutDetails: null,
      payoutIsLoading: false,
      emptyTitle: "No Data",
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(tableHeadings, 25),
    };
  },
  components: {
    TableWrapper,
    PaymentPayoutFilter,
  },
  methods: {
    toggleTransactionModal() {
      this.showTransactionModal = !this.showTransactionModal;
    },
    handleClick({ row }) {
      this.singleTransactionObj = row;
      this.showTransactionModal = true;
    },
    formatNumber,
    titleCase,
    refetch(filters) {
      this.handleRefetch(filters, this.fetchPaymentPayouts, "providersRef");
    },
    async fetchPayoutDetail() {
      this.payoutIsLoading = true;
      const response = await this.$store.dispatch(
        "payment/getPayoutDetail",
        {
          id: this.$route.params.id 
        }
      );
      this.payoutDetails = response.data
      this.payoutIsLoading = false;
    },
    async fetchPaymentPayouts() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters,};
        const response = await this.$store.dispatch(
          "payment/getPayoutTransactions",
          {
            params,
            id: this.$route.params.id 
          }
        );
        const newData = response.data.data;
        const count = response.data.total;
        return { ...response, count, total: count, data: newData };
      };
    },
    async downloadLinks() {
      const download = (filterData) =>
        this.downloadReport(tableHeadings, filterData.data);
      this.downloadTableReport("payment/getPayoutTransactions", download);
    },
  },
  created() {
    this.fetchPaymentPayouts();
    this.fetchPayoutDetail();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>

<style>
.dashboard-briefs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  /* grid-template-rows: 170px; */
}

.dashboard-brief {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}

.dashboard-brief-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}
</style>