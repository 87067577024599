<template>
	<div class="form-group mb-4">
		<label v-show="label" class="input-item-label">{{ label }}</label>
		<span
			v-show="required"
			class="text-danger pl-1"
			title="This field is required"
			>*</span
		>
		<v-select
			class="v-select"
			:class="cls"
			:placeholder="placeholder"
			@keydown.enter.native.prevent
			:get-option-label="getLabel"
			:label="optionLabel"
			:reduce="reduce"
			:options="options"
			:value="value"
			v-on:input="$emit('input', $event)"
			:disabled="disabled"
			:multiple="multiple"
			:clearable="isClear"
		/>
		<div v-if="loading" class="text-right">Loading...</div>
		<span v-else-if="error" class="text-danger">{{ error }}</span>
	</div>
</template>

<script>
	export default {
		name: "CustomSelect",
		props: [
			"name",
			"label",
			"optionLabel",
			"reduce",
			"placeholder",
			"value",
			"options",
			"required",
			"disabled",
			"getLabel",
			"loading",
			"error",
			"handleError",
			"multiple",
			"cls",
			"clear",
		],
		computed: {
			isClear() {
				return this.clear ? true : false;
			},
		},
		watch: {
			value() {
				this.handleError(this.name);
			},
		},
	};
</script>

<style></style>
