<template>
  <div class="col-12 pt-4">
    <div class="SectionItem no-b-b mb-3">
      <div>
        <p class="title m-0">Funds Transfer Overview</p>
        <p class="text-left description">
          Payment Summary
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="dashboard-briefs">
          <div class="dashboard-brief rounded py-4">
            <h2 class="call-count">
              {{ overview.totalTransferValue || "0" }}
            </h2>
            <p class="call-name">Total Transfer Value</p>
          </div>
          <div class="dashboard-brief  rounded py-4">
            <h2 class="call-count">
              {{ overview.totalNoOfTransaction || "0" }}
            </h2>
            <p class="call-name">Total No. Of Transaction</p>
          </div>
          <div class="dashboard-brief  rounded py-4">
            <h2 class="call-count">
              {{ overview.totalCharges || "0" }}
            </h2>
            <p class="call-name">Total Charges</p>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="dashboard-brief-chart p-4 ">
          <h2 class="call-count text-left">
            Transfer Statistics
          </h2>
          <FTStatistics />
        </div>
      </div>
      <div class="col-sm-6">
        <div class="p-4 request-chart">
          <h2 class="call-count text-left">
            Request Statisctis
          </h2>
          <apexchart height="300" type="donut" :options="{
                labels: [
                  'Approved Request',
                  'Pending Request',
                  'Processing Request',
                ],
              }" :series="[43, 65, 45]"></apexchart>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="dashboard-brief-chart p-4 ">
              <h2 class="call-count text-left">
                This Month
              </h2>
              <h1>24578</h1>
              <p class="text-small">Total Transactions</p>
              <div class="spike">
                <h4 class="text-right text-success">
                  <span><img src="/assets/img/icons/trend-up.svg" alt="image" width="20" /></span>
                  +21.5%
                </h4>
              </div>
              <img src="/assets/img/bar-chart.png" alt="image" class="bar-chart" />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="dashboard-brief-chart p-4 ">
              <div class="">
                <h2 class="call-count text-left">
                  This Month
                </h2>
                <h1>24578</h1>
                <p class="text-small">Disputes Raised</p>
              </div>
              <div class="spike">
                <h4 class="text-right text-danger d-inline">
                  <span><img src="/assets/img/icons/bearish.svg" alt="image" width="20" /></span>+21.5%
                </h4>
              </div>

              <img src="/assets/img/bar-chart2.svg" alt="image" class="bar-chart" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="dashboard-brief-chart p-4 ">
          <h2 class="call-count text-left">
            Top Provider Service Used
          </h2>
          <apexchart height="400" width="600" type="bar" :options="chartOptions" :series="[
                {
                  data: [
                    {
                      x: 'category A',
                      y: 10,
                    },
                    {
                      x: 'category B',
                      y: 18,
                    },
                    {
                      x: 'category C',
                      y: 13,
                    },
                    {
                      x: 'category A',
                      y: 10,
                    },
                    {
                      x: 'category B',
                      y: 18,
                    },
                    {
                      x: 'category C',
                      y: 13,
                    },
                    {
                      x: 'category A',
                      y: 10,
                    },
                    {
                      x: 'category B',
                      y: 18,
                    },
                  ],
                },
              ]"></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  toCurrency,
  formatNumber,
  graphDateFormatter,
  chartOptions
} from "../../components/utils/helpers";
import FTStatistics from "../../components/charts/FTStatistics.vue";
export default {
  name: "FTOverview",
  components: { FTStatistics },
  data() {
    return {
      overview: null,
      loading: true,
      chartOptions: chartOptions
    };
  },
  methods: {
    toCurrency,
    formatNumber,
    graphDateFormatter,
    async fetchFTOverview() {
      const response = await this.$store.dispatch(
        "fundstransfer/getFTOverview"
      );
      this.overview = response.data;
      this.loading = false;
    },
  },
  created() {
    this.fetchFTOverview();
  },
};
</script>

<style scoped>
.dashboard-briefs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  /* grid-template-rows: 170px; */
}

.dashboard-brief {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}
.dashboard-brief-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}
.request-chart {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 600px; */
  box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}
/* .dashboard-brief:not(:last-of-type) {
  border-right: 1px solid #cccbcb;
} */
.spike {
  position: absolute;
  top: 70px;
  right: 50px;
}
.call-count {
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  color: #071b2b;
  padding-top: 8px;
  margin-bottom: 20px;
}
.call-name {
  font-size: 15px;
  color: #081b2a;
  text-transform: uppercase;
}
.bar-chart {
  width: 100%;
}
</style>
