<template>
    <div>
      <div class="px-3">
        <FilterWrapper>
          <template slot="filterForm">
            <PaymentMerchantFilter
              :loading="loading"
              :rLoading="rLoading"
              :submitFilter="refetch"
              :clearFilter="() => clearFilter('merchantRef')"
              :download="downloadMerchants"
            />
          </template>
        </FilterWrapper>
      </div>
      <TableWrapper
        :loading="loading"
        :errorMsg="errorMsg"
        :refetch="fetchPaymentCustomers"
        :isEmpty="isEmpty"
        :emptyTitle="emptyTitle"
      >
        <div class="w-100 py-3" slot="content">
          <v-server-table
            ref="merchantRef"
            class="v-table row-pointer"
            :data="customers"
            :columns="columns"
            :options="options"
            @loaded="onLoaded"
            @loading="setLoading(true)"
            @row-click="handleClick"
          >
            
            <template slot="email" slot-scope="{ row }">
              {{ row.email || "N/A" }}
            </template>
            <template slot="approval" slot-scope="{ row }">
              <span :class="statusColor(row.approval)">
                {{ titleCase(row.approval) }}
              </span>
            </template>
            <template slot="created_at" slot-scope="{ row }">
              {{ row.created_at | moment(" MM-DD-YYYY h:mm a") }}
            </template>
          </v-server-table>
        </div>
      </TableWrapper>
    </div>
</template>

<script>
import { componentData } from "../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../components/utils/TableWrapper/TableWrapper.vue";
import PaymentMerchantFilter from "../../../components/filters/PaymentMerchantFilter.vue";

const tableHeadings = {
  client_id: "Merchant Reference",
  organization_name: "Business Name",
  email: "Email",
  approval: "Status",
  created_at: "Date Created",
};

export default {
  name: "Verifications",
  mixins: [componentData],
  data() {
    return {
      singleInviteObj: null,
      showSingleInviteModal: false,
      emptyTitle: "No Payment Merchant Found",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(tableHeadings, 25),
    };
  },
  components: {
    TableWrapper,
    PaymentMerchantFilter,
  },
  methods: {
    handleClick({ row }) {
      this.$router.push(`/payment-merchant/verifications/${row.client_id}`);
    },
    refetch(filters) {
      this.handleRefetch(filters, this.fetchPaymentCustomers, "merchantRef");
    },
    async fetchPaymentCustomers() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters };
        const response = await this.$store.dispatch(
          "customer/getVerificationPaymentMerchants",
          {
            params,
          }
        );
        const newData = response.data.data;
        const count = response.data.total;
        return { ...response, count, total: count, data: newData };
      };
    },
    async downloadMerchants() {
      const download = (filterData) =>
        this.downloadReport(tableHeadings, filterData.data);
      this.downloadTableReport(
        "customer/getVerificationPaymentMerchants",
        download
      );
    },
  },
  created() {
    this.fetchPaymentCustomers();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
