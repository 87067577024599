<template>
  <div class="mr-3">
    <div class="dropdown az-profile-menu">
      <img
        type="button dropdown-toggle"
        data-toggle="dropdown"
        src="/assets/img/ellipsis.svg"
        alt
        width="30px"
      />

      <div class="dropdown-menu">
        <span class="dropdown-item" @click="openAddServiceModal">
          Add New Service
        </span>
        <span class="dropdown-item" @click="openAddBulkServiceModal">
          Add Bulk Service
        </span>
        <span class="dropdown-item" @click="openEditServiceCategoryModal">
          Update Service Catgeory
        </span>
        <span class="dropdown-item" @click="openDeleteServiceCategoryModal">
          Delete Service Category
        </span>
      </div>
    </div>

    <AddServiceModal
      :id="`add${category.id}`"
      :category="category"
      :closeModal="closeCategoryModal"
    />

    <AddBulkServiceModal
      :id="`add-bulk${category.id}`"
      :category="category"
      :closeModal="closeBulkCategoryModal"
    />

    <AddServiceCategoryModal
      :id="`update${category.id}`"
      :category="category"
      :closeModal="closeEditCategoryModal"
    />

    <DeleteServiceCategoryModal
      :id="`delete${category.id}`"
      :category="category"
      :closeModal="closeDeleteCategoryModal"
    />
  </div>
</template>

<script>
import AddServiceModal from "../../modals/AddServiceModal/AddServiceModal";
import AddBulkServiceModal from "../../modals/AddBulkServiceModal/AddBulkServiceModal";
import AddServiceCategoryModal from "../../modals/AddServiceCategoryModal/AddServiceCategoryModal";
import DeleteServiceCategoryModal from "../../modals/DeleteServiceCategoryModal/DeleteServiceCategoryModal";

export default {
  name: "ServiceCategoryActions",
  props: ["category", "countryList"],
  components: {
    AddServiceModal,
    AddServiceCategoryModal,
    DeleteServiceCategoryModal,
    AddBulkServiceModal,
  },
  methods: {
    openAddServiceModal(e) {
      $(`#add${this.category.id}`).modal("show");
    },
    closeCategoryModal() {
      $(`#add${this.category.id}`).modal("hide");
    },
    openAddBulkServiceModal(e) {
      $(`#add-bulk${this.category.id}`).modal("show");
    },
    closeBulkCategoryModal() {
      $(`#add-bulk${this.category.id}`).modal("hide");
    },
    openEditServiceCategoryModal(e) {
      $(`#update${this.category.id}`).modal("show");
    },
    closeEditCategoryModal() {
      $(`#update${this.category.id}`).modal("hide");
    },
    openDeleteServiceCategoryModal(e) {
      $(`#delete${this.category.id}`).modal("show");
    },
    closeDeleteCategoryModal() {
      $(`#delete${this.category.id}`).modal("hide");
    },
  },
};
</script>
