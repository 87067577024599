var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-details--card"},[_c('div',{staticClass:"contact-details--card-row"},[_c('div',{staticClass:"contact-details--card-field"},[_vm._m(0),_c('span',{staticClass:"field-value"},[_c('div',{staticClass:"contact-details--truncated"},[_vm._v(_vm._s(_vm.user.firstname)+" "+_vm._s(_vm.user.lastname))])])]),_c('div',{staticClass:"contact-details--card-field"},[_vm._m(1),_c('span',{staticClass:"field-value"},[_c('div',{staticClass:"contact-details--truncated"},[_vm._v(_vm._s(_vm.user.firstname))])])]),_c('div',{staticClass:"contact-details--card-field"},[_vm._m(2),_c('span',{staticClass:"field-value"},[_c('div',{staticClass:"contact-details--truncated"},[_vm._v(_vm._s(_vm.user.lastname))])])])]),_c('div',{staticClass:"contact-details--card-row"},[_c('div',{staticClass:"contact-details--card-field"},[_vm._m(3),_c('span',{staticClass:"field-value"},[_c('div',{staticClass:"contact-details--truncated"},[_vm._v(_vm._s(_vm.user.email))])])]),_c('div',{staticClass:"contact-details--card-field"},[_vm._m(4),_c('span',{staticClass:"field-value"},[_c('div',{staticClass:"contact-details--truncated"},[_vm._v(_vm._s(_vm.user.country.name))])])]),_c('div',{staticClass:"contact-details--card-field"},[_vm._m(5),_c('span',{staticClass:"field-value"},[_c('div',{staticClass:"contact-details--truncated"},[_vm._v(_vm._s(_vm.user.state.state_label))])])])]),_c('div',{staticClass:"contact-details--card-row"},[_c('div',{staticClass:"contact-details--card-field"},[_vm._m(6),_c('span',{staticClass:"field-value"},[_c('div',{staticClass:"contact-details--truncated"},[_vm._v(_vm._s(_vm.user.city))])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"field-key"},[_c('div',{staticClass:"contact-details--truncated"},[_vm._v("Name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"field-key"},[_c('div',{staticClass:"contact-details--truncated"},[_vm._v("First Name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"field-key"},[_c('div',{staticClass:"contact-details--truncated"},[_vm._v("Last Name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"field-key"},[_c('div',{staticClass:"contact-details--truncated"},[_vm._v("Email")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"field-key"},[_c('div',{staticClass:"contact-details--truncated"},[_vm._v("Country")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"field-key"},[_c('div',{staticClass:"contact-details--truncated"},[_vm._v("State")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"field-key"},[_c('div',{staticClass:"contact-details--truncated"},[_vm._v("City")])])
}]

export { render, staticRenderFns }