<template>
  <form @submit.prevent="handleSubmit">
    <div class="row mb-2">
      <div class="col-sm-6 px-sm-2 col-md-4  col-xl-3">
        <CustomInput :name="'businessName'" :placeholder="'Business Name'" v-model="filters.businessName"
          :handleError="handleError" />
      </div>
      <div class="col-sm-6 px-sm-2 col-md-4  col-xl-3">
        <CustomInput :name="'transactionRef'" :placeholder="'Transaction Ref'" v-model="filters.transactionRef"
          :handleError="handleError" />
      </div>
      <div class="col-sm-6 px-sm-2 col-md-4  col-xl-3">
        <CustomSelect name="status" placeholder="Select Status" :optionLabel="'name'" :options="businessRegStatus"
          v-model="filters.status" />
      </div>
      <div class="col-sm-6 px-sm-2 col-md-4  col-xl-3">
        <DatePicker :name="'from'" v-model="filters.from" :placeholder="'Start Date'"
          :disabledDates="{ from: new Date() }" :handleError="handleError" />
      </div>

      <div class="col-sm-6 px-sm-2 col-md-4  col-xl-3">
        <DatePicker :name="'to'" v-model="filters.to" :disabledDates="{ from: new Date(), to: filters.from }"
          :placeholder="'End Date'" :handleError="handleError" />
      </div>

      <div className="col-sm-12 px-sm-3 col-md-10 px-ms-3 col-xl-5 mt-3">
        <button type="submit" :disabled="disabled || loading" class="btn btn-primary mx-3">
          Filter
        </button>

        <button v-show="isFilter" type="button" class="btn btn-outline" @click="resetFilters">
          Clear
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { filters } from "../../../../public/assets/mixins/filters";
import { businessRegStatus } from "../../../../src/components/utils/helpers";

const initialState = () => ({
  filters: {
    businessName: "",
    transactionRef: "",
    status: "",
    from: null,
    to: null,
  },
  isFilter: false,
  qLoading: false,
});

export default {
  mixins: [filters],
  name: "BusinessLogFilter",
  data() {
    return { ...initialState(), businessRegStatus };
  },
  props: ["loading", "rLoading", "clearFilter", "submitFilter"],
  computed: {
    disabled() {
      return !Object.values(this.filters).some((val) => val);
    },
  },
  methods: {
    resetFilters() {
      Object.assign(this.$data, { ...initialState() });
      this.clearFilter();
      this.isFilter = false;
    },
    handleSubmit() {
      this.isFilter = true;
      if (this.filters.from)
        this.filters.from = this.timeRangeFormat(this.filters.from, true);
      if (this.filters.to)
        this.filters.to = this.timeRangeFormat(this.filters.to);

      if (this.filters.status) this.filters.status = this.filters.status.value;

      this.submitFilter(this.filters);
    },
  },
  watch: {
    "filters.from": function() {
      this.filters.to = null;
    },
  },
};
</script>
