<template>
	<div class="w-100 pt-4">
		<v-client-table
			class="v-table row-pointer"
			:data="logdata"
			:columns="columns"
			:options="options"
		>
			<template slot="status" slot-scope="{ row }">
				<span :class="statusColor(row.status)">
					{{ titleCase(row.status) }}
				</span>
			</template>
			<template slot="createdAt" slot-scope="{ row }">
				{{ timeDateFormat(row.createdAt).date }}
				{{ timeDateFormat(row.createdAt).time }}
			</template>
		</v-client-table>
	</div>
</template>

<script>
import {
	tableOptions,
	timeDateFormat,
	statusColor,
	titleCase,
} from "../../../../components/utils/helpers";

const tableHeadings = {
	serial_no: "S/N",
	reference: "Reference",
	amount: "Amount",
	currency: "Currency",
	status: "Status",
	createdAt: "Date Created",
};

export default {
	name: "AccountCreditLog",
	data() {
		return { ...tableOptions(tableHeadings, 25) };
	},
	props: ["logs"],

	computed: {
		logdata() {
			return this.logs.data.map((obj, i) => ({
				serial_no: i + 1,
				...obj,
			}));
		},
	},
	methods: {
		timeDateFormat,
		titleCase,
		statusColor,
	},
	mounted() {
		this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
	},
};
</script>
