<template>
	<div class="col-lg-12 col-md-12">
		<div class="user-settings-section no-border pt-3">
			<div class="px-3">
				<FilterWrapper>
					<template slot="filterForm">
						<AuditTrailFilter
							:loading="loading"
							:rLoading="rLoading"
							:submitFilter="refetch"
							:applications="applications"
							:customerServices="customerServices"
							:clearFilter="() => clearFilter('auditRef')"
							:download="downloadTrails"
						/>
					</template>
				</FilterWrapper>
			</div>
			<TableWrapper
				:loading="loading"
				:errorMsg="errorMsg"
				:refetch="refetch"
				:isEmpty="isEmpty"
				:emptyTitle="emptyTitle"
			>
				<div class="w-100 py-3" slot="content">
					<v-server-table
						ref="auditRef"
						class="v-table row-pointer"
						:columns="columns"
						:options="options"
						@loaded="onLoaded"
						@loading="setLoading(true)"
					>
						<template slot="createdAt" slot-scope="{ row }">
							<span>
								{{
									row.createdAt | moment(" MM-DD-YYYY h:mm a")
								}}</span
							>
						</template>
						<template slot="event" slot-scope="{ row }">
							<ViewMore :content="row.event" title="Details" />
						</template>
					</v-server-table>
				</div>
			</TableWrapper>
		</div>

		<div class="gaps-4x"></div>
	</div>
</template>

<script>
import { componentData } from "../../../public/assets/mixins";
import AuditTrailFilter from "../../components/filters/AuditTrailFilter";

const tableHeadings = {
	id: "Audit Reference",
	event: "Event",
	name: "Name",
	category: "Category",
	createdAt: "Date Created",
};
export default {
	mixins: [componentData],
	props: {
		clientid: {
			type: String,
		},
		applications: {
			type: Array,
		},
		customerServices: {
			type: Array,
		},
	},
	data() {
		return {
			emptyTitle: "No Log Found",
			...this.tableOptions(tableHeadings, 25),
		};
	},
	components: {
		AuditTrailFilter,
	},
	methods: {
		async fetchAuditTrail() {
			this.options.requestFunction = async (data) => {
				const params = {
					...data,
					size: 25,
					...this.filters,
					clientID: this.clientid,
				};
				try {
					const response = await this.$store.dispatch(
						"customer/getAuditTrail",
						{
							params,
						}
					);
					return {
						count: response.data.result.total,
						total: response.data.result.total,
						data: response.data.result.logs,
					};
				} catch (error) {
					this.setError(error.response);
				}
			};
		},
		refetch(filters) {
			let newFilters = { ...filters };
			if (newFilters.category) {
				newFilters = {
					...newFilters,
					"category[0]": newFilters.category.value,
				};
			}
			if (newFilters.event) {
				newFilters = { ...newFilters, "event[0]": newFilters.event };
			}
			this.handleRefetch(newFilters, this.fetchAuditTrail, "auditRef");
		},
		async downloadTrails() {
			const download = (filterData) => {
				return this.downloadReport(tableHeadings, filterData.data.logs);
			};
			this.downloadTableReport("customer/getAuditTrail", download);
		},
	},
	created() {
		this.fetchAuditTrail();
	},
	mounted() {
		this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
	},
};
</script>
