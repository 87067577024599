<template>
	<Modal
		:id="id"
		:title="
			service
				? `Update ${service.service_label}`
				: `Add New Bulk Service to ${category.api_service_category}`
		"
		:toggle="handleCloseModal"
		:loading="loading"
	>
		<SuccessState
			v-if="successMsg"
			:message="`Service ${service ? 'Updated' : 'Added'} Successfully`"
			:onSuccess="handleRefetch"
		/>

		<form v-else @submit.prevent="handleSubmit" class="row">
			<div class="col-12">
				<label class="font-weight-bold">Upload CSV file:</label>
				<input
					type="file"
					ref="file_1"
					accept=".csv"
					required
					@change="uploadFile()"
					class="form-control input-bordered"
					placeholder="Upload Bulk Service CSV"
				/>
				<div class="mt-2">
					<a href="/assets/files/bulk_service_template.csv" download>
						Download sample file
					</a>
				</div>
			</div>
			<div class="col-12">
				<FormButtons
					:loading="loading"
					:handleCloseModal="handleCloseModal"
				/>
			</div>
		</form>
	</Modal>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import Modal from "../../../../components/utils/Modal/Modal";
import FormButtons from "../../../../components/utils/FormButtons/FormButtons";
import SuccessState from "../../../../components/utils/ResponseState/SuccessState";
import Spinner from "../../../../components/utils/Spinner/Spinner";

const initialState = () => ({
	request: {
		api_service_category_id: "",
		file: null,
	},
	loading: false,
	successMsg: false,
	formErrors: {},
});

export default {
	mixins: [notifications],
	name: "AddBulkServiceModal",
	data() {
		return { ...initialState() };
	},
	props: ["id", "closeModal", "service", "category"],
	components: {
		Modal,
		SuccessState,
		Spinner,
		FormButtons,
	},
	methods: {
		handleCloseModal() {
			Object.assign(this.$data, { ...initialState() });
			this.closeModal();
			this.setServiceData();
		},
		async handleRefetch() {
			this.handleCloseModal();
			await this.$store.dispatch("getMetaData");
			this.setServiceData();
		},
		handleSubmit(e) {
			console.log(e);
			e.preventDefault();
			const payload = {
				...this.request,
			};

			this.createBulkService(payload);
		},
		handleError(name) {
			this.formErrors = { ...this.formErrors, [name]: "" };
		},
		showError(msg) {
			this.loading = false;
			this.showErrorLoginNotification(msg);
		},
		getLabel: (val) => {
			return val.api_service_category;
		},
		uploadFile() {
			const file = this.$refs.file_1.files[0];
			console.log("My-FILE", this.$refs.file_1.files[0]);
			//   const dataSize = 1024 * 1024;
			this.request.file = file;
		},

		async createBulkService(payload) {
			console.log(payload);
			try {
				this.loading = true;
				let formData = new FormData();
				formData.append("api_service_category_id", this.category.id);
				formData.append("file", this.request.file);
				const response = await this.$store.dispatch(
					"infrastructureProviders/createBulkApiService",
					formData
				);
				if (response.status_code === 200) {
					this.loading = false;
					this.successMsg = true;
				} else this.showError("Error Creating Service");
			} catch (error) {
				this.showError(error.message);
			}
		},
		setServiceData() {
			//
		},
	},
	computed: {
		getApiServices() {
			return this.$store.state.metaData.apiServiceCategories;
		},
		chargeTypes() {
			return this.$store.state.services.chargeTypes.map((chargeType) => {
				return {
					label: chargeType.replace(/_/g, " "),
					value: chargeType,
				};
			});
		},
		chargeTypeIsFlatCommissionInclusiveOrExclusive() {
			return [
				"flat_commission_inclusive",
				"flat_commission_exclusive",
			].includes(this.request.charge_type);
		},
	},
	mounted() {
		this.setServiceData();
	},
};
</script>
