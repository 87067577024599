<template>
	<div class="form-group">
		<label v-show="label" class="input-item-label">{{ label }}</label>
		<span
			v-show="required"
			class="text-danger pl-1"
			title="This field is required"
			>*</span
		>
		<div class="input-wrapper" :class="disabled ? 'disabled' : ''">
			<input
				:id="id"
				:type="type"
				:min="min"
				:max="max"
				:value="value"
				v-on:input="$emit('input', $event.target.value)"
				:maxlength="maxlength"
				class="form-control input-bordered w-100"
				:placeholder="placeholder"
				:disabled="disabled"
				@keypress="isNumber"
			/>
			<div v-show="icon" class="img__wrap">
				<img
					:src="icon"
					alt=""
					class="pointer"
					@click="iconClick ? iconClick() : ''"
				/>
			</div>
		</div>
		<span v-show="error" class="text-danger">{{ error }}</span>
	</div>
</template>

<script>
	export default {
		name: "CustomInput",
		props: [
			"id",
			"value",
			"name",
			"type",
			"min",
			"max",
			"placeholder",
			"required",
			"label",
			"error",
			"maxlength",
			"isNum",
			"isCSV",
			"disabled",
			"handleError",
			"icon",
			"iconClick",
		],
		methods: {
			isNumber(val) {
				if (this.isNum) {
					if (isNaN(Number(val.key))) return val.preventDefault();
				}
				if (this.isCSV) {
					if (!val.key.match(/^([\d,])$/))
						return val.preventDefault();
				}
			},
		},
		watch: {
			value() {
				this.handleError(this.name);
			},
		},
	};
</script>
