<template>
  <div class="az-content-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="SectionItem no-b-b mb-3">
          <div class="SectionHeader">
            <span class="title">
              <router-link to="/payment-merchants?tab=verifications" class="nav-sub-link"
                ><img src="/assets/img/icons/close.svg" alt="image" height="30"
              /></router-link>
              Merchant Information</span
            >
          </div>
          <div class="content d-flex">
            <ApproveVerificationModal
              :merchantId="merchantOverview.apiCustomer.client_id"
            />
            <RejectVerificationModal
              :merchantId="merchantOverview.apiCustomer.client_id"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 card p-4">
        <h1 class="merchant-title">
          {{ merchantOverview.apiCustomer.organization_name }}
        </h1>
        <div class="contact-details--card">
          <div class="contact-details--card-row">
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Merchant Reference
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                  {{ merchantOverview.apiCustomer.client_id }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Status
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{ merchantOverview.apiCustomer.approval || "Null" }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Date Added
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                  {{
                    merchantOverview.apiCustomer.created_at
                      | moment(" MM-DD-YYYY h:mm a")
                  }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated"></div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated"></div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 card p-4">
        <h3>Verifications</h3>
        <div class="">
          <div class="contact-details--card-row">
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  First Name
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                  {{ merchantOverview.userProfile.firstname }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  First Name
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{ merchantOverview.userProfile.lastname }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Email Address
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{ merchantOverview.userProfile.email }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Phone Number
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                  {{ merchantOverview.userProfile.phone_number }}
                </div>
              </span>
            </div>
          </div>
          <div class="contact-details--card-row">
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Address 1
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                  {{ merchantOverview.userProfile.address_one || "N/A" }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Address 2
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                  {{ merchantOverview.userProfile.address_two || "N/A" }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  City
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                  {{ merchantOverview.userProfile.city || "N/A" }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase"></div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated"></div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 card p-4">
        <h3>Business Details</h3>
        <div class="">
          <div class="contact-details--card-row">
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Business Name
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                  {{ merchantOverview.apiCustomer.organization_name }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Business Email
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{ merchantOverview.apiCustomer.email || "N/A" }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Business Phone Number
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{ merchantOverview.apiCustomer.phone_number || "N/A" }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Sector
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                  {{ merchantOverview.apiCustomer.sector || "N/A" }}
                </div>
              </span>
            </div>
          </div>
          <div class="contact-details--card-row">
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Tax Identification Number
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                  {{ merchantOverview.apiCustomer.tin || "N/A" }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Business Reg. Num.
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                  {{ merchantOverview.apiCustomer.CAC || "N/A" }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase"></div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated"></div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase"></div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated"></div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 card p-4">
        <h3>Bank Details</h3>
        <div class="">
          <div class="contact-details--card-row" v-if="merchantOverview.bankDetails.BankAccount">
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Preferred Bank
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                  {{ merchantOverview.bankDetails.BankProvider.name || "N/A" }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Payout Bank
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{ merchantOverview.bankDetails.BankAccount.bank || "N/A" }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Payout Account Number
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{
                    merchantOverview.bankDetails.BankAccount.account_number ||
                      "N/A"
                  }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Payout Account Name
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                  {{
                    merchantOverview.bankDetails.BankAccount.account_name ||
                      "N/A"
                  }}
                </div>
              </span>
            </div>
          </div>
          <div v-else class="p-4">
            No bank Information
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import customer from "../../../services/customer";
import { copyToClipboard } from "../../../components/utils/helpers";
import ApproveVerificationModal from "../modals/ApproveVerificationModal.vue";
import RejectVerificationModal from "../modals/RejectVerificationModal.vue";

export default {
  name: "VerificationsDetail",
  props: {
    clientid: {
      type: String,
    },
  },
  data() {
    return {
      merchantOverview: null,
      merchantOverview: null,
    };
  },
  methods: {
    copyToClipboard,
    async fetchCurrentMerchantInfo() {
      try {
        this.loading = true;
        const response = await customer.GetVerificationPaymentMerchantsDetail({
          id: this.clientid,
        });
        this.merchantOverview = response.data.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.errorMsg = error.response.data.message;
      }
    },
  },
  mounted() {
    this.fetchCurrentMerchantInfo();
  },
  components: {
    ApproveVerificationModal,
    RejectVerificationModal,
  },
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
.merchant-title {
  font-family: "AllianzNeo";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 27px;
  color: #071b2b;
}
</style>
