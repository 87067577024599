<template>
	<div class="tab-pane" id="WhiteLabelRequests">
		<div class="row">
			<div class="col-lg-12 card pt-3">
				<v-client-table
					v-if="whiteLabelRequests.length > 0"
					ref="serverTableRef"
					name="question-table"
					class="v-table"
					:data="whiteLabelRequests"
					:columns="columns"
					:options="options"
				>
					<div
						slot="serial_no"
						class="fields--truncated"
						slot-scope="props"
					>
						<div class="dot-primary">{{ props.index }}</div>
					</div>
					<div slot="first_name" slot-scope="props">
						<div class="user-details">
							<img
								:src="
									`/assets/img/avatars/${Math.floor(
										Math.random() * 17
									) + 1}.png`
								"
								class="user-img"
								alt
							/>
							<div class="user-block">
								<div class="user-info">
									<div>
										{{ props.row.first_name }}
										{{ props.row.last_name }}
									</div>
									<div
										style="font-weight: normal;font-size: 14px;"
									>
										{{ props.row.phone_number }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						slot="existing_user"
						class="fields--truncated"
						slot-scope="props"
					>
						<div
							class="dot-primary"
							v-if="props.row.existing_user == 1"
						>
							Existing User
						</div>
						<div class="dot-secondary" v-else>New User</div>
					</div>
					<div slot="request_status" slot-scope="props">
						<span
							class="badge badge-warning badge-app"
							v-if="props.row.request_status == 'Pending'"
							>{{ props.row.request_status }}</span
						>
						<span
							class="badge badge-danger badge-app"
							v-if="props.row.request_status == 'Rejected'"
							>{{ props.row.request_status }}</span
						>
					</div>
					<div slot="created_at" slot-scope="props">
						{{
							props.row.created_at | moment(" MM-DD-YYYY h:mm a")
						}}
					</div>
					<div slot="id" slot-scope="props">
						<div class="btn-icon-list">
							<button
								v-if="
									props.row.request_status == 'Pending' ||
										props.row.request_status == 'Rejected'
								"
								class="btn btn-dark btn-icon btn-sm"
								title="Accept whitelabel request"
								@click="acceptWhiteLabelRequest(props.row)"
							>
								<i
									class="typcn typcn-input-checked-outline"
								></i>
							</button>
							<button
								v-if="props.row.request_status != 'Rejected'"
								class="btn btn-danger btn-icon btn-sm"
								data-toggle="modal"
								data-effect="effect-scale"
								data-backdrop="static"
								data-target="#WhitelabelRejectModal"
								title="Reject whitelabel request"
								@click="populateModal(props.row)"
							>
								<i class="typcn typcn-times-outline"></i>
							</button>
						</div>
					</div>
				</v-client-table>
				<DefaultContent
					v-else
					:image="'empty-resources.png'"
					:title="'No Whitelabel Requests'"
					:subtitle="
						'There are currently no whitelabel Requests in the system, do check back later.'
					"
				></DefaultContent>
			</div>
		</div>
		<WhitelabelRejectModal
			:modalData="modalData"
			v-if="modalClicked"
			@closeRejectModal="refetchRequest"
		></WhitelabelRejectModal>
	</div>
</template>

<script>
	import WhitelabelRejectModal from "../modals/WhitelabelRejectModal";
	export default {
		components: {
			WhitelabelRejectModal,
		},
		data() {
			return {
				LabelRequests: [],
				modalData: {},
				modalClicked: false,
				columns: [
					"serial_no",
					"first_name",
					"email",
					"existing_user",
					"request_status",
					"created_at",
					"id",
				],
				options: {
					sortable: [],
					perPage: 20,
					pagination: {
						chunk: 5,
					},
					texts: {
						filter: "",
						filterPlaceholder: "Search..",
						noResults: "No matching records",
					},
					headings: {
						serial_no: "S/N",
						first_name: "Full Name",
						email: "Email",
						existing_user: "User Status",
						request_status: "Status",
						created_at: "Date",
						id: "Actions",
					},
				},
			};
		},
		props: {
			whiteLabelRequests: {
				type: Array,
				required: true,
			},
		},
		methods: {
			refetchRequest() {
				this.modalData = {};
				this.modalClicked = false;
			},
			populateModal(modalData) {
				this.modalData = modalData;
				this.modalClicked = true;

				this.$emit("retchRequest");
			},
			acceptWhiteLabelRequest(item) {
				console.log("here");
				this.$emit("acceptWhiteLabelRequest", item);
			},
		},
	};
</script>
