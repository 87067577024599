<template>
  <div>
    <div v-if="walletInformation === null"></div>
    <div class="az-content-body" v-else>
      <div class="mb-4">
        <router-link to="/payment-merchants" class="mr-2 text-secondary"
          >Merchant Information</router-link
        >
        &lt;
        <router-link
          :to="`/payment-merchant/${clientid}`"
          class="mx-2 text-secondary"
          >Wallets</router-link
        >
        &lt;
        <router-link to="" class="ml-2 text-primary">
          Wallet Information</router-link
        >
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="SectionItem no-b-b mb-3">
            <div class="SectionHeader">
              <span class="title" @click="$router.back()">
                <img
                  src="/assets/img/icons/close.svg"
                  alt="image"
                  height="30"
                />
                Wallet Information</span
              >
            </div>
            <!-- <div class="content">
              <router-link
                to="/customers"
                class="btn btn-lg btn-outline-danger"
              >
                <i class="la la-arrow-left"></i> Back
              </router-link>
            </div> -->
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 card p-4">
          <div class="">
            <div class="contact-details--card-row">
              <div class="contact-details--card-field">
                <span class="field-key">
                  <div class="contact-details--truncated">
                    CUSTOMER ID
                  </div>
                </span>
                <span class="field-value">
                  <div class="contact-details--truncated">
                    {{ walletInformation.CustomerId }}
                  </div>
                </span>
              </div>

              <div class="contact-details--card-field">
                <span class="field-key">
                  <div class="contact-details--truncated">
                    CUSTOMER TYPE
                  </div>
                </span>
                <span class="field-value">
                  <div
                    class="contact-details--truncated pointer"
                    title="Click to copy"
                  >
                    {{ walletInformation.business ? "Business" : "Personal" }}
                  </div>
                </span>
              </div>
              <div class="contact-details--card-field">
                <span class="field-key">
                  <div class="contact-details--truncated">
                    ACCOUNT NAME
                  </div>
                </span>
                <span class="field-value">
                  <div
                    class="contact-details--truncated pointer"
                    title="Click to copy"
                  >
                    {{ walletInformation.Customer.first_name }}
                    {{ walletInformation.Customer.last_name }}
                  </div>
                </span>
              </div>
              <div class="contact-details--card-field">
                <span class="field-key">
                  <div class="contact-details--truncated">
                    EMAIL ADDRESS
                  </div>
                </span>
                <span class="field-value">
                  <div
                    class="contact-details--truncated pointer"
                    title="Click to copy"
                  >
                    {{ walletInformation.Customer.email }}
                  </div>
                </span>
              </div>
              <div class="contact-details--card-field">
                <span class="field-key">
                  <div class="contact-details--truncated">
                    PHONE NUMBER
                  </div>
                </span>
                <span class="field-value">
                  <div
                    class="contact-details--truncated pointer"
                    title="Click to copy"
                  >
                    {{ walletInformation.Customer.phone || "Null" }}
                  </div>
                </span>
              </div>
            </div>
            <div class="contact-details--card-row flex-start">
              <div class="contact-details--card-field">
                <span class="field-key">
                  <div class="contact-details--truncated">
                    KYC
                  </div>
                </span>
                <span class="field-value">
                  <div
                    class="contact-details--truncated pointer"
                    title="Click to copy"
                  >
                    <!-- {{ walletInformation.account_name }} -->
                    NULL
                  </div>
                </span>
              </div>
              <div class="contact-details--card-field">
                <span class="field-key">
                  <div class="contact-details--truncated">
                    VERIFICATION DATE
                  </div>
                </span>
                <span class="field-value">
                  <div class="contact-details--truncated">
                    {{
                      walletInformation.createdAt | moment(" MM-DD-YYYY h:mm a")
                    }}
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-12 col-xl-12">
          <nav class="nav az-nav-line nav-line-user-account">
            <a href="#wallet" data-toggle="tab" class="nav-link active show"
              >Wallet</a
            >
            <a href="#subscription" data-toggle="tab" class="nav-link"
              >Subscription</a
            >
          </nav>
        </div>
      </div>
      <div class="tab-content pt-4">
        <div id="wallet" class="row tab-pane active show ">
          <div class="col-12 my-4">
            <div class="dashboard-briefs">
              <div class="dashboard-brief rounded py-4">
                <h2 class="call-count">
                  {{ walletInformation.currency }}
                  {{ formatNumber(walletInformation.balance) || "0" }}
                </h2>
                <p class="call-name">
                  {{ walletInformation.currency }} Wallet Balance
                </p>
              </div>
              <div class="dashboard-brief-long  rounded p-4 ">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="contact-details--card-field mb-4">
                      <span class="field-key">
                        <div class="contact-details--truncated">
                          WALLET ID
                        </div>
                      </span>
                      <span class="field-value">
                        <div class="contact-details--truncated pointer">
                          {{ walletInformation.reference }}
                        </div>
                      </span>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="contact-details--card-field">
                      <span class="field-key">
                        <div class="contact-details--truncated">
                          PAYOUT NAME
                        </div>
                      </span>
                      <span class="field-value">
                        <div class="contact-details--truncated pointer">
                          {{ walletInformation.Customer.first_name }}
                          {{ walletInformation.Customer.last_name }}
                        </div>
                      </span>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="contact-details--card-field mb-4">
                      <span class="field-key">
                        <div class="contact-details--truncated">
                          PAYOUT ACCOUNT
                        </div>
                      </span>
                      <span class="field-value">
                        <div class="contact-details--truncated">
                          {{ walletInformation.bank.account_number }}
                          ({{ walletInformation.bank.bank_name }})
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <NGNWallet :clientid="this.clientid" />
        </div>
        <div id="subscription" class="row tab-pane">
          <WalletSuscription
            :reference="walletInformation.Customer.reference"
            :clientid="this.clientid"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NGNWallet from "./Tabs/NGNWallet.vue";
import WalletSuscription from "./Tabs/WalletSuscription.vue";
import { formatNumber } from "../../../components/utils/helpers";

export default {
  name: "SingleWallet",
  props: {
    clientid: {
      type: String,
    },
  },
  data() {
    return {
      walletInformation: null,
    };
  },
  methods: {
    formatNumber,
    async fectchSingleWallet() {
      const clTId = this.clientid;
      const wallId = this.$route.params.walletId;
      const params = {
        clTId: this.clientid,
        wallId: this.$route.params.walletId,
      };
      const response = await this.$store.dispatch(
        "customer/getPaymentSingleWallet",
        {
          params,
        }
      );

      const newData = response.data.walletInformation;
      this.walletInformation = newData;
    },
  },
  mounted() {
    this.fectchSingleWallet();
    // this.fetchCurrentMerchantInfo();
  },
  components: {
    NGNWallet,
    WalletSuscription,
  },
};
</script>
<style scoped>
.dashboard-briefs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  /* grid-template-rows: 170px; */
}
.dashboard-brief {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}
.dashboard-brief-long {
  grid-column: 2 / 4;
  /* display: flex; */
  /* flex-direction: column;
  justify-content: center;
  align-items: center; */
  box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}
.dashboard-brief-long .contact-details--card-field {
  width: 100% !important;
}

/* .dashboard-brief:not(:last-of-type) {
  border-right: 1px solid #cccbcb;
} */

.dashboard-lead {
  color: white;
}
.side-border {
  border-left: 3px dotted white;
}

.dashboard-element {
  padding: 20px;
  color: inherit;
}
.call-count {
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  color: #071b2b;
  padding-top: 8px;
  margin-bottom: 20px;
}
.call-name {
  font-size: 15px;
  color: #081b2a;
  text-transform: uppercase;
}
.flex-start {
  justify-content: flex-start;
}
.contact-details--card-field {
  width: 20% !important;
  /* margin-bottom: 10px; */
}
</style>
