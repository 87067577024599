var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 pt-4"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"dashboard-briefs"},[_c('div',{staticClass:"dashboard-brief rounded py-4"},[_c('h2',{staticClass:"call-count"},[_vm._v(" "+_vm._s(_vm.overview.totalTransferValue || "0")+" ")]),_c('p',{staticClass:"call-name"},[_vm._v("Total Transfer Value")])]),_c('div',{staticClass:"dashboard-brief rounded py-4"},[_c('h2',{staticClass:"call-count"},[_vm._v(" "+_vm._s(_vm.overview.totalNoOfTransaction || "0")+" ")]),_c('p',{staticClass:"call-name"},[_vm._v("Total No. Of Transaction")])]),_c('div',{staticClass:"dashboard-brief rounded py-4"},[_c('h2',{staticClass:"call-count"},[_vm._v(" "+_vm._s(_vm.overview.totalCharges || "0")+" ")]),_c('p',{staticClass:"call-name"},[_vm._v("Total Charges")])])])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"dashboard-brief-chart p-4"},[_c('h2',{staticClass:"call-count text-left"},[_vm._v(" Transfer Statistics ")]),_c('FTStatistics')],1)]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"p-4 request-chart"},[_c('h2',{staticClass:"call-count text-left"},[_vm._v(" Request Statisctis ")]),_c('apexchart',{attrs:{"height":"300","type":"donut","options":{
              labels: [
                'Approved Request',
                'Pending Request',
                'Processing Request',
              ],
            },"series":[43, 65, 45]}})],1),_vm._m(1)]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"dashboard-brief-chart p-4"},[_c('h2',{staticClass:"call-count text-left"},[_vm._v(" Top Provider Service Used ")]),_c('apexchart',{attrs:{"height":"400","width":"600","type":"bar","options":_vm.chartOptions,"series":[
              {
                data: [
                  {
                    x: 'category A',
                    y: 10,
                  },
                  {
                    x: 'category B',
                    y: 18,
                  },
                  {
                    x: 'category C',
                    y: 13,
                  },
                  {
                    x: 'category A',
                    y: 10,
                  },
                  {
                    x: 'category B',
                    y: 18,
                  },
                  {
                    x: 'category C',
                    y: 13,
                  },
                  {
                    x: 'category A',
                    y: 10,
                  },
                  {
                    x: 'category B',
                    y: 18,
                  },
                ],
              },
            ]}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"SectionItem no-b-b mb-3"},[_c('div',[_c('p',{staticClass:"title m-0"},[_vm._v("Funds Transfer Overview")]),_c('p',{staticClass:"text-left description"},[_vm._v(" Payment Summary ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"dashboard-brief-chart p-4"},[_c('h2',{staticClass:"call-count text-left"},[_vm._v(" This Month ")]),_c('h1',[_vm._v("24578")]),_c('p',{staticClass:"text-small"},[_vm._v("Total Transactions")]),_c('div',{staticClass:"spike"},[_c('h4',{staticClass:"text-right text-success"},[_c('span',[_c('img',{attrs:{"src":"/assets/img/icons/trend-up.svg","alt":"image","width":"20"}})]),_vm._v(" +21.5% ")])]),_c('img',{staticClass:"bar-chart",attrs:{"src":"/assets/img/bar-chart.png","alt":"image"}})])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"dashboard-brief-chart p-4"},[_c('div',{},[_c('h2',{staticClass:"call-count text-left"},[_vm._v(" This Month ")]),_c('h1',[_vm._v("24578")]),_c('p',{staticClass:"text-small"},[_vm._v("Disputes Raised")])]),_c('div',{staticClass:"spike"},[_c('h4',{staticClass:"text-right text-danger d-inline"},[_c('span',[_c('img',{attrs:{"src":"/assets/img/icons/bearish.svg","alt":"image","width":"20"}})]),_vm._v("+21.5% ")])]),_c('img',{staticClass:"bar-chart",attrs:{"src":"/assets/img/bar-chart2.svg","alt":"image"}})])])])
}]

export { render, staticRenderFns }