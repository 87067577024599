<template>
  <SideModal
    :show="show"
    :toggle="toggle"
    :title="`Transaction Information`"
    :subTitle="'All information about an open transaction'"
  >
    <!-- <div class="d-flex">
      <span
        class="badge badge-app"
        :class="
          singleInviteObj.is_active == 1 ? 'badge-success' : 'badge-danger'
        "
      >
        {{ singleInviteObj.is_active === 1 ? "Active" : "Inactive" }}</span
      >
    </div> -->
    <button type="submit" class="btn btn-lg btn-primary">
      <span>Refund</span>
    </button>
    <div class="row mt-2 pt-4">
      <div class="col-6">
        <p>
          <span class="text-muted">AMOUNT</span> <br />
          <span class="text-capitalize h3">
            &#8358;{{ formatNumber(singleNGNObj.amount) || "0" }}
          </span>
        </p>
      </div>
      <div class="col-6">
        <span
          class="badge badge-app text-capitalize"
          :class="
            singleNGNObj.status == 'successful'
              ? 'badge-success'
              : 'badge-danger'
          "
        >
          {{
            singleNGNObj.status == "successful"
              ? singleNGNObj.status
              : singleNGNObj.status
          }}
        </span>
      </div>
      <br />
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted">TRANSACTION ID </span><br />
          <span class="text-capitalize h5">{{
            singleNGNObj.id || "Null"
          }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted"> CUSTOMER NAME</span> <br />
          <!-- <span>{{ singleNGNObj.created_at }}</span> -->
          Null
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted">TRANSACTION CHANNEL</span> <br />
          <span class="text-capitalize h5">{{
            singleNGNObj.source || "Null"
          }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted">TRANSACTION DATE</span> <br />
          <span class="text-capitalize h5">{{
            singleNGNObj.createdAt | moment(" MM-DD-YYYY h:mm a")
          }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted ">TRANSACTION DESCRIPTION </span><br />
          Null
          <!-- <span>{{ singleNGNObj.created_at }}</span> -->
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted">TRANSACTION TYPE</span> <br />
          <span class="text-capitalize h5">
            {{ singleNGNObj.action === "credit" ? "Credit" : "Debit" }} </span
          ><span
            ><img
              v-if="singleNGNObj.action === 'debit'"
              src="/assets/img/icons/import.svg"
              alt=""
            />
            <img v-else src="/assets/img/icons/export.svg" alt="" />
          </span>
        </p>
      </div>
    </div>
    <br />
    <hr />
    <br />
    <h3>Channel Information</h3>
    <div class="row mt-2">
      <div class="col-6">
        <p>
          <span class="text-muted">CARD TYPE </span><br />
          <template v-if="singleNGNObj.metadata.card">
            <template v-if="singleNGNObj.metadata.card.scheme">
              <span class="text-capitalize h5">
                {{ singleNGNObj.metadata.card.scheme }}
              </span>
            </template>
          </template>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted"> CARD NUMBER</span> <br />
          <template v-if="singleNGNObj.metadata.card">
            <template v-if="singleNGNObj.metadata.card.last4">
              <span class="text-capitalize h5">
                ****{{ singleNGNObj.metadata.card.last4 }}
              </span>
            </template>
          </template>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted">CARD BANK-COUNTRY</span> <br />
          <span class="text-capitalize h5">{{ "Null" }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted">AUTHORIZATION</span> <br />
          <span class="text-capitalize h5">{{ "Nul" }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted ">IP ADDRESS </span><br />
          Null
          <!-- <span>{{ singleNGNObj.created_at }}</span> -->
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted">TRANSACTION REFERENCE</span> <br />
          <span class="text-capitalize h5"> {{ "Null" }} </span>
        </p>
      </div>
    </div>
    <br />
    <br />
    <hr />
    <div class="row mt-2">
      <div class="col-6">
        <p>
          <span class="text-muted">DEVICE TYPE </span><br />

          <template v-if="singleNGNObj.metadata.card">
            <template v-if="singleNGNObj.metadata.card.scheme">
              <span class="text-capitalize h5">
                {{ singleNGNObj.metadata.card.scheme }}
              </span>
            </template>
          </template>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted"> DEVICE OS</span> <br />
          <span class="text-capitalize h5">{{ "Null" }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted">ATTEMPT</span> <br />
          <span class="text-capitalize h5">{{ "Null" }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted">ERRORS</span> <br />
          <span class="text-capitalize h5">{{ "NulL" }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted ">IP ADDRESS </span><br />
          <span class="text-capitalize h5">{{ "NulL" }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted">TRANSACTION REFERENCE</span> <br />
          <span class="text-capitalize h5"> {{ "Null" }} </span>
        </p>
      </div>
    </div>
    <!-- <div v-if="singleNGNObj.is_active == 1">
      <router-link to="/customers" class="btn btn-lg btn-primary">
        View Customer
      </router-link>
    </div>
    <div v-else>
      <button type="submit" class="btn btn-lg btn-primary">
        Resend Invite
      </button>
    </div> -->
  </SideModal>
</template>

<script>
import { notifications } from "../../../../public/assets/mixins/notifications";
import SideModal from "../../../components/utils/Modal/SideModal.vue";
import { toCurrency, formatNumber } from "../../../components/utils/helpers";

export default {
  mixins: [notifications],
  name: "NGNDetailModal",
  data() {
    return {};
  },
  props: ["toggle", "show", "singleNGNObj"],
  components: {
    SideModal,
  },
  methods: {
    formatNumber,
    handleCloseModal() {
      this.toggle();
    },
  },
  mounted() {
    console.log(this.props.singleNGNObj);
  },
};
</script>

<style scoped>
.d-flex {
  display: flex;
  justify-content: flex-end;
}
</style>
