<template>
    <div>
        <Spinner v-if="loading" class="mt-4" />
        <div class="row" v-else>
            <div class="col-12 mt-3">
                <div class="p-md-4 p-3  card mx-4">
                    <div class="my-4">
                        <router-link to="/logs/business-registration-logs"
                            class="mr-2 text-secondary">Reports</router-link>
                        &lt;
                        <router-link to="" class="ml-2 text-primary">
                            Business Details</router-link>
                    </div>
                    <div class="">
                        <div class="SectionItem no-b-b mb-3 d-block">
                            <span class="title">
                                <router-link to="/logs/business-registration-logs" class="nav-sub-link"><img
                                        src="/assets/img/icons/close.svg" alt="image" height="30" /></router-link>
                                Close</span>
                        </div>
                    </div>

                    <div>
                        <h5 class="font-weight-bold">Business Registration Information</h5>
                        <div class="row mt-3">
                            <div class="col-lg-3 col-sm-6 mb-3">
                                <span class="text-secondary text-uppercase">Business Name</span><br />
                                <span>{{ details.proposed_option_1 }}</span>
                            </div>
                            <div class="col-lg-3 col-sm-6 mb-3">
                                <span class="text-secondary text-uppercase">Transaction Reference</span><br />
                                <span>{{ details.transaction_ref }}</span>
                            </div>
                            <div class="col-lg-2 col-sm-6 mb-3">
                                <span class="text-secondary text-uppercase">Category</span><br />
                                <span>{{ titleCase(details.line_of_business) }}</span>
                            </div>
                            <div class="col-lg-2 col-sm-6 mb-3">
                                <span class="text-secondary text-uppercase">Status</span><br />
                                <span :class="statusColor(details.status)">
                                    {{ titleCase(details.status) }}
                                </span>
                            </div>
                            <div class="col-lg-2 col-sm-6 mb-3">
                                <span class="text-secondary text-uppercase">Date REQUESTED</span><br />
                                <span>{{ timeDateFormat(details.created_at).date }}</span>
                            </div>
                        </div>

                    </div>

                    <div>
                        <div>
                            <div class="mt-5">
                                <button class="btn btn-sm btn--secondary--border px-4 mx-3"
                                    @click="refreshReportDetails()">
                                    <img src="/assets/img/icons/refresh-2.svg" class="mr-2" alt="" />
                                    Refresh Data
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 mx-4">
                <div>
                    <h5 class="font-weight-bold">Personal Information</h5>
                    <div class="row mt-3">
                        <div class="col-lg-3 col-sm-6 mb-3">
                            <span class="text-secondary text-uppercase">proprietor's firstname</span><br />
                            <span>{{ details.proprietor_firstname }}</span>
                        </div>
                        <div class="col-lg-3 col-sm-6 mb-3">
                            <span class="text-secondary text-uppercase">proprietor's surname</span><br />
                            <span>{{ details.proprietor_surname }}</span>
                        </div>
                        <div class="col-lg-3 col-sm-6 mb-3">
                            <span class="text-secondary text-uppercase">proprietor's email</span><br />
                            <span>{{ details.proprietor_email }}</span>
                        </div>
                        <div class="col-lg-3 col-sm-6 mb-3">
                            <span class="text-secondary text-uppercase">proprietor's gender</span><br />
                            <span>{{ details.proprietor_gender }}</span>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-3 col-sm-6 mb-3">
                            <span class="text-secondary text-uppercase">proprietor's nationality</span><br />
                            <span>{{ details.proprietor_nationality }}</span>
                        </div>
                        <div class="col-lg-3 col-sm-6 mb-3">
                            <span class="text-secondary text-uppercase">proprietor's phonenumber</span><br />
                            <span>{{ details.proprietor_phonenumber }}</span>
                        </div>
                        <div class="col-lg-3 col-sm-6 mb-3">
                            <span class="text-secondary text-uppercase">proprietor's dob</span><br />
                            <span>{{ details.proprietor_dob }}</span>
                        </div>
                        <div class="col-lg-3 col-sm-6 mb-3">
                            <span class="text-secondary text-uppercase">proprietor's address</span><br />
                            <span>{{ details.proprietor_street_number }}, {{ details.proprietor_service_address }}, {{
                                details.proprietor_city }}, {{
                                details.proprietor_lga
                                }}, {{ details.proprietor_state }}.</span>
                        </div>
                    </div>

                </div>
                <div class="mt-4">
                    <h5 class="font-weight-bold">Company Information</h5>
                    <div class="row mt-3">
                        <div class="col-lg-3 col-sm-6 mb-3">
                            <span class="text-secondary text-uppercase">Company address</span><br />
                            <span>{{ details.company_street_number }}, {{ details.company_address }}, {{
                                details.company_city
                                }}, {{ details.company_state }}.</span>
                        </div>
                        <div class="col-lg-3 col-sm-6 mb-3">
                            <span class="text-secondary text-uppercase">company email</span><br />
                            <span>{{ details.company_email }}</span>
                        </div>
                        <div class="col-lg-3 col-sm-6 mb-3">
                            <span class="text-secondary text-uppercase">business commencement date</span><br />
                            <span>{{ details.business_commencement_date }}</span>
                        </div>
                        <div class="col-lg-3 col-sm-6 mb-3">

                        </div>
                    </div>

                </div>
                <div class="mt-4">
                    <h5 class="font-weight-bold">Documents</h5>
                    <div class="row mt-3">
                        <div class="col-lg-3 col-sm-6 mb-3">
                            <span class="text-secondary text-uppercase">means of id</span><br />
                            <span><a :href="details.means_of_id" target="_blank" rel="noopener noreferrer">{{
                                    details.means_of_id }}</a></span>
                        </div>
                        <div class="col-lg-3 col-sm-6 mb-3">
                            <span class="text-secondary text-uppercase">passport</span><br />
                            <span>
                                <a :href="details.passport" target="_blank" rel="noopener noreferrer">{{
                                    details.passport }}</a>
                            </span>
                        </div>
                        <div class="col-lg-3 col-sm-6 mb-3">
                            <span class="text-secondary text-uppercase">signature</span><br />
                            <span>
                                <a :href="details.signature" target="_blank" rel="noopener noreferrer">{{
                                    details.signature }}</a>
                            </span>
                        </div>
                        <div class="col-lg-3 col-sm-6 mb-3">

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { componentData } from "../../../../public/assets/mixins/componentData";
import TabsV2 from "../../../components/utils/Tabs/TabsV2";
import Spinner from "../../../components/utils/Spinner/Spinner";
import { formatNumber, statusColor } from "../../../components/utils/helpers";


const tableHeadings = {
    service_name: "Service Name",
    service_type: "Service Type",
    id_number: "ID Number",
    status: "Status",
    date: "Date",
    initiated_by: "Initiated By",
};

const TabName = (id) => [
    {
        tabName: "Bulk Service Information",
        value: "Bulk_Service_Information",
        link: `/logs/bulk-verification-logs/${id}`,
    },
];

export default {
    mixins: [componentData],
    name: "BusinessRegistrationLogsDetails",
    data() {
        return {
            tabNames: TabName(this.$route.params.id),
            details: null,
            loading: true,
            rLoading: false,
            errorMsg: "",
            size: 25,
            filters: {},
            ...this.tableOptions(tableHeadings, 20),
        };
    },
    components: { TabsV2, Spinner, },
    computed: {
        tbClass() {
            return this.loading || this.errorMsg ? "hide__table" : "";
        },
    },
    methods: {
        formatNumber,
        handlClick({ row }) {
            this.$router.push(`/logs/verification-logs/${row.id_number}/ownership?id=${this.$route.params.id}`);
        },
        async fetchReportDetails() {
            const payload = {
                params: {
                    id: this.$route.params.id,
                },
            };

            try {
                const resp = (
                    await this.$store.dispatch("logs/getBusinessRegistrationLogDetails", payload)
                ).data;
                this.details = resp;
                this.loading = false;
                return resp;
            } catch (error) {
                this.loading = false;
                this.errorMsg = "Error fetching business reports";
            }
        },
        async refreshReportDetails() {
            this.loading = true;
            const payload = {
                params: {
                    id: this.$route.params.id,
                },
            };

            try {
                const resp = (
                    await this.$store.dispatch("logs/getBusinessRegistrationRefreshLogs", payload)
                ).data;
                this.details = resp;
                this.loading = false;
                return resp;
            } catch (error) {
                this.loading = false;
                this.errorMsg = "Error fetching business reports";
            }
        },
    },
    created() {
        this.fetchReportDetails();
    },
    mounted() {
        this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
    },
};
</script>


<style scoped>
.dashboard-briefs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    /* grid-template-rows: 170px; */
}
.dashboard-brief {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}

.call-count {
    font-size: 22px;
    font-weight: 600;
    line-height: 1;
    color: #071b2b;
    padding-top: 8px;
    margin-bottom: 20px;
}

.call-name {
    font-size: 15px;
    color: #081b2a;
    text-transform: uppercase;
}</style>