<template>
  <div class="px-3" v-if="details.registration_date">
    <div class="row">
      <div class="col-12 mt-3">
        <p class="font-weight-bold">
          Registered ownership as at
          {{ timeDateFormat(details.registration_date).date }} as shown below;
        </p>
      </div>
    </div>
    <div class="d-flex my-3 rounded">
      <div
        :style="{
          'background-color': random_hex_color_code(key),
          minWidth: '20px',
          maxHeight: '50px',
          width: (value.sharesCount / total) * 100 + '%',
        }"
        v-for="(value, key, index) in shareHolders"
        :key="index"
        class="shares-chart"
      >
        <div class="p-3 text-light ellipsis">
          {{ value.name }}
        </div>
        <div class="float-div p-4 shadow rounded">
          {{ value.name }} : {{ (value.sharesCount / total) * 100 }}%
        </div>
      </div>
    </div>
    <div>
      <p class="">*Person(s) of Significant Control</p>
      <br />
      <p class="">
        Ownership data might be inconsistent when the company has not filed
        recently
      </p>
    </div>
    <div class="mt-5" :class="tbClass">
      <div class="p-2 bg-light-blue mb-2">Shareholders</div>
      <v-server-table
        ref="shareHoldersRef"
        class="v-table"
        :columns="columns"
        :options="options"
        @loading="setLoading(true)"
        @loaded="setLoading(false)"
        @error="setError"
      >
        <template slot="action" slot-scope="{ row }">
          <button @click="showData({ row })" class="btn btn--primary">
            More Details
          </button>
        </template>
      </v-server-table>
    </div>
    <OwnershipModal :show="showModal" :data="modalData" :toggle="toggleModal" />
  </div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import OwnershipModal from "./OwnershipModal";
import "../style.css";

const tableHeadings = {
  name: "Name",
  designation: "Designation",
  action: "Action",
};

export default {
  name: "Ownership",
  mixins: [componentData],
  data() {
    return {
      loading: false,
      rLoading: false,
      showModal: false,
      modalData: null,
      shareHolders: null,
      total: null,
      errorMsg: "",
      size: 25,
      filters: {},
      ...this.tableOptions(tableHeadings, 20),
    };
  },
  components: { OwnershipModal },
  computed: {
    details() {
      return this.$store.state.logs.reportDetails;
    },
    tbClass() {
      return this.loading || this.errorMsg ? "hide__table" : "";
    },
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    //#Source https://bit.ly/2neWfJ2
    random_hex_color_code(k) {
      let n = (Math.random() * 0xfffff * 1000000 + k).toString(16);
      return "#" + n.slice(0, 6);
    },

    showData({ row }) {
      this.modalData = row;
      this.showModal = true;
    },
    setLoading(loading) {
      this.loading = loading;
      this.errorMsg = "";
    },
    setError(error) {
      this.errorMsg = error.message;
    },
    refetch(filters) {
      this.handleRefetch(filters, this.getReports, "shareHoldersRef");
    },
    clearFilter() {
      this.filters = {};
      this.$refs.shareHoldersRef.refresh();
    },
    async getShareHolders() {
      this.options.requestFunction = async (data) => {
        try {
          const parsedData = JSON.parse(this.details.key_personnel).filter(
            (dt) => dt.sharesValue
          );
          const sum = parsedData.reduce((accumulator, object) => {
            return accumulator + parseInt(object.sharesCount);
          }, 0);
          this.total = sum;
          this.shareHolders = parsedData;
          // console.log({ company: this.details, parsedData, sum });
          const resp = {
            total: parsedData.length,
            count: parsedData.length,
            data: parsedData,
            totalPages: length,
            currentPage: 1,
          };
          return { ...resp, count: resp.total };
        } catch (error) {
          console.log("transctionError", error);
        }
      };
    },
  },

  created() {
    this.getShareHolders();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
