import infrastructureProviders from "@/services/infrastructureProviders";

export default {
  namespaced: true,
  state: {
    serviceProviderParameters: {},
    serviceProviderPriority: {},
  },
  mutations: {},
  actions: {
    async getInfrastructureProviders() {
      try {
        let {
          data,
        } = await infrastructureProviders.GetInfrastructureProviders();
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async createInfrastructureProviders({ commit, dispatch }, payload) {
      try {
        let {
          data,
        } = await infrastructureProviders.CreateInfrastructureProviders(
          payload
        );
        console.log(
          "[data from get Infrastructure providers] ->",
          data.results
        );
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async updateInfrastructureProviders({ commit, dispatch }, payload) {
      try {
        let {
          data,
        } = await infrastructureProviders.UpdateInfrastructureProviders(
          payload
        );
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async getProviderParameters() {
      try {
        let { data } = await infrastructureProviders.GetProviderParameters();

        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async getAPIServices() {
      try {
        let { data } = await infrastructureProviders.GetAPIServices();

        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async createApiService({ commit, dispatch }, payload) {
      try {
        let { data } = await infrastructureProviders.CreateApiService(payload);

        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async createBulkApiService({ commit, dispatch }, payload) {
      try {
        let { data } = await infrastructureProviders.CreateBulkApiService(
          payload
        );

        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async updateApiService({}, payload) {
      try {
        let { data } = await infrastructureProviders.UpdateApiService(payload);
        return data;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async createApiServiceCategory({ commit, dispatch }, payload) {
      try {
        let { data } = await infrastructureProviders.CreateAPIServicesCategory(
          payload
        );

        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async updateApiServiceCategory({}, request) {
      try {
        let { data } = await infrastructureProviders.UpdateAPIServicesCategory(
          request
        );
        return data;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async deleteApiServiceCategory({}, request) {
      try {
        let { data } = await infrastructureProviders.DeleteAPIServicesCategory(
          request
        );
        return data;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async createProviderApiService({ commit, dispatch }, payload) {
      try {
        let { data } = await infrastructureProviders.CreateProviderApiService(
          payload
        );

        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async getAPIServicesCategory({ commit, dispatch }) {
      try {
        let { data } = await infrastructureProviders.GetAPIServicesCategory();

        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
  },
  getters: {},
};
