<template>
  <div>
    <button
      type="button"
      class="btn btn-lg btn-primary mr-3"
      @click="openAddProviderModal"
    >
      Make Inactive
    </button>
    <Modal
      id="makeInactive"
      title="Make Inactive"
      :toggle="closeModal"
      :loading="loading"
    >
      <h3>Are you sure you want to make inactive?</h3>
      <p>
        This would make this user inactive. Are you sure you want to continue?
      </p>
      <form @submit.prevent="makeInactive">
        <FormButtons
          :loading="loading"
          :handleCloseModal="closeModal"
          :closeBtnName="`No, Don't Make Inactive`"
          :btnName="`Yes, Make Inactive`"
        />
      </form>
    </Modal>
  </div>
</template>

<script>
import { notifications } from "../../../../../../../public/assets/mixins/notifications";
import Modal from "../../../../../../components/utils/Modal/Modal";
import FormButtons from "../../../../../../components/utils/FormButtons/FormButtons";
import CustomInput from "../../../../../../components/utils/CustomInput/CustomInput.vue";
import CustomSelect from "../../../../../../components/utils/CustomSelect/CustomSelect";

export default {
  mixins: [notifications],
  name: "MakeInactiveModal",
  data() {
    return {
      loading: false,
    };
  },
  props: ["teamInformation", "refetch"],
  methods: {
    openAddProviderModal() {
      $("#makeInactive").modal("show");
    },
    closeModal() {
      $("#makeInactive").modal("hide");
    },
    async makeInactive() {
      try {
        this.loading = true;
        let response = await this.$store.dispatch("customer/makeInactive", {
          id: this.teamInformation.user_id,
          clientid: this.$route.params.clientid,
        });

        this.loading = false;
        this.closeModal();
        this.refetch();
        this.showSuccessNotification(response.message);
      } catch (error) {
        this.loading = false;
        this.showErrorNotification(error.message);
      }
    },
  },
  components: {
    Modal,
    FormButtons,
    CustomInput,
    CustomSelect,
  },
};
</script>

<!-- please rebuild -->
