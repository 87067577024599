<template>
  <div>
    <SubscriptionDetailModal
      :show="showSubscriptionModal"
      :toggle="toggleSubscriptionModal"
      :singleSubscriptionObj="singleSubscriptionObj"
      :refetch="refetch"
    />
    <div class="px-3">
      <FilterWrapper>
        <template slot="filterForm">
          <SubscriptionFilter
            :loading="loading"
            :rLoading="rLoading"
            :submitFilter="refetch"
            :clearFilter="() => clearFilter('suscriptionRef')"
          />
        </template>
      </FilterWrapper>
    </div>
    <TableWrapper
      :loading="loading"
      :errorMsg="errorMsg"
      :refetch="fetchMerchantSuscriptions"
      :isEmpty="isEmpty"
      :emptyTitle="emptyTitle"
    >
      <div class="w-100 py-3" slot="content">
        <v-server-table
          ref="suscriptionRef"
          class="v-table row-pointer"
          :data="customers"
          :columns="columns"
          :options="options"
          @loaded="onLoaded"
          @row-click="handleClick"
        >
          <template slot="createdAt" slot-scope="{ row }">
            {{ row.createdAt | moment(" MM-DD-YYYY h:mm a") }}
          </template>
          <template slot="planName" slot-scope="{ row }">
            {{ titleCase(row.metadata.plan.name)  }}
          </template>
          <template slot="subscriptionType" slot-scope="{ row }">
            {{ titleCase(row.subscriptionType)  }}
          </template>
          <template slot="renewalDate" slot-scope="{ row }">
            {{ row.renewalDate | moment(" MM-DD-YYYY h:mm a") || 'N/A' }}
          </template>
          <template slot="name" slot-scope="{ row }">
           <span v-if="row.metadata.wallet"> {{ `${row.metadata.wallet.first_name} ${row.metadata.wallet.last_name}` }}</span><span v-else>N/A</span>
          </template>
          <template slot="walletRef" slot-scope="{ row }">
           <span v-if="row.option.walletRef"> {{ `${row.option.walletRef}` }}</span><span v-else>N/A</span>
          </template>
          <div slot="active" slot-scope="{ row }">
            <span
              class="badge badge-app"
              :class="row.active ? 'badge-success' : 'badge-danger'"
            >
              {{ row.active ? "Active" : "Inactive" }}
            </span>
          </div>
        </v-server-table>
      </div>
    </TableWrapper>
  </div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../../components/utils/TableWrapper/TableWrapper.vue";
import SubscriptionFilter from "../../../../components/filters/SubscriptionFilter";
import SubscriptionDetailModal from "../../../../views/Customers/modals/SubscriptionDetailModal.vue";

const plansHeadings = {
  "planName": "Plan",
  "name": "Name",
  subscriptionType: "Subscription Type",
  amount: "Amount",
  "walletRef": "Reference",
  createdAt: "Date Created",
  renewalDate: "Renewal Date",
  active: "Status",
};

export default {
  name: "WalletSuscription",
  mixins: [componentData],
  data() {
    return {
      singleSubscriptionObj: null,
      showSubscriptionModal: false,
      emptyTitle: "No Wallet Subscription Found",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(plansHeadings, 25),
    };
  },
  components: {
    TableWrapper,
    SubscriptionFilter,
    SubscriptionDetailModal,
  },
  methods: {
    toggleSubscriptionModal() {
      this.showSubscriptionModal = !this.showSubscriptionModal;
    },
    handleClick({ row }) {
      //   this.$router.push(`/customer/${row.client_id}`);
      this.singleSubscriptionObj = row;
      this.showSubscriptionModal = true;
    },
    async fetchMerchantSuscriptions() {
      this.options.requestFunction = async (data) => {
        const params = {
          ...data,
          ...this.filters,
          id: this.clientid,
          reference: this.reference, size: 25
        };
        const response = await this.$store.dispatch(
          "customer/getPaymentMerchantSuscriptions",
          {
            params,
          }
        );

        const newData = response.data.data;
        const count = response.data.count;
        return { ...response, count, total: count, data: newData };
      };
    },
    refetch(filters) {
      let newFilters = { ...filters };
      if (newFilters.subscriptionType)
        newFilters.subscriptionType = newFilters.subscriptionType.value;
      if (newFilters.active) newFilters.active = newFilters.active.value;
      this.handleRefetch(
        newFilters,
        this.fetchMerchantSuscriptions,
        "suscriptionRef"
      );
    },
  },

  props: ["clientid", "reference"],
  created() {
    this.fetchMerchantSuscriptions();
  },
  mounted() {
    this.columns = Object.keys(plansHeadings).map((keyVal) => keyVal);
  },
};
</script>
