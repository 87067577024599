<template>
    <div class="p-md-4 p-3">
      <PaymentTransactionDetailModal :show="showTransactionModal" :toggle="toggleTransactionModal"
        :singleTransactionObj="singleTransactionObj" :refetch="refetch" />
      <div class="SectionItem no-b-b mb-3">
        <div>
          <p class="title m-0">Payment Providers</p>
          <p class="text-left description">
            All Providers that are offering payment services
          </p>
        </div>
        <div>
          <button class="btn btn-lg btn-secondary-inverted" @click="downloadLinks()">
            <span><img src="/assets/img/icons/document-download.svg" alt="" /></span>
            Export CSV
          </button>
        </div>
      </div>
      <TableWrapper :loading="loading" :errorMsg="errorMsg" :refetch="fetchPaymentProviders" :isEmpty="isEmpty"
        :emptyTitle="emptyTitle">
        <div class="w-100 py-3" slot="content">
          <v-server-table ref="providersRef" class="v-table row-pointer" :data="customers" :columns="columns"
            :options="options" @loaded="onLoaded" @loading="setLoading(true)" @row-click="handleClick">
            <template slot="updatedAt" slot-scope="{ row }">
              {{ row.updatedAt | moment(" MM-DD-YYYY h:mm a") }}
            </template>
          </v-server-table>
        </div>
      </TableWrapper>
    </div>
</template>

<script>

import { componentData } from "../../../public/assets/mixins/componentData";
import TableWrapper from "../../components/utils/TableWrapper/TableWrapper.vue";
import PaymentMerchantFilter from "../../components/filters/PaymentMerchantFilter.vue";
import { formatNumber, titleCase } from "../../components/utils/helpers";
import PaymentTransactionDetailModal from "./modals/PaymentTransactionDetailModal.vue";

const tableHeadings = {
  id: "Transaction ID",
  name: "Name",
  updatedAt: "Date Created",
};

export default {
  name: "PaymentTransactions",
  mixins: [componentData],
  data() {
    return {
      singleTransactionObj: null,
      showTransactionModal: false,
      emptyTitle: "No Data",
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(tableHeadings, 25),
    };
  },
  components: {
    TableWrapper,
    PaymentMerchantFilter,
    PaymentTransactionDetailModal,
  },
  methods: {
    toggleTransactionModal() {
      this.showTransactionModal = !this.showTransactionModal;
    },
    handleClick({ row }) {
      this.$router.push(`/payment-providers/${row.slug}`);
    },
    formatNumber,
    titleCase,
    refetch(filters) {
      this.handleRefetch(filters, this.fetchPaymentProviders, "providersRef");
    },
    async fetchPaymentProviders() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters };
        const response = await this.$store.dispatch(
          "payment/getAllPaymentProviders",
          {
            params,
          }
        );
        const newData = response.data.data;
        const count = response.data.total;
        return { ...response, count, total: count, data: newData };
      };
    },
    async downloadLinks() {
      const download = (filterData) =>
        this.downloadReport(tableHeadings, filterData.data);
      this.downloadTableReport("payment/getAllPaymentProviders", download);
    },
  },
  created() {
    this.fetchPaymentProviders();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
