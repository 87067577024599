<template>
  <div class="page-content">
    <div class="container">
      <div class="card content-area" style="width: inherit">
        <div class="card-innr">
          <div class="card-head d-flex justify-content-between align-items-center">
            <h4 class="card-title mb-0">Transaction Details</h4>
            <a class="btn btn-sm btn-auto btn-primary d-sm-block d-none text-white" @click="back">
              <em class="fas fa-arrow-left mr-3"></em>Back
            </a>
            <a href="transactions.html" class="btn btn-icon btn-sm btn-primary d-sm-none">
              <em class="fas fa-arrow-left"></em>
            </a>
          </div>
          <div class="gaps-1-5x"></div>
          <div class="data-details d-md-flex">
            <div class="fake-class">
              <span class="data-details-title">Tranx Date</span>
              <span v-if="transactionData.
                created_at">
                {{ transactionData.
                created_at }}
              </span>
              <span class="data-details-info" v-else>N/A</span>
            </div>
            <div class="fake-class">
              <span class="data-details-title">Tranx Status</span>
              <span
                :class=" { 'badge badge-warning ucap text-capitalize':transactionData.transaction_status === 'pending',
                             'badge badge-info ucap text-capitalize':transactionData.transaction_status === 'otp',
                             'badge badge-secondary ucap text-capitalize':transactionData.transaction_status === 'abandoned',
                             'badge badge-danger ucap text-capitalize':transactionData.transaction_status === 'failed'
                             } "
                v-if="transactionData.transaction_status"
              >{{ transactionData.transaction_status }}</span>
              <span class="data-details-info" v-else>N/A</span>
            </div>
            <div class="fake-class">
              <span class="data-details-title">Tranx Approved Note</span>
              <span
                class="data-details-info"
                v-if="transactionData.provider_transaction_message"
              >{{ transactionData.provider_transaction_message }}</span>
              <span class="data-details-info" v-else>N/A</span>
            </div>
          </div>
          <div class="gaps-3x"></div>
          <h6 class="card-sub-title">Transaction Info</h6>
          <ul class="data-details-list">
            <li>
              <div class="data-details-head">Funding Source</div>
              <div class="data-details-des">
                <strong
                  v-if="transactionData.fundingSource"
                >{{ transactionData.fundingSource.funding_source_label }}</strong>
                <strong v-else>N/A</strong>
              </div>
            </li>
            <!-- li -->
            <li>
              <div class="data-details-head">Billing Model</div>
              <div class="data-details-des">
                <strong v-if="transactionData.billingModel">
                  {{ transactionData.billingModel.transfer_pricing_label }}
                  <small>- Transfer Pricing</small>
                </strong>
                <strong v-else>N/A</strong>
              </div>
            </li>
            <!-- li -->
            <li>
              <div class="data-details-head">Customer-Generated Ref.</div>
              <div class="data-details-des">
                <span
                  v-if="transactionData.customer_generated_reference"
                >{{ transactionData.customer_generated_reference }}</span>
                <span v-else>N/A</span>
              </div>
            </li>
            <!-- li -->
            <li>
              <div class="data-details-head">Transaction Ref.</div>
              <div class="data-details-des">
                <span
                  v-if="transactionData.transaction_reference"
                >{{ transactionData.transaction_reference }}</span>
                <span v-else>N/A</span>
              </div>
            </li>
            <!-- li -->
            <li>
              <div class="data-details-head">Recipient's Bank</div>
              <div class="data-details-des">
                <strong v-if="transactionData.bank">{{ transactionData.bank.name }}</strong>
                <strong v-else>N/A</strong>
              </div>
            </li>
            <!-- li -->
            <li>
              <div class="data-details-head">Recipient's Account Name</div>
              <div class="data-details-des">
                <strong
                  v-if="transactionData.recipient_account_name"
                >{{ transactionData.recipient_account_name }}</strong>
                <strong v-else>N/A</strong>
              </div>
            </li>
            <!-- li -->
            <li>
              <div class="data-details-head">Recipient's Account No.</div>
              <div class="data-details-des">
                <strong
                  v-if="transactionData.recipient_account_number"
                >{{ transactionData.recipient_account_number }}</strong>
                <strong v-else>N/A</strong>
              </div>
            </li>
            <!-- li -->
            <li>
              <div class="data-details-head">Amount Transferred</div>
              <div class="data-details-des">
                <span v-if="transactionData.amount">
                  {{ transactionData.amount }}
                  <small>- Nigerian Naira (NGN)</small>
                </span>
                <span v-else>N/A</span>
              </div>
            </li>
            <!-- li -->
          </ul>
          <!-- .data-details -->
          <div class="gaps-3x"></div>
          <h6 class="card-sub-title">Transaction Details</h6>
          <ul class="data-details-list">
            <li>
              <div class="data-details-head">Charge Fee</div>
              <div class="data-details-des">
                <strong v-if="transactionData.transactionFee">
                  {{ transactionData.transactionFee.charge_fee }}
                  <small>- Nigerian Naira (NGN)</small>
                </strong>
                <strong v-else>N/A</strong>
              </div>
            </li>
            <!-- li -->
            <li>
              <div class="data-details-head">Fee Transaction Ref.</div>
              <div class="data-details-des">
                <strong
                  v-if="transactionData.transactionFee"
                >{{ transactionData.transactionFee.fee_transaction_reference }}</strong>
                <strong v-else>N/A</strong>
              </div>
            </li>
            <!-- li -->
            <li>
              <div class="data-details-head">Transaction Device OS</div>
              <div class="data-details-des">
                <span
                  v-if="transactionData.transaction_device_os"
                >{{ transactionData.transaction_device_os}}</span>
                <span v-else>N/A</span>
              </div>
            </li>
            <!-- li -->
            <li>
              <div class="data-details-head">Transaction IP Address</div>
              <div class="data-details-des">
                <span
                  v-if="transactionData.transaction_ip_address"
                >{{ transactionData.transaction_ip_address }}</span>
                <span v-else>N/A</span>
              </div>
            </li>
            <!-- li -->
            <li>
              <div class="data-details-head">Transaction Location</div>
              <div class="data-details-des">
                <span
                  v-if="transactionData.transaction_location"
                >{{ transactionData.transaction_location }}</span>
                <span v-else>N/A</span>
              </div>
            </li>
            <!-- li -->
          </ul>
          <!-- .data-details -->
        </div>
      </div>
      <!-- .card -->
    </div>
    <!-- .container -->
  </div>
  <!-- .page-content -->
</template>

<script>
import AuthWrapper from '@/components/auth/AuthWrapper'
export default {
  components: {
    AuthWrapper
  },
  data() {
    return {
      isSubmitting: false,
      activeTab: "",
      transactionData: {}
    };
  },
  methods: {
    back() {
      history.back()
    },
    async getSingleTransaction() {
      let data = {
        id: this.$route.params.id,
        transaction_reference: this.$route.params.transaction_reference
      }
      try {
        await this.$store.dispatch("fundstransfer/getSingleTransaction", this.$route.params.transaction_reference).then((response) => {
          console.log("getSingleTransaction:", response);
          this.transactionData = response.data.result[0];
        });
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    }
  },
  async mounted() {
    await this.getSingleTransaction()
  },
};
</script>

<style scoped>
.card {
  border-radius: 4px;
  margin-bottom: 15px;
  border: none;
  background: #fff;
  transition: all 0.4s;
  vertical-align: top;
}
.card-shadow {
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.05);
}
.card-shadow:hover {
  box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.09);
}
.card-full-height {
  height: calc(100% - 15px);
}
.card-gradient-pri-sec {
  background-image: linear-gradient(45deg, #1c65c9 0%, #2c80ff 100%);
}
.card-innr {
  padding: 16px 20px;
  border-color: #e6effb !important;
}
.card-innr > .card-title {
  padding-bottom: 10px;
}
.card-innr > div:last-child:not(.input-item):not(.row):not(.step-head) {
  margin-bottom: 5px;
}
.account-info
  .card-innr
  > div:last-child:not(.input-item):not(.row):not(.step-head) {
  margin-bottom: 0;
}
.card-innr-fix {
  padding-bottom: 20px;
}
.card-title {
  color: #253992;
  font-size: 1.3em;
  font-weight: 500;
  margin: 0;
}
.card-title-lg {
  font-size: 1.6em;
}
.card-title-md {
  font-size: 1.3em;
}
.card-title-sm {
  font-size: 1.1em;
}
.card-title:last-child {
  margin-bottom: 0;
}
.card-title-text {
  width: 100%;
}
.card-title-text:not(:first-child) {
  margin-top: 4px;
}
.card-title + .btn-grp {
  margin-top: -5px;
}
.card-sub-title {
  text-transform: uppercase;
  color: #758698;
  letter-spacing: 0.1em;
  display: block;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 4px;
}
.card-head {
  padding-bottom: 18px;
}
.card-head.has-aside {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.card-head:last-child {
  padding-bottom: 0;
}
.card-opt {
  position: relative;
}
.card-footer {
  background: #f6f8fc;
}
.card .content:not(:first-child) {
  margin-top: 10px;
}
.card-token {
  background-image: linear-gradient(45deg, #1c65c9 0%, #2c80ff 100%);
  color: #fff;
}
.card-token .card-sub-title {
  color: #74fffa;
  margin-bottom: 4px;
}
.card-calc .note p {
  font-size: 11px !important;
}
.card-text-light {
  color: #fff;
}
.card-text-light .card-title,
.card-text-light .card-sub-title {
  color: #fff;
}
.card-text-light .card-opt > a {
  color: #fff !important;
}
.card-text-light .card-opt > a:after {
  border-top-color: #fff;
}
.card-text-light p {
  color: #fff;
}
.card-dark {
  background: #090d1c;
}
.card-primary {
  background: #2c80ff;
}
.card-secondary {
  background: #253992;
}
.card-success {
  background: #00d285;
}
.card-warning {
  background: #ffc100;
}
.card-info {
  background: #1babfe;
}
.card-danger {
  background: #ff6868;
}
.card-navs .card-innr {
  padding-top: 14px;
  padding-bottom: 14px;
}
.card-timeline .card-innr {
  height: calc(100% - 32px);
}
.data-details-head {
  font-size: 13px;
  font-weight: 500;
  color: #758698;
  line-height: 20px;
  padding: 15px 20px 2px;
  width: 100%;
}
.data-details-des {
  font-size: 14px;
  color: #495463;
  font-weight: 400;
  line-height: 20px;
  padding: 2px 20px 15px;
  flex-grow: 1;
  border-bottom: 1px solid #d2dde9;
  display: flex;
  justify-content: space-between;
}
.data-details-des .ti:not([data-toggle="tooltip"]),
.data-details-des [class*="fa"]:not([data-toggle="tooltip"]) {
  color: #2c80ff;
}
.data-details-des span:last-child:not(:first-child) {
  font-size: 12px;
  color: #758698;
}
.data-details-des small {
  color: #758698;
}
.data-details-des span,
.data-details-des strong {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.data-details-list {
  border-radius: 4px;
  border: 1px solid #d2dde9;
}
.data-details-list li {
  display: block;
}
.data-details-list li:last-child .data-details-des {
  border-bottom: none;
}
.data-details-title {
  font-size: 14px;
  font-weight: 600;
  color: #758698;
  line-height: 20px;
  display: block;
}
.data-details-info {
  font-size: 14px;
  font-weight: 400;
  color: #495463;
  line-height: 20px;
  display: block;
  margin-top: 6px;
}
.data-details {
  border-radius: 4px;
  padding: 18px 20px;
  border: 1px solid #d2dde9;
}
.data-details > div {
  flex-grow: 1;
  margin-bottom: 18px;
}
.data-details > div:last-child {
  margin-bottom: 0;
}

@media (min-width: 410px) {
  .card-token .token-balance-list li {
    min-width: 25%;
  }
}
@media (min-width: 576px) {
  .card {
    margin-bottom: 30px;
  }
  .card-title {
    font-size: 1.4em;
  }
  .card-title-sm {
    font-size: 1.1em;
    padding-top: 2px;
  }
  .card-innr {
    padding: 25px 30px;
  }
  .card-innr-fix {
    padding-bottom: 30px;
  }
  .card-innr-fix2 {
    padding-bottom: 10px;
  }
  .card-full-height {
    height: calc(100% - 30px);
  }
  .card-timeline .card-innr {
    height: calc(100% - 50px);
  }
  .data-details-head {
    width: 190px;
    padding: 14px 20px;
  }
  .data-details-des {
    border-top: none;
    border-left: 1px solid #d2dde9;
    width: calc(100% - 190px);
    padding: 14px 20px;
  }
  .data-details-list > li {
    display: flex;
    align-items: center;
  }
}
@media (min-width: 992px) {
  .card-navs .card-innr {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .card-token .token-balance-list li {
    min-width: 84px;
  }
}
</style>
