import { apiClient } from "./api";

export default {
	userAccounts() {
		return apiClient.get(`Account/ListUserProfiles`);
	},
	getDashboardAnalytics() {
		return apiClient.get(`Authorize/Admin/Dashboard`);
	},
	verifyCustomer(verificationData) {
		let headers = {
			clientid: verificationData.clientID,
		};
		return apiClient.put(
			`Authorize/Admin/VerifyCustomer`,
			verificationData,
			{ headers }
		);
	},
	getProfile() {
		return apiClient.get(`Authorize/AdminProfile`);
	},
	adminWLRegistration(credentials) {
		return apiClient.get(`Authorize/AdminWLRegistration/${credentials}`);
	},
	editProfile(profileDetails) {
		return apiClient.put(`Authorize/EditProfile`, profileDetails);
	},
	AdminEditSecondaryCustomerProfile(profileDetails) {
		return apiClient.put(
			`/Authorize/AdminEditSecondaryCustomer/${profileDetails.client_id}`,
			profileDetails.formattedResponse
		);
	},
	passwordChange(credential) {
		return apiClient.post(`Authorize/ResetPassword`);
	},
};
