<template>
  <div>
    <div class="d-flex flex__end py-4">
      <button type="button" class="btn btn-lg btn-primary mr-3" @click="openAddTeamModal">
        Add Team
      </button>
    </div>
    <Modal id="addTeam" title="Add Team" :toggle="closeModal" :loading="loading">
      <form @submit.prevent="addTeam">
        <div>
          <CustomInput :name="'email'" v-model="request.email" :label="`Email`" :placeholder="`Email`"
            :required="true" :handleError="() => { }" />
        </div>
        <CustomSelect name="role" placeholder="Select New Role" :optionLabel="'name'" :options="roles"
          v-model="request.role" />
        <FormButtons :loading="loading" :handleCloseModal="closeModal" :closeBtnName="`Close`" :btnName="`Add Team`" />
      </form>
    </Modal>
  </div>
</template>

<script>
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import Modal from "../../../../../components/utils/Modal/Modal";
import FormButtons from "../../../../../components/utils/FormButtons/FormButtons";
import CustomInput from "../../../../../components/utils/CustomInput/CustomInput.vue";
import CustomSelect from "../../../../../components/utils/CustomSelect/CustomSelect";

export default {
  mixins: [notifications],
  name: "AddTeamModal",
  data() {
    return {
      loading: false,
      request: { client_id: this.$route.params.clientid, email: '', role:'' },
      roles: [
        { name: "Admin", value: 2 },
        { name: "Operations", value: 3 },
        { name: "Developer", value: 4 },
        { name: "Customer Service", value: 5 },
      ],
    };
  },
  props: ["teamInformation", "refetch"],
  methods: {
    openAddTeamModal() {
      $("#addTeam").modal("show");
    },
    closeModal() {
      $("#addTeam").modal("hide");
    },
    async addTeam() {
      if (this.request.role) {
        try {
          this.loading = true;
        let response = await this.$store.dispatch(
          "customer/addCustomerTeamMember",
          {
            ...this.request,
            role: this.request.role.value,
          }
        );
        this.loading = false;
        this.closeModal();
        this.showSuccessNotification(response.message);
        } catch (error) {
          this.loading = false;
        this.showErrorNotification(error?.message);
        }
      }
    },
  },
  components: {
    Modal,
    FormButtons,
    CustomInput,
    CustomSelect,
  },
};
</script>
