<template>
	<div class="d-flex justify-content-center">
		<div class="text-center">
			<img src="/assets/img/success.svg" alt="" width="50px" />

			<h5 class="py-3">{{ message }}</h5>

			<slot />

			<button
				v-if="otherBtn"
				class="btn btn--sm btn--primary-outline px-5 fw-bold mr-3"
				@click="otherAction"
			>
				{{ otherBtn }}</button
			><button
				class="btn btn--sm btn--primary px-5 mx-auto fw-bold"
				@click="onSuccess"
			>
				Done
			</button>
		</div>
	</div>
</template>

<script>
	export default {
		name: "SuccessState",
		props: ["message", "onSuccess", "otherAction", "otherBtn"],
	};
</script>
