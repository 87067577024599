<template>
  <div>
    <TableWrapper
      :loading="loading"
      :errorMsg="errorMsg"
      :refetch="fetchMerchantSuscriptions"
      :isEmpty="isEmpty"
      :emptyTitle="emptyTitle"
    >
      <div class="w-100 py-3" slot="content">
        <v-server-table
          ref="invitesRef"
          class="v-table "
          :data="customers"
          :columns="columns"
          :options="options"
          @loaded="onLoaded"
        >
          <template slot="amount" slot-scope="{ row }">
             {{row.currency}}  {{ formatNumber(row.amount) }}
          </template>
          <template slot="charges" slot-scope="{ row }">
             {{row.currency}}  {{ formatNumber(row.charges) }}
          </template>
          <template slot="type" slot-scope="{ row }">
             {{titleCase(row.type)}} 
          </template>
          
          <template slot="createdAt" slot-scope="{ row }">
            {{ row.createdAt | moment(" MM-DD-YYYY h:mm a") }}
          </template>
        </v-server-table>
      </div>
    </TableWrapper>
  </div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../../../components/utils/TableWrapper/TableWrapper.vue";
import { formatNumber } from "../../../../../components/utils/helpers";

const plansHeadings = {
  reference: "Reference",
  type: "Type",
  amount: "Amount",
  charges: "Charges",
  createdAt: "Date Created",
};

export default {
  name: "Payouts",
  mixins: [componentData],
  data() {
    return {
      singleInviteObj: null,
      showSingleInviteModal: false,
      emptyTitle: "No Payout Found",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(plansHeadings, 25),
    };
  },
  components: {
    TableWrapper,
  },
  methods: {
    async fetchMerchantSuscriptions() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters, id: this.clientid };
        const response = await this.$store.dispatch(
          "customer/getPaymentMerchantPayouts",
          {
            params,
          }
        );

        const newData = response.data.data;
        const count = response.data.total;
        return { ...response, count, total: count, data: newData };
      };
    },
    formatNumber
  },

  props: ["clientid"],
  created() {
    this.fetchMerchantSuscriptions();
  },
  mounted() {
    this.columns = Object.keys(plansHeadings).map((keyVal) => keyVal);
  },
};
</script>
