<template>
  <div>
    <div class="px-3">
      <!-- <FilterWrapper>
        <template slot="filterForm">
          <MerchantWalletFilter
            :loading="loading"
            :rLoading="rLoading"
            :submitFilter="refetch"
            :clearFilter="() => clearFilter('walletsRef')"
          />
        </template>
      </FilterWrapper> -->
    </div>
    <TableWrapper
      :loading="loading"
      :errorMsg="errorMsg"
      :refetch="fetchServices"
      :isEmpty="isEmpty"
      :emptyTitle="emptyTitle"
    >
      <div class="w-100 py-3" slot="content">
        <v-server-table
          ref="walletsRef"
          class="v-table"
          :data="customers"
          :columns="columns"
          :options="options"
          @loading="setLoading(true)"
          @loaded="onLoaded"
        >
          
        </v-server-table>
      </div>
    </TableWrapper>
  </div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../../../components/utils/TableWrapper/TableWrapper.vue";
import MerchantWalletFilter from "../../../../../components/filters/MerchantWalletFilter";

const tableHeadings = {
  service_label: "Service",
  price: "Price",
};

export default {
  name: "Services",
  mixins: [componentData],
  data() {
    return {
      singleInviteObj: null,
      showSingleInviteModal: false,
      emptyTitle: "No Services Found",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(tableHeadings, 25),
    };
  },
  components: {
    TableWrapper,
    MerchantWalletFilter,
  },
  methods: {
    refetch(filters) {
      let newFilters = { ...filters };
      if (newFilters.status) newFilters.status = newFilters.status.value;
      this.handleRefetch(newFilters, this.fetchServices, "walletsRef");
    },
    async fetchServices() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters,   };
        const response = await this.$store.dispatch(
          "serviceProviders/getProviderServices",
          {
            params,
            id: this.$route.params.id,
          }
        );

        const newData = response.data;
        const count = response.data.length;
        return { ...response, count, total: count, data: newData };
      };
    },
  },

  props: ["clientid"],
  created() {
    this.fetchServices();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>