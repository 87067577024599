<template>
  <div class="az-content-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="SectionItem no-b-b mb-3">
          <div class="SectionHeader">
            <span class="title">Credit Logs</span>
            <div class="description">
              View all the transactions within the system
            </div>
          </div>
        </div>
      </div>
      <Loader v-if="isProcessing == true"></Loader>
      <div class="col-lg-12 card pt-3" v-else>
        <v-client-table
          ref="serverTableRef"
          name="question-table"
          class="v-table"
          :data="creditLogs"
          :columns="columns"
          :options="options"
          v-if="creditLogs.length > 0"
        >
          <template slot="payment_date" slot-scope="props">
            <span>{{
              props.row.payment_date | moment(" MM-DD-YYYY h:mm a")
            }}</span>
          </template>
          <template slot="credit_type" slot-scope="props">
            <span>{{ titleCase(props.row.credit_type) }}</span>
          </template>
          <template slot="organization_name" slot-scope="props">
            <span
              >{{ props.row.organization_name }}</span
            >
          </template>
          <template slot="loadedBy.firstname" slot-scope="props">
            <span v-if="props.row.loadedBy"
              >{{ props.row.loadedBy.firstname }}
              {{ props.row.loadedBy.lastname }}</span
            >
            <span v-else>Null</span>
          </template>
        </v-client-table>
        <DefaultContent
          v-if="creditLogs.length == 0"
          :image="'empty-resources.png'"
          :title="'No Credit Activity Found'"
          :subtitle="
            'Information about credit history would be populated once the information is made available.'
          "
        ></DefaultContent>
      </div>
    </div>
  </div>
</template>

<script>
import Nprogress from "nprogress";

import { componentData } from "../../../public/assets/mixins/componentData";
export default {
  mixins:[componentData],
  data() {
    return {
      creditLogs: [],
      isProcessing: true,
      columns: [
        "serial_no",
        "amount_credited",
        "loadedBy.firstname",
        "organization_name",
        "credit_type",
        "payment_date",
      ],
      options: {
        sortable: [],
        perPage: 25,
        pagination: {
          chunk: 5,
        },
        texts: {
          filter: "",
          filterPlaceholder: "Search..",
          noResults: "No matching records",
        },
        headings: {
          serial_no: "S/N",
          amount_credited: "Amount Credited",
          payment_date: "Date of Trnx",
          "loadedBy.firstname": "Loaded By",
          credit_type: "Credit Type",
          // "customer.client_id": "Client ID",
          "organization_name": "Organisation Name",
        },
      },
    };
  },
  methods: {
    async fetchLogs() {
      try {
        let apiResponse = await this.$store.dispatch("logs/getCreditLogs");
        let n = 1;
        let creditLogs = apiResponse.result.map((creditLog) => {
          return {
            serial_no: n++,
            amount_credited: creditLog.amount_credited,
            payment_date: creditLog.payment_date,
            loadedBy: creditLog.loadedBy,
            credit_type: creditLog.credit_type ?? "Null",
            organization_name: creditLog?.customer?.organization_name ?? 'Null',
          };
        });
        this.creditLogs = [...creditLogs];
        console.log("[log credit] ->", apiResponse.result);
        this.isProcessing = false;
        Nprogress.done();
      } catch (error) {
        console.log("[errors getting applications] ->", error);
        this.isProcessing = false;
        Nprogress.done();
      }
    },
  },
  mounted() {
    this.fetchLogs();
  },
};
</script>
