<template>
  <div class="az-content-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="SectionItem no-b-b mb-3">
          <div class="SectionHeader">
            <span class="title">
              <router-link
                :to="
                  `/payment-merchant/${this.$route.params.clientid}?tab=invoice`
                "
                class="nav-sub-link"
                ><img src="/assets/img/icons/close.svg" alt="image" height="30"
              /></router-link>
              Invoice Information</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 card p-4">
        <div class="">
          <div class="contact-details--card-row">
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">
                  Customer Name
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated ">
                  {{
                    titleCase(
                      invoiceInformation.other_info.customer.first_name
                    ) || "Null"
                  }}
                  {{
                    titleCase(invoiceInformation.other_info.customer.last_name)
                  }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">Email</div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{ invoiceInformation.other_info.customer.email || "Null" }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">
                  Phone Number
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{ invoiceInformation.other_info.customer.phone || "Null" }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">Issued Date</div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{
                    invoiceInformation.issued_date
                      | moment(" MM-DD-YYYY h:mm a")
                  }}
                </div>
              </span>
            </div>
          </div>
          <div class="contact-details--card-row mt-4 pt-4">
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">Due Date</div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{
                    invoiceInformation.due_date | moment(" MM-DD-YYYY h:mm a")
                  }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">Amount</div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{
                    toCurrency(
                      invoiceInformation.amount,
                      invoiceInformation.currency
                    ) || "Null"
                  }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">
                  Total Amount
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{ invoiceInformation.other_info.totalAmount || "Null" }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">Status</div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  <span :class="statusColor(invoiceInformation.status)">
                    {{ titleCase(invoiceInformation.status) }}</span
                  >
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="px-3">
      <FilterWrapper>
        <template slot="filterForm">
          <ItemsFilter
            :loading="loading"
            :rLoading="rLoading"
            :submitFilter="refetch"
            :clearFilter="() => clearFilter('invoiceRef')"
          />
        </template>
      </FilterWrapper>
    </div>
    <TableWrapper
      :loading="loading"
      :errorMsg="errorMsg"
      :refetch="fetchItems"
      :isEmpty="isEmpty"
      :emptyTitle="emptyTitle"
    >
      <div class="w-100 py-3" slot="content">
        <v-server-table
          ref="invoiceRef"
          class="v-table "
          :data="customers"
          :columns="columns"
          :options="options"
          @loading="setLoading(true)"
          @loaded="onLoaded"
          @row-click="handleClick"
        >
          <template slot="created_at" slot-scope="{ row }">
            {{ row.created_at | moment(" MM-DD-YYYY h:mm a") }}
          </template>
          <div slot="amount" slot-scope="{ row }">
            <span>{{ toCurrency(row.amount, row.currency) }}</span>
          </div>
          <div slot="quantity" slot-scope="{ row }">
            <span>{{ quantity || "N/A" }}</span>
          </div>
          <div slot="status" slot-scope="{ row }">
            <span :class="statusColor(row.status)">{{
              titleCase(row.status)
            }}</span>
          </div>
        </v-server-table>
      </div>
    </TableWrapper>
  </div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../../../components/utils/TableWrapper/TableWrapper.vue";
import ItemsFilter from "../../../../../components/filters/ItemsFilter.vue";

const tableHeadings = {
  reference: "Reference",
  name: "Name",
  amount: "Amount",
  quantity: "Quantity",
  created_at: "Date Created",
  status: "Status",
};

export default {
  name: "InvoiceDetail",
  mixins: [componentData],
  props: {
    clientid: {
      type: String,
    },
  },
  data() {
    return {
      invoiceInformation: null,
      emptyTitle: "No Invoice Found",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(tableHeadings, 25),
    };
  },
  methods: {
    refetch(filters) {
      this.handleRefetch(filters, this.fetchItems, "invoiceRef");
    },
    async fetchItems() {
      this.options.requestFunction = async (data) => {
        const params = {
          ...data,
          ...this.filters,
          ...this.$route.params,
          size: 25,
        };
        const response = await this.$store.dispatch(
          "payment/getMerchantInvoiceItems",
          {
            params,
          }
        );

        const newData = response.data.data;
        const count = newData.total;
        return { ...newData, count, total: count, data: newData.data };
      };
    },
    async fetchCurrentMerchantInfo() {
      const params = {
        ...this.$route.params,
      };
      const response = await this.$store.dispatch(
        "payment/getMerchantInvoiceDetail",
        {
          params,
        }
      );
      this.invoiceInformation = response.data;
    },
  },
  mounted() {
    this.fetchItems();
    this.fetchCurrentMerchantInfo();
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
  components: {
    TableWrapper,
    ItemsFilter,
  },
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
.merchant-title {
  font-family: "AllianzNeo";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 27px;
  color: #071b2b;
}
</style>
