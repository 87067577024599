<template>
  <div>
    <div class="px-3">
      <FilterWrapper>
        <template slot="filterForm">
          <TaxFilter
            :loading="loading"
            :rLoading="rLoading"
            :submitFilter="refetch"
            :clearFilter="() => clearFilter('taxRef')"
          />
        </template>
      </FilterWrapper>
    </div>
    <TableWrapper
      :loading="loading"
      :errorMsg="errorMsg"
      :refetch="fetchMerchantTaxes"
      :isEmpty="isEmpty"
      :emptyTitle="emptyTitle"
    >
      <div class="w-100 py-3" slot="content">
        <v-server-table
          ref="taxRef"
          class="v-table row-pointer"
          :data="customers"
          :columns="columns"
          :options="options"
          @loaded="onLoaded"
        >
          <template slot="name" slot-scope="{ row }">
            <div v-if="row.metadata.metadata">
              <span v-if="row.metadata.metadata.wallet"
                >{{ row.metadata.metadata.wallet.first_name }}
                {{ row.metadata.metadata.wallet.last_name }}</span
              >
              <span v-else>N/A</span>
            </div>
          </template>
          <template slot="plan" slot-scope="{ row }">
            {{ row.metadata.metadata.plan.name }}
          </template>
          <template slot="createdAt" slot-scope="{ row }">
            {{ row.createdAt | moment(" MM-DD-YYYY h:mm a") }}
          </template>
          <template slot="description" slot-scope="{ row }">
            {{ titleCase(row.description) }}
          </template>
          <div slot="status" slot-scope="{ row }">
            <span
              class="badge badge-app"
              :class="row.status ? 'badge-success' : 'badge-danger'"
            >
              {{ row.status ? "Active" : "Inactive" }}
            </span>
          </div>
        </v-server-table>
      </div>
    </TableWrapper>
  </div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../../../components/utils/TableWrapper/TableWrapper.vue";
import TaxFilter from "../../../../../components/filters/TaxFilter.vue";

const plansHeadings = {
  name: "Subcriber",
  plan: "Plan",
  title: "Title",
  description: "Description",
  createdAt: "Date Created",
  status: "Status",
};

export default {
  name: "Taxes",
  mixins: [componentData],
  data() {
    return {
      singleInviteObj: null,
      showSingleInviteModal: false,
      emptyTitle: "No Trails Found",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(plansHeadings, 25),
    };
  },
  components: {
    TableWrapper,
    TaxFilter,
  },
  methods: {
    async fetchMerchantTaxes() {
      this.options.requestFunction = async (data) => {
        const params = {
          ...data,
          ...this.filters,
          id: this.clientid,
          size: 25,
        };
        const response = await this.$store.dispatch(
          "customer/getPaymentMerchantTrails",
          {
            params,
          }
        );

        const newData = response.data.data;
        const count = response.data.count;
        return { ...response, count, total: count, data: newData };
      };
    },
    refetch(filters) {
      let newFilters = { ...filters };
      if (newFilters.type) newFilters.type = newFilters.type.value;
      this.handleRefetch(newFilters, this.fetchMerchantTaxes, "taxRef");
    },
  },

  props: ["clientid"],
  created() {
    this.fetchMerchantTaxes();
  },
  mounted() {
    this.columns = Object.keys(plansHeadings).map((keyVal) => keyVal);
  },
};
</script>
