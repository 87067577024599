<template>
  <Modal
    :id="id"
    title="Director's Details"
    :toggle="closeModal"
    :loading="loading"
  >
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <div>
            <label class="input-item-label">First Name</label>
          </div>
          <div>
            <CustomInput disabled :value="selected.first_name" />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div>
            <label class="input-item-label">Last Name</label>
          </div>
          <div>
            <CustomInput disabled :value="selected.last_name" />
          </div>
        </div>
      </div>
      <div class="col-md-6" v-if="selected.middle_name">
        <div class="form-group">
          <div>
            <label class="input-item-label">Middle Name</label>
          </div>
          <div>
            <CustomInput disabled :value="selected.middle_name" />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div>
            <label class="input-item-label">Email</label>
          </div>
          <div>
            <CustomInput disabled :value="selected.email" />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div>
            <label class="input-item-label">Address</label>
          </div>
          <div>
            <CustomInput disabled :value="selected.address" />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div>
            <label class="input-item-label">City</label>
          </div>
          <div>
            <CustomInput disabled :value="selected.city" />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div>
            <label class="input-item-label">State</label>
          </div>
          <div>
            <CustomInput disabled :value="selected.state" />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div>
            <label class="input-item-label">Country</label>
          </div>
          <div>
            <CustomInput disabled :value="selected.country" />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div>
            <label class="input-item-label">Address Two</label>
          </div>
          <div>
            <CustomInput disabled :value="selected.second_address" />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div>
            <label class="input-item-label">ID Type</label>
          </div>
          <div>
            <CustomInput disabled :value="selected.id_type" />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div>
            <label class="input-item-label">Id Number</label>
          </div>
          <div>
            <CustomInput disabled :value="selected.id_number" />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div>
            <label class="input-item-label">Date Issued</label>
          </div>
          <div>
            <CustomInput disabled :value="timeDateFormat(selected.issue_date).date" />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div>
            <label class="input-item-label">Expiry Date</label>
          </div>
          <div>
            <CustomInput disabled :value="timeDateFormat(selected.expiry_date).date" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6" v-if="selected.id_image">
        <div class="form-group">
          <div>
            <label class="input-item-label">Id Image</label>
          </div>
          <div>
            <div
              :class="{
                'link pointer': selected.id_image,
              }"
            >
              <a
                v-if="selected.id_image"
                :href="selected.id_image"
                download="Id Image"
                target="_blank"
              >
                Address Proof Link
              </a>
              <span v-else> Not Available</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6" v-if="selected.address_proof">
        <div class="form-group">
          <div>
            <label class="input-item-label">Address Proof</label>
          </div>
          <div>
            <div
              :class="{
                'link pointer': selected.address_proof,
              }"
            >
              <a
                v-if="selected.address_proof"
                :href="selected.address_proof"
                download="Proof of Address"
                target="_blank"
              >
                Address Proof Link
              </a>
              <span v-else> Not Available</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button @click="handleCloseModal" class="btn btn-primary">Close</button>
  </Modal>
</template>

<script>
import Modal from "../../../../components/utils/Modal/Modal";
import CustomInput from "../../../../components/utils/CustomInput/CustomInput.vue";
import { timeDateFormat } from "../../../../components/utils/helpers";

export default {
  name: "ComplianceDetailModal",
  data() {
    return {
      loading: false,
    };
  },
  props: ["id", "closeModal", "selected"],
  methods: {
    timeDateFormat,
    handleCloseModal() {
      this.closeModal();
    },
  },
  components: {
    Modal,
    CustomInput,
  },
};
</script>
