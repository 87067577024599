import moment from "moment";
import { Parser } from "json2csv";

export const getError = (error) => {
  const errorMessage =
    error && error.data
      ? titleCase(error.data.message.replace(/[^a-z0-9+]+/gi, " "))
      : "Error Processing Request. Try again.";

  return errorMessage;
};

export const getDateRange = (dateRange) => {
  switch (dateRange) {
    case "Today":
      return moment()
        .startOf("day")
        .format();
    case "This Week":
      return moment()
        .startOf("week")
        .format();
    case "This Month":
      return moment()
        .startOf("month")
        .format();
    case "This Year":
      return moment()
        .startOf("year")
        .format();
    default:
      return moment(new Date()).format();
  }
};

export const getTerminalsDateRangeFromValue = (dateRange) => {
  const to = moment(new Date()).format();
  switch (dateRange) {
    case "This Week":
      return {
        from: moment().startOf("week").format(),
        to,
        format: "week",
        frequency: "day",
      };
    case "Last Week":
      return {
        from: moment().subtract(1, "weeks").startOf("week").format(),
        to: moment().subtract(1, "weeks").endOf("week").format(),
        format: "week",
        frequency: "day",
      };
    case "This Month":
      return {
        from: moment().startOf("month").format(),
        to: moment().endOf("month").format(),
        format: "month",
        frequency: "day",
      };
    case "Last Month":
      return {
        from: moment().subtract(1, "months").startOf("month").format(),
        to: moment().subtract(1, "months").endOf("month").format(),
        format: "month",
        frequency: "day",
      };
    case "This Year":
      return {
        from: moment().startOf("year").format(),
        to: moment().endOf("year").format(),
        format: "year",
        frequency: "month",
      };
    case "Last Year":
      return {
        from: moment().subtract(1, "years").startOf("year").format(),
        to: moment().subtract(1, "years").endOf("year").format(),
        format: "year",
        frequency: "month",
      };
    default:
      return {};
  }
};

export const humanDateFormat = (date, variant = "long") => {
  if (!date || date === undefined) return "";
  let d = new Date(date);
  let day = d.getDate();
  let month = d.toLocaleString("en-us", { month: variant });
  let year = d.getFullYear();
  return day + " " + month + ", " + year;
};

export const timeDateFormat = (obj) => {
  if (!obj) return "";

  const time = moment(obj).format("hh:mm:ss a");
  const date = moment(obj).format("DD-MM-YYYY");
  return { time, date };
};

export const titleCase = (string) => {
  if (string === null || string === undefined) {
    return "";
  } else {
    let text = string.toLowerCase().split("_");
    for (var i = 0; i < text.length; i++) {
      text[i] = text[i][0].toUpperCase() + text[i].slice(1);
    }
    return text.join(" ");
  }
};

export const toCurrency = (n, code = "NGN") => {
  n = typeof n === "string" ? Number(n.replace(/[^0-9.]/g, "")) : n;

  if (n === 0 || n === null || n === undefined) return `${code} ${0}`;

  const getNumber = (value) => {
    return (
      `${code} ` +
      Number(value)
        .toFixed(2)
        .replace(/./g, function(c, i, a) {
          return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        })
    );
  };

  if (n.toString().startsWith("-")) {
    const newNumber = getNumber(Math.abs(n));
    return `-${newNumber}`;
  }

  return n ? getNumber(n) : "";
};

export const formatNumber = (n, float = true) => {
  n = typeof n === "string" ? Number(n.replace(/[^0-9.]/g, "")) : n;

  if (n === 0 || n === null || n === undefined) return `${0}`;

  return float
    ? Number(n)
        .toFixed(2)
        .replace(/./g, function(c, i, a) {
          return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        })
    : Number(n)
        .toFixed(0)
        .replace(/./g, function(c, i, a) {
          return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        });
};

export const copyToClipboard = (value) => {
  const tempInput = document.createElement("input");
  tempInput.value = value;
  document.body.appendChild(tempInput);
  tempInput.select();
  tempInput.focus();
  document.execCommand("copy");
  document.body.removeChild(tempInput);
  return true;
};

export const tableOptions = (
  headings,
  perPage = 25,
  perPageValues = [],
  showText
) => {
  const tabOpt = {};
  if (showText)
    tabOpt.texts = {
      filter: "",
      filterPlaceholder: "Search",
    };

  return {
    columns: [],
    options: {
      perPage,
      pagination: {
        chunk: 3,
        dropdown: false,
      },
      headings,
      perPageValues,
      filterable: showText ? true : false,
      ...tabOpt,
    },
  };
};

export const truncate = (str, len = 25) => {
  if (str.length > len) {
    return str.substring(0, len) + "...";
  } else {
    return str;
  }
};

export const getWalletApikey = (store) => {
  const keys = store.state.account.customerKeys;
  const dev = "development" || "dev";
  if (process.env.NODE_ENV === dev) return keys?.testApiKey;
  else return keys?.apiKey;
};

export const statusColor = (status) => {
  switch (status.toLowerCase()) {
    case "successful":
    case "success":
    case "sent":
    case "credit":
    case "approved":
    case "active":
    case "paid":
    case "posted":
    case "issued":
    case "verified":
    case "completed":
    case true:
      return "text-success";

    case "failed":
    case "debit":
    case "declined":
    case "expired":
    case "outstanding":
    case "draft":
    case false:
      return "text-danger";

    case "pending":
      return "text-primary";
    case "review":
      return "text-amber";

    default:
      return "";
  }
};
export const getPhone = (phone) => {
  if (phone) return phone.charAt(0) === "0" ? `+234${phone.slice(1)}` : phone;
  return "";
};

export const downloadReport = async (tableHeadings, data) => {
  try {
    let fields = Object.entries(tableHeadings).map((val) => ({
      label: val[1],
      value: val[0],
    }));

    const json2csvParser = new Parser({ fields });
    const csv = json2csvParser.parse(data);
    let blob = new Blob([csv], {
        type: "text/csv",
      }),
      url = window.URL.createObjectURL(blob);
    window.open(url);
  } catch (e) {
    this.showErrorLoginNotification("Error Downloading Report");
  }
};

export const getFilters = (filters) =>
  Object.fromEntries(
    Object.entries(filters).filter(([_, v]) => v != "" && v != null)
  );

export const getRequestData = (store, filters, data, size = 10000) => {
  const requestData = {
    apiKey: getWalletApikey(store),
    params: {
      size,
      limit: size,
      ...data,
      ...filters,
    },
  };
  return requestData;
};

export const timeRangeFormat = (obj, init) => {
  if (!obj) return null;

  const newDate = new Date(obj);
  init ? newDate.setHours(0, 0, 0, 0) : newDate.setHours(23, 59, 59, 0);
  return newDate;
};

const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const graphDateFormatter = (data) => {
  const date = new Date(data);
  return `${month[date.getMonth()]} ${date.getDay()}`;
};

export const fsStatFilter = [
  {
    name: "This Year",
    value: "thisYear",
  },
  {
    name: "Last Year",
    value: "lastYear",
  },
  {
    name: "This Month",
    value: "thisMonth",
  },
  {
    name: "Last Month",
    value: "lastMonth",
  },
  {
    name: "Weekly",
    value: "thisWeek",
  },
];
export const terminalStatFilter = [
  {
    name: "This Week",
    value: "This Week",
  },
  {
    name: "Last Week",
    value: "Last Week",
  },
  {
    name: "This Month",
    value: "This Month",
  },
  {
    name: "Last Month",
    value: "Last Month",
  },
  {
    name: "This Year",
    value: "This Year",
  },
  {
    name: "Last Year",
    value: "Last Year",
  },
];
export const providerStatFilter = [
  {
    name: "This week",
    value: "thisWeek",
  },
  {
    name: "Last week",
    value: "lastWeek",
  },
  {
    name: "This Month",
    value: "thisMonth",
  },
  {
    name: "Last Month",
    value: "lastMonth",
  },
  {
    name: "This Year",
    value: "thisYear",
  },
  {
    name: "Last Year",
    value: "lastYear",
  },
];
export const paymentStatus = [
  {
    name: "Failed",
    value: "failed",
  },
  {
    name: "Pending",
    value: "pending",
  },
  {
    name: "Successful",
    value: "successful",
  },
];
export const billsStatus = [
  {
    name: "Failed",
    value: "failed",
  },
  {
    name: "Success",
    value: "success",
  },
];

export const smsStatus = [
  {
    name: "Sent",
    value: "sent",
  },
  {
    name: "Failed",
    value: "failed",
  },
];

export const businessRegStatus = [
  {
    name: "approved",
    value: "approved",
  },
  {
    name: "pending",
    value: "pending",
  },
  {
    name: "queried",
    value: "queried",
  },
];

export const paymentPageStatus = [
  {
    name: "Expired",
    value: "expired",
  },
  {
    name: "Active",
    value: "active",
  },
];
export const auditCategories = [
  {
    name: "Account",
    value: "Account",
  },
  {
    name: "Applications",
    value: "Applications",
  },
  {
    name: "Service Provider",
    value: "Service Provider",
  },
];
export const disputeStatus = [
  {
    name: "Abandoned",
    value: "abandoned",
  },
  {
    name: "Pending",
    value: "pending",
  },
  {
    name: "Resolved",
    value: "resolved",
  },
];
export const subscriptionType = [
  {
    name: "One-Time",
    value: "one-time",
  },
  {
    name: "Recurring",
    value: "recurring",
  },
];
export const activeType = [
  {
    name: "Active",
    value: "true",
  },
  {
    name: "Inactive",
    value: "false",
  },
];
export const intervalType = [
  {
    name: "Hourly",
    value: "hourly",
  },
  {
    name: "Daily",
    value: "daily",
  },
  {
    name: "Weekly",
    value: "weekly",
  },
  {
    name: "Monthly",
    value: "monthly",
  },
];
export const taxType = [
  {
    name: "Included",
    value: "included",
  },
  {
    name: "Excluded",
    value: "excluded",
  },
];
export const keys = [
  {
    name: "Secret Key",
    value: "secretKey",
  },
  {
    name: "Public Key",
    value: "publicKey",
  },
];
export const httpMethods = [
  {
    name: "GET",
    value: "GET",
  },
  {
    name: "POST",
    value: "POST",
  },
  {
    name: "PATCH",
    value: "PATCH",
  },
  {
    name: "PUT",
    value: "PUT",
  },
  {
    name: "DELETE",
    value: "DELETE",
  },
];


export const identityMap = {
  dlInfo: { abbr: "DL", name: "Drivers Licence (DL)" },
  ninInfo: { abbr: "NIN", name: "National Identification Number (NIN)" },
  bvnInfo: { abbr: "BVN", name: "Bank Verification Number (BVN)" },
  pvcInfo: { abbr: "PVC", name: "Permanent Voter Card (PVC)" },
};
export const businessIdentityMap = {
  cacBasicInfo: { abbr: "Reg No.", name: "CAC Basic Search" },
  cacAdvancedInfo: { abbr: "Reg No.", name: "CAC Advanced Search" },
  tinInfo: { abbr: "Tax Id No..", name: "TIN Search" },
};

export const chartOptions = {
  chart: {
    type: "bar",
    height: 430,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      dataLabels: {
        position: "top",
      },
    },
  },
  dataLabels: {
    enabled: true,
    offsetX: -6,
    style: {
      fontSize: "12px",
      colors: ["#fff"],
    },
  },
  stroke: {
    show: true,
    width: 1,
    colors: ["#fff"],
  },
  tooltip: {
    shared: true,
    intersect: false,
  },
  xaxis: {
    categories: [],
  },
}
