<template>
  <div class="p-md-4 p-3">
    <FTtransactionDetailModal :show="showTransactionModal" :toggle="toggleTransactionModal"
      :singleTransactionObj="singleTransactionObj" :refetch="refetch" />
    <div class="SectionItem no-b-b mb-3">
      <div>
        <p class="title m-0">Transfer Transactions</p>
        <p class="text-left description">
          All transactions by Customers
        </p>
      </div>
      <div>
        <button class="btn btn-lg btn-secondary-inverted" @click="downloadLinks()">
          <span><img src="/assets/img/icons/document-download.svg" alt="" /></span>
          Export CSV
        </button>
      </div>
    </div>
    <div class="px-3">
      <FilterWrapper>
        <template slot="filterForm">
          <PaymentMerchantFilter :loading="loading" :rLoading="rLoading" :submitFilter="refetch"
            :clearFilter="() => clearFilter('providersRef')" />
        </template>
      </FilterWrapper>
    </div>
    <TableWrapper :loading="loading" :errorMsg="errorMsg" :refetch="fetchFTTransaction" :isEmpty="isEmpty"
      :emptyTitle="emptyTitle">
      <div class="w-100 py-3" slot="content">
        <v-server-table ref="providersRef" class="v-table row-pointer" :data="customers" :columns="columns"
          :options="options" @loaded="onLoaded" @loading="setLoading(true)" @row-click="handleClick">
          <template slot="firstName" slot-scope="{ row }">
            <p class="mb-0 ">
              {{ row.firstName || "Null" }}
            </p>
          </template>
          <template slot="amount" slot-scope="{ row }">
            &#8358;{{ formatNumber(row.amount) || "0" }}
          </template>
          <div slot="status" slot-scope="{ row }">
            <span class="badge badge-app" :class="row.responseCode == 'T100'
        ? 'badge-success'
        : row.responseCode == 'T133'
          ? 'badge-primary'
          : 'badge-danger'
      ">
              {{
              row.responseCode == "T100"
              ? "Success"
              : row.responseCode == "T133"
              ? "Pending"
              : "Failed"
              }}
            </span>
          </div>
          <template slot="email" slot-scope="{ row }">
            {{ row.email || "Null" }}
          </template>
          <template slot="updatedAt" slot-scope="{ row }">
            {{ row.updatedAt | moment(" DD-MM-YYYY h:mm a") }}
          </template>
        </v-server-table>
      </div>
    </TableWrapper>
  </div>
</template>

<script>
import { componentData } from "../../../public/assets/mixins/componentData";
import TableWrapper from "../../components/utils/TableWrapper/TableWrapper.vue";
import PaymentMerchantFilter from "../../components/filters/PaymentMerchantFilter.vue";
import { formatNumber } from "../../components/utils/helpers";
import FTtransactionDetailModal from "./modals/FTtransactionDetailModal.vue";

const tableHeadings = {
  transactionReference: "Transaction ID",
  amount: "Amount",
  accountName: "Baneficiary Name",
  accountNumber: "Baneficiary Account",
  status: "Status",
  updatedAt: "Date Created",
};

export default {
  name: "FTtransactions",
  mixins: [componentData],
  data() {
    return {
      singleTransactionObj: null,
      showTransactionModal: false,
      emptyTitle: "No Transaction Found",
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(tableHeadings, 25),
    };
  },
  components: {
    TableWrapper,
    PaymentMerchantFilter,
    FTtransactionDetailModal,
  },
  methods: {
    toggleTransactionModal() {
      this.showTransactionModal = !this.showTransactionModal;
    },
    handleClick({ row }) {
      this.singleTransactionObj = row;
      this.showTransactionModal = true;
    },
    formatNumber,
    refetch(filters) {
      this.handleRefetch(filters, this.fetchFTTransaction, "providersRef");
    },
    async fetchFTTransaction() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters };
        const response = await this.$store.dispatch(
          "fundstransfer/getFTtransactions",
          {
            params,
          }
        );
        const newData = response.data.data;
        const count = response.data.total;
        return { ...response, count, total: count, data: newData };
      };
    },
    async downloadLinks() {
      const download = (filterData) =>
        this.downloadReport(tableHeadings, filterData.data);
      this.downloadTableReport("fundstransfer/getFTtransactions", download);
    },
  },
  created() {
    this.fetchFTTransaction();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
