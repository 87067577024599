<template>
	<div>
		<img
			@click="openAddServiceCategory"
			class="pointer"
			src="/assets/img/edit.png"
			alt="update tax"
			width="25px"
		/>
		<AddServiceModal
			:id="service.id"
			:closeModal="closeModal"
			:service="service"
			:category="category"
			:countryList="countryList"
		/>
	</div>
</template>

<script>
	import AddServiceModal from "../../modals/AddServiceModal/AddServiceModal";

	export default {
		name: "UpdateServiceButton",
		props: ["service", "category", "countryList"],
		methods: {
			openAddServiceCategory() {
				$(`#${this.service.id}`).modal("show");
			},
			closeModal() {
				$(`#${this.service.id}`).modal("hide");
			},
		},
		components: {
			AddServiceModal,
		},
	};
</script>
