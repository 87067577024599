<template>
  <div class="p-md-4 p-3">
    <div class="SectionItem no-b-b mb-3">
      <div>
        <p class="title m-0">Providers</p>
        <p class="text-left description">
          View all api call logs on the system group by service
        </p>
      </div>
      <div>
        <button
          class="btn btn-lg btn-secondary-inverted"
          @click="downloadLinks()"
        >
          <span
            ><img src="/assets/img/icons/document-download.svg" alt=""
          /></span>
          Export CSV
        </button>
      </div>
    </div>
    <div class="px-3">
      <FilterWrapper>
        <template slot="filterForm">
          <ProvidersFilters
            :loading="loading"
            :rLoading="rLoading"
            :submitFilter="refetch"
            :clearFilter="() => clearFilter('providersRef')"
          />
        </template>
      </FilterWrapper>
    </div>
    <TableWrapper
      :loading="loading"
      :errorMsg="errorMsg"
      :refetch="fetchProviders"
      :isEmpty="isEmpty"
      :emptyTitle="emptyTitle"
    >
      <div class="w-100 py-3" slot="content">
        <v-server-table
          ref="providersRef"
          class="v-table row-pointer"
          :data="customers"
          :columns="columns"
          :options="options"
          @loaded="onLoaded"
          @loading="setLoading(true)"
          @row-click="handleClick"
        >
          <template slot="email" slot-scope="{ row }">
            {{ row.email || "Null" }}
          </template>
          <template slot="created_at" slot-scope="{ row }">
            <span v-if="row.created_at">{{
              row.created_at | moment(" MM-DD-YYYY h:mm a")
            }}</span>
            <span v-else>N/A</span>
          </template>
        </v-server-table>
      </div>
    </TableWrapper>
  </div>
</template>

<script>
import { componentData } from "../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../components/utils/TableWrapper/TableWrapper.vue";
import ProvidersFilters from "../filters/ProvidersFilters.vue";

const tableHeadings = {
  id: "Provider ID",
  provider_name: "Provider Name",
  service_count: "Service Count",
  created_at: "Date Created",
};

export default {
  name: "Providers",
  mixins: [componentData],
  data() {
    return {
      singleInviteObj: null,
      showSingleInviteModal: false,
      emptyTitle: "No Provider Found",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(tableHeadings, 25),
    };
  },
  components: {
    TableWrapper,
    ProvidersFilters,
  },
  methods: {
    handleClick({ row }) {
      this.$router.push(`/logs/providers/${row.id}`);
    },
    refetch(filters) {
      this.handleRefetch(filters, this.fetchProviders, "providersRef");
    },
    async fetchProviders() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters };
        const response = await this.$store.dispatch(
          "serviceProviders/getProviders",
          {
            params,
          }
        );
        const newData = response.data.data;
        const count = response.data.total;
        return { ...response, count, total: count, data: newData };
      };
    },
    async downloadLinks() {
      const download = (filterData) =>
        this.downloadReport(tableHeadings, filterData.data);
      this.downloadTableReport("serviceProviders/getProviders", download);
    },
  },
  created() {
    this.fetchProviders();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
