import serviceProviders from "@/services/serviceProviders";
import { apiClient } from "@/services/api";

export default {
  namespaced: true,
  state: {
    serviceProviderParameters: {},
    serviceProviderPriority: {},
    serviceList: [],
  },
  mutations: {
    setServiceProviderParameters(state, serviceProviderParameters) {
      state.serviceProviderParameters = serviceProviderParameters;
    },
    setServiceProviderPriority(state, serviceProviderPriority) {
      state.serviceProviderPriority = serviceProviderPriority;
    },
    setServiceList(state, serviceList) {
      state.serviceList = serviceList;
    },
  },
  actions: {
    async getServiceProviderParameters({ commit }) {
      try {
        let { data } = await serviceProviders.GetServiceProviderParameters();
        console.log(
          "[data from get service provider parameters] ->",
          data.results
        );
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async getProviders({ commit }, payload) {
      try {
        let { data } = await serviceProviders.GetProviders(payload);
        console.log(
          "[data from get service provider parameters] ->",
          data.results
        );
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async getProviderServices({ commit }, payload) {
      try {
        let { data } = await serviceProviders.GetProviderServices(payload);
        console.log("[data from get provider services] ->", data);
        commit("setServiceList", data.data);
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async getProviderCallLogs({ commit }, payload) {
      try {
        let { data } = await serviceProviders.GetProviderCallLogs(payload);
        console.log(
          "[data from get service provider parameters] ->",
          data.results
        );
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async deleteServicesProvider({ commit }, id) {
      try {
        let { data } = await serviceProviders.DeleteServicesProvider(id);
        console.log(
          "[data from get service provider parameters] ->",
          data.results
        );
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async getServiceProviderPriority({ commit }) {
      try {
        let { data } = await serviceProviders.GetServiceProviderPriority();
        console.log(
          "[data from get service provider priority] ->",
          data.results
        );
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async editServiceProviderParameters({ commit }, parameters) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let { data } = await serviceProviders.EditServiceProviderParameters(
          parameters
        );
        console.log(
          "[data from edit service provider parameters] ->",
          parameters
        );
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    setSingleParameter({ commit }, data) {
      commit("setServiceProviderParameters", data);
    },
    async editServiceProviderPriority({ commit }, priority) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let { data } = await serviceProviders.EditServiceProviderPriority(
          priority
        );
        console.log("[data from edit service provider Priority] ->", priority);
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    setSinglePriority({ commit }, data) {
      commit("setServiceProviderPriority", data);
    },
  },
  getters: {},
};
