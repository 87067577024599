<template>
  <div>
    <button
      type="button"
      class="btn btn-lg btn-danger mr-3"
      @click="openAddProviderModal"
    >
      Reject
    </button>
    <Modal
      id="rejectVerification"
      title="Reject User Details"
      :toggle="closeModal"
      :loading="loading"
    >
      <p>
        You are flagging this user and saying this user's details is not correct or seem incomplete. User will be logged out and won't be able to access dashboard
      </p>
      <form @submit.prevent="approveUser">
        <FormButtons
          :loading="loading"
          :handleCloseModal="closeModal"
          :closeBtnName="`No, Don't Reject`"
          :btnName="`Yes, Reject User`"
        />
      </form>
    </Modal>
  </div>
</template>

<script>
import { notifications } from "../../../../public/assets/mixins/notifications";
import Modal from "../../../components/utils/Modal/Modal";
import FormButtons from "../../../components/utils/FormButtons/FormButtons";

import customer from "../../../services/customer";
export default {
  mixins: [notifications],
  name: "RejectVerificationModal",
  data() {
    return {
      loading: false,
    };
  },
  props: ["merchantId"],
  methods: {
    openAddProviderModal() {
      $("#rejectVerification").modal("show");
    },
    closeModal() {
      $("#rejectVerification").modal("hide");
    },
    async approveUser() {
      this.loading = true;
      let { data } = await customer.ApprovePaymentMerchant({
        client_id: this.merchantId,
        approval: "declined",
      });
      this.loading = false;
      this.closeModal();
      this.showSuccessNotification("User Rejected");
      this.$router.go(-1);
    },
  },
  components: {
    Modal,
	FormButtons
  },
};
</script>
