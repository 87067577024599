<template>
    <div class="col-12 pt-4">
      <div class="SectionItem no-b-b mb-3">
        <div>
          <p class="title m-0">Payment Overview</p>
          <p class="text-left description">
            Payment Summary
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="dashboard-briefs">
            <div class="dashboard-brief rounded py-4">
              <h2 class="call-count">
                {{ toCurrency(overview.availableBalance) || "0" }}
              </h2>
              <p class="call-name">Available Balance</p>
            </div>
            <div class="dashboard-brief rounded py-4">
              <h2 class="call-count">
                {{ toCurrency(overview.totalBalance) || "0" }}
              </h2>
              <p class="call-name">Total Balance</p>
            </div>
            <div class="dashboard-brief rounded py-4">
              <h2 class="call-count">
                {{ formatNumber(overview.walletsCount, false) || "0" }}
              </h2>
              <p class="call-name">Total Wallet</p>
            </div>
            <div class="dashboard-brief  rounded py-4">
              <h2 class="call-count">
                {{ formatNumber(overview.payoutCount, false) || "0" }}
              </h2>
              <p class="call-name">Total Payout</p>
            </div>
            <div class="dashboard-brief  rounded py-4">
              <h2 class="call-count">
                {{ formatNumber(overview.pendingPayout, false) || "0" }}
              </h2>
              <p class="call-name">Pending Payout</p>
            </div>
            <div class="dashboard-brief  rounded py-4">
              <h2 class="call-count">
                {{ formatNumber(overview.reservedAccountCount, false) || "0" }}
              </h2>
              <p class="call-name">Total Reserved Account</p>
            </div>
            <div class="dashboard-brief  rounded py-4">
              <h2 class="call-count">
                {{ formatNumber(overview.bankPaymentTransaction, false) || "0" }}
              </h2>
              <p class="call-name">Bank Payment Transactions</p>
            </div>
            <div class="dashboard-brief  rounded py-4">
              <h2 class="call-count">
                {{ formatNumber(overview.cardPaymentTransaction, false) || "0" }}
              </h2>
              <p class="call-name">Card Payment Transactions</p>
            </div>
            <div class="dashboard-brief  rounded py-4">
              <h2 class="call-count">
                {{ formatNumber(overview.customersCount, false) || "0" }}
              </h2>
              <p class="call-name">Total Customers</p>
            </div>
            <div class="dashboard-brief  rounded py-4">
              <h2 class="call-count">
                {{ formatNumber(overview.disputeCount, false) || "0" }}
              </h2>
              <p class="call-name">Total Disputes</p>
            </div>
            <div class="dashboard-brief  rounded py-4">
              <h2 class="call-count">
                {{ formatNumber(overview.invoiceTransaction, false) || "0" }}
              </h2>
              <p class="call-name">Invoice Transactions</p>
            </div>
            <div class="dashboard-brief  rounded py-4">
              <h2 class="call-count">
                {{ formatNumber(overview.paymetLink, false) || "0" }}
              </h2>
              <p class="call-name">Payment Link</p>
            </div>
            <div class="dashboard-brief  rounded py-4">
              <h2 class="call-count">
                {{ formatNumber(overview.paymetLinkTransaction, false) || "0" }}
              </h2>
              <p class="call-name">Payment Link Transactions</p>
            </div>
            <div class="dashboard-brief  rounded py-4">
              <h2 class="call-count">
                {{ formatNumber(overview.paymetPage, false) || "0" }}
              </h2>
              <p class="call-name">Payment Page</p>
            </div>
            <div class="dashboard-brief  rounded py-4">
              <h2 class="call-count">
                {{ formatNumber(overview.paymetPageTransaction, false) || "0" }}
              </h2>
              <p class="call-name">Payment Page Transactions</p>
            </div>
            <div class="dashboard-brief  rounded py-4">
              <h2 class="call-count">
                {{ formatNumber(overview.walletTransaction, false) || "0" }}
              </h2>
              <p class="call-name">Wallet Transactions</p>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="dashboard-brief-chart p-4 ">
            <h2 class="call-count text-left">
              Payment Statistics
            </h2>
            <PaymentStatistics />
            <PaymentOverviewStatistics />
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {
  toCurrency,
  formatNumber,
  graphDateFormatter,
} from "../../components/utils/helpers";
import PaymentStatistics from "../../components/charts/PaymentStatistics.vue";
import PaymentOverviewStatistics from "../../components/charts/PaymentOverviewStatistics.vue";
export default {
  name: "PaymentOverview",
  components: { PaymentStatistics, PaymentOverviewStatistics },
  data() {
    return {
      overview: null,
      loading: true,
    };
  },
  methods: {
    toCurrency,
    formatNumber,
    graphDateFormatter,
    async fetchFTOverview() {
      const response = await this.$store.dispatch("payment/getPaymentOverview");
      this.overview = response.data;
      this.loading = false;
    },
  },
  created() {
    this.fetchFTOverview();
  },
};
</script>

<style scoped>
.dashboard-briefs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  /* grid-template-rows: 170px; */
}

.dashboard-brief {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}
.dashboard-brief-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}
.request-chart {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 600px; */
  box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}
/* .dashboard-brief:not(:last-of-type) {
  border-right: 1px solid #cccbcb;
} */
.spike {
  position: absolute;
  top: 70px;
  right: 50px;
}
.call-count {
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  color: #071b2b;
  padding-top: 8px;
  margin-bottom: 20px;
}
.call-name {
  font-size: 15px;
  color: #081b2a;
  text-transform: uppercase;
}
.bar-chart {
  width: 100%;
}
</style>
