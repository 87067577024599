<template>
	<div class="az-content-body">
		<div class="row">
			<div class="col-lg-12">
				<div class="SectionItem no-b-b">
					<div class="SectionHeader">
						<span class="title">All Transactions</span>
						<div class="description">
							View all the All Funds Transfer Transactions within
							the system
						</div>
					</div>
				</div>
			</div>

			<Loader v-if="isLoading == true"></Loader>
			<div class="col-lg-12 card pt-3" v-else>
				<v-client-table
					v-if="AllFTTransactions.length > 0"
					ref="serverTableRef"
					name="question-table"
					class="v-table"
					:data="AllFTTransactions"
					:columns="columns"
					:options="options"
				>
					<div slot="is_active" slot-scope="props">
						<span
							v-if="props.row.is_active == 1"
							class="badge badge-success badge-app"
							>Active</span
						>
						<span v-else class="badge badge-danger badge-app"
							>Inactive</span
						>
					</div>
					<div slot="id" slot-scope="props">
						<button
							@click="viewTransaction(props.row, props.index)"
							class="btn btn-dark btn-sm"
						>
							<i class="typcn typcn-eye-outline mr-1"></i>View
							Transaction
						</button>
					</div>
				</v-client-table>
				<DefaultContent
					v-else
					:image="'empty-resources.png'"
					:title="'No Transactions'"
					:subtitle="
						'There are currently no Transactions in the system, do check back later.'
					"
				></DefaultContent>
			</div>
		</div>
	</div>
</template>

<script>
	import Nprogress from "nprogress";

	export default {
		data() {
			return {
				isLoading: true,
				AllFTTransactions: [],
				columns: [
					"transaction_reference",
					"Amount",
					"created_at",
					"transaction_status",
					"id",
				],
				options: {
					sortable: [],
					perPage: 10,
					pagination: {
						chunk: 5,
					},
					texts: {
						filter: "",
						filterPlaceholder: "Search...",
						noResults: "No matching transactions",
						count:
							"Showing {from} to {to} of {count} transactions|{count} transactions|One transaction",
					},
					headings: {
						transaction_reference: "Transaction Ref.",
						transaction_status: "Transaction Status",
						Amount: "Amount",
						created_at: "Date of Transaction",
						id: "Actions",
					},
				},
			};
		},
		methods: {
			async getCustomerTransactions() {
				try {
					await this.$store
						.dispatch(
							"fundstransfer/getCustomerTransactions",
							this.$route.params.id
						)
						.then((res) => {
							console.log("[CustomerTransactions] ->", res);
							this.isLoading = false;
							Nprogress.done();
						});
				} catch (error) {
					Nprogress.done();
					this.isLoading = false;
					this.AllFTTransactions = [];
					console.log("[error] ->", error);
				}
			},
			viewTransaction(transactionInfo) {
				let id = transactionInfo.id;
				this.$router.push({
					name: "SingleFTTransaction",
					params: { id },
				});
			},
		},
		async mounted() {
			await this.getCustomerTransactions();
		},
	};
</script>

<style scoped>
	.fields--truncated {
		max-width: 200px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.user-details {
		display: flex;
	}
	.user-block {
		display: flex;
		flex-direction: column;
	}
	.user-img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		margin-right: 10px;
	}
	.user-info {
		font-weight: bold;
	}
	.user-sub {
		font-size: 15px;
	}
</style>
