<template>
    <div class="p-md-4 p-3">
        <div class="SectionItem no-b-b mb-3">
            <div class="flex__between w-100">
                <div>
                    <p class="title m-0">Subscriptions</p>
                    <p class="text-left description">
                        View, create and edit verification subscription plans
                    </p>
                </div>
                <button
                    type="button"
                    class="btn btn-sm btn--primary px-4 ml-3"
                    @click="openCreateSubPlan"
                >
                    Create Subscription Plan
                </button>
            </div>
        </div>
        <div class="px-3">
            <FilterWrapper>
                <template slot="filterForm">
                    <SubscriptionPlansFilter :loading="loading" :rLoading="rLoading" :submitFilter="refetch"
                        :clearFilter="() => clearFilter('subRef')" />
                </template>
            </FilterWrapper>
        </div>

        <TableWrapper :loading="loading" :errorMsg="errorMsg" :refetch="fetchSmartId" :isEmpty="isEmpty"
            :emptyTitle="emptyTitle">
            <div class="w-100 py-3" slot="content">
                <v-server-table ref="subRef" class="v-table" :data="customers" :columns="columns"
                    :options="options" @loaded="onLoaded"  >
                    <template slot="cycle" slot-scope="{ row }">
                        {{ titleCase(row.cycle) }}
                    </template>
                    <template slot="plan_name" slot-scope="{ row }">
                        {{ row.plan_name }}
                    </template>
                    <template slot="price" slot-scope="{ row }">
                        {{ toCurrency(row.price) }}
                    </template>
                    <template slot="action" slot-scope="{ row }">
                        <div class="d-flex">
                            <EditSubscriptionPlanButton
                            :sub="row"
                            :refetch="refetch"
                            />
                            <button class="btn btn-primary ml-3" @click="handleClick(row)">
                                View More
                            </button>
                        </div>
                    </template>
                    <template slot="services" slot-scope="{ row }">
                        <div style="height: 100px; overflow-y: scroll;">
                            <div class="d-flex" style="width: 400px; flex-wrap: wrap;">
                                <div  v-for="(service, i) in row.services"
                                    :key="i"><span
                                    
                                    class="badge badge-service badge-app ml-1 mb-1 mr-1"
                                >{{ service }}</span> </div>
                            </div>
                        </div>
                    </template>
                    <template slot="created_at" slot-scope="{ row }">
                        {{ timeDateFormat(row.created_at).date }}
                        {{ timeDateFormat(row.created_at).time }}
                    </template>
                </v-server-table>
            </div>
        </TableWrapper>
        <CreateSubscriptionPlanModal
            :closeModal="closeModal"
            :refetch="refetch"
        />
    </div>
</template>

<script>
import { componentData } from "../../../public/assets/mixins/componentData";
import SubscriptionPlansFilter from "./filters/SubscriptionPlansFilter.vue";
import TableWrapper from "../../components/utils/TableWrapper/TableWrapper.vue";
import CreateSubscriptionPlanModal from './modals/CreateSubscriptionPlanModal.vue'
import EditSubscriptionPlanButton from './buttons/EditSubscriptionPlanButton'

const tableHeadings = {
    plan_name: "Plan Name",
    cycle: "Plan Cycle",
    price: "Plan Price",
    api_call_limit: "Min. No of API Calls",
    services: "Services",
    created_at: "Date Created",
    action: "Action",
};

export default {
    name: "VerificationSubscriptions",
    mixins: [componentData],
    data() {
        return {
            emptyTitle: "No Subscription Found",
            loading: false,
            errorMsg: "",
            customers: [],
            ...this.tableOptions(tableHeadings, 25),
        };
    },
    components: {
        SubscriptionPlansFilter,
        TableWrapper,
        CreateSubscriptionPlanModal,
        EditSubscriptionPlanButton
    },
    methods: {
        refetch(filters) {
            this.handleRefetch(filters, this.fetchSmartId, "subRef");
        },
        handleClick(row ) {
            this.$router.push(`/verification-subscription/${row.id}`);
        },
        async fetchSmartId() {
            this.options.requestFunction = async (data) => {
                const params = { ...data, ...this.filters };
                const response = await this.$store.dispatch(
                    "services/getSubscriptionPlans",
                    {
                        params,
                    }
                );
                const newData = response.data.data;
                const count = response.data.total;
                return { ...response, count, total: count, data: newData };
            };
        },
        openCreateSubPlan() {
            $("#createSubPlan").modal("show");
        },
        closeModal() {
            $("#createSubPlan").modal("hide");
        },
    },
    created() {
        this.fetchSmartId();
    },
    mounted() {
        this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
    },
};
</script>