<template>
  <div class="contact-details--card">
    <div class="contact-details--card-row">
      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">Start Date</div>
        </span>
        <span class="field-value">
          <div
            class="contact-details--truncated"
          >{{ customer.created_at | moment(" MM-DD-YYYY h:mm a") }}</div>
        </span>
      </div>

      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">Last Update</div>
        </span>
        <span class="field-value">
          <div
            class="contact-details--truncated"
          >{{ customer.created_at | moment("MM-DD-YYYY h:mm a") }}</div>
        </span>
      </div>

      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">Status</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">
            <span v-if="customer.is_verified" class="badge badge-success">Verfied</span>
            <span v-else class="badge badge-danger">Not Verfied</span>
          </div>
        </span>
      </div>
    </div>

    <div class="contact-details--card-row">
      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">Name</div>
        </span>
        <span class="field-value">
          <div
            class="contact-details--truncated"
          >{{ customer.user.firstname }} {{ customer.user.lastname }}</div>
        </span>
      </div>

      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">First Name</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">{{ customer.user.firstname }}</div>
        </span>
      </div>

      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">Last Name</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">{{ customer.user.lastname }}</div>
        </span>
      </div>
    </div>

    <div class="contact-details--card-row">
      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">Email</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">{{ customer.user.email }}</div>
        </span>
      </div>

      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">Company/Organization</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">{{ customer.organization_name }}</div>
        </span>
      </div>

      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">Address</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">{{ customer.address }}</div>
        </span>
      </div>
    </div>

    <div class="contact-details--card-row">
      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">Country</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">{{customer.user.country.name}}</div>
        </span>
      </div>

      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">State</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">{{customer.user.state.state_label}}</div>
        </span>
      </div>

      <div class="contact-details--card-field">
        <span class="field-key">
          <div class="contact-details--truncated">City</div>
        </span>
        <span class="field-value">
          <div class="contact-details--truncated">{{ customer.user.city }}</div>
        </span>
      </div>
    </div>

    <div class="contact-details--card-row">
      <div class="contact-details--card-field" style="width: 100%">
        <span class="field-key">
          <div class="contact-details--truncated">Services</div>
        </span>
        <span class="field-value">
          <span
            v-for="(service, i) in activeServices"
            :key="i"
            class="badge badge-service badge-app ml-1 mb-1 mr-1"
          >{{ service.service_label }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object
    },
    activeServices: {
      type: Array
    }
  }
};
</script>

<style></style>
