import axios from "axios";
import Nprogress from "nprogress";

function getCookie(name) {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	const token = "";
	//return token;
	if (parts.length === 2)
		return parts
			.pop()
			.split(";")
			.shift();
}

export const apiClient = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL_V2,
	headers: {
		Accept: "application/json",
		Authorization: getCookie("CF_Authorization"),
	},
});

apiClient.interceptors.request.use((config) => {
	Nprogress.start();
	return config;
});
apiClient.interceptors.response.use((response) => {
	Nprogress.done();
	return response;
});
