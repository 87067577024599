<template>
  <div class="row">
    <div class="col-12">
      <transition mode="out-in" name="fade">
        <div>
          <!-- nav tabs -->
          <div class="row">
            <div class="col-12">
              <nav>
                <div
                  class="nav-tab__user-account nav nav-tabs"
                  style="top: -1px;"
                  id="nav-tab"
                  role="tablist"
                >
                  <a
                    data-toggle="tab"
                    id="nav-all-users-tab"
                    href="#nav-all-users"
                    role="tab"
                    aria-controls="nav-all-users"
                    aria-selected="false"
                    class="nav-link active"
                  >
                    <span
                      class="badge badge-pill badge-primary"
                      style="font-size:14px; font-weight: 500"
                    >1</span> Business Information
                  </a>
                  <a
                    data-toggle="tab"
                    id="nav-settings-tab"
                    href="#nav-transfer"
                    role="tab"
                    aria-controls="nav-transfer"
                    aria-selected="false"
                    class="nav-link"
                  >
                    <span
                      class="badge badge-pill badge-primary"
                      style="font-size:14px; font-weight: 500"
                    >2</span> Business Funding Source
                  </a>
                  <a
                    data-toggle="tab"
                    id="nav-settings-tab"
                    href="#nav-billing"
                    role="tab"
                    aria-controls="nav-billing"
                    aria-selected="false"
                    class="nav-link"
                  >
                    <span
                      class="badge badge-pill badge-primary"
                      style="font-size:14px; font-weight: 500"
                    >3</span>Preferred Billing
                  </a>
                  <a
                    data-toggle="tab"
                    id="nav-settings-tab"
                    href="#nav-access-config"
                    role="tab"
                    aria-controls="nav-access-config"
                    aria-selected="false"
                    class="nav-link"
                  >
                    <span
                      class="badge badge-pill badge-primary"
                      style="font-size:14px; font-weight: 500"
                    >4</span> Access Configuration
                  </a>
                  <!-- <a
                      data-toggle="tab"
                      id="nav-settings-tab"
                      href="#nav-complete-request"
                      role="tab"
                      aria-controls="nav-complete-request"
                      aria-selected="false"
                      class="nav-link"
                    >
                      <span
                        class="badge badge-pill badge-primary"
                        style="font-size:14px; font-weight: 500"
                      >5</span> Complete Request
                  </a>-->
                </div>
              </nav>
            </div>

            <div class="col-12 mt-4 tab-content" id="nav-tabContent" style="min-height: 450px;">
              <div
                class="dashboard rounded p-4 tab-pane fade show active"
                id="nav-all-users"
                role="tabpanel"
                aria-labelledby="nav-all-users-tab"
              >
                <h4>Business Details</h4>
                <h5
                  class="user-profile-email sub"
                >Provide the following information on your business.</h5>
                <form>
                  <div class="row mt-4">
                    <!-- Company/Merchant Name -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Company/Merchant Name</label>
                        <input
                          type="text"
                          v-model="business_info.company_name"
                          class="form-control input-bordered"
                          placeholder="Enter Company/Merchant Name"
                          disabled
                        />
                      </div>
                    </div>

                    <!-- RC No. -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">RC Number</label>
                        <input
                          type="text"
                          v-model="business_info.rc_no"
                          class="form-control input-bordered"
                          placeholder="Enter RC Number"
                          :disabled="validated"
                        />
                      </div>
                    </div>

                    <!-- CAC Reg No. -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">CAC Registration Number</label>
                        <input
                          type="text"
                          v-model="business_info.cac_reg_no"
                          class="form-control input-bordered"
                          placeholder="Enter CAC Registration Number"
                          :disabled="validated"
                        />
                      </div>
                    </div>

                    <!-- Annual Revenue -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Annual Revenue</label>
                        <v-select
                          :options="annualRevenue"
                          label="annual_revenue_label"
                          v-model="business_info.annual_revenue"
                          class="v-select"
                          placeholder="Select Annual Revenue"
                        ></v-select>
                      </div>
                    </div>

                    <!-- Business Type -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Business Type</label>
                        <v-select
                          :options="businessType"
                          label="business_type_label"
                          v-model="business_info.business_type"
                          class="v-select"
                          placeholder="Select Business Type"
                        ></v-select>
                      </div>
                    </div>
                    <!-- Business Phone Number -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Business Phone Number</label>
                        <input
                          type="text"
                          v-model="business_info.business_phone_no"
                          class="form-control input-bordered"
                          placeholder="Enter Business Phone Number"
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="gaps-2x"></div>
                      <button
                        class="btn btn-lg btn-primary mg-b-20"
                        @click.prevent="submitBusinessInformation"
                        ref="submitBtn"
                        type="submit"
                        :disabled="isSubmitting == true"
                      >
                        <span v-if="isSubmitting">...Saving Info</span>
                        <span v-else>Save Business Info</span>
                      </button>
                    </div>
                  </div>
                  <hr />
                  <h4 class="mt-3">Business Emails</h4>
                  <div class="row mt-4">
                    <!-- Business Email -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Business Email</label>
                        <input
                          type="text"
                          v-model="business_info.business_email"
                          class="form-control input-bordered"
                          placeholder="Enter Business Email"
                        />
                      </div>
                    </div>

                    <!-- Support Email -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Support Email</label>
                        <input
                          type="text"
                          v-model="business_info.support_email"
                          class="form-control input-bordered"
                          placeholder="Enter Support Email"
                        />
                      </div>
                    </div>

                    <!-- Chargeback Email -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Chargeback Email</label>
                        <input
                          type="text"
                          v-model="business_info.chargeback_email"
                          class="form-control input-bordered"
                          placeholder="Enter Chargeback Email"
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="gaps-2x"></div>
                      <button
                        class="btn btn-lg btn-primary mg-b-20"
                        ref="submitBtn"
                        type="submit"
                        @click.prevent="submitBusinessEmails"
                        :disabled="isSubmitting == true"
                      >
                        <span v-if="isSubmitting">...Saving Business Emails</span>
                        <span v-else>Save Business Emails</span>
                      </button>
                    </div>
                  </div>
                  <hr />

                  <h4 class="mt-3">Business Address</h4>
                  <div class="row mt-4">
                    <!-- Business Website -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Business Website</label>
                        <input
                          type="text"
                          v-model="business_info.business_website"
                          class="form-control input-bordered"
                          placeholder="Enter Business Website"
                        />
                      </div>
                    </div>

                    <!-- Building Number -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Building Number</label>
                        <input
                          type="text"
                          v-model="business_info.building_no"
                          class="form-control input-bordered"
                          placeholder="Enter Building Number"
                        />
                      </div>
                    </div>

                    <!-- Business Address -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Business Address</label>
                        <input
                          type="text"
                          v-model="business_info.building_address"
                          class="form-control input-bordered"
                          placeholder="Enter Business Address"
                        />
                      </div>
                    </div>

                    <!-- City -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">City</label>
                        <input
                          type="text"
                          v-model="business_info.city"
                          class="form-control input-bordered"
                          placeholder="Enter City"
                        />
                      </div>
                    </div>

                    <!-- State -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">State</label>
                        <v-select
                          :options="states"
                          label="state_label"
                          v-model="business_info.state"
                          class="v-select"
                          placeholder="Select State"
                        ></v-select>
                      </div>
                    </div>

                    <!-- Postal Code -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Postal Code</label>
                        <input
                          type="text"
                          v-model="business_info.postal_code"
                          class="form-control input-bordered"
                          placeholder="Enter Postal Code"
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="gaps-2x"></div>
                      <button
                        class="btn btn-lg btn-primary mg-b-20"
                        ref="submitBtn"
                        @click.prevent="submitBusinessAddressInfo"
                        type="submit"
                        :disabled="isSubmitting == true"
                      >
                        <span v-if="isSubmitting">...Saving Address Info</span>
                        <span v-else>Save Business Address Info</span>
                      </button>
                    </div>
                  </div>
                  <hr />
                  <h4 class="mt-3">Business Contacts</h4>
                  <div class="row mt-4">
                    <!-- Primary Contact First Name -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Primary Contact First Name</label>
                        <input
                          type="text"
                          v-model="business_info.primary_contact_first_name"
                          class="form-control input-bordered"
                          placeholder="Enter Primary Contact First Name"
                          disabled
                        />
                      </div>
                    </div>
                    <!-- Primary Contact Last Name -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Primary Contact Last Name</label>
                        <input
                          type="text"
                          v-model="business_info.primary_contact_last_name"
                          class="form-control input-bordered"
                          placeholder="Enter Primary Contact Last Name"
                          disabled
                        />
                      </div>
                    </div>
                    <!-- Primary Contact Email -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Primary Contact Email</label>
                        <input
                          type="text"
                          v-model="business_info.primary_contact_email"
                          class="form-control input-bordered"
                          placeholder="Enter Primary Contact Email"
                          disabled
                        />
                      </div>
                    </div>
                    <!-- Primary Contact Phone No. -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Primary Contact Phone No.</label>
                        <input
                          type="text"
                          v-model="business_info.primary_contact_phone_no"
                          class="form-control input-bordered"
                          placeholder="Enter Primary Contact Phone No."
                          disabled
                        />
                      </div>
                    </div>
                    <!-- Secondary Contact First Name -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Secondary Contact First Name</label>
                        <input
                          type="text"
                          v-model="business_info.secondary_contact_first_name"
                          class="form-control input-bordered"
                          placeholder="Enter Secondary Contact First Name"
                        />
                      </div>
                    </div>
                    <!-- Secondary Contact Last Name -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Secondary Contact Last Name</label>
                        <input
                          type="text"
                          v-model="business_info.secondary_contact_last_name"
                          class="form-control input-bordered"
                          placeholder="Enter Secondary Contact Last Name"
                        />
                      </div>
                    </div>
                    <!-- Secondary Contact Email -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Secondary Contact Email</label>
                        <input
                          type="text"
                          v-model="business_info.secondary_contact_email"
                          class="form-control input-bordered"
                          placeholder="Enter Secondary Contact Email"
                        />
                      </div>
                    </div>
                    <!-- Secondary Contact Mobile Telephone -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Secondary Contact Mobile Telephone</label>
                        <input
                          type="text"
                          v-model="business_info.secondary_contact_mobile_telephone"
                          class="form-control input-bordered"
                          placeholder="Enter Secondary Contact Mobile Telephone"
                        />
                      </div>
                    </div>
                    <!-- Secondary Contact Office Telephone -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Secondary Contact Office Telephone</label>
                        <input
                          type="text"
                          v-model="business_info.secondary_contact_office_telephone"
                          class="form-control input-bordered"
                          placeholder="Enter Secondary Contact Office Telephone"
                        />
                      </div>
                    </div>
                    <!-- Secondary Contact Designation -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Secondary Contact Designation</label>
                        <input
                          type="text"
                          v-model="business_info.secondary_contact_designation"
                          class="form-control input-bordered"
                          placeholder="Enter Secondary Contact Designation"
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="gaps-2x"></div>
                      <button
                        class="btn btn-lg btn-primary mg-b-20"
                        ref="submitBtn"
                        type="submit"
                        @click.prevent="submitBusinessContactInfo"
                        :disabled="isSubmitting == true"
                      >
                        <span v-if="isSubmitting">...Saving Business Contact Info</span>
                        <span v-else>Save Business Contact Info</span>
                      </button>
                    </div>
                  </div>
                  <hr />
                  <h4>Social Media Links</h4>
                  <!-- <h5
                        class="user-profile-email sub"
                  >Provide the virtual card given at the bank and the associated bank account.</h5>-->
                  <div class="row mt-4">
                    <!-- LinkedIn -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">LinkedIn Profile URL</label>
                        <input
                          type="text"
                          v-model="socials.linkedin_url"
                          class="form-control input-bordered"
                          placeholder="Enter LinkedIn Profile URL"
                        />
                      </div>
                    </div>

                    <!-- Facebook -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Facebook Profile URL</label>
                        <input
                          type="text"
                          v-model="socials.facebook_url"
                          class="form-control input-bordered"
                          placeholder="Enter Facebook Profile URL"
                        />
                      </div>
                    </div>

                    <!-- Instagram -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Instagram Profile URL</label>
                        <input
                          type="text"
                          v-model="socials.instagram_url"
                          class="form-control input-bordered"
                          placeholder="Enter Instagram Profile URL"
                        />
                      </div>
                    </div>

                    <!-- Twitter -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Twitter Profile URL</label>
                        <input
                          type="text"
                          v-model="socials.twitter_url"
                          class="form-control input-bordered"
                          placeholder="Enter Twitter Profile URL"
                        />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="gaps-2x"></div>
                      <button
                        class="btn btn-lg btn-primary mg-b-20"
                        ref="submitBtn"
                        type="submit"
                        @click.prevent="submitBusinessSocials"
                        :disabled="isSubmitting == true"
                      >
                        <span v-if="isSubmitting">...Saving Social Info</span>
                        <span v-else>Save Social Links</span>
                      </button>
                    </div>
                  </div>
                </form>
                <!-- <button class="btn btn-lg btn-primary mg-b-20" type="submit" @click="getInfo">
                      <span>Edit Business Info</span>
                </button>-->
              </div>
              <div
                class="tab-pane fade dashboard rounded p-4"
                id="nav-transfer"
                role="tabpanel"
                aria-labelledby="nav-settings-tab"
              >
                <h4>Business Funding Source</h4>
                <h5
                  class="user-profile-email sub"
                >Provide details about your business funding source account below.</h5>
                <form>
                  <div class="row col-md-10 mt-4">
                    <!-- Funding Source Options -->
                    <div
                      :class="{ 'col-md-12': showVirtualCard == false, 'col-md-6': showVirtualCard == true }"
                    >
                      <div class="form-group">
                        <label class="input-item-label">Funding Source Options</label>
                        <v-select
                          :options="[{id:1, funding_source_label: 'Bank'}]"
                          label="funding_source_label"
                          @input="checkFundingOption"
                          v-model="business_funding_source.funding_source_options"
                          class="v-select"
                          placeholder="Select Funding Source Option"
                          :disabled="showVirtualCard == true"
                        ></v-select>
                      </div>
                    </div>

                    <div class="col-md-6" v-if="showVirtualCard === true">
                      <div
                        class="card text-white mt-4"
                        style="max-width: 28rem; height:16rem; border-radius: 1.5em; background-image: linear-gradient( 109.6deg,  rgba(9,9,121,1) 11.2%, rgba(144,6,161,1) 53.7%, rgba(0,212,255,1) 100.2% );"
                      >
                        <div class="card-header d-flex justify-content-end">
                          <img src="/assets/img/blusalt-logo-light.svg" height="30" alt style />
                        </div>
                        <div class="card-body">
                          <img src="/assets/img/chip.svg" alt height="50" />
                          <div>
                            <p style="font-size: 2em;">{{ business_funding_source.virtual_card_no }}</p>
                            <h5 class="card-title">Virtual Card Number</h5>
                            <!-- class="shadow" -->
                          </div>
                          <div class="d-flex justify-content-end">
                            <img src="/assets/img/mastercard.svg" alt height="34" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row col-md-10 mt-4">
                    <template v-if="fundingTab === 'Bank' && showVirtualCard === false">
                      <!-- Bank List -->
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="input-item-label">Select your Bank</label>
                          <v-select
                            :options="bankList"
                            label="bank_name"
                            v-model="business_funding_source.funding_source_bank_code"
                            class="v-select"
                            placeholder="Select your bank"
                          ></v-select>
                        </div>
                      </div>

                      <!-- Funding Source Bank Branch -->
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="input-item-label">Funding Source Bank Branch</label>
                          <input
                            type="text"
                            v-model="business_funding_source.funding_source_bank_branch"
                            class="form-control input-bordered"
                            placeholder="Enter Funding Source Bank Branch"
                          />
                        </div>
                      </div>

                      <!-- Funding source a/c no -->
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="input-item-label">Funding Source Account Number</label>
                          <input
                            type="text"
                            v-model="business_funding_source.funding_source_acc_no"
                            @blur="verifyAccountNumber"
                            class="form-control input-bordered"
                            placeholder="Enter Funding Source Account Number"
                          />
                        </div>
                        <!-- <template v-if="accountVerification === true">
                              <small
                                style="color: rgb(0, 104, 46);"
                                v-if="verifyAccount.length"
                              >{{ verifyAccount[0].account_name }}</small>
                              <small
                                style="color:#ff6868;"
                                v-else
                              >Please Enter A Valid Account Number</small>
                        </template>-->
                      </div>

                      <!-- account name field -->
                      <div
                        :class="{ 'd-none': !accountVerification, 'col-md-6': accountVerification }"
                      >
                        <div class="form-group">
                          <label class="input-item-label">Account Name</label>
                          <input
                            type="text"
                            v-model="account_name"
                            class="form-control input-bordered"
                            placeholder="Enter Account Name"
                            disabled
                          />
                        </div>
                      </div>

                      <!-- Virtual Card No -->
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="input-item-label">Virtual Card Number</label>
                          <input
                            type="text"
                            v-model="business_funding_source.virtual_card_no"
                            class="form-control input-bordered"
                            placeholder="Enter Virtual Card Number"
                          />
                        </div>
                      </div>
                    </template>
                    <template v-if="showVirtualCard === false && fundingTab === 'Bank'">
                      <!-- mandate doc to bank -->
                      <div class="col-md-6" ref="uploadInput">
                        <div class="input-item">
                          <div class="input-wrap">
                            <label class="input-item-label">
                              Upload Mandate Document to Bank
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              type="file"
                              class="input-bordered"
                              accept=".pdf, .png, .jpeg, .jpg"
                              @change="selectMandateDoc"
                              ref="mandate_doc_ref"
                            />
                            <small
                              class="form-text text-muted"
                            >File must be in the format: .pdf, .png, .jpeg, or .jpg</small>
                          </div>
                          <!-- <ul class="parsley-errors-list filled" v-if="uploadSecondError">
                                <span class="text-danger">File size must be less than 5MB</span>
                          </ul>-->
                        </div>
                      </div>

                      <!-- auth doc to Blusalt -->
                      <div class="col-md-6" ref="uploadInput">
                        <div class="input-item">
                          <div class="input-wrap">
                            <label class="input-item-label">
                              Upload Authorization Document to Blusalt
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              type="file"
                              class="input-bordered"
                              accept=".pdf, .png, .jpeg, .jpg"
                              @change="selectAuthDocument"
                              ref="auth_document_ref"
                            />
                            <small
                              class="form-text text-muted"
                            >File must be in the format: .pdf, .png, .jpeg, or .jpg</small>
                          </div>
                          <!-- <ul class="parsley-errors-list filled" v-if="uploadSecondError">
                                <span class="text-danger">File size must be less than 5MB</span>
                          </ul>-->
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="gaps-2x"></div>
                        <button
                          class="btn btn-lg btn-primary mg-b-20"
                          type="submit"
                          ref="saveBtn"
                          @click.prevent="submitFundingSource"
                          :disabled="isSubmitting == true"
                        >
                          <span v-if="isSubmitting">...Saving Details</span>
                          <span v-else>Save Details</span>
                        </button>
                      </div>
                    </template>
                  </div>
                </form>
              </div>
              <div
                class="tab-pane fade dashboard rounded p-4"
                id="nav-billing"
                role="tabpanel"
                aria-labelledby="nav-settings-tab"
              >
                <h4>Billing</h4>
                <h5 class="user-profile-email sub">Set your preferred billing</h5>
                <!-- <form> -->
                  <div class="row mt-4">
                    <!-- Funds Transfer Billing -->
                    <div class="col-md-8">
                      <div class="form-group">
                        <label class="input-item-label">Funds Transfer Billing</label>
                        <v-select
                          :options="billingType"
                          label="transfer_pricing_label"
                          v-model="ft_billing"
                          @input="changeBillingTab"
                          class="v-select"
                          placeholder="Select Funds Transfer Billing"
                        ></v-select>
                      </div>
                    </div>
                  </div>
                  <div v-if="billingTab === 'amtThreshold'">
                    <div v-for="(billing, index) in billing_price" :key="index">
                      <div class="row mt-4">
                        <form class="form-inline col-md-12">
                          <label
                            class="sr-only"
                              for="inlineFormInputGroupMinimumPrice1"
                            >Minimum Price</label>
                            <div class="input-group mb-2 mr-sm-2">
                              <div class="input-group-prepend">
                                <div class="input-group-text">NGN</div>
                              </div>
                              <input
                                type="text"
                                class="form-control"
                                v-model="billing.ft_billing_min"
                                id="inlineFormInputGroupMinimumPrice1"
                                placeholder="Minimum Price"
                              />
                            </div>
                          <label
                            class="sr-only"
                            for="inlineFormInputGroupMaximumPrice2"
                          >Maximum Price</label>
                          <div class="input-group mb-2 mr-sm-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">NGN</div>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              v-model="billing.ft_billing_max"
                              id="inlineFormInputGroupMaximumPrice2"
                              placeholder="Maximum Price"
                            />
                          </div>
                          <label class="sr-only" for="inlineFormInputGroupMinimumPrice1">Billing Rate</label>
                          <div class="input-group mb-2 mr-sm-2">
                            <div class="input-group-prepend">
                              <div class="input-group-text">NGN</div>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              v-model="billing.ft_billing_rate"
                              id="inlineFormInputGroupMinimumPrice1"
                              placeholder="Billing Rate"
                            />
                          </div>
                        <button
                          class="btn btn-outline-primary ml-2 mb-2 add__btn"
                          @click.prevent="createThresholdBilling"
                        >
                          <i class="la la-plus mr-1"></i>Add
                        </button>
                        </form>
                        <!-- <span
                          class="badge badge-pill badge-primary"
                          style="height:fit-content;"
                          @click.prevent="removeForm"
                          v-if="newForm === true && billing_price.length > 1"
                        >
                          <i class="la la-minus"></i>
                        </span>
                        <h5 class="mr-2" v-if="newForm === true && billing_price.length > 1">Remove</h5> -->
                      </div>
                    </div>
                      <!-- <div class="gaps-2x"></div>
                  <button
                    class="btn btn-lg btn-primary"
                    type="submit"
                    @click.prevent="createThresholdBilling"
                    :disabled="isSubmitting == true"
                  >
                    <span v-if="isSubmitting">...Saving Details</span>
                    <span v-else>Save Details</span>
                  </button> -->
                  </div>
                  <div v-if="billingTab === 'volumeBilling'">
                    <div class="row mt-4">
                      <form class="form-inline col-md-8">
                        <label class="sr-only" for="inlineFormInputGroupMinimumPrice1">Transfer Rate</label>
                        <div class="input-group mb-2 mr-sm-2">
                          <div class="input-group-prepend">
                            <div class="input-group-text">NGN</div>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            v-model="billing_rate"
                            id="inlineFormInputGroupMinimumPrice1"
                            placeholder="Billing Rate"
                          />
                        </div>
                      </form>
                    </div>
                    <div class="gaps-2x"></div>
                  <button
                    class="btn btn-lg btn-primary"
                    type="submit"
                    @click="createVolumeBilling"
                    :disabled="isSubmitting == true"
                  >
                    <span v-if="isSubmitting">...Saving Details</span>
                    <span v-else>Save Details</span>
                  </button>
                  </div>
                <!-- </form> -->
              </div>
              <div
                class="tab-pane fade dashboard rounded p-4"
                id="nav-access-config"
                role="tabpanel"
                aria-labelledby="nav-settings-tab"
              >
                <h4>Access Configuration</h4>
                <h5
                  class="user-profile-email sub"
                >Provide details about your Access Configuration below.</h5>
                <form>
                  <div class="row mt-4">
                    <!-- IP Address -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label mb-0">IP Address</label>
                        <br />
                        <small>(IP Address to be whitelisted for access to the APIs)</small>
                        <input
                          type="text"
                          ref="ip_address"
                          v-model="access_config.ip_address"
                          class="form-control input-bordered mt-1"
                          placeholder="Enter IP Address (IP Address to be whitelisted for access to the APIs)"
                        />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="gaps-2x"></div>
                      <button
                        class="btn btn-lg btn-primary mg-b-20"
                        type="submit"
                        @click.prevent="validateIPaddress(access_config.ip_address)"
                        :disabled="isSubmitting == true"
                      >
                        <span v-if="isSubmitting">...Saving Details</span>
                        <span v-else>Save Details</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- End of transition -->
      </transition>
    </div>
  </div>
</template>

<script>
import Nprogress from "nprogress";
import { required, minLength, alphaNum, sameAs } from "vuelidate/lib/validators";
import AuthWrapper from "@/components/auth/AuthWrapper";
import { notifications } from "../../../public/assets/mixins/notifications";
import { modalMixin } from "../../../public/assets/mixins/modalMixin";

export default {
  props: {
    application: {
      type: Array,
    },
    services: {
      type: Array,
    },
  },
  mixins: [notifications, modalMixin],
  validations: {
    virtual_card: { required },
    apiVersion: { required },
    app: { required },
  },
  data() {
    return {
      transactionData: {},
      activeTab: "",
      fundingTab: "",
      billingTab: "amtThreshold",
      accountVerification: false,
      newForm: false,
      FTServiceInfo: true,
      validated: false,
      showButton: false,
      requestFTService: false,
      initializedAppResult: {},
      versions: ["v1", "v2"],
      app: "",
      apiVersion: "",
      call_id: "",
      clientID: "",
      hasNewResponse: false,
      logData: [],
      bankList: [],
      verifyAccount: [],
      account_name: "",
      apiResponse: "",
      mandate_doc: "",
      auth_document: "",
      business_info: {
        cac_reg_no: "",
        rc_no: "",
        company_name: "",
        business_type: "",
        business_email: "",
        primary_contact_first_name: "",
        primary_contact_last_name: "",
        primary_contact_email: "",
        primary_contact_phone_no: "",
        secondary_contact_first_name: "",
        secondary_contact_last_name: "",
        secondary_contact_email: "",
        secondary_contact_designation: "",
        secondary_contact_mobile_telephone: "",
        secondary_contact_office_telephone: "",
        support_email: "",
        chargeback_email: "",
        business_phone_no: "",
        business_website: "",
        building_no: "",
        building_address: "",
        city: "",
        state: "",
        postal_code: "",
        annual_revenue: "",
      },
      business_funding_source: {
        funding_source_bank_code: "",
        funding_source_bank_branch: "",
        funding_source_options: "",
        funding_source_acc_no: "",
        virtual_card_no: "",
      },
      socials: {
        linkedin_url: "",
        facebook_url: "",
        twitter_url: "",
        instagram_url: "",
      },
      ft_billing: "",
      billing_charge: "",
      billing_rate: "",
      billing_price: [
        {
          ft_billing_min: "",
          ft_billing_max: "",
          ft_billing_rate: "",
        },
      ],
      settings: {},
      access_config: {
        ip_address: "",
      },
      agreed_to_terms_conditions: "",
      submitted_business_info: "",
      submitted_authorization_document: "",
      submitted_mandate_document: "",
      submitted_funding_source_info: "",
      submitted_secondary_contact: "",
      whitelist_ip_address: "",
      status: "",
      index: "",
      // services
      servicePrice: "",

      isSubmitting: false,
      showVirtualCard: false,
      verificationResult: [],
      volumeColumns: ["transfer_rate"],
      thresholdColumns: ["min_range", "max_range", "transfer_rate"],
      thresholdOptions: {
        sortable: [],
        filterable: false,
        perPage: 10,
        pagination: {
          chunk: 5,
        },
        texts: {
          filter: "",
          filterPlaceholder: "Search...",
          noResults: "No matching billings",
          count: "Showing {from} to {to} of {count} billings|{count} billings|One billing",
        },
        headings: {
          min_range: "Minimum Range (NGN)",
          max_range: "Maximum Range (NGN)",
          transfer_rate: "Transfer Rate (NGN)",
        },
      },
      volumeOptions: {
        sortable: [],
        filterable: false,
        perPage: 10,
        pagination: {
          chunk: 5,
        },
        texts: {
          filter: "",
          filterPlaceholder: "Search...",
          noResults: "No matching billings",
          count: "Showing {from} to {to} of {count} billings|{count} billings|One billing",
        },
        headings: {
          transfer_rate: "Transfer Rate(NGN)",
        },
      },
    };
  },
  computed: {
    states() {
      return this.$store.state.metaData.countries[131].states;
    },
    annualRevenue() {
      return this.$store.state.metaData.settingsFtAnnualRevenue;
    },
    businessType() {
      return this.$store.state.metaData.settingsFtBusinessType;
    },
    fundingSource() {
      return this.$store.state.metaData.settingsFundingSource;
    },
    billingType() {
      return this.$store.state.metaData.settingsPricingDefinition;
    },
    thresholdBilling() {
      return this.$store.state.metaData.settingsDefaultThresholdBillingRate;
    },
    volumeBilling() {
      return this.$store.state.metaData.settingsDefaultVolumeBillingRate;
    },
    ftRequestMade() {
      return this.$store.state.account.profile.is_ft_request_made;
    },
    // status() {
    //   return this.$store.state.fundstransfer.businessInfo.requestApprovalSteps.status;
    // },
  },
  methods: {
    checkFundingOption() {
      if (this.business_funding_source.funding_source_options.funding_source_label === "Bank") {
        this.fundingTab = "Bank";
      } else {
        this.fundingTab = "";
        this.showButton = true;
      }
    },
    removeForm() {
      this.billing_price.pop({});
    },
    addForm() {
      this.billing_price.push({});
      this.newForm = true;
    },
    formatNumber(number) {
      let digit = "";
      number = number.toString();
      for (let i = 0; i < number.length; i++) {
        digit += number[i];
        if (i == 3 || i == 7 || i == 11) {
          digit += " - ";
        }
      }
      return digit;
    },
    changeBillingTab() {
      if (this.ft_billing.transfer_pricing_label === "Amount Threshold") {
        this.billingTab = "amtThreshold";
      } else {
        this.billingTab = "volumeBilling";
      }
    },
    selectMandateDoc() {
      let mandateFile = this.$refs.mandate_doc_ref.files[0];
      this.mandate_doc = mandateFile;
    },
    selectAuthDocument() {
      let authFile = this.$refs.auth_document_ref.files[0];
      this.auth_document = authFile;
    },
    // function to increase the width of upload progress by random numbers
    setUploadProgress() {
      let min = 15,
        max = 30;

      let randomNumber = Math.round(Math.random() * (max - min) + min);
      if (this.uploadProgress <= 100) {
        this.uploadProgress += randomNumber;
      }
    },
    validateIPaddress(ipaddress) {
      if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(this.access_config.ip_address)) {
        this.submitIP();
      } else {
        this.showErrorLoginNotification("You have entered an invalid IP address!");
        this.$refs.ip_address.borderColor = 'red';
      }
    },
    serializeRequestData(data) {
      let requestData = {
        clientID: this.$store.state.fundstransfer.businessInfo.apiCustomer.client_id,
        payload: data
      }
      console.log('request data ', requestData)
      return requestData
    },
    async submitBusinessInformation() {
      Nprogress.start();
      this.submitting = true;
      let data = {
        cac_registration_number: this.business_info.cac_reg_no,
        business_type_id: this.business_info.business_type.id,
        annual_revenue_id: this.business_info.annual_revenue.id,
        business_phone_number: this.business_info.business_phone_no,
        rc_number: this.business_info.rc_no,
      };
      let requestPayload = this.serializeRequestData(data)
      try {
        await this.$store.dispatch("fundstransfer/submitBusinessInformation", requestPayload);
        this.showSuccessNotification(data.message);
        this.$swal({
          title: `Business Information Submitted`,
          text: "Your Business Information have been submitted for review by the administrator",
          icon: "success",
          button: "Ok",
        }).then((value) => {
          // this.$refs.submitBtn.disabled = true;
        });
        console.log("[data from Details] ->", data);
        await this.getBusinessInfo();
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.submitting = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
    async submitBusinessContactInfo() {
      Nprogress.start();
      this.submitting = true;
      let data = {
        secondary_contact_firstname: this.business_info.secondary_contact_first_name,
        secondary_contact_lastname: this.business_info.secondary_contact_last_name,
        secondary_contact_designation: this.business_info.secondary_contact_designation,
        secondary_contact_office_telephone: this.business_info.secondary_contact_office_telephone,
        secondary_contact_mobile_telephone: this.business_info.secondary_contact_mobile_telephone,
        secondary_contact_email: this.business_info.secondary_contact_email,
      };
      let requestPayload = this.serializeRequestData(data);
      try {
        await this.$store.dispatch("fundstransfer/submitBusinessContactInfo", requestPayload);
        this.showSuccessNotification(data.message);
        this.$swal({
          title: `Business Contact Information Submitted`,
          text: "Your Business Contact Information has been submitted for review by the administrator",
          icon: "success",
          button: "Ok",
        }).then((value) => {
          // this.$refs.submitBtn.disabled = true;
        });
        console.log("[data from Details] ->", data);
        await this.getBusinessInfo();
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.submitting = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error);
      }
    },
    async submitIP() {
      Nprogress.start();
      this.submitting = true;
      let data = {
        whitelisted_ip: this.access_config.ip_address,
      };
      let requestPayload = this.serializeRequestData(data)
      try {
        await this.$store.dispatch("fundstransfer/submitIPDetails", requestPayload);
        this.showSuccessNotification(data.message);
        this.$swal({
          title: `Access Configuration Submitted`,
          text: "Your Access Configuration has been submitted for review by the administrator",
          icon: "success",
          button: "Ok",
        }).then((value) => {
          // this.$refs.submitBtn.disabled = true;
        });
        console.log("[data from Details] ->", data);
        await this.getBusinessInfo();
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.submitting = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
    async submitBusinessAddressInfo() {
      Nprogress.start();
      this.submitting = true;
      let data = {
        business_website: this.business_info.business_website,
        building_no: this.business_info.building_no,
        building_address: this.business_info.building_address,
        city: this.business_info.city,
        state_id: this.business_info.state.id,
        postal_code: this.business_info.postal_code,
      };
      let requestPayload = this.serializeRequestData(data);
      try {
        await this.$store.dispatch("fundstransfer/submitBusinessAddress", requestPayload);
        this.showSuccessNotification(data.message);
        this.$swal({
          title: `Business Address Information Submitted`,
          text: "Your Business Address Information has been submitted for review by the administrator",
          icon: "success",
          button: "Ok",
        }).then((value) => {
          // this.$refs.submitBtn.disabled = true;
        });
        console.log("[data from Details] ->", data);
        await this.getBusinessInfo();
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.submitting = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
    async submitBusinessEmails() {
      Nprogress.start();
      this.submitting = true;
      let data = {
        business_email: this.business_info.business_email,
        support_email: this.business_info.support_email,
        chargeback_email: this.business_info.chargeback_email,
      }
      let requestPayload = this.serializeRequestData(data)
      try {
        await this.$store.dispatch("fundstransfer/submitBusinessEmails", requestPayload);
        this.showSuccessNotification(data.message);
        this.$swal({
          title: `Business Emails Submitted`,
          text: "Your Business Emails has been submitted for review by the administrator",
          icon: "success",
          button: "Ok",
        }).then((value) => {
          // this.$refs.submitBtn.disabled = true;
        });
        console.log("[data from Details] ->", data);
        await this.getBusinessInfo();
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.submitting = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
    async createThresholdBilling() {
      console.log('billing_price:', this.billing_price);
      
      Nprogress.start();
      this.submitting = true;
      let data = {
          "transfer_rate":this.billing_price[0].ft_billing_rate,
          "min_range": this.billing_price[0].ft_billing_min,
          "max_range": this.billing_price[0].ft_billing_max
      };
      let requestPayload = {
        clientID: this.$store.state.fundstransfer.businessInfo.apiCustomer.client_id,
        id:this.$route.params.id,
        billing_id: this.ft_billing.id,
        payload: data
      };
      try {
        await this.$store.dispatch("fundstransfer/createBilling", requestPayload);
        this.showSuccessNotification(data.message);
        this.$swal({
          title: `Billing Type Submitted`,
          text: "The Billing Type has been submitted for review by the administrator",
          icon: "success",
          button: "Ok",
        }).then((value) => {
          // this.$refs.submitBtn.disabled = true;
        });
        console.log("[data from Details] ->", data);
        await this.getBusinessInfo();
        await this.getBilling();
        this.addForm();
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.submitting = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
    async createVolumeBilling() {
      Nprogress.start();
      this.submitting = true;
      let data = {
          "transfer_rate":this.billing_rate
      }
      let requestPayload = {
        clientID: this.$store.state.fundstransfer.businessInfo.apiCustomer.client_id,
        id:this.$route.params.id,
        billing_id: this.ft_billing.id,
        payload: data
      }
      try {
        await this.$store.dispatch("fundstransfer/createBilling", requestPayload);
        this.showSuccessNotification(data.message);
        this.$swal({
          title: `Billing Type Submitted`,
          text: "The Billing Type has been submitted for review by the administrator",
          icon: "success",
          button: "Ok",
        }).then((value) => {
          // this.$refs.submitBtn.disabled = true;
        });
        console.log("[data from Details] ->", data);
        await this.getBusinessInfo();
        await this.getBilling();
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.submitting = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
    async submitBusinessSocials() {
      Nprogress.start();
      this.submitting = true;
      let data = {
        linkedin_url: this.socials.linkedin_url,
        facebook_url: this.socials.facebook_url,
        twitter_url: this.socials.twitter_url,
        instagram_url: this.socials.instagram_url,
      };
      let requestPayload = this.serializeRequestData(data)
      try {
        await this.$store.dispatch("fundstransfer/submitBusinessSocials", requestPayload);
        this.showSuccessNotification(data.message);
        this.$swal({
          title: `Business Social Links Submitted`,
          text: "Your Business Social Links ha been submitted for review by the administrator",
          icon: "success",
          button: "Ok",
        }).then((value) => {
          // this.$refs.submitBtn.disabled = true;
        });
        console.log("[data from Details] ->", data);
        await this.getBusinessInfo();
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.submitting = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
    async getBusinessInfo() {
      try {
        await this.$store.dispatch("fundstransfer/getFTBusinessInfo", this.$route.params.id).then((response) => {
          console.log("getInfo:", response);

          let apiResponse = response.data.result[0];

          apiResponse.requestApprovalSteps.agreed_to_terms_conditions === 1 ? this.agreed_to_terms_conditions = true : this.agreed_to_terms_conditions = false;
          apiResponse.requestApprovalSteps.submitted_business_info === 1 ? this.submitted_business_info = true : this.submitted_business_info = false;
          apiResponse.requestApprovalSteps.submitted_authorization_document === 1 ? this.submitted_authorization_document = true : this.submitted_authorization_document = false;
          apiResponse.requestApprovalSteps.submitted_mandate_document === 1 ? this.submitted_mandate_document = true : this.submitted_mandate_document = false;
          apiResponse.requestApprovalSteps.submitted_funding_source_info === 1 ? this.submitted_funding_source_info = true : this.submitted_funding_source_info = false;
          apiResponse.requestApprovalSteps.submitted_secondary_contact === 1 ? this.submitted_secondary_contact = true : this.submitted_secondary_contact = false;
          apiResponse.requestApprovalSteps.whitelist_ip_address === 1 ? this.whitelist_ip_address = true : this.whitelist_ip_address = false;
          apiResponse.fundingSourceDetails.virtual_card_no == null ? this.showVirtualCard = false : this.showVirtualCard = true

          this.business_info.company_name = apiResponse.apiCustomer.organization_name;
          this.business_info.rc_no = apiResponse.rc_number;
          this.business_info.cac_reg_no = apiResponse.cac_registration_number;
          this.business_info.business_type = apiResponse.businessType;
          this.business_info.annual_revenue = apiResponse.revenue;
          this.business_info.business_phone_no = apiResponse.business_phone_number;
          this.business_info.business_email = apiResponse.business_email;
          this.business_info.support_email = apiResponse.support_email;
          this.business_info.chargeback_email = apiResponse.chargeback_email;
          this.business_info.business_website = apiResponse.business_website;
          this.business_info.building_no = apiResponse.building_no;
          this.business_info.building_address = apiResponse.building_address;
          this.business_info.city = apiResponse.city;
          this.business_info.state = apiResponse.state;
          this.business_info.postal_code = apiResponse.postal_code;
          this.business_info.primary_contact_first_name = apiResponse.apiCustomer.primaryContact.firstname
          this.business_info.primary_contact_last_name = apiResponse.apiCustomer.primaryContact.lastname
          this.business_info.primary_contact_email = apiResponse.apiCustomer.primaryContact.email
          this.business_info.primary_contact_phone_no = apiResponse.apiCustomer.primaryContact.phone_number
          this.business_info.secondary_contact_first_name = apiResponse.secondaryContact.secondary_contact_firstname
          this.business_info.secondary_contact_last_name = apiResponse.secondaryContact.secondary_contact_lastname
          this.business_info.secondary_contact_designation = apiResponse.secondaryContact.secondary_contact_designation
          this.business_info.secondary_contact_email = apiResponse.secondaryContact.secondary_contact_email
          this.business_info.secondary_contact_mobile_telephone = apiResponse.secondaryContact.secondary_contact_mobile_telephone
          this.business_info.secondary_contact_office_telephone = apiResponse.secondaryContact.secondary_contact_office_telephone
          this.socials.linkedin_url = apiResponse.ftBusinessSocialMediaInformation[3].pivot.social_media_link;
          this.socials.facebook_url = apiResponse.ftBusinessSocialMediaInformation[0].pivot.social_media_link;
          this.socials.twitter_url = apiResponse.ftBusinessSocialMediaInformation[1].pivot.social_media_link;
          this.socials.instagram_url = apiResponse.ftBusinessSocialMediaInformation[2].pivot.social_media_link;
          this.agreed_to_terms_conditions = apiResponse.requestApprovalSteps.agreed_to_terms_conditions;
          this.submitted_business_info = apiResponse.requestApprovalSteps.submitted_business_info;
          this.submitted_authorization_document = apiResponse.requestApprovalSteps.submitted_authorization_document;
          this.submitted_mandate_document = apiResponse.requestApprovalSteps.submitted_mandate_document;
          this.submitted_funding_source_info = apiResponse.requestApprovalSteps.submitted_funding_source_info;
          this.submitted_secondary_contact = apiResponse.requestApprovalSteps.submitted_secondary_contact;
          this.whitelist_ip_address = apiResponse.requestApprovalSteps.whitelist_ip_address;
          this.access_config.ip_address = apiResponse.whitelisted_ip;

          this.business_funding_source.funding_source_options = apiResponse.fundingSource.funding_source_label;
          this.business_funding_source.funding_source_acc_no = apiResponse.fundingSourceDetails.funding_source_account_no;
          this.business_funding_source.funding_source_bank_branch = apiResponse.fundingSourceDetails.funding_source_bank_branch;
          this.business_funding_source.funding_source_bank_code = apiResponse.fundingSourceDetails.bank;
          this.business_funding_source.virtual_card_no = this.formatNumber(apiResponse.fundingSourceDetails.virtual_card_no);
        });
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async submitFundingSource() {
      let increaseUploadPercentage = setTimeout(() => {
        this.setUploadProgress();
      }, 10);
      let uploadData = new FormData();
      uploadData.append("mandate_document_to_bank", this.mandate_doc);
      uploadData.append("authorization_document_to_blusalt", this.auth_document);
      Nprogress.start();
      this.submitting = true;
      let funding_data = {
        funding_source_id: this.business_funding_source.funding_source_options.id,
        funding_source_bank_code: this.business_funding_source.funding_source_bank_code.bank_code,
        funding_source_bank_branch: this.business_funding_source.funding_source_bank_branch,
        funding_source_account_no: this.business_funding_source.funding_source_acc_no,
        virtual_card_no: this.business_funding_source.virtual_card_no,
      };
      uploadData.append("funding_source_id", funding_data.funding_source_id);
      uploadData.append("funding_source_bank_branch", funding_data.funding_source_bank_branch);
      uploadData.append("funding_source_bank_code", funding_data.funding_source_bank_code);
      uploadData.append("funding_source_account_no", funding_data.funding_source_account_no);
      uploadData.append("virtual_card_no", funding_data.virtual_card_no);
      console.log("uploadData->", uploadData);
      let requestPayload = this.serializeRequestData(uploadData)
      try {
        await this.$store.dispatch("fundstransfer/submitFundingSource", requestPayload).then((res) => {
          this.uploadProgress = 100;
          setTimeout(() => {
            this.showSuccessNotification(res.data.message);
            this.submitting = false;
            clearTimeout(increaseUploadPercentage);
            this.uploadProgress = 10;
            console.log("res:", res);
          }, 100);
        });
        this.$swal({
          title: `Funding Source Submitted`,
          text: "Successfully submitted your funding source and related documents",
          icon: "success",
          button: "Ok",
        }).then((value) => {
          this.$refs.saveBtn.disabled = true;
        });
        console.log("[data from funding source] ->", uploadData);
        this.getBusinessInfo();
        this.showVirtualCard = true;
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.submitting = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
    async getBilling() {
      try {
        await this.$store.dispatch("fundstransfer/getBilling", this.$route.params.id).then((response) => {
          console.log("getBilling:", response);
          let apiResponse = response.data.results;
          if (apiResponse.length > 0) {
            this.ft_billing = apiResponse[0].billingOption;
            this.changeBillingTab();
          }
        });
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getBankList() {
      try {
        await this.$store.dispatch("fundstransfer/getBankList").then((response) => {
          console.log("getBankList:", response);
          this.bankList = response.data.results[0];
        });
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async verifyAccountNumber() {
      Nprogress.start();
      let data = {
        account_number: this.business_funding_source.funding_source_acc_no,
        bank_code: this.business_funding_source.funding_source_bank_code.bank_code,
      };
      try {
        await this.$store.dispatch("fundstransfer/verifyAccountNumber", data).then((response) => {
          this.verifyAccount = response.data.results[0];
          this.account_name = this.verifyAccount.account_name;
          this.accountVerification = true;
          this.accountValidated = true;
        });
        this.isLoading = false;
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        console.log(error);
      }
    },
  },
  async mounted() {
    this.clientID = await this.$store.state.account.clientID;
    await this.getBusinessInfo();
    this.access_config.ip_address = this.$store.state.fundstransfer.businessInfo.whitelisted_ip;
    this.business_funding_source.virtual_card_no = this.$store.state.fundstransfer.businessInfo.fundingSourceDetails.virtual_card_no;
    await this.getBankList();
    await this.getBilling();
    // this.status = status;
  },
};
</script>
<style scoped>
/* .shadow {
  -moz-box-shadow: inset 0 0 10px #000000;
  -webkit-box-shadow: inset 0 0 10px #000000;
  box-shadow: inset 0 0 10px #000000;
} */
.v-table.hidden-xs.VueTables.VueTables--client > .VueTables__search-field {
  display: none !important;
}
.v-select.vs__dropdown-toggle {
  padding: 2px 15px !important;
}
.add__btn {
  display: none !important;
}
.add__btn:last-child {
  display:inline-block !important;
}
</style>
