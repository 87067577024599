import payment from "@/services/payment";
import { apiClient } from "@/services/api";

apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async getPaymentOverview({ commit }, query) {
      try {
        let { data } = await payment.GetPaymentOverview(query);
        console.log("[data from get customers] ->", data.result);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentTransactions({ commit }, query) {
      try {
        let { data } = await payment.GetPaymentTransactions(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getAllPaymentDisputes({ commit }, query) {
      try {
        let { data } = await payment.GetAllPaymentDisputes(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async updatePaymentDispute({ commit }, query) {
      let { data } = await payment.UpdatePaymentDispute(query);
      return data;
    },
    async getPaymentChargebacks({ commit }, query) {
      try {
        let { data } = await payment.GetPaymentChargebacks(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentRefund({ commit }, query) {
      try {
        let { data } = await payment.GetPaymentRefund(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getAllPaymentPayouts({ commit }, query) {
      try {
        let { data } = await payment.GetAllPaymentPayouts(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPayoutDetail({ commit }, query) {
      try {
        let { data } = await payment.GetPayoutDetail(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getProviderTransactions({ commit }, query) {
      try {
        let { data } = await payment.GetProviderTransactions(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPayoutTransactions({ commit }, query) {
      try {
        let { data } = await payment.GetPayoutTransactions(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getAllPaymentProviders({ commit }, query) {
      try {
        let { data } = await payment.GetAllPaymentProviders(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentProvidersDetails({ commit }, query) {
      try {
        let { data } = await payment.GetPaymentProvidersDetails(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentStatistics({ commit }, query) {
      try {
        let { data } = await payment.GetPaymentStatistics(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentOverviewStatistics({ commit }, query) {
      try {
        let { data } = await payment.GetPaymentOverviewStatistics(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getCustomerTransactions({ commit }, query) {
      try {
        let { data } = await payment.GetCustomerTransactions(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getCustomerWallet({ commit }, query) {
      try {
        let { data } = await payment.GetCustomerWallet(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getMerchantInvoice({ commit }, query) {
      try {
        let { data } = await payment.GetMerchantInvoice(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getMerchantInvoiceDetail({ commit }, query) {
      try {
        let { data } = await payment.GetMerchantInvoiceDetail(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getMerchantInvoiceItems({ commit }, query) {
      try {
        let { data } = await payment.GetMerchantInvoiceItems(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getCustomerInvoice({ commit }, query) {
      try {
        let { data } = await payment.GetCustomerInvoice(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getCustomerInvoiceStatement({ commit }, query) {
      try {
        let { data } = await payment.GetCustomerInvoiceStatement(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getCustomerSubscriptions({ commit }, query) {
      try {
        let { data } = await payment.GetCustomerSubscriptions(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async verifyTerminalMerchant({ commit }, query) {
      try {
        let { data } = await payment.VerifyTerminalMerchant(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getMerchantTerminal({ commit }, query) {
      try {
        let { data } = await payment.GetMerchantTerminal(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async generateTerminalID({ commit }, query) {
      try {
        let { data } = await payment.GenerateTerminalID(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async activateTerminalMerchant({ commit }, query) {
      try {
        let { data } = await payment.ActivateTerminalMerchant(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async addTerminal({ commit }, query) {
      try {
        let { data } = await payment.AddTerminal(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async addBulkTerminal({ commit }, query) {
      try {
        let { data } = await payment.AddBulkTerminal(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async updateAppBundleId({ commit }, query) {
      try {
        let { data } = await payment.UpdateAppBundleId(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
  },
};
