<template>
	<div class="p-md-4 p-3">
		<div class="SectionItem d-block no-b-b mb-4">
			<div class="flex__between">
				<p class="title m-0">Services</p>
				<AddServiceCategoryButton />
			</div>
			<p class="description">
				View all the categories and services under them
			</p>
		</div>

		<template v-for="(apiServices, index) in getApiServices">
			<div
				class="app-key-wrap accordion accordion-s2"
				:id="`applications${index}`"
				:key="index"
			>
				<div
					v-if="apiServices.api_service_category"
					class="app-key-item-wrapper accordion-item"
				>
					<div class="app-key-list-item accordion-heading">
						<div class="service-list">
							<div
								class="service-name"
								data-toggle="collapse"
								:data-target="`#app${index}`"
							>
								<div class="service-icon">
									<em class="app-icon la la-cube"></em>
								</div>
								<span>
									{{ apiServices.api_service_category }}
								</span>
							</div>
							<ServiceCategoryActions
								:category="apiServices"
								:countryList="countryList"
							/>
						</div>
					</div>
					<div
						:id="`app${index}`"
						class="collapse"
						:data-parent="`#applications${index}`"
						style
					>
						<ServicesByCategory
							:serviceCategory="apiServices"
							:countryList="countryList"
						/>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import ServicesByCategory from "./ServicesByCategory/ServicesByCategory";
import AddServiceCategoryButton from "../buttons/AddServiceCategoryButton/AddServiceCategoryButton";
import ServiceCategoryActions from "../buttons/ServiceCategoryActions/ServiceCategoryActions";
import "./Services.css";

export default {
	name: "Services",
	data() {
		return {
			countryList: [],
		};
	},
	components: {
		ServicesByCategory,
		AddServiceCategoryButton,
		ServiceCategoryActions,
	},
	computed: {
		getApiServices() {
			return this.$store.state.metaData.apiServiceCategories;
		},
	},
	methods: {
		async getCountries() {
			try {
				const response = await this.$store.dispatch(
					"others/getAllCountries"
				);
				this.countryList = response.data.data.filter(
					(c) => c.country_name !== ""
				);
			} catch (error) {
				this.countryList = [];
			}
		},
	},
	async mounted() {
		await Promise.all([
			// make call to get charge type here
			this.$store.dispatch("services/getChargeTypes"),
			this.getCountries(),
		]);
	},
};
</script>
