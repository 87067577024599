<template>
  <span>
    <Spinner v-if="loading" />
    <img
      v-else
      src="/assets/img/icons/download.png"
      alt="Download as csv"
      class="ml-3 pointer"
      width="30px"
      @click="downloadReport"
    />
  </span>
</template>

<script>
import Spinner from "../../utils/Spinner/Spinner";

export default {
  name: "DownloadReport",
  props: ["downloadReport", "loading"],
  components: {
    Spinner,
  },
};
</script>
