<template>
  <div class="az-content-body">
    <div class="col-md-12">
      <div class="SectionItem d-flex justify-content-between">
        <div class="SectionHeader">
          <span class="title">Business Information</span>
          <div class="description">Submitted information from the customer.</div>
        </div>
        <button class="btn btn-md btn-outline-danger mg-b-20 ml-3" @click.prevent="back">
          <i class="la la-arrow-left mr-1"></i>Go Back
        </button>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <!-- biz info section -->
            <AppViewBusinessInfo>
              <div class="row d-flex justify-content-end mr-2 mt-2">
                <div class="mr-2">
                  <button
                    v-if="bizInfo.requestApprovalSteps.send_info_to_etz == 1 && bizInfo.requestApprovalSteps.etz_terminal_id !== null && bizInfo.requestApprovalSteps.generate_ft_authorization_keys == 1 && bizInfo.requestApprovalSteps.status === 'processing' "
                    class="btn btn-md btn-success btn-block mg-b-20"
                    data-toggle="modal"
                    data-target="#approve"
                  >Activate Request</button>
                  <!-- <button
                  v-if="bizInfo.requestApprovalSteps.status=== 'pending' "
                  class="btn btn-md btn-primary btn-block mg-b-20"
                  @click.prevent="editDetails"
                >
                  <i class="typcn typcn-pencil mr-1"></i>Edit Details
                  </button>-->
                </div>
                <div>
                  <button
                    v-if="bizInfo.requestApprovalSteps.send_info_to_etz == 0 && bizInfo.requestApprovalSteps.status === 'processing'"
                    class="btn btn-md btn-primary btn-block mg-b-20"
                    data-toggle="modal"
                    data-target="#sendToETZ"
                  >Send Info to eTranzact</button>
                  <button
                    v-if="bizInfo.requestApprovalSteps.send_info_to_etz == 1 && bizInfo.etz_terminal_id == null && bizInfo.requestApprovalSteps.status === 'processing'"
                    class="btn btn-md btn-primary btn-block mg-b-20"
                    data-toggle="modal"
                    data-target="#enterTerminalID"
                  >Update Terminal ID</button>
                  <button
                    v-if="bizInfo.requestApprovalSteps.send_info_to_etz == 1 && bizInfo.etz_terminal_id !== null && bizInfo.requestApprovalSteps.generate_ft_authorization_keys == 0 && bizInfo.requestApprovalSteps.status === 'processing'"
                    class="btn btn-md btn-primary btn-block mg-b-20"
                    data-toggle="modal"
                    data-target="#generateAuthKeys"
                  >Generate Authorisation Keys</button>
                </div>
              </div>
            </AppViewBusinessInfo>
          </div>
        </div>
        <!-- End row -->
      </div>
    </div>
    <AppETZModal
      :id="'sendToETZ'"
      :title="'Send Information to eTranzact'"
      :action="'Send'"
      :approving="approving"
      @approve="sendInfoToETZ"
    />
    <AppEnterTerminalID
      :id="'enterTerminalID'"
      @updateTerminalID="updateTerminalID"
      :verifying="verifying"
    />
    <AppAuthKeysModal
      :id="'generateAuthKeys'"
      :title="'Generate Authorisation Keys'"
      :action="'Generate'"
      :approving="approving"
      @approve="generateAuthKeys"
    />
    <AppApproveModal
      :id="'approve'"
      :title="'Activate Request'"
      :action="'Activate'"
      :approving="approving"
      @approve="approveRequest"
    />
    <!-- End AZ -->
  </div>
</template>

<script>
import Nprogress from "nprogress";
import AuthWrapper from "@/components/auth/AuthWrapper";
import { notifications } from "../../public/assets/mixins/notifications";
import AppViewBusinessInfo from "@/components/fundsTransfer/ViewBusinessInfo";
import AppApproveModal from "@/components/modals/ActivateModal";
import AppAuthKeysModal from "@/components/modals/ProcessModal";
import AppEnterTerminalID from "@/components/modals/EnterTerminalID";
import AppETZModal from "@/components/modals/ProcessModal";

export default {
  name: "FTViewBusinessInfo",
  mixins: [notifications],
  components: {
    AuthWrapper,
    AppViewBusinessInfo,
    AppEnterTerminalID,
    AppApproveModal,
    AppAuthKeysModal,
    AppETZModal
  },
  data() {
    return {
      isLoading: true,
      metaData: {},
      submitting: false,
      bizInfo: {},
      verifying: false,
      approving: false
    };
  },

  computed: {},
  async mounted() {
    await this.getFTBusinessInfo(this.$route.params.id);
  },
  methods: {
    back() {
      history.back();
    },
    editDetails() {
      let id = this.$route.params.id;
      this.$router.push({ name: "FTEditBusinessInfo", params: { id } });
    },
    dismissModal(id) {
      document.getElementById(id).click();
    },
    async getFTBusinessInfo(id) {
      try {
        await this.$store
          .dispatch("fundstransfer/getFTBusinessInfo", id)
          .then(res => {
            console.log("[requests] ->", res);
            this.bizInfo = res.data.result[0];
            console.log(this.bizInfo);
            this.isLoading = false;
            Nprogress.done();
          });
      } catch (error) {
        Nprogress.done();
        this.isLoading = false;
        this.PendingRequests = [];
        console.log("[error] ->", error);
      }
    },
    async sendInfoToETZ() {
      try {
        this.submitting = true;
        await this.$store
          .dispatch("fundstransfer/sendInfoToETZ", this.$route.params.id)
          .then(res => {
            this.showSuccessNotification(res.data.message);
            this.$swal({
              title: `Business Information sent to eTranzact`,
              text: "The Business Information has been sent to eTranzact",
              icon: "success",
              button: "Ok"
            }).then(value => {
              // this.$refs.submitBtn.disabled = true;
            });
            console.log("[requests] ->", res);
            this.isLoading = false;
            this.submitting = false;
            Nprogress.done();
            this.dismissModal("sendToETZ");
            this.getFTBusinessInfo();
          });
      } catch (error) {
        Nprogress.done();
        this.showErrorLoginNotification(error);
        this.isLoading = false;
        this.submitting = false;
        this.dismissModal("sendToETZ");
        console.log("[error] ->", error);
      }
    },
    async updateTerminalID(data) {
      let requestPayload = {
        id: this.$route.params.id,
        payload: data
      };
      try {
        this.submitting = true;
        await this.$store
          .dispatch("fundstransfer/updateTerminalID", requestPayload)
          .then(res => {
            this.showSuccessNotification(res.data.message);
            console.log("[requests] ->", res);
            this.isLoading = false;
            this.submitting = false;
            this.dismissModal("enterTerminalID");
            Nprogress.done();
            this.getFTBusinessInfo();
          });
      } catch (error) {
        Nprogress.done();
        this.showErrorLoginNotification(error);
        this.isLoading = false;
        this.submitting = false;
        this.dismissModal("enterTerminalID");
        console.log("[error] ->", error);
      }
    },
    async generateAuthKeys() {
      try {
        this.submitting = true;
        await this.$store
          .dispatch("fundstransfer/generateAuthKeys", this.$route.params.id)
          .then(res => {
            this.showSuccessNotification(res.data.message);
            console.log("[requests] ->", res);
            this.isLoading = false;
            this.submitting = false;
            Nprogress.done();
            this.dismissModal("generateAuthKeys");
            this.getFTBusinessInfo();
          });
      } catch (error) {
        Nprogress.done();
        this.showErrorLoginNotification(error.message);
        this.isLoading = false;
        this.submitting = false;
        this.dismissModal("generateAuthKeys");
        console.log("[error] ->", error);
      }
    },
    async approveRequest() {
      try {
        this.submitting = true;
        await this.$store
          .dispatch("fundstransfer/approveRequest", this.$route.params.id)
          .then(res => {
            this.showSuccessNotification(res.data.message);
            console.log("[requests] ->", res);
            this.isLoading = false;
            this.submitting = false;
            this.$router.push("/processing-requests");
            Nprogress.done();
            this.dismissModal("approve");
            this.getFTBusinessInfo();
          });
      } catch (error) {
        Nprogress.done();
        this.showErrorLoginNotification(error);
        this.isLoading = false;
        this.submitting = false;
        this.dismissModal("approve");
        console.log("[error] ->", error);
      }
    }
  }
  // watch: {
  //   bizInfo(newValue, oldValue) {
  //     console.log("value:", newValue, oldValue);
  //     this.bizInfo = newValue;
  //   }
  // }
};
</script>

<style>
.v-table.hidden-xs.VueTables.VueTables--client > .VueTables__search-field {
  display: none !important;
}
.v-select.vs__dropdown-toggle {
  padding: 2px 15px !important;
}
</style>
