<template>
  <div class="px-3">
    <div class="p-4">
        <img :src="details.image_url" height="100" width="100" alt="user_photo" class="my-4">
      <div class="row table-like-body">
        <div class="col-4">FIRSTNAME</div>
        <div class="col-4">SURNAME</div>
        <div class="col-4">MIDDLE NAME</div>
      </div>
      <div class="row table-like-header mb-4">
        <div class="col-4 ">{{ details.first_name || "N/A" }}</div>
        <div class="col-4 ">{{ details.last_name || "N/A" }}</div>
        <div class="col-4 ">{{ details.middle_name || "N/A" }}</div>
      </div>
      <div class="row table-like-body">
        <div class="col-4">GENDER</div>
        <div class="col-4">NATIONAL IDENTIFICATION NUMBER</div>
        <div class="col-4">SMART ID</div>
      </div>
      <div class="row table-like-header mb-4">
        <div class="col-4">{{ details.gender || "N/A" }}</div>
        <div class="col-4">{{ details.nin || "N/A" }}</div>
        <div class="col-4">{{ details.smart_id || "N/A" }}</div>
      </div>
      <div class="row table-like-body">
        <div class="col-4">PHONE NUMBER</div>
        <div class="col-4">DATE OF BIRTH</div>
        <div class="col-4">EMAIL</div>
      </div>
      <div class="row table-like-header mb-4">
        <div class="col-4">{{ details.phone_number || "N/A" }}</div>
        <div class="col-4">{{ details.date_of_birth || "N/A" }}</div>
        <div class="col-4">{{ details.email || "N/A" }}</div>
      </div>
      <div class="row table-like-body">
        <div class="col-4">ADDRESS</div>
        <div class="col-4">CITY</div>
        <div class="col-4">STATE</div>
      </div>
      <div class="row table-like-header">
        <div class="col-4">{{ details.address || "N/A" }}</div>
        <div class="col-4">{{ details.city || "N/A" }}</div>
        <div class="col-4">{{ details.state_of_residence || "N/A" }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import "../style.css";

export default {
  name: "MainInformation",
  mixins: [componentData],
  data() {
    return {};
  },
  computed: {
    details() {
      return this.$store.state.logs.reportDetails;
    },
  },
  methods: {},
};
</script>

<style scoped>
.row {
  font-size: 15px;
}
.table-like-header {
  background: #ffffff;
}
.table-like-body {
  font-weight: 100;
}
</style>
