import { apiClient } from "./api";

export default {
  GetCustomers(query) {
    return apiClient.get(`Authorize/GetCustomers`, query);
  },
  SearchGetCustomers(query) {
    return apiClient.get(`Authorize/Admin/SearchOrganization`, query);
  },
  GetCustomerCompliance(query) {
    return apiClient.get(`Authorize/GetUserCompliance/${query}`);
  },
  GetCustomerTeamDetails({ id, clientid }) {
    let headers = {
      clientid,
    };
    return apiClient.get(`Authorize/SingleCustomer/TeamMemberDetails/${id}`, {
      headers,
    });
  },
  AddCustomerTeamMember({ client_id, email, role }) {
    return apiClient.post(
      `Authorize/AdminAddTeamMember`,
      {
        client_id, email, role
      },
    );
  },
  UpdateCustomerTeamRole({ id, clientid, role_id }) {
    let headers = {
      clientid,
    };
    return apiClient.patch(
      `Authorize/AdminUpdateTeamMemberRole`,
      {
        user_id: id,
        role_id,
      },
      {
        headers,
      }
    );
  },
  TriggerPasswordReset({ id, clientid }) {
    let headers = {
      clientid,
    };
    return apiClient.post(
      `Authorize/AdminSendPasswordResetLink`,
      {
        user_id: id,
      },
      {
        headers,
      }
    );
  },
  Set2FA({ id, clientid }) {
    let headers = {
      clientid,
    };
    return apiClient.patch(
      `Authorize/AdminDisableTeamMemberMfa`,
      {
        user_id: id,
      },
      {
        headers,
      }
    );
  },
  MakeInactive({ id, clientid }) {
    let headers = {
      clientid,
    };
    return apiClient.patch(
      `Authorize/AdminDeactivateTeamMember`,
      {
        user_id: id,
      },
      {
        headers,
      }
    );
  },
  GetInvitedCustomers(query = {}) {
    return apiClient.get(`Authorize/admin/payments/Customer/Invites`, query);
  },
  GetPaymentCustomers(query) {
    return apiClient.get(`Authorize/admin/payments/merchants`, query);
  },
  GetVerificationPaymentMerchants(query) {
    return apiClient.get(
      `Authorize/admin/payments/verifications/pending`,
      query
    );
  },
  GetVerificationPaymentMerchantsDetail(query) {
    return apiClient.get(
      `Authorize/admin/payments/verifications/pending/${query.id}`,
      query
    );
  },
  GetActivatedPaymentMerchants(query) {
    return apiClient.get(
      `Authorize/admin/payments/verifications/approved`,
      query
    );
  },
  GetPaymentMerchantInfo(data) {
    return apiClient.post(`Authorize/admin/payments/merchant/info`, data);
  },
  ApprovePaymentMerchant(data) {
    return apiClient.put(
      `Authorize/admin/payments/verifications/updateApproval`,
      data
    );
  },
  SendAccStatement(data) {
    return apiClient.post(
      `Authorize/admin/payments/accountStatement/${data.id}`,
      data.payload
    );
  },
  GetPaymentMerchantOverview(query) {
    return apiClient.get(`Authorize/admin/payments/overview/${query.id}`);
  },
  GetPaymentMerchantTransactions(query) {
    return apiClient.get(
      `Authorize/admin/payments/transactions/${query.params.id}`,
      query
    );
  },
  GetReservedAccountTransactions(query) {
    return apiClient.get(
      `Authorize/admin/payments/reserved-account-transactions/${query.params.id}`,
      query
    );
  },
  GetPaymentMerchantWallets(query) {
    return apiClient.get(
      `Authorize/admin/payments/wallet/${query.params.ref}/wallets`,
      query
    );
  },
  GetPaymentSingleWallet(query) {
    return apiClient.get(
      `Authorize/admin/payments/wallet/${query.params.clTId}/${query.params.wallId}`,
      query
    );
  },
  GetPaymentMerchantReservedAccounts(query) {
    return apiClient.get(
      `Authorize/admin/payments/wallet/${query.params.id}/reserved-accounts`,
      query
    );
  },
  GetPaymentMerchantCollections(query) {
    return apiClient.get(
      `Authorize/admin/payments/paymentPages/${query.params.id}`,
      query
    );
  },
  GetPaymentMerchantPaymentLinks(query) {
    return apiClient.get(
      `Authorize/admin/payments/wallet/${query.params.id}/paymentlinks`,
      query
    );
  },
  GetPaymentMerchantPaymentLinkStats(query) {
    return apiClient.get(
      `Authorize/admin/payments/wallet/${query.params.id}/paymentLink/${query.params.payment_link_ref}/statistics`,
      query
    );
  },
  GetPaymentMerchantPaymentLinkInfo(query) {
    return apiClient.get(
      `Authorize/admin/payments/wallet/${query.params.id}/paymentLink/${query.params.payment_link_ref}`,
      query
    );
  },
  GetPaymentMerchantPayouts(query) {
    return apiClient.get(
      `Authorize/admin/payments/wallet/${query.params.id}/payouts`,
      query
    );
  },
  GetPaymentMerchantSubAccounts(query) {
    return apiClient.get(
      `Authorize/admin/payments/splitPayments/${query.params.id}`,
      query
    );
  },
  GetPaymentMerchantChargeBacks(query) {
    return apiClient.get(
      `Authorize/admin/payments/wallet/${query.params.id}/chargeBacks`,
      query
    );
  },
  GetPaymentMerchantRefunds(query) {
    return apiClient.get(
      `Authorize/admin/payments/wallet/${query.params.id}/refunds`,
      query
    );
  },
  GetPaymentMerchantDisputes(query) {
    return apiClient.get(
      `Authorize/admin/payments/disputes/${query.params.id}`,
      query
    );
  },
  GetPaymentMerchantPlans(query) {
    return apiClient.get(
      `Authorize/admin/payments/plans/${query.params.id}`,
      query
    );
  },
  GetPaymentMerchantPaymentMethods(query) {
    return apiClient.get(
      `Authorize/admin/payments/paymentMethod/${query.params.id}`,
      query
    );
  },
  GetPaymentMerchantPaymentPricing(query) {
    return apiClient.get(
      `Authorize/admin/payments/paymentPricing/${query.params.id}`,
      query
    );
  },
  UpdatePaymentMerchantPaymentPricing(query) {
    return apiClient.put(
      `Authorize/admin/payments/pricing/${query.id}/merchant/${query.merchantId}`,
      query.payload
    );
  },
  GetPaymentMerchantCoupons(query) {
    return apiClient.get(
      `Authorize/admin/payments/coupons/${query.params.id}`,
      query
    );
  },
  GetPaymentMerchantTaxes(query) {
    return apiClient.get(
      `Authorize/admin/payments/taxes/${query.params.id}`,
      query
    );
  },
  GetPaymentMerchantTrails(query) {
    return apiClient.get(
      `Authorize/admin/payments/trails/${query.params.id}`,
      query
    );
  },
  GetPaymentMerchantSuscriptions(query) {
    return apiClient.get(
      `Authorize/admin/payments/subscriptions/${query.params.id}`,
      query
    );
  },
  GetMerchantCutomers(query) {
    return apiClient.get(
      `/Authorize/admin/payments/customers/${query.params.id}`,
      query
    );
  },
  GetCurrentMerchantInfo(query) {
    return apiClient.get(
      `Authorize/admin/payments/currentMerchant/?reference=${query.id}`,
      query
    );
  },
  GetSettingsInfo(query) {
    return apiClient.get(`Authorize/admin/payments/settings`, query);
  },
  GetSingleCustomer(applicationDetails) {
    let headers = {
      clientid: applicationDetails,
    };
    return apiClient.get(`Authorize/GetSingleCustomer`, { headers });
  },
  getSingleCustomerDetails(applicationDetails) {
    let headers = {
      clientid: applicationDetails,
    };
    return apiClient.get(`Authorize/SingleCustomer/Details `, { headers });
  },
  updateAppLimit({ amount, clientid }) {
    let headers = {
      clientid,
    };
    return apiClient.patch(
      `Authorize/SingleCustomer/UpdateAppLimit`,
      {
        app_limit: amount,
      },
      {
        headers,
      }
    );
  },
  getSingleCustomerAdditionalDetails(applicationDetails) {
    let headers = {
      clientid: applicationDetails,
    };
    return apiClient.get(`Authorize/SingleCustomer/AdditionalDetails  `, {
      headers,
    });
  },
  AdminRegisterCustomer(appDetails) {
    return apiClient.post(`Authorize/AdminRegisterCustomer`, appDetails);
  },
  LoadCredit(appDetails) {
    let headers = {
      clientid: appDetails.clientid,
    };
    return apiClient.post(`Authorize/LoadCredit`, appDetails.payload, {
      headers,
    });
  },
  allClientLogs({ params }) {
    let headers = {
      clientid: params.clientID,
    };
    if (params.service_label) {
      headers.service_label = params.service_label;
    }
    if (params.appname) {
      headers.appname = params.appname;
    }
    if (params.apiKey) {
      headers.apiKey = params.apiKey;
    }
    return apiClient.get(`Authorize/AdminClientLogs`, { headers, params });
  },
  CustomerAuditTrail({ params }) {
    let headers = {
      clientid: params.clientID,
    };
    if (params.service_label) {
      headers.service_label = params.service_label;
    }
    if (params.appname) {
      headers.appname = params.appname;
    }
    if (params.apiKey) {
      headers.apiKey = params.apiKey;
    }
    return apiClient.get(`Authorize/Admin/MerchantAuditTrail`, {
      headers,
      params,
    });
  },
  CustomerCreditHistory(verificationData) {
    let headers = {
      clientid: verificationData.clientID,
    };
    return apiClient.get(`Authorize/CustomerCreditHistory`, { headers });
  },
  editAccountServices(accountDetail) {
    let headers = { clientid: accountDetail.clientid };
    console.log("[accountDetail] ->", accountDetail);
    return apiClient.put(
      `/Authorize/Admin/EditCustomerService`,
      accountDetail.payload,
      { headers }
    );
  },
  UpdateCustomerPrice(customerData) {
    let headers = {
      clientid: customerData.clientID,
    };
    return apiClient.put(
      `Authorize/Admin/UpdateCustomerPrice/${customerData.service}`,
      customerData.payload,
      { headers }
    );
  },
  acceptWhiteLabelRequest(payload) {
    return apiClient.get(
      `Authorize/Admin/GrantExistingUserWhitelabelRequest/${payload.id}`
    );
  },
  rejectWhiteLabelRequest(payload) {
    return apiClient.patch(
      `/Authorize/Admin/RejectWhitelabelRequest/${payload.id}`,
      payload.data
    );
  },
  SendInvite(inviteDetails) {
    return apiClient.post(
      `Authorize/admin/payments/Customer/Invite`,
      inviteDetails
    );
  },
  ReSendInvite(inviteDetails) {
    return apiClient.post(
      `Authorize/admin/payments/Customer/resendInvite`,
      inviteDetails
    );
  },
  UpdateNotifications(payload) {
    return apiClient.put(
      `Authorize/admin/payments/notification-toggle/${payload.id}?${payload.label}=${payload.value}`,
      payload
    );
  },
};
