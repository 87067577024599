import { apiClient } from "./api";

export default {
  GetPaymentDisputes(query) {
    return apiClient.get(`Authorize/GetCustomers`, query);
  },
  GetPaymentOverview(query) {
    return apiClient.get(`Authorize/admin/payments/all/overview`, query);
  },
  GetPaymentTransactions(query) {
    return apiClient.get(`Authorize/admin/payments/all/transactions`, query);
  },
  GetAllPaymentDisputes(query) {
    return apiClient.get(`Authorize/admin/payments/all/disputes`, query);
  },
  UpdatePaymentDispute(query) {
    return apiClient.put(`Authorize/admin/payments/all/disputes/${query.id}`, query.params);
  },
  GetPaymentChargebacks(query) {
    return apiClient.get(`Authorize/admin/payments/wallet/charge-backs`, query);
  },
  GetPaymentRefund(query) {
    return apiClient.get(`Authorize/admin/payments/wallet/refunds`, query);
  },
  GetAllPaymentPayouts(query) {
    return apiClient.get(`Authorize/admin/payments/all/payouts`, query);
  },
  GetPayoutDetail(query) {
    return apiClient.get(`Authorize/admin/payments/all/payout/${query.id}`, query);
  },
  GetPayoutTransactions(query) {
    return apiClient.get(`Authorize/admin/payments/all/payouts/${query.id}`, query);
  },
  GetProviderTransactions(query) {
    return apiClient.get(`Authorize/admin/payments/all/transactions`, query);
  },
  GetAllPaymentProviders(query) {
    return apiClient.get(`Authorize/admin/payments/all/providers`, query);
  },
  GetPaymentProvidersDetails(query) {
    return apiClient.get(`Authorize/admin/payments/all/providers/${query.id}`,);
  },
  GetPaymentStatistics(query) {
    return apiClient.get(`Authorize/admin/payments/wallet/transactions/source-status-statistics`, query);
  },
  GetPaymentOverviewStatistics(query) {
    return apiClient.get(`Authorize/admin/payments/all/paymentStats`, query);
  },
  GetCustomerTransactions(query) {
    return apiClient.get(
      `Authorize/admin/payments/customerInfo/transactions/${query.params.id}`,
      query
    );
  },
  GetCustomerWallet(query) {
    return apiClient.get(
      `Authorize/admin/payments/customerInfo/wallets/${query.params.id}`,
      query
    );
  },
  GetMerchantInvoice(query) {
    return apiClient.get(
      `Authorize/admin/payments/invoices/${query.params.id}`,
      query
    );
  },
  GetMerchantInvoiceDetail(query) {
    return apiClient.get(
      `Authorize/admin/payments/invoices/${query.params.clientid}/${query.params.invoiceId}`,
      query
    );
  },
  GetMerchantInvoiceItems(query) {
    return apiClient.get(
      `Authorize/admin/payments/invoices/${query.params.clientid}/${query.params.invoiceId}/products`,
      query
    );
  },
  GetCustomerInvoice(query) {
    return apiClient.get(
      `Authorize/admin/payments/customerInfo/invoices/${query.params.id}`,
      query
    );
  },
  GetCustomerInvoiceStatement(query) {
    return apiClient.get(
      `Authorize/admin/payments/invoices/${query.params.id}/customer/statement`,
      query
    );
  },
  GetCustomerSubscriptions(query) {
    return apiClient.get(
      `Authorize/admin/payments/customerInfo/subscriptions/${query.params.id}`,
      query
    );
  },
  VerifyTerminalMerchant(query) {
    return apiClient.get(
      `Authorize/admin/payments/tms/verify-merchant-terminal/${query.params.id}`,
      query
    );
  },
  GetMerchantTerminal(query) {
    return apiClient.get(
      `Authorize/admin/payments/tms/get-merchant-terminals/${query.params.id}`,
      query
    );
  },
  GenerateTerminalID(query) {
    return apiClient.get(
      `Authorize/admin/payments/tms/generate-tid`,
    );
  },
  ActivateTerminalMerchant(query) {
    return apiClient.post(
      `Authorize/admin/payments/tms/create-merchant/${query.params.id}`,
    );
  },
  AddTerminal(query) {
    return apiClient.post(
      `Authorize/admin/payments/tms/create-terminal/${query.params.id}`, {terminalId: query.terminalId},
    );
  },
  AddBulkTerminal(query) {
    return apiClient.post(
      `Authorize/admin/payments/tms/create-bulk-terminal/${query.params.id}`, {count: query.params.count},
    );
  },
  UpdateAppBundleId(query) {
    return apiClient.put(
      `Authorize/admin/payments/pos-application/${query.params.id}`, query.payload,
    );
  },
};