<template>
  <div>
    <div class="row side-tab-pills">
      <div class="col-2">
        <div
          class="nav flex-column nav-pills"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <a
            class="nav-link active"
            id="v-pills-webhooks-tab"
            data-toggle="pill"
            href="#v-pills-webhooks"
            role="tab"
            aria-controls="v-pills-webhooks"
            aria-selected="true"
            >WebHooks</a
          >
          <a
            class="nav-link"
            id="v-pills-notifications-tab"
            data-toggle="pill"
            href="#v-pills-notifications"
            role="tab"
            aria-controls="v-pills-notifications"
            aria-selected="false"
            >Notifications</a
          >
          <a
            class="nav-link"
            id="v-pills-sa-tab"
            data-toggle="pill"
            href="#v-pills-sa"
            role="tab"
            aria-controls="v-pills-sa"
            aria-selected="false"
            >Settlement Account</a
          >
          <a
            class="nav-link"
            id="v-pills-preference-tab"
            data-toggle="pill"
            href="#v-pills-preference"
            role="tab"
            aria-controls="v-pills-preference"
            aria-selected="false"
            >Preferences</a
          >
          <a
            class="nav-link"
            id="v-pills-pp-tab"
            data-toggle="pill"
            href="#v-pills-pp"
            role="tab"
            aria-controls="v-pills-pp"
            aria-selected="false"
            >Payment Pricing</a
          >
          <a
            class="nav-link"
            id="v-pills-ftp-tab"
            data-toggle="pill"
            href="#v-pills-ftp"
            role="tab"
            aria-controls="v-pills-ftp"
            aria-selected="false"
            >Payment Methods</a
          >
          <a
            class="nav-link"
            id="v-pills-ftp-tab"
            data-toggle="pill"
            href="#v-pills-app-bundle"
            role="tab"
            aria-controls="v-pills-app-bundle"
            aria-selected="false"
            >App Bundle ID</a
          >
        </div>
      </div>
      <div class="col-10">
        <div class="tab-content" id="v-pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="v-pills-webhooks"
            role="tabpanel"
            aria-labelledby="v-pills-webhooks-tab"
          >
            <h3 class="mb-4">WebHooks</h3>
            <WebHooks :clientid="clientid" />
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-notifications"
            role="tabpanel"
            aria-labelledby="v-pills-notifications-tab"
          >
            <h3 class="mb-4">Notifications</h3>
            <Notifications :clientid="clientid" />
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-sa"
            role="tabpanel"
            aria-labelledby="v-pills-sa-tab"
          >
            <h3 class="mb-4">Settlement Accounts</h3>
            <SettlementAccount :clientid="clientid" />
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-preference"
            role="tabpanel"
            aria-labelledby="v-pills-preference-tab"
          >
            <h3 class="mb-4">Preferences</h3>
            <Preferences :clientid="clientid" />
          </div>
        <div
          class="tab-pane fade"
          id="v-pills-pp"
          role="tabpanel"
          aria-labelledby="v-pills-pp-tab"
          >
          <h3 class="mb-4">Payment Pricing</h3>
          <PaymentPricing :clientid="clientid" />
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-ftp"
          role="tabpanel"
          aria-labelledby="v-pills-ftp-tab"
        >
          <h3 class="mb-4">Payment Methods</h3>
          <PaymentMethods :clientid="clientid" />
        </div>
        <div
          class="tab-pane fade"
          id="v-pills-app-bundle"
          role="tabpanel"
          aria-labelledby="v-pills-app-bundle-tab"
        >
        <h3 class="mb-4">Update App Bundle ID</h3>
        <BundleID :clientid="clientid" />
        </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../../components/utils/TableWrapper/TableWrapper.vue";
import ApiKeys from "./SettingsTabs/ApiKeys.vue";
import Notifications from "./SettingsTabs/Notifications.vue";
import Preferences from "./SettingsTabs/Preferences.vue";
import WebHooks from "./SettingsTabs/WebHooks.vue";
import PaymentMethods from "./SettingsTabs/PaymentMethods.vue";
import BundleID from "./SettingsTabs/BundleID.vue";
import PaymentPricing from "./SettingsTabs/PaymentPricing.vue";
import SettlementAccount from "./SettingsTabs/SettlementAccount.vue";

export default {
  name: "Settings",
  mixins: [componentData],
  data() {
    return {};
  },
  components: {
    TableWrapper,
    ApiKeys,
    Notifications,
    Preferences,
    WebHooks,
    SettlementAccount,
    PaymentMethods,
    PaymentPricing,
    BundleID,
  },
  props: ["clientid"],
  methods: {},
};
</script>
