<template>
  <div>
    <div class="px-3">
      <FilterWrapper>
        <template slot="filterForm">
          <GenericFilter
            :loading="loading"
            :rLoading="rLoading"
            :submitFilter="refetch"
            :clearFilter="() => clearFilter('PaymentLinkRef')"
          />
        </template>
      </FilterWrapper>
    </div>
    <TableWrapper
      :loading="loading"
      :errorMsg="errorMsg"
      :refetch="fetchSubAccounts"
      :isEmpty="isEmpty"
      :emptyTitle="emptyTitle"
    >
      <div class="w-100 py-3" slot="content">
        <v-server-table
          ref="chargeBackRef"
          class="v-table"
          :data="customers"
          :columns="columns"
          :options="options"
          @loaded="onLoaded"
        >
          <template slot="createdAt" slot-scope="{ row }">
            {{ row.createdAt | moment(" MM-DD-YYYY h:mm a") }}
          </template>
        </v-server-table>
      </div>
    </TableWrapper>
  </div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../../../components/utils/TableWrapper/TableWrapper.vue";
import GenericFilter from "../../../../../components/filters/GenericFilter.vue";

const plansHeadings = {
  reference: "Chargeback ID",
  transaction_reference: "Transaction Reference",
  createdAt: "Date Created",
  status: "Status",
};

export default {
  name: "ChargeBack",
  mixins: [componentData],
  data() {
    return {
      singleInviteObj: null,
      showSingleInviteModal: false,
      emptyTitle: "No Chargeback Found",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(plansHeadings, 25),
    };
  },
  components: {
    TableWrapper,
    GenericFilter,
  },
  methods: {
    refetch(filters) {
      this.handleRefetch(filters, this.fetchSubAccounts, "chargeBackRef");
    },
    async fetchSubAccounts() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters, id: this.clientid };
        const response = await this.$store.dispatch(
          "customer/getPaymentMerchantChargeBacks",
          {
            params,
          }
        );

        const newData = response.data.data;
        const count = response.data.total;
        return { ...response, count, total: count, data: newData };
      };
    },
  },

  props: ["clientid"],
  created() {
    this.fetchSubAccounts();
  },
  mounted() {
    this.columns = Object.keys(plansHeadings).map((keyVal) => keyVal);
  },
};
</script>
