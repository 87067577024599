<template>
  <div>
    <div class="row">
      <div class="col-12 mt-3">
        <Spinner v-if="loading" />
        <div class="p-md-4 p-3" v-else>
          <div class="my-4">
            <a v-if="this.$route.query.type" @click="$router.go(-1)" class="mr-2 text-secondary pointer">Address
              Reports</a>
            <router-link v-else to="/logs/verification-logs" class="mr-2 text-secondary">Reports</router-link>
            &lt;
            <router-link to="" class="ml-2 text-primary">
              Report Details</router-link>
          </div>
          <div class="">
            <div class="SectionItem no-b-b mb-3 d-block">
              <span class="title">
                <a v-if="this.$route.query.type" @click="$router.go(-1)" class="nav-sub-link pointer"><img
                    src="/assets/img/icons/close.svg" alt="image" height="30" /></a>
                <router-link v-else to="/logs/verification-logs" class="nav-sub-link"><img
                    src="/assets/img/icons/close.svg" alt="image" height="30" /></router-link>
                Close</span>
            </div>
          </div>
          <div :class="
              details.report && details.report.status === 'Not Found'
                ? ' my-5 bg-danger-light rounded text-danger p-2'
                : ' my-5 bg-success-light rounded text-success p-2'
            ">
            <span v-if="details.report && details.report.status === 'Not Found'">
              <img src="/assets/img/icons/error.svg" alt="image" width="13" height="13" class="mr-2" />
            </span>
            <span v-else>
              <img src="/assets/img/icons/CaretDown.svg" alt="image" class="mr-2" />
            </span>
            <span v-if="details.report"><span v-if="details.report.status">{{
                details.report.status
                }}</span></span>
            <span v-else>User found</span>
          </div>
          <div>
            <h5 class="font-weight-bold">Search Information</h5>
            <div class="row" v-if="details.search_name">
              <div class="col-lg-3 col-sm-6 mb-3">
                <span class="text-secondary text-uppercase">SEARCH name</span><br />
                <span>{{ titleCase(details.search_name) }}</span>
              </div>
              <div class="col-lg-3 col-sm-6 mb-3">
                <span class="text-secondary text-uppercase">SEARCH TYPE</span><br />
                <span>{{ titleCase(details.search_type) }}</span>
              </div>
              <div class="col-lg-3 col-sm-6 mb-3">
                <span class="text-secondary text-uppercase">Verification ID</span><br />
                <span>{{ details.verification_id }}</span>
              </div>
              <div class="col-lg-3 col-sm-6 mb-3">
                <span class="text-secondary text-uppercase">Date REQUESTED</span><br />
                <span>{{ details.created_at }}</span>
              </div>
              <div class="col-lg-3 col-sm-6 mb-3">
                <span class="text-secondary text-uppercase">Initiated By</span><br />
                <span>{{ details.initiated_by }}</span>
              </div>
              <div class="col-lg-3 col-sm-6 mb-3">
                <span class="text-secondary text-uppercase">Report URL</span><br />
                <span>{{ details.report_url }}</span>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-lg-3 col-sm-6 mb-3">
                <span class="text-secondary text-uppercase">SEARCH TERM</span><br />
                <span>{{ titleCase(details.registration_number) }}</span>
              </div>
              <div class="col-lg-3 col-sm-6 mb-3">
                <span class="text-secondary text-uppercase">Verification ID</span><br />
                <span>{{ details.report.verification_id }}</span>
              </div>
              <div class="col-lg-3 col-sm-6 mb-3">
                <span class="text-secondary text-uppercase">Date REQUESTED</span><br />
                <span>{{ details.created_at }}</span>
              </div>
              <div class="col-lg-3 col-sm-6 mb-3">
                <span class="text-secondary text-uppercase">Initiated By</span><br />
                <span>{{ details.report.initiated_by }}</span>
              </div>
            </div>
          </div>

          <div v-if="details.report">
            <div v-if="details.report.status === 'Verified' || details.report.status === 'Completed'">
              <div class="mt-5">
                <button class="btn btn-sm btn--secondary--border px-4 mx-3" @click="fetchReportDetails()">
                  <img src="/assets/img/icons/refresh-2.svg" class="mr-2" alt="" />
                  Refresh Data
                </button>
              </div>
            </div>
          </div>

          <div v-if="details.report">
            <div v-if="details.report.status === 'Verified' || details.report.status === 'Completed'">
              <div class="content__slider mt-4" v-if="details.registration_number">
                <TabsV2 :tabNames="tabNames" :tabId="'reportTab'" :tabLink="true" />
              </div>
              <div class="content__slider mt-4" v-if="
                  details.service_type === 'NIN Verification' ||
                    details.service_type ===
                      'NIN Verification BY Phone Number' ||
                    details.service_type === 'BVN Verification without Image'
                ">
                <TabsV2 :tabNames="
                    details.registration_number ? tabNames : NINTabNames
                  " :tabId="'reportTab'" :tabLink="true" />
              </div>
              <transition mode="out-in" name="slide">
                <router-view />
              </transition>
            </div>
          </div>
          <div v-if="details.building_name">
            <AddressVerification />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";
import { notifications } from "../../../../../public/assets/mixins/notifications";
import TabsV2 from "../../../../components/utils/Tabs/TabsV2";
import Spinner from "../../../../components/utils/Spinner/Spinner";
import AddressVerification from "./AddressVerification/AddressVerification.vue";

const BusinessTabName = (id) => [
  {
    tabName: "Ownership",
    value: "ownership",
    link: `/logs/verification-logs/${id}/ownership`,
  },
  {
    tabName: "Personnel",
    value: "personnel",
    link: `/logs/verification-logs/${id}/personnel`,
  },
  {
    tabName: "Company Information",
    value: "company_information",
    link: `/logs/verification-logs/${id}/company-information`,
  },
];
const NINTabName = (id) => [
  {
    tabName: "Main Information",
    value: "Main_Information",
    link: `/logs/verification-logs/${id}/main_information`,
  },
  {
    tabName: "Other Information",
    value: "Other_Information",
    link: `/logs/verification-logs/${id}/other_information`,
  },
];

export default {
  mixins: [componentData, notifications],
  name: "ServiceReportDetails",
  data() {
    return {
      tabNames: BusinessTabName(this.$route.params.id),
      NINTabNames: NINTabName(this.$route.params.id),
      details: null,
      loading: false,
    };
  },
  components: { TabsV2, Spinner, AddressVerification },
  methods: {
    async fetchReportDetails() {
      this.loading = true;
      const payload = {
        params: {
          id: this.$route.params.id,
        },
      };
      const resp = (
        await this.$store.dispatch("logs/getServiceReportLogDetails", payload)
      ).data;
      this.loading = false;
      this.details = resp;
    },
    async downloadReport() {
      if (this.details.nin) {
        window.open(this.details.report.report_url, "_blank");
      } else {
        const payload = {
          params: {
            id: this.$route.params.id,
          },
        };
        const resp = (
          await this.$store.dispatch("services/downloadReportDetails", payload)
        ).data.message;

        if (resp === "Success") {
          this.showSuccessNotification(
            "Report details has been sent to your mail."
          );
        }
      }
    },
  },
  created() {
    this.fetchReportDetails();
  },
};
</script>
