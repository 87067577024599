<template>
  <div class="az-content-body">
    <Loader v-if="isProcessing == true"></Loader>
    <div class="row" v-else>
      <div class="col-lg-12">
        <div class="SectionItem">
          <div class="SectionHeader">
            <span class="title">Dashboard</span>
            <div class="description">
              View the analytics of your application, how many api calls you're
              making and more statistics
            </div>
          </div>
        </div>
      </div>

      <template>
        <div class="col-12">
          <div class="card-body dashboard-flex dashboard-briefs">
            <div class="dashboard-brief">
              <h2 class="call-count">
                {{ dashboardData.totalCallLogs || "0" }}
              </h2>
              <p class="call-name">Total calls</p>
            </div>
            <div class="dashboard-brief">
              <h2 class="call-count">
                {{ dashboardData.totalSuccessfulCallLogs || "0" }}
              </h2>
              <p class="call-name">successful calls</p>
            </div>
            <div class="dashboard-brief">
              <h2 class="call-count">
                {{ dashboardData.totalFailedCallLogs || "0" }}
              </h2>
              <p class="call-name">Failed calls</p>
            </div>
          </div>
        </div>
        <div class="gaps-2x"></div>
        <div class="gaps-2x"></div>
        <div class="gaps-2x"></div>

        <div class="col-lg-4 mg-t-20">
          <div class="card card-dashboard-eight bg-white">
            <h6 class="card-title">Total Indentity Request</h6>
            <span class="d-block mg-b-20"
              >View total calls on identity services</span
            >

            <div class="list-group">
              <div
                class="list-group-item"
                v-for="(expense, i) in dashboardCallLogs"
                :key="i"
              >
                <div>{{ Object.keys(expense)[0] }}</div>
                <span>{{ Object.values(expense)[0] }} request(s)</span>
              </div>
            </div>
            <!-- list-group -->
          </div>
          <!-- card -->
        </div>

        <div class="col-lg-4 mg-t-20">
          <div class="card card-dashboard-eight bg-white">
            <h6 class="card-title">Total Identity Request Contd.</h6>
            <span class="d-block mg-b-20"
              >View total calls on identity services</span
            >

            <div class="list-group">
              <div class="list-group">
                <div
                  class="list-group-item"
                  v-for="(expense, i) in dashboardSecondCallLogs"
                  :key="i"
                >
                  <div>{{ Object.keys(expense)[0] }}</div>
                  <span>{{ Object.values(expense)[0] }} request(s)</span>
                </div>
              </div>
            </div>
            <!-- list-group -->
          </div>
        </div>

        <div class="col-lg-4 mg-t-20">
          <div class="card card-dashboard-eight bg-white">
            <h6 class="card-title">Verification Request</h6>
            <span class="d-block mg-b-20"
              >View the total calls on verifications</span
            >

            <div class="list-group">
              <div class="list-group">
                <div
                  class="list-group-item"
                  v-for="(expense, i) in dashboardThirdCallLogs"
                  :key="i"
                >
                  <div>{{ Object.keys(expense)[0] }}</div>
                  <span>{{ Object.values(expense)[0] }} request(s)</span>
                </div>
              </div>
            </div>
            <!-- list-group -->
          </div>
        </div>

        <div class="col-md-12 col-sm-12 mg-t-20 mg-lg-t-0">
          <div class="card card-body bg-gray-200 bd-2 mt-4">
            <ServiceCallChart :series="serviceCalls"></ServiceCallChart>
          </div>
        </div>

        <div class="col-md-12 col-sm-12 mg-t-20 mg-lg-t-0">
          <div class="card card-body bg-gray-200 bd-2 mt-4">
            <ServiceProviderChart
              :series="serviceProviderLogs"
            ></ServiceProviderChart>
          </div>
        </div>

        <div class="col-md-6 col-sm-12 mg-t-20 mg-lg-t-0">
          <div class="card card-body bg-gray-200 bd-2 mt-4">
            <BVNProviderChart :series="bvnProviderLogs"></BVNProviderChart>
          </div>
        </div>

        <div class="col-md-6 col-sm-12 mg-t-20 mg-lg-t-0">
          <div class="card card-body bg-gray-200 bd-2 mt-4">
            <PVCProviderChart :series="pvcProviderLogs"></PVCProviderChart>
          </div>
        </div>

        <div class="col-md-6 col-sm-12 mg-t-20 mg-lg-t-0">
          <div class="card card-body bg-gray-200 bd-2 mt-4">
            <AddressProviderChart
              :series="addressProviderLogs"
            ></AddressProviderChart>
          </div>
        </div>

        <div class="col-md-6 col-sm-12 mg-t-20 mg-lg-t-0">
          <div class="card card-body bg-gray-200 bd-2 mt-4">
            <BankAccountProviderChart
              :series="bankAccountProviderLogs"
            ></BankAccountProviderChart>
          </div>
        </div>

        <div class="col-md-6 col-sm-12 mg-t-20 mg-lg-t-0">
          <div class="card card-body bg-gray-200 bd-2 mt-4">
            <DLProviderChart :series="licenceProviderLogs"></DLProviderChart>
          </div>
        </div>

        <div class="col-md-6 col-sm-12 mg-t-20 mg-lg-t-0">
          <div class="card card-body bg-gray-200 bd-2 mt-4">
            <FacialComparisonProviderChart
              :series="facialComparisonProviderLogs"
            ></FacialComparisonProviderChart>
          </div>
        </div>

        <div class="col-md-6 col-sm-12 mg-t-20 mg-lg-t-0">
          <div class="card card-body bg-gray-200 bd-2 mt-4">
            <IBVNProviderChart :series="ibvnProviderLogs"></IBVNProviderChart>
          </div>
        </div>

        <div class="col-md-6 col-sm-12 mg-t-20 mg-lg-t-0">
          <div class="card card-body bg-gray-200 bd-2 mt-4">
            <LivenessCheckProviderChart
              :series="livenessCheckProviderLogs"
            ></LivenessCheckProviderChart>
          </div>
        </div>

        <div class="col-md-6 col-sm-12 mg-t-20 mg-lg-t-0">
          <div class="card card-body bg-gray-200 bd-2 mt-4">
            <NINProviderChart :series="ninProviderLogs"></NINProviderChart>
          </div>
        </div>

        <div class="col-md-6 col-sm-12 mg-t-20 mg-lg-t-0">
          <div class="card card-body bg-gray-200 bd-2 mt-4">
            <NIPProviderChart :series="nipProviderLogs"></NIPProviderChart>
          </div>
        </div>

        <div class="col-md-6 col-sm-12 mg-t-20 mg-lg-t-0">
          <div class="card card-body bg-gray-200 bd-2 mt-4">
            <NUM2BANKProviderChart
              :series="num2bankProviderLogs"
            ></NUM2BANKProviderChart>
          </div>
        </div>

        <div class="col-md-6 col-sm-12 mg-t-20 mg-lg-t-0">
          <div class="card card-body bg-gray-200 bd-2 mt-4">
            <PIVProviderChart :series="pivProviderLogs"></PIVProviderChart>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ServiceCallChart from "@/components/dashboard/ServiceCallChart";
import BVNProviderChart from "@/components/dashboard/BVNProviderChart";
import PVCProviderChart from "@/components/dashboard/PVCProviderChart";
import ServiceProviderChart from "@/components/dashboard/ServiceProviderChart";

import AddressProviderChart from "@/components/dashboard/AddressProviderChart";
import BankAccountProviderChart from "@/components/dashboard/BankAccountProviderChart";
import DLProviderChart from "@/components/dashboard/DLProviderChart";
import FacialComparisonProviderChart from "@/components/dashboard/FacialComparisonProviderChart";
import IBVNProviderChart from "@/components/dashboard/IBVNProviderChart";
import LivenessCheckProviderChart from "@/components/dashboard/LivenessCheckProviderChart";
import NINProviderChart from "@/components/dashboard/NINProviderChart";
import NIPProviderChart from "@/components/dashboard/NIPProviderChart";
import NUM2BANKProviderChart from "@/components/dashboard/NUM2BANKProviderChart";
import PIVProviderChart from "@/components/dashboard/PIVProviderChart";

export default {
  components: {
    PVCProviderChart,
    BVNProviderChart,
    ServiceCallChart,
    ServiceProviderChart,

    AddressProviderChart,
    BankAccountProviderChart,
    DLProviderChart,
    FacialComparisonProviderChart,
    IBVNProviderChart,
    LivenessCheckProviderChart,
    NINProviderChart,
    NIPProviderChart,
    NUM2BANKProviderChart,
    PIVProviderChart,
  },
  data() {
    return {
      values: [10, 5, 5, 5],
      isProcessing: true,
      dashboardData: {},
      serviceProviderLogs: [],
      bvnProviderLogs: [],
      pvcProviderLogs: [],

      ninProviderLogs: [],
      nipProviderLogs: [],
      licenceProviderLogs: [],
      addressProviderLogs: [],
      pivProviderLogs: [],
      livenessCheckProviderLogs: [],
      facialComparisonProviderLogs: [],
      ibvnProviderLogs: [],
      num2bankProviderLogs: [],
      bankAccountProviderLogs: [],

      dashboardCallLogs: [],
      dashboardSecondCallLogs: [],
      dashboardThirdCallLogs: [],
    };
  },
  methods: {
    dataFormat: function(a, b) {
      if (b) return b + "%";
      return a;
    },
    async getAnalytics() {
      let clientID =
        this.$store.state.account.clientID || localStorage.getItem("local-id");
      let header = { clientID };
      try {
        let dashboardData = await this.$store.dispatch(
          "account/getDashboardAnalytics",
          header
        );
        this.dashboardData = dashboardData.result;

        // Expenses
        this.dashboardCallLogs = [
          { BVN: this.dashboardData.serviceLogs.totalBvnCallLogs },
          { NIN: this.dashboardData.serviceLogs.totalNinCallLogs },
          {
            "BVN with Image": this.dashboardData.serviceLogs.totaliBVNCallLogs,
          },
          {
            "Permanent voters card": this.dashboardData.serviceLogs
              .totalPvcCallLogs,
          },
        ];
        this.dashboardSecondCallLogs = [
          {
            "Drivers License": this.dashboardData.serviceLogs
              .totalLicenceCallLogs,
          },
          {
            "Nigerian Passport": this.dashboardData.serviceLogs
              .totalNipCallLogs,
          },
          {
            "Address Verification": this.dashboardData.serviceLogs
              .totalAddressCallLogs,
          },
          {
            "Liveness Check": this.dashboardData.serviceLogs
              .totalLivenessCheckCallLogs,
          },
        ];
        this.dashboardThirdCallLogs = [
          {
            "Physical Indetity Verification": this.dashboardData.serviceLogs
              .totalPivCallLogs,
          },
          {
            "Facial Comparison": this.dashboardData.serviceLogs
              .totalFacialComparisonCallLogs,
          },
          {
            "Number to Bank": this.dashboardData.serviceLogs
              .totalNum2BankCallLogs,
          },
          {
            "Bank account Verification": this.dashboardData.serviceLogs
              .totalBankAccountCallLogs,
          },
        ];

        // Service calls
        this.serviceCalls = [
          {
            name: "SuccessfulCalls",
            data: [
              this.dashboardData.serviceLogs.successfulCalls
                .totalSuccessfulBvnCallLogs,
              this.dashboardData.serviceLogs.successfulCalls
                .totalSuccessfulNinCallLogs,
              this.dashboardData.serviceLogs.successfulCalls
                .totalSuccessfuliBVNCallLogs,
              this.dashboardData.serviceLogs.successfulCalls
                .totalSuccessfulPvcCallLogs,
              this.dashboardData.serviceLogs.successfulCalls
                .totalSuccessfulNipCallLogs,
              this.dashboardData.serviceLogs.successfulCalls
                .totalSuccessfulLicenceCallLogs,
              this.dashboardData.serviceLogs.successfulCalls
                .totalSuccessfulAddressCallLogs,
              this.dashboardData.serviceLogs.successfulCalls
                .totalSuccessfulPivCallLogs,
              this.dashboardData.serviceLogs.successfulCalls
                .totalSuccessfulLivenessCheckCallLogs,
              this.dashboardData.serviceLogs.successfulCalls
                .totalSuccessfulFacialComparisonCallLogs,
              this.dashboardData.serviceLogs.successfulCalls
                .totalSuccessfulNum2BankCallLogs,
              this.dashboardData.serviceLogs.successfulCalls
                .totalSuccessfulBankAccountCallLogs,
            ],
          },
          {
            name: "FailedCalls",
            data: [
              this.dashboardData.serviceLogs.failedCalls.totalFailedBvnCallLogs,
              this.dashboardData.serviceLogs.failedCalls.totalFailedNinCallLogs,
              this.dashboardData.serviceLogs.failedCalls
                .totalFailediBVNCallLogs,
              this.dashboardData.serviceLogs.failedCalls.totalFailedPvcCallLogs,
              this.dashboardData.serviceLogs.failedCalls.totalFailedNipCallLogs,
              this.dashboardData.serviceLogs.failedCalls
                .totalFailedLicenceCallLogs,
              this.dashboardData.serviceLogs.failedCalls
                .totalFailedAddressCallLogs,
              this.dashboardData.serviceLogs.failedCalls.totalFailedPivCallLogs,
              this.dashboardData.serviceLogs.failedCalls
                .totalFailedLivenessCheckCallLogs,
              this.dashboardData.serviceLogs.failedCalls
                .totalFailedFacialComparisonCallLogs,
              this.dashboardData.serviceLogs.failedCalls
                .totalFailedNum2BankCallLogs,
              this.dashboardData.serviceLogs.failedCalls
                .totalFailedBankAccountCallLogs,
            ],
          },
        ];

        this.bvnProviderLogs = [
          {
            name: "SuccesfulCalls",
            data: [
              this.dashboardData.bvnProviderLogs.successfulCalls
                .totalSuccessfulPaystackBvnLogs,
              this.dashboardData.bvnProviderLogs.successfulCalls
                .totalSuccessfulYouVerifyBvnLogs,
              this.dashboardData.bvnProviderLogs.successfulCalls
                .totalSuccessfulChamsBvnLogs,
              this.dashboardData.bvnProviderLogs.successfulCalls
                .totalSuccessfulCarbonBvnLogs,
              this.dashboardData.bvnProviderLogs.successfulCalls
                .totalSuccessfulPolarisBvnLogs,
            ],
          },
          {
            name: "FailedCalls",
            data: [
              this.dashboardData.bvnProviderLogs.failedCalls
                .totalFailedPaystackBvnLogs,
              this.dashboardData.bvnProviderLogs.failedCalls
                .totalFailedYouVerifyBvnLogs,
              this.dashboardData.bvnProviderLogs.failedCalls
                .totalFailedChamsBvnLogs,
              this.dashboardData.bvnProviderLogs.failedCalls
                .totalFailedCarbonBvnLogs,
              this.dashboardData.bvnProviderLogs.failedCalls
                .totalFailedPolarisBvnLogs,
            ],
          },
        ];

        this.ninProviderLogs = [
          {
            name: "SuccesfulCalls",
            data: [
              this.dashboardData.ninProviderLogs.successfulCalls
                .totalSuccessfulYouVerifyNinLogs,
              this.dashboardData.ninProviderLogs.successfulCalls
                .totalSuccessfulChamsNinLogs,
              this.dashboardData.ninProviderLogs.successfulCalls
                .totalSuccessfulCarbonNinLogs,
            ],
          },
          {
            name: "FailedCalls",
            data: [
              this.dashboardData.ninProviderLogs.failedCalls
                .totalFailedYouVerifyNinLogs,
              this.dashboardData.ninProviderLogs.failedCalls
                .totalFailedChamsNinLogs,
              this.dashboardData.ninProviderLogs.failedCalls
                .totalFailedCarbonNinLogs,
            ],
          },
        ];

        this.pvcProviderLogs = [
          {
            name: "SuccesfulCalls",
            data: [
              this.dashboardData.pvcProviderLogs.successfulCalls
                .totalSuccessfulYouVerifyPvcLogs,
              this.dashboardData.pvcProviderLogs.successfulCalls
                .totalSuccessfulInitsPvcLogs,
              this.dashboardData.pvcProviderLogs.successfulCalls
                .totalSuccessfulCarbonPvcLogs,
            ],
          },
          {
            name: "FailedCalls",
            data: [
              this.dashboardData.pvcProviderLogs.failedCalls
                .totalFailedYouVerifyPvcLogs,
              this.dashboardData.pvcProviderLogs.failedCalls
                .totalFailedInitsPvcLogs,
              this.dashboardData.pvcProviderLogs.failedCalls
                .totalFailedCarbonPvcLogs,
            ],
          },
        ];

        this.nipProviderLogs = [
          {
            name: "SuccesfulCalls",
            data: [
              this.dashboardData.nipProviderLogs.successfulCalls
                .totalSuccessfulYouVerifyNipLogs,
              this.dashboardData.nipProviderLogs.successfulCalls
                .totalSuccessfulCarbonNipLogs,
            ],
          },
          {
            name: "FailedCalls",
            data: [
              this.dashboardData.nipProviderLogs.failedCalls
                .totalFailedYouVerifyNipLogs,
              this.dashboardData.nipProviderLogs.failedCalls
                .totalFailedCarbonNipLogs,
            ],
          },
        ];

        this.licenceProviderLogs = [
          {
            name: "SuccesfulCalls",
            data: [
              this.dashboardData.licenceProviderLogs.successfulCalls
                .totalSuccessfulYouVerifyLicenceLogs,
              this.dashboardData.licenceProviderLogs.successfulCalls
                .totalSuccessfulCarbonLicenceLogs,
            ],
          },
          {
            name: "FailedCalls",
            data: [
              this.dashboardData.licenceProviderLogs.failedCalls
                .totalFailedYouVerifyLicenceLogs,
              this.dashboardData.licenceProviderLogs.failedCalls
                .totalFailedCarbonLicenceLogs,
            ],
          },
        ];

        this.addressProviderLogs = [
          {
            name: "SuccesfulCalls",
            data: [
              this.dashboardData.addressProviderLogs.successfulCalls
                .totalYouVerifyAddressLogs,
            ],
          },
          {
            name: "FailedCalls",
            data: [
              this.dashboardData.addressProviderLogs.failedCalls
                .totalYouVerifyAddressLogs,
            ],
          },
        ];

        this.pivProviderLogs = [
          {
            name: "SuccesfulCalls",
            data: [
              this.dashboardData.pivProviderLogs.successfulCalls
                .totalSuccessfulMicroblinkPivLogs,
            ],
          },
          {
            name: "FailedCalls",
            data: [
              this.dashboardData.pivProviderLogs.failedCalls
                .totalFailedMicroblinkPivLogs,
            ],
          },
        ];

        this.livenessCheckProviderLogs = [
          {
            name: "SuccesfulCalls",
            data: [
              this.dashboardData.livenessCheckProviderLogs.successfulCalls
                .totalSuccessfulFaceIdLivenessCheckLogs,
            ],
          },
          {
            name: "FailedCalls",
            data: [
              this.dashboardData.livenessCheckProviderLogs.failedCalls
                .totalFailedFaceIdLivenessCheckLogs,
            ],
          },
        ];

        this.facialComparisonProviderLogs = [
          {
            name: "SuccesfulCalls",
            data: [
              this.dashboardData.facialComparisonProviderLogs.successfulCalls
                .totalSuccessfulFacePlusPlusFacialComparisonLogs,
            ],
          },
          {
            name: "FailedCalls",
            data: [
              this.dashboardData.facialComparisonProviderLogs.failedCalls
                .totalFailedFacePlusPlusFacialComparisonLogs,
            ],
          },
        ];

        this.ibvnProviderLogs = [
          {
            name: "SuccesfulCalls",
            data: [
              this.dashboardData.ibvnProviderLogs.successfulCalls
                .totalSuccessfulYouVerifyiBvnLogs,
              this.dashboardData.ibvnProviderLogs.successfulCalls
                .totalSuccessfulCarboniBvnLogs,
              this.dashboardData.ibvnProviderLogs.successfulCalls
                .totalSuccessfulPolarisiBvnLogs,
            ],
          },
          {
            name: "FailedCalls",
            data: [
              this.dashboardData.ibvnProviderLogs.failedCalls
                .totalFailedYouVerifyiBvnLogs,
              this.dashboardData.ibvnProviderLogs.failedCalls
                .totalFailedCarboniBvnLogs,
              this.dashboardData.ibvnProviderLogs.failedCalls
                .totalFailedPolarisiBvnLogs,
            ],
          },
        ];

        this.num2bankProviderLogs = [
          {
            name: "SuccesfulCalls",
            data: [
              this.dashboardData.num2bankProviderLogs.successfulCalls
                .totalSuccessfulFetsNum2BankLogs,
            ],
          },
          {
            name: "FailedCalls",
            data: [
              this.dashboardData.num2bankProviderLogs.failedCalls
                .totalFailedFetsNum2BankLogs,
            ],
          },
        ];

        this.bankAccountProviderLogs = [
          {
            name: "SuccesfulCalls",
            data: [
              this.dashboardData.bankAccountProviderLogs.successfulCalls
                .totalSuccessfulPaystackBankAccountLogs,
            ],
          },
          {
            name: "FailedCalls",
            data: [
              this.dashboardData.bankAccountProviderLogs.failedCalls
                .totalFailedPaystackBankAccountLogs,
            ],
          },
        ];

        this.serviceProviderLogs = [
          {
            name: "Successful Calls",
            data: [
              this.dashboardData.serviceProviderLogs.successfulCalls
                .totalSuccessfulSterlingCallLogs,
              this.dashboardData.serviceProviderLogs.successfulCalls
                .totalSuccessfulPaystackCallLogs,
              this.dashboardData.serviceProviderLogs.successfulCalls
                .totalSuccessfulYouVerifyCallLogs,
              this.dashboardData.serviceProviderLogs.successfulCalls
                .totalSuccessfulChamsCallLogs,
              this.dashboardData.serviceProviderLogs.successfulCalls
                .totalSuccessfulInitsCallLogs,
              this.dashboardData.serviceProviderLogs.successfulCalls
                .totalSuccessfulCarbonCallLogs,
              this.dashboardData.serviceProviderLogs.successfulCalls
                .totalSuccessfulPolarisCallLogs,
              this.dashboardData.serviceProviderLogs.successfulCalls
                .totalSuccessfulMicroblinkCallLogs,
              this.dashboardData.serviceProviderLogs.successfulCalls
                .totalSuccessfulFaceIdCallLogs,
              this.dashboardData.serviceProviderLogs.successfulCalls
                .totalSuccessfulFacePlusPlusCallLogs,
              this.dashboardData.serviceProviderLogs.successfulCalls
                .totalSuccessfulFetsCallLogs,
            ],
          },
          {
            name: "Failed Calls",
            data: [
              this.dashboardData.serviceProviderLogs.failedCalls
                .totalFailedSterlingCallLogs,
              this.dashboardData.serviceProviderLogs.failedCalls
                .totalFailedPaystackCallLogs,
              this.dashboardData.serviceProviderLogs.failedCalls
                .totalFailedYouVerifyCallLogs,
              this.dashboardData.serviceProviderLogs.failedCalls
                .totalFailedChamsCallLogs,
              this.dashboardData.serviceProviderLogs.failedCalls
                .totalFailedInitsCallLogs,
              this.dashboardData.serviceProviderLogs.failedCalls
                .totalFailedCarbonCallLogs,
              this.dashboardData.serviceProviderLogs.failedCalls
                .totalFailedPolarisCallLogs,
              this.dashboardData.serviceProviderLogs.failedCalls
                .totalFailedMicroblinkCallLogs,
              this.dashboardData.serviceProviderLogs.failedCalls
                .totalFailedFaceIdCallLogs,
              this.dashboardData.serviceProviderLogs.failedCalls
                .totalFailedFacePlusPlusCallLogs,
              this.dashboardData.serviceProviderLogs.failedCalls
                .totalFailedFetsCallLogs,
            ],
          },
        ];

        this.isProcessing = false;
      } catch (error) {
        console.log("[errors getting applications] ->", error);
        this.isProcessing = false;
      }
    },
  },
  computed: {
    profile() {
      return this.$store.state.account.profile.api_customer;
    },
    userRole() {
      return this.$store.state.account.profile.roles[0].role_label;
    },
  },
  mounted() {
    this.getAnalytics();
  },
};
</script>

<style scoped>
.charts {
  padding: 20px;
  /* background-color: #cccbcb;
  border: 1px solid black; */
}

.charts svg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.graphs {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.dashboard-flex {
  padding: 0;
  /* background: #081b2a; */
  border: 1px solid #cccbcb;
  border-radius: 5px;
  color: black;
}
.dashboard-briefs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 120px;
}
.dashboard-brief {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dashboard-brief:not(:last-of-type) {
  border-right: 1px solid #cccbcb;
}

.dashboard-lead {
  color: white;
}
.side-border {
  border-left: 3px dotted white;
}

.dashboard-element {
  padding: 20px;
  color: inherit;
}
.call-count {
  font-size: 42px;
  font-weight: 600;
  line-height: 1;
  color: #2e6091;
  padding-top: 8px;
  margin-bottom: 2px;
}
.call-name {
  font-size: 15px;
  color: #081b2a;
  text-transform: uppercase;
}
</style>
