import customer from "@/services/customer";
import { apiClient } from "@/services/api";

export default {
  namespaced: true,
  state: {
    apps: [],
  },
  mutations: {
    SET_APPS(state, apps) {
      state.apps = apps;
    },
  },
  actions: {
    async getCustomers({ commit }, query) {
      try {
        let { data } = await customer.GetCustomers(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getCustomerCompliance({ commit }, query) {
      let { data } = await customer.GetCustomerCompliance(query);
      return data;
    },
    async searchGetCustomers({ commit }, query) {
      let { data } = await customer.SearchGetCustomers(query);
      return data;
    },
    async updateAppLimit({ commit }, query) {
      let { data } = await customer.updateAppLimit(query);
      return data;
    },
    async getCustomerTeamDetails({ commit }, query) {
      try {
        let { data } = await customer.GetCustomerTeamDetails(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async addCustomerTeamMember({ commit }, query) {
      try {
        let { data } = await customer.AddCustomerTeamMember(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async updateCustomerTeamRole({ commit }, query) {
      try {
        let { data } = await customer.UpdateCustomerTeamRole(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async triggerPasswordReset({ commit }, query) {
      try {
        let { data } = await customer.TriggerPasswordReset(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async set2FA({ commit }, query) {
      try {
        let { data } = await customer.Set2FA(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async makeInactive({ commit }, query) {
      try {
        let { data } = await customer.MakeInactive(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async triggerPasswordReset({ commit }, query) {
      try {
        let { data } = await customer.TriggerPasswordReset(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getInvitedCustomers({ commit }, query) {
      try {
        let { data } = await customer.GetInvitedCustomers(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentCustomers({ commit }, query) {
      try {
        let { data } = await customer.GetPaymentCustomers(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getSettingsInfo({ commit }, query) {
      try {
        let { data } = await customer.GetSettingsInfo(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentMerchantTransactions({ commit }, query) {
      try {
        let { data } = await customer.GetPaymentMerchantTransactions(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getReservedAccountTransactions({ commit }, query) {
      try {
        let { data } = await customer.GetReservedAccountTransactions(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentMerchantPlans({ commit }, query) {
      try {
        let { data } = await customer.GetPaymentMerchantPlans(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentMerchantWallets({ commit }, query) {
      try {
        let { data } = await customer.GetPaymentMerchantWallets(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentMerchantReservedAccounts({ commit }, query) {
      try {
        let { data } = await customer.GetPaymentMerchantReservedAccounts(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentMerchantCollections({ commit }, query) {
      try {
        let { data } = await customer.GetPaymentMerchantCollections(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentMerchantPaymentLinks({ commit }, query) {
      try {
        let { data } = await customer.GetPaymentMerchantPaymentLinks(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentMerchantPaymentLinkStats({ commit }, query) {
      try {
        let { data } = await customer.GetPaymentMerchantPaymentLinkStats(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentMerchantPaymentLinkInfo({ commit }, query) {
      try {
        let { data } = await customer.GetPaymentMerchantPaymentLinkInfo(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentMerchantPayouts({ commit }, query) {
      try {
        let { data } = await customer.GetPaymentMerchantPayouts(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentMerchantSubAccounts({ commit }, query) {
      try {
        let { data } = await customer.GetPaymentMerchantSubAccounts(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentMerchantChargeBacks({ commit }, query) {
      try {
        let { data } = await customer.GetPaymentMerchantChargeBacks(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async sendAccStatement({ commit }, query) {
      let { data } = await customer.SendAccStatement(query);
      console.log("[data from get customers] ->", data.results);
      return data;
    },
    async getPaymentMerchantRefunds({ commit }, query) {
      try {
        let { data } = await customer.GetPaymentMerchantRefunds(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentMerchantDisputes({ commit }, query) {
      try {
        let { data } = await customer.GetPaymentMerchantDisputes(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentMerchantPaymentMethods({ commit }, query) {
      try {
        let { data } = await customer.GetPaymentMerchantPaymentMethods(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentMerchantPaymentPricing({ commit }, query) {
      try {
        let { data } = await customer.GetPaymentMerchantPaymentPricing(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async updatePaymentMerchantPaymentPricing({ commit }, query) {
      
        let { data } = await customer.UpdatePaymentMerchantPaymentPricing(query);
        console.log("[data from get customers] ->", data.results);
        return data;
    },
    async getVerificationPaymentMerchants({ commit }, query) {
      try {
        let { data } = await customer.GetVerificationPaymentMerchants(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getActivatedPaymentMerchants({ commit }, query) {
      try {
        let { data } = await customer.GetActivatedPaymentMerchants(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentSingleWallet({ commit }, query) {
      try {
        let { data } = await customer.GetPaymentSingleWallet(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentMerchantCoupons({ commit }, query) {
      try {
        let { data } = await customer.GetPaymentMerchantCoupons(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentMerchantTaxes({ commit }, query) {
      try {
        let { data } = await customer.GetPaymentMerchantTaxes(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentMerchantTrails({ commit }, query) {
      try {
        let { data } = await customer.GetPaymentMerchantTrails(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentMerchantTaxes({ commit }, query) {
      try {
        let { data } = await customer.GetPaymentMerchantTaxes(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getPaymentMerchantSuscriptions({ commit }, query) {
      try {
        let { data } = await customer.GetPaymentMerchantSuscriptions(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getMerchantCustomers({ commit }, query) {
      try {
        let { data } = await customer.GetMerchantCutomers(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async editAccountServices({ commit, dispatch, rootState }, profileDetails) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

      try {
        let { data } = await customer.editAccountServices(profileDetails);
        dispatch("getProfile");
        return data;
      } catch (error) {
        console.log("[] ->", error.response);
        throw Error(error.response.data.message);
      }
    },
    async adminRegisterCustomer({ commit }, userDetails) {
      try {
        let result = await customer.AdminRegisterCustomer(userDetails);
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async getSingleCustomer({ commit }, appDetails) {
      try {
        let { data } = await customer.GetSingleCustomer(appDetails);
        console.log("[data from get customers] ->", data.results);
        return data.result[0];
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async getSingleCustomerDetails({ commit }, appDetails) {
      try {
        let { data } = await customer.getSingleCustomerDetails(appDetails);
        console.log("[data from get customers] ->", data.results);
        return data.result[0];
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async getSingleCustomerAdditionalDetails({ commit }, appDetails) {
      try {
        let { data } = await customer.getSingleCustomerAdditionalDetails(
          appDetails
        );
        console.log("[data from get customers] ->", data.results);
        return data.result;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async addCredit({ commit }, appDetails) {
      try {
        let { data } = await customer.LoadCredit(appDetails);
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async getAllLogs({ commit }, clientID) {
      try {
        let { data } = await customer.allClientLogs(clientID);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getAuditTrail({ commit }, clientID) {
      return await customer.CustomerAuditTrail(clientID);
    },
    async customerCreditHistory({ commit }, clientID) {
      try {
        let { data } = await customer.CustomerCreditHistory(clientID);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async UpdateCustomerPrice({ commit }, customerData) {
      try {
        let { data } = await customer.UpdateCustomerPrice(customerData);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async acceptWhiteLabelRequest({ commit }, payload) {
      try {
        let response = await customer.acceptWhiteLabelRequest(payload);
        return response;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async rejectWhiteLabelRequest({ commit }, payload) {
      try {
        let response = await customer.rejectWhiteLabelRequest(payload);
        return response;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
  },
  getters: {},
};
