import services from "@/services/services";
import { apiClient } from "@/services/api";

export default {
  namespaced: true,
  state: {
    apps: [],
    chargeTypes: [],
  },
  mutations: {
    setChargeTypes(state, chargeTypes) {
      state.chargeTypes = chargeTypes;
    },
  },
  actions: {
    async verifyBVN({ commit }, verificationData) {
      try {
        let { data } = await services.BVNVerification(verificationData);
        console.log("[data from get BVN] ->", data.results);
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async verifyPVC({ commit }, verificationData) {
      try {
        let { data } = await services.PVCVerification(verificationData);
        console.log("[data from get PVC] ->", data.results);
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async verifyNIN({ commit }, verificationData) {
      try {
        let { data } = await services.NINVerification(verificationData);
        console.log("[data from get servicess] ->", data.results);
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async verifyDL({ commit }, verificationData) {
      try {
        let { data } = await services.DLVerification(verificationData);
        console.log("[data from get servicess] ->", data.results);
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async verifyNIP({ commit }, verificationData) {
      try {
        let { data } = await services.NIPVerification(verificationData);
        console.log("[data from get servicess] ->", data.results);
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async getChargeTypes({ commit }) {
      try {
        let { data } = await apiClient.get("/Authorize/BillingChargeType");
        // console.log('[data from billing charge type] ->', data.data);
        commit("setChargeTypes", data.data);
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async getServiceCategoriesWithServices({ commit }, payload) {
      try {
        let { data } = await services.GetServiceCategoriesWithServices(payload);
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async getSubscriptionPlans({ commit }, payload) {
      try {
        let { data } = await services.GetSubscriptionPlans(payload);
        console.log("[service categories with services] ->", data.results);
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async getSubscriptionPlanDetails({ commit }, payload) {
      try {
        let { data } = await services.GetSubscriptionPlanDetails(payload);
        console.log("[service categories with services] ->", data.results);
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async updateServiceCategoriesWithServices({ commit }, payload) {
      try {
        let { data } = await services.UpdateServiceCategoriesWithServices(
          payload
        );
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async createSubscriptionPlan({ commit }, payload) {
      try {
        let { data } = await services.CreateSubscriptionPlan(
          payload
        );
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async updateSubscriptionPlan({ commit }, payload) {
      try {
        let { data } = await services.UpdateSubscriptionPlan(
          payload
        );
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
  },
  getters: {},
};
