<template>
    <form @submit.prevent="handleSubmit">
        <div class="row mb-2">
            <div class="col-sm-6 px-sm-2 col-md-4  col-xl-2">
                <CustomSelect
                    name="type"
                    placeholder="Select Type"
                    :optionLabel="'name'"
                    :options="subscriptionType"
                    v-model="filters.type"
                />
            </div>
            

            <div className="col-sm-12 px-sm-3 col-md-10 px-ms-3 col-xl-5 mt-3">
                <button type="submit" :disabled="disabled || loading" class="btn btn-primary mx-3">
                    Filter
                </button>

                <button v-show="isFilter" type="button" class="btn btn-outline" @click="resetFilters">
                    Clear
                </button>


            </div>
        </div>
    </form>
</template>

<script>
import { filters } from "../../../../public/assets/mixins/filters";

const initialState = () => ({
    filters: {
        type: "",
        from: null,
        to: null,
    },
    isFilter: false,
    qLoading: false,
});

const subscriptionType = [
    {
        name: "General",
        value: "general",
    },
    {
        name: "Custom",
        value: "custom",
    },
];
export default {
    mixins: [filters],
    name: "SubscriptionPlansFilter",
    data() {
        return { ...initialState(), subscriptionType };
    },
    props: ["loading", "rLoading", "clearFilter", "submitFilter"],
    computed: {
        disabled() {
            return !Object.values(this.filters).some((val) => val);
        },
    },
    methods: {
        resetFilters() {
            Object.assign(this.$data, { ...initialState() });
            this.clearFilter();
            this.isFilter = false;
        },
        handleSubmit() {
            this.isFilter = true;
            if (this.filters.from)
                this.filters.from = this.timeRangeFormat(this.filters.from, true);
            if (this.filters.to)
                this.filters.to = this.timeRangeFormat(this.filters.to);

            if (this.filters.type) this.filters.type = this.filters.type.value;

            this.submitFilter(this.filters);
        },
    },
    watch: {
        "filters.from": function () {
            this.filters.to = null;
        },
    },
};
</script>
