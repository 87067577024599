<template>
  <div class="card-body">
    <apexchart height="400" type="bar" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
export default {
  props: {
    series: {
      type: Array
    }
  },
  data() {
    return {
      chartOptions: {
        fontFamily: "AllianzNeo, sans-serif",
        chart: {
          type: 'bar',
          height: 450,
          stacked: true,
        },
        plotOptions: {
          bar: {
            vertical: true,
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        title: {
          text: 'Number 2 Bank Provider Chart',
          align: "left",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "20px",
            color: "#263238",
            width: "100%"
          }
        },
        xaxis: {
          categories: ['Fets'],
          labels: {
            formatter: function (val) {
              return val
            }
          }
        },
        yaxis: {

          title: {
            text: undefined
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " Calls"
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40
        }
      },
    }
  }
}
</script>