import { apiClient } from "./api";

export default {
  GetServiceProviderParameters() {
    return apiClient.get(`Authorize/FetchServiceProviders`);
  },
  DeleteServicesProvider(id) {
    return apiClient.get(`Authorize/DeleteInfrastructureProvider/${id}`);
  },
  EditServiceProviderParameters(parameters) {
    let headers = { clientid: parameters.clientid };
    return apiClient.put(
      `Authorize/EditServiceProviderParameter/${parameters.provider_id}/${parameters.service_id}`,
      parameters.data,
      { headers }
    );
  },
  EditServiceProviderPriority(priority) {
    let headers = { clientid: priority.clientid };
    return apiClient.patch(
      `Authorize/EditProviderPriority/${priority.service_id}/${priority.provider_id}`,
      priority.data,
      { headers }
    );
  },
  GetServiceProviderPriority(priority) {
    let headers = { clientid: priority.clientid };
    return apiClient.put(
      `Authorize/InfrastructureProviders/${service_label}`,
      priority.requestPayload,
      { headers }
    );
  },
  GetProviders(payload) {
    return apiClient.get(`Authorize/Admin/Providers`, payload);
  },
  GetProviderDetail(payload) {
    return apiClient.get(`Authorize/Admin/ProviderParameters/${payload.id}`);
  },
  GetProviderServices(payload) {
    return apiClient.get(
      `Authorize/Admin/ProviderServices/${payload.id}`,
      payload
    );
  },
  GetProviderCallLogs(payload) {
    return apiClient.get(
      `Authorize/Admin/ProviderApiCalls/${payload.id}`,
      payload
    );
  },
  GetProviderApiCallCount(payload) {
    return apiClient.get(
      `Authorize/Admin/ProviderApiCallCount/${payload.id}`,
      payload
    );
  },
  GetProviderTotalApiCallPrice(payload) {
    return apiClient.get(
      `Authorize/Admin/ProviderTotalApiCallPrice/${payload.id}`,
      payload
    );
  },
  GetProviderServiceCount(payload) {
    return apiClient.get(
      `Authorize/Admin/ProviderServiceCount/${payload.id}`,
      payload
    );
  },
  GetTotalServiceAndProvider(payload) {
    return apiClient.get(`Authorize/Admin/TotalServiceAndProvider`, payload);
  },
  GetTotalApiLogCount(payload) {
    return apiClient.get(`Authorize/Admin/TotalApiLogCount?filterBy=${payload.filterBy}`, payload);
  },
  GetTotalApiCallSum(payload) {
    return apiClient.get(`Authorize/Admin/TotalApiCallSum?filterBy=${payload.filterBy}`, payload);
  },
};
