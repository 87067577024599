<template>
  <div class="container">
    <!-- Modal -->
    <div
      class="modal fade"
      :id="id"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Enter Terminal ID</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              id="close__modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row mt-4">
                <!-- ETZ Terminal ID -->
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="input-item-label">Enter eTranzact Terminal ID</label>
                    <input
                      type="text"
                      v-model="etz_terminal_id"
                      class="form-control input-bordered mt-1"
                      placeholder="Enter eTranzact Terminal ID"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-danger mr-2" data-dismiss="modal">Cancel</button>
            <button class="btn btn-primary" @click="updateTerminalID" id="verify_button">
              <template v-if="verifying === false">Send</template>
              <template v-if="verifying === true">
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                Sending...
              </template>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UpdateTerminalID",
  data() {
    return {
      etz_terminal_id: ""
    };
  },
  props: {
    id: {
      type: String
    },
    verifying: {
      type: Boolean
    }
  },
  methods: {
    updateTerminalID() {
      const etz_terminal_id = this.etz_terminal_id;
      this.$emit("updateTerminalID", { etz_terminal_id });
    }
  }
};
</script>

<style>
</style>    