<template>
  <SideModal
    :show="show"
    :toggle="toggle"
    :title="`Transaction Information`"
    :subTitle="'All informations about open transactions'"
  >
    <div class="row mt-2 pt-4">
      <div class="col-6">
        <p>
          <span class="text-muted">AMOUNT</span> <br />
          <span class="text-capitalize h3">
            {{
              toCurrency(
                singleTransactionObj.amount,
                singleTransactionObj.currency
              )
            }}
          </span>
        </p>
      </div>
      <div class="col-6">
        <p>
          <span class="text-muted">CHARGES</span> <br />
          <span class="text-capitalize h3">
            {{
              toCurrency(
                singleTransactionObj.charges,
                singleTransactionObj.currency
              )
            }}
          </span>
        </p>
      </div>
      <div class="col-12 mt-2">
        <p>
          <span class="text-muted text-uppercase">transaction id </span><br />
          <span class="text-capitalize h5">
            {{ singleTransactionObj.reference }}
          </span>
        </p>
      </div>
      <div class="col-6" v-if="singleTransactionObj.status">
        <span
          v-if="
            singleTransactionObj.status == 'successful' ||
              singleTransactionObj.status == 'approved'
          "
          class="text-success text-uppercase"
        >
          {{ titleCase(singleTransactionObj.status) }}
        </span>
        <span
          v-else-if="
            singleTransactionObj.status == 'pending' ||
              singleTransactionObj.status == 'processing'
          "
          class="text-warning text-uppercase"
        >
          {{ titleCase(singleTransactionObj.status) }}
        </span>
        <span v-else class="text-danger text-uppercase">
          {{ titleCase(singleTransactionObj.status) }}
        </span>
      </div>
      <br />
      
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">transaction date</span> <br />
          <span class="text-capitalize h5">
            {{ singleTransactionObj.createdAt | moment(" MM-DD-YYYY h:mm a") }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">Payment Method</span>
          <br />
          <span class="text-capitalize h5">
            {{ titleCase(singleTransactionObj.source) }}
          </span>
        </p>
      </div>
      <div v-if="singleTransactionObj.client_reference" class="col-6 mt-2">
        <span class="text-uppercase">Client Reference</span>
        <span class="text-uppercase">{{
          singleTransactionObj.client_reference || "Not Available"
        }}</span>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">Transaction description</span>
          <br />
          <span class="text-capitalize h5">{{
            singleTransactionObj.narration || "Not Available"
          }}</span>
        </p>
      </div>
    </div>
    <br />
    <hr />
    <br />
    <div
      v-if="
        singleTransactionObj.metadata &&
          Object.keys(singleTransactionObj.metadata).length > 0
      "
    >
      <h4
        v-if="
          !(
            singleTransactionObj.metadata &&
            Object.keys(singleTransactionObj.metadata).length > 0 &&
            isTerminal
          )
        "
        class="font-weight-bold"
      >
        Other Information
      </h4>
      <div
        class="row mt-2 pt-4"
        v-if="singleTransactionObj.metadata.terminalData"
      >
        <div class="col-6 mt-2">
          <span class="text-muted text-uppercase">Terminal Id</span>
          <br />
          <span class="text-uppercase h5">{{
            singleTransactionObj.metadata.terminalData.terminalId
          }}</span>
        </div>
        <div class="col-6 mt-2">
          <span class="text-uppercase">Pan</span>
          <br />
          <span class="text-uppercase h5">{{
            cardFormat(singleTransactionObj.metadata.terminalData.pan)
          }}</span>
        </div>
        <div class="col-6 mt-2">
          <span class="text-uppercase">Card Currency</span>
          <br />
          <span class="text-uppercase h5">{{
            singleTransactionObj.metadata.terminalData.cardCurrency
          }}</span>
        </div>
        <div class="col-6 mt-2">
          <span class="text-uppercase">Card Scheme</span>
          <br />
          <span class="text-uppercase h5">{{
            titleCase(singleTransactionObj.metadata.terminalData.cardScheme)
          }}</span>
        </div>
        <div class="col-6 mt-2">
          <span class="text-uppercase">Stan</span>
          <br />
          <span class="text-uppercase h5">{{
            titleCase(singleTransactionObj.metadata.terminalData.stan)
          }}</span>
        </div>
        <div class="col-6 mt-2">
          <span class="text-uppercase">Terminal Name</span>
          <br />
          <span class="text-uppercase h5">{{
            titleCase(singleTransactionObj.metadata.terminalData.terminalName)
          }}</span>
        </div>
      </div>
      <div class="row mt-2 pt-2">
        <div v-if="singleTransactionObj.metadata.response" class="col-6 mt-2">
          <div v-if="singleTransactionObj.metadata.response.message">
            <span class="text-uppercase">Response</span>
            <br />
            <span class="text-uppercase h5">
              {{ titleCase(singleTransactionObj.metadata.response.message) }}
            </span>
          </div>
        </div>
        <div v-if="singleTransactionObj.metadata.session_id" class="col-6 mt-2">
          <span class="text-uppercase">Session ID</span>
          <br />
          <span class="text-uppercase h5">{{ singleTransactionObj.metadata.session_id }}</span>
        </div>
        <div v-if="singleTransactionObj.metadata.ip" class="col-6 mt-2">
          <span class="text-uppercase">IP Address</span>
          <br />
          <span class="text-uppercase h5">{{ singleTransactionObj.metadata.ip }}</span>
        </div>
        <div
          v-if="singleTransactionObj.metadata.invoice_reference"
          class="col-6 mt-2"
        >
          <span class="text-uppercase">Invoice Reference</span>
          <br />
          <span class="text-uppercase h5">{{ singleTransactionObj.metadata.invoice_reference }}</span>
        </div>
        <div v-if="singleTransactionObj.metadata.source" class="col-6 mt-2">
          <span class="text-uppercase">Source</span>
          <br />
          <span class="text-uppercase h5">{{ singleTransactionObj.metadata.source }}</span>
        </div>
      </div>
      <h5
        class="font-weight-bold mt-2"
        v-if="singleTransactionObj.metadata.source_account"
      >
        Sender Details
      </h5>
      <div
        v-if="singleTransactionObj.metadata.source_account"
        class="row mt-2 pt-4"
      >
        <div
          v-if="singleTransactionObj.metadata.source_account.account_number"
          class="col-6 mt-2"
        >
          <span class="text-uppercase">Account Number</span>
          <br />
          <span class="text-uppercase h5">{{
            cardFormat(
              singleTransactionObj.metadata.source_account.account_number
            )
          }}</span>
        </div>
        <div
          v-if="singleTransactionObj.metadata.source_account.account_name"
          class="col-6 mt-2"
        >
          <span class="text-uppercase">Account Name</span>
          <br />
          <span class="text-uppercase h5">{{
            singleTransactionObj.metadata.source_account.account_name
          }}</span>
        </div>
        <div
          v-if="singleTransactionObj.metadata.source_account.bank_name"
          class="col-6 mt-2"
        >
          <span class="text-uppercase">Bank Name</span>
          <br />
          <span class="text-uppercase h5">{{
            singleTransactionObj.metadata.source_account.bank_name
          }}</span>
        </div>
      </div>

      <h5
        class="font-weight-bold mt-4"
        v-if="singleTransactionObj.metadata.destination"
      >
        Recipients Details
      </h5>
      <div
        v-if="singleTransactionObj.metadata.destination"
        class="row mt-2 pt-2"
      >
        <div
          v-if="singleTransactionObj.metadata.destination.recipient"
          class="col-6 mt-2"
        >
          <span class="text-uppercase">Account Number</span>
          <br />
          <span class="text-uppercase h5">
            {{
              cardFormat(singleTransactionObj.metadata.destination.recipient)
            }}
          </span>
        </div>
        <div
          v-if="singleTransactionObj.metadata.destination.recipient_name"
          class="col-6 mt-2"
        >
          <span class="text-uppercase">Account Name</span>
          <br />
          <span class="text-uppercase h5">
            {{ singleTransactionObj.metadata.destination.recipient_name }}
          </span>
        </div>

        <div
          v-if="singleTransactionObj.metadata.destination.bank_name"
          class="col-6 mt-2"
        >
          <span class="text-uppercase">Bank Name</span>
          <br />
          <span class="text-uppercase h5">
            {{ singleTransactionObj.metadata.destination.bank_name }}
          </span>
        </div>

        <div
          v-else-if="singleTransactionObj.metadata.destination.bank_code"
          class="col-6 mt-2"
        >
          <span class="text-uppercase">Bank Name</span>
          <br />
          <span class="text-uppercase h5">
            {{ bankName }}
          </span>
        </div>
        <div
          v-if="singleTransactionObj.metadata.destination.type"
          class="col-6 mt-2"
        >
          <span class="text-uppercase">Access Medium</span>
          <br />
          <span class="text-uppercase h5">
            {{ titleCase(singleTransactionObj.metadata.destination.type) }}
          </span>
        </div>
      </div>

      <template v-else-if="singleTransactionObj.metadata.account_number">
        <h5 class="font-weight-bold mt-4">Recipients Details</h5>
        <div class="col-6 mt-2">
          <span class="text-uppercase">Account Number</span>
          <br />
          <span class="text-uppercase h5">{{
            cardFormat(singleTransactionObj.metadata.account_number)
          }}</span>
        </div></template
      >

      <h5
        class="font-weight-bold mt-2"
        v-if="singleTransactionObj.metadata.card"
      >
        Sender Details
      </h5>
      <div v-if="singleTransactionObj.metadata.card" class="row mt-2 pt-2">
        <div
          v-if="singleTransactionObj.metadata.card.scheme"
          class="col-6 mt-2"
        >
          <span class="text-uppercase">Access Medium</span>
          <br />
          <span class="text-uppercase h5">Card</span>
        </div>

        <div v-if="singleTransactionObj.metadata.card.last4" class="col-6 mt-2">
          <span class="text-uppercase">Card Number</span>
          <br />
          <span class="text-uppercase h5">
            {{ cardFormat(singleTransactionObj.metadata.card.last4) }}</span
          >
        </div>

        <div
          v-if="singleTransactionObj.metadata.card.scheme"
          class="col-6 mt-2"
        >
          <span class="text-uppercase">Card Type</span>
          <br>
          <span class="text-uppercase h5">{{
            titleCase(singleTransactionObj.metadata.card.scheme)
          }}</span>
        </div>

        <div v-if="singleTransactionObj.metadata.cardholder" class="col-6 mt-2">
          <span class="text-uppercase">Account Name</span>
          <br />
          <span class="text-uppercase h5">
            {{
              singleTransactionObj.metadata.card.cardholder || "Not Available"
            }}
          </span>
        </div>
        <div
          v-if="singleTransactionObj.metadata.card.bankName"
          class="col-6 mt-2"
        >
          <span class="text-uppercase">Bank Name</span>
          <br />
          <span class="text-uppercase h5">
            {{ singleTransactionObj.metadata.card.bankName || "Not Available" }}
          </span>
        </div>
      </div>
    </div>
  </SideModal>
</template>

<script>
import { notifications } from "../../../../public/assets/mixins/notifications";
import { componentData } from "../../../../public/assets/mixins/componentData";
import SideModal from "../../../components/utils/Modal/SideModal.vue";
import { formatNumber } from "../../../components/utils/helpers";

export default {
  mixins: [notifications, componentData],
  name: "SubscriptionDetailModal",
  data() {
    return {};
  },
  props: ["toggle", "show", "singleTransactionObj", "isTerminal"],
  components: {
    SideModal,
  },
  methods: {
    formatNumber,
    handleCloseModal() {
      this.toggle();
    },
    cardFormat(value) {
      let result = "";
      if (value.length >= 4) result = value.substr(-4);
      return value ? `**** **** **** ${result}` : "";
    },
  },
};
</script>

<style scoped>
.d-flex {
  display: flex;
  justify-content: flex-end;
}
</style>
