<template>
	<Modal
		:id="id"
		:title="
			parameter
				? `Update Provider Parameter`
				: `Add New Provider Parameter`
		"
		:toggle="handleCloseModal"
		:loading="loading"
	>
		<SuccessState
			v-if="successMsg"
			:message="
				`Provider Parameter ${
					parameter ? 'Updated' : 'Added'
				} Successfully`
			"
			:onSuccess="handleRefetch"
		/>

		<form v-else @submit.prevent="handleSubmit" class="row">
			<template v-if="!parameter">
				<div class="col-sm-6 col-12">
					<CustomSelect
						:name="'service_id'"
						:placeholder="'Select API Service'"
						:options="apiServices"
						:optionLabel="'service_label'"
						:label="'API Service'"
						v-model="request.service_id"
						:required="true"
						:error="formErrors.service_id"
						:handleError="handleError"
					/>
				</div>

				<div class="col-sm-6 col-12">
					<CustomInput
						:name="'price'"
						:type="'number'"
						v-model="request.price"
						:label="'Price'"
						:placeholder="'Enter Price'"
						:required="true"
						:error="formErrors.price"
						:handleError="handleError"
					/>
				</div>
			</template>

			<div class="col-12">
				<CustomInput
					:name="'route'"
					v-model="request.route"
					:label="'Route'"
					:placeholder="'Enter Route'"
					:required="true"
					:error="formErrors.route"
					:handleError="handleError"
				/>
			</div>

			<div v-show="paramter" class="col-sm-6 col-12">
				<CustomInput
					:name="'price'"
					:type="'number'"
					v-model="request.price"
					:label="'Price'"
					:placeholder="'Enter Price'"
					:required="true"
					:error="formErrors.price"
					:handleError="handleError"
				/>
			</div>

			<div class="col-sm-6 col-12">
				<CustomSelect
					:name="'http_method'"
					label="HTTP Method"
					:placeholder="'Select HTTP Method'"
					:options="['POST', 'GET', 'PUT', 'PATCH', 'DELETE']"
					v-model="request.http_method"
					:required="true"
					:error="formErrors.http_method"
					:handleError="handleError"
				/>
			</div>

			<div v-if="!parameter" class="col-sm-6 col-12">
				<CustomInput
					:name="'priority'"
					:type="'number'"
					v-model="request.priority"
					:label="'Priority'"
					:placeholder="'Enter Priority'"
					:required="true"
					:error="formErrors.priority"
					:handleError="handleError"
				/>
			</div>

			<div class="col-sm-6 col-12">
				<CustomInput
					:name="'ios_validation_license'"
					v-model="request.ios_validation_license"
					:label="'IOS Validation License'"
					:placeholder="'Enter IOS Validation License'"
					:error="formErrors.ios_validation_license"
					:handleError="handleError"
				/>
			</div>

			<div class="col-sm-6 col-12">
				<CustomInput
					:name="'android_validation_license'"
					v-model="request.android_validation_license"
					:label="'Android Validation License'"
					:placeholder="'Enter Android Validation License'"
					:error="formErrors.android_validation_license"
					:handleError="handleError"
				/>
			</div>

			<div class="col-sm-6 col-12">
				<DatePicker
					:name="'core_license_expiry_date'"
					v-model="request.core_license_expiry_date"
					:label="'Core License Expiry Date'"
					:placeholder="'Core License Expiry Date'"
					:disabledDates="{ to: new Date() }"
					:error="formErrors.core_license_expiry_date"
					:handleError="handleError"
					/>
			</div>

			<div class="col-sm-6 col-12">
				<DatePicker
					:name="'last_notified_date'"
					v-model="request.last_notified_date"
					:label="'Last Notified Date'"
					:placeholder="'Last Notified Date'"
					:disabledDates="{ to: new Date() }"
					:error="formErrors.last_notified_date"
					:handleError="handleError"
					/>
			</div>

			<div class="col-12">
				<FormButtons
					:loading="loading"
					:handleCloseModal="handleCloseModal"
				/>
			</div>
		</form>
	</Modal>
</template>

<script>
	import moment from "moment";
	import { notifications } from "../../../../../public/assets/mixins/notifications";
	import { componentData} from "../../../../../public/assets/mixins/componentData";
	import Modal from "../../../../components/utils/Modal/Modal";
	import FormButtons from "../../../../components/utils/FormButtons/FormButtons";
	import CustomInput from "../../../../components/utils/CustomInput/CustomInput";
	import CustomSelect from "../../../../components/utils/CustomSelect/CustomSelect";
	import SuccessState from "../../../../components/utils/ResponseState/SuccessState";
	import { formValidator } from "../../../../components/utils/FormValidator/FormValidator";
	import Spinner from "../../../../components/utils/Spinner/Spinner";

	const initialState = () => ({
		request: {
			service_id: "",
			priority: "",
			price: "",
			http_method: "",
			route: "",
			android_validation_license: "",
			core_license_expiry_date: "",
			ios_validation_license: "",
			last_notified_date: "",
		},
		loading: false,
		successMsg: false,
		formErrors: {},
	});

	export default {
		mixins: [notifications, componentData],
		name: "AddProviderParametersModal",
		data() {
			return { ...initialState() };
		},
		props: [
			"id",
			"closeModal",
			"provider",
			"parameter",
			"refetch",
			"apiServices",
		],
		components: {
			Modal,
			CustomInput,
			CustomSelect,
			SuccessState,
			Spinner,
			FormButtons,
		},
		methods: {
			handleCloseModal() {
				Object.assign(this.$data, { ...initialState() });
				this.closeModal();
				this.setProviderData();
			},
			handleRefetch() {
				this.handleCloseModal();
				this.refetch();
				this.setProviderData();
			},
			getData() {
				const data = { ...this.request };
				!this.request.ios_validation_license &&
					delete data.ios_validation_license;
				!this.request.android_validation_license &&
					delete data.android_validation_license;
				!this.request.core_license_expiry_date &&
					delete data.core_license_expiry_date;
				!this.request.last_notified_date &&
					delete data.last_notified_date;
				return data;
			},
			handleSubmit(e) {
				e.preventDefault();
				const { formErrors, isValid } = formValidator(this.getData());
				if (isValid)
					this.parameter
						? this.updateService()
						: this.createService();
				else this.formErrors = formErrors;
			},
			handleError(name) {
				this.formErrors = { ...this.formErrors, [name]: "" };
			},
			showError(msg) {
				this.loading = false;
				this.showErrorLoginNotification(msg);
			},

			async createService() {
				try {
					const data = {
						...this.getData(),
						provider_id: this.provider.id,
						service_id: this.request.service_id.id,
					};
					if(data.core_license_expiry_date) {
						data.core_license_expiry_date = moment(data.core_license_expiry_date).format("YYYY-MM-DD");
					}
					if(data.last_notified_date) {
						data.last_notified_date = moment(data.last_notified_date).format("YYYY-MM-DD");
					}
					this.loading = true;
					const response = await this.$store.dispatch(
						"infrastructureProviders/createProviderApiService",
						data
						);
					if (response.status_code === 200) {
						this.loading = false;
						this.successMsg = true;
					} else
						this.showError(
							response.message || "Error Creating Service"
						);
				} catch (error) {
					this.showError(error.message);
				}
			},

			async updateService() {
				try {
					const data = {
						...this.getData(),
						provider_id: this.provider.id,
					};
					this.loading = true;
					const response = await this.$store.dispatch(
						"serviceProviders/editServiceProviderParameters",
						{
							provider_id: data.provider_id,
							service_id: data.service_id,
							data,
						}
					);
					if (response.status_code === 201) {
						this.loading = false;
						this.successMsg = true;
					} else
						this.showError(
							response.message || "Error Updating Parameter"
						);
				} catch (error) {
					this.showError(error.message);
				}
			},

			setProviderData() {
				if (this.parameter) {
					this.request.service_id = this.parameter.service_id;
					this.request.priority = this.parameter.priority;
					this.request.price = this.parameter.price;
					this.request.http_method = this.parameter.http_method;
					this.request.route = this.parameter.route;
					this.request.android_validation_license = this.parameter.android_validation_license;
					this.request.core_license_expiry_date = this.parameter.core_license_expiry_date;
					this.request.ios_validation_license = this.parameter.ios_validation_license;
					this.request.last_notified_date = this.parameter.last_notified_date;
				}
			},
		},
		mounted() {
			this.setProviderData();
		},
	};
</script>
