<template>
	<Modal
		:id="id"
		:title="`Delete ${provider.provider_name}`"
		:toggle="handleCloseModal"
		:loading="loading"
	>
		<SuccessState
			v-if="successMsg"
			:message="`${provider.provider_name} Deleted Successfully`"
			:onSuccess="handleRefetch"
		/>
		<div v-else-if="loading" class="my-5">
			<Spinner :size="'20px'" />
		</div>

		<form v-else @submit.prevent="deleteCategory">
			<h4 class="text-center my-4">
				Are you sure you wanted to delete
				{{ provider.provider_name }}?
			</h4>

			<FormButtons
				:btnName="'Delete'"
				:primary="false"
				:loading="loading"
				:handleCloseModal="handleCloseModal"
			/>
		</form>
	</Modal>
</template>

<script>
	import { notifications } from "../../../../../public/assets/mixins/notifications";
	import Modal from "../../../../components/utils/Modal/Modal";
	import FormButtons from "../../../../components/utils/FormButtons/FormButtons";
	import SuccessState from "../../../../components/utils/ResponseState/SuccessState";
	import Spinner from "../../../../components/utils/Spinner/Spinner";

	const initailState = () => ({
		loading: false,
		successMsg: false,
	});

	export default {
		mixins: [notifications],
		name: "DeleteServiceProviderModal",
		data() {
			return { ...initailState() };
		},
		props: ["id", "closeModal", "provider"],
		components: { Modal, SuccessState, Spinner, FormButtons },
		methods: {
			handleCloseModal() {
				Object.assign(this.$data, { ...initailState() });
				this.closeModal();
			},
			async handleRefetch() {
				this.handleCloseModal();
				await this.$store.dispatch("getMetaData");
			},
			showError(msg) {
				this.loading = false;
				this.showErrorLoginNotification(msg);
			},
			async deleteCategory() {
                try {
                    this.loading = true;
					const response = await this.$store.dispatch(
						`serviceProviders/deleteServicesProvider`,
						this.provider.id
					);

					if (response.status_code === 200) {
						this.loading = false;
						this.successMsg = true;
					} else
						this.showError(
							response?.message ||
								"Error Deleting Service Category"
						);
				} catch (error) {
					this.showError(error.message);
				}
			},
		},
	};
</script>
