<template>
  <div>
    <div class="px-3">
      <FilterWrapper>
        <template slot="filterForm">
          <CallLogFilters
            :loading="loading"
            :rLoading="rLoading"
            :submitFilter="refetch"
            :clearFilter="() => clearFilter('walletsRef')"
          />
        </template>
      </FilterWrapper>
    </div>
    <TableWrapper
      :loading="loading"
      :errorMsg="errorMsg"
      :refetch="fetchProviderCallLogs"
      :isEmpty="isEmpty"
      :emptyTitle="emptyTitle"
    >
      <div class="w-100 py-3" slot="content">
        <v-server-table
          ref="walletsRef"
          class="v-table"
          :data="customers"
          :columns="columns"
          :options="options"
          @loading="setLoading(true)"
          @loaded="onLoaded"
          @row-click="handleClick"
        >
          <template slot="amount" slot-scope="{ row }">
            &#8358;{{ formatNumber(row.amount) || "0" }}
          </template>
          <div slot="status" slot-scope="{ row }">
            <span
              class="badge badge-app"
              :class="row.status === 1 ? 'badge-success' : 'badge-danger'"
            >
              {{ row.status === 1 ? "Successful" : "Failed" }}
            </span>
          </div>
          <template slot="created_at" slot-scope="{ row }">
            {{ row.created_at | moment(" MM-DD-YYYY h:mm a") }}
          </template>
        </v-server-table>
      </div>
    </TableWrapper>
  </div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../../../components/utils/TableWrapper/TableWrapper.vue";
import { formatNumber } from "../../../../../components/utils/helpers";
import CallLogFilters from '../../../filters/CallLogFilters.vue'

const tableHeadings = {
  request_reference: "Reference",
  service_label: "Service",
  price_per_call: "Price",
  status: "Status",
  created_at: "Date Created",
};

export default {
  name: "CallLogs",
  mixins: [componentData],
  data() {
    return {
      singleTransactionObj: null,
      showTransactionModal: false,
      emptyTitle: "No Logs Found",
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(tableHeadings, 25),
    };
  },
  components: {
    TableWrapper,
    CallLogFilters,
  },
  methods: {
    toggleTransactionModal() {
      this.showTransactionModal = !this.showTransactionModal;
    },
    handleClick({ row }) {
      this.singleTransactionObj = row;
      this.showTransactionModal = true;
    },
    refetch(filters) {
      let newFilters = { ...filters };
      if (newFilters.status) newFilters.status = newFilters.status === 'successful' ? 1 : 0;
      this.handleRefetch(newFilters, this.fetchProviderCallLogs, "walletsRef");
    },
    async fetchProviderCallLogs() {
      this.options.requestFunction = async (data) => {
        const params = {
          ...data,
          ...this.filters,
        };
        const response = await this.$store.dispatch(
          "serviceProviders/getProviderCallLogs",
          {
            params,
            id: this.$route.params.id,
          }
        );
        const newData = response.data.data;
        const count = response.data.total;
        return { ...response, count, total: count, data: newData };
      };
    },
    formatNumber,
  },

  props: ["clientid"],
  created() {
    this.fetchProviderCallLogs();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
