<template>
  <div class="az-content-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="SectionItem no-b-b">
          <div class="SectionHeader">
            <span class="title">Bill Payment Logs</span>
            <div class="description">
              View all bill payment logs on the system group by service
            </div>
          </div>
        </div>
      </div>

      <div class="px-3">
        <FilterWrapper>
          <template slot="filterForm">
            <BillsPaymentFilters
              :loading="loading"
              :rLoading="rLoading"
              :submitFilter="refetch"
              :clearFilter="() => clearFilter('serverTableRef')"
            />
          </template>
        </FilterWrapper>
      </div>
      <div class="col-lg-12 card pt-3">
        <v-server-table
          ref="serverTableRef"
          name="question-table"
          class="v-table"
          :data="customers"
          :columns="columns"
          :options="options"
          @loaded="onLoaded"
          @loading="setLoading(true)"
        >
          <template slot="amount" slot-scope="props">
            <span>{{ toCurrency(props.row.amount) }}</span>
          </template>
          <template slot="organization_name" slot-scope="props">
            <span>{{ props.row.organization_name || "N/A" }}</span>
          </template>
          <template slot="created_at" slot-scope="props">
            <span>{{
              props.row.created_at | moment(" MM-DD-YYYY h:mm a")
            }}</span>
          </template>
          <template slot="status" slot-scope="{ row }">
            <span :class="statusColor(row.status)">
              {{ titleCase(row.status) }}
            </span>
          </template>
        </v-server-table>
      </div>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../public/assets/mixins/componentData";
import BillsPaymentFilters from "../../components/filters/BillsPaymentFilter.vue";

const tableHeadings = {
  amount: "Cost",
  organization_name: "Organization Name",
  type: "Service",
  recipient: "Recipient",
  status: "Status",
  created_at: "Date of Trnx",
};
export default {
  mixins: [componentData],
  data() {
    return {
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(tableHeadings, 25),
    };
  },
  components: {
    BillsPaymentFilters,
  },
  methods: {
    refetch(filters) {
      this.handleRefetch(filters, this.fetchAdminLogs, "serverTableRef");
    },
    async fetchAdminLogs() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters };
        const apiResponse = await this.$store.dispatch(
          "logs/getBillPaymentLogs",
          {
            params,
          }
        );
        const newData = apiResponse.data.data;
        const count = apiResponse.data.total;
        return { ...apiResponse, count, total: count, data: newData };
      };
    },
    async downloadLogs() {
      const download = (filterData) => {
        return this.downloadReport(tableHeadings, filterData.data);
      };
      this.downloadTableReport("logs/getAdminLogs", download);
    },
  },
  created() {
    this.fetchAdminLogs();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
