<template>
  <div class="p-md-4 p-3">
    <div class="SectionItem no-b-b mb-3">
      <div>
        <p class="title m-0">Audit Trails</p>
        <p class="text-left description">
          View the trail of actions performed by an admin user
        </p>
      </div>
    </div>

    <div class="px-3">
      <FilterWrapper>
        <template slot="pageSelect"> </template>
        <template slot="filterForm">
          <AuditTrailFilter
            :loading="loading"
            :rLoading="rLoading"
            :submitFilter="refetch"
            :clearFilter="() => clearFilter('auditTrail')"
            :download="downloadTrails"
          />
        </template>
      </FilterWrapper>
    </div>

    <TableWrapper
      :loading="loading"
      :errorMsg="errorMsg"
      :refetch="fetchAuditTrails"
      :isEmpty="isEmpty"
      :emptyTitle="emptyTitle"
    >
      <div class="w-100 py-3" slot="content">
        <v-server-table
          ref="auditTrail"
          class="v-table row-pointer"
          :columns="columns"
          :options="options"
          @loaded="onLoaded"
        >
          <template slot="createdAt" slot-scope="props">
            <span>{{
              props.row.createdAt | moment(" MM-DD-YYYY h:mm a")
            }}</span>
          </template>
        </v-server-table>
      </div>
    </TableWrapper>
  </div>
</template>
<script>
import FailedState from "../components/utils/ResponseState/FailedState";
import { tableOptions } from "../components/utils/helpers";
import AuditTrailFilter from "../components/filters/AuditTrailFilter";
import { componentData } from "../../public/assets/mixins/componentData";
import TableWrapper from "../components/utils/TableWrapper/TableWrapper.vue";

const tableHeadings = {
  id: "Id",
  event: "Event",
  name: "Name",
  category: "Category",
  createdAt: "Date Created",
};
export default {
  name: "AuditTrails",
  mixins: [componentData],

  data() {
    return {
      emptyTitle: "No Customer Found",
      loading: false,
      errorMsg: "",
      ...tableOptions(tableHeadings, 25),
    };
  },
  components: {
    FailedState,
    AuditTrailFilter,
    TableWrapper,
  },
  methods: {
    refetch(filters) {
      let newFilters = { ...filters };
      if (newFilters.category) {
        newFilters = {
          ...newFilters,
          "category[0]": newFilters.category.value,
        };
      }
      if (newFilters.event) {
        newFilters = { ...newFilters, "event[0]": newFilters.event };
      }
      this.handleRefetch(newFilters, this.fetchAuditTrails, "auditTrail");
    },
    async fetchAuditTrails() {
      this.options.requestFunction = async (data) => {
        const params = { size: data.limit, page: data.page, ...this.filters };
        const response = await this.$store.dispatch("logs/getAuditTrail", {
          params,
        });

        const newData = response.result.logs;
        const total = response.result.total;
        return { count: total, total: total, data: newData };
      };
    },
    async downloadTrails() {
      const download = (filterData) => {
        return this.downloadReport(tableHeadings, filterData.logs);
      };
      this.downloadTableReport("logs/getAuditTrail", download);
    },
  },
  created() {
    this.fetchAuditTrails();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
