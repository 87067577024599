<template>
  <div class="p-md-4 p-3">
    <div class="SectionItem no-b-b mb-3">
      <div>
        <p class="title m-0">Payment Merchants</p>
        <p class="text-left description">
          View all the payment customers information
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-12 col-xl-12">
        <nav class="nav az-nav-line nav-line-user-account">
          <router-link
            to="/payment-merchants?tab=activated"
            class="nav-link "
            :class="route === 'activated' ? 'active show' : ''"
            >Activated</router-link
          >
          <router-link
            to="/payment-merchants?tab=verifications"
            class="nav-link"
            :class="route === 'verifications' ? 'show active' : ''"
            >Verifications</router-link
          >
        </nav>
      </div>
    </div>
    <div class="tab-content pt-4 mx-2">
      <div
        id="activated"
        class="row tab-pane "
        :class="route === 'activated' ? 'show active' : ''"
      >
        <Activated />
      </div>
      <div
        id="verifications"
        class="row tab-pane"
        :class="route === 'verifications' ? 'show active' : ''"
      >
        <Verifications />
      </div>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../public/assets/mixins/componentData";
import PaymentMerchantFilter from "../../components/filters/PaymentMerchantFilter.vue";
import Activated from "./PaymentMerchantTabs/Activated.vue";
import Verifications from "./PaymentMerchantTabs/Verifications.vue";

const tableHeadings = {
  firstName: "First Name",
  lastName: "Last Name",
  name: "Organisation",
  email: "email",
  reference: "Reference",
  updatedAt: "Date Created",
};

export default {
  name: "PaymentMerchants",
  mixins: [componentData],
  data() {
    return {
      singleInviteObj: null,
      showSingleInviteModal: false,
      emptyTitle: "No Payment Merchant Found",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(tableHeadings, 25),
    };
  },
  components: {
    PaymentMerchantFilter,
    Activated,
    Verifications,
  },
  methods: {},
  created() {},
  computed: {
    route() {
      return this.$route.query.tab;
    },
  },
};
</script>
