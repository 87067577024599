import { apiClient } from "./api";

export default {
  CreditHistory() {
    return apiClient.get(`Authorize/CreditHistory`);
  },
  getAdminLogs(data) {
    let {  query } = data;
    query = JSON.stringify(query);
    return apiClient.get(`Authorize/Admin/ServiceLogs`, data);
  },
  getAuditTrail(query) {
    return apiClient.get(`Authorize/Admin/AuditTrail`, query);
  },
  getAllWhiteLabelRequests() {
    return apiClient.get("Authorize/Admin/GetWhiteLabelRequests");
  },
  GetCreditHistoryOverview(query) {
    return apiClient.get(
      `Authorize/admin/payments/CreditLogs/History?fromDate=2021-09-01&toDate=2022-04-20&page=1`,
      query
    );
  },
  GetCreditLogStats(query) {
    return apiClient.get(
      `Authorize/admin/payments/CreditLogs/Stats?filterBy=${query.filterBy}`
    );
  },
  GetProviderOverviewLogStats(query) {
    return apiClient.get(
      `Authorize/Admin/ProviderApiCallGraph/${query.id}?filterBy=${query.filterBy}`
    );
  },
  GetSMSLogs(query) {
    return apiClient.get(`Authorize/Messaging/AdminSmsLogs`, query);
  },
  GetServiceReportLogs(query) {
    return apiClient.get(`Authorize/AdminVerificationReport`, query);
  },
  GetBusinessRegistrationLogs(query) {
    return apiClient.get(`Authorize/Admin/CACRegistration/Registrations`, query);
  },
  GetBusinessRegistrationRefreshLogs(query) {
    return apiClient.get(`Authorize/Admin/CACRegistration/Refresh/${query.params.id}`, query);
  },
  GetBusinessRegistrationLogDetails(query) {
    return apiClient.get(`Authorize/Admin/CACRegistration/${query.params.id}`, query);
  },
  GetServiceReportLogDetails(query) {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    return apiClient.get(
      `Authorize/AdminVerificationReportDetails/${id ?? query.params.id}`,
      query
    );
  },
  GetServiceBulkReportLogDetails(query) {
    return apiClient.get(
      `Authorize/AdminBulkVerificationReportDetails/${query.params.id}`,
      query
    );
  },
  GetServiceBulkReportLogDetailsExport(query) {
    let headers = {
      clientid: query.params.clientid,
    };
    return apiClient.get(
      `Authorize/AdminBulkVerificationReportDetails/export/${query.params.id}`,
      {headers}
    );
  },
  GetBillPaymentLogs(query) {
    return apiClient.get(`Authorize/AdminBillPaymentLogs`, query);
  },
  GetProviderLogStats(query) {
    return apiClient.get(
      `Authorize/Admin/ApiLogsOverviewGraph?filterBy=${query.filterBy}`
    );
  },
  GetSmartIDCustomers(query) {
    return apiClient.get(`Authorize/Admin/SmartId/Customers`, query);
  },
  GetSmartIDCustomersDetails(query) {
    return apiClient.get(`Authorize/Admin/SmartId/CustomerProfile/${query.smartId}`, query);
  },
  GetSmartIDCustomerVerificationDetails(query) {
    return apiClient.get(`Authorize/Admin/SmartId/CustomerVerificationDetails/${query.smartId}`, query);
  },
  GetSmartIDBusiness(query) {
    return apiClient.get(`Authorize/Admin/SmartId/Businesses`, query);
  },
  GetSmartIDBusinessesDetails(query) {
    return apiClient.get(`Authorize/Admin/SmartId/BusinessProfile/${query.smartId}`, query);
  },
  GetSmartIDBusinessesVerificationDetails(query) {
    return apiClient.get(`Authorize/Admin/SmartId/BusinessVerificationDetails/${query.smartId}`, query);
  },
};
