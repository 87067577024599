<template>
  <div class="p-md-4 p-3">
    <div class="SectionItem no-b-b mb-3">
      <div>
        <p class="title m-0">Payment Transactions</p>
        <p class="text-left description">Payment Summary</p>
      </div>
      <div>
        <button class="btn btn-lg btn-secondary-inverted">
          <span
            ><img src="/assets/img/icons/document-download.svg" alt=""
          /></span>
          Export CSV
        </button>
      </div>
    </div>
    <div class="px-3">
      <FilterWrapper>
        <template slot="filterForm">
          <PaymentMerchantFilter
            :loading="loading"
            :rLoading="rLoading"
            :submitFilter="refetch"
            :clearFilter="() => clearFilter('merchantRef')"
          />
        </template>
      </FilterWrapper>
    </div>
    <TableWrapper
      :loading="loading"
      :errorMsg="errorMsg"
      :refetch="fetchPaymentCustomers"
      :isEmpty="isEmpty"
      :emptyTitle="emptyTitle"
    >
      <div class="w-100 py-3" slot="content">
        <v-server-table
          ref="merchantRef"
          class="v-table row-pointer"
          :data="customers"
          :columns="columns"
          :options="options"
          @loaded="onLoaded"
          @loading="setLoading(true)"
          @row-click="handleClick"
        >
          <template slot="user.firstname" slot-scope="{ row }">
            <p class="mb-0 font-weight-bold">
              {{ row.user.firstname }}
              {{ row.user.lastname }}
            </p>
            {{ row.client_id }}
          </template>
          <template slot="user.lastname" slot-scope="{ row }">
            {{ row.user.roles[0].role_label }}
          </template>
          <div slot="is_active" slot-scope="{ row }">
            <span
              class="badge badge-app"
              :class="row.is_active == 1 ? 'badge-success' : 'badge-danger'"
            >
              {{ row.is_active == 1 ? "Active" : "Inactive" }}
            </span>
          </div>
          <template slot="createdAt" slot-scope="{ row }">
            {{ row.createdAt | moment(" MM-DD-YYYY h:mm a") }}
          </template>
        </v-server-table>
      </div>
    </TableWrapper>
  </div>
</template>

<script>
import { componentData } from "../../../public/assets/mixins/componentData";
import TableWrapper from "../../components/utils/TableWrapper/TableWrapper.vue";
import PaymentMerchantFilter from "../../components/filters/PaymentMerchantFilter.vue";

const tableHeadings = {
  id: "Merchant ID",
  organization_name: "Full Name",
  name: "Organisation",
  email: "email",
  createdAt: "Date Created",
};

export default {
  name: "PaymentDisputes",
  mixins: [componentData],
  data() {
    return {
      singleInviteObj: null,
      showSingleInviteModal: false,
      emptyTitle: "No Payment Found",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(tableHeadings, 25),
    };
  },
  components: {
    TableWrapper,
    PaymentMerchantFilter,
  },
  methods: {
    handleClick({ row }) {
      this.$router.push(`/payment-merchant/${row.id}`);
    },
    refetch(filters) {
      this.handleRefetch(filters, this.fetchPaymentCustomers, "merchantRef");
    },
    async fetchPaymentCustomers() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters };
        const response = await this.$store.dispatch(
          "customer/getPaymentCustomers",
          {
            params,
          }
        );
        const newData = response.data.data;
        const count =
          response.data.count > 0
            ? response.data.count - 1
            : response.data.count;
        return { ...response, count, total: count, data: newData };
      };
    },
  },
  created() {
    this.fetchPaymentCustomers();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
