<template>
  <div class="az-content-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="SectionItem no-b-b mb-3">
          <div class="SectionHeader">
            <span class="title">
              <router-link
                to="/payment-merchants?tab=activated"
                class="nav-sub-link"
                ><img src="/assets/img/icons/close.svg" alt="image" height="30"
              /></router-link>
              Merchant Information</span
            >
          </div>
          <!-- <div class="content">
            <router-link to="/customers" class="btn btn-lg btn-outline-danger">
              <i class="la la-arrow-left"></i> Back
            </router-link>
          </div> -->
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 card p-4">
        <h1 class="merchant-title">
          <span class="mr-4" v-if="merchantInformation.logo"
            ><img height="35" :src="merchantInformation.logo" alt=""/></span
          >{{ merchantInformation.name }}
        </h1>
        <div class="contact-details--card">
          <div class="contact-details--card-row">
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">
                  Merchant Reference
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                  {{ merchantInformation.reference }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">CLIENT NAME</div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{ merchantInformation.firstName }}
                  {{ merchantInformation.lastName || "Null" }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">
                  Email Address
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{ merchantInformation.email || "Null" }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">Phone Number</div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{ merchantInformation.phoneNumber || "Null" }}
                </div>
              </span>
            </div>

            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">Date Created</div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                  {{
                    merchantInformation.createdAt | moment(" MM-DD-YYYY h:mm a")
                  }}
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-12 col-xl-12">
        <nav class="nav az-nav-line nav-line-user-account">
          <router-link
            :to="`/payment-merchant/${clientid}?tab=overview`"
            class="nav-link "
            :class="route === 'overview' ? 'active show' : ''"
            >Overview</router-link
          >
          <router-link
            :to="`/payment-merchant/${clientid}?tab=wallet`"
            class="nav-link "
            :class="route === 'wallet' ? 'active show' : ''"
            >Wallet</router-link
          >
          <router-link
            :to="`/payment-merchant/${clientid}?tab=collection`"
            class="nav-link "
            :class="route === 'collection' ? 'active show' : ''"
            >Collection</router-link
          >
          <router-link
            :to="`/payment-merchant/${clientid}?tab=reserved-account`"
            class="nav-link "
            :class="route === 'reserved-account' ? 'active show' : ''"
            >Reserved Account</router-link
          >
          <router-link
            :to="`/payment-merchant/${clientid}?tab=transfer`"
            class="nav-link "
            :class="route === 'transfer' ? 'active show' : ''"
            >Transfer</router-link
          >
          <router-link
            :to="`/payment-merchant/${clientid}?tab=suscription`"
            class="nav-link "
            :class="route === 'suscription' ? 'active show' : ''"
            >Subscriptions</router-link
          >
          <router-link
            :to="`/payment-merchant/${clientid}?tab=invoice`"
            class="nav-link "
            :class="route === 'invoice' ? 'active show' : ''"
            >Invoice</router-link
          >
          <router-link
            :to="`/payment-merchant/${clientid}?tab=customers`"
            class="nav-link "
            :class="route === 'customers' ? 'active show' : ''"
            >Customers</router-link
          >
          <router-link
            :to="`/payment-merchant/${clientid}?tab=transactions`"
            class="nav-link "
            :class="route === 'transactions' ? 'active show' : ''"
            >Transactions</router-link
          >
          <router-link
            :to="`/payment-merchant/${clientid}?tab=tms`"
            class="nav-link "
            :class="route === 'tms' ? 'active show' : ''"
            >TMS</router-link
          >
          <router-link
            :to="`/payment-merchant/${clientid}?tab=settings`"
            class="nav-link "
            :class="route === 'settings' ? 'active show' : ''"
            >Settings</router-link
          >
        </nav>
      </div>
    </div>
    <div class="tab-content pt-4">
      <div
        id="overview"
        class="row tab-pane "
        :class="route === 'overview' ? 'show active' : ''"
      >
        <Overview :merchantOverview="merchantOverview" :loading="loading" />
      </div>
      <div
        id="wallet"
        class="row tab-pane "
        :class="route === 'wallet' ? 'show active' : ''"
      >
        <Wallets :clientid="clientid" />
      </div>
      <div
        id="collection"
        class="row tab-pane "
        :class="route === 'collection' ? 'show active' : ''"
      >
        <Collections :clientid="clientid" />
      </div>
      <div
        id="reserved-account"
        class="row tab-pane "
        :class="route === 'reserved-account' ? 'show active' : ''"
      >
        <ReservedAccounts :clientid="clientid" />
      </div>
      <div
        id="customers"
        class="row tab-pane "
        :class="route === 'customers' ? 'show active' : ''"
      >
        <MerchantCustomer :clientid="clientid" />
      </div>
      <div
        id="transactions"
        class="row tab-pane"
        :class="route === 'transactions' ? 'show active' : ''"
      >
        <Transactions :clientid="clientid" />
      </div>
      <div
        id="suscription"
        class="row tab-pane"
        :class="route === 'suscription' ? 'show active' : ''"
      >
        <Suscription :clientid="clientid" />
      </div>
      <div
        id="invoice"
        class="row tab-pane"
        :class="route === 'invoice' ? 'show active' : ''"
      >
        <Invoice :clientid="clientid" />
      </div>
      <div
        id="settings"
        class="row tab-pane "
        :class="route === 'settings' ? 'show active' : ''"
      >
        <Settings :clientid="clientid" />
      </div>
      <div
        id="transfer"
        class="row tab-pane "
        :class="route === 'transfer' ? 'show active' : ''"
      >
        <Transfer :clientid="clientid" />
      </div>
      <div
        id="transfer"
        class="row tab-pane "
        :class="route === 'tms' ? 'show active' : ''"
      >
        <Terminals :clientid="clientid" />
      </div>
    </div>
  </div>
</template>

<script>
import customer from "../../../services/customer";
import Overview from "./Tabs/Overview.vue";
import Transactions from "./Tabs/Transactions.vue";
import Transfer from "./Tabs/Transfer.vue";
import Wallets from "./Tabs/Wallets.vue";
import Terminals from "./Tabs/Tms/Terminals.vue";
import ReservedAccounts from "./Tabs/ReservedAccounts.vue";
import Invoice from "./Tabs/Invoice.vue";
import MerchantCustomer from "./Tabs/MerchantCustomer.vue";
import { copyToClipboard } from "../../../components/utils/helpers";
import Suscription from "./Tabs/Suscription.vue";
import Settings from "./Tabs/Settings.vue";
import Collections from "./Tabs/Collections.vue";

export default {
  name: "MerchantDetail",
  props: {
    clientid: {
      type: String,
    },
  },
  data() {
    return {
      merchantOverview: null,
      merchantInformation: null,
      loading: false,
    };
  },
  methods: {
    copyToClipboard,
    async fecthMerchantOverview() {
      try {
        this.loading = true;
        const response = await customer.GetPaymentMerchantOverview({
          id: this.clientid,
        });
        this.merchantOverview = response.data.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.errorMsg = error.response.data.message;
      }
    },
    async fetchCurrentMerchantInfo() {
      const response = await customer.GetCurrentMerchantInfo({
        id: this.clientid,
      });
      this.merchantInformation = response.data.data;
    },
  },
  mounted() {
    this.fecthMerchantOverview();
    this.fetchCurrentMerchantInfo();
  },
  computed: {
    route() {
      return this.$route.query.tab;
    },
  },
  components: {
    Overview,
    Transactions,
    Transfer,
    MerchantCustomer,
    Suscription,
    Settings,
    Wallets,
    ReservedAccounts,
    Collections,
    Invoice,
    Terminals
  },
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
.merchant-title {
  font-family: "AllianzNeo";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 27px;
  color: #071b2b;
}
</style>
