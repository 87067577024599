<template>
  <div class="page-ath w-bg">
    <div class="row justify-content-center align-items-center" style="height: 100vh; width:100%;">
      <transition mode="out-in" name="slide">
        <slot></slot>
      </transition>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.page-ath.w-bg {
  background: #fff;
  background-image: url(/assets/img/app-background.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  /* height: 722px; */
}
.card {
  border-radius: 4px;
  margin-bottom: 15px;
  border: none;
  background: #fff;
  transition: all 0.4s;
  vertical-align: top;
}

.card-shadow {
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.05);
}
.card-shadow:hover {
  box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.09);
}
.card-full-height {
  height: calc(100% - 15px);
}
.card-gradient-pri-sec {
  background-image: linear-gradient(45deg, #1c65c9 0%, #2c80ff 100%);
}
.card-innr {
  padding: 30px 32px;
  border-color: #e6effb !important;
}
.card-innr > .card-title {
  padding-bottom: 10px;
}
.card-innr > div:last-child:not(.input-item):not(.row):not(.step-head) {
  margin-bottom: 5px;
}
.account-info
  .card-innr
  > div:last-child:not(.input-item):not(.row):not(.step-head) {
  margin-bottom: 0;
}
.card-innr-fix {
  padding-bottom: 20px;
}
.card-title {
  color: #253992;
  font-size: 1.3em;
  font-weight: 500;
  margin: 0;
}
.card-title-lg {
  font-size: 1.6em;
}
.card-title-md {
  font-size: 1.3em;
}
.card-title-sm {
  font-size: 1.1em;
}
.card-title:last-child {
  margin-bottom: 0;
}
.card-title-text {
  width: 100%;
}
.card-title-text:not(:first-child) {
  margin-top: 4px;
}
.card-title + .btn-grp {
  margin-top: -5px;
}
.card-sub-title {
  text-transform: uppercase;
  color: #758698;
  letter-spacing: 0.1em;
  display: block;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 4px;
}
.card-head {
  padding-bottom: 18px;
}
.card-head.has-aside {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.card-head:last-child {
  padding-bottom: 0;
}
.card-opt {
  position: relative;
}
.card-footer {
  background: #f6f8fc;
}
.card .content:not(:first-child) {
  margin-top: 10px;
}
.card-token {
  background-image: linear-gradient(45deg, #1c65c9 0%, #2c80ff 100%);
  color: #fff;
}
.card-token .card-sub-title {
  color: #74fffa;
  margin-bottom: 4px;
}
.card-calc .note p {
  font-size: 11px !important;
}
.card-text-light {
  color: #fff;
}
.card-text-light .card-title,
.card-text-light .card-sub-title {
  color: #fff;
}
.card-text-light .card-opt > a {
  color: #fff !important;
}
.card-text-light .card-opt > a:after {
  border-top-color: #fff;
}
.card-text-light p {
  color: #fff;
}
.card-dark {
  background: #090d1c;
}
.card-primary {
  background: #2c80ff;
}
.card-secondary {
  background: #253992;
}
.card-success {
  background: #00d285;
}
.card-warning {
  background: #ffc100;
}
.card-info {
  background: #1babfe;
}
.card-danger {
  background: #ff6868;
}
.card-navs .card-innr {
  padding-top: 14px;
  padding-bottom: 14px;
}
.card-timeline .card-innr {
  height: calc(100% - 32px);
}

@media (min-width: 410px) {
  .card-token .token-balance-list li {
    min-width: 25%;
  }
}
@media (min-width: 576px) {
  .card {
    margin-bottom: 30px;
  }
  .card-title {
    font-size: 1.4em;
  }
  .card-title-sm {
    font-size: 1.1em;
    padding-top: 2px;
  }
  .card-innr {
    padding: 25px 30px;
  }
  .card-innr-fix {
    padding-bottom: 30px;
  }
  .card-innr-fix2 {
    padding-bottom: 10px;
  }
  .card-full-height {
    height: calc(100% - 30px);
  }
  .card-timeline .card-innr {
    height: calc(100% - 50px);
  }
}
@media (min-width: 992px) {
  .card-navs .card-innr {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .card-token .token-balance-list li {
    min-width: 84px;
  }
}
@media (min-width: 1200px) {
  .card-token .token-balance-list li {
    min-width: 33.33%;
  }
}

.card-innr.auth-card {
  padding: 45px 40px;
}
</style>