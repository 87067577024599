<template>
  <div>
    <div
      class="modal fade"
      :id="id"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg " role="document">
        <div class="modal-content border-0">
          <div class="modal-header bg-black">
            <h5 class="modal-title text-dark" id="exammpleModalTitle">
              Edit Priority
            </h5>
            <button
              class="close text-white"
              type="button"
              data-dismiss="modal"
              aria-label="close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h5 style="font-weight:600;">
              Priority Listing for
              {{ providers[0].service_label }} Service Provider(s)
            </h5>
            <div class="table-responsive">
              <table class="table table-stripped mg-b-0">
                <tbody>
                  <tr v-for="provider in providers" :key="provider.id">
                    <td class="d-flex justify-content-between fs-12">
                      <div>
                        {{ provider.provider_name }}
                      </div>
                      <div>{{ provider.priority }}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
              ​
            </div>
            <h6 class="fs-15 my-2" style="font-weight:600;">
              Edit Service Provider Priority below:
            </h6>
            <div class="form-row mt-2">
              <div class="col-md-9 pr-2">
                <div class="input-item">
                  <div class="input-wrap">
                    <label class="input-item-label">
                      Service Provider
                      <span class="text-red">*</span>
                    </label>
                    <v-select
                      :options="providers"
                      transition=""
                      label="provider_name"
                      class
                      placeholder="Select Service Provider"
                      @blur="$v.service_provider_label.$touch()"
                      v-model="$v.service_provider_label.$model"
                    ></v-select>
                    <template v-if="$v.service_provider_label.$dirty">
                      <small
                        class="error"
                        v-if="!$v.service_provider_label.required"
                        >Service Provider Name is required</small
                      >
                    </template>
                  </div>
                </div>
              </div>

              <div class="col-md-3 pl-2">
                <div class="input-item">
                  <div class="input-wrap">
                    <label class="input-item-label">
                      Priority
                      <span class="text-red">*</span>
                    </label>
                    <v-select
                      :options="providers"
                      label="priority"
                      class
                      transition=""
                      placeholder="Select Priority"
                      @blur="$v.service_provider_priority.$touch()"
                      v-model="$v.service_provider_priority.$model"
                    ></v-select>
                    <template v-if="$v.service_provider_priority.$dirty">
                      <small
                        class="error"
                        v-if="!$v.service_provider_priority.required"
                        >Priority is required</small
                      >
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- action button -->
          <div class="modal-footer border-0">
            <button
              id="editPriority"
              class="btn btn-success rounded-md"
              @click="editPriority"
            >
              <template v-if="editing === false">Submit</template>
              <template v-if="editing === true">
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span
                >Submiting...
              </template>
            </button>
            <button
              class="btn btn-dark rounded-md"
              data-dismiss="modal"
              @click="clearState"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Vue from "vue";

Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    editing: {
      type: Boolean,
      default: false,
    },
    providers: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      service_provider_label: "",
      service_provider_priority: "",
    };
  },

  validations: {
    service_provider_label: { required },
    service_provider_priority: { required },
  },

  methods: {
    editPriority() {
      let PriorityData = {
        priority: this.service_provider_priority.priority,
      };
      let data = {
        service_id: this.service_provider_label.service_id,
        provider_id: this.service_provider_label.provider_id,
        data: PriorityData,
      };
      this.$emit("editPriority", data);
    },
    clearState() {
      this.service_provider_label = "";
      this.service_provider_priority = "";
    },
    onClickOutside() {
      this.clearState();
    },
  },

  computed: {
    getPriority() {
      console.log(
        "edit",
        this.$store.state.serviceProviders.serviceProviderParameters
      );
      return this.$store.state.serviceProviders.serviceProviderParameters;
    },
  },
};
</script>

<style scoped>
.table td {
  padding: 6px 15px;
}
</style>
