<template>
  <div
    class="modal fade"
    :id="id"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-between align-items-center">
          <div>
            <h5 class="modal-title fs-17 font-weight-bold" id="exampleModalCenterTitle">{{ title }}</h5>
          </div>
          <button
            type="button"
            class="close"
            id="close__modal"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 d-flex justify-content-center align-items-center flex-column">
              <img src="../../../public/assets/img/warning-blue.svg" height="100" width="100" alt />
              <h6 class="text-muted fs-16 mt-3 text-center">Are you sure you want to do this?</h6>
            </div>
          </div>
        </div>

        <!-- action button -->
        <div class="modal-footer border-0 justify-content-center">
          <button class="btn btn-primary rounded-md" @click="approve" :disabled="approving">
            <template v-if="approving === false">{{action}}</template>
            <template v-if="approving === true">
              <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>Approving...
            </template>
          </button>

          <button class="btn btn-dark rounded-md" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String
    },
    title: {
      type: String
    },
    action: {
      type: String
    },
    approving: {
      type: Boolean
    }
  },
  methods: {
    approve() {
      this.$emit("approve");
    }
  }
};
</script>
