<template>
  <div>
    <img
      @click="openUpdateParamerModal"
      class="pointer"
      src="/assets/img/edit.png"
      alt="update tax"
      width="25px"
    />
    <AddProviderParametersModal
      :id="`upd${parameter.id}`"
      :closeModal="closeModal"
      :provider="provider"
      :parameter="parameter"
      :refetch="refetch"
    />
  </div>
</template>

<script>
import AddProviderParametersModal from "../../modals/AddProviderParametersModal/AddProviderParametersModal";

export default {
  name: "UpdateProviderParametersButton",
  props: ["provider", "parameter", "refetch"],
  methods: {
    openUpdateParamerModal() {
      $(`#upd${this.parameter.id}`).modal("show");
    },
    closeModal() {
      $(`#upd${this.parameter.id}`).modal("hide");
    },
  },
  components: {
    AddProviderParametersModal,
  },
};
</script>
