<template>
  <SideModal :show="show" :toggle="toggle" :title="`Chargeback Information`"
    :subTitle="`Details about customer's chargeback`">
    <div class="row mt-2 pt-4">
      <!-- <div class="col-12 mb-4">
        <button type="submit" class="btn btn-lg btn-primary mr-4">
          Refund
        </button>
        <button type="submit" class="btn btn-lg btn-outline-primary text-dark">
          Retriger Notification
        </button>
      </div> -->
      <div class="col-6">
        <p>
          <span class="text-muted">AMOUNT</span> <br />
          <span class="text-capitalize h3">
            &#8358;{{ formatNumber(singleTransactionObj.Transaction.amount) || "0" }}
          </span>
        </p>
      </div>
      <div class="col-6">

      </div>
      <br />
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">CHARGEBACK ID</span> <br />
          <span class="text-capitalize h5">
            {{ singleTransactionObj.reference | moment(" MM-DD-YYYY h:mm a") }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="badge badge-app text-capitalize" :class="singleTransactionObj.status == 'ACCEPTED' ? 'badge-success' : 'badge-danger'
    ">
            {{ singleTransactionObj.status }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">rrn</span> <br />
          <span class="text-capitalize h5">
            {{ singleTransactionObj.data.data.rrn }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">transaction reference</span>
          <br />
          <span class="text-capitalize h5">{{
            titleCase(singleTransactionObj.transaction_reference)
            }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">created At</span> <br />
          <span class="text-capitalize h5">
            {{ singleTransactionObj.createdAt | moment(" MM-DD-YYYY h:mm a") }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">updated At</span> <br />
          <span class="text-capitalize h5">
            {{ singleTransactionObj.updatedAt | moment(" MM-DD-YYYY h:mm a") }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">Due At</span> <br />
          <span class="text-capitalize h5">
            {{ singleTransactionObj.due_at | moment(" MM-DD-YYYY h:mm a") }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">reason</span> <br />
          <span class="text-capitalize h5">
            {{ singleTransactionObj.reason_code }} - <span v-if="singleTransactionObj.reason_code === '13.6'">Credit Not
              Processed</span> <span v-else>Merchandise/Services Not Received</span>
          </span>
        </p>
      </div>
    </div>
  </SideModal>
</template>

<script>
import { notifications } from "../../../../public/assets/mixins/notifications";
import SideModal from "../../../components/utils/Modal/SideModal.vue";
import { formatNumber, titleCase } from "../../../components/utils/helpers";

export default {
  mixins: [notifications],
  name: "PaymentChargebackDetailModal",
  data() {
    return {};
  },
  props: ["toggle", "show", "singleTransactionObj"],
  components: {
    SideModal,
  },
  methods: {
    formatNumber,
    titleCase,
    handleCloseModal() {
      this.toggle();
    },
  },
};
</script>

<style scoped>
.d-flex {
  display: flex;
  justify-content: flex-end;
}
</style>
