<template>
  <div id="editAccountService" class="modal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-content-demo">
        <div class="modal-body">
          <button
            type="button"
            class="close pos-absolute t-20 r-30 tx-26"
            data-dismiss="modal"
            aria-label="Close"
            id="close-editAccountService"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title mg-b-5">Update Account Services</h5>
          <p>Let's get you all setup editing your app service(s).</p>
          <div class="b-b"></div>
          <div class="gaps-2x"></div>
          <form @submit.prevent="editApplication">
            <!-- CLient ID -->
            <div class="form-group">
              <label class="input-item-label">Client ID</label>
              <input
                type="text"
                disabled
                v-model="modalData.client_id"
                class="form-control input-bordered"
                :placeholder="modalData.client_id"
              />
            </div>
            <div v-for="(service, key) in serviceCategories" :key="key">
              <SubService
                :service="service"
                :handleSelectService="handleSelectService"
                :activeServices="activeServices"
              />
            </div>
            <div class="gaps-2x"></div>
            <button
              type="submit"
              class="btn btn-lg btn-primary"
              :disabled="isProcessing == true"
            >
              <span v-if="isProcessing">...editing account services</span>
              <span v-else>Update account Service(s)</span>
            </button>
            <button
              type="button"
              class="btn btn-lg btn-light ml-2"
              data-dismiss="modal"
            >
              Close
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nprogress from "nprogress";
import { notifications } from "../../../../../public/assets/mixins/notifications";
import SubService from "./SubService";

export default {
  mixins: [notifications],
  props: ["modalData", "serviceCategories", "activeService"],
  data() {
    return {
      isProcessing: false,
      activeServices: [],
    };
  },
  components: { SubService },
  computed: {
    userServices() {
      return this.$store.state.metaData.services;
    },
    metaData() {
      return this.$store.state.metaData;
    },
  },
  methods: {
    async editApplication() {
      this.isProcessing = true;
      try {
        let response = await this.$store.dispatch(
          "services/updateServiceCategoriesWithServices",
          {
            clientid: this.modalData.client_id,
            payload: {
              service: this.activeServices,
              service_category: [],
            },
          }
        );
        this.isProcessing = true;
        this.showSuccessNotification(response.message);
        let self = this;
        setTimeout(() => {
          self.closeModalSuccess();
          self.isProcessing = false;
        }, 2500);
      } catch (error) {
        this.isProcessing = false;
        console.log("[error] ->", error);
        this.showErrorLoginNotification(error.message);
      }
    },
    handleSelectService(service, single = true, add = true) {
      if (single) {
        if (this.activeServices.includes(service)) {
          let tempService = this.activeServices.filter(
            (data) => data !== service
          );
          this.activeServices = tempService;
        } else {
          this.activeServices = [...this.activeServices, service];
        }
      } else {
        if (add) {
          this.activeServices = [
            ...new Set([
              ...this.activeServices,
              ...service.apiServices.map((dt) => dt.id),
            ]),
          ];
        } else {
          this.activeServices = this.activeServices.filter(
            (el) => !service.apiServices.map((dt) => dt.id).includes(el)
          );
        }
      }
    },
    closeModalSuccess() {
      this.$emit("refetchApplication");
      let closeButton = document.getElementById("close-editAccountService");
      closeButton.click();
    },
  },
  watch:{
   activeService: function(newVal) { 
      this.activeServices = newVal.map((dt) => dt.id)
    } 
  },
  async mounted() {},
};
</script>
