<template>
	<div>
		<div v-if="content.length > 15" class="pointer" @click="openViewMore">
			{{ truncate(content, 15) }}
			<span class="text-primary">
				view
			</span>
		</div>
		<span v-else>{{ content }}</span>

		<Modal :id="'viewmore'" :title="title" :toggle="closeModal">
			<span class="text-center text-break">{{ content }}</span>
		</Modal>
	</div>
</template>

<script>
import Modal from "../Modal/Modal";
import { truncate } from "../helpers";

export default {
	name: "ViewMore",
	props: ["title", "content"],
	methods: {
		truncate,
		openViewMore() {
			$("#viewmore").modal("show");
		},
		closeModal() {
			$("#viewmore").modal("hide");
		},
	},
	components: {
		Modal,
	},
};
</script>
