<template>
  <div :id="modalId" class="modal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-content-demo">
        <div class="modal-body">
          <button
            type="button"
            class="close pos-absolute t-20 r-30 tx-26"
            data-dismiss="modal"
            aria-label="Close"
            id="close-modal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
					<h5 class="modal-title mg-b-5">Confirm Action</h5>
					<slot name="placeholder-content"></slot>
					<slot name="placeholder-button"></slot>
          <div class="b-b"></div>
          <div class="gaps-2x"></div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nprogress from "nprogress"
import { required } from 'vuelidate/lib/validators';
import { notifications } from '../../../public/assets/mixins/notifications';

export default {
  mixins: [notifications],
  props: {
    modalData: {
      type: Object
		},
		modalId: {
			type: String
		},
  },
  data() {
    return {
      isProcessing: false
    }
  },
  methods: {
    closeModal() {
      let closeButton = document.getElementById('close-modal')
      closeButton.click()
    }
  }
}
</script>


