<template>
  <div>
    <div
      v-for="notification in notifications"
      :key="notification.name"
      id="example-1"
    >
      <div class="my-4 border-bottom">
        <label
          class="switch"
          @click="
            updateNotificationSettings({
              label: notification.label,
              value: !notification.value,
            })
          "
        >
          <input type="checkbox" :checked="notification.value" />
          <span class="slider round"></span>
        </label>
        <span class="ml-4">{{ notification.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Nprogress from "nprogress";
import { notifications } from "../../../../../../public/assets/mixins/notifications";
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../../../components/utils/TableWrapper/TableWrapper.vue";
import customer from "../../../../../services/customer";

export default {
  name: "Notifications",
  mixins: [componentData, notifications],
  data() {
    return {
      notifications: null,
      singleInviteObj: null,
      showSingleInviteModal: false,
      emptyTitle: "No Notification Found",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
    };
  },
  components: {
    TableWrapper,
  },
  methods: {
    async fetchNotifications() {
      const params = { reference: this.clientid };
      const response = await this.$store.dispatch("customer/getSettingsInfo", {
        params,
      });
      const tempData = response.data.wallet.settings[0];
      const newData = [
        {
          name: "Would you like to use OTP on wallet creation?",
          value: tempData.wallet_creation_otp,
          label: "wallet_creation_otp",
        },
        {
          name:
            "Would you like to turn on/off the option to receive email notification for transactions?",
          value: tempData.transfer_otp,
          label: "transfer_otp",
        },
      ];
      this.notifications = newData;
    },
    async updateNotificationSettings(params) {
      Nprogress.start();
      
      try {
        let { data } = await customer.UpdateNotifications({
          ...params,
          id: this.clientid,
        });
        this.showSuccessNotification(data.message);
        Nprogress.done();
        // return data;
      } catch (error) {
        console.error(error);
        Nprogress.done();
        throw Error(error.response.data.message);
      }
    },
  },

  props: ["clientid"],
  created() {
    this.fetchNotifications();
  },
};
</script>

<style scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 30px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 32px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
