<template>
  <div class="col-12">
    <div class="dashboard-briefs mb-4">
      <div class="dashboard-brief rounded py-4">
        <h2 class="call-count">
          {{ toCurrency(totalApiCallPrice) || "0" }}
        </h2>
        <p class="call-name mb-0">TOTAL CALL VALUE</p>
      </div>
      <div class="dashboard-brief rounded py-4">
        <h2 class="call-count">
          {{ apiCallCount || "0" }}
        </h2>
        <p class="call-name mb-0">NUMBER OF CALLS</p>
      </div>
      <div class="dashboard-brief rounded py-4">
        <h2 class="call-count">
          {{ serviceCount || "0" }}
        </h2>
        <p class="call-name mb-0">NUMBER OF SERVICES</p>
      </div>
    </div>
    <div class="row">
      
      <div class="col-12">
        <div class="dashboard-brief-chart p-4 ">
          <h2 class="call-count text-left">
            Log Statistics
          </h2>
          <ProviderOverviewLogStat />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  toCurrency,
  formatNumber,
} from "../../../../../components/utils/helpers";
import serviceProviders from "../../../../../services/serviceProviders";
import ProviderOverviewLogStat from "../../../../../components/charts/ProviderOverviewLogStat.vue";
export default {
  name: "Overview",
  props: ["providerOverview"],
  data() {
    return {
      loading: false,
      apiCallCount: 0,
      totalApiCallPrice: 0,
      serviceCount: 0,
    };
  },
  components: { ProviderOverviewLogStat },
  methods: {
    toCurrency,
    formatNumber,
    async fecthProviderStats() {
      try {
        this.loading = true;
        const response = await serviceProviders.GetProviderApiCallCount({
          id: this.$route.params.id,
        });
        const response2 = await serviceProviders.GetProviderTotalApiCallPrice({
          id: this.$route.params.id,
        });
        const response3 = await serviceProviders.GetProviderServiceCount({
          id: this.$route.params.id,
        });
        this.apiCallCount = response.data.data.totalRows;
        this.totalApiCallPrice = response2.data.data.total_price;
        this.serviceCount = response3.data.data.totalRows;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.errorMsg = error.response.data.message;
      }
    },
  },
  mounted() {
    this.fecthProviderStats();
  },
};
</script>

<style scoped>
.dashboard-briefs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  /* grid-template-rows: 170px; */
}
.dashboard-briefs-sm {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  row-gap: 5px;
  /* grid-template-rows: 170px; */
}
.dashboard-brief {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}
.dashboard-brief-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}
/* .dashboard-brief:not(:last-of-type) {
  border-right: 1px solid #cccbcb;
} */

.dashboard-lead {
  color: white;
}
.side-border {
  border-left: 3px dotted white;
}

.dashboard-element {
  padding: 20px;
  color: inherit;
}
.call-count {
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  color: #071b2b;
  padding-top: 8px;
  margin-bottom: 20px;
}
.call-name {
  font-size: 15px;
  color: #081b2a;
  text-transform: uppercase;
}
</style>
