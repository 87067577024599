<template>
  <div
    class="modal fade"
    :class="cls"
    :id="id"
    tabindex="-1"
    role="dialog"
    data-backdrop="static"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        
        <div class="text-center pos-relative modal-header">
          <h4 class="themed-text m-0 font-weight-bold">
            {{ title }}
          </h4>
          <button class="close" @click="toggle" :disabled="loading">
            <img src="/assets/img/close.png" alt="" />
          </button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: ["id", "title", "toggle", "loading", "cls"],
};
</script>

<style scoped>
.close {
  position: absolute;
  right: 35px;
  top: 18px;
}
.close img {
  height: 20px;
}
</style>
