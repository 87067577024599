<template>
  <div class="col-md-5">
    <form @submit.prevent="updateAppId">
      <CustomInput
        :name="'appBundleId'"
        :label="'Bundle ID'"
        :placeholder="'Enter Bundle ID'"
        v-model="appBundleId"
        :handleError="()=>{}"
      />
       <Spinner v-if="loading" />
      <button v-else class="btn btn-primary" :disabled="!appBundleId">Update App Bundle ID</button>
    </form>
  </div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import { notifications } from "../../../../../../public/assets/mixins/notifications";


export default {
  name: "BundleID",
  mixins: [componentData,notifications],
  data() {
    return {
      appBundleId:'',
      loading: false,
      errorMsg: "",
    };
  },
  methods: {
    async updateAppId(e) {
      this.loading = true;
      const params = { id: this.clientid };
      const response = await this.$store.dispatch(
        "payment/updateAppBundleId",
        {
          params,
          payload: {
              appBundleId: this.appBundleId,
              appstoreUrl: "",
              playstoreUrl: ""
          }
        }

      );
      this.loading = false;
      if (response.status === "Success") {
        this.showSuccessNotification(response.message)
      } else {
         this.showErrorLoginNotification('Unable to update App Bundle ID');
      }
    },
  },

  props: ["clientid"],
  created() {
    // this.fetchWebHooks();
  },
};
</script>
