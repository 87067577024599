<template>
    <div id="addCredit" class="modal">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modal-content-demo">
                <div class="modal-body">
                    <button
                        type="button"
                        class="close pos-absolute t-20 r-30 tx-26"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="close-addCredit"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h5 class="modal-title mg-b-5">Add Credit</h5>
                    <p>Add test credit for a customer.</p>
                    <div class="b-b"></div>
                    <div class="gaps-2x"></div>
                    <form @submit.prevent="addCredit">
                        <!-- CLient ID -->
                        <div class="form-group">
                            <label class="input-item-label">Client ID</label>
                            <input
                                type="text"
                                disabled
                                class="form-control input-bordered"
                                :placeholder="modalValue.client_id"
                            />
                        </div>
                        <!-- Credit amount -->
                        <div class="form-group">
                            <label class="input-item-label"
                                >Credit amount</label
                            >
                            <input
                                type="number"
                                class="form-control input-bordered"
                                placeholder
                                v-model="creditAmount"
                                step="any"
                            />
                        </div>

                        <div class="gaps-2x"></div>
                        <button
                            type="submit"
                            class="btn btn-lg btn-primary"
                            :disabled="isProcessing == true"
                        >
                            <span v-if="isProcessing">..adding credit</span>
                            <span v-else>Add credit</span>
                        </button>
                        <button
                            type="button"
                            class="btn btn-lg btn-light ml-2"
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Nprogress from "nprogress";
import { required } from "vuelidate/lib/validators";
import { notifications } from "../../../public/assets/mixins/notifications";

export default {
    mixins: [notifications],
    props: {
        modalValue: {
            type: Object,
        },
    },
    data() {
        return {
            creditAmount: "",
            isProcessing: false,
        };
    },
    methods: {
        clearInputFields() {
            this.creditAmount = "";
        },
        formatAddCreditData() {
            let requestData = new FormData();
            requestData.append("credit_amount", this.creditAmount);
            requestData.append("client_id", this.modalValue.client_id);
            return requestData;
        },
        closeModalSuccess() {
            let closeButton = document.getElementById("close-addCredit");
            closeButton.click();

            this.$emit("refetchCustomer");
        },
        async addCredit() {
            this.isProcessing = true;
            try {
                let requestPayload = {
                    payload: this.formatAddCreditData(),
                    clientid: this.modalValue.client_id,
                };
                let application = await this.$store.dispatch(
                    "customer/addCredit",
                    requestPayload
                );
                this.showSuccessNotification(application.message);
                let self = this;
                setTimeout(() => {
                    self.closeModalSuccess();
                    self.isProcessing = true;
                    self.clearInputFields();
                }, 2500);
                Nprogress.done();
            } catch (error) {
                Nprogress.done();
                this.isProcessing = true;
                console.log("[error] ->", error);
                this.showErrorLoginNotification(error.message);
            }
        },
    },
};
</script>
