<template>
  <div class="col-12 pt-4">
    <div class="SectionItem no-b-b mb-3">
      <div>
        <p class="title m-0">Credit Log Listing</p>
        <p class="text-left description">
          View all transactions within the system
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="dashboard-briefs">
          <div class="dashboard-brief rounded py-4">
            <h2 class="call-count">
              &#8358;{{ formatNumber(overview.total_credits || "0") }}
            </h2>
            <p class="call-name">Total Balance</p>
          </div>
          <div class="dashboard-brief  rounded py-4">
            <h2 class="call-count">
              &#8358;{{ formatNumber(overview.amount_remaining) || "0" }}
            </h2>
            <p class="call-name">Available Balance</p>
          </div>
          <div class="dashboard-brief  rounded py-4">
            <h2 class="call-count">
              &#8358;{{ formatNumber(overview.total_amount_charged) || "0" }}
            </h2>
            <p class="call-name">Total Amount</p>
          </div>
          <div class="dashboard-brief rounded py-4">
            <h2 class="call-count">
              {{ overview.credit_count || "0" }}
            </h2>
            <p class="call-name">Credit Count</p>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="dashboard-brief-chart p-4 ">
          <h2 class="call-count text-left">
            Log Value
          </h2>
          <apexchart
            height="400"
            width="300"
            type="donut"
            :options="chartOptions"
            :series="[
              parseInt(
                overview.amount_remaining - overview.total_amount_charged
              ),
              parseInt(overview.amount_remaining),
            ]"
          ></apexchart>
        </div>
        <div class="dashboard-brief-chart p-4 d-none">
          <h2 class="call-count text-left">
            Funding Type
          </h2>
          <apexchart
            height="400"
            type="donut"
            :options="{}"
            :series="[44, 55]"
          ></apexchart>
        </div>
      </div>
      <div class="col-12">
        <div class="dashboard-brief-chart p-4 ">
          <h2 class="call-count text-left">
            Log Statistics
          </h2>
          <CreditLogStat />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  toCurrency,
  formatNumber,
  graphDateFormatter,
} from "../../components/utils/helpers";
import CreditLogStat from "../../components/charts/CreditLogStat.vue";
export default {
  name: "CreditLogsOverview",
  data() {
    return {
      overview: null,
      loading: true,
      chartOptions: {
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
              },
            },
          },
        },
        labels: ["Amount Used", "Amount Remaining"],
      },
    };
  },
  components: { CreditLogStat },
  methods: {
    toCurrency,
    formatNumber,
    graphDateFormatter,
    async fetchCreditLogHistory(params = {}) {
      const response = await this.$store.dispatch(
        "logs/getCreditHistoryOverview",
        {
          params,
        }
      );
      this.overview = response.data;
      this.loading = false;
    },
  },
  created() {
    this.fetchCreditLogHistory();
  },
};
</script>

<style scoped>
.dashboard-briefs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  /* grid-template-rows: 170px; */
}
.dashboard-briefs-sm {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
  row-gap: 5px;
  /* grid-template-rows: 170px; */
}
.dashboard-brief {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}
.dashboard-brief-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}
/* .dashboard-brief:not(:last-of-type) {
  border-right: 1px solid #cccbcb;
} */

.dashboard-lead {
  color: white;
}
.side-border {
  border-left: 3px dotted white;
}

.dashboard-element {
  padding: 20px;
  color: inherit;
}
.call-count {
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  color: #071b2b;
  padding-top: 8px;
  margin-bottom: 20px;
}
.call-name {
  font-size: 15px;
  color: #081b2a;
  text-transform: uppercase;
}
</style>
