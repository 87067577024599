<template>
    <div class="p-md-4 p-3">
        <div class="SectionItem no-b-b mb-3">
            <div>
                <p class="title m-0">Personal</p>
                <p class="text-left description">
                    View reports from all personal verification checks
                </p>
            </div>
        </div>
        <div class="px-3">
            <FilterWrapper>
                <template slot="filterForm">
                    <PerosnalFilter :loading="loading" :rLoading="rLoading" :submitFilter="refetch"
                        :clearFilter="() => clearFilter('smartRef')" />
                </template>
            </FilterWrapper>
        </div>

        <TableWrapper :loading="loading" :errorMsg="errorMsg" :refetch="fetchSmartId" :isEmpty="isEmpty"
            :emptyTitle="emptyTitle">
            <div class="w-100 py-3" slot="content">
                <v-server-table ref="smartRef" class="v-table row-pointer" :data="customers" :columns="columns"
                    :options="options" @loaded="onLoaded" @row-click="handleClick">
                    <template slot="name" slot-scope="{ row }">
                    {{ row.first_name }}
                    {{ row.last_name }}
                    </template>
                    <template slot="created_at" slot-scope="{ row }">
                    {{ timeDateFormat(row.created_at).date }}
                    {{ timeDateFormat(row.created_at).time }}
                    </template>
                </v-server-table>
            </div>
        </TableWrapper>
    </div>
</template>

<script>
import { componentData } from "../../../public/assets/mixins/componentData";
import PerosnalFilter from "./filters/PersonalFilter.vue";
import TableWrapper from "../../components/utils/TableWrapper/TableWrapper.vue";

const tableHeadings = {
    name: "Name",
    phone_number: "Phone Number",
    smart_id: "Smart Id",
    service_count: "No of Service",
    created_at: "Date Created",
};

export default {
    name: "PersonalSmartID",
    mixins: [componentData],
    data() {
        return {
            emptyTitle: "No Verification Found",
            loading: false,
            errorMsg: "",
            customers: [],
            ...this.tableOptions(tableHeadings, 25),
        };
    },
    components: {
        PerosnalFilter,
        TableWrapper,
    },
    methods: {
        handleClick({ row }) {
              this.$router.push(`/personal-smart-id/${row.smart_id}`);
        },
        refetch(filters) {
            this.handleRefetch(filters, this.fetchSmartId, "smartRef");
        },
        async fetchSmartId() {
            this.options.requestFunction = async (data) => {
                const params = { ...data, ...this.filters };
                const response = await this.$store.dispatch(
                    "logs/getSmartIDCustomers",
                    {
                        params,
                    }
                );
                const newData = response.data.data;
                const count = response.data.total;
                return { ...response, count, total: count, data: newData };
            };
        },
    },
    created() {
        this.fetchSmartId();
    },
    mounted() {
        this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
    },
};
</script>