<template>
	<div class="az-content-body">
		<div class="row">
			<div class="col-lg-12">
				<div class="SectionItem no-b-b">
					<div class="SectionHeader">
						<span class="title">Transactions</span>
						<div class="description">
							View all the transactions within the system
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-12 card pt-3">
				<v-client-table
					ref="serverTableRef"
					name="question-table"
					class="v-table"
					:data="customers"
					:columns="columns"
					:options="options"
				></v-client-table>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				customers: [
					{
						id: 1,
						account_type: "customer",
						app_id: "V312908",
						create_at: "1",
						fullname: "Abiodun Emmanuel",
						email: "Ayemitiboabiodun02@gmail.com",
						phone_number: "08088445544",
					},
					{
						id: 2,
						account_type: "customer",
						app_id: "T334490",
						create_at: "1",
						fullname: "Ayemitibo Emmanuel",
						email: "aye02@gmail.com",
						phone_number: "08088445544",
					},

					{
						id: 3,
						account_type: "customer",
						app_id: "P234544",
						create_at: "1",
						fullname: "Tunmise Adenuga",
						email: "tunmise@gmail.com",
						phone_number: "09021743695",
					},
					{
						id: 4,
						account_type: "merchant",
						app_id: "k456342",
						create_at: "1",
						fullname: "Al-Ameen Ipaye",
						email: "al-ameen@gmail.com",
						phone_number: "09021743695",
					},
				],
				columns: ["id", "fullname", "email", "phone_number", "app_id"],
				options: {
					sortable: [],
					perPage: 5,
					pagination: {
						chunk: 3,
					},
					texts: {
						filter: "",
						filterPlaceholder: "Search..",
						noResults: "No matching records",
					},
					headings: {
						id: "S/N",
						fullname: "Full Name",
						email: "Email",
						phonenumber: "Phone Number",
						create_at: "Actions",
					},
				},
			};
		},
	};
</script>
