var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"az-header az-header-dashboard-nine"},[_c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('div',{staticClass:"az-header-right"},[_c('span',{staticClass:"top-nav-partition"}),_vm._m(1),_vm._m(2),_c('div',{staticClass:"dropdown az-profile-menu"},[_vm._m(3),_c('div',{staticClass:"dropdown-menu"},[_vm._m(4),_c('div',{staticClass:"user-info"},[_vm._m(5),_c('div',{staticClass:"user-details"},[_c('span',{staticClass:"user-name",attrs:{"title":"Brian Wilson"}},[_vm._v(_vm._s(_vm.user.firstname)+" "+_vm._s(_vm.user.lastname))]),_c('span',{staticClass:"user-email",attrs:{"title":"brian.wilson@opay.com"}},[_vm._v(_vm._s(_vm.user.email))])])]),_c('router-link',{staticClass:"dropdown-item",attrs:{"to":"/account"}},[_c('i',{staticClass:"la la-user"}),_vm._v(" My Profile ")]),(false)?_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"la la-credit-card"}),_vm._v(" Financials ")]):_vm._e(),(false)?_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"la la-terminal"}),_vm._v(" API Docs ")]):_vm._e(),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":_vm.logout}},[_c('i',{staticClass:"la la-sign-out"}),_vm._v(" Sign Out ")])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"az-header-left"},[_c('a',{staticClass:"az-header-menu-icon",attrs:{"href":"","id":"azSidebarToggle"}},[_c('span')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"az-header-support"},[_c('a',{attrs:{"href":"#","title":"support"}},[_c('i',{staticClass:"la la-life-buoy"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"az-header-notification"},[_c('a',{staticClass:"new",attrs:{"href":"#","title":"notifications"}},[_c('i',{staticClass:"la la-bell"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"az-profile-link",attrs:{"href":""}},[_c('div',{staticClass:"az-img-user"},[_c('img',{attrs:{"src":"/assets/img/avatar1.svg","alt":""}})]),_c('em',{staticClass:"az-header-arrow fas fa-chevron-down"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"az-dropdown-header d-sm-none"},[_c('a',{staticClass:"az-header-arrow",attrs:{"href":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"user-avatar",attrs:{"src":"/assets/img/avatar1.svg","alt":""}})])
}]

export { render, staticRenderFns }