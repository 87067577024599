var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.title),expression:"title"}],staticClass:"col-12 pt-3 px-4"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.title)+" ("+_vm._s(_vm.len)+")")])]),_vm._m(0),(_vm.loading)?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"flex__center my-5 py-5"},[_c('Spinner',{attrs:{"size":'30px'}})],1)]):_vm._e(),_c('div',{staticClass:"col-12",class:_vm.tbClass},[(_vm.params.type != 'invoices')?_c('v-server-table',{ref:"transRef",staticClass:"v-table row-pointer",attrs:{"columns":_vm.columns,"options":_vm.options},on:{"loading":() => {
            _vm.setLoading(true);
            _vm.$emit('loading', { status: true });
          },"loaded":($event) => {
            _vm.setLoading(false);
            _vm.$emit('loading', { status: false, value: $event });
          },"error":($event) => {
            _vm.setError($event);
            _vm.$emit('error', false);
          }},scopedSlots:_vm._u([{key:"metadata.account_number",fn:function({ row }){return [_vm._v(" "+_vm._s(row.metadata && row.metadata.account_number ? row.metadata.account_number : "-------------------")+" ")]}},{key:"amount",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm.toCurrency(row.amount, row.currency))+" ")]}},{key:"source",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm.titleCase( row.source === "merchant_transfer" ? "Bank_transfer" : row.source ))+" ")]}},{key:"metadata.source_account.account_name",fn:function({ row }){return [_vm._v(" "+_vm._s(row.metadata && row.metadata.source_account ? row.metadata.source_account.account_name : "---------")+" ")]}},{key:"action",fn:function({ row }){return [_c('span',{class:_vm.statusColor(row.action)},[_vm._v(" "+_vm._s(_vm.titleCase(row.action))+" ")])]}},{key:"status",fn:function({ row }){return [_c('span',{class:_vm.statusColor(row.status)},[_vm._v(" "+_vm._s(_vm.titleCase(row.status))+" ")])]}},{key:"createdAt",fn:function({ row }){return [_c('span',[_vm._v(" "+_vm._s(_vm.timeDateFormat(row.createdAt).date + " " + _vm.timeDateFormat(row.createdAt).time)+" ")])]}}],null,false,3637757793)}):_c('v-server-table',{ref:"transRef",staticClass:"v-table row-pointer",attrs:{"columns":_vm.columns,"options":_vm.options},on:{"loading":() => {
            _vm.setLoading(true);
            _vm.$emit('loading', { status: true });
          },"loaded":($event) => {
            _vm.setLoading(false);
            _vm.$emit('loading', { status: false, value: $event });
          },"error":($event) => {
            _vm.setError($event);
            _vm.$emit('error', false);
          }},scopedSlots:_vm._u([{key:"amount",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm.toCurrency(row.amount, row.currency))+" ")]}},{key:"status",fn:function({ row }){return [_c('span',{class:_vm.statusColor(row.status)},[_vm._v(" "+_vm._s(_vm.titleCase(row.status))+" ")])]}},{key:"amount_paid",fn:function({ row }){return [_c('span',[_vm._v(" "+_vm._s(_vm.toCurrency( row.partial.is_partial ? row.partial.installments.reduce((a, b) => a + b, 0) : row.status == "paid" ? row.amount : 0, row.currency ))+" ")])]}},{key:"outstanding",fn:function({ row }){return [_c('span',[_vm._v(" "+_vm._s(_vm.toCurrency( row.partial.is_partial ? row.amount - row.partial.installments.reduce((a, b) => a + b, 0) : row.status != "paid" ? row.amount : 0, row.currency ))+" ")])]}},{key:"issued_date",fn:function({ row }){return [_c('span',[_vm._v(" "+_vm._s(row.issued_date ? _vm.timeDateFormat(row.issued_date).date + " " + _vm.timeDateFormat(row.issued_date).time : "Not Available")+" ")])]}},{key:"due_date",fn:function({ row }){return [_c('span',[_vm._v(" "+_vm._s(row.due_date ? _vm.timeDateFormat(row.due_date).date + " " + _vm.timeDateFormat(row.due_date).time : "Not Available")+" ")])]}}])})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 mt-3"},[_c('div',{staticClass:"px-3"})])
}]

export { render, staticRenderFns }