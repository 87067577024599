<template>
  <Modal
    :id="id"
    :title="provider ? `Update ${provider.provider_name}` : 'Add New Provider'"
    :toggle="handleCloseModal"
    :loading="loading"
  >
    <SuccessState
      v-if="successMsg"
      :message="`Provider ${provider ? 'Updated' : 'Added'} Successfully`"
      :onSuccess="handleRefetch"
    />

    <form v-else @submit.prevent="handleSubmit">
      <CustomInput
        :name="'provider_name'"
        v-model="provider_name"
        :label="'Provider Name'"
        :placeholder="'Enter Provider Name'"
        :required="true"
        :error="formErrors.provider_name"
        :handleError="handleError"
      />

      <FormButtons :loading="loading" :handleCloseModal="handleCloseModal" />
    </form>
  </Modal>
</template>

<script>
import { notifications } from "../../../../../public/assets/mixins/notifications";
import Modal from "../../../../components/utils/Modal/Modal";
import FormButtons from "../../../../components/utils/FormButtons/FormButtons";
import CustomInput from "../../../../components/utils/CustomInput/CustomInput";
import SuccessState from "../../../../components/utils/ResponseState/SuccessState";
import { formValidator } from "../../../../components/utils/FormValidator/FormValidator";
import Spinner from "../../../../components/utils/Spinner/Spinner";

const initialState = () => ({
  provider_name: "",
  loading: false,
  successMsg: false,
  formErrors: {},
});

export default {
  mixins: [notifications],
  name: "AddProviderModal",
  data() {
    return { ...initialState() };
  },
  props: ["id", "closeModal", "provider", "refetch"],
  components: {
    Modal,
    CustomInput,
    SuccessState,
    Spinner,
    FormButtons,
  },

  methods: {
    handleCloseModal() {
      Object.assign(this.$data, { ...initialState() });
      this.closeModal();
      this.setProviderData();
    },
    async handleRefetch() {
      this.handleCloseModal();
      this.refetch();
      this.setProviderData();
    },
    handleSubmit(e) {
      e.preventDefault();
      const { formErrors, isValid } = formValidator({
        provider_name: this.provider_name,
      });
      if (isValid)
        this.provider ? this.updateProvider() : this.createProvider();
      else this.formErrors = formErrors;
    },
    handleError(name) {
      this.formErrors = { ...this.formErrors, [name]: "" };
    },
    showError(msg) {
      this.loading = false;
      this.showErrorLoginNotification(msg);
    },
    async createProvider() {
      try {
        this.loading = true;
        const response = await this.$store.dispatch(
          "infrastructureProviders/createInfrastructureProviders",
          {
            provider_name: this.provider_name,
          }
        );
        if (response.status_code === 201) {
          this.loading = false;
          this.successMsg = true;
        } else this.showError(response?.message || "Error Adding Provider");
      } catch (error) {
        this.showError(error.message);
      }
    },

    async updateProvider() {
      try {
        this.loading = true;
        const response = await this.$store.dispatch(
          `infrastructureProviders/updateInfrastructureProviders`,
          {
            id: this.provider.id,
            payload: {
              provider_name: this.provider_name,
            },
          }
        );

        if (response.status_code === 201) {
          this.loading = false;
          this.successMsg = true;
        } else this.showError(response?.message || "Error Updating Provider");
      } catch (error) {
        this.showError(error.message);
      }
    },

    setProviderData() {
      if (this.provider) {
        this.provider_name = this.provider.provider_name;
      }
    },
  },
  computed: {
    userID() {
      return this.$store.state.account.profile.api_customer.user_id;
    },
  },
  mounted() {
    this.setProviderData();
  },
};
</script>
