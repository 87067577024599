<template>
  <div class="">
    <div class="mt-5" :class="tbClass">
      <div class="p-2 bg-light-blue mb-2">Company Contact Persons</div>
      <v-server-table
        ref="shareHoldersRef"
        class="v-table"
        :columns="columns"
        :options="options"
        @loading="setLoading(true)"
        @loaded="setLoading(false)"
        @error="setError"
      >
      </v-server-table>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../../../../../public/assets/mixins/componentData";
import "../../style.css";

const tableHeadings = {
  name: "Name",
  email: "Email",
  phone: "Phone",
};

export default {
  name: "ContactPersons",
  mixins: [componentData],
  props: ["details"],
  data() {
    return {
      loading: false,
      rLoading: false,
      showModal: false,
      modalData: null,
      errorMsg: "",
      size: 25,
      filters: {},
      ...this.tableOptions(tableHeadings, 20),
    };
  },
  components: {},
  computed: {
    tbClass() {
      return this.loading || this.errorMsg ? "hide__table" : "";
    },
  },
  methods: {
    setLoading(loading) {
      this.loading = loading;
      this.errorMsg = "";
    },
    setError(error) {
      this.errorMsg = error.message;
    },
    refetch(filters) {
      const newFilters = Object.fromEntries(
        Object.entries(filters).filter(([_, v]) => v != "" && v != null)
      );
      if (filters) this.filters = newFilters;
      this.getShareHolders();
      this.$refs.shareHoldersRef.refresh();
    },
    clearFilter() {
      this.filters = {};
      this.$refs.shareHoldersRef.refresh();
    },
    async getShareHolders() {
      this.options.requestFunction = async (data) => {
        try {
          const parsedData = JSON.parse(
            this.details.company_contact_persons
          ).map((dt) => ({
            name: dt.name,
            phone: dt.contacts.phone[0],
            email: dt.contacts.email[0],
          }));
          const resp = {
            total: parsedData.length,
            count: parsedData.length,
            data: parsedData,
            totalPages: length,
            currentPage: 1,
          };
          return { ...resp, count: resp.total };
        } catch (error) {
          console.log("transctionError", error);
        }
      };
    },
  },

  created() {
    this.getShareHolders();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
