<template>
	<div>
		<!-- <div v-if="walletInformation === null"></div> -->
		<div class="az-content-body">
			<div class="mb-4">
				<router-link to="/payment-merchants" class="mr-2 text-secondary"
					>Merchant Information</router-link
				>
				&lt;
				<router-link
					:to="`/payment-merchant/${clientid}`"
					class="mx-2 text-secondary"
					>Sub Account</router-link
				>
				&lt;
				<router-link to="" class="ml-2 text-primary">
					Sub Account Information</router-link
				>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="SectionItem no-b-b mb-3">
						<div class="SectionHeader">
							<span class="title" @click="$router.back()">
								<img
									src="/assets/img/icons/close.svg"
									alt="image"
									height="30"
								/>
								Sub Account Information</span
							>
						</div>
						<!-- <div class="content">
              <router-link
                to="/customers"
                class="btn btn-lg btn-outline-danger"
              >
                <i class="la la-arrow-left"></i> Back
              </router-link>
            </div> -->
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12 card p-4">
					<div class="">
						<div class="contact-details--card-row">
							<div class="contact-details--card-field">
								<span class="field-key">
									<div class="contact-details--truncated">
										PROVIDER ID
									</div>
								</span>
								<span class="field-value">
									<div class="contact-details--truncated">
										Null
										<!-- {{ walletInformation.CustomerId }} -->
									</div>
								</span>
							</div>

							<div class="contact-details--card-field">
								<span class="field-key">
									<div class="contact-details--truncated">
										EMAIL
									</div>
								</span>
								<span class="field-value">
									<div
										class="contact-details--truncated pointer"
										title="Click to copy"
									>
										Null
										<!-- {{ walletInformation.business ? "Business" : "Personal" }} -->
									</div>
								</span>
							</div>
							<div class="contact-details--card-field">
								<span class="field-key">
									<div class="contact-details--truncated">
										PHONE NUMBER
									</div>
								</span>
								<span class="field-value">
									<div
										class="contact-details--truncated pointer"
										title="Click to copy"
									>
										Null
										<!-- {{ walletInformation.Customer.first_name }}
                    {{ walletInformation.Customer.last_name }} -->
									</div>
								</span>
							</div>
							<div class="contact-details--card-field">
								<span class="field-key">
									<div class="contact-details--truncated">
										DATE CREATED
									</div>
								</span>
								<span class="field-value">
									<div
										class="contact-details--truncated pointer"
										title="Click to copy"
									>
										Null
										<!-- {{ walletInformation.Customer.email }} -->
									</div>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 my-4">
					<div class="dashboard-briefs">
						<div class="dashboard-brief rounded py-4">
							<h2 class="call-count">
								<!-- {{ walletInformation.currency }} -->NGN
								{{ formatNumber(0) || "0" }}
							</h2>
							<p class="call-name">
								<!-- {{ walletInformation.currency }} -->
								Total Amount
							</p>
						</div>
						<div class="dashboard-brief rounded py-4">
							<h2 class="call-count">
								<!-- {{ walletInformation.currency }} -->NGN
								{{ formatNumber(0) || "0" }}
							</h2>
							<p class="call-name">
								<!-- {{ walletInformation.currency }} -->
								Total Charges
							</p>
						</div>
						<div class="dashboard-brief-long  rounded p-4 ">
							<div class="row">
								<div class="col-sm-4">
									<div
										class="contact-details--card-field mb-4"
									>
										<span class="field-key">
											<div
												class="contact-details--truncated"
											>
												TRANSACTION ID
											</div>
										</span>
										<span class="field-value">
											<div
												class="contact-details--truncated pointer"
												title="Click to copy"
											>
												<!-- {{ walletInformation.account_name }} -->
												NULL
											</div>
										</span>
									</div>
									<div class="contact-details--card-field">
										<span class="field-key">
											<div
												class="contact-details--truncated"
											>
												PAYOUT NAME
											</div>
										</span>
										<span class="field-value">
											<div
												class="contact-details--truncated pointer"
												title="Click to copy"
											>
												NULL
												<!-- {{ walletInformation.Customer.first_name }}
                          {{ walletInformation.Customer.last_name }} -->
											</div>
										</span>
									</div>
								</div>
								<div class="col-sm-4">
									<div
										class="contact-details--card-field mb-4"
									>
										<span class="field-key">
											<div
												class="contact-details--truncated"
											>
												PAYOUT DATE
											</div>
										</span>
										<span class="field-value">
											<div
												class="contact-details--truncated"
											>
												NULL
												<!-- {{ walletInformation.bank.account_number }}
                          ({{ walletInformation.bank.bank_name }}) -->
											</div>
										</span>
									</div>
									<div
										class="contact-details--card-field mb-4"
									>
										<span class="field-key">
											<div
												class="contact-details--truncated"
											>
												PAYOUT ACCOUNT
											</div>
										</span>
										<span class="field-value">
											<div
												class="contact-details--truncated"
											>
												NULL
												<!-- {{ walletInformation.bank.account_number }}
                          ({{ walletInformation.bank.bank_name }}) -->
											</div>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="az-content-body">
			<TransactionDetailModal
				:show="showTransactionModal"
				:toggle="toggleTransactionModal"
				:singleTransactionObj="singleTransactionObj"
				:refetch="refetch"
			/>
			<div class="px-3">
				<FilterWrapper>
					<template slot="filterForm">
						<TransactionFilter
							:loading="loading"
							:rLoading="rLoading"
							:submitFilter="refetch"
							:clearFilter="() => clearFilter('transactionRef')"
						/>
					</template>
				</FilterWrapper>
			</div>
			<TableWrapper
				:loading="loading"
				:errorMsg="errorMsg"
				:refetch="fectchSingleWallet"
				:isEmpty="isEmpty"
				:emptyTitle="emptyTitle"
			>
				<div class="w-100 py-3" slot="content">
					<v-server-table
						ref="transactionRef"
						class="v-table row-pointer"
						:data="customers"
						:columns="columns"
						:options="options"
						@loading="setLoading(true)"
						@loaded="onLoaded"
						@row-click="handleClick"
					>
						<template slot="createdAt" slot-scope="{ row }">
							{{ row.createdAt | moment(" MM-DD-YYYY h:mm a") }}
						</template>
						<div slot="status" slot-scope="{ row }">
							<span
								class="badge badge-app"
								:class="
									row.status === 'successful'
										? 'badge-success'
										: 'badge-danger'
								"
							>
								{{ row.status }}
							</span>
						</div>
						<div slot="action" slot-scope="{ row }">
							<span class="badge badge-app">
								{{
									row.action === "credit" ? "Credit" : "Debit"
								}} </span
							><span
								><img
									v-if="row.action === 'credit'"
									src="/assets/img/icons/import.svg"
									alt=""
								/>
								<img
									v-else
									src="/assets/img/icons/export.svg"
									alt=""
								/>
							</span>
						</div>
					</v-server-table>
				</div>
			</TableWrapper>
		</div>
	</div>
</template>

<script>
import { componentData } from "../../../../public/assets/mixins/componentData";
import TransactionFilter from "../../../components/filters/TransactionFilter.vue";
import TableWrapper from "../../../components/utils/TableWrapper/TableWrapper.vue";
import TransactionDetailModal from "../../../views/Customers/modals/TransactionDetailModal.vue";
import NGNWallet from "./Tabs/NGNWallet.vue";
import WalletSuscription from "./Tabs/WalletSuscription.vue";
import { toCurrency, formatNumber } from "../../../components/utils/helpers";

const tableHeadings = {
	reference: "Reference",
	amount: "Amount",
	type: "Channel",
	action: "Type",
	status: "Status",
	createdAt: "Date Created",
};

export default {
	name: "SingleSubAccount",
	mixins: [componentData],
	props: {
		clientid: {
			type: String,
		},
	},
	data() {
		return {
			singleTransactionObj: null,
			showTransactionModal: false,
			emptyTitle: "No Transaction Found",
			showInviteModal: false,
			loading: false,
			errorMsg: "",
			customers: [],
			...this.tableOptions(tableHeadings, 25),
		};
	},
	methods: {
		formatNumber,
		toggleTransactionModal() {
			this.showTransactionModal = !this.showTransactionModal;
		},
		handleClick({ row }) {
			//   this.$router.push(`/customer/${row.client_id}`);
			this.singleTransactionObj = row;
			this.showTransactionModal = true;
		},
		async fectchSingleWallet() {
			this.options.requestFunction = async (data) => {
				const params = {
					...data,
					...this.filters,
					id: this.clientid,
					wallet: this.$route.params.subAccountId,
				};
				const response = await this.$store.dispatch(
					"customer/getPaymentMerchantTransactions",
					{
						params,
					}
				);

				const newData = response.data.data;
				const count = response.data.count;
				return { ...response, count, total: count, data: newData };
			};
		},
		refetch(filters) {
			let newFilters = { ...filters };
			if (newFilters.status) newFilters.status = newFilters.status.value;
			this.handleRefetch(
				newFilters,
				this.fectchSingleWallet,
				"transactionRef"
			);
		},
	},
	props: ["clientid"],
	created() {
		this.fectchSingleWallet();
	},
	mounted() {
		this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
	},
	components: {
		NGNWallet,
		WalletSuscription,
		TableWrapper,
		TransactionFilter,
		TransactionDetailModal,
	},
};
</script>
<style scoped>
.dashboard-briefs {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 10px;
	/* grid-template-rows: 170px; */
}
.dashboard-brief {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}
.dashboard-brief-long {
	grid-column: 3 / 5;
	/* display: flex; */
	/* flex-direction: column;
  justify-content: center;
  align-items: center; */
	box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}
.dashboard-brief-long .contact-details--card-field {
	width: 100% !important;
}

/* .dashboard-brief:not(:last-of-type) {
  border-right: 1px solid #cccbcb;
} */

.dashboard-lead {
	color: white;
}
.side-border {
	border-left: 3px dotted white;
}

.dashboard-element {
	padding: 20px;
	color: inherit;
}
.call-count {
	font-size: 22px;
	font-weight: 600;
	line-height: 1;
	color: #071b2b;
	padding-top: 8px;
	margin-bottom: 20px;
}
.call-name {
	font-size: 15px;
	color: #081b2a;
	text-transform: uppercase;
}
.flex-start {
	justify-content: flex-start;
}
.contact-details--card-field {
	width: 20% !important;
	/* margin-bottom: 10px; */
}
</style>
