import fundstransfer from "@/services/fundstransfer";
import { apiClient } from "@/services/api";

apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;

export default {
  namespaced: true,
  state: {
    businessInfo: {},
    etz_email: "",
  },
  mutations: {
    SET_BUSINESS_INFO(state, businessInfo) {
      state.businessInfo = businessInfo;
    },
    SET_ETZ_EMAIL(state, etz_email) {
      state.etz_email = etz_email;
    },
  },
  actions: {
    async getPendingRequests({ commit }, PendingRequests) {
      try {
        let result = await fundstransfer.getPendingRequests(PendingRequests);
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async getProcessingRequests({ commit }, ProcessingRequests) {
      try {
        let result = await fundstransfer.getProcessingRequests(
          ProcessingRequests
        );
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async getActivatedRequests({ commit }, ActivatedRequests) {
      try {
        let result = await fundstransfer.getActivatedRequests(
          ActivatedRequests
        );
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async getFTRequests({ commit }, FTRequests) {
      try {
        let result = await fundstransfer.getFTRequests(FTRequests);
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async getFTCustomers({ commit }, FTCustomers) {
      try {
        let result = await fundstransfer.getFTCustomers(FTCustomers);
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async getFTBusinessInfo({ commit }, FTBusinessInfo) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let result = await fundstransfer.getFTBusinessInfo(FTBusinessInfo);
        let bizInfo = result.data.result[0];
        commit("SET_BUSINESS_INFO", bizInfo);
        return result;
      } catch (error) {
        // throw Error(error.response.data.message);
        console.log(error);
      }
    },
    async getCustomerBusinessInfo({ commit }, CustomerBusinessInfo) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let result = await fundstransfer.getCustomerBusinessInfo(
          CustomerBusinessInfo
        );
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async getFundingSource({ commit }, fundingSource) {
      try {
        let result = await fundstransfer.getFundingSource(fundingSource);
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async getCustomerTransactions({ commit }, CustomerTransactions) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let result = await fundstransfer.getCustomerTransactions(
          CustomerTransactions
        );
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async getAllTransactions({ commit }, AllTransactions) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let result = await fundstransfer.getAllTransactions(AllTransactions);
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async getSuccessfulTransactions({ commit }, SuccessTransaction) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let result = await fundstransfer.getSuccessfulTransactions(
          SuccessTransaction
        );
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async getFailedTransactions({ commit }, FailedTransaction) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let result = await fundstransfer.getFailedTransactions(
          FailedTransaction
        );
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async getPendingTransactions({ commit }, PendingTransaction) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let result = await fundstransfer.getPendingTransactions(
          PendingTransaction
        );
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async getSingleTransaction({ commit }, SingleTransaction) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let result = await fundstransfer.getSingleTransaction(
          SingleTransaction
        );
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async getBankList({ commit }, bankList) {
      try {
        let result = await fundstransfer.getBankList(bankList);
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async submitBusinessInformation({ commit }, businessInformation) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let result = await fundstransfer.submitBusinessInformation(
          businessInformation
        );
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async submitBusinessAddress({ commit }, businessAddress) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let result = await fundstransfer.submitBusinessAddress(businessAddress);
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async submitBusinessContactInfo({ commit }, businessContactInfo) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let result = await fundstransfer.submitBusinessContactInfo(
          businessContactInfo
        );
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async submitBusinessEmails({ commit }, businessEmails) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let result = await fundstransfer.submitBusinessEmails(businessEmails);
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async submitBusinessSocials({ commit }, businessSocials) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let result = await fundstransfer.submitBusinessSocials(businessSocials);
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async submitFundingSource({ commit }, FundingSource) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let result = await fundstransfer.submitFundingSource(FundingSource);
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async completeFTSubmission({ commit }, FTSubmission) {
      try {
        let result = await fundstransfer.completeFTSubmission(FTSubmission);
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async verifyBankDetails({ commit }, BankDetails) {
      try {
        let result = await fundstransfer.verifyBankDetails(BankDetails);
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async submitIPDetails({ commit }, IPDetails) {
      try {
        let result = await fundstransfer.submitIPDetails(IPDetails);
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async sendInfoToETZ({ commit }, ETZDetails) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let result = await fundstransfer.sendInfoToETZ(ETZDetails);
        return result;
      } catch (error) {
        // throw Error(error.response.data.message);
        console.log(error);
      }
    },
    async updateTerminalID({ commit }, TerminalID) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let result = await fundstransfer.updateTerminalID(TerminalID);
        return result;
      } catch (error) {
        // throw Error(error.response.data.message);
        console.log(error);
      }
    },
    async generateAuthKeys({ commit }, AuthKeys) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let result = await fundstransfer.generateAuthKeys(AuthKeys);
        return result;
      } catch (error) {
        // throw Error(error.response.data.message);
        console.log(error);
      }
    },
    async submitETZEmail({ commit }, ETZEmail) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let result = await fundstransfer.submitETZEmail(ETZEmail);
        return result;
      } catch (error) {
        // throw Error(error.response.data.message);
        console.log(error);
      }
    },
    async getETZEmail({ commit }, ETZEmail) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let result = await fundstransfer.getETZEmail(ETZEmail);
        let etz_email = result.data.result[0].etz_registration_email;
        commit("SET_ETZ_EMAIL", etz_email);
        return result;
      } catch (error) {
        // throw Error(error.response.data.message);
        console.log(error);
      }
    },
    async getDefaultPricing({ commit }, DefaultPricing) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let result = await fundstransfer.getDefaultPricing(DefaultPricing);
        return result;
      } catch (error) {
        // throw Error(error.response.data.message);
        console.log(error);
      }
    },
    async approveRequest({ commit }, Request) {
      apiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL_V2;
      try {
        let result = await fundstransfer.approveRequest(Request);
        return result;
      } catch (error) {
        // throw Error(error.response.data.message);
        console.log(error);
      }
    },
    async verifyAccountNumber({ commit }, accountNumber) {
      try {
        let result = await fundstransfer.verifyAccountNumber(accountNumber);
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async createBilling({ commit }, billing) {
      try {
        let result = await fundstransfer.createBilling(billing);
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async editBilling({ commit }, billing) {
      try {
        let result = await fundstransfer.editBilling(billing);
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async getBilling({ commit }, billing) {
      try {
        let result = await fundstransfer.getBilling(billing);
        return result;
      } catch (error) {
        throw Error(error.response.data.message);
      }
    },
    async getFTOverview({ commit }, query) {
      try {
        let { data } = await fundstransfer.GetFTOverview(query);
        console.log("[data from get customers] ->", data.result);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getFTProviders({ commit }, query) {
      try {
        let { data } = await fundstransfer.GetFTProviders(query);
        console.log("[data from get customers] ->", data.results);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getFTtransactions({ commit }, query) {
      try {
        let { data } = await fundstransfer.GetFTtransactions(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getFTProviderTransactions({ commit }, query) {
      try {
        let { data } = await fundstransfer.GetFTProviderTransactions(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getFTProviderIntegrations({ commit }, query) {
      try {
        let { data } = await fundstransfer.GetFTProviderIntegrations(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
  },
};
