<template>
  <!-- nav tabs -->
  <div class="row" v-if="bizInfo">
    <div
      :class="{'col-12': status === 'pending' || status === 'approved', 'col-8': status === 'processing'}"
    >
      <div class="col-12">
        <nav>
          <div
            class="nav-tab__user-account nav nav-tabs"
            style="top: -1px;"
            id="nav-tab"
            role="tablist"
          >
            <a
              v-if="status === 'pending' || status === 'approved'"
              data-toggle="tab"
              id="nav-all-users-tab"
              href="#nav-all-users"
              role="tab"
              aria-controls="nav-all-users"
              aria-selected="false"
              class="nav-link active"
            >
              <span
                class="badge badge-pill badge-primary"
                style="font-size:14px; font-weight: 500"
              >1</span> Business Information
            </a>
            <a
              v-if="status ==='processing'"
              data-toggle="tab"
              id="nav-all-users-tab"
              href="#nav-all-users"
              role="tab"
              aria-controls="nav-all-users"
              aria-selected="false"
              class="nav-link active"
            >Business Information</a>
            <a
              v-if="status === 'pending' || status === 'approved'"
              data-toggle="tab"
              id="nav-settings-tab"
              href="#nav-transfer"
              role="tab"
              aria-controls="nav-transfer"
              aria-selected="false"
              class="nav-link"
            >
              <span
                class="badge badge-pill badge-primary"
                style="font-size:14px; font-weight: 500"
              >2</span> Business Funding Source
            </a>
            <a
              v-if="status ==='processing'"
              data-toggle="tab"
              id="nav-settings-tab"
              href="#nav-transfer"
              role="tab"
              aria-controls="nav-transfer"
              aria-selected="false"
              class="nav-link"
            >Funding Source</a>
            <a
              v-if="status === 'pending' || status === 'approved'"
              data-toggle="tab"
              id="nav-settings-tab"
              href="#nav-billing"
              role="tab"
              aria-controls="nav-billing"
              aria-selected="false"
              class="nav-link"
            >
              <span
                class="badge badge-pill badge-primary"
                style="font-size:14px; font-weight: 500"
              >3</span> Preferred Billing
            </a>
            <a
              v-if="status === 'processing'"
              data-toggle="tab"
              id="nav-settings-tab"
              href="#nav-billing"
              role="tab"
              aria-controls="nav-billing"
              aria-selected="false"
              class="nav-link"
            >Billing</a>
            <a
              v-if="status === 'pending' || status === 'approved'"
              data-toggle="tab"
              id="nav-settings-tab"
              href="#nav-access-config"
              role="tab"
              aria-controls="nav-access-config"
              aria-selected="false"
              class="nav-link"
            >
              <span
                class="badge badge-pill badge-primary"
                style="font-size:14px; font-weight: 500"
              >4</span> Access Configuration
            </a>
            <a
              v-if="status === 'processing'"
              data-toggle="tab"
              id="nav-settings-tab"
              href="#nav-access-config"
              role="tab"
              aria-controls="nav-access-config"
              aria-selected="false"
              class="nav-link"
            >Access Config.</a>
          </div>
        </nav>
      </div>
      <div
        class="dashboard rounded p-4 tab-pane fade show active"
        id="nav-all-profile"
        role="tabpanel"
        aria-labelledby="nav-all-profile-tab"
      >
        <!-- nav tabs -->
        <div class="row">
          <div class="col-12 tab-content" id="nav-tabContent" style="min-height: 450px;">
            <div
              class="dashboard rounded p-4 tab-pane fade show active"
              id="nav-all-users"
              role="tabpanel"
              aria-labelledby="nav-all-users-tab"
            >
              <form>
                <div class="d-flex justify-content-between">
                  <h2>Business Details</h2>
                  <!-- <h5 class="user-profile-email sub">Provide the following information on your business.</h5> -->
                  <button
                    v-if="editBusinessInfo == false && status === 'pending'"
                    class="btn btn-md btn-primary mg-b-20"
                    @click.prevent="editBusinessInfo = true"
                  >
                    <i class="typcn typcn-pencil mr-1"></i>Edit Details
                  </button>
                </div>
                <div
                  :class="{'row mt-4': status==='processing'|| status === 'approved' || editBusinessInfo == true, 'row': editBusinessInfo == false}"
                >
                  <!-- Company/Merchant Name -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Company/Merchant Name</label>
                      <input
                        type="text"
                        v-model="business_info.company_name"
                        :class="{'form-control input-bordered disabled__edit': editBusinessInfo == true, 'form-control disabledInput': editBusinessInfo == false}"
                        placeholder="Enter Company/Merchant Name"
                        :disabled="editBusinessInfo == false"
                      />
                    </div>
                  </div>

                  <!-- RC No. -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">RC Number</label>
                      <input
                        type="text"
                        v-model="business_info.rc_no"
                        :class="{'form-control input-bordered text-uppercase': editBusinessInfo == true, 'form-control disabledInput text-uppercase': editBusinessInfo == false}"
                        placeholder="Enter RC Number"
                        :disabled="editBusinessInfo == false"
                      />
                    </div>
                  </div>

                  <!-- CAC Reg No. -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">CAC Registration Number</label>
                      <input
                        type="text"
                        v-model="business_info.cac_reg_no"
                        :class="{'form-control input-bordered text-uppercase': editBusinessInfo == true, 'form-control disabledInput text-uppercase': editBusinessInfo == false}"
                        placeholder="Enter CAC Registration Number"
                        :disabled="editBusinessInfo == false"
                      />
                    </div>
                  </div>

                  <!-- Business Phone Number -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Business Phone Number</label>
                      <input
                        type="text"
                        v-model="business_info.business_phone_no"
                        :class="{'form-control input-bordered': editBusinessInfo == true, 'form-control disabledInput': editBusinessInfo == false}"
                        placeholder="Enter Business Phone Number"
                        :disabled="editBusinessInfo == false"
                      />
                    </div>
                  </div>

                  <!-- Annual Revenue -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Annual Revenue</label>
                      <v-select
                        v-if="editBusinessInfo == true"
                        :options="annualRevenue"
                        label="annual_revenue_label"
                        v-model="business_info.annual_revenue"
                        class="v-select"
                        placeholder="Select Annual Revenue"
                      ></v-select>
                      <input
                        v-if="editBusinessInfo == false"
                        type="text"
                        v-model="business_info.annual_revenue.annual_revenue_label"
                        class="form-control disabledInput"
                        placeholder="Select Annual Revenue"
                        disabled
                      />
                    </div>
                  </div>

                  <!-- Business Type -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Business Type</label>
                      <v-select
                        :options="businessType"
                        v-if="editBusinessInfo == true"
                        label="business_type_label"
                        v-model="business_info.business_type"
                        class="v-select"
                        placeholder="Select Business Type"
                      ></v-select>
                      <input
                        v-if="editBusinessInfo == false"
                        type="text"
                        v-model="business_info.business_type.business_type_label"
                        class="form-control disabledInput"
                        placeholder="Select Annual Revenue"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="gaps-2x"></div>
                    <button
                      v-if="editBusinessInfo == true"
                      class="btn btn-lg btn-primary mg-b-20 mr-2"
                      @click.prevent="submitBusinessInformation"
                      ref="submitBtn"
                      type="submit"
                      :disabled="isSubmitting == true"
                    >
                      <span v-if="isSubmitting">...Saving Info</span>
                      <span v-else>Save Business Info</span>
                    </button>
                    <button
                      v-if="editBusinessInfo == true"
                      class="btn btn-md btn-outline-danger mg-b-20"
                      @click.prevent="editBusinessInfo = false"
                    >Cancel</button>
                  </div>
                </div>
                <hr />
                <div class="d-flex justify-content-between mt-3">
                  <h2>Business Emails</h2>
                  <button
                    v-if="editEmails == false && status === 'pending'"
                    class="btn btn-md btn-primary mg-b-20"
                    @click.prevent="editEmails = true"
                  >
                    <i class="typcn typcn-pencil mr-1"></i>Edit Emails
                  </button>
                </div>
                <div
                  :class="{'row mt-4': status==='processing'|| status === 'approved' || editEmails == true, 'row': editEmails == false}"
                >
                  <!-- Business Email -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Business Email</label>
                      <input
                        type="text"
                        v-model="business_info.business_email"
                        :class="{'form-control input-bordered': editEmails == true, 'form-control disabledInput': editEmails == false}"
                        placeholder="Enter Business Email"
                        :disabled="editEmails == false"
                      />
                    </div>
                  </div>

                  <!-- Support Email -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Support Email</label>
                      <input
                        type="text"
                        v-model="business_info.support_email"
                        :class="{'form-control input-bordered': editEmails == true, 'form-control disabledInput': editEmails == false}"
                        placeholder="Enter Support Email"
                        :disabled="editEmails == false"
                      />
                    </div>
                  </div>

                  <!-- Chargeback Email -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Chargeback Email</label>
                      <input
                        type="text"
                        v-model="business_info.chargeback_email"
                        :class="{'form-control input-bordered': editEmails == true, 'form-control disabledInput': editEmails == false}"
                        placeholder="Enter Chargeback Email"
                        :disabled="editEmails == false"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="gaps-2x"></div>
                    <button
                      v-if="editEmails == true"
                      class="btn btn-lg btn-primary mg-b-20 mr-2"
                      @click.prevent="submitBusinessEmails"
                      ref="submitBtn"
                      type="submit"
                      :disabled="isSubmitting == true"
                    >
                      <span v-if="isSubmitting">...Saving Info</span>
                      <span v-else>Save Emails</span>
                    </button>
                    <button
                      v-if="editEmails == true"
                      class="btn btn-md btn-outline-danger mg-b-20"
                      @click.prevent="editEmails = false"
                    >Cancel</button>
                  </div>
                </div>
                <hr />

                <div class="d-flex justify-content-between mt-3">
                  <h2>Business Address</h2>
                  <button
                    v-if="editAddressInfo == false && status === 'pending'"
                    class="btn btn-md btn-primary mg-b-20"
                    @click.prevent="editAddressInfo = true"
                  >
                    <i class="typcn typcn-pencil mr-1"></i>Edit Address Info
                  </button>
                </div>
                <div
                  :class="{'row mt-4': status==='processing'|| status === 'approved' || editAddressInfo == true, 'row': editAddressInfo == false}"
                >
                  <!-- Business Website -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Business Website</label>
                      <input
                        type="text"
                        v-model="business_info.business_website"
                        :class="{'form-control input-bordered': editAddressInfo == true, 'form-control disabledInput': editAddressInfo == false}"
                        placeholder="Enter Business Website"
                        :disabled="editAddressInfo == false"
                      />
                    </div>
                  </div>

                  <!-- Postal Code -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Postal Code</label>
                      <input
                        type="text"
                        v-model="business_info.postal_code"
                        :class="{'form-control input-bordered': editAddressInfo == true, 'form-control disabledInput': editAddressInfo == false}"
                        placeholder="Enter Postal Code"
                        :disabled="editAddressInfo == false"
                      />
                    </div>
                  </div>

                  <!-- Building Number -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Building Number</label>
                      <input
                        type="text"
                        v-model="business_info.building_no"
                        :class="{'form-control input-bordered': editAddressInfo == true, 'form-control disabledInput': editAddressInfo == false}"
                        placeholder="Enter Building Number"
                        :disabled="editAddressInfo == false"
                      />
                    </div>
                  </div>

                  <!-- Business Address -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Business Address</label>
                      <input
                        type="text"
                        v-model="business_info.building_address"
                        :class="{'form-control input-bordered': editAddressInfo == true, 'form-control disabledInput': editAddressInfo == false}"
                        placeholder="Enter Business Address"
                        :disabled="editAddressInfo == false"
                      />
                    </div>
                  </div>

                  <!-- City -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">City</label>
                      <input
                        type="text"
                        v-model="business_info.city"
                        :class="{'form-control input-bordered': editAddressInfo == true, 'form-control disabledInput': editAddressInfo == false}"
                        placeholder="Enter City"
                        :disabled="editAddressInfo == false"
                      />
                    </div>
                  </div>

                  <!-- State -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">State</label>
                      <v-select
                        :options="states"
                        v-if="editAddressInfo == true"
                        label="state_label"
                        v-model="business_info.state"
                        class="v-select"
                        placeholder="Select State"
                      ></v-select>
                      <input
                        v-if="editAddressInfo == false"
                        type="text"
                        v-model="business_info.state.state_label"
                        class="form-control disabledInput"
                        placeholder="Select State"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="gaps-2x"></div>
                    <button
                      v-if="editAddressInfo == true"
                      class="btn btn-lg btn-primary mg-b-20 mr-2"
                      @click.prevent="submitBusinessAddressInfo"
                      ref="submitBtn"
                      type="submit"
                      :disabled="isSubmitting == true"
                    >
                      <span v-if="isSubmitting">...Saving Info</span>
                      <span v-else>Save Address Info</span>
                    </button>
                    <button
                      v-if="editAddressInfo == true"
                      class="btn btn-md btn-outline-danger mg-b-20"
                      @click.prevent="editAddressInfo = false"
                    >Cancel</button>
                  </div>
                </div>
                <hr />
                <div class="d-flex justify-content-between mt-3">
                  <h2>Business Contacts</h2>
                  <button
                    v-if="editContactInfo == false && status === 'pending'"
                    class="btn btn-md btn-primary mg-b-20"
                    @click.prevent="editContactInfo = true"
                  >
                    <i class="typcn typcn-pencil mr-1"></i>Edit Contact Info
                  </button>
                </div>
                <div
                  :class="{'row mt-4': status==='processing'|| status === 'approved' || editContactInfo == true, 'row': editContactInfo == false}"
                >
                  <!-- Primary Contact First Name -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Primary Contact First Name</label>
                      <input
                        type="text"
                        v-model="business_info.primary_contact_first_name"
                        :class="{'form-control input-bordered disabled__edit': editContactInfo == true, 'form-control disabledInput': editContactInfo == false}"
                        placeholder="Enter Primary Contact First Name"
                        :disabled="editContactInfo == false"
                      />
                    </div>
                  </div>
                  <!-- Primary Contact Last Name -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Primary Contact Last Name</label>
                      <input
                        type="text"
                        v-model="business_info.primary_contact_last_name"
                        :class="{'form-control input-bordered disabled__edit': editContactInfo == true, 'form-control disabledInput': editContactInfo == false}"
                        placeholder="Enter Primary Contact Last Name"
                        :disabled="editContactInfo == false"
                      />
                    </div>
                  </div>
                  <!-- Primary Contact Email -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Primary Contact Email</label>
                      <input
                        type="text"
                        v-model="business_info.primary_contact_email"
                        :class="{'form-control input-bordered disabled__edit': editContactInfo == true, 'form-control disabledInput': editContactInfo == false}"
                        placeholder="Enter Primary Contact Email"
                        :disabled="editContactInfo == false"
                      />
                    </div>
                  </div>
                  <!-- Primary Contact Phone No. -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Primary Contact Phone No.</label>
                      <input
                        type="text"
                        v-model="business_info.primary_contact_phone_no"
                        :class="{'form-control input-bordered disabled__edit': editContactInfo == true, 'form-control disabledInput': editContactInfo == false}"
                        placeholder="Enter Primary Contact Phone No."
                        :disabled="editContactInfo == false"
                      />
                    </div>
                  </div>
                  <!-- Secondary Contact First Name -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Secondary Contact First Name</label>
                      <input
                        type="text"
                        v-model="business_info.secondary_contact_first_name"
                        :class="{'form-control input-bordered': editContactInfo == true, 'form-control disabledInput': editContactInfo == false}"
                        placeholder="Enter Secondary Contact First Name"
                        :disabled="editContactInfo == false"
                      />
                    </div>
                  </div>
                  <!-- Secondary Contact Last Name -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Secondary Contact Last Name</label>
                      <input
                        type="text"
                        v-model="business_info.secondary_contact_last_name"
                        :class="{'form-control input-bordered': editContactInfo == true, 'form-control disabledInput': editContactInfo == false}"
                        placeholder="Enter Secondary Contact Last Name"
                        :disabled="editContactInfo == false"
                      />
                    </div>
                  </div>
                  <!-- Secondary Contact Mobile Telephone -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Secondary Contact Mobile Telephone</label>
                      <input
                        type="text"
                        v-model="business_info.secondary_contact_mobile_telephone"
                        :class="{'form-control input-bordered': editContactInfo == true, 'form-control disabledInput': editContactInfo == false}"
                        placeholder="Enter Secondary Contact Mobile Telephone"
                        :disabled="editContactInfo == false"
                      />
                    </div>
                  </div>
                  <!-- Secondary Contact Office Telephone -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Secondary Contact Office Telephone</label>
                      <input
                        type="text"
                        v-model="business_info.secondary_contact_office_telephone"
                        :class="{'form-control input-bordered': editContactInfo == true, 'form-control disabledInput': editContactInfo == false}"
                        placeholder="Enter Secondary Contact Office Telephone"
                        :disabled="editContactInfo == false"
                      />
                    </div>
                  </div>
                  <!-- Secondary Contact Email -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Secondary Contact Email</label>
                      <input
                        type="text"
                        v-model="business_info.secondary_contact_email"
                        :class="{'form-control input-bordered': editContactInfo == true, 'form-control disabledInput': editContactInfo == false}"
                        placeholder="Enter Secondary Contact Email"
                        :disabled="editContactInfo == false"
                      />
                    </div>
                  </div>
                  <!-- Secondary Contact Designation -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Secondary Contact Designation</label>
                      <input
                        type="text"
                        v-model="business_info.secondary_contact_designation"
                        :class="{'form-control input-bordered': editContactInfo == true, 'form-control disabledInput': editContactInfo == false}"
                        placeholder="Enter Secondary Contact Designation"
                        :disabled="editContactInfo == false"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="gaps-2x"></div>
                    <button
                      v-if="editContactInfo == true"
                      class="btn btn-lg btn-primary mg-b-20 mr-2"
                      @click.prevent="submitBusinessContactInfo"
                      ref="submitBtn"
                      type="submit"
                      :disabled="isSubmitting == true"
                    >
                      <span v-if="isSubmitting">...Saving Info</span>
                      <span v-else>Save Contact Info</span>
                    </button>
                    <button
                      v-if="editContactInfo == true"
                      class="btn btn-md btn-outline-danger mg-b-20"
                      @click.prevent="editContactInfo = false"
                    >Cancel</button>
                  </div>
                </div>
                <hr />
                <div class="d-flex justify-content-between mt-3">
                  <h2>Social Media Links</h2>
                  <button
                    v-if="editSocialLinks == false && status === 'pending'"
                    class="btn btn-md btn-primary mg-b-20"
                    @click.prevent="editSocialLinks = true"
                  >
                    <i class="typcn typcn-pencil mr-1"></i>Edit Social Links
                  </button>
                </div>
                <div
                  :class="{'row mt-4': status==='processing'|| status === 'approved' || editSocialLinks == true, 'row': editSocialLinks == false}"
                >
                  <!-- LinkedIn -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">LinkedIn Profile URL</label>
                      <input
                        type="text"
                        v-model="socials.linkedin_url"
                        :class="{'form-control input-bordered': editSocialLinks == true, 'form-control disabledInput': editSocialLinks == false}"
                        placeholder="Enter LinkedIn Profile URL"
                        :disabled="editSocialLinks == false"
                      />
                    </div>
                  </div>

                  <!-- Facebook -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Facebook Profile URL</label>
                      <input
                        type="text"
                        v-model="socials.facebook_url"
                        :class="{'form-control input-bordered': editSocialLinks == true, 'form-control disabledInput': editSocialLinks == false}"
                        placeholder="Enter Facebook Profile URL"
                        :disabled="editSocialLinks == false"
                      />
                    </div>
                  </div>

                  <!-- Instagram -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Instagram Profile URL</label>
                      <input
                        type="text"
                        v-model="socials.instagram_url"
                        :class="{'form-control input-bordered': editSocialLinks == true, 'form-control disabledInput': editSocialLinks == false}"
                        placeholder="Enter Instagram Profile URL"
                        :disabled="editSocialLinks == false"
                      />
                    </div>
                  </div>

                  <!-- Twitter -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Twitter Profile URL</label>
                      <input
                        type="text"
                        v-model="socials.twitter_url"
                        :class="{'form-control input-bordered': editSocialLinks == true, 'form-control disabledInput': editSocialLinks == false}"
                        placeholder="Enter Twitter Profile URL"
                        :disabled="editSocialLinks == false"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="gaps-2x"></div>
                    <button
                      v-if="editSocialLinks == true"
                      class="btn btn-lg btn-primary mg-b-20 mr-2"
                      @click.prevent="submitBusinessSocials"
                      ref="submitBtn"
                      type="submit"
                      :disabled="isSubmitting == true"
                    >
                      <span v-if="isSubmitting">...Saving Info</span>
                      <span v-else>Save Social Links</span>
                    </button>
                    <button
                      v-if="editSocialLinks == true"
                      class="btn btn-md btn-outline-danger mg-b-20"
                      @click.prevent="editSocialLinks = false"
                    >Cancel</button>
                  </div>
                </div>
              </form>
            </div>
            <div
              class="tab-pane fade dashboard rounded p-4"
              id="nav-transfer"
              role="tabpanel"
              aria-labelledby="nav-settings-tab"
            >
              <h2>Business Funding Source</h2>
              <!-- <h5
                class="user-profile-email sub"
              >Provide details about your business funding source account below.</h5>-->
              <form>
                <div class="row col-md-10 mt-4">
                  <!-- Funding Source Options -->
                  <div
                    :class="{ 'col-md-12': showVirtualCard == false || status=== 'processing', 'col-md-6': showVirtualCard == true }"
                  >
                    <div class="form-group">
                      <label class="input-item-label">Funding Source Options</label>
                      <v-select
                        :options="[{id:1, funding_source_label: 'Bank'}]"
                        label="funding_source_label"
                        @input="checkFundingOption"
                        v-model="business_funding_source.funding_source_options"
                        class="v-select"
                        placeholder="Select Funding Source Option"
                        :disabled="showVirtualCard == true"
                      ></v-select>
                    </div>
                  </div>

                  <div
                    :class="{'col-md-6': status === 'pending' || status === 'approved', 'col-md-12': status === 'processing'}"
                    v-if="showVirtualCard === true"
                  >
                    <div
                      class="card text-white mt-4"
                      style="max-width: 28rem; height:16rem; border-radius: 1.5em; background-image: linear-gradient( 109.6deg,  rgba(9,9,121,1) 11.2%, rgba(144,6,161,1) 53.7%, rgba(0,212,255,1) 100.2% );"
                    >
                      <div class="card-header d-flex justify-content-end">
                        <img src="/assets/img/blusalt-logo-light.svg" height="30" alt style />
                      </div>
                      <div class="card-body">
                        <img src="/assets/img/chip.svg" alt height="50" />
                        <div>
                          <p style="font-size: 2em;">{{ business_funding_source.virtual_card_no }}</p>
                          <h5 class="card-title">Virtual Card Number</h5>
                        </div>
                        <div class="d-flex justify-content-end">
                          <img src="/assets/img/mastercard.svg" alt height="34" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row col-md-10 mt-4">
                  <template v-if="fundingTab === 'Bank' && showVirtualCard == false">
                    <!-- Bank List -->
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="input-item-label">Select your Bank</label>
                        <v-select
                          :options="bankList"
                          label="bank_name"
                          v-model="business_funding_source.funding_source_bank_code"
                          class="v-select"
                          placeholder="Select your bank"
                        ></v-select>
                      </div>
                    </div>

                    <!-- Funding Source Bank Branch -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Funding Source Bank Branch</label>
                        <input
                          type="text"
                          v-model="business_funding_source.funding_source_bank_branch"
                          class="form-control input-bordered"
                          placeholder="Enter Funding Source Bank Branch"
                        />
                      </div>
                    </div>

                    <!-- Funding source a/c no -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Funding Source Account Number</label>
                        <input
                          type="text"
                          v-model="business_funding_source.funding_source_acc_no"
                          @blur="verifyAccountNumber"
                          class="form-control input-bordered"
                          placeholder="Enter Funding Source Account Number"
                        />
                      </div>
                      <!-- <template v-if="accountVerification === true">
                              <small
                                style="color: rgb(0, 104, 46);"
                                v-if="verifyAccount.length"
                              >{{ verifyAccount[0].account_name }}</small>
                              <small
                                style="color:#ff6868;"
                                v-else
                              >Please Enter A Valid Account Number</small>
                      </template>-->
                    </div>

                    <!-- account name field -->
                    <div
                      :class="{ 'd-none': !accountVerification, 'col-md-6': accountVerification }"
                    >
                      <div class="form-group">
                        <label class="input-item-label">Account Name</label>
                        <input
                          type="text"
                          v-model="account_name"
                          class="form-control input-bordered"
                          placeholder="Enter Account Name"
                          disabled
                        />
                      </div>
                    </div>
                    <!-- Virtual Card No -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="input-item-label">Virtual Card Number</label>
                        <input
                          type="text"
                          v-model="business_funding_source.virtual_card_no"
                          class="form-control input-bordered"
                          placeholder="Enter Virtual Card Number"
                        />
                      </div>
                    </div>
                  </template>
                  <template v-if="showVirtualCard == false && fundingTab === 'Bank'">
                    <!-- mandate doc to bank -->
                    <div class="col-md-6" ref="uploadInput">
                      <div class="input-item">
                        <div class="input-wrap">
                          <label class="input-item-label">
                            Upload Mandate Document to Bank
                            <span class="text-danger">*</span>
                          </label>
                          <input
                            type="file"
                            class="input-bordered"
                            accept=".pdf, .png, .jpeg, .jpg"
                            @change="selectMandateDoc"
                            ref="mandate_doc_ref"
                          />
                          <small
                            class="form-text text-muted"
                          >File must be in the format: .pdf, .png, .jpeg, or .jpg</small>
                        </div>
                        <!-- <ul class="parsley-errors-list filled" v-if="uploadSecondError">
                                <span class="text-danger">File size must be less than 5MB</span>
                        </ul>-->
                      </div>
                    </div>

                    <!-- auth doc to Blusalt -->
                    <div class="col-md-6" ref="uploadInput">
                      <div class="input-item">
                        <div class="input-wrap">
                          <label class="input-item-label">
                            Upload Authorization Document to Blusalt
                            <span class="text-danger">*</span>
                          </label>
                          <input
                            type="file"
                            class="input-bordered"
                            accept=".pdf, .png, .jpeg, .jpg"
                            @change="selectAuthDocument"
                            ref="auth_document_ref"
                          />
                          <small
                            class="form-text text-muted"
                          >File must be in the format: .pdf, .png, .jpeg, or .jpg</small>
                        </div>
                        <!-- <ul class="parsley-errors-list filled" v-if="uploadSecondError">
                                <span class="text-danger">File size must be less than 5MB</span>
                        </ul>-->
                      </div>
                    </div>
                  </template>
                  <div class="col-12">
                    <div class="gaps-2x"></div>
                    <button
                      v-if="showVirtualCard == false"
                      class="btn btn-lg btn-primary mg-b-20 mr-2"
                      @click.prevent="submitFundingSource"
                      ref="submitBtn"
                      type="submit"
                      :disabled="isSubmitting == true"
                    >
                      <span v-if="isSubmitting">...Saving Info</span>
                      <span v-else>Submit Funding Source</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div
              class="tab-pane fade dashboard rounded p-4"
              id="nav-billing"
              role="tabpanel"
              aria-labelledby="nav-settings-tab"
            >
              <div class="d-flex justify-content-between">
                <h2>Billing - Custom/Default Pricing</h2>
                <button
                  v-if="editBilling == false && status === 'pending'"
                  class="btn btn-md btn-primary mg-b-20"
                  @click.prevent="editBilling = true"
                >
                  <i class="typcn typcn-pencil mr-1"></i>Edit Billing
                </button>
              </div>
              <!-- <h5 class="user-profile-email sub">Set your preferred billing</h5> -->
              <template v-if="editBilling == false">
                <form @submit.prevent="editBillingType">
                  <div class="row mt-4">
                    <!-- Funds Transfer Billing -->
                  </div>
                  <div class="col-md-8 p-0">
                    <v-client-table
                      name="billing-list"
                      :api-mode="false"
                      class="v-table hidden-xs"
                      :data="Billing"
                      :columns="columns"
                      :options="options"
                    ></v-client-table>
                  </div>
                </form>
              </template>
              <template v-if="editBilling == true">
                <div class="row mt-4">
                  <!-- Funds Transfer Billing -->
                  <div class="col-md-8">
                    <div class="form-group">
                      <label class="input-item-label">Funds Transfer Billing</label>
                      <v-select
                        :options="billingType"
                        label="transfer_pricing_label"
                        v-model="ft_billing"
                        @input="changeBillingTab"
                        class="v-select"
                        placeholder="Select Funds Transfer Billing"
                      ></v-select>
                    </div>
                  </div>
                </div>
                <div v-if="billingTab === 'amtThreshold'">
                  <div v-for="(billing, index) in billing_price" :key="index">
                    <div class="row mt-4">
                      <form class="form-inline col-md-12">
                        <label class="sr-only" for="inlineFormInputGroupMinimumPrice1">Minimum Price</label>
                        <div class="input-group mb-2 mr-sm-4">
                          <div class="input-group-prepend">
                            <div class="input-group-text">NGN</div>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            v-model="billing.min_range"
                            id="inlineFormInputGroupMinimumPrice1"
                            placeholder="Minimum Price"
                          />
                        </div>
                        <label class="sr-only" for="inlineFormInputGroupMaximumPrice2">Maximum Price</label>
                        <div class="input-group mb-2 mr-sm-4">
                          <div class="input-group-prepend">
                            <div class="input-group-text">NGN</div>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            v-model="billing.max_range"
                            id="inlineFormInputGroupMaximumPrice2"
                            placeholder="Maximum Price"
                          />
                        </div>
                        <label class="sr-only" for="inlineFormInputGroupMinimumPrice1">Billing Rate</label>
                        <div class="input-group mb-2 mr-sm-4">
                          <div class="input-group-prepend">
                            <div class="input-group-text">NGN</div>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            v-model="billing.transfer_rate"
                            id="inlineFormInputGroupMinimumPrice1"
                            placeholder="Billing Rate"
                          />
                        </div>
                        <button
                          class="btn btn-outline-danger my-2 remove__btn"
                          v-if="billing_price.length > 1"
                          @click.prevent="removeForm(index)"
                        >
                          <i class="la la-minus mr-1"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                  <button
                    class="btn btn-outline-primary my-2 add__btn"
                    @click.prevent="addForm(billing_price[billing_price.length - 1].max_range)"
                  >
                    <i class="la la-plus mr-1"></i>Add
                  </button>
                  <div class="gaps-2x"></div>
                  <button
                    v-if="editBilling == true"
                    class="btn btn-md btn-primary mg-b-20 mr-2"
                    @click.prevent="createThresholdBilling"
                    ref="submitBtn"
                    type="submit"
                    :disabled="isSubmitting == true"
                  >
                    <span v-if="isSubmitting">...Saving Info</span>
                    <span v-else>Save Billing</span>
                  </button>
                  <button
                    v-if="editBilling == true"
                    class="btn btn-md btn-outline-danger mg-b-20"
                    @click.prevent="editBilling = false"
                  >Cancel</button>
                </div>
                <div v-if="billingTab === 'volumeBilling'">
                  <div class="row mt-4">
                    <form class="form-inline col-md-8">
                      <label class="sr-only" for="inlineFormInputGroupMinimumPrice1">Transfer Rate</label>
                      <div class="input-group mb-2 mr-sm-2">
                        <div class="input-group-prepend">
                          <div class="input-group-text">NGN</div>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          v-model="billing_rate"
                          id="inlineFormInputGroupMinimumPrice1"
                          placeholder="Billing Rate"
                        />
                      </div>
                    </form>
                  </div>
                  <div class="gaps-2x"></div>
                  <button
                    v-if="editBilling == true"
                    class="btn btn-lg btn-primary mg-b-20 mr-2"
                    @click.prevent="createVolumeBilling"
                    ref="submitBtn"
                    type="submit"
                    :disabled="isSubmitting == true"
                  >
                    <span v-if="isSubmitting">...Saving Info</span>
                    <span v-else>Save Billing</span>
                  </button>
                  <button
                    v-if="editBilling == true"
                    class="btn btn-md btn-outline-danger mg-b-20"
                    @click.prevent="editBilling = false"
                  >Cancel</button>
                </div>
              </template>
            </div>
            <div
              class="tab-pane fade dashboard rounded p-4"
              id="nav-access-config"
              role="tabpanel"
              aria-labelledby="nav-settings-tab"
            >
              <!-- <h5
                class="user-profile-email sub"
              >Provide details about your Access Configuration below.</h5>-->
              <form>
                <div class="d-flex justify-content-between">
                  <h2>Access Configuration</h2>
                  <button
                    v-if="editIP == false && status === 'pending'"
                    class="btn btn-md btn-primary mg-b-20"
                    @click.prevent="editIP = true"
                  >
                    <i class="typcn typcn-pencil mr-1"></i>Edit IP Address
                  </button>
                </div>
                <div class="row">
                  <!-- IP Address -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label mb-0">IP Address</label>
                      <br />
                      <small>(IP Address to be whitelisted for access to the APIs)</small>
                      <input
                        type="text"
                        ref="ip_address"
                        v-model="access_config.ip_address"
                        :class="{'form-control input-bordered mt-1': editIP == true, 'form-control disabledInput mt-1': editIP == false}"
                        placeholder="Enter IP Address (IP Address to be whitelisted for access to the APIs)"
                        :disabled="editIP == false"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="gaps-2x"></div>
                    <button
                      v-if="editIP == true"
                      class="btn btn-lg btn-primary mg-b-20 mr-2"
                      @click.prevent="submitIP"
                      ref="submitBtn"
                      type="submit"
                      :disabled="isSubmitting == true"
                    >
                      <span v-if="isSubmitting">...Saving Info</span>
                      <span v-else>Save IP Address</span>
                    </button>
                    <button
                      v-if="editIP == true"
                      class="btn btn-md btn-outline-danger mg-b-20"
                      @click.prevent="editIP = false"
                    >Cancel</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4" v-if="status === 'processing'">
      <slot></slot>
      <div class="dashboard rounded mt-2">
        <div class="card">
          <div class="card-header bg-light">Actions</div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <i
                class="far fa-check-circle mr-1"
                style="font-size: 20px"
                v-if="bizInfo.requestApprovalSteps.send_info_to_etz === 1"
              ></i>
              <i
                class="far fa-times-circle mr-1"
                style="font-size: 20px"
                v-if="bizInfo.requestApprovalSteps.send_info_to_etz === 0"
              ></i> Business Information to eTranzact.
            </li>
            <li class="list-group-item">
              <i
                class="far fa-check-circle mr-1"
                style="font-size: 20px"
                v-if="bizInfo.etz_terminal_id !== null"
              ></i>
              <i
                class="far fa-times-circle mr-1"
                style="font-size: 20px"
                v-if="bizInfo.etz_terminal_id === null"
              ></i> eTranzact Terminal ID Update.
            </li>
            <li class="list-group-item">
              <i
                class="far fa-check-circle mr-1"
                style="font-size: 20px"
                v-if="bizInfo.requestApprovalSteps.generate_ft_authorization_keys === 1"
              ></i>
              <i
                class="far fa-times-circle mr-1"
                style="font-size: 20px"
                v-if="bizInfo.requestApprovalSteps.generate_ft_authorization_keys === 0"
              ></i> Authorisation Keys Generation.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- page-content -->
</template>

<script>
import Nprogress from "nprogress";
import AuthWrapper from "@/components/auth/AuthWrapper";
import { notifications } from "../../../public/assets/mixins/notifications";

export default {
  name: "BusinessInfo",
  mixins: [notifications],
  components: {
    AuthWrapper
  },
  data() {
    return {
      Billing: [],
      activeTab: "",
      fundingTab: "",
      accountVerification: false,
      newForm: false,
      FTServiceInfo: true,
      validated: false,
      showButton: false,
      requestFTService: false,
      initializedAppResult: {},
      versions: ["v1", "v2"],
      app: "",
      apiVersion: "",
      call_id: "",
      clientID: "",
      hasNewResponse: false,
      logData: [],
      bankList: [],
      billingTab: "",
      verifyAccount: [],
      account_name: "",
      apiResponse: "",
      mandate_doc: "",
      auth_document: "",
      business_info: {
        cac_reg_no: "",
        rc_no: "",
        company_name: "",
        business_type: "",
        business_email: "",
        primary_contact_first_name: "",
        primary_contact_last_name: "",
        primary_contact_email: "",
        primary_contact_phone_no: "",
        secondary_contact_first_name: "",
        secondary_contact_last_name: "",
        secondary_contact_email: "",
        secondary_contact_designation: "",
        secondary_contact_office_telephone: "",
        support_email: "",
        chargeback_email: "",
        business_phone_no: "",
        business_website: "",
        building_no: "",
        building_address: "",
        city: "",
        state: "",
        postal_code: "",
        annual_revenue: ""
      },
      business_funding_source: {
        funding_source_bank_code: "",
        funding_source_bank_branch: "",
        funding_source_options: "",
        funding_source_acc_no: "",
        virtual_card_no: ""
      },
      socials: {
        linkedin_url: "",
        facebook_url: "",
        twitter_url: "",
        instagram_url: ""
      },
      ft_billing: "",
      billing_charge: "",
      billing_rate: "",
      billing_price: [
        {
          min_range: "",
          max_range: "",
          transfer_rate: ""
        }
      ],
      settings: {},
      access_config: {
        ip_address: ""
      },
      servicePrice: "",
      isSubmitting: false,
      showVirtualCard: false,
      verificationResult: [],
      columns: ["min_range", "max_range", "transfer_rate"],
      options: {
        sortable: [],
        filterable: false,
        perPage: 10,
        pagination: {
          chunk: 5
        },
        texts: {
          filter: "",
          filterPlaceholder: "Search...",
          noResults: "No matching billings",
          count:
            "Showing {from} to {to} of {count} billings|{count} billings|One billing"
        },
        headings: {
          min_range: "Minimum Range (NGN)",
          max_range: "Maximum Range (NGN)",
          transfer_rate: "Transfer Rate (NGN)"
        }
      },
      isLoading: true,
      metaData: {},
      submitting: false,
      bizInfo: null,
      status: "",
      editBusinessInfo: false,
      editEmails: false,
      editAddressInfo: false,
      editContactInfo: false,
      editSocialLinks: false,
      editIP: false,
      editFundingSource: false,
      editBilling: false
    };
  },

  computed: {
    states() {
      return this.$store.state.metaData.countries[131].states;
    },
    annualRevenue() {
      return this.$store.state.metaData.settingsFtAnnualRevenue;
    },
    businessType() {
      return this.$store.state.metaData.settingsFtBusinessType;
    },
    fundingSource() {
      return this.$store.state.metaData.settingsFundingSource;
    },
    billingType() {
      return this.$store.state.metaData.settingsPricingDefinition;
    },
    thresholdBilling() {
      return this.$store.state.metaData.settingsDefaultThresholdBillingRate;
    },
    volumeBilling() {
      return this.$store.state.metaData.settingsDefaultVolumeBillingRate;
    },
    ftRequestMade() {
      return this.$store.state.account.profile.is_ft_request_made;
    }
    // status() {
    //   return this.$store.state.fundstransfer.businessInfo.requestApprovalSteps.status;
    // },
  },

  async mounted() {
    await this.getFTBusinessInfo(this.$route.params.id);
    this.status = this.$store.state.fundstransfer.businessInfo.requestApprovalSteps.status;
    await this.getBankList();
    await this.getBilling();
  },

  methods: {
    back() {
      history.back();
    },
    checkFundingOption() {
      if (
        this.business_funding_source.funding_source_options
          .funding_source_label === "Bank"
      ) {
        this.fundingTab = "Bank";
      } else {
        this.fundingTab = "";
        this.showButton = true;
      }
    },
    removeForm(index) {
      this.billing_price.splice(index, 1);
    },
    addForm(max_range) {
      this.billing_price.push({
        min_range: +max_range + 0.01
      });
      this.newForm = true;
    },
    formatNumber(number) {
      let digit = "";
      number = number.toString();
      for (let i = 0; i < number.length; i++) {
        digit += number[i];
        if (i == 3 || i == 7 || i == 11) {
          digit += " - ";
        }
      }
      return digit;
    },
    changeBillingTab() {
      if (this.ft_billing.transfer_pricing_label === "Amount Threshold") {
        this.billingTab = "amtThreshold";
      } else {
        this.billingTab = "volumeBilling";
      }
    },
    selectMandateDoc() {
      let mandateFile = this.$refs.mandate_doc_ref.files[0];
      this.mandate_doc = mandateFile;
    },
    selectAuthDocument() {
      let authFile = this.$refs.auth_document_ref.files[0];
      this.auth_document = authFile;
    },
    // function to increase the width of upload progress by random numbers
    setUploadProgress() {
      let min = 15,
        max = 30;

      let randomNumber = Math.round(Math.random() * (max - min) + min);
      if (this.uploadProgress <= 100) {
        this.uploadProgress += randomNumber;
      }
    },
    async getFTBusinessInfo(id) {
      try {
        await this.$store
          .dispatch("fundstransfer/getFTBusinessInfo", this.$route.params.id)
          .then(response => {
            console.log("111getInfo:", response);

            let apiResponse = response.data.result[0];
            apiResponse.business_website === null
              ? (this.business_info.business_website = "http://")
              : (this.business_info.business_website =
                  apiResponse.business_website);
            if (false){
              apiResponse.ftBusinessSocialMediaInformation.pivot === undefined
                  ? (this.socials.linkedin_url = "http://")
                  : (this.socials.linkedin_url =
                      apiResponse.ftBusinessSocialMediaInformation[3].pivot.social_media_link);
              apiResponse.ftBusinessSocialMediaInformation.pivot === undefined
                  ? (this.socials.facebook_url = "http://")
                  : (this.socials.facebook_url =
                      apiResponse.ftBusinessSocialMediaInformation[0].pivot.social_media_link);
              apiResponse.ftBusinessSocialMediaInformation.pivot === undefined
                  ? (this.socials.twitter_url = "http://")
                  : (this.socials.twitter_url =
                      apiResponse.ftBusinessSocialMediaInformation[1].pivot.social_media_link);
              apiResponse.ftBusinessSocialMediaInformation.pivot === undefined
                  ? (this.socials.instagram_url = "http://")
                  : (this.socials.instagram_url =
                      apiResponse.ftBusinessSocialMediaInformation[2].pivot.social_media_link);
              apiResponse.ftBusinessSocialMediaInformation.pivot === undefined
                  ? (this.socials.instagram_url = "http://")
                  : (this.socials.instagram_url =
                      apiResponse.ftBusinessSocialMediaInformation[2].pivot.social_media_link);

              this.socials.linkedin_url =
                  apiResponse.ftBusinessSocialMediaInformation[3].pivot.social_media_link;
              this.socials.facebook_url =
                  apiResponse.ftBusinessSocialMediaInformation[0].pivot.social_media_link;
              this.socials.twitter_url =
                  apiResponse.ftBusinessSocialMediaInformation[1].pivot.social_media_link;
              this.socials.instagram_url =
                  apiResponse.ftBusinessSocialMediaInformation[2].pivot.social_media_link;
            }

            apiResponse.fundingSourceDetails.virtual_card_no == null
              ? (this.showVirtualCard = false)
              : (this.showVirtualCard = true);

            this.business_info.company_name =
              apiResponse.apiCustomer.organization_name;
            this.business_info.rc_no = apiResponse.rc_number;
            this.business_info.cac_reg_no = apiResponse.cac_registration_number;
            this.business_info.business_type = apiResponse.businessType;
            this.business_info.annual_revenue = apiResponse.revenue;
            this.business_info.business_phone_no =
              apiResponse.business_phone_number;
            this.business_info.business_email = apiResponse.business_email;
            this.business_info.support_email = apiResponse.support_email;
            this.business_info.chargeback_email = apiResponse.chargeback_email;
            this.business_info.building_no = apiResponse.building_no;
            this.business_info.building_address = apiResponse.building_address;
            this.business_info.city = apiResponse.city;
            this.business_info.state = apiResponse.state;
            this.business_info.postal_code = apiResponse.postal_code;
            this.business_info.primary_contact_first_name =
              apiResponse.apiCustomer.primaryContact.firstname;
            this.business_info.primary_contact_last_name =
              apiResponse.apiCustomer.primaryContact.lastname;
            this.business_info.primary_contact_email =
              apiResponse.apiCustomer.primaryContact.email;
            this.business_info.primary_contact_phone_no =
              apiResponse.apiCustomer.primaryContact.phone_number;
            this.business_info.secondary_contact_first_name =
              apiResponse.secondaryContact.secondary_contact_firstname;
            this.business_info.secondary_contact_last_name =
              apiResponse.secondaryContact.secondary_contact_lastname;
            this.business_info.secondary_contact_designation =
              apiResponse.secondaryContact.secondary_contact_designation;
            this.business_info.secondary_contact_email =
              apiResponse.secondaryContact.secondary_contact_email;
            this.business_info.secondary_contact_mobile_telephone =
              apiResponse.secondaryContact.secondary_contact_mobile_telephone;
            this.business_info.secondary_contact_office_telephone =
              apiResponse.secondaryContact.secondary_contact_office_telephone;

            this.access_config.ip_address = apiResponse.whitelisted_ip;

            this.business_funding_source.funding_source_options =
              apiResponse.fundingSource.funding_source_label;
            this.business_funding_source.funding_source_acc_no =
              apiResponse.fundingSourceDetails.funding_source_account_no;
            this.business_funding_source.funding_source_bank_branch =
              apiResponse.fundingSourceDetails.funding_source_bank_branch;
            this.business_funding_source.funding_source_bank_code =
              apiResponse.fundingSourceDetails.bank;
            this.business_funding_source.virtual_card_no = this.formatNumber(
              apiResponse.fundingSourceDetails.virtual_card_no
            );

            console.log('set bizinfo', apiResponse)
            this.bizInfo = apiResponse;
            console.log(this.bizInfo)
          });
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getBilling() {
      try {
        await this.$store
          .dispatch("fundstransfer/getBilling", this.$route.params.id)
          .then(response => {
            console.log("getBilling:", response);
            this.Billing = response.data.result;
            this.billing_price = response.data.result;
          });
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getBankList() {
      try {
        await this.$store
          .dispatch("fundstransfer/getBankList")
          .then(response => {
            console.log("getBankList:", response);
            this.bankList = response.data.results[0];
          });
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async verifyAccountNumber() {
      Nprogress.start();
      let data = {
        account_number: this.business_funding_source.funding_source_acc_no,
        bank_code: this.business_funding_source.funding_source_bank_code
          .bank_code
      };
      try {
        await this.$store
          .dispatch("fundstransfer/verifyAccountNumber", data)
          .then(response => {
            this.verifyAccount = response.data.results[0];
            this.account_name = this.verifyAccount.account_name;
            this.accountVerification = true;
            this.accountValidated = true;
          });
        this.isLoading = false;
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        console.log(error);
      }
    },
    serializeRequestData(data) {
      let requestData = {
        clientID: this.$store.state.fundstransfer.businessInfo.apiCustomer
          .client_id,
        payload: data
      };
      console.log("request data ", requestData);
      return requestData;
    },
    async submitBusinessInformation() {
      Nprogress.start();
      this.submitting = true;
      let data = {
        cac_registration_number: this.business_info.cac_reg_no,
        business_type_id: this.business_info.business_type.id,
        annual_revenue_id: this.business_info.annual_revenue.id,
        business_phone_number: this.business_info.business_phone_no,
        rc_number: this.business_info.rc_no
      };
      let requestPayload = this.serializeRequestData(data);
      try {
        await this.$store.dispatch(
          "fundstransfer/submitBusinessInformation",
          requestPayload
        );
        this.showSuccessNotification(data.message);
        this.$swal({
          title: `Business Information Submitted`,
          text:
            "Your Business Information have been submitted for review by the administrator",
          icon: "success",
          button: "Ok"
        }).then(value => {
          // this.$refs.submitBtn.disabled = true;
        });
        console.log("[data from Details] ->", data);
        await this.getFTBusinessInfo();
        Nprogress.done();
        this.editBusinessInfo = false;
      } catch (error) {
        Nprogress.done();
        this.submitting = false;
        this.editBusinessInfo = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
    async submitBusinessContactInfo() {
      Nprogress.start();
      this.submitting = true;
      let data = {
        secondary_contact_firstname: this.business_info
          .secondary_contact_first_name,
        secondary_contact_lastname: this.business_info
          .secondary_contact_last_name,
        secondary_contact_designation: this.business_info
          .secondary_contact_designation,
        secondary_contact_office_telephone: this.business_info
          .secondary_contact_office_telephone,
        secondary_contact_mobile_telephone: this.business_info
          .secondary_contact_mobile_telephone,
        secondary_contact_email: this.business_info.secondary_contact_email
      };
      let requestPayload = this.serializeRequestData(data);
      try {
        await this.$store.dispatch(
          "fundstransfer/submitBusinessContactInfo",
          requestPayload
        );
        this.showSuccessNotification(data.message);
        this.$swal({
          title: `Business Contact Information Submitted`,
          text:
            "Your Business Contact Information has been submitted for review by the administrator",
          icon: "success",
          button: "Ok"
        }).then(value => {
          // this.$refs.submitBtn.disabled = true;
        });
        console.log("[data from Details] ->", data);
        await this.getFTBusinessInfo();
        Nprogress.done();
        this.editContactInfo = false;
      } catch (error) {
        Nprogress.done();
        this.submitting = false;
        this.editContactInfo = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error);
      }
    },
    async submitIP() {
      Nprogress.start();
      this.submitting = true;
      let data = {
        whitelisted_ip: this.access_config.ip_address
      };
      let requestPayload = this.serializeRequestData(data);
      try {
        await this.$store.dispatch(
          "fundstransfer/submitIPDetails",
          requestPayload
        );
        this.showSuccessNotification(data.message);
        this.$swal({
          title: `Access Configuration Submitted`,
          text:
            "Your Access Configuration has been submitted for review by the administrator",
          icon: "success",
          button: "Ok"
        }).then(value => {
          // this.$refs.submitBtn.disabled = true;
        });
        console.log("[data from Details] ->", data);
        await this.getFTBusinessInfo();
        Nprogress.done();
        this.editIP = false;
      } catch (error) {
        Nprogress.done();
        this.submitting = false;
        this.editIP = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
    async submitBusinessAddressInfo() {
      Nprogress.start();
      this.submitting = true;
      let data = {
        business_website: this.business_info.business_website,
        building_no: this.business_info.building_no,
        building_address: this.business_info.building_address,
        city: this.business_info.city,
        state_id: this.business_info.state.id,
        postal_code: this.business_info.postal_code
      };
      let requestPayload = this.serializeRequestData(data);
      try {
        await this.$store.dispatch("fundstransfer/Info", requestPayload);
        this.showSuccessNotification(data.message);
        this.$swal({
          title: `Business Address Information Submitted`,
          text:
            "Your Business Address Information has been submitted for review by the administrator",
          icon: "success",
          button: "Ok"
        }).then(value => {
          // this.$refs.submitBtn.disabled = true;
        });
        console.log("[data from Details] ->", data);
        await this.getFTBusinessInfo();
        Nprogress.done();
        this.editAddressInfo = false;
      } catch (error) {
        Nprogress.done();
        this.submitting = false;
        this.editAddressInfo = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
    async submitBusinessEmails() {
      Nprogress.start();
      this.submitting = true;
      let data = {
        business_email: this.business_info.business_email,
        support_email: this.business_info.support_email,
        chargeback_email: this.business_info.chargeback_email
      };
      let requestPayload = this.serializeRequestData(data);
      try {
        await this.$store.dispatch(
          "fundstransfer/submitBusinessEmails",
          requestPayload
        );
        this.showSuccessNotification(data.message);
        this.$swal({
          title: `Business Emails Submitted`,
          text:
            "Your Business Emails has been submitted for review by the administrator",
          icon: "success",
          button: "Ok"
        }).then(value => {
          // this.$refs.submitBtn.disabled = true;
        });
        console.log("[data from Details] ->", data);
        await this.getFTBusinessInfo();
        Nprogress.done();
        this.editEmails = false;
      } catch (error) {
        Nprogress.done();
        this.submitting = false;
        this.editEmails = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
    async submitBusinessSocials() {
      Nprogress.start();
      this.submitting = true;
      let data = {
        linkedin_url: this.socials.linkedin_url,
        facebook_url: this.socials.facebook_url,
        twitter_url: this.socials.twitter_url,
        instagram_url: this.socials.instagram_url
      };
      let requestPayload = this.serializeRequestData(data);
      try {
        await this.$store.dispatch(
          "fundstransfer/submitBusinessSocials",
          requestPayload
        );
        this.showSuccessNotification(data.message);
        this.$swal({
          title: `Business Social Links Submitted`,
          text:
            "Your Business Social Links ha been submitted for review by the administrator",
          icon: "success",
          button: "Ok"
        }).then(value => {
          // this.$refs.submitBtn.disabled = true;
        });
        console.log("[data from Details] ->", data);
        await this.getFTBusinessInfo();
        Nprogress.done();
        this.editSocialLinks = false;
      } catch (error) {
        Nprogress.done();
        this.submitting = false;
        this.editSocialLinks = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
    async submitFundingSource() {
      let increaseUploadPercentage = setTimeout(() => {
        this.setUploadProgress();
      }, 10);
      let uploadData = new FormData();
      uploadData.append("mandate_document_to_bank", this.mandate_doc);
      uploadData.append(
        "authorization_document_to_blusalt",
        this.auth_document
      );
      Nprogress.start();
      this.submitting = true;
      let funding_data = {
        funding_source_id: this.business_funding_source.funding_source_options
          .id,
        funding_source_bank_code: this.business_funding_source
          .funding_source_bank_code.bank_code,
        funding_source_bank_branch: this.business_funding_source
          .funding_source_bank_branch,
        funding_source_account_no: this.business_funding_source
          .funding_source_acc_no,
        virtual_card_no: this.business_funding_source.virtual_card_no
      };
      uploadData.append("funding_source_id", funding_data.funding_source_id);
      uploadData.append(
        "funding_source_bank_branch",
        funding_data.funding_source_bank_branch
      );
      uploadData.append(
        "funding_source_bank_code",
        funding_data.funding_source_bank_code
      );
      uploadData.append(
        "funding_source_account_no",
        funding_data.funding_source_account_no
      );
      uploadData.append("virtual_card_no", funding_data.virtual_card_no);
      console.log("uploadData->", uploadData);
      let requestPayload = this.serializeRequestData(uploadData);
      try {
        await this.$store
          .dispatch("fundstransfer/submitFundingSource", requestPayload)
          .then(res => {
            this.uploadProgress = 100;
            setTimeout(() => {
              this.showSuccessNotification(res.data.message);
              this.submitting = false;
              clearTimeout(increaseUploadPercentage);
              this.uploadProgress = 10;
              console.log("res:", res);
            }, 100);
          });
        this.$swal({
          title: `Funding Source Submitted`,
          text:
            "Successfully submitted your funding source and related documents",
          icon: "success",
          button: "Ok"
        }).then(value => {
          this.$refs.saveBtn.disabled = true;
        });
        console.log("[data from funding source] ->", uploadData);
        this.getFTBusinessInfo();
        this.showVirtualCard = true;
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.submitting = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
    async createThresholdBilling() {
      console.log("billing_price:", this.billing_price);

      Nprogress.start();
      this.submitting = true;
      let data = {
        transfer_rate: this.billing_price[0].transfer_rate,
        min_range: this.billing_price[0].min_range,
        max_range: this.billing_price[0].max_range
      };
      let requestPayload = {
        clientID: this.$store.state.fundstransfer.businessInfo.apiCustomer
          .client_id,
        id: this.$route.params.id,
        billing_id: this.ft_billing.id,
        payload: data
      };
      try {
        await this.$store.dispatch(
          "fundstransfer/createBilling",
          requestPayload
        );
        this.showSuccessNotification(data.message);
        this.$swal({
          title: `Billing Type Submitted`,
          text:
            "The Billing Type has been submitted for review by the administrator",
          icon: "success",
          button: "Ok"
        }).then(value => {
          // this.$refs.submitBtn.disabled = true;
        });
        console.log("[data from Details] ->", data);
        await this.getFTBusinessInfo();
        await this.getBilling();
        this.addForm();
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.submitting = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
    async createVolumeBilling() {
      Nprogress.start();
      this.submitting = true;
      let data = {
        transfer_rate: this.billing_rate
      };
      let requestPayload = {
        clientID: this.$store.state.fundstransfer.businessInfo.apiCustomer
          .client_id,
        id: this.$route.params.id,
        billing_id: this.ft_billing.id,
        payload: data
      };
      try {
        await this.$store.dispatch(
          "fundstransfer/createBilling",
          requestPayload
        );
        this.showSuccessNotification(data.message);
        this.$swal({
          title: `Billing Type Submitted`,
          text:
            "The Billing Type has been submitted for review by the administrator",
          icon: "success",
          button: "Ok"
        }).then(value => {
          // this.$refs.submitBtn.disabled = true;
        });
        console.log("[data from Details] ->", data);
        await this.getFTBusinessInfo();
        await this.getBilling();
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.submitting = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    }
  }
};
</script>


<style scoped>
.v-table.hidden-xs.VueTables.VueTables--client > .VueTables__search-field {
  display: none !important;
}
.v-select.vs__dropdown-toggle {
  padding: 2px 15px !important;
}
.v-table.hidden-xs.VueTables.VueTables--client .VuePagination nav {
  display: none !important;
}
.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator,
.vs--disabled .vs__search,
.vs--disabled .vs__selected {
  background-color: transparent !important;
}
.form-control:disabled {
  background-color: transparent !important;
}
.disabledInput {
  border: none;
  /* padding-left: 0; */
}
.disabled__edit {
  background-color: #e9ecef;
}
</style>
