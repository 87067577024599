import { apiClient } from "./api";

export default {
  RegisterCustomerApp(applicationDetails) {
    return apiClient.post(`Authorize/RegisterCustomerApp`, applicationDetails);
  },
  RegisterAppForCustomer(applicationDetails) {
    let headers = {
			clientid: applicationDetails.clientID
		}
    return apiClient.post(`Authorize/RegisterAppForCustomer`, applicationDetails.payload, {headers});
  },
  EditApplication(applicationDetails) {
    return apiClient.put(`Authorize/EditApplication/${applicationDetails.id}`, applicationDetails.requestPayload);
  },
  AdminEditApplication(applicationDetails) {
    let headers = {
			clientid: applicationDetails.requestPayload.clientID
		}
    return apiClient.put(
      `Authorize/AdminEditApplicationService/${applicationDetails.id}`,
      applicationDetails.requestPayload.payload,
      { headers }
    );
  },
  AdminToggleApplicationStatus(applicationDetails) {
    console.log('toggle triggered ', applicationDetails)
    let headers = {
			apiKey: applicationDetails.apiKey,
      appname: applicationDetails.appname
		}
    return apiClient.put(`Authorize/Admin/ActivateCustomerApp`, applicationDetails.payload, {headers});
  },
  RetrieveApiKey(appDetails) {
    return apiClient.post(`Authorize/RetrieveApiKey`, appDetails);
  },
  ReGenerateApiKey(appDetails) {
    return apiClient.post(`Authorize/ReGenerateApiKey`, appDetails);
  },
  GetApps() {
    return apiClient.get(`Authorize/GetApps`);
  },
  RetreiveClientId(appDetails) {
    return apiClient.post(`Authorize/RetreiveClientId`, appDetails);
  }
};
