<template>
  <div class="az-content-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="SectionItem no-b-b mb-3">
          <div class="SectionHeader">
            <span class="title">
              <router-link to="/logs/providers" class="nav-sub-link"
                ><img
                  src="/assets/img/icons/go-back.svg"
                  alt="image"
                  height="30"
              /></router-link>
              Provider Information</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="providerOverview && providerOverview.provider_name ">
      <div class="col-12 card p-4">
        <h1 class="merchant-title">
          {{ providerOverview.provider_name }}
        </h1>
        <div class="contact-details--card">
          <div class="contact-details--card-row">
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Provider Id
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                  {{ providerOverview.id }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                 
                </div>
              </span>
            </div>

            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated"></div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                 
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-12 col-xl-12">
        <nav class="nav az-nav-line nav-line-user-account">
          <a href="#overview" data-toggle="tab" class="nav-link active show"
            >Overview</a
          >
          <a href="#services" data-toggle="tab" class="nav-link">Services</a>
          <a href="#transaction" data-toggle="tab" class="nav-link">
            Call Logs</a
          >

          <!-- <a href="#integration" data-toggle="tab" class="nav-link"
            >Integration</a
          > -->
        </nav>
      </div>
    </div>
    <div class="tab-content pt-4">
      <div id="overview" class="row tab-pane active show ">
        <Overview :providerOverview="providerOverview" />
      </div>
      <div id="services" class="row tab-pane">
        <Services />
      </div>
      <div id="transaction" class="row tab-pane">
        <CallLogs :clientid="clientid" />
      </div>
      <!-- <div id="integration" class="row tab-pane">
        <Integration :clientid="clientid" />
      </div> -->
    </div>
  </div>
</template>

<script>
import Overview from "./Tabs/Overview.vue";
import CallLogs from "./Tabs/CallLogs.vue";
import Services from "./Tabs/Services.vue";
import { copyToClipboard } from "../../../../components/utils/helpers";
// import Integration from "./Tabs/Integration.vue";
import serviceProviders from "../../../../services/serviceProviders";

export default {
  name: "ProviderDetail",
  props: {
    clientid: {
      type: String,
    },
  },
  data() {
    return {
      providerOverview: null,
      merchantInformation: null,
    };
  },
  methods: {
    copyToClipboard,
    async fecthMerchantOverview() {
      try {
        this.loading = true;
        const response = await serviceProviders.GetProviderDetail({
          id: this.$route.params.id,
        });
        this.providerOverview = response.data.data.info;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.errorMsg = error.response.data.message;
      }
    },
  },
  mounted() {
    this.fecthMerchantOverview();
  },
  components: {
    Overview,
    CallLogs,
    // Integration,
    Services,
  },
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
.merchant-title {
  font-family: "AllianzNeo";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 27px;
  color: #071b2b;
}
</style>
