<template>
  <div>
    <button
      type="button"
      class="btn btn-lg btn-success mr-3"
      @click="openAddProviderModal"
    >
      Approve
    </button>
    <Modal
      id="approveVerification"
      title="Confirm Approval"
      :toggle="closeModal"
      :loading="loading"
    >
      <p>
        You are confirming that this user's details are correct and so user can
        make payout transactions!
      </p>
      <form @submit.prevent="approveUser">
        <FormButtons
          :loading="loading"
          :handleCloseModal="closeModal"
          :closeBtnName="`No, Don't Approve`"
          :btnName="`Yes, Approve User`"
        />
      </form>
    </Modal>
  </div>
</template>

<script>
import { notifications } from "../../../../public/assets/mixins/notifications";
import Modal from "../../../components/utils/Modal/Modal";
import FormButtons from "../../../components/utils/FormButtons/FormButtons";

import customer from "../../../services/customer";
export default {
  mixins: [notifications],
  name: "ApproveVerificationModal",
  data() {
    return {
      loading: false,
    };
  },
  props: ["merchantId"],
  methods: {
    openAddProviderModal() {
      $("#approveVerification").modal("show");
    },
    closeModal() {
      $("#approveVerification").modal("hide");
    },
    async approveUser() {
      this.loading = true;
      let { data } = await customer.ApprovePaymentMerchant({
        client_id: this.merchantId,
        approval: "approved",
      });
      this.loading = false;
      this.closeModal();
      this.showSuccessNotification("User Approved");
      this.$router.go(-1);
    },
  },
  components: {
    Modal,
	FormButtons
  },
};
</script>
