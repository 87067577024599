<template>
  <div>
    <div class="px-3">
      <FilterWrapper>
        <template slot="filterForm">
          <GenericFilter
            :loading="loading"
            :rLoading="rLoading"
            :submitFilter="refetch"
            :clearFilter="() => clearFilter('refundRef')"
          />
        </template>
      </FilterWrapper>
    </div>
    <TableWrapper
      :loading="loading"
      :errorMsg="errorMsg"
      :refetch="fetchWebHooks"
      :isEmpty="isEmpty"
      :emptyTitle="emptyTitle"
    >
      <div class="w-100 py-3" slot="content">
        <v-server-table
          class="v-table row-pointer"
          :data="customers"
          :columns="columns"
          :options="options"
          @loaded="onLoaded"
        >
          <template slot="createdAt" slot-scope="{ row }">
            {{ row.createdAt | moment(" MM-DD-YYYY h:mm a") }}
          </template>
        </v-server-table>
      </div>
    </TableWrapper>
  </div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../../../components/utils/TableWrapper/TableWrapper.vue";
import GenericFilter from "../../../../../components/filters/GenericFilter.vue";


const plansHeadings = {
  bank: "Bank Name",
  account_number: "Account Number",
  account_name: "Account Name",
  createdAt: 'Date Created'
};

export default {
  name: "SettlementAcount",
  mixins: [componentData],
  data() {
    return {
      singleInviteObj: null,
      showSingleInviteModal: false,
      emptyTitle: "No Account",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(plansHeadings, 25),
    };
  },
  components: {
    TableWrapper,
    GenericFilter
  },
  methods: {
    async fetchWebHooks() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters, reference: this.clientid };
        const response = await this.$store.dispatch(
          "customer/getSettingsInfo",
          {
            params,
          }
        );

        const newData = response.data.wallet.settlementAccounts;
        return { count: newData.length, total: newData.length, data: newData };
      };
    },
  },

  props: ["clientid"],
  created() {
    this.fetchWebHooks();
  },
  mounted() {
    this.columns = Object.keys(plansHeadings).map((keyVal) => keyVal);
  },
};
</script>
