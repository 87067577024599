<template>
  <div class="row">
    <div class="col-md-7">
      <apexchart type="bar" height="400" width="1200" :options="chartOptions" :series="series"></apexchart>
    </div>
    <div class="col-md-5">
      <apexchart height="300" type="donut" :options="chartOptions2" :series="series2"></apexchart>
    </div>
  </div>
</template>

<script>
import {
  toCurrency,
  formatNumber,
titleCase,
} from "../utils/helpers";
import CustomSelect from "../utils/CustomSelect/CustomSelect";

export default {
  name: "PaymentStatistics",
  data() {
    return {
      chartData: [],
      series: [],
      series2:[],
      chartOptions: {
        chart: {
          type: "bar",
          height: 430,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [],
        },
      },
      chartOptions2:null
    };
  },
  components: { CustomSelect },
  methods: {
    toCurrency,
    formatNumber,
    async fetchPaymentStatistics(filterBy) {
      const { data } = await this.$store.dispatch(
        "payment/getPaymentStatistics",
      );
      const xAxis = data.source.map(dt => titleCase(dt.source));
      const yAxis = data.source.map(dt => parseInt(dt.count));
      const xAxis2 = data.status.map(dt => titleCase(dt.status));
      const yAxis2 = data.status.map(dt => parseInt(dt.count));
      xAxis2.pop()
      yAxis2.pop()
      this.series2 = yAxis2;
      
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: { categories: xAxis },
      };
      this.chartOptions2 = {
        ...this.chartOptions2,
        labels: xAxis2,
      };
      this.series = [
        {
          data: yAxis,
        },
      ];
      this.loading = false;
    },
  },
  created() {
    this.fetchPaymentStatistics(this.filterBy);
  },
};
</script>

<style scoped></style>
