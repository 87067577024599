<template>
  <Modal
    :id="id"
    :title="'Add New Terminal'"
    :toggle="handleCloseModal"
    :loading="loading"
  >
    <SuccessState
      v-if="successMsg"
      :message="`Terminal(s) Added Successfully`"
      :onSuccess="handleRefetch"
    />

    <form v-else @submit.prevent="handleSubmit">
      <div class="form-group d-flex">
        <label class="rdiobox">
          <input
            name="billing"
            required
            type="radio"
            value="1"
            v-model="isBulk"
          />
          <span class="fs-15">Single Terminal</span>
        </label>
        <label class="rdiobox ml-3">
          <input
            name="billing"
            required
            type="radio"
            value="2"
            v-model="isBulk"
          />
          <span class="fs-15" >
            Bulk Terminal
          </span>
        </label>
      </div>
      <div v-if="isBulk==='2'">
        <CustomInput
          :name="'count'"
          v-model="count"
          :label="'No of Terminals'"
          :placeholder="'Enter number of terminals'"
          :required="true"
          :error="formErrors.count"
          :handleError="handleError"
          :isNum="true"
          :min="2"
          :type="'number'"
        />
      </div>
      <div v-else>
        
      </div>
      <FormButtons :loading="loading" :handleCloseModal="handleCloseModal" :btnName="isBulk === '2' ?'Generate Bulk ID' : 'Generate ID'" />
    </form>
  </Modal>
</template>

<script>
import { notifications } from "../../../../../../../public/assets/mixins/notifications";
import Modal from "../../../../../../components/utils/Modal/Modal";
import FormButtons from "../../../../../../components/utils/FormButtons/FormButtons";
import CustomInput from "../../../../../../components/utils/CustomInput/CustomInput";
import SuccessState from "../../../../../../components/utils/ResponseState/SuccessState";
import { formValidator } from "../../../../../../components/utils/FormValidator/FormValidator";
import Spinner from "../../../../../../components/utils/Spinner/Spinner";

const initialState = () => ({
  loading: false,
  successMsg: false,
  formErrors: {},
  isBulk: '1',
  count: ''
});

export default {
  mixins: [notifications],
  name: "AddTerminalModal",
  data() {
    return { ...initialState() };
  },
  props: ["id", "closeModal", "refetch", 'clientid'],
  components: {
    Modal,
    CustomInput,
    SuccessState,
    Spinner,
    FormButtons,
  },

  methods: {
    handleCloseModal() {
      Object.assign(this.$data, { ...initialState() });
      this.closeModal();
    },
    async handleRefetch() {
      this.handleCloseModal();
      this.refetch();
      this.setProviderData();
    },
    handleSubmit(e) {
      e.preventDefault();
      if (this.isBulk === '1') {
          this.createTerminal();
      } else {
        const { formErrors, isValid } = formValidator({
          count: this.count,
        });
        if (isValid)
          this.createBulkTerminal();
        else this.formErrors = formErrors;
      }
    },
    handleError(name) {
      this.formErrors = { ...this.formErrors, [name]: "" };
    },
    showError(msg) {
      this.loading = false;
      this.showErrorLoginNotification(msg);
    },
    async createTerminal() {
      try {
        this.loading = true;
        const response = await this.$store.dispatch(
          "payment/addTerminal",
          {
            params: {
              id: this.clientid,
            },
          }
        );
        if (response.status_code === 200) {
          this.loading = false;
          this.successMsg = true;
        } else this.showError(response?.message || "Error Adding Terminal");
      } catch (error) {
        this.showError(error.message);
      }
    },
    downloadableCSV(rows) {
      var content = "data:text/csv;charset=utf-8,";

      rows.forEach(function (row, index) {
        content += row.join(",") + "\n";
      });

      return encodeURI(content);
    },
    async createBulkTerminal() {
      try {
        this.loading = true;
        const response = await this.$store.dispatch(
          "payment/addBulkTerminal",
          {
            params: {
              id: this.clientid,
              count: this.count
            },
          }
        );
        const csvStream = response.data.terminalIds.map(dt => [dt])
        window.open(this.downloadableCSV(csvStream))
        if (response.status_code === 200) {
          this.loading = false;
          this.successMsg = true;
        } else this.showError(response?.message || "Error Adding Terminal");
      } catch (error) {
        this.showError(error.message);
      }
    },
    
    
  },
};
</script>
