<template>
  <div>
    <CollectionDetailModal
      :show="showCollectionModal"
      :toggle="toggleCollectionModal"
      :singleCollectionObj="singleCollectionObj"
      :refetch="refetch"
    />
    <div class="px-3">
      <FilterWrapper>
        <template slot="filterForm">
          <CollectionFilters
            :loading="loading"
            :rLoading="rLoading"
            :submitFilter="refetch"
            :clearFilter="() => clearFilter('collectionRef')"
          />
        </template>
      </FilterWrapper>
    </div>
    <TableWrapper
      :loading="loading"
      :errorMsg="errorMsg"
      :refetch="fetchCollections"
      :isEmpty="isEmpty"
      :emptyTitle="emptyTitle"
    >
      <div class="w-100 py-3" slot="content">
        <v-server-table
          ref="collectionRef"
          class="v-table row-pointer"
          :data="customers"
          :columns="columns"
          :options="options"
          @loaded="onLoaded"
          @row-click="handleClick"
        >
          <template slot="title" slot-scope="{ row }">
							<b>{{ titleCase(row.title) }}</b>
						</template>
						<template slot="createdAt" slot-scope="{ row }">
							<span>
								{{ timeDateFormat(row.createdAt).date }}
								{{ timeDateFormat(row.createdAt).time }}
							</span>
						</template>
						<template slot="amount" slot-scope="{ row }">
							{{ toCurrency(row.amount) }}
						</template>
						<template slot="page_type" slot-scope="{ row }">
							<span>
								{{ titleCase(row.page_type) }}
							</span>
						</template>
						<template slot="status" slot-scope="{ row }">
							<span :class="statusColor(row.status)">
								{{ titleCase(row.status) }}
							</span>
						</template>
        </v-server-table>
      </div>
    </TableWrapper>
  </div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../../../components/utils/TableWrapper/TableWrapper.vue";
import CollectionFilters from "../../../../../components/filters/CollectionFilters";
import CollectionDetailModal from "../../../../../views/Customers/modals/CollectionDetailModal.vue";
import { formatNumber } from "../../../../../components/utils/helpers";

const plansHeadings = {
  title: "Page Name",
  reference: "Reference",
  page_type: "Page Type",
  amount: "Amount",
  status: "Status",
  createdAt: "Date Created",
  action: "",
};

export default {
  name: "Collection",
  mixins: [componentData],
  data() {
    return {
      singleCollectionObj: null,
      showCollectionModal: false,
      emptyTitle: "No Collection Found",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(plansHeadings, 25),
    };
  },
  components: {
    TableWrapper,
    CollectionFilters,
    CollectionDetailModal,
  },
  methods: {
    toggleCollectionModal() {
      this.showCollectionModal = !this.showCollectionModal;
    },
    handleClick({ row }) {
      //   this.$router.push(`/customer/${row.client_id}`);
      this.singleCollectionObj = row;
      this.showCollectionModal = true;
    },
    formatNumber,
    refetch(filters) {
      let newFilters = { ...filters };
      if (newFilters.status) newFilters.status = newFilters.status.value;
      this.handleRefetch(newFilters, this.fetchCollections, "collectionRef");
    },
    async fetchCollections() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters, id: this.clientid };
        const response = await this.$store.dispatch(
          "customer/getPaymentMerchantCollections",
          {
            params,
          }
        );

        const newData = response.data.data;
        const total = response.data.total;
        return { ...response, count:total, total, data: newData };
      };
    },
  },

  props: ["clientid"],
  created() {
    this.fetchCollections();
  },
  mounted() {
    this.columns = Object.keys(plansHeadings).map((keyVal) => keyVal);
  },
};
</script>
