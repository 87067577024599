<template>
  <div class="row">
    <div class="col-12 mt-3">
      <div class="p-md-4 p-3">
        <div class="">
          <div class="SectionItem no-b-b mb-3 d-block">
            <p class="title">Reports</p>
            <h5 class="sub-title">View reports from all verification checks</h5>
          </div>
        </div>
      </div>
      <div class="px-3">
        <FilterWrapper>
          <template slot="filterForm">
            <ServiceReportFilters
              :loading="loading"
              :rLoading="rLoading"
              :submitFilter="refetch"
              :clearFilter="() => clearFilter('reportsRef')"
              :download="downloadLogs"
            />
          </template>
        </FilterWrapper>
      </div>
    </div>
    <div v-if="loading" class="col-12">
      <div class="flex__center my-5 py-5">
        <Spinner :size="'30px'" />
      </div>
    </div>

    <div v-else-if="errorMsg" class="col-12">
      <FailedState :errorMsg="errorMsg" :refetch="refetch" />
    </div>

    <div class="col-12 mx-4" :class="tbClass">
      <v-server-table
        ref="reportsRef"
        class="v-table row-pointer"
        :columns="columns"
        :options="options"
        @loading="setLoading(true)"
        @loaded="setLoading(false)"
        @row-click="handlClick"
      >
       <template slot="status" slot-scope="{ row }">
            <span
              :class="statusColor(row.status)"
            >
              {{ titleCase(row.status) }}
            </span>
          </template>
        <template slot="created_at" slot-scope="{ row }">
          {{ timeDateFormat(row.created_at).date }}
          {{ timeDateFormat(row.created_at).time }}
        </template>
      </v-server-table>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../../public/assets/mixins/componentData";
import ServiceReportFilters from "../../../components/filters/ServiceReportFilter.vue";

const tableHeadings = {
  service_name: "Service Name",
  service_type: "Service Type",
  verification_id: "ID Number",
  status: "Status",
  created_at: "Date",
  initiated_by: "Initiated By",
};

export default {
  mixins: [componentData],
  name: "ServiceReports",
  data() {
    return {
      loading: false,
      rLoading: false,
      showModal: false,
      modalData: null,
      errorMsg: "",
      size: 25,
      filters: {},
      ...this.tableOptions(tableHeadings, 20),
    };
  },
  components: {
    ServiceReportFilters,
  },
  computed: {
    tbClass() {
      return this.loading || this.errorMsg ? "hide__table" : "";
    },
  },
  methods: {
    handlClick({ row }) {
      if(row.service_name === 'Bulk Verification') {
        this.$router.push(
          `/logs/bulk-verification-logs/${row.verification_id}`
        );
      } else if (row.service_type === "NIN" || row.service_type === "NIN Phone" || row.service_type === "BVN") {
        this.$router.push(
          `/logs/verification-logs/${row.verification_id}/main_information`
          );
      } 
      else {
        this.$router.push(`/logs/verification-logs/${row.verification_id}/ownership`);
      }
    },
    refetch(filters) {
      this.handleRefetch(filters, this.getReports, "reportsRef");
    },
    async getReports() {
      this.options.requestFunction = async (data) => {
        const payload = {
          params: {
            ...data,
            size: this.size,
            ...this.filters,
          },
        };

        try {
          const resp = (
            await this.$store.dispatch("logs/getServiceReportLogs", payload)
          ).data;

          return { ...resp, count: resp.total };
        } catch (error) {
          this.loading = false;
          this.errorMsg = "Error fetching service reports";
        }
      };
    },
    async downloadLogs() {
      const download = (filterData) => {
        return this.downloadReport(tableHeadings, filterData.data);
      };

      this.downloadTableReport("logs/getServiceReportLogs", download);
    },
  },
  created() {
    this.getReports();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>


<style>
.text-amber {
  color: orange;
}
</style>
