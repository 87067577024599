<template>
  <div>
    <div class="px-3">
      <FilterWrapper>
        <template slot="filterForm">
          <CollectionFilters
            :loading="loading"
            :rLoading="rLoading"
            :submitFilter="refetch"
            :clearFilter="() => clearFilter('disputesRef')"
          />
        </template>
      </FilterWrapper>
    </div>
    <TableWrapper
      :loading="loading"
      :errorMsg="errorMsg"
      :refetch="fetchDisputes"
      :isEmpty="isEmpty"
      :emptyTitle="emptyTitle"
    >
      <div class="w-100 py-3" slot="content">
        <v-server-table
          ref="disputesRef"
          class="v-table"
          :data="customers"
          :columns="columns"
          :options="options"
          @loaded="onLoaded"
        >
          <template slot="createdAt" slot-scope="{ row }">
							<span>
								{{ timeDateFormat(row.createdAt).date }}
								{{ timeDateFormat(row.createdAt).time }}
							</span>
						</template>
						<template slot="amount" slot-scope="{ row }">
								{{ toCurrency(row.amount) }}
						</template>
						<template slot="status" slot-scope="{ row }">
							<span :class="statusColor(row.status)">
								{{ titleCase(row.status) }}
							</span>
						</template>
        </v-server-table>
      </div>
    </TableWrapper>
  </div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../../../components/utils/TableWrapper/TableWrapper.vue";
import CollectionFilters from "../../../../../components/filters/CollectionFilters";

const plansHeadings = {
  createdAt: "Date",
	TransactionId: "Transaction ID",
	amount: "Amount",
	description: "Description",
	reference: "Reference",
	status: "Status",
};

export default {
  name: "Disputes",
  mixins: [componentData],
  data() {
    return {
      singleInviteObj: null,
      showSingleInviteModal: false,
      emptyTitle: "No Dispute Found",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(plansHeadings, 25),
    };
  },
  components: {
    TableWrapper,
    CollectionFilters,
  },
  methods: {
    refetch(filters) {
      let newFilters = { ...filters };
      if (newFilters.status) newFilters.status = newFilters.status.value;
      console.log("object");
      this.handleRefetch(newFilters, this.fetchDisputes, "disputesRef");
    },
    async fetchDisputes() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters, id: this.clientid };
        const response = await this.$store.dispatch(
          "customer/getPaymentMerchantDisputes",
          {
            params,
          }
        );

        const newData = response.data.data;
        const count = response.data.count;
        return { ...response, count, total: count, data: newData };
      };
    },
  },

  props: ["clientid"],
  created() {
    this.fetchDisputes();
  },
  mounted() {
    this.columns = Object.keys(plansHeadings).map((keyVal) => keyVal);
  },
};
</script>
