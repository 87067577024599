<template>
  <div class="col-md-12 col-lg-12">
    <div class="default-ImageEmpty">
      <img :src="`/assets/img/${image}`" alt style="width: 300px;" />
      <h3 class="defaultTitle">{{title}}</h3>
      <p>{{subtitle}}</p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    }
  }
}
</script>

<style>
</style>