<template>
    <div>
        <div class="contact-details--card border-0 m-0 shadow-sm rounded pointer px-4" v-for="(verification, i) in details"
            :key="i" @click="handleClick(verification.verification_id)">
            <div class="flex__between">
                <p class="font-weight-bold">{{ verification.type.name }}</p>
                <p class="font-weight-bold" :class="statusColor(verification?.status)">{{
                    verification?.status }}</p>
            </div>
            <div class="contact-details--card-row">
                <div class="contact-details--card-field">
                    <span class="field-key">
                        <div class="contact-details--truncated text-uppercase">
                            {{ verification.type?.abbr }}
                        </div>
                    </span>
                    <span class="field-value">
                        <div class="contact-details--truncated">
                            {{ verification.identity_number }}
                        </div>
                    </span>
                </div>
                <div class="contact-details--card-field">
                    <span class="field-key">
                        <div class="contact-details--truncated text-uppercase">Verification ID</div>
                    </span>
                    <span class="field-value">
                        <div class="contact-details--truncated pointer">
                            {{ verification.verification_id }}
                        </div>
                    </span>
                </div>
                <div class="contact-details--card-field">
                    <span class="field-key">
                        <div class="contact-details--truncated text-uppercase">Date Requested</div>
                    </span>
                    <span class="field-value">
                        <div class="contact-details--truncated pointer">
                            {{ verification?.created_at }}
                        </div>
                    </span>
                </div>
                <div class="contact-details--card-field">
                   
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import { componentData } from "../../../public/assets/mixins/componentData";
import { businessIdentityMap } from '../../components/utils/helpers/index'


export default {
    name: "BusinessIdentityVerification",
    mixins: [componentData],
    data() {
        return {
            emptyTitle: "No Verification Found",
            loading: true,
            errorMsg: "",
            details: null,
        };
    },
    components: {
    },
    methods: {
        handleClick(id) {
            this.$router.push(`/logs/verification-logs/${id}/ownership`);
        },
        refetch(filters) {
            this.handleRefetch(filters, this.fetchSmartId, "smartRef");
        },
        transformed(data) {
            return Object.entries(data).map(
                ([verificationType, verificationValue]) => ({
                    identity_number:
                        verificationValue?.registration_number,
                    ...verificationValue,
                    type: businessIdentityMap[verificationType],
                })
            );
        },
        async fetchSmartId() {
            const response = await this.$store.dispatch(
                "logs/getSmartIDBusinessesVerificationDetails",
                {
                    smartId: this.$route.params.smart_id,
                }
            );
            this.details = this.transformed(response.data);
            this.loading = false;
        },
    },
    created() {
        this.fetchSmartId();
    },
};
</script>
<!-- rebuild -->