<template>
	<div>
		<img
			@click="openEditSubPlan"
			class="pointer"
			src="/assets/img/edit.png"
			alt="update tax"
			width="25px"
		/>
		<EditPricingModal
			:id="sub.id"
			:closeModal="closeModal"
			:sub="sub"
			:refetch="refetch"
		/>
	</div>
</template>

<script>
	import EditPricingModal from "../modals/EditPricingModal";

	export default {
		name: "EditPricingButton",
		props: ["sub", 'refetch'],
		methods: {
			openEditSubPlan() {
				$(`#${this.sub.id}`).modal("show");
			},
			closeModal() {
				$(`#${this.sub.id}`).modal("hide");
			},
		},
		components: {
			EditPricingModal,
		},
	};
</script>
