import application from "@/services/application";
import { apiClient } from "@/services/api";

export default {
  namespaced: true,
  state: {
    apps: []
  },
  mutations: {
    SET_APPS(state, apps) {
      state.apps = apps;
    }
  },
  actions: {
    async getApplications({ commit }) {
      let apps;
      try {
        let { data } = await application.GetApps();
        console.log("[data from get applications] ->", data.results);
        apps = data.results;
        commit("SET_APPS", apps);
        return apps;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async createApplication({ commit }, appDetails) {
      try {
        let { data } = await application.RegisterCustomerApp(appDetails);
        return data.results;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async createApplicationForCustomer({ commit }, appDetails) {
      try {
        let { data } = await application.RegisterAppForCustomer(appDetails);
        return data.results;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async editApplication({ commit }, appDetails) {
      try {
        let { data } = await application.EditApplication(appDetails);
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async adminEditApplication({ commit }, appDetails) {
      try {
        let { data } = await application.AdminEditApplication(appDetails);
        return data;
      } catch (error) {
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    },
    async adminToggleApplication({ commit }, appDetails) {
      try {
        let { data } = await application.AdminToggleApplicationStatus(appDetails);
        return data;
      } catch (error) {
        console.log('error ', error.response)
        console.error(error.response.data.message);
        throw Error(error.response.data.message);
      }
    }
  },
  getters: {}
};
