<template>
	<Modal
		:id="id"
		title="Edit Pricing"
		:toggle="handleCloseModal"
		:loading="loading"
	>
		<form  @submit.prevent="handleSubmit">
			
			<CustomSelect
				name="chargingType"
				:label="'Charging Type'"
				placeholder="Select Type"
				:optionLabel="'name'"
				:error="formErrors.chargingType"
				:handleError="handleError"
				:options="chargingCycle"
				v-model="chargingType"
			/>
			<CustomInput
				v-if="chargingType === 'percentage'"
	          :name="'percentageCost'"
			  :label="'Percentage Cost'"
	          :placeholder="'Enter Percentage Cost'"
	          v-model="percentageCost"
				:error="formErrors.percentageCost"
	          :handleError="handleError"
	        />
			<CustomInput
				v-else
	          :name="'flatCost'"
			  :label="'Flat Cost'"
	          :placeholder="'Enter Flat Cost'"
	          v-model="flatCost"
			  :type="'number'"
				:error="formErrors.flatCost"
	          :handleError="handleError"
	        />
			<CustomInput
	          :name="'cap'"
			  :label="'Cap'"
	          :placeholder="'Enter Cap'"
	          v-model="cap"
			  :type="'number'"
			  :error="formErrors.cap"
	          :handleError="handleError"
	        />
			<FormButtons
				:loading="loading"
				:handleCloseModal="handleCloseModal"
			/>
		</form>
	</Modal>
</template>

<script>
	import { notifications } from "../../../../../../../public/assets/mixins/notifications";
	import Modal from "../../../../../../components/utils/Modal/Modal";
	import FormButtons from "../../../../../../components/utils/FormButtons/FormButtons";
	import CustomInput from "../../../../../../components/utils/CustomInput/CustomInput";
	import CustomSelect from "../../../../../../components/utils/CustomSelect/CustomSelect";
	import SuccessState from "../../../../../../components/utils/ResponseState/SuccessState";
	import { formValidator } from "../../../../../../components/utils/FormValidator/FormValidator";
	import Spinner from "../../../../../../components/utils/Spinner/Spinner";
	import SubService from '../../../../../../views/Customers/modals/AccountServiceEditModal/SubService'

	const initialState = () => ({
		chargingType: "",
		percentageCost: "",
		flatCost: "",
		cap: "",
		loading: false,
		successMsg: false,
		formErrors: {},
	});
	const chargingCycle = [
		{
			name: "Percentage",
			value: "percentage",
		},
		{
			name: "Flat",
			value: "flat",
		},
	];
	export default {
		mixins: [notifications],
		name: "EditPricingModal",
		data() {
			return { ...initialState(), chargingCycle };
		},
		props: ["id", "closeModal", "refetch", 'sub'],
		components: {
			Modal,
			CustomInput,
			CustomSelect,
			SuccessState,
			Spinner,
			SubService,
			FormButtons,
		},
		computed: {
			getApiServices() {
				return this.$store.state.metaData.apiServiceCategories;
			},
		},
		methods: {
			handleCloseModal() {
				// Object.assign(this.$data, { ...initialState() });
				// setTimeout(() => {
				// 	window.location.reload()
				// },2000 )
				this.closeModal();
			},
			handleSubmit(e) {
				e.preventDefault();
				const payload = {
					chargingType: this.chargingType,
					percentageCost: this.percentageCost,
					flatCost: this.flatCost,
				}

				if (this.chargingType === 'percentage' || this.chargingType.value === 'percentage') delete payload.flatCost
				if (this.chargingType === 'flat' || this.chargingType.value === 'flat') delete payload.percentageCost
				const { formErrors, isValid } = formValidator(
					payload
				);
				if (isValid) {
					this.updatePricing()
				}
				else this.formErrors = formErrors;
			},
			handleError(name) {
				this.formErrors = { ...this.formErrors, [name]: "" };
			},
			async updatePricing() {
				try {
					this.loading = true;
						const payload = {
						cap: this.cap,
						chargingType: this.chargingType.value ?? this.chargingType,
						percentageCost: this.percentageCost,
						flatCost: this.flatCost,
					}

					if (this.chargingType === 'percentage' || this.chargingType.value === 'percentage') delete payload.flatCost
					if (this.chargingType === 'flat' || this.chargingType.value === 'flat') delete payload.percentageCost

					if (!this.cap) delete payload.cap
					let response = await this.$store.dispatch(
						"customer/updatePaymentMerchantPaymentPricing",{ payload,  merchantId: this.$route.params.clientid, id: this.sub.id, }
					);

					if (response.status_code === 200) {
						this.refetch()
						this.showSuccessNotification('Pricing Updated Successfully');
						this.handleCloseModal()
						this.loading = false
					} else {
						this.showErrorLoginNotification('Unable to update pricing');
						this.loading = false
					}
					
				} catch (error) {
					console.log("[error] ->", error);
				}
			},
			
		},
		mounted() {
			this.chargingType = this.sub.chargingType;
			this.percentageCost = this.sub.percentageCost;
			this.flatCost = this.sub.flatCost;
			this.cap = this.sub.cap;
		},
	};
</script>
