import moment from "moment";

export function isPhoneValid(params = "") {
  return async function(value) {
    let v = (await this).$v;

    const fields = params.split(".");

    fields.forEach((param) => {
      if (v && v[param]) v = v[param];
    });

    if (v && v.required && !value.normal) {
      return false;
    }

    if (value.normal) {
      return value && value.valid && value.valid == true ? true : false;
    }

    return true;
  };
}

export const formVuelidator = (v) => {
  let msg = null;

  const params = Object.keys(v.$params);

  for (let i = 0; i < params.length; i++) {
    const field = params[i];

    if (field == "required" && !v[field]) {
      msg = "This field cannot be empty";
      break;
    }

    if (field == "numeric" && !v[field]) {
      msg = "This field must contain only numbers";
      break;
    }

    if (field == "alpha" && !v[field]) {
      msg = "This field must contain only letters";
      break;
    }

    if (field == "alphaNum" && !v[field]) {
      msg = "This field must contain only letters & numbers";
      break;
    }

    if (field == "email" && !v[field]) {
      msg = "This field must contain a valid email";
      break;
    }

    if (field == "isPhoneValid" && !v[field]) {
      msg = "This field must contain a valid phone number";
      break;
    }

    if (field == "minLength" && !v[field]) {
      msg = `This field must have a minimum length of ${v.$params[field].min}`;
      break;
    }

    if (field == "maxLength" && !v[field]) {
      msg = `This field must have a maximum length of ${v.$params[field].max}`;
      break;
    }

    if (field == "minValue" && !v[field]) {
      msg = `This field must have a minimum value of ${v.$params[field].min}`;
      break;
    }

    if (field == "maxValue" && !v[field]) {
      msg = `This field must have a maximum value of ${v.$params[field].max}`;
      break;
    }

    if (field == "isDate" && !v[field]) {
      msg = `This field must be a valid date`;
      break;
    }
  }

  return msg;
};

export const isDate = (value) => {
  return moment.isDate(value);
};
