var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('CustomSelect',{attrs:{"name":'type',"label":'Type',"placeholder":'Choose Statement Section',"optionLabel":'label',"options":[
            // {
            //   label: 'Transactions',
            //   value: 'transactions',
            //   extra: 'Payment Transactions',
            // },
            { label: 'Invoices', value: 'invoices' },
            { label: 'Wallet Transactions', value: 'wallet' },
          ],"error":_vm.$v.request.type.$error && _vm.formVuelidator(_vm.$v.request.type),"required":false},on:{"input":_vm.handleReset},model:{value:(_vm.request.type),callback:function ($$v) {_vm.$set(_vm.request, "type", $$v)},expression:"request.type"}})],1),_c('div',{staticClass:"col-md-4 position-relative"},[_c('CustomInput',{attrs:{"name":'durations',"value":_vm.range,"otherCls":'pointer',"label":'Durations',"placeholder":'Choose Date',"readonly":true,"error":_vm.$v.request.durations.$error &&
              _vm.formVuelidator(_vm.$v.request.durations),"icon":'/assets/img/icons/calender.svg'},nativeOn:{"click":function($event){return _vm.handleShowDate.apply(null, arguments)}}}),(_vm.showDate)?_c('DateRangePicker',{attrs:{"dates":_vm.request.durations},on:{"reset":_vm.handleReset,"close":function($event){_vm.showDate = false}}}):_vm._e()],1),_c('div',{staticClass:"col-md-6"},[_c('GenerateStatementBtn',{attrs:{"success":_vm.success,"loading":_vm.loading,"transactions":_vm.transactions,"dates":_vm.request.durations,"params":_vm.params},on:{"refresh":_vm.handleRefresh,"generate":_vm.handleGenerate}})],1),(_vm.show)?_c('div',{staticClass:"mt-4 col-12"},[_c('AccountStatementTable',{attrs:{"params":_vm.params},on:{"loading":_vm.handleLoading,"error":_vm.handleError}})],1):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"input-item-label"},[_vm._v(" Generate Account Statement ")])])
}]

export { render, staticRenderFns }