<template>
	<Modal
		:id="id"
		title="Update Subscription Plan"
		:toggle="handleCloseModal"
		:loading="loading"
	>
		<form  @submit.prevent="handleSubmit">
			
			<CustomInput
	          :name="'plan_name'"
			  :label="'Plan Name'"
	          :placeholder="'Enter Subscription Plan Name'"
	          v-model="plan_name"
				:error="formErrors.plan_name"
	          :handleError="handleError"
	        />
			<CustomInput
	          :name="'price'"
			  :label="'Plan Price'"
	          :placeholder="'Enter Plan Price'"
	          v-model="price"
			  :type="'number'"
			  :error="formErrors.price"
	          :handleError="handleError"
	        />
			<CustomInput
	          :name="'api_call_limit'"
			  :label="'API Call Limit'"
	          :placeholder="'Enter number'"
	          v-model="api_call_limit"
			   :type="'number'"
			  :error="formErrors.api_call_limit"
	          :handleError="handleError"
	        />
			<CustomSelect
				name="cycle"
				:label="'Plan Type'"
				placeholder="Select Type"
				:optionLabel="'name'"
				:error="formErrors.type"
				:handleError="handleError"
				:options="subscriptionCycle"
				v-model="cycle"
			/>
			<div v-for="(service, key) in serviceCategories" :key="key">
				<SubService
				:service="service"
				:handleSelectService="handleSelectService"
				:activeServices="activeServices"
				/>
			</div>
			<FormButtons
				:loading="loading"
				:handleCloseModal="handleCloseModal"
			/>
		</form>
	</Modal>
</template>

<script>
	import { notifications } from "../../../../public/assets/mixins/notifications";
	import Modal from "../../../components/utils/Modal/Modal";
	import FormButtons from "../../../components/utils/FormButtons/FormButtons";
	import CustomInput from "../../../components/utils/CustomInput/CustomInput";
	import CustomSelect from "../../../components/utils/CustomSelect/CustomSelect";
	import SuccessState from "../../../components/utils/ResponseState/SuccessState";
	import { formValidator } from "../../../components/utils/FormValidator/FormValidator";
	import Spinner from "../../../components/utils/Spinner/Spinner";
	import SubService from '../../../views/Customers/modals/AccountServiceEditModal/SubService'

	const initialState = () => ({
		plan_name: "",
		api_call_limit: "",
		price: "",
		cycle: "",
		serviceCategories: [],
		activeServices: [],
		loading: false,
		successMsg: false,
		formErrors: {},
	});
	const subscriptionCycle = [
		{
			name: "Weekly",
			value: "weekly",
		},
		{
			name: "Monthly",
			value: "monthly",
		},
		{
			name: "Yearly",
			value: "yearly",
		},
	];
	export default {
		mixins: [notifications],
		name: "EditSubscriptionPlanModal",
		data() {
			return { ...initialState(), subscriptionCycle };
		},
		props: ["id", "closeModal", "refetch", 'sub'],
		components: {
			Modal,
			CustomInput,
			CustomSelect,
			SuccessState,
			Spinner,
			SubService,
			FormButtons,
		},
		computed: {
			getApiServices() {
				return this.$store.state.metaData.apiServiceCategories;
			},
		},
		methods: {
			handleCloseModal() {
				this.refetch({});
				// Object.assign(this.$data, { ...initialState() });
				this.closeModal();
			},
			handleSubmit(e) {
				e.preventDefault();
				const { formErrors, isValid } = formValidator(
					{
						plan_name: this.plan_name,
						price: this.price,
						api_call_limit: this.api_call_limit,
						cycle: this.cycle,
					}
				);
				if (isValid) {
					this.addSubscriptionPlan()
				}
				else this.formErrors = formErrors;
			},
			handleError(name) {
				this.formErrors = { ...this.formErrors, [name]: "" };
			},
			showError(msg) {
				this.loading = false;
				this.showErrorLoginNotification(msg);
			},
			handleSelectService(service, single = true, add = true) {
				if (single) {
					if (this.activeServices.includes(service)) {
						let tempService = this.activeServices.filter(
							(data) => data !== service
						);
						this.activeServices = tempService;
					} else {
						this.activeServices = [...this.activeServices, service];
					}
				} else {
					if (add) {
						this.activeServices = [
							...new Set([
								...this.activeServices,
								...service.apiServices.map((dt) => dt.id),
							]),
						];
					} else {
						this.activeServices = this.activeServices.filter(
							(el) => !service.apiServices.map((dt) => dt.id).includes(el)
						);
					}
				}
			},
			async addSubscriptionPlan() {
				try {
					this.loading = true;
					const payload = {
						plan_name: this.plan_name,
						price: this.price,
						api_call_limit: this.api_call_limit,
						cycle: this.cycle?.value ?? this.cycle,
						service_ids: this.activeServices
					}

					let response = await this.$store.dispatch(
						"services/updateSubscriptionPlan",{ payload, id: this.sub.id }
					);
					
					if (response.status_code === 200) {
						this.showSuccessNotification('Subscription Plan Updated Successfully');
						this.handleCloseModal()
						this.loading = false
					} else {
						this.showErrorLoginNotification('Unable to update subscription plan');
						this.loading = false
					}
					
				} catch (error) {
					console.log("[error] ->", error);
				}
			},
			async fetchServiceCategories() {
				try {
					let response = await this.$store.dispatch(
						"services/getServiceCategoriesWithServices",
					);

					this.serviceCategories = response.data;
				} catch (error) {
					console.log("[error] ->", error);
				}
			},
			
		},
		
		watch: {
			sub: function (newVal) {
				this.plan_name = newVal.plan_name;
				this.price = newVal.price;
				this.api_call_limit = newVal.api_call_limit;
				this.cycle = newVal.cycle;
				this.activeServices = JSON.parse(newVal.service_ids);
			}
		},
		mounted() {
			this.plan_name = this.sub.plan_name;
			this.price = this.sub.price;
			this.api_call_limit = this.sub.api_call_limit;
			this.cycle = this.sub.cycle;
			this.activeServices = JSON.parse(this.sub.service_ids);
			this.fetchServiceCategories();
		},
	};
</script>
