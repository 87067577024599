<template>
  <div class="az-content-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="SectionItem no-b-b mb-3">
          <div class="SectionHeader">
            <span class="title">
              <router-link to="/payment-providers" class="nav-sub-link"><img src="/assets/img/icons/go-back.svg"
                  alt="image" height="30" /></router-link>
              Provider Information</span>
          </div>
          <!-- <div class="content">
            <router-link to="/customers" class="btn btn-lg btn-outline-danger">
              <i class="la la-arrow-left"></i> Back
            </router-link>
          </div> -->
        </div>
      </div>
    </div>
    <Spinner v-if="loading" />
    <div class="row" v-else>
      <div class="col-12 card p-4">
        <h1 class="merchant-title">
          <!-- <span class="mr-4"
            ><img height="35" :src="merchantInformation.logo" alt=""/></span
          > -->
          {{ providerOverview.provider.name }}
        </h1>
        <div class="contact-details--card">
          <div class="contact-details--card-row">
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Provider Id
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                  {{ providerOverview.provider.id }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Email Address
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{ providerOverview.provider.email || "N/A" }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Phone Number
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{ providerOverview.provider.phoneNumber || "Null" }}
                </div>
              </span>
            </div>

            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">Date Created</div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                  {{
                  providerOverview.provider.createdAt | moment(" MM-DD-YYYY h:mm a")
                  }}
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-12 col-xl-12">
        <nav class="nav az-nav-line nav-line-user-account">
          <a href="#overview" data-toggle="tab" class="nav-link active show">Overview</a>
          <a href="#transaction" data-toggle="tab" class="nav-link">
            Transactions</a>
        </nav>
      </div>
    </div>
    <div class="tab-content pt-4" v-if="!loading">
      <div id="overview" class="row tab-pane active show ">
        <Overview :providerOverview="providerOverview.transaction" />
      </div>
      <div id="transaction" class="row tab-pane">
        <Transactions :clientid="clientid" />
      </div>
    </div>
  </div>
</template>

<script>
import Overview from "./Tabs/Overview.vue";
import Transactions from "./Tabs/Transactions.vue";
import { copyToClipboard } from "../../../components/utils/helpers";

export default {
  name: "PaymentProviderDetail",
  props: {
    clientid: {
      type: String,
    },
  },
  data() {
    return {
      providerOverview: null,
      loading: null,
      merchantInformation: null,
    };
  },
  methods: {
    copyToClipboard,
    async fecthMerchantOverview() {
      try {
        this.loading = true;
        const response = await this.$store.dispatch(
          "payment/getPaymentProvidersDetails",
          {
            id: this.$route.params.id
          }
        );
        this.providerOverview = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.errorMsg = error.response.data.message;
      }
    },
  },
  mounted() {
    this.fecthMerchantOverview();
  },
  components: {
    Overview,
    Transactions,
  },
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
.merchant-title {
  font-family: "AllianzNeo";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 27px;
  color: #071b2b;
}
</style>
