<template>
  <div
    id="elBody"
    class="az-body az-body-sidebar az-body-dashboard-nine"
    v-show="isLoading == false"
  >
    <TheSidebar />
    <div class="az-content az-content-dashboard-nine">
      <TheTopBar />
      <transition mode="out-in" name="slide" v-if="isLoading == false">
        <router-view></router-view>
      </transition>
      <TheFooter />
    </div>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar";
import TheTopBar from "./TheTopBar";
import TheFooter from "./TheFooter";
import { notifications } from "../../../public/assets/mixins/notifications";
export default {
  mixins: [notifications],
  components: {
    TheSidebar,
    TheTopBar,
    TheFooter,
  },
  data() {
    return {
      isLoading: true,
      IDLE_TIMEOUT: 600, //10 mintutes after an idle screen
      idlesecondsTimer: 0,
      idleSecondsCounter: 0,
    };
  },
  async mounted() {
    // idle timer function
    document.onclick = () => {
      this.idleSecondsCounter = 0;
    };
    document.onmousemove = () => {
      this.idleSecondsCounter = 0;
    };
    document.onkeypress = () => {
      this.idleSecondsCounter = 0;
    };

    this.idlesecondsTimer = window.setInterval(this.CheckIdleTime, 1000);
    try {
      let apiResponse = await this.$store.dispatch("account/getProfile");
      await this.$store.dispatch("getMetaData");
      this.user = apiResponse;
      this.isLoading = false;
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    CheckIdleTime() {
      this.idleSecondsCounter++;
      var oPanel = this.IDLE_TIMEOUT - this.idleSecondsCounter;
      if (this.idleSecondsCounter >= this.IDLE_TIMEOUT) {
        this.showErrorNotification("You've been logged out");
        this.$store.dispatch("account/logout");
        window.clearInterval(this.idleSecondsTimer);
      }
    },
  },
};
</script>
