import others from "@/services/others";

export default {
	namespaced: true,
	state: {},
	mutations: {},
	actions: {
		async getAllCountries() {
			try {
				let response = await others.GetAllCountries();
				return response;
			} catch (error) {
				throw Error(error.response.data.message);
			}
		},
	},
	getters: {},
};
