<template>
	<div class="card">
        <div class="row">
            <div v-if="loading" class="col-12">
                <div class="flex__center my-5 py-5">
                    <Spinner :size="'30px'" />
                </div>
            </div>

            <div v-else-if="errorMsg" class="col-12">
                <FailedState :errorMsg="errorMsg" :refetch="refetch" />
            </div>

            <div v-else-if="isEmpty" class="col-12 ">
				<EmptyData 
					:title="emptyTitle" 
					:text="emptyText"
					
				>
					<slot name="empty"  />
				</EmptyData>
			</div>

            <div class="col-12" :class="tbClass">
                <slot name="content"  />
            </div>
	    </div>
    </div>
</template>

<script>
    import Spinner from "../Spinner/Spinner";
    import FailedState from "../ResponseState/FailedState";
    import EmptyData from "../EmptyData/EmptyData"

	export default {
		name: "TableWrapper",
        props: ["loading", "errorMsg", "isEmpty", "emptyText", "emptyTitle", "refetch" ],
        components: {
            Spinner,
            FailedState,
            EmptyData
        },
        computed: {
            tbClass() {
                return this.loading || this.errorMsg || this.isEmpty ? "hide__table" : "";
            },
        },
	};
</script>


