<template>
  <div>
  <div v-if="TerminalMerchantId">
     <TerminalTable :clientid="clientid" :TerminalMerchantId="TerminalMerchantId" />
  </div>
  <div v-else class=" py-4">
    <h3 class="text-center">Merchant is currently not a Terminal Merchant</h3>
    <br>
    <p class="text-center">Click here to activate</p>
   <div class="flex__center">
     <div v-if='loading'><Spinner/></div>
     <button v-else class="btn btn-primary" @click="activatePaymentMerchant">Activate Terminal Merchant</button>
   </div>
  </div>
  </div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import { notifications } from '../../../../../../public/assets/mixins/notifications'
import TerminalTable from './TerminalTable.vue'


export default {
  name: "Terminals",
  mixins: [componentData, notifications],
  data() {
    return {
      TerminalMerchantId: '', 
      loading: false
    };
  },
  components: {
    TerminalTable
  },
  methods: {
    refetch() {
      
    },
    async verifyPaymentMerchant() {
       const params = { id: this.clientid };
        const response = await this.$store.dispatch(
          "payment/verifyTerminalMerchant",
          {
            params,
          }
        );
        if (response.data.TerminalMerchantId) {
          this.TerminalMerchantId = response.data.TerminalMerchantId
        }
    },
    async activatePaymentMerchant() {
      const params = { id: this.clientid };
      this.loading = true;
        try {
          const response = await this.$store.dispatch(
          "payment/activateTerminalMerchant",
          {
            params,
          }
        );
          if (response.data.action) {
          this.showSuccessNotification('Merchant Activated Successfully')
          this.verifyPaymentMerchant()
        }
        this.loading = false;
        } catch (error) {
          console.log(error)
           this.showErrorLoginNotification('Unable to Activate Merchant')
          this.loading = false;
        }
    },
  },
  props: ["clientid"],
  created() {
    this.verifyPaymentMerchant();
  },
};
</script>
