import logs from "@/services/logs";

export default {
  namespaced: true,
  state: {
    apps: [],
    reportDetails: null,
  },
  mutations: {
    SET_REPORT_DETAILS(state, details) {
      state.reportDetails = details;
    },
  },
  actions: {
    async getCreditLogs({ commit }) {
      try {
        let { data } = await logs.CreditHistory();
        console.log("[credit history] ->", data);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getAdminLogs({ commit }, requestData) {
      try {
        let { data } = await logs.getAdminLogs(requestData);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getAuditTrail({ commit }, requestData) {
      try {
        let { data } = await logs.getAuditTrail(requestData);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getAllWhiteLabelRequests() {
      try {
        let { data } = await logs.getAllWhiteLabelRequests();
        return data;
      } catch (error) {}
    },
    async getCreditHistoryOverview({ commit }, query) {
      try {
        let { data } = await logs.GetCreditHistoryOverview(query);
        console.log("[data from get customers] ->", data.result);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getSMSLogs({ commit }, query) {
      try {
        let { data } = await logs.GetSMSLogs(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getServiceReportLogs({ commit }, query) {
      try {
        let { data } = await logs.GetServiceReportLogs(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getBusinessRegistrationLogs({ commit }, query) {
      try {
        let { data } = await logs.GetBusinessRegistrationLogs(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getBusinessRegistrationRefreshLogs({ commit }, query) {
      try {
        let { data } = await logs.GetBusinessRegistrationRefreshLogs(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getBusinessRegistrationLogDetails({ commit }, query) {
      try {
        let { data } = await logs.GetBusinessRegistrationLogDetails(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getServiceReportLogDetails({ commit }, query) {
      try {
        let { data } = await logs.GetServiceReportLogDetails(query);
        commit("SET_REPORT_DETAILS", data.data);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getServiceBulkReportLogDetails({ commit }, query) {
      try {
        let { data } = await logs.GetServiceBulkReportLogDetails(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getServiceBulkReportLogDetailsExport({ commit }, query) {
      try {
        let { data } = await logs.GetServiceBulkReportLogDetailsExport(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getBillPaymentLogs({ commit }, query) {
      try {
        let { data } = await logs.GetBillPaymentLogs(query);
        commit("SET_REPORT_DETAILS", data.data);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getSmartIDCustomers({ commit }, query) {
      try {
        let { data } = await logs.GetSmartIDCustomers(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getSmartIDBusiness({ commit }, query) {
      try {
        let { data } = await logs.GetSmartIDBusiness(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getSmartIDCustomersDetails({ commit }, query) {
      try {
        let { data } = await logs.GetSmartIDCustomersDetails(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getSmartIDCustomerVerificationDetails({ commit }, query) {
      try {
        let { data } = await logs.GetSmartIDCustomerVerificationDetails(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getSmartIDBusinessesDetails({ commit }, query) {
      try {
        let { data } = await logs.GetSmartIDBusinessesDetails(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
    async getSmartIDBusinessesVerificationDetails({ commit }, query) {
      try {
        let { data } = await logs.GetSmartIDBusinessesVerificationDetails(query);
        return data;
      } catch (error) {
        console.error(error);
        throw Error(error.response.data.message);
      }
    },
  },
};
