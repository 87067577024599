<template>
<div>
  <!-- <spinner v-if="loading" /> -->
  <div class="col-12 pt-4" >
    <div class="SectionItem no-b-b mb-3">
      <div>
        <p class="title m-0">Log Overview </p>
        <p class="text-left description">
          An Overview of Service provider call log
        </p>
      </div>
    </div>
    <div class="flex__end">
      <div class="col-2">
      <CustomSelect
        name="filterBy"
        placeholder="Filter By"
        :optionLabel="'name'"
        :options="providerStatFilter"
        v-model="filterBy"
        :handleError="()=>{}"
      />
    </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="dashboard-briefs">
          <div class="dashboard-brief rounded py-4">
            <h2 class="call-count">
             <span v-if="stats">
               <span v-if="stats.total_price">{{ toCurrency(stats.total_price || "0") }}</span>
               <span v-else>0</span>
              </span>
              <span v-else>0</span>
            </h2>
            <p class="call-name">Total Value of Calls</p>
          </div>
          <div class="dashboard-brief rounded py-4">
            <h2 class="call-count">
              <span v-if="stats">
               <span v-if="stats.totalRow">{{ formatNumber(stats.totalRow, false) || "0" }}</span>
               <span v-else>0</span>
              </span>
              <span v-else>0</span>
              
            </h2>
            <p class="call-name">Total Number of Call Logs</p>
          </div>
          <div class="dashboard-brief rounded py-4">
            <h2 class="call-count">
             <span v-if="overview">
               <span v-if="overview.serviceCount.totalRows">{{ formatNumber(overview.serviceCount.totalRows, false) || "0" }}</span>
               <span v-else>0</span>
              </span>
              <span v-else>0</span>
            </h2>
            <p class="call-name">Number of Services</p>
          </div>
          <div class="dashboard-brief rounded py-4">
            <h2 class="call-count">
              <span v-if="overview">
               <span v-if="overview.serviceCount.totalRows">{{ formatNumber(overview.providerCount.totalRows, false) || "0" }}</span>
               <span v-else>0</span>
              </span>
              <span v-else>0</span>
            </h2>
            <p class="call-name">Number of Providers</p>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="dashboard-brief-chart p-4">
          <h2 class="call-count text-left">Call Log Statistics</h2>
          <ProviderLogStat />
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  toCurrency,
  formatNumber,
  graphDateFormatter,
  providerStatFilter
} from "../../../components/utils/helpers";
import Spinner from "../../../components/utils/Spinner/Spinner";
import CustomSelect from "../../../components/utils/CustomSelect/CustomSelect";
import ProviderLogStat from "../../../components/charts/ProviderLogStat.vue";
import serviceProviders from "../../../services/serviceProviders";
export default {
  name: "CreditLogsOverview",
  data() {
    return {
      overview: null,
      stats: null,
      loading: false,
      filterBy: "thisMonth",
      chartOptions: {
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
              },
            },
          },
        },
        labels: ["Amount Used", "Amount Remaining"],
      },
      providerStatFilter
    };
  },
  components: { ProviderLogStat,Spinner, CustomSelect },
  methods: {
    toCurrency,
    formatNumber,
    graphDateFormatter,
    async fetchCreditLogHistory() {
      const response = await serviceProviders.GetTotalServiceAndProvider();
      this.overview = {
        ...response.data.data,
      };
    },
    async fetchTotalApiLogCount(filterBy) {
      const response = await serviceProviders.GetTotalApiLogCount(filterBy);
      this.stats = {
        ...this.stats,
        ...response.data.data,
      };
    },
    async fetchTotalApiCallSum(filterBy) {
      const response = await serviceProviders.GetTotalApiCallSum(filterBy);
      this.stats = {
        ...this.stats,
        ...response.data.data,
      };
    },
  },
  watch: {
    filterBy(newVal, oldVal) {
      this.stats = null
      this.fetchTotalApiLogCount({
        filterBy: newVal.value,
      });
      this.fetchTotalApiCallSum({
        filterBy: newVal.value,
      });
    },
  },
  created() {
    this.fetchCreditLogHistory();
    this.fetchTotalApiLogCount({filterBy: this.filterBy});
    this.fetchTotalApiCallSum({filterBy: this.filterBy});
  },
};
</script>

<style scoped>
.dashboard-briefs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  /* grid-template-rows: 170px; */
}
.dashboard-briefs-sm {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
  row-gap: 5px;
  /* grid-template-rows: 170px; */
}
.dashboard-brief {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}
.dashboard-brief-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}
/* .dashboard-brief:not(:last-of-type) {
  border-right: 1px solid #cccbcb;
} */

.dashboard-lead {
  color: white;
}
.side-border {
  border-left: 3px dotted white;
}

.dashboard-element {
  padding: 20px;
  color: inherit;
}
.call-count {
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  color: #071b2b;
  padding-top: 8px;
  margin-bottom: 20px;
}
.call-name {
  font-size: 15px;
  color: #081b2a;
  text-transform: uppercase;
}
</style>
