<template>
  <div>
    <button
      type="button"
      class="btn btn-sm btn--primary px-4 ml-3"
      @click="openEditAppLimitModal"
    >
      Edit App Limit
    </button>
    <EditAppLimitModal
      :id="'appLimit'"
      :clientid="clientid"
      :closeModal="closeModal"
      @refetch="refetch"
      :customer="customer"
    />
  </div>
</template>

<script>
import EditAppLimitModal from "../../modals/EditAppLimitModal.vue";

export default {
  name: "EditAppLimitButton",
  props: ["clientid", "customer"],
  methods: {
    openEditAppLimitModal() {
      $("#appLimit").modal("show");
    },
    closeModal() {
      $("#appLimit").modal("hide");
    },
    refetch() {
      this.$emit("refetch");
    },
  },
  components: {
    EditAppLimitModal,
  },
};
</script>
