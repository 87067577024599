<template>
  <AuthWrapper>
    <div class="card-login">
      <a href="index.php" class="logo">
        <img src="/assets/img/blusalt-logo-sb-inverse.svg" alt />
      </a>
      <h2>Seems like you forgot something, lets help you get it back.</h2>

      <form @submit.prevent="initiateResetPassord">
        <div class="form-group">
          <label class="input-item-label">Email</label>
          <input
            type="email"
            class="form-control input-bordered"
            placeholder="Enter your email address"
            v-model.trim="email"
          />
          <ul class="parsley-errors-list filled" id="parsley-id-26" v-show="$v.email.$error">
            <li class="parsley-required">Email is required.</li>
          </ul>
        </div>

        <button
          type="submit"
          class="btn btn-lg btn-primary btn-block mg-b-20"
          :disabled="isProcessing == true"
        >
          <span v-if="isProcessing">Attempting to remember</span>
          <span v-else>Forgot Password</span>
        </button>
        <p>
          Already have an account?
          <router-link to="/">Login</router-link>
        </p>
      </form>
    </div>
  </AuthWrapper>
</template>

<script>
import Nprogress from "nprogress"
import { required } from 'vuelidate/lib/validators';
import AuthWrapper from '@/components/auth/AuthWrapper';
import { notifications } from '../../public/assets/mixins/notifications';

export default {
  name: "Forgot Password",
  mixins: [notifications],
  components: {
    AuthWrapper
  },
  data() {
    return {
      email: '',
      isProcessing: false
    }
  },
  validations: {
    email: {
      required
    }
  },
  methods: {
    async initiateResetPassord() {
      if (this.$v.$invalid) return
      Nprogress.start();
      this.isProcessing = true;

      let credential = {
        "email": this.email,
      }
      try {
        let apiResponse = await this.$store.dispatch('auth/initiatePasswordReset', credential)
        console.log('[apiResponse of login] ->', apiResponse);
        Nprogress.done();
        this.showSuccessNotification(apiResponse.message)
        this.email = ''
        this.isProcessing = false;
      } catch (error) {
        Nprogress.done();
        this.isProcessing = false;
        console.log('[] ->', error.response);
        this.showErrorLoginNotification(error.message)
      }
    }
  }
};
</script>
