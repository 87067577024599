<template>
	<div class="service-content accordion-content">
		<div v-if="getMetaData(serviceCategory).length > 0">
			<ul class="service-listing header">
				<li>Name</li>
				<li>Price</li>
				<li>Fail Over</li>
				<li>Country</li>
				<li>Charge Type</li>
				<li>Action</li>
			</ul>

			<template v-for="item in getMetaData(serviceCategory)">
				<ul class="service-listing" :key="item.id">
					<li>{{ item.service_label }}</li>
					<li>{{ item.price_per_call }}</li>
					<li>{{ item.fail_over_price }}</li>
					<li>
						{{
							item.country_name
								? `${item.country_name} (${item.country_code})`
								: "Not Available"
						}}
					</li>
					<li>
						{{
							item.charge_type
								? titleCase(item.charge_type)
								: "Not Available"
						}}
					</li>
					<li>
						<UpdateServiceButton
							:service="item"
							:category="serviceCategory"
							:countryList="countryList"
						/>
					</li>
				</ul>
			</template>
		</div>
		<div v-else class="text-danger text-center pt-2">
			<h5 class="description">No service found under this category</h5>
		</div>
	</div>
</template>

<script>
import UpdateServiceButton from "../../buttons/UpdateServiceButton/UpdateServiceButton";
import { titleCase } from "../../../../components/utils/helpers";

export default {
	name: "ServicesByCategory",
	props: ["serviceCategory", "countryList"],
	components: {
		UpdateServiceButton,
	},
	methods: {
		titleCase,
		getMetaData(serviceCategory) {
			const meta = this.$store.state.metaData.services.filter(
				({ category }) =>
					category &&
					category.api_service_category ===
						serviceCategory.api_service_category
			);
			return meta;
		},
	},
};
</script>
