<template>
  <div>
    <div class="px-3">
      <FilterWrapper>
        <template slot="filterForm">
          <MerchantCustomerFilter
            :loading="loading"
            :rLoading="rLoading"
            :submitFilter="refetch"
            :clearFilter="() => clearFilter('merchantCustomerRef')"
          />
        </template>
      </FilterWrapper>
    </div>
    <TableWrapper
      :loading="loading"
      :errorMsg="errorMsg"
      :refetch="fetchCustomers"
      :isEmpty="isEmpty"
      :emptyTitle="emptyTitle"
    >
      <div class="w-100 py-3" slot="content">
        <v-server-table
          ref="merchantCustomerRef"
          class="v-table row-pointer"
          :data="customers"
          :columns="columns"
          :options="options"
          @loaded="onLoaded"
          @loading="setLoading(true)"
          @row-click="handleClick"
        >
          <template slot="createdAt" slot-scope="{ row }">
            {{ row.createdAt | moment(" MM-DD-YYYY h:mm a") }}
          </template>
          <template slot="phone" slot-scope="{ row }">
            {{ row.phone || 'N/A' }}
          </template>
          <template slot="first_name" slot-scope="{ row }">
            <p class="mb-0 font-weight-bold" v-if="row.first_name">
              {{ titleCase(row.first_name) }}
              {{ titleCase(row.last_name) }}
            </p>
            <p v-else>N/A</p>
          </template>
        </v-server-table>
      </div>
    </TableWrapper>
  </div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../../components/utils/TableWrapper/TableWrapper.vue";
import MerchantCustomerFilter from "../../../../components/filters/MerchantCustomerFilter.vue";

const tableHeadings = {
  first_name: "Full Name",
  email: "Email",
  phone: "Phone",
  reference: "Reference",
  createdAt: "Date Created",
};

export default {
  name: "MerchantCustomer",
  mixins: [componentData],
  data() {
    return {
      singleInviteObj: null,
      showSingleInviteModal: false,
      emptyTitle: "No Customer Found",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(tableHeadings, 25),
    };
  },
  components: {
    TableWrapper,
    MerchantCustomerFilter,
  },
  methods: {
    refetch(filters) {
      this.handleRefetch(filters, this.fetchCustomers, "merchantCustomerRef");
    },
    handleClick({ row }) {
      this.$router.push(
        `/payment-merchant/${this.$route.params.clientid}/customer/${row.reference}`
      );
    },
    async fetchCustomers() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters, id: this.clientid };
        const response = await this.$store.dispatch(
          "customer/getMerchantCustomers",
          {
            params,
          }
        );

        const newData = response.data.data;
        const count = response.data.total;
        return { ...response, count, total: count, data: newData };
      };
    },
  },

  props: ["clientid"],
  created() {
    this.fetchCustomers();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
