<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn--primary px-4 ml-3"
			@click="openAddServiceCategory"
		>
			Add Service Category
		</button>
		<AddServiceCategoryModal
			:id="'addServiceCategory'"
			:closeModal="closeModal"
		/>
	</div>
</template>

<script>
	import AddServiceCategoryModal from "../../modals/AddServiceCategoryModal/AddServiceCategoryModal";

	export default {
		name: "AddServiceCategoryButton",
		methods: {
			openAddServiceCategory() {
				$("#addServiceCategory").modal("show");
			},
			closeModal() {
				$("#addServiceCategory").modal("hide");
			},
		},
		components: {
			AddServiceCategoryModal,
		},
	};
</script>
