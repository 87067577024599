<template>
  <div>
    <div class="row side-tab-pills">
      <div class="col-2">
        <div
          class="nav flex-column nav-pills"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <a
            class="nav-link active"
            id="v-pills-collections-tab"
            data-toggle="pill"
            href="#v-pills-collections"
            role="tab"
            aria-controls="v-pills-collections"
            aria-selected="true"
            >Payment Page</a
          >
          <a
            class="nav-link"
            id="v-pills-links-tab"
            data-toggle="pill"
            href="#v-pills-links"
            role="tab"
            aria-controls="v-pills-links"
            aria-selected="false"
            >Payment Links</a
          >
          <a
            class="nav-link"
            id="v-pills-payouts-tab"
            data-toggle="pill"
            href="#v-pills-payouts"
            role="tab"
            aria-controls="v-pills-payouts"
            aria-selected="false"
            >Payouts</a
          >
          <a
            class="nav-link"
            id="v-pills-accounts-tab"
            data-toggle="pill"
            href="#v-pills-accounts"
            role="tab"
            aria-controls="v-pills-accounts"
            aria-selected="false"
            >Split Payment</a
          >
          <a
            class="nav-link"
            id="v-pills-disputes-tab"
            data-toggle="pill"
            href="#v-pills-disputes"
            role="tab"
            aria-controls="v-pills-disputes"
            aria-selected="false"
            >Disputes</a
          >
          <a
            class="nav-link"
            id="v-pills-chargeback-tab"
            data-toggle="pill"
            href="#v-pills-chargeback"
            role="tab"
            aria-controls="v-pills-chargeback"
            aria-selected="false"
            >Chargeback</a
          >
          <a
            class="nav-link"
            id="v-pills-refund-tab"
            data-toggle="pill"
            href="#v-pills-refund"
            role="tab"
            aria-controls="v-pills-refund"
            aria-selected="false"
            >Refund</a
          >
        </div>
      </div>
      <div class="col-10">
        <div class="tab-content" id="v-pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="v-pills-collections"
            role="tabpanel"
            aria-labelledby="v-pills-collections-tab"
          >
            <h3 class="mb-4">Payment Page</h3>
            <Collection :clientid="clientid" />
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-links"
            role="tabpanel"
            aria-labelledby="v-pills-links-tab"
          >
            <h3 class="mb-4">Payment Links</h3>
            <PaymentLinks :clientid="clientid" />
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-payouts"
            role="tabpanel"
            aria-labelledby="v-pills-payouts-tab"
          >
            <h3 class="mb-4">Payouts</h3>
            <Payouts :clientid="clientid" />
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-accounts"
            role="tabpanel"
            aria-labelledby="v-pills-accounts-tab"
          >
            <h3 class="mb-4">Split Payment</h3>
            <SubAccounts :clientid="clientid" />
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-disputes"
            role="tabpanel"
            aria-labelledby="v-pills-accounts-tab"
          >
            <h3 class="mb-4">Disputes</h3>
            <Disputes :clientid="clientid" />
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-chargeback"
            role="tabpanel"
            aria-labelledby="v-pills-accounts-tab"
          >
            <h3 class="mb-4">Chargeback</h3>
            <ChargeBack :clientid="clientid" />
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-refund"
            role="tabpanel"
            aria-labelledby="v-pills-accounts-tab"
          >
            <h3 class="mb-4">Refund</h3>
            <Refund :clientid="clientid" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../../components/utils/TableWrapper/TableWrapper.vue";
import PaymentLinks from "./CollectionTabs/PaymentLinks.vue";
import Payouts from "./CollectionTabs/Payouts.vue";
import SubAccounts from "./CollectionTabs/SubAccounts.vue";
import Collection from "./CollectionTabs/Collection.vue";
import Disputes from "./CollectionTabs/Disputes.vue";
import ChargeBack from "./CollectionTabs/ChargeBacks.vue";
import Refund from "./CollectionTabs/Refunds.vue";

export default {
  name: "Collections",
  mixins: [componentData],
  data() {
    return {};
  },
  components: {
    TableWrapper,
    PaymentLinks,
    Payouts,
    SubAccounts,
    Collection,
    Disputes,
    ChargeBack,
    Refund
  },
  props: ["clientid"],
  methods: {},
};
</script>
