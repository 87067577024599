<template>
  <SideModal
    :show="show"
    :toggle="toggle"
    :title="`Subscription Information`"
    :subTitle="''"
  >
    <div class="row mt-2 pt-4">
      <div class="col-6">
        <p>
          <span class="text-muted">TOTAL AMOUNT</span> <br />
          <span class="text-capitalize h3">
            &#8358;{{ formatNumber(singleSubscriptionObj.amountAfter) || "0" }}
          </span>
        </p>
      </div>
      <div class="col-6">
        <span
          class="badge badge-app text-capitalize"
          :class="
            singleSubscriptionObj.active
              ? 'badge-success'
              : 'badge-danger'
          "
        >
          {{
            singleSubscriptionObj.active
              ? 'Active'
              : "Inactive"
          }}
        </span>
      </div>
      <br />
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted">AMOUNT </span><br />
          <span class="text-capitalize h5">
            &#8358;{{ formatNumber(singleSubscriptionObj.amount) || "0" }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted">SETUP AMOUNT</span> <br />
          <span class="text-capitalize h5">
            &#8358;{{ formatNumber(singleSubscriptionObj.setup_amount) || "0" }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted">COUPON AMOUNT</span> <br />
         <span class="text-capitalize h5">
            &#8358;{{ formatNumber(singleSubscriptionObj.couponAmount) || "0" }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted">VAT</span> <br />
          <span class="text-capitalize h5">{{
            singleSubscriptionObj.vat
          }}</span>
        </p>
      </div>
    </div>
    <br />
    <hr />
    <br />
    <h3>Subscription Details</h3>
    <div class="row mt-2">
      <div class="col-6">
        <p>
          <span class="text-muted text-uppercase">Subscription id </span><br />
          <span class="text-capitalize h5">{{  singleSubscriptionObj.id }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase"> Subscription type</span> <br />
          <span class="text-capitalize h5">{{
            singleSubscriptionObj.subscriptionType || "Null"
          }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">Date created</span> <br />
          {{ singleSubscriptionObj.createdAt | moment(" MM-DD-YYYY h:mm a") }}
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">Start Date</span> <br />
           {{ singleSubscriptionObj.startDate | moment(" MM-DD-YYYY h:mm a") }}
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">End Date </span><br />
           {{ singleSubscriptionObj.endDate | moment(" MM-DD-YYYY h:mm a") }}
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">Renewal Date </span><br />
          {{ singleSubscriptionObj.renewalDate | moment(" MM-DD-YYYY h:mm a") }}
        </p>
      </div>
    </div>
    <br />
    <hr />
    <br />
    <h3>Plan Details</h3>
    <div class="row mt-2">
      <div class="col-6">
        <p>
          <span class="text-muted text-uppercase">Plan Name </span><br />
          <span class="text-capitalize h5">{{singleSubscriptionObj.metadata.plan.name }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase"> Plan reference</span> <br />
          <span class="text-capitalize h5">{{
            singleSubscriptionObj.planRef || "Null"
          }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">Interval</span> <br />
          <span class="text-capitalize h5">{{singleSubscriptionObj.metadata.plan.interval }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">Description</span> <br />
          <span class="text-capitalize h5">{{singleSubscriptionObj.metadata.plan.description }}</span>
        </p>
      </div>
    </div>
    <br />
    <hr />
    <br />
    <h3 v-if="singleSubscriptionObj.metadata.wallet">Subscriber Information</h3>
    <div class="row mt-2" v-if="singleSubscriptionObj.metadata.wallet">
      <div class="col-6">
        <p>
          <span class="text-muted text-uppercase">Name </span><br />
          <span class="text-capitalize h5" v-if="singleSubscriptionObj.metadata.wallet">{{singleSubscriptionObj.metadata.wallet.first_name }} {{singleSubscriptionObj.metadata.wallet.last_name }}</span><span v-else>N/A</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">Account Number </span> <br />
          <span class="text-capitalize h5">{{singleSubscriptionObj.metadata.wallet.account_number }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">Bank</span> <br />
          <span class="text-capitalize h5">{{singleSubscriptionObj.metadata.wallet.bank_name }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">Email</span> <br />
          <span class="h5">{{singleSubscriptionObj.metadata.wallet.email }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">Phone</span> <br />
          <span class="h5">{{singleSubscriptionObj.metadata.wallet.phone }}</span>
        </p>
      </div>
    </div>
    
   
  </SideModal>
</template>

<script>
import { notifications } from "../../../../public/assets/mixins/notifications";
import SideModal from "../../../components/utils/Modal/SideModal.vue";
import { formatNumber } from "../../../components/utils/helpers";

export default {
  mixins: [notifications],
  name: "SubscriptionDetailModal",
  data() {
    return {};
  },
  props: ["toggle", "show", "singleSubscriptionObj"],
  components: {
    SideModal,
  },
  methods: {
    formatNumber,
    handleCloseModal() {
      this.toggle();
    },
  },
};
</script>

<style scoped>
.d-flex {
  display: flex;
  justify-content: flex-end;
}
</style>
