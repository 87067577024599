<template>
  <AuthWrapper>
    <div class="card-login">
      <a href="index.php" class="logo">
        <img src="/assets/img/blusalt-logo-sb-inverse.svg" alt />
      </a>
      <div class="activate-header d-flex justify-content-center align-items-center flex-column">
        <img src="/assets/img/bad-gateway.png" width="240px" class="d-flex" />
        <p
          class="mt-5"
          style="font-size:16px; text-align: center"
        >Seems you came where you shouldn't have, Please click the button to go back to safely</p>
        <div class="gaps-1-5x"></div>
        <a href="/" class="btn btn-lg btn-primary btn-block mg-b-20">Back to Safety</a>
      </div>
    </div>
  </AuthWrapper>
</template>

<script>
import AuthWrapper from '@/components/auth/AuthWrapper';

export default {
  components: {
    AuthWrapper
  }
}
</script>