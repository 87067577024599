<template>
  <div class="border rounded p-2 px-4 mb-3 ">
    <div class="flex__between" @click="toggleShow">
      <h5 class="font-weight-bold mt-2">
        <!-- <label class="switch mr-2 mt-2 mb-0 pb-0">
          <input
            type="checkbox"
            :checked="
              service.apiServices.filter((dt) => dt.is_activated).length
            "
          />
          <span class="slider round"></span>
        </label> -->
        {{ service.api_service_category }}
      </h5>
      <img src="/assets/img/icons/arrow-down.svg" alt="image" height="20" />
    </div>
    <div v-if="show" class="row">
      <div class="col-12 mt-3">
        <label class="ckbox" v-if="service.apiServices.length">
          <input type="checkbox" @click="handleSelect(service, false)" />
          <span style="font-size: 15px">Check All</span>
        </label>
        <p v-else>No service available.</p>
        <hr />
      </div>
      <div
        class="col-6 p-2"
        v-for="(service, key) in service.apiServices"
        :key="key"
      >
        <div class="bg-pry p-3">
          <label class="ckbox d-flex">
            <input
              type="checkbox"
              @click="handleSelect(service.id, true)"
              :checked="activeServices.includes(service.id)"
            />
            <span style="font-size: 15px; line-height: 20px;">{{ service.service_label }}</span>
          </label>
        </div>
      </div>

      
    </div>
  </div>
</template>

<script>
export default {
  name: "SubService",
  props: ["service", "handleSelectService", "activeServices"],
  data() {
    return {
      show: false,
      checkAll: false,
    };
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
    },
    handleSelect(data, single = true) {
      if (single) {
        this.handleSelectService(data, single);
      } else {
        if (this.checkAll) {
          this.handleSelectService(data, single, false);
          this.checkAll = !this.checkAll;
        } else {
          this.checkAll = !this.checkAll;
          this.handleSelectService(data, single, true);
        }
      }
    },
  },
};
</script>

<style scoped>
.bg-pry {
  background: #f6fafe;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 32px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
