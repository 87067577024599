<template>
  <SideModal
    :show="show"
    :toggle="toggle"
    :title="`Send Invite`"
    :subTitle="'Send an invite to a customer to create account'"
  >
    <form @submit.prevent="handleSubmit">
      <CustomInput
        :name="'customerFirstName'"
        v-model="request.customerFirstName"
        :label="`Customer's First Name`"
        :placeholder="`Customer's First Name`"
        :required="true"
        :error="formErrors.customerFirstName"
        :handleError="handleError"
      />
      <CustomInput
        :name="'customerLastName'"
        v-model="request.customerLastName"
        :label="`Customer's Last Name`"
        :placeholder="`Customer's Last Name`"
        :required="true"
        :error="formErrors.customerLastName"
        :handleError="handleError"
      />
      <CustomInput
        :name="'customerEmail'"
        v-model="request.customerEmail"
        :label="`Customer's Email`"
        :placeholder="`Customer's Email`"
        :required="true"
        :error="formErrors.customerEmail"
        :handleError="handleError"
      />

      <div class="flex__between pt-4">
        <button
          type="submit"
          class="btn btn-lg w-100 btn-primary"
          :disabled="loading"
        >
          <Spinner v-if="loading" />
          <span v-else>Send Invite</span>
        </button>
      </div>
    </form>
  </SideModal>
</template>

<script>
import { notifications } from "../../../../public/assets/mixins/notifications";
import CustomInput from "../../../components/utils/CustomInput/CustomInput.vue";
import { formValidator } from "../../../components/utils/FormValidator/FormValidator";
import SideModal from "../../../components/utils/Modal/SideModal.vue";
import SuccessState from "../../../components/utils/ResponseState/SuccessState.vue";
import customer from "../../../services/customer";

const initialState = () => ({
  request: { customerEmail: "", customerFirstName: "", customerLastName: "" },
  loading: false,
  successMsg: false,
  formErrors: {},
});

export default {
  mixins: [notifications],
  name: "CreateCustomerModal",
  data() {
    return { ...initialState() };
  },
  props: ["toggle", "show", "invitesRef"],
  components: {
    CustomInput,
    SideModal,
    SuccessState,
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      const { formErrors, isValid } = formValidator(this.request);
      if (isValid) {
        this.handleSendInvite();
      } else {
        this.formErrors = formErrors;
      }
    },
    handleError(name) {
      this.formErrors = { ...this.formErrors, [name]: "" };
    },

    async handleSendInvite() {
      this.loading = true;
      try {
        let { data } = await customer.SendInvite(this.request);
        this.loading = false;
        this.invitesRef.refresh();
        this.request = {
          customerEmail: "",
          customerFirstName: "",
          customerLastName: "",
        };
        this.showSuccessNotification(data.message);
        this.toggle();
      } catch (error) {
        this.showErrorNotification(error.response.data.message);
        this.loading = false;
        throw Error(error.response.data.message);
      }
    },
  },
};
</script>
