<template>
	<ul
		class="nav nav-tabs flex-nowrap"
		:class="tabClass"
		:id="tabId"
		role="tablist"
	>
		<template v-if="tabLink">
			<template v-for="{ tabName, value, link, devOnly } in tabNames">
				<li v-if="devOnly" class="nav-item" :key="value">
					<DevOnly>
						<span
							class="nav-link pointer"
							:class="link === $route.path ? 'active' : ''"
							@click="$router.push(link)"
							role="presentation"
							>{{ tabName }}</span
						>
					</DevOnly>
				</li>

				<li v-else class="nav-item" :key="value">
					<span
						class="nav-link pointer"
						:class="link === $route.path ? 'active' : ''"
						@click="$router.push(link)"
						role="presentation"
						>{{ tabName }}</span
					>
				</li>
			</template></template
		>

		<template v-else>
			<template v-for="{ tabName, value } in tabNames">
				<li class="nav-item" :key="value">
					<a
						class="nav-link"
						:class="value === tabNames[0].value ? 'active' : ''"
						:id="`${value}-tab`"
						data-toggle="tab"
						:href="`#${value}`"
						role="tab"
						:aria-controls="value"
						aria-selected="true"
						>{{ tabName }}</a
					>
				</li>
			</template></template
		>
	</ul>
</template>

<script>
import DevOnly from "../AccessControl/DevOnly.vue";

export default {
	name: "TabsV2",
	props: {
		tabNames: Array,
		tabLink: {
			type: Boolean,
			default: false,
		},
		tabId: String,
		tabClass: {
			type: String,
			default: "flex-tab",
		},
	},
	components: {
		DevOnly,
	},
};
</script>
