<template>
	<div id="applicationPriceModal" class="modal">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content modal-content-demo">
				<div class="modal-body">
					<button
						type="button"
						class="close pos-absolute t-20 r-30 tx-26"
						data-dismiss="modal"
						aria-label="Close"
						id="close-applicationPriceModal"
					>
						<span aria-hidden="true">&times;</span>
					</button>
					<h5 class="modal-title mg-b-5">Update Service Price</h5>
					<p>Update the service price for a customer service.</p>
					<div class="b-b"></div>
					<div class="gaps-2x"></div>
					<form @submit.prevent="updateServicePrice">
						<!-- CLient ID -->
						<div class="form-group">
							<label class="input-item-label">Client ID</label>
							<input
								type="text"
								disabled
								class="form-control input-bordered"
								:placeholder="customerData.client_id"
							/>
						</div>
						<!-- Application Services -->
						<div class="form-group">
							<label class="input-item-label">Service</label>
							<input
								type="text"
								disabled
								class="form-control input-bordered"
								:placeholder="customerData.service_label"
							/>
						</div>
						<!-- Charge Type -->
						<div class="form-group">
							<label class="input-item-label">Charge Type</label>
							<v-select
								class="v-select"
								@keydown.enter.native.prevent
								:name="'charge_type'"
								:placeholder="'Select Charge Type'"
								:options="chargeTypes"
								label="label"
								:reduce="(chargeType) => chargeType.value"
								v-model="customerData.charge_type"
							></v-select>
							<!-- <CustomSelect
                :name="'charge_type'"
                :placeholder="'Select Charge Type'"
                :options="chargeTypes"
                option-label="label"
                :reduce="chargeType => chargeType.value"
                :label="'Charge Type'"
                v-model="customerData.charge_type"
              /> -->
							<!-- <input
                type="text"
                v-model="customerData.charge_type"
                class="form-control input-bordered"
                :placeholder="customerData.charge_type.replace(/_/g, ' ')"
              /> -->
						</div>

						<!-- Price per call -->
						<div class="form-group">
							<label class="input-item-label"
								>Price Per Call</label
							>
							<input
								type="number"
								step=".01"
								class="form-control input-bordered"
								placeholder="Enter price per call"
								v-model="customerData.price_per_call"
							/>
							<ul
								class="parsley-errors-list filled"
								v-show="$v.customerData.price_per_call.$error"
							>
								<li class="parsley-required">
									Price per call is required.
								</li>
							</ul>
						</div>

						<!-- Application Credit limit -->
						<div class="form-group">
							<label class="input-item-label"
								>Has Fail-overs</label
							>
							<v-select
								class="v-select"
								@keydown.enter.native.prevent
								name="fail overs"
								v-model="customerData.has_fail_over"
								:options="failOvers"
								placeholder="Select fail-over services"
							></v-select>
						</div>

						<div class="gaps-2x"></div>
						<button
							type="submit"
							class="btn btn-lg btn-primary"
							:disabled="isProcessing == true"
						>
							<span v-if="isProcessing">Updating Price..</span>
							<span v-else>Update Price</span>
						</button>
						<button
							type="button"
							class="btn btn-lg btn-light ml-2"
							data-dismiss="modal"
						>
							Close
						</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Nprogress from "nprogress";
import { required } from "vuelidate/lib/validators";
import { notifications } from "../../../public/assets/mixins/notifications";
import CustomSelect from "@/components/utils/CustomSelect/CustomSelect";

export default {
	props: {
		customerData: {
			type: Object,
		},
	},
	mixins: [notifications],
	components: { CustomSelect },
	data() {
		return {
			services: "",
			failOvers: ["Yes", "No"],
			hasFailOvers: "Yes",
			pricePerCall: "",
			isProcessing: false,
		};
	},
	computed: {
		chargeTypes() {
			return this.$store.state.services.chargeTypes.map((chargeType) => {
				return {
					label: chargeType.replace(/_/g, " "),
					value: chargeType,
				};
			});
		},
	},
	validations: {
		// pricePerCall: {
		//   required
		// },
		customerData: {
			price_per_call: {
				required,
			},
			has_fail_over: {
				required,
			},
		},
	},

	watch: {
		pricePerCall(newValue, oldValue) {
			console.log("price ", this.this.customerData);
			return this.customerData.price_per_call;
		},
	},
	methods: {
		clearInputFields() {
			this.services = "";
			this.hasFailOvers = "";
			this.pricePerCall = "";
		},

		serializeRequestData() {
			let requestData = {
				clientID: this.customerData.client_id,
				service: this.customerData.service_id,
				payload: {
					price_per_call: this.customerData.price_per_call,
					has_fail_over:
						this.customerData.has_fail_over == "Yes" ? 1 : 0,
					charge_type: this.customerData.charge_type,
				},
			};
			console.log("request data ", requestData);
			return requestData;
		},

		async updateServicePrice() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}

			try {
				let requestPayload = this.serializeRequestData();
				this.isProcessing = true;
				let application = await this.$store.dispatch(
					"customer/UpdateCustomerPrice",
					requestPayload
				);
				this.showSuccessNotification(application.message);
				let self = this;
				setTimeout(() => {
					self.closeModalSuccess();
					self.clearInputFields();
					self.isProcessing = false;
				}, 2500);
				Nprogress.done();
			} catch (error) {
				Nprogress.done();
				this.isProcessing = false;
				console.log("[error] ->", error);
				this.showErrorLoginNotification(error.message);
			}
		},
		closeModalSuccess() {
			document.getElementById("close-applicationPriceModal").click();
			// closeButton.click()
			this.$emit("closePriceModal");
		},
	},
};
</script>
