<template>
  <div>
    <div class="row side-tab-pills">
      <div class="col-2">
        <div
          class="nav flex-column nav-pills"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <a
            class="nav-link active"
            id="v-pills-suscription-tab"
            data-toggle="pill"
            href="#v-pills-suscription"
            role="tab"
            aria-controls="v-pills-suscription"
            aria-selected="true"
            >Subscriptions</a
          >
          <a
            class="nav-link"
            id="v-pills-plans-tab"
            data-toggle="pill"
            href="#v-pills-plans"
            role="tab"
            aria-controls="v-pills-plans"
            aria-selected="false"
            >Plans</a
          >
          <a
            class="nav-link"
            id="v-pills-coupon-tab"
            data-toggle="pill"
            href="#v-pills-coupon"
            role="tab"
            aria-controls="v-pills-coupon"
            aria-selected="false"
            >Coupon</a
          >
          <a
            class="nav-link"
            id="v-pills-tax-tab"
            data-toggle="pill"
            href="#v-pills-tax"
            role="tab"
            aria-controls="v-pills-tax"
            aria-selected="false"
            >Tax</a
          >
          <a
            class="nav-link"
            id="v-pills-trail-tab"
            data-toggle="pill"
            href="#v-pills-trail"
            role="tab"
            aria-controls="v-pills-trail"
            aria-selected="false"
            >Trail</a
          >
        </div>
      </div>
      <div class="col-10">
        <div class="tab-content" id="v-pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="v-pills-suscription"
            role="tabpanel"
            aria-labelledby="v-pills-suscription-tab"
          >
            <h3 class="mb-4">Suscriptions</h3>
            <Suscriptions :clientid="clientid" />
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-plans"
            role="tabpanel"
            aria-labelledby="v-pills-plans-tab"
          >
            <h3 class="mb-4">Plans</h3>
            <Plans :clientid="clientid" />
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-coupon"
            role="tabpanel"
            aria-labelledby="v-pills-coupon-tab"
          >
            <h3 class="mb-4">Coupon</h3>
            <Coupons :clientid="clientid" />
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-tax"
            role="tabpanel"
            aria-labelledby="v-pills-tax-tab"
          >
            <h3 class="mb-4">Tax</h3>
            <Taxes :clientid="clientid" />
          </div>
          <div
            class="tab-pane fade"
            id="v-pills-trail"
            role="tabpanel"
            aria-labelledby="v-pills-tax-tab"
          >
            <h3 class="mb-4">Trail</h3>
            <Trails :clientid="clientid" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../../components/utils/TableWrapper/TableWrapper.vue";
import Plans from "./SuscriptionTabs/Plans.vue";
import Coupons from "./SuscriptionTabs/Coupons.vue";
import Taxes from "./SuscriptionTabs/Taxes.vue";
import Trails from "./SuscriptionTabs/Trails.vue";
import Suscriptions from "./SuscriptionTabs/Suscriptions.vue";

export default {
  name: "Suscription",
  mixins: [componentData],
  data() {
    return {};
  },
  components: {
    TableWrapper,
    Plans,
    Coupons,
    Taxes,
    Trails,
    Suscriptions,
  },
  props: ["clientid"],
  methods: {},
};
</script>
