<template>
  <div>
    <div class="px-3">
      <FilterWrapper>
        <template slot="filterForm">
          <TerminalFilter
            :loading="loading"
            :rLoading="rLoading"
            :submitFilter="refetch"
            :clearFilter="() => clearFilter('transactionRef')"
          />
        </template>
        <template slot="pageSelect">
          
         <div>
           <button class="btn btn-primary btn-sm" @click="openModal">Add Terminal</button>
         </div>
        </template>
      </FilterWrapper>
    </div>
    <TableWrapper
      :loading="loading"
      :errorMsg="errorMsg"
      :refetch="verifyPaymentMerchant"
      :isEmpty="isEmpty"
      :emptyTitle="emptyTitle"
    >
      <div class="w-100 py-3" slot="content">
        <v-server-table
          ref="transactionRef"
          class="v-table row-pointer"
          :data="customers"
          :columns="columns"
          :options="options"
          @loading="setLoading(true)"
          @loaded="onLoaded"
        >
          <template slot="createdAt" slot-scope="{ row }">
            {{ row.createdAt | moment(" MM-DD-YYYY h:mm a") }}
          </template>
          <template slot="TerminalMerchantId" slot-scope="{ row }">
            {{ row.TerminalMerchantId|| 'N/A' }}
          </template>
          
        </v-server-table>
      </div>
    </TableWrapper>
    <AddTerminalModal
  			:id="`add-terminal`"
  			:refetch="refetch"
  			:closeModal="closeModal"
        :clientid="clientid"
  		/>
  </div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../../../components/utils/TableWrapper/TableWrapper.vue";
import TerminalFilter from "../../../../../components/filters/TerminalFilter.vue";
import { formatNumber } from "../../../../../components/utils/helpers";
import AddTerminalModal from './modals/AddTerminalModal.vue'

const tableHeadings = {
  TerminalId: "Terminal Id",
  InstitutionId: "Institution Id",
  TerminalMerchantId: "Terminal Merchant Id",
  createdAt: "Date Created",
};

export default {
  name: "Terminals",
  mixins: [componentData],
  data() {
    return {
      emptyTitle: "No Terminals Found",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(tableHeadings, 25),
    };
  },
  components: {
    TableWrapper,
    TerminalFilter,
    AddTerminalModal
  },
  methods: {
    formatNumber,
    refetch(filters) {
      let newFilters = { ...filters };
      if (newFilters.status) newFilters.status = newFilters.status.value;
      this.handleRefetch(newFilters, this.verifyPaymentMerchant, "transactionRef");
    },
    openModal(e) {
      $(`#add-terminal`).modal("show");
    },
    closeModal() {
      $(`#add-terminal`).modal("hide");
    },
    async verifyPaymentMerchant() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters, id: this.clientid };
        const response = await this.$store.dispatch(
          "payment/getMerchantTerminal",
          {
            params,
          }
        );

        const newData = response.data.data;
        const count = response.data.total;
        return { ...response, count, total: count, data: newData };
      };
    },
  },

  props: ["clientid"],
  created() {
    this.verifyPaymentMerchant();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
