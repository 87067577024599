<template>
	<Modal
		:id="id"
		:title="
			category
				? `Update ${category.api_service_category}`
				: 'Add New Service Catgeory'
		"
		:toggle="handleCloseModal"
		:loading="loading"
	>
		<SuccessState
			v-if="successMsg"
			:message="
				`Service Catgeory ${
					category ? 'Updated' : 'Added'
				} Successfully`
			"
			:onSuccess="handleRefetch"
		/>

		<form v-else @submit.prevent="handleSubmit">
			<CustomInput
				:name="'api_service_category'"
				v-model="api_service_category"
				:label="'Service Category'"
				:placeholder="'Enter Service Category'"
				:required="true"
				:error="formErrors.api_service_category"
				:handleError="handleError"
			/>

			<FormButtons
				:loading="loading"
				:handleCloseModal="handleCloseModal"
			/>
		</form>
	</Modal>
</template>

<script>
	import { notifications } from "../../../../../public/assets/mixins/notifications";
	import Modal from "../../../../components/utils/Modal/Modal";
	import FormButtons from "../../../../components/utils/FormButtons/FormButtons";
	import CustomInput from "../../../../components/utils/CustomInput/CustomInput";
	import SuccessState from "../../../../components/utils/ResponseState/SuccessState";
	import { formValidator } from "../../../../components/utils/FormValidator/FormValidator";
	import Spinner from "../../../../components/utils/Spinner/Spinner";

	const initialState = () => ({
		api_service_category: "",
		loading: false,
		successMsg: false,
		formErrors: {},
	});

	export default {
		mixins: [notifications],
		name: "AddServiceCategoryModal",
		data() {
			return { ...initialState() };
		},
		props: ["id", "closeModal", "category"],
		components: {
			Modal,
			CustomInput,
			SuccessState,
			Spinner,
			FormButtons,
		},

		methods: {
			handleCloseModal() {
				Object.assign(this.$data, { ...initialState() });
				this.closeModal();
				this.setCategoryData();
			},
			async handleRefetch() {
				this.handleCloseModal();
				await this.$store.dispatch("getMetaData");
				this.setCategoryData();
			},
			handleSubmit(e) {
				e.preventDefault();
				const { formErrors, isValid } = formValidator({
					api_service_category: this.api_service_category,
				});
				if (isValid)
					this.category ? this.updateCategory() : this.addCategory();
				else this.formErrors = formErrors;
			},
			handleError(name) {
				this.formErrors = { ...this.formErrors, [name]: "" };
			},
			showError(msg) {
				this.loading = false;
				this.showErrorLoginNotification(msg);
			},
			async addCategory() {
				try {
					this.loading = true;
					const response = await this.$store.dispatch(
						"infrastructureProviders/createApiServiceCategory",
						{ api_service_category: this.api_service_category }
					);
					if (response.status_code === 200) {
						this.loading = false;
						this.successMsg = true;
					} else
						this.showError(
							response?.message ||
								"Error Creating Service Category"
						);
				} catch (error) {
					this.showError(error.message);
				}
			},

			async updateCategory() {
				try {
					this.loading = true;
					const response = await this.$store.dispatch(
						`infrastructureProviders/updateApiServiceCategory`,
						{
							id: this.category.id,
							payload: {
								api_service_category: this.api_service_category,
							},
						}
					);

					if (response.status_code === 200) {
						this.loading = false;
						this.successMsg = true;
					} else this.showError("Error Updating Service Category");
				} catch (error) {
					this.showError(error.message);
				}
			},

			setCategoryData() {
				if (this.category) {
					this.api_service_category = this.category.api_service_category;
				}
			},
		},
		mounted() {
			this.setCategoryData();
		},
	};
</script>
