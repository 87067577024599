<template>
  <div class="col-12" v-if="!loading">
    <div class="dashboard-briefs">
      <div class="dashboard-brief rounded py-4">
        <h2 class="call-count">
          &#8358;{{ formatNumber(merchantOverview.totalBalance) || "0" }}
        </h2>
        <p class="call-name">Total Balance</p>
      </div>
      <div class="dashboard-brief  rounded py-4">
        <h2 class="call-count">
          &#8358;{{ formatNumber(merchantOverview.availableBalance) || "0" }}
        </h2>
        <p class="call-name">Available Balance</p>
      </div>
      <div class="dashboard-brief rounded py-4">
        <h2 class="call-count">
          &#8358;{{ formatNumber(merchantOverview.pendingPayout) || "0" }}
        </h2>
        <p class="call-name">Pending Payout</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="dashboard-briefs-sm">
          <div class="dashboard-brief rounded py-4">
            <h2 class="call-count">
              {{ merchantOverview.customersCount || "0" }}
            </h2>
            <p class="call-name">Customers Count</p>

            <!-- <div class="btn border border-primary btn-sm bg-light border-secondary">View</div> -->
          </div>
          <div class="dashboard-brief rounded py-4">
            <h2 class="call-count">
              {{ merchantOverview.payoutCount || "0" }}
            </h2>
            <p class="call-name">Payout Count</p>
          </div>
          <div class="dashboard-brief  rounded py-4">
            <h2 class="call-count">
              {{
                formatNumber(merchantOverview.bankPaymentTransaction, false) ||
                  "0"
              }}
            </h2>
            <p class="call-name">Bank Payment Transactions</p>
          </div>
          <div class="dashboard-brief  rounded py-4">
            <h2 class="call-count">
              {{
                formatNumber(merchantOverview.cardPaymentTransaction, false) ||
                  "0"
              }}
            </h2>
            <p class="call-name">Card Payment Transactions</p>
          </div>
          <div class="dashboard-brief rounded py-4">
            <h2 class="call-count">
              {{ merchantOverview.reservedAccountCount || "0" }}
            </h2>
            <p class="call-name">Reserved Account Count</p>
          </div>
          <div class="dashboard-brief  rounded py-4">
            <h2 class="call-count">
              {{ formatNumber(merchantOverview.disputeCount, false) || "0" }}
            </h2>
            <p class="call-name">Total Disputes</p>
          </div>
          <div class="dashboard-brief  rounded py-4">
            <h2 class="call-count">
              {{
                formatNumber(merchantOverview.invoiceTransaction, false) || "0"
              }}
            </h2>
            <p class="call-name">Invoice Transactions</p>
          </div>
          <div class="dashboard-brief  rounded py-4">
            <h2 class="call-count">
              {{
                formatNumber(merchantOverview.walletTransaction, false) || "0"
              }}
            </h2>
            <p class="call-name">Wallet Transactions</p>
          </div>
          <div class="dashboard-brief rounded py-4">
            <h2 class="call-count">
              {{ merchantOverview.transactionCount || "0" }}
            </h2>
            <p class="call-name">Transaction Count</p>
          </div>
          <div class="dashboard-brief rounded py-4">
            <h2 class="call-count">
              {{ merchantOverview.pendingTransactionCount || "0" }}
            </h2>
            <p class="call-name">Pending Transaction Count</p>
          </div>
          <div class="dashboard-brief  rounded py-4">
            <h2 class="call-count">
              {{
                formatNumber(merchantOverview.paymetLinkTransaction, false) ||
                  "0"
              }}
            </h2>
            <p class="call-name">Payment Link Transactions</p>
          </div>
          <div class="dashboard-brief  rounded py-4">
            <h2 class="call-count">
              {{ formatNumber(merchantOverview.paymetPage, false) || "0" }}
            </h2>
            <p class="call-name">Payment Page</p>
          </div>
          <div class="dashboard-brief  rounded py-4">
            <h2 class="call-count">
              {{
                formatNumber(merchantOverview.paymetPageTransaction, false) ||
                  "0"
              }}
            </h2>
            <p class="call-name">Payment Page Transactions</p>
          </div>
          <div class="dashboard-brief rounded py-4">
            <h2 class="call-count">
              {{ formatNumber(merchantOverview.plansCount, false) || "0" }}
            </h2>
            <p class="call-name">Plans Count</p>
          </div>
          <div class="dashboard-brief rounded py-4">
            <h2 class="call-count">
              {{ formatNumber(merchantOverview.subAccountCount, false) || "0" }}
            </h2>
            <p class="call-name">Sub Account Count</p>
          </div>
          <div class="dashboard-brief rounded py-4">
            <h2 class="call-count">
              {{ formatNumber(merchantOverview.subcriptionCount, false) || "0" }}
            </h2>
            <p class="call-name">Subcription Count</p>
          </div>
          <div class="dashboard-brief rounded py-4">
            <h2 class="call-count">
              {{ formatNumber(merchantOverview.successTransactionCount, false) || "0" }}
            </h2>
            <p class="call-name">Success Transaction Count</p>
          </div>
          <div class="dashboard-brief rounded py-4">
            <h2 class="call-count">
              {{ formatNumber(merchantOverview.walletsCount, false) || "0" }}
            </h2>
            <p class="call-name">Wallet Count</p>
          </div>
          <div class="dashboard-brief rounded py-4">
            <h2 class="call-count">
			   {{ formatNumber(merchantOverview.failedTransactionCount, false) || "0" }}
            </h2>
            <p class="call-name">Failed Transaction Count</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <Spinner/>
  </div>
</template>

<script>
import { toCurrency, formatNumber } from "../../../../components/utils/helpers";
import Spinner from '../../../../components/utils/Spinner/Spinner'
export default {
  name: "Overview",
  props: ["merchantOverview",'loading'],
  methods: {
    toCurrency,
    formatNumber,
  },
  components: {
    Spinner
  }
};
</script>

<style scoped>
.dashboard-briefs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  /* grid-template-rows: 170px; */
}
.dashboard-briefs-sm {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  row-gap: 5px;
  /* grid-template-rows: 170px; */
}
.dashboard-brief {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}
.dashboard-brief-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}
/* .dashboard-brief:not(:last-of-type) {
  border-right: 1px solid #cccbcb;
} */

.dashboard-lead {
  color: white;
}
.side-border {
  border-left: 3px dotted white;
}

.dashboard-element {
  padding: 20px;
  color: inherit;
}
.call-count {
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  color: #071b2b;
  padding-top: 8px;
  margin-bottom: 20px;
}
.call-name {
  font-size: 15px;
  color: #081b2a;
  text-transform: uppercase;
}
</style>
