<template>
	<div class="col-md-12 col-lg-12 col-xl-12">
		<div class="user-settings-section no-border">
			<div class="user-setting-activity-logs card pt-3">
				<v-client-table
					v-if="customer.secondary_customers_info.length > 0"
					ref="serverTableRef"
					name="question-table"
					class="v-table"
					:data="customer.secondary_customers_info"
					:columns="columns"
					:options="options"
				>
					<div
						slot="serial_no"
						class="fields--truncated"
						slot-scope="props"
					>
						<span>{{ props.index }}</span>
					</div>
					<div
						slot="user.roles[0].role_label"
						class="fields--truncated"
						slot-scope="props"
					>
						<span>{{ props.row.user.roles[0].role_label }}</span>
					</div>
					<div slot="client_id" slot-scope="props">
						<button
							@click.prevent="
								viewCustomer(`/customer/${props.row.client_id}`)
							"
							class="btn btn-dark btn-sm"
						>
							<i class="typcn typcn-eye-outline mr-1"></i>View
							Customer
						</button>
					</div>
				</v-client-table>
				<DefaultContent
					v-else
					:image="'empty-resources.png'"
					:title="'No Customer Data'"
					:subtitle="
						'There are currently no customers in the system, do check back later.'
					"
				></DefaultContent>
			</div>
		</div>

		<!-- Modal section -->
	</div>
</template>

<script>
	// modal

	export default {
		props: {
			customer: {
				type: Object,
			},
		},
		data() {
			return {
				isProcessing: true,
				columns: [
					"serial_no",
					"user.firstname",
					"user.lastname",
					"user.email",
					"organization_name",
					"client_id",
				],
				options: {
					sortable: [],
					perPage: 50,
					pagination: {
						chunk: 3,
					},
					texts: {
						filter: "",
						filterPlaceholder: "Search..",
						noResults: "No matching records",
					},
					headings: {
						serial_no: "S/N",
						"user.firstname": "First Name",
						"user.lastname": "Last Name",
						"user.email": "Email",
						payment_date: "Transaction Date",
						client_id: "Action",
					},
				},
			};
		},
		methods: {
			viewCustomer(route) {
				location.assign(route);
			},
		},
	};
</script>

<style scoped>
	.service-header-title {
		color: #5a5e63;
		font-size: 16px;
		font-weight: 600;
		text-transform: none;
		letter-spacing: 0;
	}

	.api-service-header {
		padding-top: 16px;
		padding-bottom: 15px;
		width: 80%;
		display: inline-block;
		line-height: 1.5;
	}
	.api-service-header h1 {
		margin-bottom: 3px;
		font-weight: 400;
		font-size: 1.3rem;
		color: #353f45;
		-webkit-font-smoothing: antialiased;
	}
</style>
