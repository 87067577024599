import { apiClient } from './api'

export default {
	customerRegistration(customerDetails) {
		return apiClient.post(`Authorize/RegisterCustomer`, customerDetails)
	},
	authenticateUser(userDetail) {
		return apiClient.post(`Authorize/Login`, userDetail)
	},
	SendPasswordResetLink(email) {
		return apiClient.post(`Authorize/SendPasswordResetLink`, email)
	},
	ActivateAccount(token) {
		return apiClient.get(`Authorize/ActivateAccount/${token}`)
	},
	ForgotPassword(userDetails) {
		return apiClient.post(`Authorize/ForgotPassword`, userDetails)
	}
}
