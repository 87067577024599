<template>
	<div class="mr-3">
		<div class="dropdown az-profile-menu">
			<img
				type="button dropdown-toggle"
				data-toggle="dropdown"
				src="/assets/img/ellipsis.svg"
				alt
				width="30px"
			/>

			<div class="dropdown-menu">
				<span class="dropdown-item" @click="openAddServiceModal">
					Add New Provider Parameter
				</span>

				<span class="dropdown-item" @click="openEditProviderModal">
					Update Provider
				</span>
				<span class="dropdown-item" @click="openDeleteProviderModal">
					Delete Provider
				</span>
			</div>
		</div>

		<AddProviderParametersModal
			:id="`${provider.id}`"
			:provider="provider"
			:refetch="refetchSevice"
			:closeModal="closAddproviderModal"
			:apiServices="apiServices"
		/>

		<AddProviderModal
			:id="`update${provider.id}`"
			:provider="provider"
			:refetch="refetch"
			:closeModal="closeEditProviderModal"
		/>
		<DeleteServiceProviderModal
			:id="`delete${provider.id}`"
			:provider="provider"
			:refetch="refetch"
			:closeModal="closeDeleteProviderModal"
		/>
	</div>
</template>

<script>
	import AddProviderModal from "../../modals/AddProviderModal/AddProviderModal";
	import AddProviderParametersModal from "../../modals/AddProviderParametersModal/AddProviderParametersModal";
	import DeleteServiceProviderModal from "../../modals/DeleteServiceProviderModal/DeleteServiceProviderModal";

	export default {
		name: "ProviderActions",
		props: ["provider", "refetch", "refetchSevice", "apiServices"],
		components: {
			AddProviderModal,
			AddProviderParametersModal,
			DeleteServiceProviderModal
		},
		methods: {
			openAddServiceModal(e) {
				$(`#${this.provider.id}`).modal("show");
			},
			closAddproviderModal() {
				$(`#${this.provider.id}`).modal("hide");
			},
			openEditProviderModal(e) {
				$(`#update${this.provider.id}`).modal("show");
			},
			closeEditProviderModal() {
				$(`#update${this.provider.id}`).modal("hide");
			},
			openDeleteProviderModal() {
				$(`#delete${this.provider.id}`).modal("show");
			},
			closeDeleteProviderModal() {
				$(`#delete${this.provider.id}`).modal("hide");
			},
		},
	};
</script>
