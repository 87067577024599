<template>
  <AuthWrapper>
    <div class="no-sm-padding col-xl-4 col-lg-5 col-md-4 col-sm-12">
      <div class="card">
        <div class="card-innr auth-card">
          <a href="index.php" class="logo">
            <img src="/assets/img/blusalt-logo-sb-inverse.svg" alt />
          </a>

          <div
            class="card-header-w-des no-border align-items-center justify-content-between"
          >
            <div style="text-align: center; margin: 40px 0px 30px;">
              <h2 style="  font-weight: 500;  letter-spacing: -0.02em;">
                Building the future of financial services
              </h2>
              <h4 class="card-title" style="color: rgb(34, 34, 34);">
                Welcome Back, Please login to your account.
              </h4>
            </div>
          </div>
          <form @submit.prevent="performLogin">
            <div class="form-group">
              <label class="input-item-label">Email</label>
              <input
                type="email"
                name="email"
                class="form-control input-bordered"
                placeholder="Enter your email"
                v-model.trim="username"
              />
              <ul
                class="parsley-errors-list filled"
                id="parsley-id-26"
                v-show="$v.username.$error"
              >
                <li class="parsley-required">Username is required.</li>
              </ul>
            </div>
            <div class="form-group mg-b-20">
              <label class="input-item-label">Password</label>
              <input
                type="password"
                name="password"
                class="form-control input-bordered"
                placeholder="Enter your password"
                v-model="password"
              />
              <ul
                class="parsley-errors-list filled"
                id="parsley-id-26"
                v-show="$v.password.$error"
              >
                <li class="parsley-required">Password is required.</li>
              </ul>
            </div>

            <div class="pb-3">
              <router-link to="/forgot-password" class="left"
                >Forgot password?</router-link
              >
            </div>
            <div class="gaps-2x"></div>

            <button
              type="submit"
              class="btn btn-lg btn-primary btn-block mg-b-20"
              :disabled="isProcessing == true"
            >
              <span v-if="isProcessing">Attempting to sigin</span>
              <span v-else>Sign In</span>
            </button>
            <!-- <p>
          Don't have an account?
          <router-link to="/register">Create one now</router-link>
            </p>-->
          </form>
          <div class="az-signup-footer">
            <p>
              © {{ date }} Blusalt Financial Services Ltd. All rights reserved.
              <a href="http://blusalt.io">
                Go to website
                <i class="la la-arrow-right"></i>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </AuthWrapper>
</template>

<script>
import Nprogress from "nprogress";
import { required } from "vuelidate/lib/validators";
import AuthWrapper from "@/components/auth/AuthWrapper";
import { notifications } from "../../public/assets/mixins/notifications";

export default {
  name: "Login",
  mixins: [notifications],
  components: {
    AuthWrapper,
  },
  data() {
    return {
      date: new Date().getFullYear(),
      username: "",
      password: "",
      isProcessing: false,
    };
  },
  validations: {
    username: {
      required,
    },
    password: {
      required,
    },
  },
  methods: {
    async performLogin() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("[invalid fields] ->");
        return;
      }

      Nprogress.start();
      this.isProcessing = true;

      let credential = {
        username: this.username,
        password: this.password,
      };
      try {
        let apiResponse = await this.$store.dispatch(
          "auth/loginUser",
          credential
        );
        console.log("[apiResponse of login] ->", apiResponse);
        Nprogress.done();
        this.showSuccessNotification(apiResponse.data.message);
        setTimeout(() => {
          location.replace("/account");
        }, 2000);
      } catch (error) {
        Nprogress.done();
        this.isProcessing = false;
        console.log("[] ->", error.response);
        this.showErrorLoginNotification(error.message);
      }
    },
  },
};
</script>

<style scoped>
.no-sm-padding {
  margin-left: 20px;
}
</style>
