<script>
export default {
  name: "SideModal",
  props: ["title", "subTitle", "toggle", "show"],
};
</script>

<template>
  <div class="side-modal-root" v-if="show">
    <div class="side-modal-container col-md-6 col-lg-6 col-xl-4">
      <div class="side-modal-header mb-4">
        <span class="title">{{ title }}</span>
        <div class="description">
          {{ subTitle }}
        </div>
      </div>
      <button class="close" @click="toggle">
        <img src="/assets/img/close-circle.svg" alt="image" height="30" />
      </button>
      <div class="side-modal-body">
        <slot></slot>
      </div>
    </div>
  </div>
  <div v-else></div>
</template>

<style scoped>
.side-modal-root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100vw;
  z-index: 100;
  display: flex;
  justify-content: flex-end;
}

.side-modal-container {
  overflow-y: scroll;
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  border: 10px;
  height: 100vh;
  padding: 2rem;
}

.side-modal-header .title {
  font-size: 1.9em;
  font-weight: 600;
  display: block;
  color: #2d383e;
}

.side-modal-header .description {
  font-size: 16px;
  line-height: 1.5;
  color: #5d6a74;
  margin-bottom: 18px;
  -webkit-font-smoothing: antialiased;
}

.close {
  position: absolute;
  right: 35px;
  top: 18px;
}
</style>
