<template>
  <div>
    <div class="row">
      <div class="col-sm-10"></div>
      <div class="col-sm-2">
        <CustomSelect name="filterBy" placeholder="Filter By" :optionLabel="'name'" :options="terminalStatFilter"
          v-model="filterBy" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-4">
        <h3 class="font-weight-bold">Transfer </h3>
        <apexchart v-if="transferStatsSeries[0].data.length != 0" type="bar" height="400" width="1200"
          :options="transferData" :series="transferStatsSeries">
        </apexchart>
        <div v-else class="h-400 flex__center bg-light">
          <h5 class="my-4">No Data Available</h5>
        </div>
      </div>
      <div class="col-md-6 mb-6">
        <h3 class="font-weight-bold">Payout </h3>
        <apexchart v-if="payoutStatsSeries[0].data.length != 0" type="bar" height="400" width="1200"
          :options="payoutData" :series="payoutStatsSeries"></apexchart>
        <div v-else class="h-400 flex__center bg-light">
          <h5 class="my-4">No Data Available</h5>
        </div>
      </div>
      <div class="col-md-6">
        <h3 class="font-weight-bold">Charges </h3>
        <apexchart v-if="chargeStatsSeries[0].data.length != 0" type="bar" height="400" width="1200"
          :options="chargeData" :series="chargeStatsSeries"></apexchart>
        <div v-else class="h-400 flex__center bg-light">
          <h5 class="my-4">No Data Available</h5>
        </div>
      </div>
      <div class="col-md-6">
        <h3 class="font-weight-bold">Collection </h3>
        <apexchart v-if="collectionStatsSeries[0].data.length != 0" type="bar" height="400" width="1200"
          :options="collectionData" :series="collectionStatsSeries">
        </apexchart>
        <div v-else class="h-400 flex__center bg-light">
          <h5 class="my-4">No Data Available</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  toCurrency,
  formatNumber,
  graphDateFormatter,
  terminalStatFilter,
  getTerminalsDateRangeFromValue
} from "../utils/helpers";
import CustomSelect from "../utils/CustomSelect/CustomSelect";
import { titleCase } from "../utils/helpers";

export default {
  name: "PaymentOverviewStatistics",
  data() {
    return {
      chartData: [],
      filterBy: "This Week",
      terminalStatFilter,
      series: [
        {
          name: "Total Charges",
          data: [],
        },
      ],
      transferStatsSeries:[],
      payoutStatsSeries:[],
      chargeStatsSeries:[],
      collectionStatsSeries:[],
      transferData:{},
      payoutData:{},
      chargeData:{},
      collectionData:{},
      chartOptions: {
        chart: {
          type: "bar",
          height: 430,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [],
        },
      },
    };
  },
  components: { CustomSelect },
  methods: {
    toCurrency,
    formatNumber,
    graphDateFormatter,
    
    async fetchPaymentStatistics(filterBy) {
      const range = getTerminalsDateRangeFromValue(filterBy)
      const response = await this.$store.dispatch(
        "payment/getPaymentOverviewStatistics", { params: range }
      );
      const { transferStats, payoutStats, chargeStats, collectionStats } = response.data
      const transferXAxis = transferStats.map(dt => `${titleCase(Object.keys(dt)[0]) } ${Object.values(dt)[0]}`);
      const transferYAxis = transferStats.map(dt => parseInt(dt.total_amount));
      const chargeStatsXAxis = chargeStats.map(dt => `${titleCase(Object.keys(dt)[0]) } ${Object.values(dt)[0]}`);
      const chargeStatsYAxis = chargeStats.map(dt => parseInt(dt.total_charges));
      const payoutStatsXAxis = payoutStats.map(dt => `${titleCase(Object.keys(dt)[0])} ${Object.values(dt)[0]}`);
      const payoutStatsYAxis = payoutStats.map(dt => parseInt(dt.total_amount));
      const collectionStatsXAxis = collectionStats.map(dt => `${titleCase(Object.keys(dt)[0]) } ${Object.values(dt)[0]}`);
      const collectionStatsYAxis = collectionStats.map(dt => parseInt(dt.total_amount));

      this.transferData = {
        ...this.chartOptions,
        xaxis: { categories: transferXAxis },
      };
      this.chargeData = {
        ...this.chartOptions,
        xaxis: { categories: chargeStatsXAxis },
      };
      this.payoutData = {
        ...this.chartOptions,
        xaxis: { categories: payoutStatsXAxis },
      };
      this.collectionData = {
        ...this.chartOptions,
        xaxis: { categories: collectionStatsXAxis },
      };
      this.transferStatsSeries = [
        {
          data: transferYAxis,
        },
      ];
      this.payoutStatsSeries = [
        {
          data: payoutStatsYAxis,
        },
      ]
      this.chargeStatsSeries = [
        {
          data: chargeStatsYAxis,
        },
      ]
      this.collectionStatsSeries = [
        {
          data: collectionStatsYAxis,
        },
      ]
      
      this.loading = false;
    },
  },
  watch: {
    filterBy(newVal, oldVal) {
      this.fetchPaymentStatistics(newVal.value);
    },
  },
  created() {
    this.fetchPaymentStatistics(this.filterBy);
  },
};
</script>

<style scoped>
.h-400{
  min-height: 400px;
}
</style>
