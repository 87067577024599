<template>
	<div class="az-header az-header-dashboard-nine">
		<div class="container-fluid">
			<div class="az-header-left">
				<a href id="azSidebarToggle" class="az-header-menu-icon">
					<span></span>
				</a>
			</div>
			<div class="az-header-right">
				<span class="top-nav-partition"></span>
				<div class="az-header-support">
					<a href="#" title="support">
						<i class="la la-life-buoy"></i>
					</a>
				</div>
				<div class="az-header-notification">
					<a href="#" class="new" title="notifications">
						<i class="la la-bell"></i>
					</a>
				</div>
				<div class="dropdown az-profile-menu">
					<a href class="az-profile-link">
						<div class="az-img-user">
							<img src="/assets/img/avatar1.svg" alt />
						</div>
						<em class="az-header-arrow fas fa-chevron-down"></em>
					</a>
					<div class="dropdown-menu">
						<div class="az-dropdown-header d-sm-none">
							<a href class="az-header-arrow"></a>
						</div>
						<div class="user-info">
							<div>
								<img
									class="user-avatar"
									src="/assets/img/avatar1.svg"
									alt
								/>
							</div>
							<div class="user-details">
								<span class="user-name" title="Brian Wilson"
									>{{ user.firstname }}
									{{ user.lastname }}</span
								>
								<span
									class="user-email"
									title="brian.wilson@opay.com"
									>{{ user.email }}</span
								>
							</div>
						</div>
						<router-link to="/account" class="dropdown-item">
							<i class="la la-user"></i> My Profile
						</router-link>
						<a href="#" class="dropdown-item" v-if="false">
							<i class="la la-credit-card"></i> Financials
						</a>
						<a href="#" class="dropdown-item" v-if="false">
							<i class="la la-terminal"></i> API Docs
						</a>
						<a @click="logout" href="#" class="dropdown-item">
							<i class="la la-sign-out"></i> Sign Out
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	computed: {
		user() {
			return this.$store.state.account.profile;
		},
	},
	methods: {
		logout() {
			this.$store.dispatch("account/logout");
		},
	},
};
</script>
