<template>
  <SideModal :show="show" :toggle="toggle" :title="`Dispute Info`" :subTitle="'Opened Dispute informations'">
    <div class="row mt-2 pt-4">
      <!-- <div class="col-12 mb-4">
        <button type="submit" class="btn btn-lg btn-primary mr-4">
          Refund
        </button>
        <button type="submit" class="btn btn-lg btn-outline-primary text-dark">
          Retriger Notification
        </button>
      </div> -->
      <div class="col-6">
        <p>
          <span class="text-muted">AMOUNT</span> <br />
          <span class="text-capitalize h3">
            &#8358;{{ formatNumber(singleTransactionObj.amount) || "0" }}
          </span>
        </p>
      </div>
      <div class="col-6">
        <span class="badge badge-app text-capitalize" :class="
  singleTransactionObj.status == 'resolved'
              ? 'badge-success'
              : 'badge-danger'
          ">
          {{ singleTransactionObj.status }}
        </span>
      </div>
      <br />
      <div class="col-6 mt-2" v-if="singleTransactionObj.first_name">
        <p>
          <span class="text-muted text-uppercase">Customer Name</span>
          <br />
          <span class="text-capitalize h5">{{ singleTransactionObj.first_name }}
            {{ singleTransactionObj.last_name }}</span>
        </p>
      </div>
      <div class="col-6 mt-2" v-if="singleTransactionObj.email">
        <p>
          <span class="text-muted text-uppercase">Email Address</span>
          <br />
          <span class="text-capitalize h5">{{
            singleTransactionObj.email
            }}</span>
        </p>
      </div>
      <div class="col-6 mt-2" v-if="singleTransactionObj.phone_number ">
        <p>
          <span class="text-muted text-uppercase">Phone Number</span>
          <br />
          <span class="text-capitalize h5">{{
            singleTransactionObj.phone_number || 'Not Available'
            }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">Dispute Type</span>
          <br />
          <span class="text-capitalize h5">Null</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">transaction id</span> <br />
          <span class="text-capitalize h5">
            {{ singleTransactionObj.reference }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">transaction date</span> <br />
          <span class="text-capitalize h5">
            {{ singleTransactionObj.updatedAt | moment(" MM-DD-YYYY h:mm a") }}
          </span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">Reason</span> <br />
          <span class="text-capitalize h5">
            {{ singleTransactionObj.reason || 'Not Available' }}
          </span>
        </p>
      </div>
    </div>
    <br />
    <hr />
    <br />
    <div class="flex__between">
      <h3>Dispute Proof</h3>
      <a :href="singleTransactionObj.proof_url" v-if="singleTransactionObj.proof_url" download
        class="btn btn-light">Download Proof</a>
    </div>
    <div class="row mt-2">
      <div v-if="singleTransactionObj.proof_url">
        <img class="img-fluid" :src="singleTransactionObj.proof_url" alt="dispute_proof" height="">
      </div>
      <p v-else>No proof</p>
    </div>
    <br />
    <button @click="updateDispute('pending')" class="btn btn-primary d-block"
      v-if="singleTransactionObj.status == 'resolved'">
      Re-open
    </button>
    <div class="mt-2" v-else>
      <button class="btn btn-primary mr-2" @click="updateDispute('resolved')">
        Resolve
      </button>
      <button class="btn btn-danger" @click="updateDispute('closed')">
        Close
      </button>
    </div>
  </SideModal>
</template>

<script>
import { notifications } from "../../../../public/assets/mixins/notifications";
import SideModal from "../../../components/utils/Modal/SideModal.vue";
import { formatNumber, titleCase } from "../../../components/utils/helpers";

export default {
  mixins: [notifications],
  name: "PaymentDisputesDetailModal",
  data() {
    return {};
  },
  props: ["toggle", "show", "singleTransactionObj", 'refetch','filters'],
  components: {
    SideModal,
  },
  methods: {
    formatNumber,
    titleCase,
    handleCloseModal() {
      this.toggle();
    },
    async updateDispute(status) {
      const params = { status };
      const response = await this.$store.dispatch(
        "payment/updatePaymentDispute",
        {
          id: this.singleTransactionObj.reference,
          params
        }
      );

      this.refetch(this.filters)
      this.toggle()
      this.showSuccessNotification(response.message);
    },
  },
};
</script>

<style scoped>
.d-flex {
  display: flex;
  justify-content: flex-end;
}
</style>
