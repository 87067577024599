var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 pt-4"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"dashboard-briefs"},[_c('div',{staticClass:"dashboard-brief rounded py-4"},[_c('h2',{staticClass:"call-count"},[_vm._v(" ₦"+_vm._s(_vm.formatNumber(_vm.overview.total_credits || "0"))+" ")]),_c('p',{staticClass:"call-name"},[_vm._v("Total Balance")])]),_c('div',{staticClass:"dashboard-brief rounded py-4"},[_c('h2',{staticClass:"call-count"},[_vm._v(" ₦"+_vm._s(_vm.formatNumber(_vm.overview.amount_remaining) || "0")+" ")]),_c('p',{staticClass:"call-name"},[_vm._v("Available Balance")])]),_c('div',{staticClass:"dashboard-brief rounded py-4"},[_c('h2',{staticClass:"call-count"},[_vm._v(" ₦"+_vm._s(_vm.formatNumber(_vm.overview.total_amount_charged) || "0")+" ")]),_c('p',{staticClass:"call-name"},[_vm._v("Total Amount")])]),_c('div',{staticClass:"dashboard-brief rounded py-4"},[_c('h2',{staticClass:"call-count"},[_vm._v(" "+_vm._s(_vm.overview.credit_count || "0")+" ")]),_c('p',{staticClass:"call-name"},[_vm._v("Credit Count")])])])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"dashboard-brief-chart p-4"},[_c('h2',{staticClass:"call-count text-left"},[_vm._v(" Log Value ")]),_c('apexchart',{attrs:{"height":"400","width":"300","type":"donut","options":_vm.chartOptions,"series":[
            parseInt(
              _vm.overview.amount_remaining - _vm.overview.total_amount_charged
            ),
            parseInt(_vm.overview.amount_remaining),
          ]}})],1),_c('div',{staticClass:"dashboard-brief-chart p-4 d-none"},[_c('h2',{staticClass:"call-count text-left"},[_vm._v(" Funding Type ")]),_c('apexchart',{attrs:{"height":"400","type":"donut","options":{},"series":[44, 55]}})],1)]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"dashboard-brief-chart p-4"},[_c('h2',{staticClass:"call-count text-left"},[_vm._v(" Log Statistics ")]),_c('CreditLogStat')],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"SectionItem no-b-b mb-3"},[_c('div',[_c('p',{staticClass:"title m-0"},[_vm._v("Credit Log Listing")]),_c('p',{staticClass:"text-left description"},[_vm._v(" View all transactions within the system ")])])])
}]

export { render, staticRenderFns }