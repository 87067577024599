import { apiClient } from "./api";

export default {
  getPendingRequests(PendingRequests) {
    return apiClient.get(
      `/Authorize/FundsTransfer/Admin/ServiceRequest/Pending`,
      PendingRequests
    );
  },
  getProcessingRequests(ProcessingRequests) {
    return apiClient.get(
      `/Authorize/FundsTransfer/Admin/ServiceRequest/Processing`,
      ProcessingRequests
    );
  },
  getActivatedRequests(ActivatedRequests) {
    return apiClient.get(
      `/Authorize/FundsTransfer/Admin/ServiceRequest/Approved`,
      ActivatedRequests
    );
  },
  getFundingSource(fundingSource) {
    return apiClient.get(
      `/Authorize/FundsTransfer/FundingSource`,
      fundingSource
    );
  },
  getFTRequests(FTRequests) {
    return apiClient.get(
      `/Authorize/FundsTransfer/Admin/ServiceRequest`,
      FTRequests
    );
  },
  getFTCustomers(FTCustomers) {
    return apiClient.get(
      `/Authorize/FundsTransfer/Admin/Customer`,
      FTCustomers
    );
  },
  getFTBusinessInfo(bizInfo) {
    return apiClient.get(
      `/Authorize/FundsTransfer/Admin/ServiceRequest/${bizInfo}`
    );
  },
  getCustomerBusinessInfo(bizInfo) {
    return apiClient.get(`/Authorize/FundsTransfer/Admin/Customer/${bizInfo}`);
  },
  getCustomerTransactions(CustomerTransactions) {
    return apiClient.get(
      `/Authorize/FundsTransfer/Admin/Customer/${CustomerTransactions}/Transaction`
    );
  },
  getAllTransactions(AllTransactions) {
    return apiClient.get(
      `/Authorize/FundsTransfer/Admin/Transaction`,
      AllTransactions
    );
  },
  getSuccessfulTransactions(SuccessTransactions) {
    return apiClient.get(
      `/Authorize/FundsTransfer/Admin/Transaction/Success`,
      SuccessTransactions
    );
  },
  getFailedTransactions(FailedTransactions) {
    return apiClient.get(
      `/Authorize/FundsTransfer/Admin/Transaction/Failed`,
      FailedTransactions
    );
  },
  getPendingTransactions(PendingTransactions) {
    return apiClient.get(
      `/Authorize/FundsTransfer/Admin/Transaction/Pending`,
      PendingTransactions
    );
  },
  getSingleTransaction(transaction_reference) {
    return apiClient.get(
      `/Authorize/FundsTransfer/Admin/Transaction/${transaction_reference}`
    );
  },
  getBankList(bankList) {
    return apiClient.get(`/Authorize/Admin/FundsTransfer/BankList`, bankList);
  },
  submitBusinessInformation(businessInformation) {
    let headers = { clientid: businessInformation.clientID };
    return apiClient.put(
      `/Authorize/FundsTransfer/Admin/Customer/${businessInformation.clientID}/Business`,
      businessInformation.payload,
      { headers }
    );
  },
  submitBusinessSocials(businessSocials) {
    let headers = { clientid: businessSocials.clientID };
    return apiClient.put(
      `/Authorize/FundsTransfer/Admin/Customer/${businessSocials.clientID}/Business/SocialMediaLinks`,
      businessSocials.payload,
      { headers }
    );
  },
  submitBusinessAddress(businessAddress) {
    let headers = { clientid: businessAddress.clientID };
    return apiClient.put(
      `/Authorize/FundsTransfer/Admin/Customer/${businessAddress.clientID}/Business/Address`,
      businessAddress.payload,
      { headers }
    );
  },
  submitBusinessEmails(businessEmails) {
    let headers = { clientid: businessEmails.clientID };
    return apiClient.put(
      `/Authorize/FundsTransfer/Admin/Customer/${businessEmails.clientID}/Business/Email`,
      businessEmails.payload,
      { headers }
    );
  },
  submitBusinessContactInfo(businessContactInfo) {
    let headers = { clientid: businessContactInfo.clientID };
    return apiClient.put(
      `/Authorize/FundsTransfer/Admin/Customer/${businessContactInfo.clientID}/Business/Contact`,
      businessContactInfo.payload,
      { headers }
    );
  },
  submitFundingSource(FundingSource) {
    let headers = { clientid: FundingSource.clientID };
    return apiClient.put(
      `/Authorize/FundsTransfer/Admin/Customer/${FundingSource.clientID}/Business/FundingSource`,
      FundingSource.payload,
      { headers }
    );
  },
  verifyBankDetails(BankDetails) {
    let headers = { clientid: BankDetails.clientID };
    return apiClient.put(
      `/Authorize/FundsTransfer/Admin/Customer/${BankDetails.clientID}/Business/BankDetails`,
      BankDetails.data,
      { headers }
    );
  },
  verifyAccountNumber(accountNumber) {
    return apiClient.post(
      `/Authorize/FundsTransfer/VerifyBankAccount`,
      accountNumber
    );
  },
  submitIPDetails(IPDetails) {
    let headers = { clientid: IPDetails.clientID };
    return apiClient.put(
      `/Authorize/FundsTransfer/Admin/Customer/${IPDetails.clientID}/Business/Ip`,
      IPDetails.payload,
      { headers }
    );
  },
  submitETZEmail(ETZEmail) {
    return apiClient.put(
      `Authorize/FundsTransfer/Admin/Settings/ETZ/Email`,
      ETZEmail
    );
  },
  getETZEmail(ETZEmail) {
    return apiClient.get(`Authorize/FundsTransfer/Admin/Settings`, ETZEmail);
  },
  sendInfoToETZ(ETZDetails) {
    return apiClient.get(
      `/Authorize/FundsTransfer/Admin/SendToEtz/${ETZDetails}`
    );
  },
  updateTerminalID(TerminalID) {
    return apiClient.patch(
      `/Authorize/FundsTransfer/Admin/EtzTerminalId/${TerminalID.id}`,
      TerminalID.payload
    );
  },
  generateAuthKeys(AuthKeys) {
    return apiClient.get(
      `Authorize/FundsTransfer/Admin/FtBusinessInformation/${AuthKeys}/AuthorizationKeys`
    );
  },
  getDefaultPricing(DefaultPricing) {
    return apiClient.put(
      `Authorize/FundsTransfer/Admin/Pricing/Default`,
      DefaultPricing
    );
  },
  approveRequest(Request) {
    return apiClient.get(
      `Authorize/FundsTransfer/Admin/ServiceRequest/ft_business_information_id/${Request}/Approve`
    );
  },
  createBilling(billing) {
    let headers = { clientid: billing.clientID };
    return apiClient.post(
      `Authorize/FundsTransfer/Admin/Pricing/${billing.billing_id}/Custom/Customer/${billing.id}`,
      billing.payload,
      { headers }
    );
  },
  editBilling(billing) {
    let headers = { clientid: billing.clientID };
    return apiClient.put(
      `Authorize/FundsTransfer/Admin/Pricing/${billing.billing_id}/Custom/Customer/${billing.id}/BillingRate/${billing.billing_rate_id}`,
      billing.payload,
      { headers }
    );
  },
  getBilling(billing) {
    return apiClient.get(
      `Authorize/FundsTransfer/Admin/Billing/Customer/${billing}`
    );
  },
  GetFTOverview(query) {
    return apiClient.get(`Authorize/admin/fundTransfer/overview`, query);
  },
  GetFTStats(query) {
    return apiClient.get(
      `Authorize/admin/fundTransfer/statistics?filterBy=${query}`
    );
  },
  GetFTProviders(query) {
    return apiClient.get(`Authorize/admin/fundTransfer/providers`, query);
  },
  GetFTtransactions(query) {
    return apiClient.get(`Authorize/admin/fundTransfer/transactions`, query);
  },
  GetFTProviderDetail(query) {
    return apiClient.get(`Authorize/admin/fundTransfer/provider/${query.id}`);
  },
  GetFTProviderStats(query) {
    return apiClient.get(
      `Authorize/admin/fundTransfer/provider/${query.id}/statistics?filterBy=${query.filterBy}`
    );
  },
  GetFTProviderTransactions(query) {
    return apiClient.get(
      `Authorize/admin/fundTransfer/provider/${query.params.id}/transactions`,
      query
    );
  },
  GetFTProviderIntegrations(query) {
    return apiClient.get(
      `Authorize/admin/fundTransfer/provider/${query.params.id}/integrations`
    );
  },
  DeleteFTProviderWebHook(id) {
    console.log({ id });
    return apiClient.delete(
      `Authorize/admin/fundTransfer/provider/${id}/webhooks`
    );
  },
  DeleteFTProviderKey(id) {
    console.log({ id });
    return apiClient.delete(`Authorize/admin/fundTransfer/provider/${id}/keys`);
  },
  CreateFTProviderKey(query) {
    return apiClient.post(
      `Authorize/admin/fundTransfer/provider/${query.providerCbnCode}/keys`,
      query
    );
  },
  CreateFTProviderWebHook(query) {
    return apiClient.post(
      `Authorize/admin/fundTransfer/provider/${query.providerCbnCode}/webhooks`,
      query
    );
  },
  CreateFTProviderRoutes(query) {
    return apiClient.post(
      `Authorize/admin/fundTransfer/provider/${query.providerCbnCode}/routes`,
      query
    );
  },
  EditFTProviderRoutes(query) {
    return apiClient.patch(
      `Authorize/admin/fundTransfer/provider/${query.id}/routes`,
      {
        endpoint: query.endpoint,
        cost: query.cost,
        httpMethod: query.httpMethod,
        priority: query.priority,
        providerCbnCode: query.providerCbnCode,
      }
    );
  },
};
