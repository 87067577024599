<template>
  <div class="col-12">
    <div class="dashboard-briefs">
      <div class="dashboard-brief rounded py-4">
        <h2 class="call-count">
          {{ providerOverview.total_count || "0" }}
        </h2>
        <p class="call-name mb-0">Number of Transactions</p>
      </div>
      <div class="dashboard-brief rounded py-4">
        <h2 class="call-count">
          &#8358;{{ formatNumber(providerOverview.total_amount) || "0" }}
        </h2>
        <p class="call-name mb-0">Total Amount</p>
      </div>
      <div class="dashboard-brief  rounded py-4">
        <h2 class="call-count">
          &#8358;{{ formatNumber(providerOverview.total_charges) || "0" }}
        </h2>
        <p class="call-name mb-0">Total Charges</p>
      </div>
    </div>
   
  </div>
</template>

<script>
import { toCurrency, formatNumber } from "../../../../components/utils/helpers";
export default {
  name: "Overview",
  props: ["providerOverview"],
  methods: {
    toCurrency,
    formatNumber,
  },
};
</script>

<style scoped>
.dashboard-briefs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  /* grid-template-rows: 170px; */
}
.dashboard-briefs-sm {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  row-gap: 5px;
  /* grid-template-rows: 170px; */
}
.dashboard-brief {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}
.dashboard-brief-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}
/* .dashboard-brief:not(:last-of-type) {
  border-right: 1px solid #cccbcb;
} */

.dashboard-lead {
  color: white;
}
.side-border {
  border-left: 3px dotted white;
}

.dashboard-element {
  padding: 20px;
  color: inherit;
}
.call-count {
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  color: #071b2b;
  padding-top: 8px;
  margin-bottom: 20px;
}
.call-name {
  font-size: 15px;
  color: #081b2a;
  text-transform: uppercase;
}
</style>
