<template>
  <div>
    <TableWrapper
      :loading="loading"
      :errorMsg="errorMsg"
      :refetch="fetchApiKeys"
      :isEmpty="isEmpty"
      :emptyTitle="emptyTitle"
    >
      <div class="w-100 py-3" slot="content">
        <v-server-table
          class="v-table row-pointer"
          :data="customers"
          :columns="columns"
          :options="options"
          @loaded="onLoaded"
        >
          
        </v-server-table>
      </div>
    </TableWrapper>
  </div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../../../components/utils/TableWrapper/TableWrapper.vue";

const plansHeadings = {
  name: "Key Type",
  hash: "Key",
  hassh: " ",
  hasdh: " ",
  haadh: " ",
};

export default {
  name: "ApiKeys",
  mixins: [componentData],
  data() {
    return {
      singleInviteObj: null,
      showSingleInviteModal: false,
      emptyTitle: "No Keys Found",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(plansHeadings, 25),
    };
  },
  components: {
    TableWrapper,
  },
  methods: {
    async fetchApiKeys() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters, reference: this.clientid };
        const response = await this.$store.dispatch(
          "customer/getSettingsInfo",
          {
            params,
          }
        );
        const tempData = response.data.apiAndWebhooks.data;
        const newData = [
          { name: "Public Key", value: tempData.publicKey, hash: "****" },
          { name: "API Key", value: tempData.apiKey, hash: "****" },
          { name: "Test API Key", value: tempData.testApiKey, hash: "****" },
          {
            name: "Test Public Key",
            value: tempData.testPublicKey,
            hash: "****",
          },
        ];

        return { count: 4, total: 1, data: newData };
      };
    },
  },

  props: ["clientid"],
  created() {
    this.fetchApiKeys();
  },
  mounted() {
    this.columns = Object.keys(plansHeadings).map((keyVal) => keyVal);
  },
};
</script>
