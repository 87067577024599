<template>
  <div class="az-content-body">
    <div class="row">
      <div class="col-md-12">
        <div class="SectionItem d-flex justify-content-between">
          <div class="SectionHeader">
            <span class="title">Business Information</span>
            <div class="description">Submitted information from the customer.</div>
          </div>
          <div>
            <button
              class="btn btn-sm btn-primary btn-block mg-b-20"
              @click.prevent="sendToETZ"
              :disabled="submitting == true"
            >
              <span v-if="submitting == true">Sending info to eTranzact...</span>
              <span v-else>Send info to eTranzact</span>
            </button>
          </div>
        </div>
      </div>
      <!-- biz info section -->
      <button
        class="btn btn-sm btn-primary mg-b-20"
        @click.prevent="back"
        :disabled="submitting == true"
      >Go Back</button>
      <!-- nav tabs -->
      <div class="row">
        <div class="col-12">
          <nav>
            <div
              class="nav-tab__user-account nav nav-tabs"
              style="top: -1px;"
              id="nav-tab"
              role="tablist"
            >
              <a
                data-toggle="tab"
                id="nav-all-users-tab"
                href="#nav-all-users"
                role="tab"
                aria-controls="nav-all-users"
                aria-selected="false"
                class="nav-link active"
              >Business Information</a>
              <a
                data-toggle="tab"
                id="nav-settings-tab"
                href="#nav-transfer"
                role="tab"
                aria-controls="nav-transfer"
                aria-selected="false"
                class="nav-link"
              >Business Funding Source</a>
              <a
                data-toggle="tab"
                id="nav-settings-tab"
                href="#nav-billing"
                role="tab"
                aria-controls="nav-billing"
                aria-selected="false"
                class="nav-link"
              >Billing</a>
            </div>
          </nav>
        </div>

        <div class="col-12 mt-4 tab-content" id="nav-tabContent">
          <div
            class="dashboard rounded p-4 tab-pane fade show active"
            id="nav-all-users"
            role="tabpanel"
            aria-labelledby="nav-all-users-tab"
          >
            <h4>Business Information Details</h4>
            <h5 class="user-profile-email sub">Provide the following information on your business.</h5>
          </div>
          <div
            class="tab-pane fade dashboard rounded p-4"
            id="nav-transfer"
            role="tabpanel"
            aria-labelledby="nav-settings-tab"
          >
            <h4>Business Funding Source</h4>
            <h5 class="user-profile-email sub">Details about business funding source account below.</h5>
          </div>
          <div
            class="tab-pane fade dashboard rounded p-4"
            id="nav-billing"
            role="tabpanel"
            aria-labelledby="nav-settings-tab"
          >
            <h4>Billing</h4>
            <h5 class="user-profile-email sub">Business' Preferred billing</h5>
          </div>
        </div>
      </div>
      <!-- End row -->
    </div>

    <!-- End AZ -->
  </div>
</template>

<script>
import Nprogress from "nprogress";
import AuthWrapper from "@/components/auth/AuthWrapper";
import { notifications } from "../../public/assets/mixins/notifications";

export default {
  name: "FundsTransfer",
  mixins: [notifications],
  components: {
    AuthWrapper,
  },
  data() {
    return {
      isLoading: true,
      metaData: {},
      submitting: false,
      bizInfo: {}
    };
  },
  watch: {},

  computed: {},
  async mounted() {
    await this.getFTBusinessInfo(this.$route.params.id)
  },
  methods: {
    back() {
      history.back();
    },
    async getFTBusinessInfo(id) {
      try {
        await this.$store.dispatch('fundstransfer/getFTBusinessInfo', id).then((res) => {
          console.log('[requests] ->', res);
          this.bizInfo = res.data.result[0];
          console.log(this.bizInfo);
          this.isLoading = false
          Nprogress.done()
        })
      }
      catch (error) {
        Nprogress.done()
        this.isLoading = false
        this.PendingRequests = []
        console.log('[error] ->', error);
      }
    },
  },
};
</script>

<style>
.v-table.hidden-xs.VueTables.VueTables--client > .VueTables__search-field {
  display: none !important;
}
.v-select.vs__dropdown-toggle {
  padding: 2px 15px !important;
}
</style>
