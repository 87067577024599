<template>
  <form @submit.prevent="handleSubmit">
    <div class="row mb-2">
      <div class="col-sm-6 px-sm-2 col-md-3">
        <CustomInput
          :name="'request_reference'"
          :placeholder="'Request Reference'"
          v-model="filters.request_reference"
          :handleError="handleError"
        />
      </div>
      <div class="col-sm-6 px-sm-2 col-md-3">
        <CustomSelect
          name="service_label"
          placeholder="Select Services"
          :optionLabel="'service_label'"
          :options="providerServices"
          v-model="filters.service_label"
        />
      </div>
      <div class="col-sm-6 px-sm-2 col-md-3">
        <CustomSelect
          name="status"
          placeholder="Select Status"
          :optionLabel="'name'"
          :options="paymentStatus"
          v-model="filters.status"
        />
      </div>

      <div class="col-sm-6 px-sm-2 col-md-3">
        <DatePicker
          :name="'from'"
          v-model="filters.from"
          :placeholder="'Start Date'"
          :disabledDates="{ from: new Date() }"
          :handleError="handleError"
        />
      </div>

      <div class="col-sm-6 px-sm-2 col-md-3">
        <DatePicker
          :name="'to'"
          v-model="filters.to"
          :disabledDates="{ from: new Date(), to: filters.from }"
          :placeholder="'End Date'"
          :handleError="handleError"
        />
      </div>

      <div className="col-sm-12 px-sm-3 col-md-10 px-ms-3 col-xl-3 mt-3">
        <button
          type="submit"
          :disabled="disabled || loading"
          class="btn btn-primary mx-3"
        >
          Filter
        </button>

        <button
          v-show="isFilter"
          type="button"
          class="btn btn-outline"
          @click="resetFilters"
        >
          Clear
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { filters } from "../../../../public/assets/mixins/filters";
import { paymentStatus } from "../../../components/utils/helpers";

const initialState = () => ({
  filters: {
    request_reference: "",
    id: "",
    from: null,
    to: null,
  },
  isFilter: false,
  qLoading: false,
});

export default {
  mixins: [filters],
  name: "CallLogFilters",
  data() {
    return { ...initialState(), paymentStatus };
  },
  props: ["loading", "rLoading", "clearFilter", "submitFilter"],
  computed: {
    disabled() {
      return !Object.values(this.filters).some((val) => val);
    },
  },
  methods: {
    resetFilters() {
      Object.assign(this.$data, { ...initialState() });
      this.clearFilter();
      this.isFilter = false;
    },
    handleSubmit() {
      this.isFilter = true;
      if (this.filters.from)
        this.filters.from = this.timeRangeFormat(this.filters.from, true);
      if (this.filters.to)
        this.filters.to = this.timeRangeFormat(this.filters.to);

      if (this.filters.status) this.filters.status = this.filters.status.value;
      if (this.filters.service_label) this.filters.service_label = this.filters.service_label.service_label;
      this.submitFilter(this.filters);
    },
  },
  watch: {
    "filters.from": function () {
      this.filters.to = null;
    },
  },
  computed: {
    providerServices() {
      return this.$store.state.serviceProviders.serviceList;
    },
  },
};
</script>
