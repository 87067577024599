import Vue from "vue";
import Vuex from "vuex";

import { apiClient } from "../services/api";

import application from "./modules/application";
import account from "./modules/account";
import auth from "./modules/auth";
import logs from "./modules/logs";
import services from "./modules/services";
import payment from "./modules/payment";
import serviceProviders from "./modules/serviceProviders";
import customer from "./modules/customer";
import fundstransfer from "./modules/fundstransfer";
import infrastructureProviders from "./modules/infrastructureProviders";
import others from "./modules/others";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    logs,
    account,
    application,
    services,
    customer,
    serviceProviders,
    payment,
    fundstransfer,
    infrastructureProviders,
    others,
  },
  state: {
    metaData: [],
    isLoading: false,
  },
  mutations: {
    SET_META_DATA(state, metadata) {
      state.metaData = metadata;
      state.isLoading = false;
    },
  },

  actions: {
    async getMetaData({ commit }) {
      try {
        let { data } = await apiClient.get(`Authorize/Admin/MetaData`);
        commit("SET_META_DATA", data.results);
        return data;
      } catch (error) {
        if (error.response) {
          throw new Error(error.response);
        } else {
          throw new Error("Unable to fetch meta data");
        }
      }
    },
  },
});
