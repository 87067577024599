import auth from '@/services/auth'
import { apiClient } from '@/services/api'

export default {
  namespaced: true,
  state: {
    accounts: [],
    token: '',
  },
  mutations: {
    ALL_ACCOUNTS(state) {},
    SET_TOKEN(state, token) {
      state.token = token
    },
  },
  actions: {
    async registerUser({ commit }, userDetails) {
      try {
        let result = await auth.customerRegistration(userDetails)
        return result
      } catch (error) {
        throw Error(error.response.data.message)
      }
    },
    async loginUser({ commit, dispatch }, userDetails) {
      try {
        let token
        let result = await auth.authenticateUser(userDetails)
        if (result.data['status_code'] == 200) {
          token = result.data.results.token
          localStorage.setItem('bsalt-token', token)
          apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`

          // Get User Profile
          let user = await dispatch('account/getProfile', null, { root: true })
          let role = user.roles[0].role_label

          // Check if role is Admin, if not  - Remove user token and clear auth state back to normal
          console.log('[role] ->', role)
          if (role == 'Blusalt Admin' || role == 'Crenet Admin') {
            return result
          } else {
            localStorage.clear()
            apiClient.defaults.headers.common['Authorization'] = `Bearer `
            throw new Error("Something went wrong, you're not authorized")
          }
        }
      } catch (error) {
        if (!error.response) {
          throw Error(error.message)
        }
        throw Error(error.response.data.message)
      }
    },
    async initiatePasswordReset({ commit }, userDetails) {
      try {
        let { data } = await auth.SendPasswordResetLink(userDetails)
        return data
      } catch (error) {
        console.log('[error] ->', error)
        throw Error(error.response.data.message)
      }
    },
    async passwordReset({ commit }, userDetails) {
      try {
        let { data } = await auth.ForgotPassword(userDetails)
        return data
      } catch (error) {
        console.log('[error] ->', error)
        throw Error(error.response.data.message)
      }
    },
    async activateAccount({ commit }, token) {
      try {
        let { data } = await auth.ActivateAccount(token)
        return data.results
      } catch (error) {
        console.log('[error] ->', error)
        throw Error(error.response.data.message)
      }
    },
  },

  getters: {},
}
