<template>
  <div class="az-content-body">
    <div class="col-md-12">
      <div class="SectionItem d-flex justify-content-between">
        <div class="SectionHeader">
          <span class="title">Business Information</span>
          <div class="description">Submitted information from the customer.</div>
        </div>
        <button class="btn btn-md btn-outline-danger mg-b-20 ml-3" @click.prevent="back">
          <i class="la la-arrow-left mr-1"></i>Go Back
        </button>
        <!-- <div>
          <button
            class="btn btn-md btn-primary btn-block mg-b-20"
            @click.prevent="sendToETZ"
            :disabled="submitting == true"
          >
            <span v-if="submitting == true">Sending info to eTranzact...</span>
            <span v-else>Send info to eTranzact</span>
          </button>
        </div>-->
      </div>
    </div>
    <!-- nav tabs -->
    <div class="row">
      <div class="col-12">
        <AppEditBusinessInfo />
      </div>
    </div>
    <!-- End row -->

    <!-- End AZ -->
  </div>
</template>

<script>
import Nprogress from "nprogress";
import AuthWrapper from "@/components/auth/AuthWrapper";
import { notifications } from "../../public/assets/mixins/notifications";
import AppEditBusinessInfo from "@/components/fundsTransfer/EditBusinessInfo";

export default {
  name: "FTEditBusinessInfo",
  mixins: [notifications],
  components: {
    AuthWrapper,
    AppEditBusinessInfo,
  },
  data() {
    return {
      isLoading: true,
      metaData: {},
      submitting: false,
      bizInfo: {},
    };
  },
  watch: {},

  computed: {},
  async mounted() { },
  methods: {
    back() {
      history.back()
    }
  },
};
</script>

<style>
.v-table.hidden-xs.VueTables.VueTables--client > .VueTables__search-field {
  display: none !important;
}
.v-select.vs__dropdown-toggle {
  padding: 2px 15px !important;
}
</style>
