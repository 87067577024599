// import AccessControl from "@/utils/AccessControl/AccessControl";
import FilterWrapper from "../../../src/components/utils/FilterWrapper/FilterWrapper";
import FailedState from "../../../src/components/utils/ResponseState/FailedState";
import TableWrapper from "../../../src/components/utils/TableWrapper/TableWrapper";
import Spinner from "../../../src/components/utils/Spinner/Spinner";
import CustomInput from "../../../src/components/utils/CustomInput/CustomInput";
import DatePicker from "../../../src/components/utils/DatePicker/DatePicker";
import CustomSelect from "../../../src/components/utils/CustomSelect/CustomSelect";
import ViewMore from "../../../src/components/utils/ViewMore/ViewMore";
import EmptyData from "../../../src/components/utils/EmptyData/EmptyData";
// import EmptyWallet from "@/components/wallet/common/EmptyWallet/EmptyWallet";
import { notifications } from "./notifications";
import {
	timeDateFormat,
	tableOptions,
	getWalletApikey,
	getRequestData,
	titleCase,
	toCurrency,
	statusColor,
	getFilters,
	copyToClipboard,
	downloadReport,
	getPhone,
	getError,
	truncate,
} from "../../../src/components/utils/helpers";

export const componentData = {
	mixins: [notifications],
	data() {
		return {
			loading: false,
			rLoading: false,
			isEmpty: false,
			errorMsg: "",
			size: 25,
			filters: {},
		};
	},
	computed: {
		merchant() {
			return this.$store.state.account.profile.merchant;
		},
		tbClass() {
			return this.loading || this.errorMsg ? "hide__table" : "";
		},
	},
	components: {
		// AccessControl,
		FilterWrapper,
		// EmptyWallet,
		FailedState,
		Spinner,
		TableWrapper,
		CustomInput,
		CustomSelect,
		ViewMore,
		DatePicker,
		EmptyData,
	},
	methods: {
		timeDateFormat,
		tableOptions,
		getWalletApikey,
		titleCase,
		statusColor,
		getFilters,
		copyToClipboard,
		downloadReport,
		getPhone,
		toCurrency,
		getError,
		truncate,
		setLoading(loading) {
			this.loading = loading;
			this.errorMsg = "";
		},
		onLoaded({ total }) {
			this.setLoading(false);
			this.isEmpty = total > 0 ? false : true;
		},
		setError(error) {
			this.loading = false;
			this.errorMsg = getError(error);
		},
		handleRefetch(filters, apiCall, refName) {
			const newFilters = getFilters(filters);
			if (filters) this.filters = newFilters;
			apiCall();
			//   location.reload();
			this.$refs[refName] && this.$refs[refName].refresh();
		},
		clearFilter(refName) {
			this.filters = {};
			this.$refs[refName].refresh();
		},
		async handleAPICall(url) {
			this.options.requestFunction = async (data) => {
				// const params = { ...data, ...this.filters };
				const requestData = getRequestData(
					this.$store,
					this.filters,
					data,
					this.size
				);
				try {
					return (await this.$store.dispatch(url, requestData)).data
						.data;
				} catch (error) {
					this.setError(error);
				}
			};
		},
		async downloadTableReport(url, manageData) {
			this.rLoading = true;
			const request = getRequestData(this.$store, this.filters, {});
			const response = await this.$store.dispatch(url, request);

			const { status, data, result, status_code } = response || {};
			if (status === "Success" || status_code === 200) {
				manageData(data || result);
				this.rLoading = false;
			} else {
				this.rLoading = false;
				this.showErrorLoginNotification("Error Downloading Report");
			}
		},
		setColums(tableHeadings) {
			this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
		},
	},
};
