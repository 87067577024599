<template>
  <SideModal
    :show="show"
    :toggle="toggle"
    :title="`Collection Information`"
    :subTitle="'Payment Collection'"
  >
    <div class="row mt-2 pt-4">
      <div class="col-6">
        <p>
          <span class="text-muted">AMOUNT</span> <br />
          <span class="text-capitalize h3">
            &#8358;{{ formatNumber(singleCollectionObj.amount) || "0" }}
          </span>
        </p>
      </div>
      <div class="col-6">
        <p>
          <span class="text-muted text-capitalize">Type</span> <br />
          <span class="text-capitalize h3">
            {{singleCollectionObj.page_type}}
          </span>
        </p>
      </div>
      <br />
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted">REFERENCE </span><br />
          <span class="text-capitalize h5">{{
            singleCollectionObj.reference || "Null"
          }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-capitalize"> Status</span> <br />
          <span class="text-capitalize h5" :class="statusColor(singleCollectionObj.status)">{{
            singleCollectionObj.status
          }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted text-uppercase">Description</span> <br />
          <span class="text-capitalize h5">{{ singleCollectionObj.description}}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted">Created At </span> <br />
          <span class="text-capitalize h5">{{
            singleCollectionObj.createdAt | moment(" MM-DD-YYYY h:mm a")
          }}</span>
        </p>
      </div>
      <div class="col-6 mt-2">
        <p>
          <span class="text-muted">Expiry Date </span> <br />
          <span class="text-capitalize h5">{{
            singleCollectionObj.expiry_date | moment(" MM-DD-YYYY h:mm a")
          }}</span>
        </p>
      </div>
    </div>
    <br />
    <br>
    <div v-for="(image, index) in singleCollectionObj.image_urls" class="row">
      <div :key="index" class="col-6 mt-2" v-bind="key">
        <img class="img-fluid" :src="image" alt="prodcut-img">
      </div>
    </div>
  </SideModal>
</template>

<script>
import { componentData } from "../../../../public/assets/mixins/componentData";
import { notifications } from "../../../../public/assets/mixins/notifications";
import SideModal from "../../../components/utils/Modal/SideModal.vue";
import { formatNumber } from "../../../components/utils/helpers";

export default {
  mixins: [componentData, notifications],
  name: "CollectionDetailModal",
  data() {
    return {};
  },
  props: ["toggle", "show", "singleCollectionObj"],
  components: {
    SideModal,
  },
  methods: {
    formatNumber,
    handleCloseModal() {
      this.toggle();
    },
  },
  mounted() {
    console.log(this.singleCollectionObj);
  },
};
</script>

<style scoped>
.d-flex {
  display: flex;
  justify-content: flex-end;
}
</style>
