<template>
  <div class="az-sidebar az-sidebar-sticky">
    <div class="az-sidebar-header">
      <a href="#" class="az-logo">
        <img src="/assets/img/blusalt-logo-sb.svg" alt style />
      </a>
    </div>

    <div class="az-sidebar-body">
      <ul class="nav">
        <li class="nav-item">
          <router-link to="/dashboard" class="nav-link">
            <i class="la la-tachometer"></i>Dashboard
          </router-link>
        </li>

        <li class="nav-item">
          <a href class="nav-link with-sub">
            <i class="la la-users"></i>Customers
          </a>
          <nav class="nav-sub" role="navigation" aria-labelledby="customerNavigation">
            <router-link to="/invites" class="nav-sub-link">Invites</router-link>
            <router-link to="/customers" class="nav-sub-link">Customer listing</router-link>
            <router-link to="/white-label" class="nav-sub-link">Whitelabel Customer</router-link>
            <!-- <router-link to="/funds-transfer-customers" class="nav-sub-link">Funds Transfer Customers</router-link> -->
            <router-link to="/payment-merchants?tab=activated" class="nav-sub-link">Payment Merchants</router-link>
          </nav>
        </li>
        <li class="nav-item">
          <a href class="nav-link with-sub">
            <i class="la la-terminal"></i>Smart Id
          </a>
          <nav class="nav-sub" role="navigation" aria-labelledby="customerNavigation">
            <router-link to="/personal-smart-id" class="nav-sub-link">Personal</router-link>
            <router-link to="/business-smart-id" class="nav-sub-link">Business</router-link>
            <router-link to="/verification-subscription" class="nav-sub-link">Subscriptions</router-link>
          </nav>
        </li>
          <li class="nav-item">
            <a href class="nav-link with-sub">
              <i class="la la-retweet"></i>Payments
            </a>
            <nav class="nav-sub">
              <router-link to="/payment-overview" class="nav-sub-link">Overview</router-link>
              <router-link to="/payment-transactions" class="nav-sub-link">All Transactions</router-link>
              <router-link to="/payment-payouts" class="nav-sub-link">Payouts</router-link>
              <router-link to="/payment-disputes" class="nav-sub-link">Disputes</router-link>
              <router-link to="/payment-chargebacks" class="nav-sub-link">Chargebacks</router-link>
              <router-link to="/payment-refunds" class="nav-sub-link">Refunds</router-link>
              <router-link to="/payment-providers" class="nav-sub-link">Providers</router-link>
            </nav>
          </li>
          <li class="nav-item">
            <a href class="nav-link with-sub">
              <i class="la la-money"></i>Funds Transfer
            </a>
            <nav class="nav-sub">
              <router-link to="/funds-transfer-overview" class="nav-sub-link">Overview</router-link>
              <router-link to="/funds-transfer-transactions" class="nav-sub-link">Transactions</router-link>
            </nav>
          </li>
        <li class="nav-item">
          <a href class="nav-link with-sub">
            <i class="la la-bar-chart"></i>Logs
          </a>
          <nav class="nav-sub">
            <router-link to="/logs/sms" class="nav-sub-link">SMS Logs</router-link>
            <router-link to="/logs/service-logs" class="nav-sub-link">
              Service logs
            </router-link>
            <router-link to="/logs/verification-logs" class="nav-sub-link">
              Verification logs
            </router-link>
            <router-link to="/logs/address-verification-logs" class="nav-sub-link">
              Address Verification logs
            </router-link>
            <router-link to="/logs/business-registration-logs" class="nav-sub-link">
              Business Registration logs
            </router-link>
            <router-link to="/logs/bill-payment-logs" class="nav-sub-link">
              Bill Payment logs
            </router-link>
          </nav>
        </li>
        <li class="nav-item">
          <a href class="nav-link with-sub">
            <i class="la la-user-secret"></i>Service Provider Logs
          </a>
          <nav class="nav-sub">
            <router-link to="/logs/providers-overview" class="nav-sub-link">Overview</router-link>

            <router-link to="/logs/providers" class="nav-sub-link">
              Providers
            </router-link>
          </nav>
        </li>
        <li class="nav-item">
          <a href class="nav-link with-sub">
            <i class="la la-bar-chart"></i>Credit logs
          </a>
          <nav class="nav-sub">
              <router-link to="/credit-logs" class="nav-sub-link">Overview</router-link>
            <router-link to="/credit-logs-listing" class="nav-sub-link">Listing</router-link>
          </nav>
        </li>
        <li class="nav-item">
          <router-link to="/audit" class="nav-link">
            <i class="la la-line-chart"></i>Audit Trail
          </router-link>
        </li>

        <li class="nav-item">
          <a href class="nav-link with-sub">
            <i class="la la-cogs"></i>Settings
          </a>
          <nav class="nav-sub" role="navigation" aria-labelledby="servicesNavigation">
            <router-link to="/services" class="nav-sub-link">Services</router-link>
            <router-link to="/service-providers" class="nav-sub-link">Service Providers</router-link>
            <router-link to="/providers" class="nav-sub-link">Providers Settings</router-link>
            <!-- <router-link to="/funds-transfer-settings" class="nav-sub-link">Funds Transfer Settings</router-link> -->
          </nav>
        </li>

        <li class="nav-item" v-if="false">
          <router-link to="/transactions" class="nav-link">
            <i class="la la-bar-chart"></i>Transactions
          </router-link>
        </li>

        <li class="nav-item" v-if="userRoleStorage == 'Crenet Admin'">
          <router-link to="/test-service" class="nav-link">
            <i class="la la-server"></i>Test Service
          </router-link>
        </li>

        <li class="nav-item" v-if="false">
          <router-link to="/settings" class="nav-link">
            <i class="la la-gear"></i>Settings
          </router-link>
        </li>

        <li class="nav-item">
          <router-link to="/account" class="nav-link">
            <i class="la la-user"></i>Account
          </router-link>
        </li>
      </ul>
      <p class="nav-tip" v-if="false">
        Contact our integration team to learn more about our API and dashboard,
        or to schedule a demo:
        <a href="#">blusalt.io/contact</a>
      </p>
      <ul class="nav fixed" v-if="false">
        <li>
          <a href="#" target="_blank">
            API Documentation
            <img src="/assets/img/new-window-arrow.svg" alt="open-in-new-window" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userRoleStorage: "",
    };
  },
  computed: {
    userRole() {
      return this.$store.state.account.profile.roles[0].role_label;
    },
  },
  props: {
    appSettings: {
      type: Object,
    },
  },
  components: {
  },
  mounted() {
    let userRoleStorage = localStorage.getItem("identifiedAs");
    this.userRoleStorage = userRoleStorage;
  },
};
</script>
