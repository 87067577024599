<template>
  <div>
    <div class="row">
      <div class="col-sm-10"></div>
      <div class="col-sm-2">
        <CustomSelect
          name="filterBy"
          placeholder="Filter By"
          :optionLabel="'name'"
          :options="fsStatFilter"
          v-model="filterBy"
        />
      </div>
    </div>
    <div>
      <apexchart
        type="bar"
        height="400"
        width="1200"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import fundstransfer from "../../services/fundstransfer";
import {
  toCurrency,
  formatNumber,
  graphDateFormatter,
  fsStatFilter,
} from "../utils/helpers";
import CustomSelect from "../utils/CustomSelect/CustomSelect";

export default {
  name: "FTStatistics",
  data() {
    return {
      chartData: [],
      filterBy: "thisMonth",
      fsStatFilter,
      series: [
        {
          name: "Total Charges",
          data: [],
        },
        {
          name: "Transaction Values",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 430,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [],
        },
      },
    };
  },
  components: { CustomSelect },
  methods: {
    toCurrency,
    formatNumber,
    graphDateFormatter,
    async fetchFTOverview(filterBy) {
      const { data } = await fundstransfer.GetFTStats(filterBy);
      const xAxis = data.data.xAxis;
      const yAxis = data.data.yAxis;
      let wholeChartData = [];
      for (let index = 0; index < xAxis.length; index++) {
        wholeChartData.push({ x: xAxis[index], y: yAxis[index] || index });
      }
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: { categories: xAxis },
      };
      this.series = [
        {
          data: yAxis.totalCharges,
        },
        {
          data: yAxis.transactionValues,
        },
      ];
      this.loading = false;
    },
  },
  watch: {
    filterBy(newVal, oldVal) {
      this.fetchFTOverview(newVal.value);
    },
  },
  created() {
    this.fetchFTOverview(this.filterBy);
  },
};
</script>

<style scoped></style>
