<template>
  <form @submit.prevent="handleSubmit">
    <div class="row mb-2">
      <div class="col-sm-6 px-sm-2 col-md-4  col-xl-3">
        <CustomSelect
          :name="'app'"
          :placeholder="'Select App'"
          :optionLabel="'app_name'"
          :options="applications"
          v-model="app"
          :handleError="handleError"
        />
      </div>
      <div class="col-sm-6 px-sm-2 col-md-4  col-xl-3">
        <CustomSelect
          :name="'service'"
          :placeholder="'Select Service'"
          :optionLabel="'service_label'"
          :options="customerServices"
          v-model="service"
          :handleError="handleError"
        />
      </div>
      <div class="col-sm-6 px-sm-2 col-md-4  col-xl-3">
        <DatePicker
          :name="'from'"
          v-model="filters.from"
          :placeholder="'Start Date'"
          :disabledDates="{ from: new Date() }"
        />
      </div>

      <div class="col-sm-6 px-sm-2 col-md-4  col-xl-3">
        <DatePicker
          :name="'to'"
          v-model="filters.to"
          :disabledDates="{ from: new Date(), to: filters.from }"
          :placeholder="'End Date'"
        />
      </div>

      <div className="col-sm-12 px-sm-3 col-md-10 px-ms-3 col-xl-5 mt-3">
        <button type="submit" :disabled="loading" class="btn btn-primary mx-3">
          Filter
        </button>

        <button
          v-show="isFilter"
          type="button"
          class="btn btn-outline"
          @click="resetFilters"
        >
          Clear
        </button>

        <div class="d-inline-block"></div>
      </div>
    </div>
  </form>
</template>

<script>
import { filters } from "../../../public/assets/mixins/filters";

const initialState = () => ({
  filters: {
    from: null,
    to: null,
  },
  app: null,
  service: null,
  isFilter: false,
  qLoading: false,
});

export default {
  mixins: [filters],
  name: "AccountActivityFilter",
  data() {
    return { ...initialState(), apps: [] };
  },
  props: [
    "loading",
    "rLoading",
    "clearFilter",
    "submitFilter",
    "applications",
    "customerServices",
  ],
  computed: {
    disabled() {
      return !Object.values(this.filters).some((val) => val);
    },
  },
  methods: {
    resetFilters() {
      Object.assign(this.$data, { ...initialState() });
      this.clearFilter();
      this.isFilter = false;
    },
    handleSubmit() {
      this.isFilter = true;

      if (this.app) {
        this.filters.appname = this.app.app_name;
        this.filters.apiKey = this.app.api_key;
      }
      if (this.service) {
        this.filters.service_label = this.service.service_label;
      }
      if (this.filters.from)
        this.filters.from = this.timeRangeFormat(this.filters.from, true);
      if (this.filters.to)
        this.filters.to = this.timeRangeFormat(this.filters.to);
      this.submitFilter(this.filters);
    },
  },
  watch: {
    "filters.from": function() {
      this.filters.to = null;
    },
  },
};
</script>
