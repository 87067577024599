<template>
    <div class="p-md-4 p-3">
      <div class="SectionItem no-b-b mb-3">
        <div>
          <p class="title m-0">Payment Payouts</p>
          <p class="text-left description">
            Payment Summary
          </p>
        </div>
        <div>
          <button class="btn btn-lg btn-secondary-inverted" @click="downloadLinks()">
            <span><img src="/assets/img/icons/document-download.svg" alt="" /></span>
            Export CSV
          </button>
        </div>
      </div>
      <div class="px-3">
        <FilterWrapper>
          <template slot="filterForm">
            <PaymentPayoutFilter :loading="loading" :rLoading="rLoading" :submitFilter="refetch"
              :clearFilter="() => clearFilter('providersRef')" />
          </template>
        </FilterWrapper>
      </div>
      <TableWrapper :loading="loading" :errorMsg="errorMsg" :refetch="fetchPaymentPayouts" :isEmpty="isEmpty"
        :emptyTitle="emptyTitle">
        <div class="w-100 py-3" slot="content">
          <v-server-table ref="providersRef" class="v-table row-pointer" :data="customers" :columns="columns"
            :options="options" @loaded="onLoaded" @loading="setLoading(true)" @row-click="handleClick">
            <template slot="first_name" slot-scope="{ row }">
              <p class="mb-0 ">{{ titleCase(row.first_name) }} {{ titleCase(row.last_name) }}</p>
            </template>
            <template slot="source" slot-scope="{ row }">
              <p class="mb-0 ">
                {{ titleCase(row.source) || "Null" }}
              </p>
            </template>
            <template slot="amount" slot-scope="{ row }">
              &#8358;{{ formatNumber(row.amount) || "0" }}
            </template>
            <template slot="charges" slot-scope="{ row }">
              &#8358;{{ formatNumber(row.charges) || "0" }}
            </template>
            <div slot="status" slot-scope="{ row }">
              <span class="badge badge-app" :class="
                  row.status == 'successful' ? 'badge-success' : 'badge-danger'
                ">
                {{ titleCase(row.status) }}
              </span>
            </div>
            <div slot="type" slot-scope="{ row }">
              <span>
                {{ row.type || "Null" }}
              </span>
            </div>
            <div slot="reason" slot-scope="{ row }">
              <span>
                {{ row.reason || "Null" }}
              </span>
            </div>

            <template slot="updatedAt" slot-scope="{ row }">
              {{ row.updatedAt | moment(" MM-DD-YYYY h:mm a") }}
            </template>
          </v-server-table>
        </div>
      </TableWrapper>
    </div>
</template>

<script>
import { componentData } from "../../../public/assets/mixins/componentData";
import TableWrapper from "../../components/utils/TableWrapper/TableWrapper.vue";
import PaymentPayoutFilter from "../../components/filters/PaymentPayoutFilter.vue";
import { formatNumber, titleCase } from "../../components/utils/helpers";

const tableHeadings = {
  reference: "Reference",
  id: "Transaction ID",
  amount: "Amount",
  charges: "Charges",
  status: "Status",
  updatedAt: "Payout Date",
};

export default {
  name: "AllPaymentPayouts",
  mixins: [componentData],
  data() {
    return {
      singleTransactionObj: null,
      showTransactionModal: false,
      emptyTitle: "No Data",
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(tableHeadings, 25),
    };
  },
  components: {
    TableWrapper,
    PaymentPayoutFilter,
  },
  methods: {
    toggleTransactionModal() {
      this.showTransactionModal = !this.showTransactionModal;
    },
    handleClick({ row }) {
      this.$router.push(`/payment-payouts/${row.id}`);
    },
    formatNumber,
    titleCase,
    refetch(filters) {
      this.handleRefetch(filters, this.fetchPaymentPayouts, "providersRef");
    },
    async fetchPaymentPayouts() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters, size: data.limit };
        const response = await this.$store.dispatch(
          "payment/getAllPaymentPayouts",
          {
            params,
          }
        );
        const newData = response.data.data;
        const count = response.data.total;
        return { ...response, count, total: count, data: newData };
      };
    },
    async downloadLinks() {
      const download = (filterData) =>
        this.downloadReport(tableHeadings, filterData.data);
      this.downloadTableReport("payment/getAllPaymentPayouts", download);
    },
  },
  created() {
    this.fetchPaymentPayouts();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
