<template>
  <div>
    <!-- <div v-if="walletInformation === null"></div> -->
    <div class="az-content-body">
      <div class="mb-4">
        <router-link to="/payment-merchants" class="mr-2 text-secondary"
          >Merchant Information</router-link
        >
        &lt;
        <router-link
          :to="`/payment-merchant/${clientid}`"
          class="mx-2 text-secondary"
          >Reserved Account</router-link
        >
        &lt;
        <router-link to="" class="ml-2 text-primary">
          Reserved Account Information</router-link
        >
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="SectionItem no-b-b mb-3">
            <div class="SectionHeader">
              <span class="title" @click="$router.back()">
                <img
                  src="/assets/img/icons/close.svg"
                  alt="image"
                  height="30"
                />
                Reserved Account Information</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="overview">
        <div class="col-12 my-4">
          <div class="dashboard-briefs">
            <div class="dashboard-brief rounded py-4">
              <h2 class="call-count">
                <!-- {{ walletInformation.currency }} -->NGN
                <span v-if="overview">{{
                  formatNumber(
                    overview.VirtualAccount.ReservedAccount
                      .total_transactions_value
                  )
                }}</span>
                <span v-else>0</span>
              </h2>
              <p class="call-name">
                <!-- {{ walletInformation.currency }} -->
                Total Transaction Value
              </p>
            </div>
            <div class="dashboard-brief rounded py-4">
              <h2 class="call-count">
                <span v-if="overview">{{
                  overview.VirtualAccount.ReservedAccount.total_transactions
                }}</span>
                <span v-else>0</span>
              </h2>
              <p class="call-name">
                Total Transactions
              </p>
            </div>
            <div class="dashboard-brief-long  rounded p-4 ">
              <div class="row">
                <div class="col-sm-4">
                  <div class="contact-details--card-field mb-4">
                    <span class="field-key">
                      <div class="contact-details--truncated">
                        ACCOUNT NAME
                      </div>
                    </span>
                    <span class="field-value">
                      <div
                        class="contact-details--truncated pointer"
                        title="Click to copy"
                      >
                        <span v-if="overview">{{
                          overview.VirtualAccount.account_name
                        }}</span>
                        <span v-else>NULL</span>
                      </div>
                    </span>
                  </div>
                  <div class="contact-details--card-field">
                    <span class="field-key">
                      <div class="contact-details--truncated">
                        ACCOUNT NUMBER
                      </div>
                    </span>
                    <span class="field-value">
                      <div
                        class="contact-details--truncated pointer"
                        title="Click to copy"
                      >
                        <span v-if="overview">{{
                          overview.account_number
                        }}</span>
                        <span v-else>NULL</span>
                      </div>
                    </span>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="contact-details--card-field mb-4">
                    <span class="field-key">
                      <div class="contact-details--truncated">
                        BANK NAME
                      </div>
                    </span>
                    <span class="field-value">
                      <div class="contact-details--truncated">
                        <span v-if="overview">{{
                          overview.VirtualAccount.ReservedAccount.bank_name
                        }}</span>
                        <span v-else>NULL</span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="az-content-body">
      <h3>All Transactions</h3>
      <TransactionDetailModal
        :show="showTransactionModal"
        :toggle="toggleTransactionModal"
        :singleTransactionObj="singleTransactionObj"
        :refetch="refetch"
      />
      <div class="px-3">
        <FilterWrapper>
          <template slot="filterForm">
            <TransactionFilter
              :loading="loading"
              :rLoading="rLoading"
              :submitFilter="refetch"
              :clearFilter="() => clearFilter('transactionRef')"
            />
          </template>
        </FilterWrapper>
      </div>

      <TableWrapper
        :loading="loading"
        :errorMsg="errorMsg"
        :refetch="fectchSingleWallet"
        :isEmpty="isEmpty"
        :emptyTitle="emptyTitle"
      >
        <div class="w-100 py-3" slot="content">
          <v-server-table
            ref="transactionRef"
            class="v-table row-pointer"
            :data="customers"
            :columns="columns"
            :options="options"
            @loading="setLoading(true)"
            @loaded="onLoaded"
            @row-click="handleClick"
          >
            <template slot="createdAt" slot-scope="{ row }">
              {{ row.createdAt | moment(" MM-DD-YYYY h:mm a") }}
            </template>
            <template slot="amount" slot-scope="{ row }">
              {{ toCurrency(row.amount) }}
            </template>
            <div slot="status" slot-scope="{ row }">
              <span
                class="badge badge-app"
                :class="
                  row.status === 'successful' ? 'badge-success' : 'badge-danger'
                "
              >
                {{ row.status }}
              </span>
            </div>
            <div slot="action" slot-scope="{ row }">
              <span class="badge badge-app">
                {{ row.action === "credit" ? "Credit" : "Debit" }} </span
              ><span
                ><img
                  v-if="row.action === 'credit'"
                  src="/assets/img/icons/import.svg"
                  alt=""
                />
                <img v-else src="/assets/img/icons/export.svg" alt="" />
              </span>
            </div>
          </v-server-table>
        </div>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../../public/assets/mixins/componentData";
import TransactionFilter from "../../../components/filters/TransactionFilter.vue";
import TableWrapper from "../../../components/utils/TableWrapper/TableWrapper.vue";
import TransactionDetailModal from "../../../views/Customers/modals/TransactionDetailModal.vue";
import NGNWallet from "./Tabs/NGNWallet.vue";
import WalletSuscription from "./Tabs/WalletSuscription.vue";
import { formatNumber } from "../../../components/utils/helpers";

const tableHeadings = {
  reference: "Reference",
  "VirtualAccount.account_name": "Customer Name",
  amount: "Amount",
  createdAt: "Date Created",
};

export default {
  name: "SingleReservedAccount",
  mixins: [componentData],
  props: {
    clientid: {
      type: String,
    },
  },
  data() {
    return {
      singleTransactionObj: null,
      overview: null,
      showTransactionModal: false,
      emptyTitle: "No Transaction Found",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(tableHeadings, 25),
    };
  },
  methods: {
    formatNumber,
    toggleTransactionModal() {
      this.showTransactionModal = !this.showTransactionModal;
    },
    handleClick({ row }) {
      this.singleTransactionObj = row;
      this.showTransactionModal = true;
    },
    async fectchSingleWallet() {
      this.options.requestFunction = async (data) => {
        const params = {
          ...data,
          ...this.filters,
          id: this.clientid,
          size: 25,
          reserved_account: this.$route.params.accountId,
        };
        const response = await this.$store.dispatch(
          "customer/getReservedAccountTransactions",
          {
            params,
          }
        );

        const newData = response.data.data;
        const count = response.data.count;
        this.overview = newData[0];
        return { ...response, count, total: count, data: newData };
      };
    },
    refetch(filters) {
      let newFilters = { ...filters };
      if (newFilters.status) newFilters.status = newFilters.status.value;
      this.handleRefetch(newFilters, this.fectchSingleWallet, "transactionRef");
    },
  },
  props: ["clientid"],
  created() {
    this.fectchSingleWallet();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
  components: {
    NGNWallet,
    WalletSuscription,
    TableWrapper,
    TransactionFilter,
    TransactionDetailModal,
  },
};
</script>
<style scoped>
.dashboard-briefs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  /* grid-template-rows: 170px; */
}
.dashboard-brief {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}
.dashboard-brief-long {
  grid-column: 3 / 5;
  /* display: flex; */
  /* flex-direction: column;
  justify-content: center;
  align-items: center; */
  box-shadow: 0 0.5rem 0.9rem rgb(0 0 0 / 6%) !important;
}
.dashboard-brief-long .contact-details--card-field {
  width: 100% !important;
}

/* .dashboard-brief:not(:last-of-type) {
  border-right: 1px solid #cccbcb;
} */

.dashboard-lead {
  color: white;
}
.side-border {
  border-left: 3px dotted white;
}

.dashboard-element {
  padding: 20px;
  color: inherit;
}
.call-count {
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  color: #071b2b;
  padding-top: 8px;
  margin-bottom: 20px;
}
.call-name {
  font-size: 15px;
  color: #081b2a;
  text-transform: uppercase;
}
.flex-start {
  justify-content: flex-start;
}
.contact-details--card-field {
  width: 20% !important;
  /* margin-bottom: 10px; */
}
</style>
