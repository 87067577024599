<template>
  <div class="az-content-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="SectionItem no-b-b mb-3">
          <div class="SectionHeader">
            <span class="title">
              <a @click="$router.go(-1)" class="nav-sub-link"
                ><img
                  src="/assets/img/icons/go-back.svg"
                  alt="image"
                  height="30"
              /></a>
              Customer Information</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 card p-4">
        <h1 class="merchant-title">
          <span class="mr-4"
            ><img height="35" src="/assets/img/avatar1.svg" alt=""/></span
          >{{ merchantInformation.first_name }}
          {{ merchantInformation.last_name }}
        </h1>
        <div class="contact-details--card">
          <div class="contact-details--card-row">
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">
                  Merchant Reference
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                  {{ merchantInformation.reference }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">CLIENT NAME</div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{ merchantInformation.first_name }}
                  {{ merchantInformation.last_name }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">
                  Email Address
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{ merchantInformation.email || "Null" }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">Phone Number</div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{ merchantInformation.phone || "Null" }}
                </div>
              </span>
            </div>

            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated">Date Created</div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                  {{
                    merchantInformation.createdAt | moment(" MM-DD-YYYY h:mm a")
                  }}
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-12 col-xl-12">
        <nav class="nav az-nav-line nav-line-user-account">
          <a href="#transactions" data-toggle="tab" class="nav-link active show"
            >Transactions</a
          >
          <a href="#wallet" data-toggle="tab" class="nav-link">Wallet</a>
          <a href="#invoice" data-toggle="tab" class="nav-link">Invoice</a>
          <a href="#subscription" data-toggle="tab" class="nav-link"
            >Subscriptions</a
          >
          <a href="#account" data-toggle="tab" class="nav-link"
            >Account Statement</a
          >
        </nav>
      </div>
    </div>
    <div class="tab-content pt-4">
      <div id="transactions" class="row tab-pane active show">
        <Transactions />
      </div>
      <div id="wallet" class="row tab-pane">
        <Wallet />
      </div>
      <div id="invoice" class="row tab-pane">
        <Invoice />
      </div>
      <div id="subscription" class="row tab-pane">
        <Subscription />
      </div>
      <div id="account" class="row tab-pane">
        <Account />
      </div>
    </div>
  </div>
</template>

<script>
import customer from "../../../../../services/customer";
import Transactions from "./Tabs/Transactions.vue";
import Wallet from "./Tabs/Wallet.vue";
import Invoice from "./Tabs/Invoice";
import { copyToClipboard } from "../../../../../components/utils/helpers";
import Subscription from "./Tabs/Subscription.vue";
import Account from "./Tabs/Account.vue";

export default {
  name: "MerchantDetail",
  props: {
    clientid: {
      type: String,
    },
  },
  data() {
    return {
      merchantInformation: null,
    };
  },
  methods: {
    copyToClipboard,
    async fetchCurrentMerchantInfo() {
      const params = {
        id: this.$route.params.clientid,
        reference: this.$route.params.customerid,
      };
      const response = await customer.GetMerchantCutomers({
        params,
      });
      this.merchantInformation = response.data.data.data[0];
    },
  },
  mounted() {
    this.fetchCurrentMerchantInfo();
  },
  components: {
    Transactions,
    Invoice,
    Wallet,
    Subscription,
    Account
  },
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
.merchant-title {
  font-family: "AllianzNeo";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 27px;
  color: #071b2b;
}
</style>
