<template>
    <div>
        <div class="contact-details--card border-0 m-0 shadow-sm rounded pointer"
        v-for="(verification, i) in details"
        :key="i"
        @click="handleClick(verification.verification_id)"
        >
        <div class="flex__between">
            <p class="font-weight-bold">{{ verification.type.name }}</p> 
            <p class="font-weight-bold" :class="statusColor(verification?.verification_status)">{{ verification?.verification_status }}</p> 
        </div>
            <div class="contact-details--card-row">
                <div class="contact-details--card-field">
                    <span class="field-key">
                        <div class="contact-details--truncated text-uppercase">
                             {{ verification.type?.abbr }}
                        </div>
                    </span>
                    <span class="field-value">
                        <div class="contact-details--truncated">
                            {{ verification.identity_number }}
                        </div>
                    </span>
                </div>
                <div class="contact-details--card-field">
                    <span class="field-key">
                        <div class="contact-details--truncated text-uppercase">PHONE NUMBER</div>
                    </span>
                    <span class="field-value">
                        <div class="contact-details--truncated pointer">
                           {{ verification.phone_number }}
                        </div>
                    </span>
                </div>
                <div class="contact-details--card-field">
                    <span class="field-key">
                        <div class="contact-details--truncated text-uppercase">First Name</div>
                    </span>
                    <span class="field-value">
                        <div class="contact-details--truncated pointer">
                           {{ verification?.first_name }}
                        </div>
                    </span>
                </div>

                <div class="contact-details--card-field">
                    <span class="field-key">
                        <div class="contact-details--truncated text-uppercase">Last Name</div>
                    </span>
                    <span class="field-value">
                        <div class="contact-details--truncated">
                            {{ verification?.last_name }}
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { componentData } from "../../../public/assets/mixins/componentData";
import { identityMap } from '../../components/utils/helpers/index'


export default {
    name: "IdentityVerification",
    mixins: [componentData],
    data() {
        return {
            emptyTitle: "No Verification Found",
            loading: true,
            errorMsg: "",
            details: null,
        };
    },
    components: {
    },
    methods: {
        handleClick(id) {
              this.$router.push(`/logs/verification-logs/${id}/main_information`);
        },
        refetch(filters) {
            this.handleRefetch(filters, this.fetchSmartId, "smartRef");
        },
        transformed(data) {
            const { identityVerification = {} } = data || {};
            return Object.entries(identityVerification).map(
                ([verificationType, verificationValue]) => ({
                    identity_number:
                        verificationValue?.nin ||
                        verificationValue?.bvn ||
                        verificationValue?.pvc ||
                        verificationValue?.drivers_license ||
                        verificationValue?.dl,
                    ...verificationValue,
                    type: identityMap[verificationType],
                })
            );
        },
        async fetchSmartId() {
            const response = await this.$store.dispatch(
                "logs/getSmartIDCustomerVerificationDetails",
                {
                    smartId: this.$route.params.smart_id,
                }
            );
            this.details = this.transformed(response.data);
            this.loading = false;
        },
    },
    created() {
        this.fetchSmartId();
    },
};
</script>