<template>
  <div>
    <TableWrapper
      :loading="loading"
      :errorMsg="errorMsg"
      :refetch="fetchWebHooks"
      :isEmpty="isEmpty"
      :emptyTitle="emptyTitle"
    >
      <div class="w-100 py-3" slot="content">
        <v-server-table
          class="v-table"
          :data="customers"
          :columns="columns"
          :options="options"
          @loaded="onLoaded"
        >
          <template slot="createdAt" slot-scope="{ row }">
            {{ row.createdAt | moment(" MM-DD-YYYY h:mm a") }}
          </template>
          <template slot="category" slot-scope="{ row }">
            {{ titleCase(row.category)|| 'N/A'  }}
          </template>
          <template slot="status" slot-scope="{ row }">
            {{ titleCase(row.status)  }}
          </template>
        </v-server-table>
      </div>
    </TableWrapper>
  </div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../../../components/utils/TableWrapper/TableWrapper.vue";

const plansHeadings = {
  name: "Name",
  currency: "Currency",
  category: "Category",
  status: "Status",
  createdAt: 'Date Created'
};

export default {
  name: "PaymentMethod",
  mixins: [componentData],
  data() {
    return {
      singleInviteObj: null,
      showSingleInviteModal: false,
      emptyTitle: "No Account",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(plansHeadings, 25),
    };
  },
  components: {
    TableWrapper,
  },
  methods: {
    async fetchWebHooks() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters, id: this.clientid };
        const response = await this.$store.dispatch(
          "customer/getPaymentMerchantPaymentMethods",
          {
            params,
          }
        );

        const newData = response.data.data;
        const count = response.data.count;
        return { ...response, count, total: count, data: newData };
      };
    },
  },

  props: ["clientid"],
  created() {
    this.fetchWebHooks();
  },
  mounted() {
    this.columns = Object.keys(plansHeadings).map((keyVal) => keyVal);
  },
};
</script>
