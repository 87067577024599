<template>
  <div>
    <div
      class="modal fade"
      :id="id"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content border-0">
          <div class="modal-header bg-black">
            <h5 class="modal-title text-white" id="exammpleModalTitle">Edit Parameters</h5>
            <button class="close text-white" type="button" data-dismiss="modal" aria-label="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-row">
              <!-- Route -->
              <div class="col-md-6">
                <div class="input-item">
                  <div class="input-wrap">
                    <label class="input-item-label">
                      Route
                      <span class="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      class="input-bordered"
                      placeholder="Enter Route"
                      v-model="getParameters.route"
                    />
                    <!-- @blur="$v.route.$touch()" -->
                    <!-- <template v-if="$v.route.$dirty">
                      <small class="error" v-if="!$v.route.required">Route is required</small>
                    </template>-->
                  </div>
                </div>
              </div>

              <!-- HTTP Method -->
              <div class="col-md-6">
                <div class="input-item">
                  <div class="input-wrap">
                    <label class="input-item-label">
                      HTTP Method
                      <span class="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      class="input-bordered"
                      placeholder="Enter HTTP Method"
                      v-model="getParameters.http_method"
                    />
                    <!-- @blur="$v.http_method.$touch()" -->
                    <!-- <template v-if="$v.http_method.$dirty">
                      <small class="error" v-if="!$v.http_method.required">HTTP Method is required</small>
                    </template>-->
                  </div>
                </div>
              </div>
            </div>

            <div class="form-row mt-4">
              <!-- iOS Validation License -->
              <div class="col-md-6">
                <div class="input-item">
                  <div class="input-wrap">
                    <label class="input-item-label">
                      iOS Validation License
                      <span class="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      class="input-bordered"
                      placeholder="Enter iOS Validation License"
                      v-model="getParameters.ios_validation_license"
                    />
                    <!-- @blur="$v.ios_validation_license.$touch()" -->
                    <!-- <template v-if="$v.ios_validation_license.$dirty">
                      <small
                        class="error"
                        v-if="!$v.ios_validation_license.required"
                      >iOS Validation License is required</small>
                    </template>-->
                  </div>
                </div>
              </div>

              <!-- android_validation_license-->
              <div class="col-md-6">
                <div class="input-item">
                  <div class="input-wrap">
                    <label class="input-item-label">
                      Android Validation License
                      <span class="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      class="input-bordered"
                      placeholder="Enter Android Validation License"
                      v-model="getParameters.android_validation_license"
                    />
                    <!-- @blur="$v.android_validation_license.$touch()" -->
                    <!-- <template v-if="$v.android_validation_license.$dirty">
                      <small
                        class="error"
                        v-if="!$v.android_validation_license.required"
                      >Android Validation License is required</small>
                    </template>-->
                  </div>
                </div>
              </div>
            </div>

            <div class="form-row mt-4">
              <!-- price -->
              <div class="col-md-6">
                <div class="input-item">
                  <div class="input-wrap">
                    <label class="input-item-label">
                      Price
                      <span class="text-red">*</span>
                    </label>
                    <input
                      class="input-bordered"
                      placeholder="Enter Price"
                      v-model="getParameters.price"
                    />
                    <!-- @blur="$v.price.$touch()" -->
                    <!-- <template v-if="$v.price.$dirty">
                      <small class="error" v-if="!$v.price.required">Price field required</small>
                    </template>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- action button -->
          <div class="modal-footer border-0">
            <button id="editParameters" class="btn btn-success rounded-md" @click="editParameters">
              <template v-if="editing === false">Submit</template>
              <template v-if="editing === true">
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>Submitting...
              </template>
            </button>
            <button class="btn btn-dark rounded-md" data-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    editing: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      ParametersInfo: {},
      // route: '',
      // http_method: '',
      // ios_validation_license: '',
      // android_validation_license: '',
      // price: ''
    };
  },

  methods: {
    getCurrentData(currentData) {
      this.ParametersInfo = currentData;
    },

    editParameters() {
      let data = {
        route: this.getParameters.route,
        http_method: this.getParameters.http_method,
        ios_validation_license: this.getParameters.ios_validation_license,
        android_validation_license: this.getParameters.android_validation_license,
        price: this.getParameters.price
      };
      this.$emit("editParameters", data);
    }
  },

  computed: {
    getParameters() {
      console.log('edit', this.$store.state.serviceProviders.serviceProviderParameters);
      return this.$store.state.serviceProviders.serviceProviderParameters;
    }
  }
};
</script>

<style scoped>
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 10px !important;
  padding-left: 10px !important;
}
</style>