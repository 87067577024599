<template>
  <div>
    <button type="button" class="btn btn-lg btn-primary mr-3" @click="openAddProviderModal">
      Update Role
    </button>
    <Modal id="updateRole" title="Update Role" :toggle="closeModal" :loading="loading">
      <form @submit.prevent="updateRole">
        <div v-if="teamInformation.role">
          <CustomInput :name="'customerFirstName'" v-model="teamInformation.role.name" :label="`Current Role`"
            :placeholder="`Customer's First Name`" :required="true" disabled :handleError="() => { }" />
        </div>
        <CustomSelect name="role" placeholder="Select New Role" :optionLabel="'name'" :options="roles" v-model="role" />
        <FormButtons :loading="loading" :handleCloseModal="closeModal" :closeBtnName="`Close`"
          :btnName="`Update Role`" />
      </form>
    </Modal>
  </div>
</template>

<script>
import { notifications } from "../../../../../../../public/assets/mixins/notifications";
import Modal from "../../../../../../components/utils/Modal/Modal";
import FormButtons from "../../../../../../components/utils/FormButtons/FormButtons";
import CustomInput from "../../../../../../components/utils/CustomInput/CustomInput.vue";
import CustomSelect from "../../../../../../components/utils/CustomSelect/CustomSelect";

export default {
  mixins: [notifications],
  name: "ApproveVerificationModal",
  data() {
    return {
      loading: false,
      role: "",
      roles: [
        { name: "Admin", value: 2 },
        { name: "Operations", value: 3 },
        { name: "Developer", value: 4 },
        { name: "Customer Service", value: 5 },
      ],
    };
  },
  props: ["teamInformation", "refetch"],
  methods: {
    openAddProviderModal() {
      $("#updateRole").modal("show");
    },
    closeModal() {
      $("#updateRole").modal("hide");
    },
    async updateRole() {
      if (this.role) {
        try {
          this.loading = true;
        let response = await this.$store.dispatch(
          "customer/updateCustomerTeamRole",
          {
            id: this.teamInformation.user_id,
            clientid: this.$route.params.clientid,
            role_id: this.role.value,
          }
        );
        this.loading = false;
        this.closeModal();
        this.refetch()
        this.showSuccessNotification(response.message);
        } catch (error) {
          this.loading = false;
        this.showErrorNotification(error?.message);
        }
      }
    },
  },
  components: {
    Modal,
    FormButtons,
    CustomInput,
    CustomSelect,
  },
};
</script>
