<template>
  <SideModal
    :show="show"
    :toggle="toggle"
    :title="`Create Customer`"
    :subTitle="'Add a new customer'"
  >
    <!-- Create Customer section -->
    <form @submit.prevent="performRegistration">
      <div class="row">
        <!-- First name field -->
        <div class="col-md-12">
          <div class="form-group">
            <label class="input-item-label">Firstname</label>
            <input
              type="text"
              name="firstname"
              class="form-control input-bordered"
              v-model="customerDetails.firstname"
              placeholder="Enter your first name"
            />
            <ul
              class="parsley-errors-list filled"
              id="parsley-id-26"
              v-show="$v.customerDetails.firstname.$error"
            >
              <li class="parsley-required">Firstname is required.</li>
            </ul>
          </div>
        </div>

        <!-- last name field -->
        <div class="col-md-12">
          <div class="form-group">
            <label class="input-item-label">Lastname</label>
            <input
              type="text"
              name="lastname"
              class="form-control input-bordered"
              v-model="customerDetails.lastname"
              placeholder="Enter your last name"
            />
            <ul
              class="parsley-errors-list filled"
              id="parsley-id-26"
              v-show="$v.customerDetails.lastname.$error"
            >
              <li class="parsley-required">Lastname is required.</li>
            </ul>
          </div>
        </div>

        <!-- Organisation field -->
        <div class="col-md-12">
          <div class="form-group">
            <label class="input-item-label">Organisation Name</label>
            <input
              type="text"
              name="organisation"
              class="form-control input-bordered"
              v-model="customerDetails.organisation"
              placeholder="Enter your organisation name"
            />
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label class="input-item-label">Phone number</label>
            <input
              type="tel"
              name="email"
              class="form-control input-bordered"
              v-model="customerDetails.phoneNumber"
              placeholder="Enter your phone number"
            />
            <ul
              class="parsley-errors-list filled"
              id="parsley-id-26"
              v-show="$v.customerDetails.phoneNumber.$error"
            >
              <li class="parsley-required">
                Phone number is required.
              </li>
            </ul>
          </div>
        </div>

        <!-- Email field -->
        <div class="col-md-12">
          <div class="form-group">
            <label class="input-item-label">Email</label>
            <input
              type="email"
              name="email"
              class="form-control input-bordered"
              v-model="customerDetails.email"
              placeholder="Enter your email"
            />
            <ul
              class="parsley-errors-list filled"
              id="parsley-id-26"
              v-show="$v.customerDetails.email.$error"
            >
              <li class="parsley-required">Email is required.</li>
            </ul>
          </div>
        </div>

        <!-- Whitlabelled field -->
        <div class="col-md-12">
          <div class="form-group">
            <label class="input-item-label"
              >Do you want to enable white-labelling</label
            >
            <v-select
              class="v-select"
              @keydown.enter.native.prevent
              name="address"
              v-model="customerDetails.whitelabelling"
              :options="enableWhitelabelling"
              placeholder="Would you like to enable white-labelling"
            ></v-select>
            <ul
              class="parsley-errors-list filled"
              id="parsley-id-26"
              v-show="$v.customerDetails.whitelabelling.$error"
            >
              <li class="parsley-required">
                whitelabelling option is required.
              </li>
            </ul>
          </div>
        </div>

        <!--Services field -->
        <div class="col-md-12">
          <div class="form-group">
            <label class="input-item-label">Services</label>
            <a href="#" class="right" v-if="customerDetails.services"
              >Fail over services provides fallbacks and makes results more
              efficent</a
            >
            <v-select
              class="v-select multiple"
              placeholder="Select a service"
              @keydown.enter.native.prevent
              v-model="customerDetails.services"
              :required="!customerDetails.services"
              :options="metaData.services"
              label="service_label"
              multiple
            ></v-select>
            <ul
              class="parsley-errors-list filled"
              id="parsley-id-26"
              v-show="$v.customerDetails.services.$error"
            >
              <li class="parsley-required">Services are required.</li>
            </ul>
          </div>
        </div>

        <!-- Fail over servies -->
        <div
          :class="[
            customerDetails.hasFailOvers == 'Yes' ? 'col-md-4' : 'col-md-12',
          ]"
        >
          <div class="form-group">
            <label class="input-item-label">Fail-over services</label>
            <v-select
              class="v-select"
              @keydown.enter.native.prevent
              name="address"
              :disabled="!customerDetails.services"
              v-model="customerDetails.hasFailOvers"
              :options="failOvers"
              placeholder="Would you like to select fail-over services"
            ></v-select>
          </div>
        </div>

        <!-- Select Fail over services -->
        <div class="col-md-8" v-if="customerDetails.hasFailOvers == 'Yes'">
          <div class="form-group">
            <label class="input-item-label">Fail-over services</label>
            <v-select
              class="v-select"
              @keydown.enter.native.prevent
              name="fail overs"
              v-model="customerDetails.failOverOptions"
              :options="failOverOptions"
              multiple
              placeholder="Select fail-over services"
              label="service_label"
            ></v-select>
          </div>
        </div>

        <!-- Address field -->
        <div class="col-md-6">
          <div class="form-group">
            <label class="input-item-label">Address</label>
            <input
              type="text"
              name="address"
              class="form-control input-bordered"
              v-model="customerDetails.address"
              placeholder="Enter your address"
            />
          </div>
        </div>

        <!-- City field -->
        <div class="col-md-6">
          <div class="form-group">
            <label class="input-item-label">City</label>
            <input
              type="text"
              class="form-control input-bordered"
              v-model="customerDetails.city"
              placeholder="Enter your city"
            />
          </div>
        </div>

        <!--Country field -->
        <div class="col-md-6">
          <div class="form-group">
            <label class="input-item-label">Country</label>
            <v-select
              class="v-select"
              placeholder="Select a country"
              v-model="customerDetails.country"
              @keydown.enter.native.prevent
              :options="metaData.countries"
              @input="changeState"
              label="name"
            ></v-select>
            <!-- <ul class="parsley-errors-list filled" id="parsley-id-26">
                        <li class="parsley-required">This value is required.</li>
                      </ul>-->
          </div>
        </div>

        <!-- State field -->
        <div class="col-md-6">
          <div class="form-group">
            <label class="input-item-label">State</label>
            <v-select
              class="v-select"
              placeholder="Select a state"
              v-model="customerDetails.state"
              @keydown.enter.native.prevent
              :disabled="!customerDetails.country"
              :options="states"
              label="state_label"
            ></v-select>
          </div>
        </div>

        <!--Password field -->
        <!-- <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Password</label>
                      <input
                        type="password"
                        autocomplete
                        class="form-control input-bordered"
                        v-model="customerDetails.password"
                        placeholder="Enter a password"
                      />
                      <ul
                        class="parsley-errors-list filled"
                        id="parsley-id-26"
                        v-show="$v.customerDetails.password.$error"
                      >
                        <li class="parsley-required">Password must be a minimum of 6 charachers.</li>
                      </ul>
                    </div>
                  </div>-->

        <!-- Confirm password field -->
        <!-- <div class="col-md-6">
                    <div class="form-group">
                      <label class="input-item-label">Confirm Password</label>
                      <input
                        type="password"
                        autocomplete
                        class="form-control input-bordered"
                        v-model.lazy="customerDetails.confirmpassword"
                        placeholder="Confrim the password"
                      />
                      <ul class="parsley-errors-list filled" id="parsley-id-26">
                        <li
                          class="parsley-required"
                          v-if="$v.customerDetails.confirmpassword.$error"
                        >Field is required and must be the same as the password.</li>
                      </ul>
                    </div>
                  </div>-->
      </div>

      <div class="gaps-2x"></div>
      <button
        class="btn btn-lg btn-primary btn-block mg-b-20"
        type="submit"
        :disabled="submitting == true"
      >
        <span v-if="submitting == true">Creating account</span>
        <span v-else>Submit</span>
      </button>
    </form>

    <!-- End row -->
  </SideModal>
</template>

<script>
import Nprogress from "nprogress";
import {
  required,
  minLength,
  alphaNum,
  sameAs,
} from "vuelidate/lib/validators";
import { notifications } from "../../../../public/assets/mixins/notifications";
import SideModal from "../../../components/utils/Modal/SideModal.vue";

const initialState = () => ({
  isLoading: true,
  states: [],
  metaData: {},
  submitting: false,
  enableWhitelabelling: ["Yes", "No"],
  failOvers: ["Yes", "No"],
  customerDetails: {
    firstname: "",
    lastname: "",
    organisation: "",
    phoneNumber: "",
    email: "",
    services: "",
    address: "",
    whitelabelling: "",
    city: "",
    state: "",
    country: "",
    // password: '',
    // confirmpassword: '',
    hasFailOvers: "",
    failOverOptions: "",
  },
});

export default {
  mixins: [notifications],
  name: "CreateCustomerModal",
  data() {
    return { ...initialState() };
  },
  watch: {
    "customerDetails.services"(newVal, old) {
      let newServiceArray, failOverServices, missingIndex;
      if (newVal) newServiceArray = newVal.map((service) => service.id);

      if (this.customerDetails.failOverOptions) {
        failOverServices = this.customerDetails.failOverOptions.map(
          (service) => service.id
        );
      }

      if (newServiceArray && failOverServices) {
        for (let index = 0; index < failOverServices.length; index++) {
          if (!newServiceArray.includes(failOverServices[index])) {
            missingIndex = index;
            break;
          }
        }
        this.customerDetails.failOverOptions.splice(missingIndex, 1);
      }
    },
  },
  validations: {
    customerDetails: {
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      phoneNumber: {
        required,
        minLength: minLength(11),
      },
      email: {
        required,
      },
      services: {
        required,
      },
      whitelabelling: {
        required,
      },
      // confirmpassword: {
      //   required,
      //   sameAsPassword: sameAs("password")
      // }
    },
  },
  computed: {
    changeState() {
      this.customerDetails.state = "";
      if (!this.customerDetails.country) {
        this.states = [];
      } else {
        this.states = this.customerDetails.country.states || [];
      }
    },

    failOverOptions() {
      if (this.customerDetails.services) {
        return this.customerDetails.services;
      }
    },
  },
  props: ["toggle", "show", "refetch"],
  components: {
    SideModal,
  },
  methods: {
    formatRequestData() {
      let requestData = new FormData();

      if (this.customerDetails.services) {
        this.customerDetails.services.map((service) => {
          requestData.append("services", service.id);
        });
      }
      if (this.customerDetails.hasFailOvers == "Yes") {
        this.customerDetails.failOverOptions.map((service) => {
          requestData.append("fail_over_services", service.id);
        });
      }

      requestData.append("firstname", this.customerDetails.firstname);
      requestData.append("lastname", this.customerDetails.lastname);
      requestData.append(
        "organization_name",
        this.customerDetails.organisation
      );
      requestData.append("email", this.customerDetails.email);
      requestData.append("address", this.customerDetails.address);
      requestData.append("city", this.customerDetails.city);
      requestData.append("phone_number", this.customerDetails.phoneNumber);
      // requestData.append('fail_over_services', this.customerDetails.failOverOptions)
      requestData.append(
        "state_id",
        this.customerDetails.state ? this.customerDetails.state.id : ""
      );
      requestData.append(
        "country_id",
        this.customerDetails.country ? this.customerDetails.country.id : ""
      );
      requestData.append(
        "is_whitelabelled",
        this.customerDetails.whitelabelling == "Yes" ? 1 : 0
      );
      // requestData.append('password', this.customerDetails.password)

      return requestData;
    },
    async performRegistration() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("[invalid fields] ->");
        return;
      }

      Nprogress.start();
      this.submitting = true;
      let formattedResponse = this.formatRequestData();
      console.log("[formatted response ] ->", formattedResponse);
      try {
        let { data } = await this.$store.dispatch(
          "customer/adminRegisterCustomer",
          formattedResponse
        );
        this.showSuccessNotification(data.message);
        console.log("[data from registration] ->", data);
        Nprogress.done();
        this.customerDetails = {
          firstname: "",
          lastname: "",
          organisation: "",
          phoneNumber: "",
          email: "",
          services: "",
          address: "",
          whitelabelling: "",
          city: "",
          state: "",
          country: "",
          // password: '',
          // confirmpassword: '',
          hasFailOvers: "",
          failOverOptions: "",
        };
        this.toggle();
      } catch (error) {
        Nprogress.done();
        this.submitting = false;
        console.log("error :", error);
        this.showErrorLoginNotification(error.message);
      }
    },
  },
  async mounted() {
    try {
      let apiResponse = await this.$store.dispatch("getMetaData");
      this.metaData = apiResponse.results;
      this.isLoading = false;
    } catch (error) {
      console.log("[Error] ->", error);
      this.isLoading = false;
    }
  },
};
</script>
