<template>
	<Modal
		:id="id"
		:title="'Load Credit'"
		:toggle="handleCloseModal"
		:loading="loading"
	>
		<SuccessState
			v-if="successMsg"
			:message="'Credit Loaded Successfully'"
			:onSuccess="handleRefetch"
		/>

		<form v-else-if="step === 1" @submit.prevent="handleSubmit">
			<CustomInput
				:name="'clientid'"
				v-model="clientid"
				:label="'Client Id'"
				:placeholder="'Client Id'"
				:required="true"
				:disabled="true"
			/>

			<CustomSelect
				name="currency_type"
				label="Currency"
				placeholder="Select Currency"
				:options="['NGN']"
				v-model="request.currency_type"
				:required="true"
				:error="formErrors.currency_type"
				:handleError="handleError"
			/>

			<CustomInput
				:name="'credit_amount'"
				v-model="request.credit_amount"
				:label="'Amount'"
				:placeholder="'Amount'"
				:required="true"
				:error="formErrors.credit_amount"
				:handleError="handleError"
			/>

			<FormButtons
				:loading="loading"
				:handleCloseModal="handleCloseModal"
			/>
		</form>
		<template v-else-if="step === 2">
			<h4 class="text-center my-4">
				Are you sure you wanted to credit this customer wallet?
			</h4>

			<div class="flex__between pt-4">
				<button
					type="button"
					class="btn btn-outline w-100 mr-3"
					:disabled="loading"
					@click="handleCloseModal"
				>
					No, Cancel
				</button>
				<button
					type="submit"
					class="btn btn-lg w-100 btn-primary ml-3"
					:disabled="loading"
					@click="handleLoadCredit"
				>
					<Spinner v-if="loading" />
					<span v-else>Yes, Submit</span>
				</button>
			</div>
		</template>
	</Modal>
</template>

<script>
	import { notifications } from "../../../../../public/assets/mixins/notifications";
	import Modal from "../../../../components/utils/Modal/Modal";
	import FormButtons from "../../../../components/utils/FormButtons/FormButtons";
	import CustomInput from "../../../../components/utils/CustomInput/CustomInput";
	import CustomSelect from "../../../../components/utils/CustomSelect/CustomSelect";
	import SuccessState from "../../../../components/utils/ResponseState/SuccessState";
	import { formValidator } from "../../../../components/utils/FormValidator/FormValidator";
	import Spinner from "../../../../components/utils/Spinner/Spinner";

	const initialState = () => ({
		request: { credit_amount: "", currency_type: "NGN" },
		loading: false,
		successMsg: false,
		formErrors: {},
		step: 1,
	});

	export default {
		mixins: [notifications],
		name: "AddCreditModal",
		data() {
			return { ...initialState() };
		},
		props: ["id", "clientid", "closeModal", "refetch"],
		components: {
			Modal,
			CustomSelect,
			CustomInput,
			SuccessState,
			Spinner,
			FormButtons,
		},
		computed: {
			clientID() {
				return this.$store.state.account.clientID;
			},
		},
		methods: {
			handleCloseModal() {
				Object.assign(this.$data, { ...initialState() });
				this.closeModal();
			},
			handleRefetch() {
				this.handleCloseModal();
				this.refetch();
			},
			handleSubmit(e) {
				e.preventDefault();
				const { formErrors, isValid } = formValidator(this.request);
				if (isValid) this.step = 2;
				else this.formErrors = formErrors;
			},
			handleError(name) {
				this.formErrors = { ...this.formErrors, [name]: "" };
			},

			async handleLoadCredit() {
				try {
					const { currency_type, credit_amount } = this.request || {};
					let payload = new FormData();
					payload.append("credit_amount", credit_amount);
					payload.append("currency_type", currency_type);
					payload.append("client_id", this.clientid);

					let requestPayload = { payload, clientid: this.clientid };

					this.loading = true;

					await this.$store
						.dispatch("customer/addCredit", requestPayload)
						.then(() => {
							this.loading = false;
							this.successMsg = true;
						});
				} catch (error) {
					this.loading = false;
					this.showErrorLoginNotification(error.message);
				}
			},
		},
	};
</script>
