<template>
	<div class="tab-pane active show" id="WhiteLabelCustomers">
		<div class="row">
			<div class="col-lg-12 card pt-3">
				<v-client-table
					v-if="whiteLabelRequests.length > 0"
					ref="serverTableRef"
					name="question-table"
					class="v-table"
					:data="whiteLabelRequests"
					:columns="columns"
					:options="options"
				>
					<div
						slot="serial_no"
						class="fields--truncated"
						slot-scope="props"
					>
						<div>{{ props.index }}</div>
					</div>
					<div slot="first_name" slot-scope="props">
						<div class="user-details">
							<img
								:src="
									`/assets/img/avatars/${Math.floor(
										Math.random() * 17
									) + 1}.png`
								"
								class="user-img"
								alt
							/>
							<div class="user-block">
								<div class="user-info">
									<div>
										{{ props.row.first_name }}
										{{ props.row.last_name }}
									</div>
									<span
										style="font-weight: normal;font-size: 14px;"
										>{{ props.row.phone_number }}</span
									>
								</div>
							</div>
						</div>
					</div>

					<div
						slot="existing_user"
						class="fields--truncated"
						slot-scope="props"
					>
						<div v-if="props.row.existing_user == 1">
							Existing User
						</div>
						<div v-else>New User</div>
					</div>
					<div slot="request_status" slot-scope="props">
						<span class="badge badge-success badge-app">{{
							props.row.request_status
						}}</span>
					</div>
					<div slot="created_at" slot-scope="props">
						{{
							props.row.created_at | moment(" MM-DD-YYYY h:mm a")
						}}
					</div>
					<div slot="id" slot-scope="props">
						<button
							disabled
							v-if="!props.row.api_customer"
							class="btn btn-dark btn-sm"
						>
							<i class="typcn typcn-eye-outline mr-1"></i>View
							Customer
						</button>
						<button
							v-else
							@click="
								$router.push(
									`customer/${props.row.api_customer.client_id}`
								)
							"
							class="btn btn-dark btn-sm"
						>
							<i class="typcn typcn-eye-outline mr-1"></i>View
							Customer
						</button>
					</div>
				</v-client-table>
				<DefaultContent
					v-else
					:image="'empty-resources.png'"
					:title="'No Whitelabel Customers'"
					:subtitle="
						'There are currently no whitelabel Customers in the system, do check back later.'
					"
				></DefaultContent>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				LabelRequests: [],
				columns: [
					"serial_no",
					"first_name",
					"email",
					"existing_user",
					"request_status",
					"id",
				],
				options: {
					sortable: [],
					perPage: 20,
					pagination: {
						chunk: 5,
					},
					texts: {
						filter: "",
						filterPlaceholder: "Search..",
						noResults: "No matching records",
					},
					headings: {
						serial_no: "S/N",
						first_name: "Full Name",
						email: "Email",
						existing_user: "User Status",
						request_status: "Status",

						id: "Actions",
					},
				},
			};
		},
		props: {
			whiteLabelRequests: {
				type: Array,
				required: false,
			},
		},
	};
</script>
