<template>
  <div>
    <div class="row">
      <div class="col-12 card p-4">
        <div class="contact-details--card" :style="{ border: 'none' }">
          <div class="contact-details--card-row">
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Settlement Type
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                  {{ preferences.settlement_type || "Null" }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Settlement Account
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{ preferences.BankAccount.account_number || "Null" }}
                </div>
              </span>
            </div>
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Settlement Currency
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{ preferences.default_currency || "Null" }}
                </div>
              </span>
            </div>
          </div>
          <div
            class="contact-details--card-row mt-4 pt-4 justify-content-start"
          >
            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Service Charge Type
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated pointer">
                  {{ preferences.service_charge_type || "Null" }}
                </div>
              </span>
            </div>

            <div class="contact-details--card-field">
              <span class="field-key">
                <div class="contact-details--truncated text-uppercase">
                  Service Charge Amount
                </div>
              </span>
              <span class="field-value">
                <div class="contact-details--truncated">
                  {{ preferences.service_charge }}
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../../../components/utils/TableWrapper/TableWrapper.vue";

export default {
  name: "Preferences",
  mixins: [componentData],
  data() {
    return {
      preferences: null,
      singleInviteObj: null,
      showSingleInviteModal: false,
      emptyTitle: "No Preference Found",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
    };
  },
  components: {
    TableWrapper,
  },
  methods: {
    async fetchNotifications() {
      this.loading = true;
      const params = { reference: this.clientid };
      const response = await this.$store.dispatch("customer/getSettingsInfo", {
        params,
      });
      const newData = response.data.wallet.settings[0];
      this.preferences = newData;
    },
  },

  props: ["clientid"],
  created() {
    this.fetchNotifications();
  },
};
</script>
