<template>
  <div>
    <div class="px-3">
      <FilterWrapper>
        <template slot="filterForm">
          <PlansFilter
            :loading="loading"
            :rLoading="rLoading"
            :submitFilter="refetch"
            :clearFilter="() => clearFilter('plansRef')"
          />
        </template>
      </FilterWrapper>
    </div>
    <TableWrapper
      :loading="loading"
      :errorMsg="errorMsg"
      :refetch="fetchMerchantPlans"
      :isEmpty="isEmpty"
      :emptyTitle="emptyTitle"
    >
      <div class="w-100 py-3" slot="content">
        <v-server-table
          ref="plansRef"
          class="v-table row-pointer"
          :data="customers"
          :columns="columns"
          :options="options"
          @loaded="onLoaded"
        >
          <template slot="created_at" slot-scope="{ row }">
            {{ row.created_at | moment(" MM-DD-YYYY h:mm a") }}
          </template>
          <template slot="name" slot-scope="{ row }">
             {{titleCase(row.name)}} 
          </template>
          <template slot="subscription_type" slot-scope="{ row }">
             {{titleCase(row.subscription_type)}} 
          </template>
          <template slot="interval" slot-scope="{ row }">
             {{titleCase(row.interval) || 'N/A'}} 
          </template>
          <div slot="status" slot-scope="{ row }">
            <span
              class="badge badge-app"
              :class="
                row.status === 'successful' ? 'badge-success' : 'badge-danger'
              "
            >
              {{ row.status }}
            </span>
          </div>
        </v-server-table>
      </div>
    </TableWrapper>
  </div>
</template>

<script>
import { componentData } from "../../../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../../../components/utils/TableWrapper/TableWrapper.vue";
import PlansFilter from "../../../../../components/filters/PlansFilter";

const plansHeadings = {
  name: "Plan Name",
  amount: "Amount",
  interval: "Interval",
  subscription_type: "Subscription Type",
  reference: "Plan Refrence",
  created_at: "Date Created",
};

export default {
  name: "plans",
  mixins: [componentData],
  data() {
    return {
      singleInviteObj: null,
      showSingleInviteModal: false,
      emptyTitle: "No Plan Found",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(plansHeadings, 25),
    };
  },
  components: {
    PlansFilter,
    TableWrapper,
  },
  methods: {
    async fetchMerchantPlans() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters, id: this.clientid, size: 25 };
        const response = await this.$store.dispatch(
          "customer/getPaymentMerchantPlans",
          {
            params,
          }
        );

        const newData = response.data.data;
        const count = response.data.total;
        return { ...response, count, total: count, data: newData };
      };
    },
    refetch(filters) {
      let newFilters = { ...filters };
      if (newFilters.subscriptionType)
        newFilters.subscriptionType = newFilters.subscriptionType.value;
      if (newFilters.interval) newFilters.interval = newFilters.interval.value;
      this.handleRefetch(newFilters, this.fetchMerchantPlans, "plansRef");
    },
  },

  props: ["clientid"],
  created() {
    this.fetchMerchantPlans();
  },
  mounted() {
    this.columns = Object.keys(plansHeadings).map((keyVal) => keyVal);
  },
};
</script>
