<template>
  <div id="WhitelabelRejectModal" class="modal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-content-demo">
        <div class="modal-body">
          <button
            type="button"
            class="close pos-absolute t-20 r-30 tx-26"
            data-dismiss="modal"
            aria-label="Close"
            id="close-WhitelabelRejectModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title mg-b-5">Reject a White-label request</h5>
          <p>Please provide your reason for rejecting the request.</p>
          <div class="b-b"></div>
          <div class="gaps-2x"></div>
          <form @submit.prevent="rejectWhitelabelRequest">
            <!-- CLient ID -->
            <div class="form-group">
              <label class="input-item-label">Reason for rejecting</label>
              <input
                type="text"
                class="form-control input-bordered"
                v-model="reason"
                placeholder="Enter the reason for rejecting request"
              />
              <ul class="parsley-errors-list filled" v-show="$v.reason.$error">
                <li class="parsley-required">reason for rejecting is required.</li>
              </ul>
            </div>
            <!-- Credit amount -->

            <div class="gaps-2x"></div>
            <button type="submit" class="btn btn-lg btn-primary" :disabled="isProcessing == true">
              <span v-if="isProcessing">..adding credit</span>
              <span v-else>Add credit</span>
            </button>
            <button type="button" class="btn btn-lg btn-light ml-2" data-dismiss="modal">Close</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nprogress from "nprogress";
import { required } from "vuelidate/lib/validators";
import { notifications } from "../../../public/assets/mixins/notifications";

export default {
  mixins: [notifications],
  props: {
    modalData: {
      type: Object
    }
  },
  data() {
    return {
      reason: "",
      isProcessing: false
    }
  },
  validations: {
    reason: {
      required
    }
  },
  methods: {
    clearInputFields() {
      this.reason = "";
    },
    closeModalSuccess() {
      let closeButton = document.getElementById("close-WhitelabelRejectModal");
      closeButton.click();

      this.$emit("closeRejectModal");
    },
    async rejectWhitelabelRequest() {
      this.isProcessing = true;
      let requestPayload = {
        id: this.modalData.id,
        data: {
          "reason": this.reason,
        }
      }
      try {
        let response = await this.$store.dispatch("customer/rejectWhiteLabelRequest", requestPayload);
        console.log('[response] ->', response);
        this.showSuccessNotification(response.message);
        let self = this;
        setTimeout(() => {
          self.closeModalSuccess();
          self.isProcessing = true;
          self.clearInputFields();
        }, 2500);
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.isProcessing = true;
        console.log("[error] ->", error);
        this.showErrorLoginNotification(error.message);
      }
    }
  }
};
</script>
