<template>
	<div class="col-lg-12 col-md-12">
		<div class="user-settings-section no-border pt-3">
			<div class="px-3">
				<FilterWrapper>
					<template slot="filterForm">
						<AccountActivityFilter
							:loading="loading"
							:rLoading="rLoading"
							:submitFilter="refetch"
							:applications="applications"
							:customerServices="customerServices"
							:clearFilter="() => clearFilter('logRef')"
						/>
					</template>
				</FilterWrapper>
			</div>
			<TableWrapper
				:loading="loading"
				:errorMsg="errorMsg"
				:refetch="fetchLogs"
				:isEmpty="isEmpty"
				:emptyTitle="emptyTitle"
			>
				<div class="w-100 py-3" slot="content">
					<v-server-table
						ref="logRef"
						class="v-table row-pointer"
						:columns="columns"
						:options="options"
						@loaded="onLoaded"
						@loading="setLoading(true)"
					>
						<template slot="app.app_name" slot-scope="{ row }">
							<span v-if="row.app">{{
								titleCase(row.app.app_name)
							}}</span>
							<span v-else>Null</span>
						</template>
						<template
							slot="service.service_label"
							slot-scope="{ row }"
						>
							<span v-if="row.service">{{
								titleCase(row.service.service_label)
							}}</span>
							<span v-else>Null</span>
						</template>
						<template slot="identity_number" slot-scope="{ row }">
							{{ titleCase(row.identity_number) || "Null" }}
						</template>
						<template slot="price_per_call" slot-scope="{ row }">
							{{ row.price_per_call || "Null" }}
						</template>
						<template slot="request_time" slot-scope="{ row }">
							<span>
								{{
									row.request_time
										| moment(" MM-DD-YYYY h:mm a")
								}}</span
							>
						</template>
					</v-server-table>
				</div>
			</TableWrapper>
		</div>

		<div class="gaps-4x"></div>
	</div>
</template>

<script>
import { componentData } from "../../../public/assets/mixins/componentData";
import AccountActivityFilter from "../../components/filters/AccountActivityFilter.vue";
import TableWrapper from "../../components/utils/TableWrapper/TableWrapper.vue";

const tableHeadings = {
	"app.app_name": "App Name",
	request_reference: "Request Reference",
	identity_number: "Identification Number",
	price_per_call: "Amount Debited",
	request_time: "Request Time",
	"service.service_label": "Service",
};
export default {
	mixins: [componentData],
	props: {
		clientid: {
			type: String,
		},
		applications: {
			type: Array,
		},
		customerServices: {
			type: Array,
		},
	},
	data() {
		return {
			emptyTitle: "No Log Found",
			loading: false,
			errorMsg: "",
			...this.tableOptions(tableHeadings, 25),
		};
	},
	components: {
		TableWrapper,
		AccountActivityFilter,
	},
	methods: {
		async fetchLogs() {
			this.options.requestFunction = async (data) => {
				const params = {
					...data,
					...this.filters,
					clientID: this.clientid,
				};
				const response = await this.$store.dispatch(
					"customer/getAllLogs",
					{
						params,
					}
				);

				if (response.result.length < 1) {
					return {
						count: 0,
						total: 0,
						totalPages: 0,
						data: [],
					};
				}
				return {
					count: response.total,
					total: response.total,
					totalPages: response.lastPage,
					data: response.result,
				};
			};
		},
		refetch(filters) {
			this.handleRefetch(filters, this.fetchLogs, "logRef");
		},
	},
	created() {
		this.fetchLogs();
	},
	mounted() {
		this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
		// this.fetchLogs();
	},
};
</script>
