<template>
   <div>
        <div v-if="loading" > <Spinner/></div>
        <div class="az-content-body" v-else>
            <div class="row">
              <div class="col-lg-12">
                <div class="SectionItem no-b-b mb-3">
                  <div class="SectionHeader">
                    <span class="title">
                      <a
                        @click="$router.go(-1)"
                        class="nav-sub-link pointer"
                        ><img src="/assets/img/icons/close.svg" alt="image" height="30"
                      /></a>
                      Customer Information</span
                    >
                  </div>
              
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 card p-4">
                <h1 class="merchant-title">
                  <span class="mr-4"
                    ><img height="35" src="/assets/img/avatar1.svg" alt=""/></span
                  >
                  {{ details.first_name }} {{ details.last_name }}
                </h1>
                <div class="contact-details--card border-0 m-0">
                  <div class="contact-details--card-row">
                    <div class="contact-details--card-field">
                      <span class="field-key">
                        <div class="contact-details--truncated text-uppercase">
                          Smart Id
                        </div>
                      </span>
                      <span class="field-value">
                        <div class="contact-details--truncated">
                          {{ details.smart_id }}
                        </div>
                      </span>
                    </div>
                    <div class="contact-details--card-field">
                      <span class="field-key">
                        <div class="contact-details--truncated">Verification Date</div>
                      </span>
                      <span class="field-value">
                        <div class="contact-details--truncated pointer">
                         {{ timeDateFormat(details.created_at).date }}
                         {{ timeDateFormat(details.created_at).time }}
                        </div>
                      </span>
                    </div>
                    <div class="contact-details--card-field">
                      <span class="field-key">
                        <div class="contact-details--truncated"></div>
                      </span>
                      <span class="field-value">
                        <div class="contact-details--truncated pointer">
                          <!-- {{ merchantInformation.phoneNumber || "Null" }} -->
                        </div>
                      </span>
                    </div>

                    <div class="contact-details--card-field">
                      <span class="field-key">
                        <div class="contact-details--truncated"></div>
                      </span>
                      <span class="field-value">
                        <div class="contact-details--truncated">
                          <!-- {{
                          merchantInformation.createdAt | moment(" MM-DD-YYYY h:mm a")
                      }} -->
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="contact-details--card border-0 m-0">
                  <div class="contact-details--card-row m-0">
                    <div class="contact-details--card-field">
                      <span class="field-key">
                        <div class="contact-details--truncated text-uppercase">
                          Services
                        </div>
                      </span>
                      <span class="field-value">
                        <div class="">
                         <span class="field-value">
                            <span
                                v-for="(service, i) in details.services"
                                :key="i"
                                class="badge badge-service badge-app ml-1 mb-1 mr-1"
                            >{{ service }}</span>
                            </span>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-lg-12 col-xl-12">
                    <nav class="nav az-nav-line nav-line-user-account">
                    <router-link
                        to="/payment-merchants?tab=identity"
                        class="nav-link active show"
                        >Identity Verification</router-link
                    >
                    </nav>
                </div>
            </div>
            <div class="tab-content pt-4 mx-2">
                <div
                    id="identity"
                    class="row tab-pane show active"
                >
                  <BusinessIdentityVerification v-if="this.$route.fullPath.includes('business')" />
                  <IdentityVerification v-else />
                </div>
            </div>
        </div>
   </div>
</template>

<script>
import { componentData } from "../../../public/assets/mixins/componentData";
import IdentityVerification from './IdentityVerification.vue'
import BusinessIdentityVerification from './BusinessIdentityVerification.vue'

const route = window.location.pathname.includes('business') ? "logs/getSmartIDBusinessesDetails" : "logs/getSmartIDCustomersDetails"

export default {
    name: "PersonalSmartID",
    mixins: [componentData],
    data() {
        return {
            loading: true,
            details: null,
        };
    },
    components: {
      IdentityVerification,
      BusinessIdentityVerification
    },
    methods: {
      async fetchSmartId() {
            const response = await this.$store.dispatch(
                window.location.pathname.includes('business') ? "logs/getSmartIDBusinessesDetails" : "logs/getSmartIDCustomersDetails",
                {
                    smartId: this.$route.params.smart_id,
                }
            );
            this.details = response.data;
            this.loading = false;
            
        },
    },
    created() {
        this.fetchSmartId();
    },
};
</script>