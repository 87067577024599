<template>
  <div class="az-content-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="SectionItem no-b-b">
          <div class="SectionHeader">
            <span class="title">Service Logs</span>
            <div class="description">
              View all api call logs on the system group by service
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 card pt-3">
        <div class="px-3">
          <FilterWrapper>
            <template slot="filterForm">
              <ServiceProviderLogsFilter
                :loading="loading"
                :rLoading="rLoading"
                :submitFilter="refetch"
                :clearFilter="() => clearFilter('serverTableRef')"
                :download="downloadLogs"
              />
            </template>
          </FilterWrapper>
        </div>
        <v-server-table
          ref="serverTableRef"
          name="question-table"
          class="v-table"
          :data="customers"
          :columns="columns"
          :options="options"
          @loaded="onLoaded"
          @loading="setLoading(true)"
        >
          <template slot="created_at" slot-scope="props">
            <span>{{
              props.row.created_at | moment(" MM-DD-YYYY h:mm a")
            }}</span>
          </template>
        </v-server-table>
      </div>
    </div>
  </div>
</template>

<script>
import { componentData } from "../../../public/assets/mixins/componentData";
import ServiceProviderLogsFilter from "../../components/filters/ServiceProviderLogsFilter.vue";

const tableHeadings = {
  request_reference: "Reference",
  api_customer_id: "Customer ID",
  price_per_call: "Cost",
  "apiCustomer.organization_name": "Customer Name",
  'service.service_label': "Service",
  created_at: "Date of Trnx",
};
export default {
  mixins: [componentData],
  data() {
    return {
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(tableHeadings, 25),
    };
  },
  components: {
    ServiceProviderLogsFilter,
  },
  methods: {
    refetch(filters) {
      this.handleRefetch(filters, this.fetchAdminLogs, "serverTableRef");
    },
    async fetchAdminLogs() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters };
        const apiResponse = await this.$store.dispatch("logs/getAdminLogs", {
          params,
        });
        apiResponse.result.count = apiResponse.result.total;
        const newData = apiResponse.result.data;
        const count = apiResponse.result.total;
        return { ...apiResponse, count, total: count, data: newData };
      };
    },
    async downloadLogs() {
      const download = (filterData) => {
        return this.downloadReport(tableHeadings, filterData.data);
      };
      this.downloadTableReport("logs/getAdminLogs", download);
    },
  },
  created() {
    this.fetchAdminLogs();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
