<template>
	<Modal
		id="createSubPlan"
		title="Add Subscription Plan"
		:toggle="handleCloseModal"
		:loading="loading"
	>
		<form  @submit.prevent="handleSubmit">
			<div class="form-group d-flex">
				<label class="rdiobox">
				<input
					name="billing"
					required
					type="radio"
					value="1"
					v-model="isCustom"
				/>
				<span class="fs-15">Subscription Plan</span>
				</label>
				<label class="rdiobox ml-3">
				<input
					name="billing"
					required
					type="radio"
					value="2"
					v-model="isCustom"
				/>
				<span class="fs-15" >
					Custom Subscription Plan
				</span>
				</label>
			</div>
			<div v-if="isCustom === '2'" class="position-relative">
				<CustomInput
		          :name="'customer'"
				  :label="'Cusotmer'"
		          :placeholder="'Enter Cusotmer Name'"
		          v-model="customer"
					:error="formErrors.customer"
		          :handleError="handleError"
		        />
				<div class="search-result-container w-100 shadow" v-if="customer.length > 2 && client_id === ''" >
					<div  v-for="(result, key) in customers" :key="key"
					class="search-result p-2 border w-100 pointer" @click="()=>{
						client_id = result.client_id
						customer = result.organization_name
					}" >
						{{ result.organization_name }}
					</div>
				</div>
			</div>
			<CustomInput
	          :name="'plan_name'"
			  :label="'Plan Name'"
	          :placeholder="'Enter Subscription Plan Name'"
	          v-model="plan_name"
				:error="formErrors.plan_name"
	          :handleError="handleError"
	        />
			<CustomInput
	          :name="'price'"
			  :label="'Plan Price'"
	          :placeholder="'Enter Plan Price'"
	          v-model="price"
			  :type="'number'"
			  :error="formErrors.price"
	          :handleError="handleError"
	        />
			<CustomInput
	          :name="'api_call_limit'"
			  :label="'API Call Limit'"
	          :placeholder="'Enter number'"
	          v-model="api_call_limit"
			   :type="'number'"
			  :error="formErrors.api_call_limit"
	          :handleError="handleError"
	        />
			<CustomSelect
				name="cycle"
				:label="'Plan Type'"
				placeholder="Select Type"
				:optionLabel="'name'"
				:error="formErrors.type"
				:handleError="handleError"
				:options="subscriptionCycle"
				v-model="cycle"
			/>
			<div v-for="(service, key) in serviceCategories" :key="key">
				<SubService
				:service="service"
				:handleSelectService="handleSelectService"
				:activeServices="activeServices"
				/>
			</div>
			<FormButtons
				:loading="loading"
				:handleCloseModal="handleCloseModal"
			/>
		</form>
	</Modal>
</template>

<script>
	import { notifications } from "../../../../public/assets/mixins/notifications";
	import Modal from "../../../components/utils/Modal/Modal";
	import FormButtons from "../../../components/utils/FormButtons/FormButtons";
	import CustomInput from "../../../components/utils/CustomInput/CustomInput";
	import CustomSelect from "../../../components/utils/CustomSelect/CustomSelect";
	import SuccessState from "../../../components/utils/ResponseState/SuccessState";
	import { formValidator } from "../../../components/utils/FormValidator/FormValidator";
	import Spinner from "../../../components/utils/Spinner/Spinner";
	import SubService from '../../../views/Customers/modals/AccountServiceEditModal/SubService'

	const initialState = () => ({
		client_id: "",
		plan_name: "",
		api_call_limit: "",
		price: "",
		cycle: "",
		customer: '',
		isCustom: '1',
	});
	const subscriptionCycle = [
		{
			name: "Weekly",
			value: "weekly",
		},
		{
			name: "Monthly",
			value: "monthly",
		},
		{
			name: "Yearly",
			value: "yearly",
		},
	];
	export default {
		mixins: [notifications],
		name: "CreateSubscriptionPlanModal",
		data() {
			return {
				...initialState(),
				serviceCategories: [],
				activeServices: [],
				loading: false,
				successMsg: false,
				formErrors: {},
				customers: [],
				subscriptionCycle
			};
		},
		props: ["id", "closeModal", "refetch"],
		components: {
			Modal,
			CustomInput,
			CustomSelect,
			SuccessState,
			Spinner,
			SubService,
			FormButtons,
		},
		methods: {
			handleCloseModal() {
				this.refetch({});
				Object.assign(this.$data, { ...initialState() , subscriptionCycle });
				this.closeModal();
			},
			handleSubmit(e) {
				e.preventDefault();
				const payload = {
					plan_name: this.plan_name,
					price: this.price,
					api_call_limit: this.api_call_limit,
					cycle: this.cycle,
				}
				if(this.isCustom === '2') payload.customer = this.customer
				const { formErrors, isValid } = formValidator(payload);
				if (isValid) {
					this.addSubscriptionPlan()
				}
				else this.formErrors = formErrors;
			},
			handleError(name) {
				this.formErrors = { ...this.formErrors, [name]: "" };
			},
			showError(msg) {
				this.loading = false;
				this.showErrorLoginNotification(msg);
			},
			handleSelectService(service, single = true, add = true) {
				if (single) {
					if (this.activeServices.includes(service)) {
						let tempService = this.activeServices.filter(
							(data) => data !== service
						);
						this.activeServices = tempService;
					} else {
						this.activeServices = [...this.activeServices, service];
					}
				} else {
					if (add) {
						this.activeServices = [
							...new Set([
								...this.activeServices,
								...service.apiServices.map((dt) => dt.id),
							]),
						];
					} else {
						this.activeServices = this.activeServices.filter(
							(el) => !service.apiServices.map((dt) => dt.id).includes(el)
						);
					}
				}
			},
			async addSubscriptionPlan() {
				try {
					this.loading = true
					const payload = {
						plan_name: this.plan_name,
						price: this.price,
						api_call_limit: this.api_call_limit,
						cycle: this.cycle.value,
						service_ids: this.activeServices
					}
					if(this.client_id) payload.client_id = this.client_id

					let response = await this.$store.dispatch(
						"services/createSubscriptionPlan",{ payload }
					);

					if (response.status_code === 200) {
						this.showSuccessNotification('Subscription Plan Created Successfully');
						this.handleCloseModal()
						this.loading = false
					} else {
						this.showErrorLoginNotification('Unable to create subscription plan');
						this.loading = false
					}
					
				} catch (error) {
					console.log("[error] ->", error);
				}
			},
			async fetchServiceCategories() {
				try {
					let response = await this.$store.dispatch(
						"services/getServiceCategoriesWithServices",
					);

					this.serviceCategories = response.data;
				} catch (error) {
					console.log("[error] ->", error);
				}
			},
			async searchCustomer(payload) {
				try {
					let response = await this.$store.dispatch(
						"customer/searchGetCustomers", payload
					);
					this.customers = response.data.slice(0,4)

				} catch (error) {
					console.log("[error] ->", error);
				}
			},
			
		},
		watch: {
			customer: function (newVal) {
				const data = {
					params: {
						phrase: newVal
					}
				}
				this.searchCustomer(data)
			}
		},
		mounted() {
			this.fetchServiceCategories();
		},
	};
</script>

<style>
.search-result-container{
	position: absolute;
    background: white;
    top: 73px;
}
</style>
