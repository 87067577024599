<template>
  <Modal
    cls="large-modal generate_statement_modal"
    :id="id"
    :title="'Print Statement'"
    :toggle="closeModal"
  >
    <!-- <template v-if="!error" #customHeader> -->

    <div class="flex__between mt-4">
      <div class="text">
        <div class="text--lg fw-bold primary">Preview Statement</div>
        <div class="text--xsmd light-primary">
          This is how your customer will view their statement
        </div>
      </div>
      <div class="d-flex">
        <button
          @click.prevent="handleSendToMail"
          class="btn btn--sm bg-light px-3 mr-4"
          :disabled="sending || isDisabled"
        >
          <Spinner v-if="sending" />
          <span v-else>Send to Mail</span>
        </button>

        <button
          :disabled="isDisabled"
          v-print="'#print-statement'"
          class="btn btn--sm btn--primary px-3 py-0"
        >
          <span class="d-none d-lg-block">Download PDF</span>
        </button>
      </div>
    </div>
    <!-- </template> -->

    <div v-if="!error">
      <div v-if="loading" class="mt-5 pt-5">
        <Spinner />
      </div>
      <div v-else>
        <AccountStatementContent
          :data="transactions"
          :dates="dates"
          :extra="extra"
          @ref="handleRef"
          :type="params.type"
        />
        <button @click="closeModal" class="btn btn--sm btn--primary px-3 py-0">
          Close
        </button>
      </div>
    </div>
    <div v-if="error" class="mt-5 pt-5">
      <FailedState :errorMsg="error" :refetch="getTransactions" />
    </div>
  </Modal>
</template>

<script>
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Modal from "../../../../../../components/utils/Modal/Modal";
import Spinner from "../../../../../../components/utils/Spinner/Spinner";
import { getWalletApikey } from "../../../../../../components/utils/helpers";
import AccountStatementContent from "./AccountStatement/AccountStatementContent.vue";
import FailedState from "../../../../../../components/utils/ResponseState/FailedState";
import { notifications } from "../../../../../../../public/assets/mixins/notifications";
import { filters } from "../../../../../../../public/assets/mixins/filters";

export default {
  props: ["id", "closeModal", "dates", "params"],
  mixins: [notifications, filters],
  data() {
    return {
      printRef: "",
      loading: false,
      sending: false,
      extra: null,
      error: "",
      transactions: {},
      customer: {},
    };
  },
  computed: {
    isDisabled() {
      return (
        (this.transactions.data && this.transactions.data.length < 1) ||
        (this.transactions.invoices && this.transactions.invoices.length < 1)
      );
    },
  },
  components: {
    Spinner,
    FailedState,
    Modal,
    AccountStatementContent,
  },
  methods: {
    getRequestData(data = {}, limit = 9999999999) {
      const requestData = {
        apiKey: getWalletApikey(this.$store),
        params: {
          ...data,
          limit,
        },
      };
      return requestData;
    },
    async getTransactions() {
      this.loading = true;
      this.error = "";
      const params = {
        id: this.$route.params.clientid,
        customer: this.$route.params.customerid,
        ...this.params,
      };
      const url =
        this.params.type == "invoices"
          ? "payment/getCustomerInvoiceStatement"
          : "payment/getCustomerTransactions";

      try {
        const response = await this.$store.dispatch(url, {
          params,
        });

        const newData =
          this.params.type == "invoices"
            ? response.data.invoices
            : response.data.customerTransactions.data;
        const count =
          this.params.type == "invoices"
            ? response.data.invoices.length
            : response.data.customerTransactions.count;

        this.extra = response.data;
        this.transactions = newData;
        if (count > 0) {
          this.customer = this.params.type == 'invoices' ? newData[0].other_info.customer : newData[0].Customer;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    handleRef(ref) {
      this.printRef = ref;
    },
    async handleSendToMail() {
      if (this.isDisabled) return;

      this.sending = true;

      const doc = new jsPDF("p", "mm", [600, 500]);

      const canvas = await html2canvas(this.printRef);

      doc.addImage(
        canvas.toDataURL("image/png"),
        "PNG",
        1,
        1,
        undefined,
        undefined,
        undefined,
        "FAST"
      );

      const blob = doc.output("blob");
      const payload = new FormData();

      payload.append("account_statement", blob);
      payload.append("customerEmail", this.customer.email);

      try {
        const response = await this.$store.dispatch(
          "customer/sendAccStatement",
          {
            id: this.$route.params.clientid,
            payload,
          }
        );

        if (response.status_code === 200) {
          this.sending = false;
          this.showSuccessNotification(response.message);
          this.closeModal();
        } else {
          this.sending = false;
          this.showErrorLoginNotification(response.message);
        }
      } catch (error) {
        this.sending = false;
        this.showErrorLoginNotification(this.getError(error));
      }
    },
  },

  mounted() {
    this.getTransactions();
  },
};
</script>
