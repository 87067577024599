<template>
  <div id="appLimit" class="modal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-content-demo">
        <div class="modal-body">
          <button
            type="button"
            class="close pos-absolute t-20 r-30 tx-26"
            data-dismiss="modal"
            aria-label="Close"
            id="close-appLimit"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="modal-title mg-b-5">Update Limit</h5>
          <div class="b-b"></div>
          <div class="gaps-2x"></div>
          <form @submit.prevent="appLimit">
            <!-- Credit amount -->
            <div class="form-group">
              <label class="input-item-label">Amount</label>
              <input
                class="form-control input-bordered"
                placeholder
                required
                v-model="value"
              />
            </div>

            <div class="gaps-2x"></div>
            <button
              type="submit"
              class="btn btn-lg btn-primary"
              :disabled="isProcessing == true"
            >
              <span v-if="isProcessing">..processing</span>
              <span v-else>Save Changes</span>
            </button>
            <button
              type="button"
              class="btn btn-lg btn-light ml-2"
              data-dismiss="modal"
            >
              Close
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nprogress from "nprogress";
import { notifications } from "../../../public/assets/mixins/notifications";

export default {
  mixins: [notifications],
  props: ["customer", "clientid"],
  data() {
    return {
      creditAmount: "",
      value: "",
      isProcessing: false,
    };
  },
  methods: {
    closeModalSuccess() {
      let closeButton = document.getElementById("close-appLimit");
      closeButton.click();

      this.$emit("refetch");
    },
    async appLimit(e) {
      this.isProcessing = true;
      try {
        let requestPayload = {
          amount: this.value,
          clientid: this.clientid,
        };
        let application = await this.$store.dispatch(
          "customer/updateAppLimit",
          requestPayload
        );
        this.showSuccessNotification(application.message);
        let self = this;
        setTimeout(() => {
          self.closeModalSuccess();
          self.isProcessing = false;
        }, 2500);
        Nprogress.done();
      } catch (error) {
        Nprogress.done();
        this.isProcessing = false;
        console.log("[error] ->", error);
        this.showErrorLoginNotification(error.message);
      }
    },
  },
  watch: {
    customer(newVal, oldVal) {
      this.value = newVal.credit_for_apps_and_sec_customers || oldVal.credit_for_apps_and_sec_customers;
    },
  },
};
</script>
