import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import { routes } from "./router";
import store from "@/store/index";
import { apiClient } from "@/services/api";

// 3rd Party Packages
import VTooltip from "v-tooltip";
import VueMoment from "vue-moment";
import vSelect from "vue-select";
import Vuelidate from "vuelidate";
import "vue-select/dist/vue-select.css";
import { ClientTable, ServerTable } from "vue-tables-2";
import Nprogress from "nprogress";
import VueIziToast from "vue-izitoast";
import VueSweetalert2 from "vue-sweetalert2";
import "izitoast/dist/css/iziToast.css";
import "vue-select/dist/vue-select.css";
import "nprogress/nprogress.css";
import JQuery from "jquery";
window.JQuery = JQuery;

Vue.use(VTooltip);
Vue.use(VueMoment);
Vue.use(ClientTable);
Vue.use(ServerTable);
Vue.use(VueIziToast);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.component("v-select", vSelect);

import "vue-select/dist/vue-select.css";
import "vue-select/dist/vue-select.css";
import "sweetalert2/dist/sweetalert2.min.css";
import VueApexCharts from "vue-apexcharts";
import Print from "vue-print-nb";

Vue.component("apexchart", VueApexCharts);
Vue.use(Print);
import Loader from "@/components/UI/Loader.vue";
import DefaultContent from "@/components/UI/DefaultContent";

// Registering Local Components
Vue.component("Loader", Loader);
Vue.component("DefaultContent", DefaultContent);

// Register filter for number
Vue.filter("numberSeperator", function(value) {
  if (!value) return "";
  value = Number.parseInt(value);
  return Number(value).toLocaleString();
});

Vue.config.productionTip = false;

if (localStorage.getItem("bsalt-token")) {
  let token = localStorage.getItem("bsalt-token");
  apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

// route guard
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
// Navigation guards
router.beforeEach((to, from, next) => {
  Nprogress.start();

  // route guards
  let token = localStorage.getItem("loggedInUserToken");
  let user_role = localStorage.getItem("user_role");

  // Farmer1st and crenet admin access
  if (to.matched.some((record) => record.meta.SuperAdminAccess)) {
    if ((token !== null && user_role === "Crenet Admin") || (token !== null && user_role === "Farmer1st Admin")) {
      next();
    } else {
      localStorage.clear();
      next("/login");
      // console.log('token null')
    }
  }

  // update title and meta tag
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));
  next();
});

router.afterEach((routeTo, routeFrom, next) => {
  Nprogress.done();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
