<template>
	<div>
		<button
			type="button"
			class="btn btn-sm btn--primary px-4 ml-3"
			@click="openAddProviderModal"
		>
			Add Provider
		</button>
		<AddProviderModal
			:id="'addProvider'"
			:closeModal="closeModal"
			:refetch="refetch"
		/>
	</div>
</template>

<script>
	import AddProviderModal from "../../modals/AddProviderModal/AddProviderModal";

	export default {
		name: "AddProviderButton",
		props: ["refetch"],
		methods: {
			openAddProviderModal() {
				$("#addProvider").modal("show");
			},
			closeModal() {
				$("#addProvider").modal("hide");
			},
		},
		components: {
			AddProviderModal,
		},
	};
</script>
