import { apiClient } from "./api";
import Axios from "axios";

export default {
  GetInfrastructureProviders() {
    return apiClient.get(`Authorize/FetchInfrastructureProviders/Admin`);
  },
  CreateInfrastructureProviders(payload) {
    return apiClient.post(
      `Authorize/FetchInfrastructureProviders/Admin`,
      payload
    );
  },
  UpdateInfrastructureProviders({ id, payload }) {
    return apiClient.put(
      `Authorize/FetchInfrastructureProviders/${id}`,
      payload
    );
  },
  GetProviderParameters() {
    return apiClient.get(`Authorize/FetchProvidersParameters`);
  },
  GetAPIServices() {
    return apiClient.get(`Authorize/FetchApiServices`);
  },
  CreateProviderApiService(payload) {
    return apiClient.post(`Authorize/FetchProvidersParameters`, payload);
  },
  GetAPIServicesCategory() {
    return apiClient.get(`Authorize/FetchApiServiceCategories`);
  },
  UpdateAPIServicesCategory(request) {
    const { id, payload } = request || {};
    return apiClient.put(`Authorize/FetchApiServiceCategories/${id}`, payload);
  },
  DeleteAPIServicesCategory(id) {
    return apiClient.delete(`Authorize/FetchApiServiceCategories/${id}`);
  },
  CreateAPIServicesCategory(payload) {
    return apiClient.post(`Authorize/CreateApiServiceCategories`, payload);
  },
  CreateApiService(payload) {
    return apiClient.post(`Authorize/CreateApiService`, payload);
  },
  CreateBulkApiService(payload) {
    return apiClient.post(`Authorize/CreateApiService/Bulk`, payload);
  },
  UpdateApiService({ id, payload }) {
    return apiClient.put(`Authorize/UpdateApiService/${id}`, payload);
  },
};
