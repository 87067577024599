import DatePicker from "../../../src/components/utils/DatePicker/DatePicker";
// import DateTimePicker from "../../../src/components/utils/DatePicker/DateTimePicker";
import CustomInput from "../../../src/components/utils/CustomInput/CustomInput";
// import CustomAmountInput from "../../../src/components/utils/CustomInput/CustomAmountInput";
// import CustomTextArea from "../../../src/components/utils/CustomTextArea/CustomTextArea";
import CustomSelect from "../../../src/components/utils/CustomSelect/CustomSelect";
// import CustomerSelect from "../../../src/components/utils/Selects/CustomerSelect/CustomerSelect";
// import BankSelect from "../../../src/components/utils/Selects/BankSelect/BankSelect";
import Spinner from "../../../src/components/utils/Spinner/Spinner";
import DownloadReport from "../../../src/components/utils/DownloadReport/DownloadReport";
// import TaxSelect from "../../../src/components/utils/Selects/TaxSelect/TaxSelect";
// import CouponSelect from "../../../src/components/utils/Selects/CouponSelect/CouponSelect";
// import ItemSelect from "../../../src/components/utils/Selects/ItemSelect/ItemSelect";
// import FormButtons from "../../../src/components/utils/FormButtons/FormButtons";
import Modal from "../../../src/components/utils/Modal/Modal";
import SuccessState from "../../../src/components/utils/ResponseState/SuccessState";
// import SuccessModal from "../../../src/components/utils/Modal/SuccessModal/SuccessModal";
// import AccessControl from "../../../src/components/utils/AccessControl/AccessControl";
import {
  timeRangeFormat,
  toCurrency,
  getWalletApikey,
  tableOptions,
  titleCase,
  getError,
} from "../../../src/components/utils/helpers";
import { formValidator } from "../../../src/components/utils/FormValidator/FormValidator";

export const filters = {
  data() {
    return {
      formErrors: {},
    };
  },
  components: {
    Spinner,
    CustomInput,
    // CustomTextArea,
    CustomSelect,
    DownloadReport,
    // BankSelect,
    DatePicker,
    // DateTimePicker,
    // TaxSelect,
    // CouponSelect,
    // CustomerSelect,
    // ItemSelect,
    // FormButtons,
    SuccessState,
    Modal,
    // SuccessModal,
    // CustomAmountInput,
    // AccessControl,
  },
  methods: {
    getWalletApikey,
    timeRangeFormat,
    formValidator,
    tableOptions,
    toCurrency,
    titleCase,
    getError,
    handleError(name) {
      this.formErrors = { ...this.formErrors, [name]: "" };
    },
  },
  computed: {
    yesterDay() {
      return {
        to: new Date(new Date().valueOf() - 1000 * 60 * 60 * 24),
      };
    },
  },
};
