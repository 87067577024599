<template>
	<div id="apikeyCreateNew" class="modal">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content modal-content-demo">
				<div class="modal-body">
					<button
						type="button"
						class="close pos-absolute t-20 r-30 tx-26"
						data-dismiss="modal"
						aria-label="Close"
						id="close-createApplication"
					>
						<span aria-hidden="true">&times;</span>
					</button>
					<h5 class="modal-title mg-b-5">Create New Application</h5>
					<p>Create application for a customer.</p>
					<div class="b-b"></div>
					<div class="gaps-2x"></div>
					<form @submit.prevent="createApplication">
						<!-- CLient ID -->
						<div class="form-group">
							<label class="input-item-label">Client ID</label>
							<input
								type="text"
								disabled
								class="form-control input-bordered"
								:placeholder="clientID"
							/>
						</div>
						<!-- Application Name -->
						<div class="form-group">
							<label class="input-item-label"
								>Application Name</label
							>
							<input
								type="text"
								class="form-control input-bordered"
								placeholder
								v-model="applicationName"
							/>
							<ul
								class="parsley-errors-list filled"
								v-show="$v.applicationName.$error"
							>
								<li class="parsley-required">
									Application name is required.
								</li>
							</ul>
						</div>

						<!-- Application Services -->
						<div class="form-group">
							<label class="input-item-label">Services</label>
							<v-select
								class="v-select h-auto"
								placeholder="Select a service"
								v-model="services"
								@keydown.enter.native.prevent
								:options="userServices"
								:required="!services"
								label="service_label"
								multiple
							></v-select>
							<ul
								class="parsley-errors-list filled"
								v-show="$v.services.$error"
							>
								<li class="parsley-required">
									Services are required.
								</li>
							</ul>
						</div>

						<!-- Application Credit limit -->

						<!-- Application Credit limit -->
						<div class="form-group mt-4">
							<label class="ckbox">
								<input type="checkbox" v-model="unlimited" />
								<span style="font-size: 15px"
									>Do you want to cap the credit</span
								>
							</label>
							<span class="input-note"
								>Note: Setting a cap to your API credit limit
								would limit the the credit limit on the
								app.</span
							>
						</div>

						<div class="form-group" v-if="unlimited == 1">
							<label class="input-item-label"
								>API Credit Limit</label
							>
							<input
								type="text"
								class="form-control input-bordered"
								v-model="creditLimit"
								placeholder
							/>
						</div>

						<!-- Billing type -->
						<label class="input-item-label">Billing Type</label>
						<div class="form-group">
							<label class="rdiobox">
								<input
									name="billing"
									type="radio"
									checked
									v-model="billing_type_id"
									value="1"
								/>
								<span
									>Core Billing (You will be charged per call
									for any service)</span
								>
							</label>
						</div>
						<div class="form-group">
							<label class="rdiobox">
								<input
									name="billing"
									type="radio"
									v-model="billing_type_id"
									value="2"
								/>
								<span
									class="fs-15"
									v-if="billing_type_id == '2'"
								>
									Bundled Billing ( Services will be billed as
									a bundle, total bundle cost is
									<strong>{{ getBundledPrice }}</strong
									>)
								</span>
								<span class="fs-15" v-else
									>Bundled Billing (Services will be billed as
									a bundle)</span
								>
							</label>

							<ul
								class="parsley-errors-list filled"
								v-show="$v.billing_type_id.$error"
							>
								<li class="parsley-required">
									Billing Type are required.
								</li>
							</ul>
						</div>

						<!-- submit Button -->
						<div class="gaps-2x"></div>
						<button
							type="submit"
							class="btn btn-lg btn-primary"
							:disabled="isProcessing == true"
						>
							<span v-if="isProcessing"
								>Creating application</span
							>
							<span v-else>Create</span>
						</button>
						<button
							type="button"
							class="btn btn-lg btn-light ml-2"
							data-dismiss="modal"
						>
							Close
						</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Nprogress from "nprogress";
	import { required } from "vuelidate/lib/validators";
	import { notifications } from "../../../public/assets/mixins/notifications";

	export default {
		mixins: [notifications],
		props: {
			servicePrices: {
				type: Array,
			},
			clientID: {
				type: String,
			},
			bundledPrice: {
				type: Number,
			},
		},
		data() {
			return {
				services: "",
				creditLimit: "",
				applicationName: "",
				isProcessing: false,
				billing_type_id: "",
				unlimited: null,
			};
		},
		validations: {
			services: { required },
			applicationName: { required },
			billing_type_id: { required },
		},
		computed: {
			userServices() {
				return this.servicePrices;
			},
			metaData() {
				return this.$store.state.metaData;
			},
			getBundledPrice() {
				let total = 0;
				if (this.services) {
					for (let service of this.services) {
						total += service.pivot.price_per_call;
					}
				}
				return total;
			},
		},
		methods: {
			clearInputFields() {
				this.services = "";
				this.creditLimit = "";
				this.applicationName = "";
			},
			formatCreateApplicationData() {
				let requestData = new FormData();

				if (this.unlimited == null || this.unlimited == 0) {
					requestData.append("is_app_credit_limited", 0);
					requestData.append("api_credit_limit", 0);
				} else {
					requestData.append("is_app_credit_limited", 1);
					requestData.append("api_credit_limit", this.creditLimit);
				}
				requestData.append("app_name", this.applicationName);
				requestData.append("api_credit_limit", this.creditLimit);
				requestData.append("billing_type_id", this.billing_type_id);

				if (this.services) {
					for (let index = 0; index < this.services.length; index++) {
						requestData.append("services", this.services[index].id);
					}
				}

				return requestData;
			},
			closeModalSuccess() {
				let closeButton = document.getElementById(
					"close-createApplication"
				);
				closeButton.click();

				this.$emit("fetchCustomer");
			},
			async createApplication() {
				this.isProcessing = true;
				this.$v.$touch();
				if (this.$v.$invalid) return;

				try {
					let requestPayload = {
						clientID: this.clientID,
						payload: this.formatCreateApplicationData(),
					};
					let application = await this.$store.dispatch(
						"application/createApplicationForCustomer",
						requestPayload
					);
					this.showSuccessNotification(application.message);
					let self = this;
					this.isProcessing = false;
					setTimeout(() => {
						self.closeModalSuccess();
						self.clearInputFields();
					}, 2500);
					Nprogress.done();
				} catch (error) {
					Nprogress.done();
					this.isProcessing = false;
					console.log("[error] ->", error);
					this.showErrorLoginNotification(error.message);
				}
			},
		},
	};
</script>
