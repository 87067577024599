<template>
  <div>
    <TransactionDetailModal
      :show="showTransactionModal"
      :toggle="toggleTransactionModal"
      :singleTransactionObj="singleTransactionObj"
      :refetch="refetch"
    />
    <div class="px-3">
      <FilterWrapper>
        <template slot="filterForm">
          <TransactionFilter
            :loading="loading"
            :rLoading="rLoading"
            :submitFilter="refetch"
            :clearFilter="() => clearFilter('transactionRef')"
          />
        </template>
      </FilterWrapper>
    </div>
    <TableWrapper
      :loading="loading"
      :errorMsg="errorMsg"
      :refetch="fetchTransaction"
      :isEmpty="isEmpty"
      :emptyTitle="emptyTitle"
    >
      <div class="w-100 py-3" slot="content">
        <v-server-table
          ref="transactionRef"
          class="v-table row-pointer"
          :data="customers"
          :columns="columns"
          :options="options"
          @loading="setLoading(true)"
          @loaded="onLoaded"
          @row-click="handleClick"
        >
          <template slot="createdAt" slot-scope="{ row }">
            {{ row.createdAt | moment(" MM-DD-YYYY h:mm a") }}
          </template>
          <template slot="amount" slot-scope="{ row }">
            {{ row.currency }} {{ formatNumber(row.amount) }}
          </template>
          <template slot="type" slot-scope="{ row }">
            {{ titleCase(row.type) }}
          </template>
          <template slot="charges" slot-scope="{ row }">
            {{ row.currency }} {{ formatNumber(row.charges) }}
          </template>
          <template slot="source" slot-scope="{ row }">
            {{ titleCase(row.source) }}
          </template>
          <div slot="status" slot-scope="{ row }">
            <span
              class="badge badge-app"
              :class="
                row.status === 'successful' ? 'badge-success' : 'badge-danger'
              "
            >
              {{ row.status }}
            </span>
          </div>
          <div slot="action" slot-scope="{ row }">
            <span class="badge badge-app">
              {{ row.action === "credit" ? "Credit" : "Debit" }} </span
            ><span
              ><img
                v-if="row.action === 'credit'"
                src="/assets/img/icons/import.svg"
                alt=""
              />
              <img v-else src="/assets/img/icons/export.svg" alt="" />
            </span>
          </div>
        </v-server-table>
      </div>
    </TableWrapper>
  </div>
</template>

<script>
import { componentData } from "../../../../../public/assets/mixins/componentData";
import TableWrapper from "../../../../components/utils/TableWrapper/TableWrapper.vue";
import TransactionFilter from "../../../../components/filters/TransactionFilter.vue";
import TransactionDetailModal from "../../../../views/Customers/modals/TransactionDetailModal.vue";
import { formatNumber } from "../../../../components/utils/helpers";

const tableHeadings = {
  reference: "Reference",
  amount: "Amount",
  type: "Source",
  source: "Payment Method",
  action: "Payment Type",
  charges: "Charges",
  status: "Status",
  createdAt: "Date Created",
};

export default {
  name: "Transanctions",
  mixins: [componentData],
  data() {
    return {
      singleTransactionObj: null,
      showTransactionModal: false,
      emptyTitle: "No Transaction Found",
      showInviteModal: false,
      loading: false,
      errorMsg: "",
      customers: [],
      ...this.tableOptions(tableHeadings, 25),
    };
  },
  components: {
    TableWrapper,
    TransactionFilter,
    TransactionDetailModal,
  },
  methods: {
    toggleTransactionModal() {
      this.showTransactionModal = !this.showTransactionModal;
    },
    handleClick({ row }) {
      //   this.$router.push(`/customer/${row.client_id}`);
      this.singleTransactionObj = row;
      this.showTransactionModal = true;
    },
    formatNumber,
    refetch(filters) {
      let newFilters = { ...filters };
      if (newFilters.status) newFilters.status = newFilters.status.value;
      this.handleRefetch(newFilters, this.fetchTransaction, "transactionRef");
    },
    async fetchTransaction() {
      this.options.requestFunction = async (data) => {
        const params = { ...data, ...this.filters, id: this.clientid };
        const response = await this.$store.dispatch(
          "customer/getPaymentMerchantTransactions",
          {
            params,
          }
        );

        const newData = response.data.data;
        const count = response.data.total;
        return { ...response, count, total: count, data: newData };
      };
    },
  },

  props: ["clientid"],
  created() {
    this.fetchTransaction();
  },
  mounted() {
    this.columns = Object.keys(tableHeadings).map((keyVal) => keyVal);
  },
};
</script>
