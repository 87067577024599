var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row",attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"col-lg-12"},[[_c('div',{staticClass:"app-key-wrap accordion accordion-s3",attrs:{"id":"applications3"}},[_c('div',{staticClass:"app-key-item-wrapper accordion-item"},[_vm._m(0),_c('div',{staticClass:"collapse pt-4",attrs:{"id":"app3","data-parent":"#applications3"}},[_c('div',{staticClass:"row px-4"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"form-group d-flex"},[_vm._m(1),_c('div',{staticClass:"col-md-6"},[(_vm.data.rc_number)?_c('span',[_vm._v(" "+_vm._s(_vm.data.rc_number)+" ")]):_c('span',[_vm._v(" Not Available")])])]),_c('div',{staticClass:"form-group d-flex"},[_vm._m(2),_c('div',{staticClass:"col-md-6"},[(_vm.data.tin)?_c('span',[_vm._v(" "+_vm._s(_vm.data.tin)+" ")]):_c('span',[_vm._v(" Not Available")])])])])])])])])],[_c('div',{staticClass:"app-key-wrap accordion accordion-s2",attrs:{"id":"applications1"}},[_c('div',{staticClass:"app-key-item-wrapper accordion-item"},[_vm._m(3),_c('div',{staticClass:"collapse pt-4",attrs:{"id":"app1","data-parent":"#applications1"}},[_c('div',{staticClass:"row px-4"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"form-group d-flex"},[_vm._m(4),_c('div',{staticClass:"col-md-6",class:{
                      'link pointer': _vm.data.certificate_of_incorporation,
                    }},[(_vm.data.certificate_of_incorporation)?_c('a',{attrs:{"href":_vm.data.certificate_of_incorporation,"download":"Proof of Address","target":"_blank"}},[_vm._v(" Certificate of Incorporation Link ")]):_c('span',[_vm._v(" Not Available")])])]),_c('div',{staticClass:"form-group d-flex"},[_vm._m(5),_c('div',{staticClass:"col-md-6",class:{
                      'link pointer': _vm.data.form_cac,
                    }},[(_vm.data.form_cac)?_c('a',{attrs:{"href":_vm.data.form_cac,"download":"Proof of Address","target":"_blank"}},[_vm._v(" Business Certificate Link ")]):_c('span',[_vm._v(" Not Available")])])]),_c('div',{staticClass:"form-group d-flex"},[_vm._m(6),_c('div',{staticClass:"col-md-6",class:{
                      'link pointer': _vm.data.memorandum_articles_of_association,
                    }},[(_vm.data.memorandum_articles_of_association)?_c('a',{attrs:{"href":_vm.data.memorandum_articles_of_association,"download":"Proof of Address","target":"_blank"}},[_vm._v(" Memom Mart Link ")]):_c('span',[_vm._v(" Not Available")])])]),_c('div',{staticClass:"form-group d-flex"},[_vm._m(7),_c('div',{staticClass:"col-md-6",class:{
                      'link pointer': _vm.data.proof_of_address,
                    }},[(_vm.data.proof_of_address)?_c('a',{attrs:{"href":_vm.data.proof_of_address,"download":"Proof of Address","target":"_blank"}},[_vm._v(" Proof of Address Link ")]):_c('span',[_vm._v(" Not Available")])])]),_c('div',{staticClass:"form-group d-flex"},[_vm._m(8),_c('div',{staticClass:"col-md-6",class:{
                      'link pointer': _vm.data.tin_document,
                    }},[(_vm.data.tin_document)?_c('a',{attrs:{"href":_vm.data.tin_document,"download":"TIN Document","target":"_blank"}},[_vm._v(" TIN Document Link ")]):_c('span',[_vm._v(" Not Available")])])])])])])])])],[_c('div',{staticClass:"app-key-wrap accordion accordion-s2",attrs:{"id":"applications2"}},[_c('div',{staticClass:"app-key-item-wrapper accordion-item"},[_vm._m(9),_c('div',{staticClass:"collapse",attrs:{"id":"app2","data-parent":"#applications2"}},[(_vm.data.directors)?_c('div',{staticClass:"mt-4 px-4"},[_c('v-client-table',{attrs:{"data":_vm.data.directors,"columns":_vm.columns,"options":_vm.options},scopedSlots:_vm._u([{key:"id_type",fn:function(props){return [_c('div',[_vm._v(" "+_vm._s(props.row.id_type ? _vm.titleCase(props.row.id_type) : "Not Available")+" ")])]}},{key:"action",fn:function(props){return [_c('div',[_c('i',{staticClass:"pointer mr-2",on:{"click":function($event){return _vm.handleRowClick(props.row)}}},[_vm._v("View")])])]}},{key:"id_image",fn:function(props){return [_c('div',[(props.row.id_image)?_c('a',{attrs:{"href":props.row.id_image,"download":`${props.row.first_name} ID Document`,"target":"_blank"}},[_vm._v("ID Document")]):[_vm._v("Not Available")]],2)]}},{key:"address_proof",fn:function(props){return [_c('div',[(props.row.address_proof)?_c('a',{attrs:{"href":props.row.address_proof,"download":`${props.row.first_name} Proof of Address`,"target":"_blank"}},[_vm._v("Proof of Address")]):[_vm._v("Not Available")]],2)]}}],null,false,2518613661)})],1):_vm._e()])])])],_c('ComplianceDetailsModal',{attrs:{"id":'details',"closeModal":_vm.closeModal,"selected":_vm.selected}})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-key-list-item accordion-heading"},[_c('div',{staticClass:"service-list"},[_c('div',{staticClass:"service-name",attrs:{"data-toggle":"collapse","data-target":"#app3"}},[_c('div',{staticClass:"service-icon"},[_c('em',{staticClass:"app-icon la la-cube"})]),_c('span',[_vm._v(" Business Information ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"input-item-label"},[_vm._v(" Business Registration Number ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"input-item-label"},[_vm._v(" Tax Identification Number ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-key-list-item accordion-heading"},[_c('div',{staticClass:"service-list"},[_c('div',{staticClass:"service-name",attrs:{"data-toggle":"collapse","data-target":"#app1"}},[_c('div',{staticClass:"service-icon"},[_c('em',{staticClass:"app-icon la la-cube"})]),_c('span',[_vm._v(" Business Documentation ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"input-item-label"},[_vm._v("Certificate of Incorporation")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"input-item-label"},[_vm._v(" Business Certificate ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"input-item-label"},[_vm._v(" Memom Mart ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"input-item-label"},[_vm._v("Proof of Address")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"input-item-label"},[_vm._v("TIN Document")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-key-list-item accordion-heading"},[_c('div',{staticClass:"service-list"},[_c('div',{staticClass:"service-name",attrs:{"data-toggle":"collapse","data-target":"#app2"}},[_c('div',{staticClass:"service-icon"},[_c('em',{staticClass:"app-icon la la-cube"})]),_c('span',[_vm._v(" Director Information ")])])])])
}]

export { render, staticRenderFns }