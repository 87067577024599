<template>
  <Loader v-if="isLoading == true"></Loader>
  <div class="az-content-body" v-else>
    <div class="row">
      <div class="col-lg-12">
        <div class="SectionItem no-b-b mb-3">
          <div class="SectionHeader">
            <span class="title">Whitelabel</span>
            <div class="description">View All whitelabel Requests And Customers</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-12 col-xl-12">
        <nav class="nav az-nav-line nav-line-user-account mg-b-40">
          <a
            href="#WhiteLabelCustomers"
            data-toggle="tab"
            @click="filterWhiteLabelCustomers"
            class="nav-link active show"
          >Whitelabel Customers</a>
          <a
            href="#WhiteLabelRequests"
            data-toggle="tab"
            class="nav-link"
            @click="filterWhiteLabelRequests"
          >Whitelabel Requests</a>
        </nav>
      </div>
    </div>

    <div class="tab-content">
      <transition mode="out-in" name="fade">
        <WhiteLabelCustomers :whiteLabelRequests="whiteLabelCutomers" />
      </transition>
      <transition mode="out-in" name="fade">
        <WhiteLabelRequests
          :whiteLabelRequests="whiteLabelRequests"
          @acceptWhiteLabelRequest="acceptWhiteLabelRequest"
          @retchRequest="fetchData"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import Nprogress from "nprogress"
import WhiteLabelCustomers from "@/components/whitelabel/WhiteLabelCustomers";
import WhiteLabelRequests from "@/components/whitelabel/WhiteLabelRequests";
import { notifications } from "../../public/assets/mixins/notifications";
export default {
  data() {
    return {
      isLoading: true,
      whiteLabelRequests: [],
      whiteLabelCutomers: [],
      whiteLabel: [],
      columns: ['serial_no', 'first_name', 'existing_user', 'request_status', "created_at", 'email'],
      options: {
        sortable: [],
        perPage: 20,
        'pagination': {
          chunk: 5,
        },
        texts: {
          filter: '',
          filterPlaceholder: 'Search..',
          noResults: 'No matching records'
        },
        headings: {
          // serial_no: 'S/N',
          'first_name': 'Full Name',
          'existing_user': 'User Status',
          'request_status': 'Status',
          'created_at': 'Date',
          'email': 'Actions'
        }
      },
    }
  },
  mixins: [notifications],
  components: {
    "WhiteLabelCustomers": WhiteLabelCustomers,
    "WhiteLabelRequests": WhiteLabelRequests
  },
  methods: {
    fetchData() {
      this.getAllWhiteLabelRequests()
    },
    async acceptWhiteLabelRequest(item) {
      try {
        Nprogress.start()
        let whiteLabelRequest = await this.$store.dispatch("customer/acceptWhiteLabelRequest", item)
        this.showSuccessNotification(whiteLabelRequest.message);
        Nprogress.done()
        await this.getAllWhiteLabelRequests()
      } catch (error) {
        this.showErrorLoginNotification(error);
      }
    },
    filterWhiteLabelCustomers() {
      this.whiteLabelCutomers = this.whiteLabel.filter(item => {
        return item.request_status == 'Granted'
      })
    },
    filterWhiteLabelRequests() {
      this.whiteLabelRequests = this.whiteLabel.filter(item => {
        return item.request_status !== 'Granted'
      })
    },
    async getAllWhiteLabelRequests() {
      try {
        let { result } = await this.$store.dispatch('logs/getAllWhiteLabelRequests')
        this.whiteLabel = result
        console.log(result)
        this.whiteLabelCutomers = result.filter(item => {
          return item.request_status == 'Granted'
        })
        this.whiteLabelRequests = result.filter(item => {
          return item.request_status !== 'Granted'
        })
      } catch (error) {
        console.log(error)
        throw Error(error)
      }
    }
  },
  async mounted() {
    try {
      await this.getAllWhiteLabelRequests()
      this.isLoading = false
      Nprogress.done()
    } catch (error) {
      Nprogress.done()
      this.isLoading = false
      this.whiteLabelRequests = []
      console.log('[error] ->', error);
    }
  }
}
</script>


<style>
.fields--truncated {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.user-details {
  display: flex;
}
.user-block {
  display: flex;
  flex-direction: column;
}
.user-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.user-info {
  font-weight: bold;
}
.user-sub {
  font-size: 15px;
}
</style>